<template>
  <div class="account-statement-filters" :class="{ 'account-statement-filters--modal': isModal }">
    <div class="account-statement-filters__group">
      <div class="account-statement-filters__group-name">
        {{ textK('UI_ACCOUNT_STATEMENT_PERIOD_TITLE') }}
      </div>
      <DatePickerWrapper
        ref="datepickerAirbus"
        class="account-statement-filters__group-input"
        :label="labelText"
        :max-date="maxDate"
        :auto-apply="true"
        v-model="keyDate"
        :date-picker-type="'text'"
      >
        <template #label>
          <VTextField
            class="account-statement-filters__group-input"
            :label="labelText"
            v-model="displayKeyDateText"
            :type="isModal ? uiVariant.Secondary : uiVariant.Primary"
            :silent-disabled="true"
            :as-span="true"
          />
        </template>
      </DatePickerWrapper>
    </div>
  </div>
</template>

<style
  src="@/src/finance/components/account-statement/account-statement-filter/account-statement-filters.scss"
  lang="scss"
  scoped
></style>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import dayjs from 'dayjs';
import DatePickerWrapper from '@/src/core/components/ui/form/datepicker/datepicker.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import useText from '@/src/core/hooks/useText';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import { DisplayDateFormat, InputDateFormat } from '@/src/core/utils/dates';
import { useAccountStatementStore } from '@/src/finance/stores/account-statement';

interface Props {
  isModal?: boolean;
}

withDefaults(defineProps<Props>(), {
  isModal: false,
});

const textK = useText();

const uiVariant = UiVariant;
const accountStatementStore = useAccountStatementStore();
const filters = computed(() => {
  return accountStatementStore.filters;
});
const keyDate = ref(new Date(filters.value.keyDate || new Date()));
const displayKeyDateText = ref('');
const datepickerAirbus = ref<InstanceType<typeof DatePickerWrapper>>();
const maxDate = dayjs().format(DisplayDateFormat);

const labelText = computed(() => {
  return textK('UI_ACCOUNT_STATEMENT_PERIOD_DATEPICKER_LABEL_SINGLE');
});

watch(
  () => keyDate.value,
  (newVal: Date) => {
    accountStatementStore.setFilters({
      keyDate: dayjs(newVal, InputDateFormat).format(DisplayDateFormat),
    });
    displayKeyDateText.value = dayjs(newVal).format(DisplayDateFormat);
  },
  { immediate: true },
);
</script>
