import { ProductApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { IWindowServerRendered } from '@/src/core/types/ui';
import { srUnescape } from '@/src/core/utils/unescape';
import { defineStore } from 'pinia';
import { ref } from 'vue';

const srProject: HTMLElement | undefined = (window as unknown as IWindowServerRendered).srProduct;

export const useProductDetailsStore = defineStore('product-details', () => {
  const content = ref<string>((srProject && srProject.innerHTML) || '');
  const id = ref<string>((srProject && srProject.getAttribute('sr-product')) || '');
  const isBusy = ref<boolean>(false);

  const contentUpdated = (payload: { content: string; id: string }) => {
    content.value = srUnescape(payload.content);
    id.value = payload.id;
  };

  const isBusyUpdated = (payload: { IsBusy: boolean }) => {
    isBusy.value = payload.IsBusy;
  };

  const reset = () => {
    content.value = '';
    id.value = '';
    isBusy.value = false;
  };

  const setIsBusy = async (IsBusy: boolean) => {
    isBusyUpdated({ IsBusy });
  };

  const fetchProductDetails = async (payload: {
    manufacturer: string;
    manufacturerAid: string;
    offerId?: string;
    reload?: boolean;
  }) => {
    if (
      !payload.reload &&
      payload.manufacturer + payload.manufacturerAid + payload.offerId === id.value
    ) {
      return;
    } else {
      reset();
    }
    isBusyUpdated({ IsBusy: true });

    let response: { IsSuccess: boolean; Data?: string };

    if (payload.offerId) {
      response = await Req({
        url: ProductApi.OfferDetails(
          payload.manufacturer,
          payload.manufacturerAid,
          payload.offerId,
        ),
      });
    } else {
      response = await Req({
        url: ProductApi.ProductDetails(payload.manufacturer, payload.manufacturerAid),
      });
    }
    const { IsSuccess, Data } = response;

    if (IsSuccess && Data) {
      contentUpdated({
        content: Data,
        id: payload.manufacturer + payload.manufacturerAid + payload.offerId,
      });
    }
    isBusyUpdated({ IsBusy: false });
    return { IsSuccess };
  };

  const setProductDetails = async (payload: { content: string; id: string; code: string }) => {
    contentUpdated({
      content: payload.content,
      id: payload.id,
    });
  };

  return {
    content,
    id,
    isBusy,
    setIsBusy,
    fetchProductDetails,
    setProductDetails,
    reset,
  };
});
