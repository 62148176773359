// tslint:disable
import { apim } from '@/src/core/apim';
import { getEnv } from '@/src/core/services/environment';
import { toStandardLegacyResponse } from '@/src/core/services/legacy-response-utils';
import { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class HeavyMaintenanceApi {
  public static HeavyMaintenanceFormInfo = (userId: string) =>
    `${baseUrl}/api/heavymaintenance/${userId}`;
  public static HeavyMaintenanceFormSubmit = (userId: string) =>
    `${baseUrl}/api/heavymaintenanceform/${userId}/submit`;

  public static async handleHeavyMaintenanceFormInfo() {
    const resp = await apim.getHeavyMaintenanceFormInfo('satairb2b', {
      asm: Cookies.get('asm') === 'true',
    });

    return toStandardLegacyResponse(resp);
  }

  public static async handleHeavyMaintenanceFormSubmit(settings: AxiosRequestConfig) {
    const resp = await apim.saveHeavyMaintenanceForm('satairb2b', settings.data, {
      asm: Cookies.get('asm') === 'true',
    });

    return toStandardLegacyResponse(resp);
  }
}
