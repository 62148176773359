<template>
  <div class="l_standard-app-wrapper">
    <div class="l_standard-app-wrapper error-page">
      <div class="error-page-icon">
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 114 132">
          <g id="Error" transform="translate(-255 -163)">
            <g id="Group" transform="translate(256 163)">
              <path
                id="Stroke-1_1_"
                fill="#fff"
                fill-rule="evenodd"
                d="M32.8 44.6L.6 92l24.2 38.8H74l1-10.2 19-20.2L73.2 59z"
                clip-rule="evenodd"
              ></path>
              <path id="Line" d="M52.5.8v39.1" class="st1"></path>
              <path d="M48.5 21.6v15.3" class="st1"></path>
              <path id="Line-Copy" d="M4.5 42.4v25.5" class="st1"></path>
              <path id="Stroke-4" d="M98 109l7.4 7.5" class="st2"></path>
              <path id="Stroke-7" d="M102 103.1l10.5.2" class="st2"></path>
              <path id="Stroke-10" d="M100 93.9l7.9-6.9" class="st2"></path>
              <path
                fill="#2e2e2e"
                d="M73.9 58.2L32.4 43.4l-32.6 48-.4.6 24.8 39.8h50.7L76 121l19.2-20.5-21.3-42.3zM92 98.6L49.8 82.7V65.5l13.9 6.1.8-1.8-15.1-6.6-13.5-16.4 36.6 13L92 98.6zM32.9 46.3L47.5 64 27.6 88.4l1.5 1.3 18.6-22.8v16.4l-23.1 45.6L1.8 92l31.1-45.7zM74 120.2l-.9 9.6H26.4l22.9-45.1 43 16.2L74 120.2z"
              ></path>
            </g>
          </g>
        </svg>
      </div>
      <!-- <h1 class="error-page-headline">{{textK('UI_ERRORPAGES_404_HEADER')}}</h1> -->
      <span class="error-page-paragraph" v-html="maintenanceText"></span>
    </div>
  </div>
</template>

<style lang="scss" src="./../error-pages.scss" scoped></style>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import { UmbracoApi } from '@/src/core/api/umbracoApi';
import useText from '@/src/core/hooks/useText';
import { Req } from '@/src/core/services/requester';

@Component({})
export class ServiceUnavailableLayout extends Vue {
  public textK = useText();

  public maintenanceText = '';

  private async mounted() {
    const { Data, IsSuccess } = await Req({
      url: UmbracoApi.getMaintenanceText,
    });

    if (IsSuccess) {
      this.maintenanceText = Data;
    }
  }
}

export default toNative(ServiceUnavailableLayout);
</script>
