import { Site, useUIStore } from '@/src/core/stores/ui';
import { RouteLocationNormalized } from 'vue-router';

const InitialProvisioningLayout = () =>
  import('@/src/planning/layouts/initial-provisioning/initial-provisioning-layout.vue');

const PlanningLayout = () => import('@/src/planning/layouts/planning-layout.vue');

const planningRoutes = [
  {
    path: '/planning',
    name: 'planning',
    redirect: { name: 'initial-provisioning' },
    component: PlanningLayout,
    meta: {
      loginRestricted: true,
    },
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: () => void) => {
      const uiStore = useUIStore();
      uiStore.setSplashScreen({ splashScreen: true });
      uiStore.setCurrentSite({ site: Site.Planning });
      next();
    },
    children: [
      {
        path: '/planning/initial-provisioning',
        name: 'initial-provisioning',
        components: {
          planningRoute: InitialProvisioningLayout,
        },
        meta: {
          title: 'Initial provisioning',
          type: 'Initial provisioning page',
          loginRestricted: true,
        },
      },
    ],
  },
];

export default planningRoutes;
