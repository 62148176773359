import { defineStore } from 'pinia';
import { ref } from 'vue';
import { UmbracoApi } from '../api';
import { Req } from '../services/requester';
import { NavigationItem } from '../types/interfaces';

export const useNavigationStore = defineStore('navigation', () => {
  const status = ref<string>('');
  const menu = ref<NavigationItem[]>([]);

  const statusUpdated = (payload: { status: string }) => {
    status.value = payload.status;
  };

  const menuUpdated = (payload: { menu: NavigationItem[] }) => {
    menu.value = [];
    menu.value.push(...payload.menu);
  };

  const fetchMenu = async (payload?: { force?: boolean }) => {
    if (status.value !== 'SUCCESS' || payload?.force) {
      statusUpdated({ status: 'PENDING' });

      const { IsSuccess, Data } = await Req({
        url: UmbracoApi.getMenu,
      });

      if (IsSuccess) {
        menuUpdated({ menu: Data });
        statusUpdated({ status: 'SUCCESS' });
      }
    }
  };

  return {
    fetchMenu,
    menu,
    status,
  };
});
