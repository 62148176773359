<template>
  <component :is="icon" :data-name="props.name" class="icon" />
</template>

<style lang="scss" src="./svgicon.scss" scoped />

<script setup lang="ts">
import { defineAsyncComponent } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});
const icon = defineAsyncComponent(() => import(`../../../assets/icons/${props.name}.svg`));
</script>
