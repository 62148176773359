import { UmbracoApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { UmbracoRouteConfig } from '@/src/core/types/interfaces';
import { defineStore } from 'pinia';

const keysToLowerCase = (originalRouteConfig: RouteConfigData) =>
  Object.keys(originalRouteConfig).reduce((result, key) => {
    result[key.toLowerCase()] = originalRouteConfig[key];
    return result;
  }, {});

interface RouteConfigData {
  [key: string]: UmbracoRouteConfig;
}

interface RouteConfigState {
  isFetched: boolean;
  routeConfig: RouteConfigData | null;
}

export const useRouteConfigStore = defineStore('routeConfig', {
  state: (): RouteConfigState => ({
    isFetched: false,
    routeConfig: null,
  }),
  actions: {
    async fetchRouteConfig() {
      const { IsSuccess, Data } = await Req({
        url: UmbracoApi.getRouteConfig,
      });

      if (IsSuccess && Data) {
        const routeConfigData = keysToLowerCase(Data);

        this.routeConfig = routeConfigData;
        this.isFetched = true;
      }
    },
    getMenuConfig(key: string, value: string, defaultValue?: string) {
      if (this.routeConfig) {
        const menuConfig = this.routeConfig[key];

        if (menuConfig) {
          const menuConfigValue = menuConfig[value];

          if (menuConfigValue) {
            return menuConfigValue;
          }
        }
      }

      return defaultValue;
    },
    getMenuConfigPath(key: string, defaultValue?: string) {
      return this.getMenuConfig(key, 'path', defaultValue);
    },
    getMenuConfigLabel(key: string, defaultValue?: string) {
      return this.getMenuConfig(key, 'menuname', defaultValue);
    },
    getMenuConfigBeta(key: string, defaultValue?: string) {
      return this.getMenuConfig(key, 'beta', defaultValue);
    },
  },
});
