<template>
  <StandardDialogModal :has-cancel-button="false" :size="uiSize.Medium">
    <template #header>
      <h3>Time flies. <br />Please request a new mail</h3>
    </template>
    <template #body>
      <div class="inner-body typeset">
        <p>
          The time to create your password has expired. But don’t worry, we can send a fresh link to
          you. Please click the button below to receive a "Reset password" mail (which is the one,
          you will need to use now).
        </p>
      </div>
    </template>
    <template #actions>
      <VButton
        :type="uiVariant.Default"
        v-ui-test="'request-new-password-button'"
        :loading="isBusy"
        @click="resetPassword"
        >Mail me</VButton
      >
    </template>
  </StandardDialogModal>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { UserApi } from '@/src/core/api';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import { Req } from '@/src/core/services/requester';
import { useModalStore } from '@/src/core/stores/modal';

const modalStore = useModalStore();
const uiVariant = UiVariant;
const uiSize = UiSize;

const email = ref('');
const token = ref<string | undefined>('');
const isBusy = ref(false);

const route = useRoute();
const router = useRouter();

const resetPassword = async (e: Event) => {
  e.preventDefault();

  isBusy.value = true;
  const { IsSuccess } = await Req({
    url: UserApi.ResetUserPassword(encodeURIComponent(email.value)),
    method: 'POST',
  });

  if (IsSuccess) {
    modalStore.closeModal();
    removeBusy();
    router.push({ name: 'market' });
  } else {
    isBusy.value = false;
  }
};

const removeBusy = () => {
  modalStore.lockCurrentModal(false);
  isBusy.value = false;
};

onMounted(() => {
  const emailValue = route.query.email as string;
  const tokenValue = route.query.token as string;

  email.value = emailValue;
  token.value = tokenValue ? tokenValue.replace(/ /g, '+') : undefined;
});
</script>
