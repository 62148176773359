<template>
  <component :is="tag" v-bind="attrs">
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';

export interface Props {
  href?: string;
}

const props = defineProps<Props>();

const tag = computed(() => {
  return props.href && props.href !== '' ? 'a' : 'div';
});

const attrs = computed(() => {
  return props.href && props.href !== '' ? { href: props.href } : {};
});
</script>
