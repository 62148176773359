import { EventBus } from '@/src/market/services/event-bus';

type EventMap = {
  addToCartClick: [];
  increase: [];
  decrease: [];
  contextualClick: [];
  multilineSearch: [];
};

export const SearchEventBus = new EventBus<EventMap>();
