<template>
  <div class="seller-details-content" v-ui-test="'seller-details-content'">
    <div class="seller-cover">
      <template v-if="mastershop.BannerCdnUrl">
        <MediaHandler
          class="seller-cover__bg"
          :placeholder="[
            {
              url: `${mastershop.BannerCdnUrl}&width=400&mode=crop`,
              size: 400,
            },
            {
              url: `${mastershop.BannerCdnUrl}&width=800&mode=crop`,
              size: 800,
            },
            {
              url: `${mastershop.BannerCdnUrl}&width=1600&mode=crop`,
              size: 1600,
            },
          ]"
          img-type="background"
        >
          <div class="no-js-background" style="background-color: white"></div>
        </MediaHandler>
      </template>
      <template v-else>
        <div class="no-js-background content-theme--dark-green"></div>
      </template>
      <div class="seller-cover__container">
        <ArrowIcon class="hero-module-arrow arrow--h1" :direction="'right-down'">
          <div class="ani-icon-arrow-container">
            <svg
              class="ani-icon-arrow right-down"
              ref="svg"
              version="1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <g>
                <path class="arrow-base" ref="arrowBase" d="M0 82L83 0l406 405-83 83z" />
                <path class="arrow-left" ref="arrowLeft" d="M512 0L395 62v450h117z" />
                <path class="arrow-right" ref="arrowRight" d="M0 512l62-117h450v117z" />
              </g>
            </svg>
          </div>
        </ArrowIcon>
        <h1>{{ mastershop.Name }}</h1>
      </div>
    </div>

    <div class="seller-details">
      <div class="seller-details__main" v-ui-test="'seller-details-description'">
        <div class="seller-details__main__inner typeset">
          <h3>About</h3>
          <p>{{ mastershop.Description }}</p>
        </div>
      </div>

      <div class="seller-details__info" v-ui-test="'mastershop-seller-details-info'">
        <div class="seller-details__media">
          <template v-if="mastershop.LogoCdnUrl">
            <img
              class="seller-details-logo"
              alt="seller details logo"
              width="200"
              :src="`${mastershop.LogoCdnUrl}&width=200&mode=crop`"
            />
          </template>
        </div>

        <ul class="seller-details-data">
          <li v-if="mastershop.CompanyType.length">
            <span class="seller-details-data__label">{{
              textK('UI_SELLERPAGE_COMPANY_TYPE')
            }}</span>
            {{ mastershop.CompanyType.join(', ') }}
          </li>
          <li v-if="mastershop.Website">
            <span class="seller-details-data__label">{{ textK('UI_SELLERPAGE_WEBSITE') }}</span>
            <p>
              <a :href="mastershop.Website" target="_blank">{{ mastershop.Website }}</a>
            </p>
          </li>
          <li v-if="mastershop.PaymentTerms">
            <span class="seller-details-data__label">
              {{ textK('UI_SELLERPAGE_PAYMENTTERMS') }}
            </span>
            <p>{{ mastershop.PaymentTerms }}</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="seller-details typeset">
      <div class="seller-details__main" v-ui-test="'seller-details-details'">
        <h3>{{ textK('UI_SELLERPAGE_SELLER_DETAILS') }}</h3>
        <p>{{ textK('UI_SELLERPAGE_SELLER_DETAILS_INFORMATION') }}</p>
        <Accordion :context="0" :variant="2">
          <template v-if="mastershop.ShowCompanyDetailsSection">
            <AccordionItem :index="0" :title="textK('UI_SELLERPAGE_MAIN_COMPANY_DETAILS')">
              <VSimpleTable
                class="product-details-table"
                :headers="[textK('UI_SELLERPAGE_COMPANY_DETAILS'), '']"
              >
                <template v-if="mastershop.DisplayAddress">
                  <VSimpleTableRow v-ui-test="'Address'">
                    <VSimpleTableCell
                      class="product-details-cell--index"
                      :label="'cell-label'"
                      :style="{ width: '50%' }"
                    >
                      {{ textK('UI_SELLERPAGE_LEGAL_ADDRESS') }}
                    </VSimpleTableCell>
                    <VSimpleTableCell
                      class="align-right"
                      :label="'cell-value'"
                      :style="{ width: '50%' }"
                    >
                      <div v-html="mastershop.DisplayAddress"></div>
                    </VSimpleTableCell>
                  </VSimpleTableRow>
                </template>

                <template v-if="mastershop.CompanyOfficeDays.length > 0">
                  <VSimpleTableRow v-ui-test="'CompanyOfficeDays'">
                    <VSimpleTableCell
                      class="product-details-cell--index"
                      :label="'cell-label'"
                      :style="{ width: '50%' }"
                    >
                      {{ textK('UI_SELLERPAGE_OFFICE_DAYS') }}
                    </VSimpleTableCell>
                    <VSimpleTableCell
                      class="align-right"
                      :label="'cell-value'"
                      :style="{ width: '50%' }"
                    >
                      <div v-html="mastershop.CompanyOfficeDays.join(', ')"></div>
                    </VSimpleTableCell>
                  </VSimpleTableRow>

                  <template v-if="mastershop.CompanyPublicHolidays">
                    <VSimpleTableRow v-ui-test="'CompanyPublicHolidays'">
                      <VSimpleTableCell
                        class="product-details-cell--index"
                        :label="'cell-label'"
                        :style="{ width: '50%' }"
                      >
                        {{ textK('UI_SELLERPAGE_NATIONAL_PUBLIC_HOLIDAY') }}
                      </VSimpleTableCell>
                      <VSimpleTableCell
                        class="align-right"
                        :label="'cell-value'"
                        :style="{ width: '50%' }"
                      >
                        <div v-html="mastershop.CompanyPublicHolidays"></div>
                      </VSimpleTableCell>
                    </VSimpleTableRow>
                  </template>

                  <template v-if="mastershop.RegistrationNumber">
                    <VSimpleTableRow v-ui-test="'RegistrationNumber'">
                      <VSimpleTableCell
                        class="product-details-cell--index"
                        :label="'cell-label'"
                        :style="{ width: '50%' }"
                      >
                        {{ textK('UI_SELLERPAGE_REGISTRATION_NUMBER') }}
                      </VSimpleTableCell>
                      <VSimpleTableCell
                        class="align-right"
                        :label="'cell-value'"
                        :style="{ width: '50%' }"
                      >
                        <div v-html="mastershop.RegistrationNumber"></div>
                      </VSimpleTableCell>
                    </VSimpleTableRow>
                  </template>

                  <template v-if="mastershop.CompanyDunsNumber">
                    <VSimpleTableRow v-ui-test="'CompanyDunsNumber'">
                      <VSimpleTableCell
                        class="product-details-cell--index"
                        :label="'cell-label'"
                        :style="{ width: '50%' }"
                      >
                        {{ textK('UI_SELLERPAGE_DUNS_NUMBER') }}
                      </VSimpleTableCell>
                      <VSimpleTableCell
                        class="align-right"
                        :label="'cell-value'"
                        :style="{ width: '50%' }"
                      >
                        <div v-html="mastershop.CompanyDunsNumber"></div>
                      </VSimpleTableCell>
                    </VSimpleTableRow>
                  </template>

                  <template v-if="mastershop.TaxIdNumber">
                    <VSimpleTableRow v-ui-test="'CompanyDunsNumber'">
                      <VSimpleTableCell
                        class="product-details-cell--index"
                        :label="'cell-label'"
                        :style="{ width: '50%' }"
                      >
                        {{ textK('UI_SELLERPAGE_TAX_VAT_ID_NUMBER') }}
                      </VSimpleTableCell>
                      <VSimpleTableCell
                        class="align-right"
                        :label="'cell-value'"
                        :style="{ width: '50%' }"
                      >
                        <div v-html="mastershop.TaxIdNumber"></div>
                      </VSimpleTableCell>
                    </VSimpleTableRow>
                  </template>
                </template>
              </VSimpleTable>
            </AccordionItem>
          </template>

          <AccordionItem :index="1" :title="textK('UI_SELLERPAGE_QUALITY_ASSURANCE')">
            <VSimpleTable
              class="product-details-table"
              :headers="[textK('UI_SELLERPAGE_QUALITY_ASSURANCE'), '']"
            >
              <template v-if="mastershop.QualitySelfAssesmentUrl">
                <VSimpleTableRow v-ui-test="'QSA'">
                  <VSimpleTableCell
                    class="product-details-cell--index"
                    :label="'cell-label'"
                    :style="{ width: '50%' }"
                  >
                    {{ textK('UI_SELLERPAGE_QUALITY_SELF_ASSESMENT') }}
                  </VSimpleTableCell>
                  <VSimpleTableCell
                    class="align-right"
                    :label="'cell-value'"
                    :style="{ width: '50%' }"
                  >
                    <DownloadLink
                      :file-name="textK('UI_SELLERPAGE_DOWNLOAD_ATTACHMENT')"
                      :url="mastershop.QualitySelfAssesmentUrl"
                    />
                  </VSimpleTableCell>
                </VSimpleTableRow>
              </template>

              <template v-if="mastershop.ReachCompliance">
                <VSimpleTableRow v-ui-test="'Reach'">
                  <VSimpleTableCell
                    class="product-details-cell--index"
                    :label="'cell-label'"
                    :style="{ width: '50%' }"
                  >
                    {{ textK('UI_SELLERPAGE_REACH_COMPLIANCE') }}
                  </VSimpleTableCell>
                  <VSimpleTableCell
                    class="align-right"
                    :label="'cell-value'"
                    :style="{ width: '50%' }"
                  >
                    <div v-html="mastershop.ReachCompliance"></div>
                  </VSimpleTableCell>
                </VSimpleTableRow>
              </template>

              <template v-if="mastershop.RohsCompliance">
                <VSimpleTableRow v-ui-test="'Reach'">
                  <VSimpleTableCell
                    class="product-details-cell--index"
                    :label="'cell-label'"
                    :style="{ width: '50%' }"
                  >
                    {{ textK('UI_SELLERPAGE_ROHS_COMPLIANCE') }}
                  </VSimpleTableCell>
                  <VSimpleTableCell
                    class="align-right"
                    :label="'cell-value'"
                    :style="{ width: '50%' }"
                  >
                    <div v-html="mastershop.RohsCompliance"></div>
                  </VSimpleTableCell>
                </VSimpleTableRow>
              </template>

              <template v-if="mastershop.SatairClassification">
                <VSimpleTableRow v-ui-test="'SatairClassification'">
                  <VSimpleTableCell
                    class="product-details-cell--index"
                    :label="'cell-label'"
                    :style="{ width: '50%' }"
                  >
                    {{ textK('UI_SELLERPAGE_QUALITY_CLASSIFICATION') }}
                  </VSimpleTableCell>
                  <VSimpleTableCell
                    class="align-right"
                    :label="'cell-value'"
                    :style="{ width: '50%' }"
                  >
                    <div v-html="mastershop.SatairClassification" />
                    <br />
                    <template v-if="mastershop.SatairClassificationDescription">
                      <div v-html="mastershop.SatairClassificationDescription" />
                    </template>
                    <br />
                    <template v-if="mastershop.SatairClassificationExpirationDate">
                      <span>Expires</span>
                      <span>{{ DateFormat(mastershop.SatairClassificationExpirationDate) }}</span>
                    </template>
                  </VSimpleTableCell>
                </VSimpleTableRow>
              </template>
            </VSimpleTable>
          </AccordionItem>

          <template v-if="mastershop.ShowTermsSection">
            <AccordionItem :index="2" :title="textK('UI_SELLERPAGE_TERMS_POLICIES')">
              <VSimpleTable
                class="product-details-table"
                :headers="[textK('UI_SELLERPAGE_TERMS_POLICIES'), '']"
              >
                <template
                  v-if="mastershop.MinimumOrderValueUsd && mastershop.MinimumOrderValueUsd > 0"
                >
                  <VSimpleTableRow v-ui-test="'MOV'">
                    <VSimpleTableCell
                      class="product-details-cell--index"
                      :label="'cell-label'"
                      :style="{ width: '50%' }"
                    >
                      {{ textK('UI_SELLERPAGE_MINIMUM_ORDER_VALUE') }}
                    </VSimpleTableCell>
                    <VSimpleTableCell
                      class="align-right"
                      :label="'cell-value'"
                      :style="{ width: '50%' }"
                    >
                      {{ mastershop.MinimumOrderValueUsd }} USD
                    </VSimpleTableCell>
                  </VSimpleTableRow>
                </template>

                <template
                  v-if="
                    mastershop.MinimumOrderLineValueUsd && mastershop.MinimumOrderLineValueUsd > 0
                  "
                >
                  <VSimpleTableRow v-ui-test="'MOLV'">
                    <VSimpleTableCell
                      class="product-details-cell--index"
                      :label="'cell-label'"
                      :style="{ width: '50%' }"
                    >
                      {{ textK('UI_SELLERPAGE_MINIMUM_ORDER_LINE_VALUE') }}
                    </VSimpleTableCell>
                    <VSimpleTableCell
                      class="align-right"
                      :label="'cell-value'"
                      :style="{ width: '50%' }"
                    >
                      {{ mastershop.MinimumOrderLineValueUsd }} USD
                    </VSimpleTableCell>
                  </VSimpleTableRow>
                </template>

                <template v-if="mastershop.Priorities && mastershop.Priorities.length > 0">
                  <VSimpleTableRow v-ui-test="'OPH'">
                    <VSimpleTableCell
                      class="product-details-cell--index"
                      :label="'cell-label'"
                      :style="{ width: '50%' }"
                    >
                      {{ textK('UI_SELLERPAGE_ORDER_PRIORITIES_HANDLING') }}
                    </VSimpleTableCell>
                    <VSimpleTableCell
                      class="align-right"
                      :label="'cell-value'"
                      :style="{ width: '50%' }"
                    >
                      {{ mastershop.Priorities.join(', ') }}
                    </VSimpleTableCell>
                  </VSimpleTableRow>
                </template>

                <template
                  v-if="mastershop.PriorityFee && mastershop.PriorityFee.Priority?.Code === 'AOG'"
                >
                  <VSimpleTableRow v-ui-test="'OPH'">
                    <VSimpleTableCell
                      class="product-details-cell--index"
                      :label="'cell-label'"
                      :style="{ width: '50%' }"
                    >
                      {{ textK('UI_SELLERPAGE_AOG_HANDLING_FEE') }}
                    </VSimpleTableCell>
                    <VSimpleTableCell
                      class="align-right"
                      :label="'cell-value'"
                      :style="{ width: '50%' }"
                    >
                      {{ mastershop.PriorityFee.Value }}
                      {{ mastershop.PriorityFee.Type === 'PERCENTAGE' ? ' %' : 'USD' }}
                    </VSimpleTableCell>
                  </VSimpleTableRow>
                </template>

                <template v-if="mastershop.PriorityFee?.BusinessHours">
                  <VSimpleTableRow v-ui-test="'OPH'">
                    <VSimpleTableCell
                      class="product-details-cell--index"
                      :label="'cell-label'"
                      :style="{ width: '50%' }"
                    >
                      {{ textK('UI_SELLERPAGE_AOG_HANDLING_FEE_TIME_PERIOD') }}
                    </VSimpleTableCell>
                    <VSimpleTableCell
                      class="align-right"
                      :label="'cell-value'"
                      :style="{ width: '50%' }"
                    >
                      <div v-html="mastershop.PriorityFee.BusinessHours" />
                    </VSimpleTableCell>
                  </VSimpleTableRow>
                </template>

                <template v-if="mastershop.TermsAndPolicies.length > 0">
                  <VSimpleTableRow v-ui-test="'terms'">
                    <VSimpleTableCell
                      class="product-details-cell--index"
                      :label="'cell-label'"
                      :style="{ width: '50%' }"
                    >
                      {{ textK('UI_SELLERPAGE_TERMS_POLICIES') }}
                    </VSimpleTableCell>
                    <VSimpleTableCell
                      class="align-right"
                      :label="'cell-value'"
                      :style="{ width: '50%' }"
                    >
                      <div v-for="term in mastershop.TermsAndPolicies" :key="term.Id">
                        <DownloadLink :file-name="term.FriendlyName" :url="term.Url" />
                        <br />
                      </div>
                    </VSimpleTableCell>
                  </VSimpleTableRow>
                </template>
              </VSimpleTable>

              <template v-if="mastershop.ReturnPolicy">
                <VSimpleTable
                  class="product-details-table"
                  :headers="[textK('UI_SELLERPAGE_RETURN_CANCELLATION_CONDITIONS'), '']"
                >
                  <VSimpleTableRow v-ui-test="'extract'">
                    <VSimpleTableCell
                      class="align-left"
                      :label="'cell-value'"
                      :style="{ width: '100%' }"
                    >
                      {{ mastershop.ReturnPolicy }}
                    </VSimpleTableCell>
                  </VSimpleTableRow>
                </VSimpleTable>
              </template>
            </AccordionItem>
          </template>
        </Accordion>
      </div>
    </div>

    <template v-if="marketplaceShops.length > 0">
      <div class="seller-details typeset">
        <div class="seller-details__main" v-ui-test="'seller-details-locations'">
          <h3>{{ textK('UI_SELLERPAGE_SHIPPING_LOCATIONS') }}</h3>
          <p>{{ textK('UI_SELLERPAGE_FIND_SELLER_LOCATIONS') }}</p>
          <Accordion :context="0" :variant="2">
            <SellerDetailsMastershopLocation
              v-for="shop in marketplaceShops"
              :key="shop.Id"
              :shop="shop"
            />
          </Accordion>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import MediaHandler from '@/src/core/components/media-handler/media-handler.vue';
import AccordionItem from '@/src/core/components/ui/accordion/accordion-item/accordion-item.vue';
import Accordion from '@/src/core/components/ui/accordion/accordion.vue';
import ArrowIcon from '@/src/core/components/ui/ani-icons/arrow/arrow-icon.vue';
import DownloadLink from '@/src/core/components/ui/download-link/download-link.vue';
import VSimpleTable from '@/src/core/components/ui/simple-table/simple-table.vue';
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import useText from '@/src/core/hooks/useText';
import { MarketplaceMasterShop, MarketplaceShop } from '@/src/core/types/api';
import { DateFormat } from '@/src/core/utils/dates';
import SellerDetailsMastershopLocation from '@/src/market/components/seller/seller-details/seller-details-mastershop-location/seller-details-mastershop-location.vue';

export interface Props {
  mastershop: MarketplaceMasterShop;
  marketplaceShops: MarketplaceShop[];
}

const textK = useText();
defineProps<Props>();
</script>
