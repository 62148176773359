<template>
  <form @submit.prevent="createQuoteList">
    <StandardDialogModal :has-cancel-button="true" :size="uiSize.Small">
      <template #header>
        <h3>{{ textK('UI_QUOTES_CREATE_NAME_QUOTE_DRAFT') }}</h3>
      </template>
      <template #body>
        <div class="inner-body">
          <VTextField
            :type="uiVariant.Secondary"
            name="List name"
            label="Name"
            v-model="listName"
            ref="textfield"
          ></VTextField>
        </div>
      </template>
      <template #actions>
        <VButton
          v-ui-test="'create-quote-list-button'"
          :disabled="listName.length <= 0"
          :loading="isBusy"
          input-type="button"
          @click.prevent="createQuoteList"
          :type="uiVariant.Default"
          >Save</VButton
        >
      </template>
    </StandardDialogModal>
  </form>
</template>

<style lang="scss" src="./modal-create-quote-list.scss" scoped></style>

<script lang="ts">
import { Vue, Component, Ref, toNative } from 'vue-facing-decorator';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { IQuoteDrafts } from '@/src/core/types/interfaces';
import { useListsStore } from '@/src/market/stores/list';
import { useQuoteDraftsStore } from '@/src/market/stores/quote-drafts';

@Component({
  components: {
    VTextField,
    VButton,
    StandardDialogModal,
  },
})
export class ModalCreateQuoteList extends Vue {
  public textK = useText();
  public listsStore = useListsStore();
  public quoteDraftsStore = useQuoteDraftsStore();
  public modalStore = useModalStore();
  @Ref() readonly textfield!: InstanceType<typeof VTextField>;

  public listName: string = '';
  public uiSize = UiSize;
  public uiVariant = UiVariant;

  public get isBusy(): boolean {
    // this is lists that should be changed
    return this.listsStore.isBusy;
  }

  public async createQuoteList() {
    this.modalStore.lockCurrentModal(true);
    const result = await this.quoteDraftsStore.createList({ listName: this.listName });
    if (result.IsSuccess) {
      const myList = {} as IQuoteDrafts;
      myList.Code = result.Data.Code;
      this.modalStore.closeModal();
      if (this.$route.name === 'submittedQuotes') {
        this.$router.push({ name: 'drafts' });
      }
    } else {
      this.modalStore.lockCurrentModal(false);
    }
  }

  public mounted() {
    (this.$refs.textfield as any).setFocus();
  }
}

export default toNative(ModalCreateQuoteList);
</script>
