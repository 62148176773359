<template>
  <div class="visual-banner">
    <div class="visual-banner__content">
      <span class="visual-banner-title">{{ textK('UI_SIGNUP_REQUEST_ACCESS_BANNER_TITLE') }}</span>
      <span class="visual-banner-link">{{ textK('UI_SIGNUP_REQUEST_ACCESS_BANNER_CTA') }}</span>
    </div>
    <div class="visual-banner__logo">
      <MarketLogo :show-label="false" />
    </div>
  </div>
</template>

<style lang="scss" src="./target-alternate-get-access-banner.scss" scoped></style>

<script lang="ts">
import { Vue, Component, Prop, toNative } from 'vue-facing-decorator';
import useText from '@/src/core/hooks/useText';
import { IAdobeTargetOffer } from '@/src/core/types/adobe';
import MarketLogo from '@/src/market/components/market-header-logo/market-header-logo.vue';

@Component({
  components: {
    MarketLogo,
  },
})
export class TargetAlternateGetAccessBanner extends Vue {
  public textK = useText();
  @Prop() private offer: IAdobeTargetOffer;
}

export default toNative(TargetAlternateGetAccessBanner);
</script>
