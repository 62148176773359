import { getEnv, isDevelopment } from '@/src/core/services/environment';
import axios, { AxiosRequestConfig } from 'axios';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';
const apimUrl = getEnv('VUE_APP_HOST_APIM') ?? '';

export class UmbracoApi {
  public static getBlocks = `${baseUrl}/api/blocks`;
  public static getCountries = `${baseUrl}/api/country/all`;
  public static getEusForm = `${baseUrl}/api/eusform`;
  public static getFeatureFlags = `${baseUrl}/api/feature-flags-sapcc`;
  public static getGlobalNotification = `${baseUrl}/api/notification/global`;
  public static getImageSources = `${baseUrl}/api/media/links`;
  public static getMaintenanceStatus = `${baseUrl}/api/maintenance/check`;
  public static getMaintenanceText = `${baseUrl}/api/maintenance/text`;
  public static getMenu = `${baseUrl}/api/menu`;
  public static getMenuHelpLinks = `${baseUrl}/api/menu/helplinks`;
  public static getMenuQuickLinks = `${baseUrl}/api/menu/quicklinks`;
  public static getMicrocopies = `${baseUrl}/api/texts`;
  public static getReleaseNotes = `${baseUrl}/api/releasenotes`;
  public static getTimeZones = `${baseUrl}/api/timezones`;
  public static getWorkAreas = `${baseUrl}/api/workareas`;
  public static login = `${baseUrl}/api/login`;
  public static logout = `${baseUrl}/api/logout`;
  public static newsletterSignUp = `${baseUrl}/api/user/signupnewsletter`;
  public static SAML = (relayState: string) => `${baseUrl}/api/saml/authnRequest/${relayState}`;
  public static getRouteConfig = `${baseUrl}/api/routeconfig`;
  public static getRefreshToken = `${baseUrl}/api/refreshtoken`;

  public static apimLogin = `${apimUrl}/authorizationserver/oauth/token`;
  public static apimLogout = `${apimUrl}/logout`;

  public static async handleLogin(settings: AxiosRequestConfig) {
    const { data: originalData } = settings;

    if (!originalData) {
      return;
    }

    const data = {
      ...(originalData.userId
        ? {
            grant_type: 'password',
            username: originalData.userId,
            password: originalData.password,
          }
        : {
            grant_type: 'custom',
            ...originalData,
            mssoAccessToken: originalData.mssoToken,
            jwt: originalData.mssoToken,
          }),
    };

    const resp = await axios({
      url: UmbracoApi.apimLogin,
      method: 'POST',
      data,
      headers: {
        env: isDevelopment() ? 'localhost' : undefined!,
        'content-type': 'application/x-www-form-urlencoded',
      },
      withCredentials: true,
    });

    return {
      IsSuccess: true,
      Data: resp.data,
    };
  }

  public static async handleRefresh() {
    const resp = await axios({
      url: UmbracoApi.apimLogin,
      method: 'POST',
      data: {
        grant_type: 'refresh_token',
      },
      headers: {
        env: isDevelopment() ? 'localhost' : undefined!,
        'content-type': 'application/x-www-form-urlencoded',
      },
      withCredentials: true,
    });

    return {
      IsSuccess: true,
      Data: resp.data,
    };
  }
}
