import { FaqApi } from '@/src/content/api/faqApi';
import { Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { IFaqCategory, IUmbracoSearch, IUmbracoSearchResult } from '@/src/core/types/interfaces';
import { srUnescape } from '@/src/core/utils/unescape';
import { defineStore } from 'pinia';

export interface State {
  content: string;
  categories: IFaqCategory[];
  current: IFaqCategory;
  searchQuery: string;
  searchResult: IUmbracoSearchResult[];
  hoveredSearchResult: number;
  parent: string;
  url: string;
  isBusy: boolean;
}

export const useFaqStore = defineStore('faqStore', {
  state: (): State => ({
    content: '',
    categories: [],
    current: {},
    searchQuery: '',
    searchResult: [],
    hoveredSearchResult: -1,
    parent: '',
    url: '',
    isBusy: false,
  }),
  actions: {
    isBusyUpdated(payload: { isBusy: boolean }) {
      this.isBusy = payload.isBusy;
    },
    contentUpdated(payload: {
      content?: string;
      categories?: IFaqCategory[];
      current?: IFaqCategory;
      parent?: string;
      url?: string;
    }) {
      if (payload.content) {
        this.content = payload.content;
      }

      if (payload.categories) {
        this.categories = [];
        this.categories.push(...payload.categories);
      }

      if (payload.current) {
        this.current = Object.assign({}, payload.current);
      } else {
        this.current = {};
      }

      if (payload.parent) {
        this.parent = payload.parent;
      } else {
        this.parent = '';
      }

      if (payload.url) {
        this.url = payload.url;
      }
    },
    searchUpdated(payload: { Data: IUmbracoSearch }) {
      this.searchResult = [];

      if (payload.Data && payload.Data.Results) {
        this.searchResult.push(...payload.Data.Results);
      }
    },
    hoveredSearchResultUpdated(payload: { Index: number }) {
      this.hoveredSearchResult = payload.Index;
    },
    searchQueryUpdated(payload: { Query: string }) {
      this.searchQuery = payload.Query || '';
    },
    reset() {
      this.$reset();
    },
    async fetchFaqContent(payload: { contentURL: string }) {
      if (payload.contentURL === this.url) {
        return;
      }
      this.isBusyUpdated({ isBusy: true });

      const { IsSuccess, Data } = await Req(
        {
          url: payload.contentURL,
        },
        new ReqQueue(ReqQueueTypes.Default, 'faqContent'),
      );

      if (IsSuccess) {
        this.contentUpdated({
          content: srUnescape(Data.Content) || '<span></span>',
          categories: Data.Categories || [],
          current: Data.Current,
          parent: Data.Back || '',
          url: payload.contentURL,
        });
      }
      this.isBusyUpdated({ isBusy: false });
    },
    setFaqContent(payload: {
      content?: string;
      contentURL?: string;
      current?: IFaqCategory;
      categories?: IFaqCategory[];
      parent?: string;
    }) {
      this.isBusyUpdated({ isBusy: true });
      this.contentUpdated({
        categories: payload.categories,
        content: payload.content,
        url: payload.contentURL,
        parent: payload.parent,
      });
      this.isBusyUpdated({ isBusy: false });
    },
    async faqSearch(payload: { query: string }) {
      this.isBusyUpdated({ isBusy: true });

      const { IsSuccess, Data } = await Req(
        {
          url: FaqApi.Search(
            encodeURIComponent(payload.query),
            (this.current && this.current.tag) || '',
          ),
        },
        new ReqQueue(ReqQueueTypes.Default, 'faqSearch'),
      );

      if (IsSuccess) {
        this.searchQueryUpdated({ Query: payload.query });
        this.hoveredSearchResultUpdated({ Index: -1 });
        this.searchUpdated({ Data });
      }
      this.isBusyUpdated({ isBusy: false });
    },
    hoverNextSearchResult() {
      let index: number = this.hoveredSearchResult;

      if (this.searchResult?.length > 0) {
        if (index >= this.searchResult.length - 1) {
          index = 0;
        } else {
          index++;
        }
      } else {
        index = -1;
      }

      this.hoveredSearchResultUpdated({ Index: index });
    },
    hoverPreviousSearchResult() {
      let index: number = this.hoveredSearchResult;

      if (this.searchResult?.length > 0) {
        if (index <= 0) {
          index = this.searchResult.length - 1;
        } else {
          index--;
        }
      } else {
        index = -1;
      }

      this.hoveredSearchResultUpdated({ Index: index });
    },
    hoverSearchResult(payload: { Index: number }) {
      if (this.searchResult && payload.Index <= this.searchResult.length - 1) {
        this.hoveredSearchResultUpdated(payload);
      } else {
        this.hoveredSearchResultUpdated({ Index: -1 });
      }
    },
    clearFaqSearch() {
      this.searchQueryUpdated({ Query: '' });
      this.hoveredSearchResultUpdated({ Index: -1 });
      this.searchUpdated({ Data: { Results: [] } });
    },
  },
});
