<template>
  <div class="faq-content-wrapper">
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInTranslateDown"
      leave-active-class="animated fadeOutTranslateDown"
      mode="out-in"
    >
      <Component :is="component"></Component>
    </transition>
    <Spinner :dark-theme="true" :has-overlay="true" v-if="isBusy"></Spinner>
  </div>
</template>
<style lang="scss" src="./faq-loader.scss"></style>

<script lang="ts" setup>
import { type Component, compile, computed, onBeforeUnmount, onMounted, watch } from 'vue';
import { useFaqStore } from '@/src/content/stores/faq';
import { Site, useUIStore } from '@/src/core/stores/ui';
import { IWindowServerRendered } from '@/src/core/types/ui';
import { useRoute } from 'vue-router';

export interface IFaqSrData {
  categories: [];
  parent: string;
}

const route = useRoute();

const uiStore = useUIStore();
const faqStore = useFaqStore();
const site = Site;

const content = computed(() => {
  return faqStore.content;
});

const isBusy = computed(() => {
  return faqStore.isBusy;
});

const component = computed(
  () => (content.value ? { render: compile(content.value) } : null) as Component,
);

watch(
  () => route,
  () => {
    if (uiStore.site === site.Content) {
      faqStore.fetchFaqContent({ contentURL: route.path || '' });
    }
  },
  { deep: true },
);

onMounted(() => {
  const srContent: HTMLElement | undefined = (window as unknown as IWindowServerRendered).srContent;
  const srData: IFaqSrData | undefined = (window as unknown as IWindowServerRendered)
    .srData as IFaqSrData;

  if (srContent) {
    faqStore.setFaqContent({
      content: srContent.outerHTML,
      contentURL: srContent.getAttribute('sr-content') || '',
      categories: srData && srData.categories,
      parent: srData && srData.parent,
    });

    (window as unknown as IWindowServerRendered).srContent = undefined;
    (window as unknown as IWindowServerRendered).srData = undefined;
  } else {
    faqStore.fetchFaqContent({ contentURL: route.path || '' });
  }
});

onBeforeUnmount(() => {
  faqStore.reset();
});
</script>
