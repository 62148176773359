<template>
  <form class="request-access" @submit.prevent="requestAccess()">
    <StandardDialogModal :size="uiSize.Medium">
      <template #header>
        <template v-if="requestAccessType === 'company'">
          <div class="typeset">
            <h3>{{ textK('UI_SIGNUP_REQUEST_BUSINESS_ACCOUNT_TITLE') }}</h3>
            <p>{{ textK('UI_SIGNUP_REQUEST_BUSINESS_ACCOUNT_DESCRIPTION') }}</p>
          </div>
        </template>
        <template v-else>
          <div class="typeset">
            <h3>{{ textK('UI_SIGNUP_REQUEST_USER_ACCOUNT_TITLE') }}</h3>
            <p>{{ textK('UI_SIGNUP_REQUEST_USER_ACCOUNT_DESCRIPTION') }}</p>
          </div>
        </template>
      </template>
      <template #body>
        <div class="inner-body">
          <div class="input-container">
            <VTextField
              @valid="setValid('firstName', $event)"
              v-ui-test="'request-access-firstname'"
              :type="uiVariant.Secondary"
              :label="textK('UI_COMMON_FIRSTNAME')"
              v-model="user.FirstName"
              :required="true"
              name="fname"
              autocomplete="given-name"
            ></VTextField>
          </div>
          <div class="input-container">
            <VTextField
              @valid="setValid('lastName', $event)"
              v-ui-test="'request-access-lastname'"
              :type="uiVariant.Secondary"
              :label="textK('UI_COMMON_LASTNAME')"
              v-model="user.LastName"
              :required="true"
              name="lname"
              autocomplete="family-name"
            ></VTextField>
          </div>
          <div class="input-container">
            <VTextField
              @valid="setValid('company', $event)"
              v-ui-test="'request-access-company'"
              :type="uiVariant.Secondary"
              :label="textK('UI_COMMON_COMPANY')"
              v-model="user.Company"
              :required="true"
              name="lname"
              autocomplete="company-name"
            ></VTextField>
          </div>
          <div class="input-container">
            <CountrySelect
              v-ui-test="'request-access-country'"
              :type="uiVariant.Secondary"
              v-model="user.Country"
              @valid="setValid('countryCode', $event)"
              :required="true"
            />
          </div>
          <div class="input-container">
            <VTextField
              input-type="email"
              @valid="setValid('email', $event)"
              v-ui-test="'request-access-email'"
              :type="uiVariant.Secondary"
              :label="textK('UI_COMMON_EMAIL')"
              v-model="user.Email"
              :required="true"
              name="email"
              autocomplete="email"
            ></VTextField>
          </div>

          <div class="input-phone-container">
            <div class="input-container">
              <VTextField
                v-ui-test="'request-access-country-code'"
                :input-type="'tel'"
                :vmax-length="3"
                :type="uiVariant.Secondary"
                :label="textK('UI_COMMON_COUNTRYCODE')"
                :prefix="'+'"
                v-model="user.CountryCode"
                name="country-code"
              ></VTextField>
            </div>
            <div class="input-container">
              <VTextField
                v-ui-test="'request-access-contact-number'"
                :input-type="'tel'"
                :vmax-length="12"
                :type="uiVariant.Secondary"
                :label="textK('UI_COMMON_TELEPHONE')"
                v-model="user.Phone"
                name="phone"
                autocomplete="tel"
              ></VTextField>
            </div>
          </div>

          <div class="input-container">
            <VTextarea
              v-ui-test="'request-access-comment'"
              :type="uiVariant.Secondary"
              :label="textK('UI_COMMON_COMMENT')"
              v-model="user.Comment"
              name="comment"
            ></VTextarea>
          </div>
        </div>
      </template>
      <template #actions>
        <a class="back-button" v-if="!!modalParams.backToModal" @click="goBackTo()">Back</a>
        <VButton v-ui-test="'request-access-button'" :disabled="!formValid" :loading="loading">{{
          textK('UI_SIGNUP_REQUEST_ACCESS')
        }}</VButton>
      </template>
    </StandardDialogModal>
  </form>
</template>

<style lang="scss" src="./modal-request-access.scss" scoped></style>

<script lang="ts" setup>
import { AccessApi } from '@/src/core/api';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import CountrySelect from '@/src/core/components/ui/country-select/country-select.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { Req } from '@/src/core/services/requester';
import { useModalStore } from '@/src/core/stores/modal';
import { User } from '@/src/core/types/api';
import { computed, ref } from 'vue';

const textK = useText();
const modalStore = useModalStore();
const uiSize = UiSize;
const uiVariant = UiVariant;
const loading = ref(false);
const user = ref<RequestAccessUser>({} as RequestAccessUser);
const validationCollection = ref<Record<string, boolean>>({});

const setValid = (key: string, valid: boolean) => {
  validationCollection.value = {
    ...validationCollection.value,
    [key]: valid,
  };
};

const formValid = computed(() => {
  return !Object.keys(validationCollection.value).find(
    (key) => validationCollection.value[key] === false,
  );
});

const modalParams = computed(() => modalStore.params as { type: string; backToModal?: string });

const requestAccessType = computed(() => modalParams.value.type);

const requestAccess = async () => {
  user.value.RequestType = requestAccessType.value;
  loading.value = true;

  const { IsSuccess } = await Req({
    url: AccessApi.RequestAccess,
    method: 'post',
    data: user.value,
  });

  if (IsSuccess) {
    modalStore.closeModal();
  }
  loading.value = false;
};

const goBackTo = () => {
  const modalComponent = modalParams.value?.backToModal;
  if (modalComponent) {
    modalStore.closeModal();
    modalStore.showModal({ modalComponent });
  }
};

interface RequestAccessUser extends User {
  Company: string;
  Comment: string;
  RequestType: string;
}
</script>
