import { App, DirectiveBinding } from 'vue';

interface IUiTestOptions {
  showTestAttr: boolean;
}

const UiTests = (app: App, UiTestOptions: IUiTestOptions) => {
  const setTag = (el: HTMLElement, binding: DirectiveBinding) => {
    if (UiTestOptions.showTestAttr) {
      el.setAttribute('data-' + (binding.arg || 'ui-test'), binding.value);
    }
  };

  app.directive('ui-test', {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
      setTag(el, binding);
    },
    updated(el: HTMLElement, binding: DirectiveBinding) {
      setTag(el, binding);
    },
  });
};

export default UiTests;

export type { IUiTestOptions };
