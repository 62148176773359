<template>
  <div class="product-details">
    <section class="product-details-section product-details-header-wrapper">
      <div class="product-details-small-image">
        <svg class="placeholder-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 110">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M75.4 12.4v47.4l-59-59L.8 16.4l59 59H12.4L.8 97.4h96.6V.8z"
          />
        </svg>
      </div>
      <div class="product-details-header">
        <h1 class="product-details-title" v-ui-test="'product-details-skeleton-title'">
          <span><SkeletonLoader :size="uiSize.Large"></SkeletonLoader> </span>
          <span class="product-details-subtitle"><SkeletonLoader></SkeletonLoader></span>
        </h1>
      </div>
    </section>
    <section class="product-details-section">
      <div class="product-details-row">
        <div class="product-details-image">
          <ProductDetailsGallery>
            <svg
              class="placeholder-image"
              v-ui-test="'product-details-skeleton-placeholder-image'"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 110 110"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M75.4 12.4v47.4l-59-59L.8 16.4l59 59H12.4L.8 97.4h96.6V.8z"
              />
            </svg>
          </ProductDetailsGallery>
        </div>
        <div class="product-details-information">
          <ul class="product-details-list">
            <li class="product-details-list-item product-details-list-item--full">
              <div class="product-details-list-item-content">
                <p class="product-details-list-item-title product-details-title-label">
                  <SkeletonLoader :size="uiSize.Small"></SkeletonLoader>
                </p>
                <p class="product-details-list-item-value">
                  <SkeletonLoader :size="uiSize.Large"></SkeletonLoader>
                </p>
              </div>
            </li>
            <li class="product-details-list-item">
              <div class="product-details-list-item-content">
                <p class="product-details-list-item-title product-details-title-label">
                  <SkeletonLoader :size="uiSize.Small"></SkeletonLoader>
                </p>
                <p class="product-details-list-item-value"><SkeletonLoader></SkeletonLoader></p>
              </div>
            </li>
            <li class="product-details-list-item">
              <div class="product-details-list-item-content">
                <p class="product-details-list-item-title product-details-title-label">
                  <SkeletonLoader :size="uiSize.Small"></SkeletonLoader>
                </p>
                <p class="product-details-list-item-value"><SkeletonLoader></SkeletonLoader></p>
              </div>
            </li>
            <template v-if="isAuthenticated">
              <li class="product-details-list-item">
                <div class="product-details-list-item-content">
                  <p class="product-details-list-item-title product-details-title-label">
                    <SkeletonLoader :size="uiSize.Small"></SkeletonLoader>
                  </p>
                  <p class="product-details-list-item-value"><SkeletonLoader></SkeletonLoader></p>
                </div>
              </li>
              <li class="product-details-list-item">
                <div class="product-details-list-item-content">
                  <p class="product-details-list-item-title product-details-title-label">
                    <SkeletonLoader :size="uiSize.Small"></SkeletonLoader>
                  </p>
                  <p class="product-details-list-item-value"><SkeletonLoader></SkeletonLoader></p>
                </div>
              </li>
            </template>
          </ul>
        </div>
        <div class="product-details-cta"></div>
      </div>
    </section>
    <section class="product-details-seller">
      <div class="product-details-seller__header"><SkeletonLoader /></div>
      <div
        class="product-details-seller__wrapper product-details-seller__wrapper--loader"
        style="height: 10em"
      >
        <div
          class="product-details-seller__description product-details-seller__description--loading typeset"
        >
          <h3><SkeletonLoader :size="uiSize.Large" /></h3>
          <p>
            <SkeletonLoader :size="uiSize.Small" /><br />
            <SkeletonLoader :size="uiSize.Medium" />
          </p>
        </div>
      </div>
    </section>
    <section class="product-details-section">
      <div class="product-details-row">
        <div class="product-details-table-group">
          <VSimpleTable class="product-details-table" :headers="['', '']">
            <VSimpleTableRow v-for="index in 3" :key="index">
              <VSimpleTableCell class="product-details-cell--index" :set-width="true"
                ><SkeletonLoader></SkeletonLoader
              ></VSimpleTableCell>
              <VSimpleTableCell :set-width="true"
                ><SkeletonLoader></SkeletonLoader
              ></VSimpleTableCell>
            </VSimpleTableRow>
          </VSimpleTable>
          <VSimpleTable class="product-details-table" :headers="[' ', '']">
            <VSimpleTableRow v-for="(item, index) in 5" :key="index">
              <VSimpleTableCell class="product-details-cell--index" :set-width="true"
                ><SkeletonLoader></SkeletonLoader
              ></VSimpleTableCell>
              <VSimpleTableCell :set-width="true"
                ><SkeletonLoader></SkeletonLoader
              ></VSimpleTableCell>
            </VSimpleTableRow>
          </VSimpleTable>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import VSimpleTable from '@/src/core/components/ui/simple-table/simple-table.vue';
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import SkeletonLoader from '@/src/core/components/ui/skeleton-loader/skeleton-loader.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import ProductDetailsGallery from '@/src/market/components/product/product-details/product-details-gallery/product-details-gallery.vue';
import { computed } from 'vue';

const uiSize = UiSize;
const authenticationStore = useAuthenticationStore();

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);
</script>
