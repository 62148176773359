<template>
  <aside
    :class="{
      'initial-provisioning-filters': true,
      'initial-provisioning-filters initial-provisioning-filters--isModal': isModal,
    }"
  >
    <section>
      <p class="initial-provisioning-filters__header">Search for</p>
      <VTextField
        label="File name"
        name="Filename search"
        :model-value="searchTerm"
        :type="isModal ? UiVariant.Secondary : UiVariant.Primary"
        @update:modelValue="handleSearchTerm"
      />
    </section>
    <section>
      <p class="initial-provisioning-filters__header">Filter by</p>
      <VSelect
        :options="categoryOptions"
        :disabled="categoryOptions.length === 0"
        label="Category"
        :model-value="categoryOptions.indexOf(selectedCategory)"
        :type="isModal ? UiVariant.Secondary : UiVariant.Primary"
        @update:modelValue="selectCategory"
      />
    </section>
  </aside>
</template>

<style lang="scss" src="./initial-provisioning-filters.scss" scoped />

<script lang="ts" setup>
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import { useInitialProvisioningStore } from '@/src/planning/store/initial-provisioning';
import { computed } from 'vue';

interface Props {
  isModal?: boolean;
}

withDefaults(defineProps<Props>(), {
  isModal: false,
});

const initialProvisioningStore = useInitialProvisioningStore();

const searchTerm = computed(() => {
  return initialProvisioningStore.searchTerm;
});

const categoryOptions = computed(() => {
  return initialProvisioningStore.getCategoryOptions();
});

const selectedCategory = computed(() => {
  return initialProvisioningStore.selectedCategory;
});

const handleSearchTerm = (value: string) => {
  initialProvisioningStore.setSearchTerm(value);
};

const selectCategory = (index: number) => {
  const value = categoryOptions.value[index];
  initialProvisioningStore.setSelectedCategory(value);
};
</script>
