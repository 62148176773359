import { ProductApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

interface ServiceReference {
  Title: string;
  Text: string;
  Link: string;
}

const mapProcurementToServiceOrderParam = (procurementType: string) => {
  if (procurementType.toUpperCase() === ProcurementType.ExchangeNew) {
    return 'exchange';
  }

  if (procurementType.toUpperCase() === ProcurementType.ExchangeUsed) {
    return 'exchange used';
  }

  return procurementType;
};

export const useServiceReferenceStore = defineStore('serviceReferenceStore', () => {
  const serviceReferences = ref<ServiceReference[]>([]);

  function $reset() {
    serviceReferences.value = [];
  }

  async function fetchServiceReferences(type: string, isTool = false, isPropPart = false) {
    const procurementType = mapProcurementToServiceOrderParam(type);

    const { IsSuccess, Data } = await Req({
      url: ProductApi.ServiceReference(),
      params: {
        procurementType,
        isTool,
        isPropPart,
      },
    });

    if (IsSuccess && Data) {
      serviceReferences.value = Data;
    }

    return { IsSuccess };
  }

  return { fetchServiceReferences, serviceReferences, $reset };
});
