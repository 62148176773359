<template>
  <div class="dot-loader-container" role="status" aria-label="loading">
    <div class="default-icon" ref="icon" title="Animated loading dots">
      <slot name="icon"></slot>
    </div>
    <svg
      ref="circleGroup"
      class="dot-loader"
      :class="[{ loading: loading }]"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      aria-hidden="true"
      focusable="false"
    >
      <g>
        <circle ref="circle1" cx="258" cy="356" r="0" />
        <circle ref="circle2" cx="258" cy="356" r="0" />
        <circle ref="circle3" cx="258" cy="356" r="0" />
      </g>
    </svg>
  </div>
</template>

<style lang="scss" src="./dot-loader.scss" scoped></style>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { gsap } from 'gsap';

interface Props {
  loading?: boolean;
  toColor?: string;
}

export interface ILoaderAttr {
  r: number;
  cx: number;
  cy: number;
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  toColor: '#2EECA5',
});

const circleGroup = ref<HTMLElement>();
const circle1 = ref<HTMLElement>();
const circle2 = ref<HTMLElement>();
const circle3 = ref<HTMLElement>();
const icon = ref<HTMLElement>();

const loaderTimeline = gsap.timeline({ paused: true });
const radiusEase = ref('power3.in');
const fillEase = ref('power3.out');
const elasticEase = ref('elastic.inOut');

const endLoadingAnimation = () => {
  if (loaderTimeline.time() > 1.6) {
    // Check if less than the whole animation has run
    loaderTimeline.pause(1.6).reverse();
  } else {
    loaderTimeline.reverse();
  }
};

const beginLoading = () => {
  loaderTimeline.play();
};

const endLoading = () => {
  endLoadingAnimation();
};

const loadingComplete = () => {
  loaderTimeline.kill();
};

const createDotLoaderTimeline = () => {
  // Add to timeline, if element is added
  if (icon.value) {
    loaderTimeline.to(icon.value, {
      scale: 0,
      ease: elasticEase.value,
      duration: 0.5,
    });
  }

  if (!!circle1.value && !!circle2.value && !!circle3.value) {
    loaderTimeline
      .to([circle1.value, circle2.value, circle3.value], {
        attr: { r: 60 },
        ease: radiusEase.value,
        duration: 0.2,
      })
      .to(circle1.value, {
        duration: 0.15,
        attr: { cx: 64 },
      })
      .to(circle3.value, {
        duration: 0.15,
        attr: { cx: 448 },
      })
      .to([circle1.value, circle2.value, circle3.value], {
        ease: fillEase.value,
        duration: 0.6,
        onReverseComplete: () => loadingComplete(),
        fill: props.toColor,
        attr: {
          cy: 450,
        },
        stagger: {
          yoyo: true,
          amount: 0.5,
          repeatDelay: 0.1,
          repeat: -1,
        },
      });
  }
};

watch(
  () => props.loading,
  (newValue: boolean) => {
    if (newValue) {
      beginLoading();
    } else {
      endLoading();
    }
  },
);

onMounted(() => {
  createDotLoaderTimeline();

  if (props.loading) {
    beginLoading();
  }
});

onUnmounted(() => {
  loadingComplete();
});
</script>
