<template>
  <InViewWatcher>
    <div class="box-spot-module-wrapper">
      <slot />
    </div>
  </InViewWatcher>
</template>

<style lang="scss" src="./box-spot-module.scss"></style>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';

@Component({
  components: {
    InViewWatcher,
  },
})
export class ContentBoxSpotModule extends Vue {}

export default toNative(ContentBoxSpotModule);
</script>
