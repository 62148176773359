export enum MaterialStatus {
  STATUS_S1 = 'S1',
  STATUS_E = 'E',
  STATUS_ZERO = '0',
}

export enum MaterialStatusBlocked {
  STATUS_2 = '2',
  STATUS_S1 = 'S1',
  STATUS_S2 = 'S2',
  STATUS_S4 = 'S4',
  STATUS_S5 = 'S5',
  STATUS_S6 = 'S6',
  STATUS_S7 = 'S7',
  STATUS_S9 = 'S9',
  STATUS_Z1 = 'Z1',
  STATUS_Z2 = 'Z2',
  STATUS_Z4 = 'Z4',
  STATUS_Z6 = 'Z6',
  STATUS_Z7 = 'Z7',
  STATUS_Z8 = 'Z8',
  STATUS_Z9 = 'Z9',
  STATUS_AD = 'AD',
  STATUS_OM = 'OM',
}
