import { RouteRecord } from 'vue-router';

const RestrictedRouteChecker = async (routerObject: RouteRecord[]) => {
  const path = routerObject.find((match) => {
    const validRoute = !(!!match.meta.loginRestricted && match.meta?.loginRestricted);
    if (validRoute) {
      return match.path;
    } else {
      return;
    }
  });
  return path ? path : '/';
};

export default RestrictedRouteChecker;
