/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** ATA Chapter */
export interface ATAChapter {
  code?: string;
  name?: string;
}

/** Request body parameter (DTO in xml or json format) */
export interface AddEntryRemarksRequest {
  remarks?: string;
}

/** DAH Membership */
export interface AddMembershipRequest {
  type?: string;
  typeVersion?: string;
}

/** Additional Product Info */
export interface AdditionalProductInfo {
  id?: string;
  /** @format int64 */
  quantity?: number;
  /** Product Additional Infos */
  productAdditionalInfo?: ProductAdditionalInfos;
}

/** Additional Product Info List */
export interface AdditionalProductInfoList {
  productEntries?: AdditionalProductInfo[];
}

/** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
export interface Address {
  /** Unique id value of the address which is optional while creating new address. While performing other address operations this value is the key */
  id?: string;
  /**
   * Title of the address person
   * @example "Mr."
   */
  title?: string;
  /**
   * Code of the title
   * @example "MR"
   */
  titleCode: string;
  /**
   * First name of the address person
   * @example "John"
   */
  firstName: string;
  /**
   * Last name of the address person
   * @example "Doe"
   */
  lastName: string;
  /**
   * Company Name
   * @example "Global Corp."
   */
  companyName?: string;
  /**
   * First line of the address
   * @example "123 Main Street"
   */
  line1: string;
  /**
   * Second line of the address
   * @example "Garden Level"
   */
  line2?: string;
  /**
   * Town, field required
   * @example "New York City"
   */
  town: string;
  /** Response body fields which will be returned while fetching the list of country's regions. */
  region?: Region;
  /**
   * District name
   * @example "8th"
   */
  district?: string;
  /**
   * Postal code of the address
   * @example "10001"
   */
  postalCode: string;
  /**
   * Phone number
   * @example "+1 (123) 456-7890"
   */
  phone?: string;
  /**
   * Cellphone number
   * @example "+1 (123) 456-7890"
   */
  cellphone?: string;
  /**
   * Email address
   * @example "johndoe@domain.com"
   */
  email?: string;
  /** Response body fields which will be returned while fetching the list of countries. The DTO is in XML or .json format */
  country?: Country;
  /** Boolean flag if address is for shipping */
  shippingAddress?: boolean;
  /** Boolean flag if address is default */
  defaultAddress?: boolean;
  /** Boolean flag if address is visible in the Address Book */
  visibleInAddressBook?: boolean;
  /** Boolean flag if address is formatted */
  formattedAddress?: string;
  shipToCode?: string;
  restrictedAddress?: boolean;
  url?: string;
  street2?: string;
  street3?: string;
  street4?: string;
  street5?: string;
  regionCode?: string;
  billingAddress?: boolean;
  sapCustomerId?: string;
  payerAddress?: boolean;
  soldtoAddress?: boolean;
  defaultShippingAddress?: boolean;
  defaultBillingAddress?: boolean;
  defaultPayerAddress?: boolean;
  openingHours?: string;
  restrictedMasterShopIds?: string[];
  eusRequired?: boolean;
  exportBlocked?: boolean;
  timeZone?: string;
}

/** Address List */
export interface AddressList {
  /** @deprecated */
  addresses?: Address[];
  redAddresses?: Address[];
  blueAddresses?: Address[];
}

/** Airbus Account Statements */
export interface AirbusAccountStatements {
  requestId?: string;
  totalResultSize?: string;
  shownResultSize?: string;
  lineItems?: AirbusAccountStatementsLineItems[];
  summaries?: AirbusAccountStatementsSummaries[];
}

/** Airbus Account Statements line items */
export interface AirbusAccountStatementsLineItems {
  keyDate?: string;
  accountingDocumentNumber?: string;
  documentDate?: string;
  customerOrderNumber?: string;
  partNumber?: string;
  manufacturerCode?: string;
  quantity?: string;
  unit?: UnitData;
  amount?: PriceData;
  packingSheetNumber?: string;
  billOfLadingNumber?: string;
}

/** Details of the Airbus Account Statement request */
export interface AirbusAccountStatementsRequest {
  keyDate?: string;
  paginationDirection?: string;
  requestId?: string;
  /** @format int32 */
  pageSize?: number;
}

/** Airbus Account Statements Summaries */
export interface AirbusAccountStatementsSummaries {
  keyDate?: string;
  dueAmount?: PriceData;
  overdueAmount?: PriceData;
  totalAmount?: PriceData;
}

/** Airbus Box */
export interface AirbusBox {
  orderEntryNumber?: string;
  deliveryNumber?: string;
  deliveryItem?: string;
  boxKey?: string;
  internalHandlingUnitId?: string;
  externalHandlingUnitId?: string;
  trackingNumber?: string;
  quantity?: string;
  /** Airbus Box Dimensions */
  dimensions?: AirbusBoxDimensions;
  carrierDetails?: string;
  shipmentNumber?: string;
  forwarderTrackingId?: string;
  isCertificateAvailable?: boolean;
}

/** Airbus Box Dimensions */
export interface AirbusBoxDimensions {
  length?: string;
  width?: string;
  height?: string;
  dimensionUnit?: UnitData;
  totalWeight?: string;
  weightUnit?: UnitData;
}

export interface AirbusCertificateForSalesNew {
  code?: string;
  name?: string;
  formattedName?: string;
}

/** Airbus Cross Plant Material Status */
export interface AirbusCrossPlantMaterialStatus {
  code?: string;
  name?: string;
}

/** Airbus Customer Code Response */
export interface AirbusCustomerCodeResponse {
  customerCodes?: string[];
}

/** Airbus Delivery */
export interface AirbusDelivery {
  orderEntryNumber?: string;
  deliveryNumber?: string;
  deliveryItem?: string;
  deliveryDate?: string;
  deliveryStatus?: string;
  trackingNumber?: string;
  deliveredQuantity?: string;
  salesUnit?: UnitData;
  accountNumber?: string;
  containerCount?: string;
  containerWeight?: string;
  shipmentNumber?: string;
  carrierName?: string;
  carrierDetails?: string;
  airWayBillType?: string;
  shippedFromLocationCode?: string;
  shippedFromLocationName?: string;
  batchNumber?: string;
  boxes?: AirbusBox[];
  /** @deprecated */
  documents?: AirbusOrderDocuments[];
}

/** Invoice filters */
export interface AirbusDocumentsListRequest {
  /** @uniqueItems true */
  documentIds?: string[];
}

/** Airbus export sales orders request */
export interface AirbusExportSalesOrdersRequest {
  /** @uniqueItems true */
  orderNumbers?: string[];
}

/** Airbus Heavy Maintenance Form Info */
export interface AirbusHeavyMaintenanceFormInfo {
  aircraftTypes?: AircraftType[];
  checkTypes?: CheckType[];
  msnPrefixes?: MSNPrefix[];
  maintenanceStation?: string;
}

/** The heavy maintenance form to save. */
export interface AirbusHeavyMaintenanceFormRequest {
  additionalInformation?: string;
  aircraftTypeCode?: string;
  aircraftTail?: string;
  checkTypeCode?: string;
  email?: string;
  maintenanceStation?: string;
  msnPrefixCode?: string;
  msn?: string;
  name?: string;
  operator?: string;
  phoneNumber?: string;
  fax?: string;
  fromDate?: string;
  toDate?: string;
}

/** Airbus Invoice List */
export interface AirbusInvoiceListItem {
  invoice?: AirbusInvoiceListItem[];
}

/** Request body parameter (DTO in xml or json format) */
export interface AirbusInvoiceListRequest {
  billingDateFrom?: string;
  billingDateTo?: string;
  cpoNumber?: string;
  documentType?: 'INVOICE' | 'CREDIT_NOTE';
  materialNumber?: string[];
  salesOrderNumber?: string[];
  invoiceDocumentNumber?: string[];
}

/** Airbus Order Details */
export interface AirbusOrderDetails {
  sapOrderId?: string;
  purchaseOrderNumber?: string;
  customerIdentificationCode?: string;
  shipToCode?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:12Z"
   */
  orderCreatedDate?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:12Z"
   */
  expectedDeliveryDate?: string;
  partNumber?: string;
  cageCode?: string;
  materialNumberExternal?: string;
  priority?: string;
  quantity?: string;
  unit?: UnitData;
  /** Order Type */
  orderType?: OrderType;
  customInvoiceAvailable?: boolean;
  aircraftRegistrationNumber?: string;
  totalPrice?: PriceData;
  entries?: AirbusOrderDetailsEntry[];
  documents?: AirbusOrderDocuments[];
}

/** Airbus Order Details Entry */
export interface AirbusOrderDetailsEntry {
  orderEntryNumber?: string;
  partNumber?: string;
  cageCode?: string;
  materialNumberExternal?: string;
  quantity?: string;
  unit?: UnitData;
  priority?: string;
  shipToCode?: string;
  interchangeabilityAvailable?: boolean;
  name?: string;
  basePrice?: PriceData;
  rejectionCode?: string;
  itemCategory?: string;
  totalPrice?: PriceData;
  plannedShipments?: AirbusPlannedShipment[];
  deliveries?: AirbusDelivery[];
}

/** Airbus Order Documents */
export interface AirbusOrderDocuments {
  invoiceNumber?: string;
  orderEntryNumber?: string;
  deliveryNumber?: string;
  deliveryItem?: string;
  billingDate?: string;
  status?: string;
  invoicedQuantity?: string;
  salesUnit?: UnitData;
  shipmentNumber?: string;
  awbNumber?: string;
  shipmentCompletionDate?: string;
  transportationDetails?: string;
  totalPrice?: PriceData;
}

/** Airbus Planned Shipment */
export interface AirbusPlannedShipment {
  orderEntryNumber?: string;
  plannedShipmentNumber?: string;
  requestedDate?: string;
  plannedDeliveredQuantity?: string;
  confirmedQuantity?: string;
  salesUnit?: UnitData;
  plannedDeliveryDate?: string;
}

/** Airbus Quote Price Entry Response */
export interface AirbusQuotePriceEntryResponse {
  /** @format int32 */
  entryNumber?: number;
  unitPrice?: PriceData;
  netPrice?: PriceData;
  quantity?: string;
  priceUnit?: UnitData;
  salesUnit?: UnitData;
}

/** Details of the Airbus quote price request */
export interface AirbusQuotePriceRequest {
  quoteCode?: string;
  entryNumber?: string;
  /** @format int32 */
  quantity?: number;
}

/** Airbus Quote Price Response */
export interface AirbusQuotePriceResponse {
  totalPrice?: PriceData;
  entries?: AirbusQuotePriceEntryResponse[];
}

/** Details of the Airbus quote request for SALE_NEW procurement encapsulated in a DTO. */
export interface AirbusQuoteRequest {
  requestedQuantity?: string;
  productId?: string;
  remark?: string;
  poNumber?: string;
}

/** Airbus Repair Station */
export interface AirbusRepairStation {
  code?: string;
  description?: string;
}

/** Airbus Service Order */
export interface AirbusServiceOrder {
  purchaseOrderNumber?: string;
  airbusSapOrderId?: string;
  orderNumber?: string;
  cageCode?: string;
  partNumber?: string;
  productName?: string;
  serialNumber?: string;
  status?: string;
  statusDescription?: string;
  orderDate?: string;
  goodsReceiptDate?: string;
  customerApprovedDate?: string;
  quoteApprovedDate?: string;
  quoteProvidedDate?: string;
  shipOrScrapDate?: string;
  plannedEndDate?: string;
  airCraftRegistrationNumber?: string;
  shippingDetails?: string[];
  remarks?: string;
  leaseDate?: string;
  returnedDate?: string;
  leaseDuration?: string;
}

/** Airbus service orders search request */
export interface AirbusServiceOrderRequest {
  purchaseOrderNumber?: string;
  airbusSapOrderId?: string;
  orderNumber?: string;
  cageCode?: string;
  partNumber?: string;
  fromDate?: string;
  toDate?: string;
  airCraftRegistrationNumber?: string;
  status?: string;
}

/** Airbus Spec Order History Response */
export interface AirbusSpecOrderHistoryResponse {
  specHistoryData?: AirbusSpecOrderHistoryResponseItem[];
}

/** Airbus Spec Order History Item Response */
export interface AirbusSpecOrderHistoryResponseItem {
  /**
   * @format date-time
   * @example "2024-11-15T07:57:12Z"
   */
  creationTime?: string;
  type?: string;
  direction?: string;
  message?: string[];
}

/** Aircraft Type */
export interface AircraftType {
  code?: string;
  name?: string;
}

/** Applicability */
export interface Applicability {
  code?: string;
  name?: string;
}

export interface AtosMembership {
  membershipId?: string;
  member?: boolean;
  type?: string;
  typeVersion?: string;
}

/** Availability Info */
export interface AvailabilityInfo {
  /** @format int64 */
  quantity?: number;
  /** @format date */
  availabilityDate?: string;
  infoStatus?: string;
  /** Warehouse */
  warehouse?: Warehouse;
}

/** Request body parameter (DTO in xml or json format) */
export interface AvailabilityInfoRequest {
  productEntries?: AvailabilityInfoRequestEntry[];
}

/** Availability Info Request Entry */
export interface AvailabilityInfoRequestEntry {
  /** @format int64 */
  quantity?: number;
  id?: string;
  warehouseCode?: string;
}

export interface B2BCostCenter {
  originalCode?: string;
  name?: string;
  active?: string;
  code?: string;
  unit?: B2BUnit;
}

export interface B2BPaymentType {
  code?: string;
  displayName?: string;
}

/** B2B Unit Sales Organization */
export interface B2BUnitSalesOrganization {
  code?: string;
  name?: string;
}

export interface B2BUnit {
  uid?: string;
  name?: string;
  /** @deprecated */
  addresses?: Address[];
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  defaultShippingAddress?: Address;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  defaultBillingAddress?: Address;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  defaultSoldToAddress?: Address;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  defaultPayerAddress?: Address;
  shippingAddresses?: Address[];
  payerAddressIsSameAsBilling?: boolean;
  /** B2B Unit Sales Organization */
  b2BUnitSalesOrganization?: B2BUnitSalesOrganization;
  customerIdentificationCode?: string;
  parentUnitId?: string;
  companyRegistrationNumber?: string;
  companyTaxRegistrationNumber?: string;
  vatID?: string;
  eusRequired?: boolean;
  exportBlocked?: boolean;
  forwarderInformationRequired?: boolean;
  readOnlyAccount?: boolean;
  /** @deprecated */
  termsOfPaymentCode?: string;
  /** Terms Of Payment */
  termsOfPayment?: TermsOfPayment;
  /** Terms Of Delivery */
  deliveryTerms?: TermsOfDelivery;
  requiresCashInAdvance?: boolean;
  owningSystem?: string;
  accountGroup?: string;
  /** District */
  district?: District;
  /** Company Category */
  companyCategory?: CompanyCategory;
  directorAssigned?: string;
}

/** Representation of a Base Option */
export interface BaseOption {
  /** Variant type of base option */
  variantType?: string;
  /** List of all variant options */
  options?: VariantOption[];
  /** Representation of a Variant Option */
  selected?: VariantOption;
}

/** Request body parameter (DTO in xml or json format) */
export interface BillingDocumentInfoRequest {
  /** @format date */
  fromDate?: string;
  /** @format date */
  toDate?: string;
  excludeInvoices?: boolean;
  excludeCredits?: boolean;
  documentNumbers?: string[];
  poNumbers?: string[];
  orderNumbers?: string[];
  emailAddresses?: string[];
  materialNumberExternals?: string[];
}

/** Billing Document Info Response */
export interface BillingDocumentInfoResponse {
  documentNumber?: string;
  formattedDocumentNumber?: string;
  documentType?: string;
  documentTypeDescription?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:08Z"
   */
  createDate?: string;
  poNumber?: string;
  orderNumber?: string;
  formattedOrderNumber?: string;
  /** Representation of a Price */
  netAmount?: Price;
  /** Representation of a Price */
  vatAmount?: Price;
  /** Representation of a Price */
  totalAmount?: Price;
}

/** Billing Document Info Response List */
export interface BillingDocumentInfoResponseList {
  billingDocumentInfos?: BillingDocumentInfoResponse[];
}

/** Representation of a Breadcrumb */
export interface Breadcrumb {
  /** Code of the facet */
  facetCode?: string;
  /** Name of the facet */
  facetName?: string;
  /** Value code of the facet */
  facetValueCode?: string;
  /** Value name of the facet */
  facetValueName?: string;
  /** Representation of a Search State */
  removeQuery?: SearchState;
  /** Representation of a Search State */
  truncateQuery?: SearchState;
}

/** Representation of a Card Type */
export interface CardType {
  /** Card type code */
  code?: string;
  /** Card type name */
  name?: string;
}

/** Carrier */
export interface Carrier {
  code?: string;
  name?: string;
  preferred?: boolean;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:03Z"
   */
  lastUsedDate?: string;
  serviceLevelRequired?: boolean;
}

/** Carrier List */
export interface CarrierList {
  carriers?: Carrier[];
}

/** Representation of a Cart */
export interface Cart {
  /** Code number of order */
  code?: string;
  /** Flag stating iv value is net-value */
  net?: boolean;
  /** Representation of a Price */
  totalPriceWithTax?: Price;
  /** Representation of a Price */
  totalPrice?: Price;
  /** Representation of a Price */
  totalTax?: Price;
  /** Representation of a Price */
  subTotal?: Price;
  /** Representation of a Price */
  deliveryCost?: Price;
  /** List of order entries */
  entries?: OrderEntry[];
  /** List of entry groups */
  entryGroups?: EntryGroup[];
  /** @format int32 */
  totalItems?: number;
  /** Representation of a Delivery mode */
  deliveryMode?: DeliveryMode;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  deliveryAddress?: Address;
  /** Representation of a Payment Details */
  paymentInfo?: PaymentDetails;
  /** List of applied order promotions */
  appliedOrderPromotions?: PromotionResult[];
  /** List of applied product promotions */
  appliedProductPromotions?: PromotionResult[];
  /** Representation of a Price */
  productDiscounts?: Price;
  /** Representation of a Price */
  orderDiscounts?: Price;
  /** Representation of a Price */
  totalDiscounts?: Price;
  /** Site */
  site?: string;
  /** Store */
  store?: string;
  /** Guest user id identifier */
  guid?: string;
  /** Flag showing if order is calculated */
  calculated?: boolean;
  /** List of applied vouchers */
  appliedVouchers?: Voucher[];
  /** Representation of a Principal webservice DTO used for defining User data types */
  user?: Principal;
  /** List of pickup order entry group */
  pickupOrderGroups?: PickupOrderEntryGroup[];
  /** List of delivery order entries group */
  deliveryOrderGroups?: DeliveryOrderEntryGroup[];
  /**
   * Quantity of pickup items
   * @format int64
   */
  pickupItemsQuantity?: number;
  /**
   * Quantity of delivery items
   * @format int64
   */
  deliveryItemsQuantity?: number;
  /** Customer requested date for order retrieval */
  requestedRetrievalAt?: string;
  /** Tags for order */
  tags?: string[];
  /**
   * @format date-time
   * @example "2024-11-15T07:57:05Z"
   */
  modifiedTime?: string;
  unit?: B2BUnit;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:05Z"
   */
  creationTime?: string;
  projectID?: string;
  purchaseOrderNumber?: string;
  /** Priority information to put for master shop. */
  priorityInfo?: PriorityInfo;
  /** Terms Of Delivery */
  termsOfDelivery?: TermsOfDelivery;
  formattedCode?: string;
  userReference?: string;
  warehouseOrderEntryGroups?: WarehouseOrderEntryGroup[];
  /** @deprecated */
  termsOfPaymentCode?: string;
  /** @deprecated */
  termsOfPaymentDescription?: string;
  /** Terms Of Payment */
  termsOfPayment?: TermsOfPayment;
  shippingDetails?: string;
  /** Purchase Order Type */
  poType?: PurchaseOrderType;
  deliveryInfo?: string;
  originDisplay?: string;
  /** Forwarder Info */
  forwarderInfo?: ForwarderInfo;
  /** Master Shop */
  masterShop?: MasterShop;
  orderOrigin?: string;
  /**
   * Total unit count
   * @format int32
   */
  totalUnitCount?: number;
  /** List of potential order promotions for cart */
  potentialOrderPromotions?: PromotionResult[];
  /** List of potential product promotions for cart */
  potentialProductPromotions?: PromotionResult[];
  /** Name of the cart */
  name?: string;
  /** Description of the cart */
  description?: string;
  /**
   * Date of cart expiration time
   * @format date-time
   * @example "2024-11-15T07:57:05Z"
   */
  expirationTime?: string;
  /**
   * Date of saving cart
   * @format date-time
   * @example "2024-11-15T07:57:05Z"
   */
  saveTime?: string;
  /** Representation of a Principal webservice DTO used for defining User data types */
  savedBy?: Principal;
  /** Earliest possible retrieval date available for order */
  earliestRetrievalAt?: string;
  costCenter?: B2BCostCenter;
  paymentType?: B2BPaymentType;
  importStatus?: 'PROCESSING' | 'COMPLETED';
  cartChangedExternally?: boolean;
  containsNoPriceProduct?: boolean;
  containsKitProduct?: boolean;
  containsToolProduct?: boolean;
  masterShopInfos?: MasterShopInfo[];
  orderable?: boolean;
  removedCartEntries?: RemovedCartEntry[];
}

/** Cart Entry Pricing */
export interface CartEntryPricing {
  /** @format int32 */
  entryNumber?: number;
  owningSystem?: string;
  priceAvailable?: boolean;
  availabilityInfos?: AvailabilityInfo[];
  /** @format int64 */
  quantity?: number;
  /** Unit */
  salesUnit?: Unit;
  /** Unit */
  priceUnit?: Unit;
  /** Representation of a Price */
  basePrice?: Price;
  /** Representation of a Price */
  totalPrice?: Price;
  zproPriceAvailable?: boolean;
  /** @format int64 */
  priceUnitFactor?: number;
  errorMessage?: string;
}

/** Cart Entry Product To Add */
export interface CartEntryProductToAdd {
  code?: string;
}

/** Cart Entry To Add */
export interface CartEntryToAdd {
  id?: string;
  /** Cart Entry Product To Add */
  product?: CartEntryProductToAdd;
  /** @format int64 */
  quantity?: number;
  warehouseCode?: string;
  /** Cart Entry Warehouse To Add */
  warehouse?: CartEntryWarehouseToAdd;
}

/** Cart Entry Warehouse To Add */
export interface CartEntryWarehouseToAdd {
  code?: string;
}

/** Representation of a Cart list */
export interface CartList {
  /** List of carts */
  carts?: Cart[];
}

/** Representation of a Cart modification */
export interface CartModification {
  /** Status code of cart modification */
  statusCode?: string;
  /**
   * Quantity added with cart modification
   * @format int64
   */
  quantityAdded?: number;
  /**
   * Total number of products to be created, added or updated during a cart modification. This value is always the quantity that has been requested.
   * @format int64
   */
  quantity?: number;
  /** Request body parameter (DTO in xml or json format) which contains details like : quantity of product (quantity), pickup store name (deliveryPointOfService.name) */
  entry?: OrderEntry;
  /** Delivery mode changed */
  deliveryModeChanged?: boolean;
  /** Status message */
  statusMessage?: string;
  offerId?: string;
}

/** Representation of a Cart modification list */
export interface CartModificationList {
  /** List of cart modifications */
  cartModifications?: CartModification[];
}

/** Cart Pricing */
export interface CartPricing {
  code?: string;
  /** Representation of a Price */
  totalPrice?: Price;
  entries?: CartEntryPricing[];
  masterShopInfos?: MasterShopInfo[];
  errorMessage?: string;
}

/** Representation of a Category */
export interface Category {
  /** Code of the category */
  code?: string;
  /** Name of the category */
  name?: string;
  /** URL of the category */
  url?: string;
  /** Representation of an Image */
  image?: Image;
}

/** Certificate Type */
export interface CertificateType {
  code?: string;
  name?: string;
}

/** Change Value */
export interface ChangeValue {
  /**
   * @format date-time
   * @example "2024-11-15T07:57:11Z"
   */
  modificationTime?: string;
  modificationType?: string;
  attributeName?: string;
  oldValue?: string;
  newValue?: string;
  modifiedItemType?: string;
  modifiedItemId?: string;
  infoStatus?: string;
  friendlyName?: string;
}

/** Change Value List */
export interface ChangeValueList {
  changes?: ChangeValue[];
}

/** Check Type */
export interface CheckType {
  code?: string;
  name?: string;
}

/** Checkout Options */
export interface CheckoutOptions {
  orderTypes?: OrderTypeOption[];
}

/** Child Unit */
export interface ChildUnit {
  uid?: string;
  formattedUID?: string;
  owningSystem?: string;
  cic?: string;
}

/** Representation of a Classification */
export interface Classification {
  /** Code of the classification */
  code?: string;
  /** Name of the classification */
  name?: string;
  /** List of features for given classification */
  features?: Feature[];
}

/** Company Category */
export interface CompanyCategory {
  code?: string;
  name?: string;
}

/** Representation of a Configuration Info */
export interface ConfigurationInfo {
  /** Type of configuration info */
  configuratorType?: string;
  /** Status of configuration info */
  status?: string;
  /** Label of configuration info */
  configurationLabel?: string;
  /** Value of configuration info */
  configurationValue?: string;
}

/** Representation of a Consignment */
export interface Consignment {
  /** Consignment code */
  code?: string;
  /** Consignment tracking identifier */
  trackingID?: string;
  /** Consignment status */
  status?: string;
  /** Consignment status display */
  statusDisplay?: string;
  /**
   * Consignment status date
   * @format date-time
   * @example "2024-11-15T07:57:04Z"
   */
  statusDate?: string;
  /** List of consignment entries */
  entries?: ConsignmentEntry[];
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  shippingAddress?: Address;
  /** Representation of a Point of service */
  deliveryPointOfService?: PointOfService;
  /** Records of item arrivals facilitate the tracking and management of item quantities, associated arrival times, and certainty levels. These records distinguish between guaranteed, estimated, and unknown arrival scenarios. */
  arrivalSlot?: EntryArrivalSlot;
  trackingUrl?: string;
  infoStatus?: string;
  blueForwarderString?: string;
  /** Carrier */
  carrierDetails?: Carrier;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:04Z"
   */
  shippingDate?: string;
  /** Warehouse */
  warehouse?: Warehouse;
  documents?: Document[];
  containersInThisShipment?: Container[];
}

/** Representation of a Consignment Entry */
export interface ConsignmentEntry {
  /** Request body parameter (DTO in xml or json format) which contains details like : quantity of product (quantity), pickup store name (deliveryPointOfService.name) */
  orderEntry?: OrderEntry;
  /**
   * Quantity value of Consignment entry
   * @format int64
   */
  quantity?: number;
  /**
   * Shipped quantity
   * @format int64
   */
  shippedQuantity?: number;
  batchNumber?: string;
  exportLicense?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:04Z"
   */
  exportDate?: string;
  containersWithThisEntry?: Container[];
}

/** Container */
export interface Container {
  containerId?: string;
  /** @format int64 */
  quantity?: number;
  packageType?: string;
  weight?: string;
  /** Unit */
  weightUnit?: Unit;
  length?: string;
  width?: string;
  height?: string;
  /** Unit */
  dimensionsUnit?: Unit;
}

/** Correlation Id */
export interface CorrelationId {
  correlationId?: string;
}

/** Response body fields which will be returned while fetching the list of countries. The DTO is in XML or .json format */
export interface Country {
  /**
   * Country code in iso format
   * @example "US"
   */
  isocode?: string;
  /**
   * Name of the country
   * @example "United States"
   */
  name?: string;
}

/** Representation of a Currency */
export interface Currency {
  /** Code of the currency in iso format */
  isocode?: string;
  /** Name of the currency */
  name?: string;
  /** Boolean flag whether currency is active */
  active?: boolean;
  /** Symbol of the currency */
  symbol?: string;
}

/** Input containing a custom: forwarder name, forwarder account Number, additional info and localContactInfo */
export interface CustomForwarderInfo {
  forwarderName: string;
  forwarderAccountNumber: string;
  additionalInfo?: string;
  localContactInfo?: string;
}

/** Customer Profile Information */
export interface CustomerProfileInformation {
  firstName?: string;
  lastName?: string;
  email?: string;
  universalLogon?: string;
  airbusWorldUsernameLogin?: string;
}

/** Representation of a Delivery mode */
export interface DeliveryMode {
  /**
   * Code of the delivery mode
   * @example "premium-gross"
   */
  code?: string;
  /**
   * Name of the delivery mode
   * @example "Premium Delivery"
   */
  name?: string;
  /**
   * Description of the delivery mode
   * @example "1-2 business days"
   */
  description?: string;
  /** Representation of a Price */
  deliveryCost?: Price;
}

/** Representation of a Delivery Order Entry Group */
export interface DeliveryOrderEntryGroup {
  /** Representation of a Price */
  totalPriceWithTax?: Price;
  /** List of order entries */
  entries?: OrderEntry[];
  /**
   * Quantity of order entries in a group
   * @format int64
   */
  quantity?: number;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  deliveryAddress?: Address;
}

/** District */
export interface District {
  code?: string;
  name?: string;
}

/** Document */
export interface Document {
  code?: string;
  fileName?: string;
  type?: string;
}

/** Document Info */
export interface DocumentInfo {
  documentName?: string;
  documentID?: string;
}

/** Records of item arrivals facilitate the tracking and management of item quantities, associated arrival times, and certainty levels. These records distinguish between guaranteed, estimated, and unknown arrival scenarios. */
export interface EntryArrivalSlot {
  /**
   * Represents the number of items expected to arrive.
   * @format double
   * @example 12
   */
  quantity?: number;
  /**
   * The date associated with the expected arrival slot.
   * @format date-time
   * @example "2024-01-18T17:40:46+05:30"
   */
  at?: string;
  /**
   * The accuracy of the arrival slot as estimated, or unknown.
   * @example "ESTIMATED"
   */
  accuracy?: 'ESTIMATED' | 'UNKNOWN';
}

/** Representation of an Entry Group */
export interface EntryGroup {
  /** List of order entries */
  entries?: OrderEntry[];
  /** List of child entry groups */
  entryGroups?: EntryGroup[];
  /**
   * Identifier of the entry group
   * @format int32
   * @example 1
   */
  entryGroupNumber?: number;
  /**
   * Label for the entry group
   * @example "Photo On The Go Package"
   */
  label?: string;
  /**
   * Indicates if the entry group is in an error state
   * @example true
   */
  erroneous?: boolean;
  /**
   * Indicates type of the group, possible values are STANDALONE, CONFIGURABLEBUNDLE or any customer implemented type for any new provider
   * @example "STANDALONE"
   */
  type?: string;
}

/** The FHS quote information to be sent via email. */
export interface FHSQuoteEmailToAirbus {
  productId?: string;
  quotationReference?: string;
  requestedQuantity?: string;
  supplier?: string;
  location?: string;
  procurementType?: string;
  remarks?: string;
}

/** Representation of a Facet */
export interface Facet {
  /** Name of the facet */
  name?: string;
  /**
   * Priority value of the facet
   * @format int32
   */
  priority?: number;
  /** Flag stating if facet is category facet */
  category?: boolean;
  /** Flag stating if facet is multiSelect */
  multiSelect?: boolean;
  /** Flag stating if facet is visible */
  visible?: boolean;
  /** List of top facet values */
  topValues?: FacetValue[];
  /** List of all facet values */
  values?: FacetValue[];
}

/** Representation of a Facet Value */
export interface FacetValue {
  /** Name of the facet value */
  name?: string;
  /**
   * Count of the facet value
   * @format int64
   */
  count?: number;
  /** Representation of a Search State */
  query?: SearchState;
  /** Flag stating if facet value is selected */
  selected?: boolean;
  code?: string;
}

/** Representation of a Feature */
export interface Feature {
  /** Code of the feature */
  code?: string;
  /** Name of the feature */
  name?: string;
  /** Description of the feature */
  description?: string;
  /** Type of the feature */
  type?: string;
  /** Range number of the feature */
  range?: boolean;
  /** Flag defining it feature is comparable */
  comparable?: boolean;
  /** Representation of a Feature Unit */
  featureUnit?: FeatureUnit;
  /** List of feature values */
  featureValues?: FeatureValue[];
}

/** Feature Flag */
export interface FeatureFlag {
  name?: string;
  active?: boolean;
}

/** Representation of a Feature Unit */
export interface FeatureUnit {
  /** Symbol of the feature unit */
  symbol?: string;
  /** Name of the feature unit */
  name?: string;
  /** Type of the feature unit */
  unitType?: string;
}

/** Representation of a Feature Value */
export interface FeatureValue {
  /** Value of the feature */
  value?: string;
}

/** File */
export interface File {
  name?: string;
  content?: string;
  mimeType?: string;
}

/** Flight Hour Service */
export interface FlightHourService {
  supplier?: string;
  materialNumberExternal?: string;
  procurementType?: string;
  locations?: string[];
  remarks?: string;
}

/** Forgotten Password Change Object. */
export interface ForgottenPassword {
  token?: string;
  newPassword?: string;
}

/** Forwarder Info */
export interface ForwarderInfo {
  /** Carrier */
  carrier?: Carrier;
  additionalInfo?: string;
  forwarderName?: string;
  localContactInfo?: string;
  forwarderAccountNumber?: string;
  serviceLevelAgreements?: string;
}

/** Representation of a Future Stock */
export interface FutureStock {
  /** Representation of a Stock */
  stock?: Stock;
  /**
   * Date of future stock
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  date?: string;
  /**
   * Date of future stock expressed in text value
   * @example "31/12/2056"
   */
  formattedDate?: string;
}

/** Representation of a GeoPoint */
export interface GeoPoint {
  /**
   * Geopoint latitude
   * @format double
   */
  latitude?: number;
  /**
   * Geopoint longitude
   * @format double
   */
  longitude?: number;
}

/** Hazardous Material */
export interface HazardousMaterial {
  code?: string;
  name?: string;
}

/** Representation of an Image */
export interface Image {
  /** Type of the image, can be PRIMARY or GALLERY */
  imageType?: 'PRIMARY' | 'GALLERY';
  /** Format of the image, can be zoom, product, thumbnail, store, cartIcon, etc. */
  format?: string;
  /** URL address of the image */
  url?: string;
  /** Tooltip content which is visible while image mouse hovering */
  altText?: string;
  /**
   * Index of the image while displayed in gallery
   * @format int32
   */
  galleryIndex?: number;
  isMainImage?: boolean;
}

/** Request body parameter (DTO in xml or json format) which contains the file contents as Base64 as well as a filename */
export interface ImportProductList {
  name?: string;
  contentType?: string;
  content?: string;
}

/** Import Product List Status */
export interface ImportProductListStatus {
  /** Request body parameter (DTO in xml or json format) which contains details of the new product list */
  productList?: UserProductList;
  importStatus?: 'PROCESSING' | 'COMPLETED';
  errors?: string[];
}

/** Initial Provisioning Document */
export interface InitialProvisioningDocument {
  fileName?: string;
  fileExtension?: string;
  fileSize?: string;
  /** Initial Provisioning Document Category */
  category?: InitialProvisioningDocumentCategory;
  code?: string;
  modifiedDate?: string;
}

/** Initial Provisioning Document Category */
export interface InitialProvisioningDocumentCategory {
  name?: string;
  code?: string;
}

/** Initial Provisioning Document List */
export interface InitialProvisioningDocumentList {
  initialProvisioningDocumentsList?: InitialProvisioningDocument[];
}

/** Request body parameter (DTO in xml or json format) */
export interface InitialProvisioningDocumentListRequest {
  codes?: string[];
}

/** Interchangeability Info */
export interface InterchangeabilityInfo {
  /** Representation of a Product */
  originalProduct?: Product;
  interchangeabilityType?: string;
}

/** Interchangeable */
export interface Interchangeable {
  id?: string;
  partNumber?: string;
  cageCode?: string;
  /** @format int32 */
  interchangeabilityCode?: number;
  /** @format int32 */
  sortIndex?: number;
  leaderPart?: boolean;
}

/** Invoice Item */
export interface InvoiceItem {
  reference?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:08Z"
   */
  documentDate?: string;
  transaction?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:08Z"
   */
  dueDate?: string;
  invoiceNumber?: string;
  formattedInvoiceNumber?: string;
  /** Representation of a Price */
  amount?: Price;
}

/** Invoice Item List */
export interface InvoiceItemList {
  invoiceItems?: InvoiceItem[];
  startBalances?: Price[];
  endBalances?: Price[];
  totals?: Price[];
  /** @format int32 */
  totalCount?: number;
}

/** Request body parameter (DTO in xml or json format) */
export interface InvoiceItemsRequest {
  /** @format date */
  fromDate?: string;
  /** @format date */
  toDate?: string;
  /** @format int32 */
  count?: number;
  /** @format int32 */
  startIndex?: number;
}

/** Item Category */
export interface ItemCategory {
  code?: string;
  description?: string;
}

/** Representation of a Language */
export interface Language {
  /** iso code of the language */
  isocode?: string;
  /** name of the language */
  name?: string;
  /** name the language in native form */
  nativeName?: string;
  /** true/false indicator when the language is active */
  active?: boolean;
}

export interface LeaseDetail {
  numberOfDays?: string;
  percentagePerDay?: string;
  chargesPerDay?: string;
  subtotal?: string;
}

export interface LeaseInfo {
  loanUnitText?: string;
  unitPrice?: string;
  handlingFee?: string;
  currency?: string;
  minimumLease?: LeaseDetail;
  additionalLease?: LeaseDetail;
}

/** The request to place the order from */
export interface LillyOrderQuoteRequest {
  purchaseOrderNumber?: string;
}

/** Input containing a pair of part number and cage code to validate for Lilly */
export interface LillyProductSearchElement {
  partNumberPrefix?: string;
  cageCodePrefix?: string;
}

/** POJO containing the result page for product search. */
export interface LillyProductValidate {
  status?: string;
  /** Input containing a pair of part number and cage code to validate for Lilly */
  searchTerm?: LillyProductSearchElement;
  products?: Product[];
}

/** The result of a validation attempt. Contains a list of ProductValidateWsDTO objects. */
export interface LillyProductsValidationList {
  results?: LillyProductValidate[];
  additionalInfo?: AdditionalProductInfo[];
}

/** Linked Account */
export interface LinkedAccount {
  masterUnitUid?: string;
  masterUnitName?: string;
  readOnlyCustomer?: boolean;
  financeCustomer?: boolean;
  admin?: boolean;
  currentAccount?: boolean;
  redCustomer?: boolean;
  blueCustomer?: boolean;
  /** Child Unit */
  redChildUnit?: ChildUnit;
  /** Child Unit */
  blueChildUnit?: ChildUnit;
}

/** Linked Account List */
export interface LinkedAccountList {
  linkedAccounts?: LinkedAccount[];
}

/** Entries to add to cart. */
export interface ListOfCartEntriesToAdd {
  orderEntries?: CartEntryToAdd[];
}

/** Quote entry quantities */
export interface ListOfQuoteEntriesToAdd {
  quoteEntries?: QuoteEntryToAdd[];
}

/** Location Info */
export interface LocationInfo {
  /** @format int32 */
  sortIndex?: number;
  warehouseLocationInitials?: string;
  warehouseLocationDescription?: string;
  /** @format int32 */
  availableStock?: number;
}

/** Login Change Object. */
export interface LoginChange {
  newLogin?: string;
  password?: string;
}

/** MSN Prefix */
export interface MSNPrefix {
  code?: string;
  name?: string;
}

/** Manufacturer */
export interface Manufacturer {
  cageCode?: string;
  name?: string;
}

/** Master B2B Unit */
export interface MasterB2BUnit {
  uid?: string;
  name?: string;
  redB2BUnit?: B2BUnit;
  blueB2BUnit?: B2BUnit;
}

/** Master Shop */
export interface MasterShop {
  id?: string;
  name?: string;
  description?: string;
  /** @uniqueItems true */
  companyType?: string[];
  countryShippedFrom?: string;
  website?: string;
  companyDunsNumber?: string;
  paymentTerms?: string;
  logo?: string;
  banner?: string;
  searchResultLogo?: string;
  newSearchResultLogo?: string;
  shops?: Shop[];
  satairClassification?: string;
  satairClassificationDescription?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  satairClassificationExpirationDate?: string;
  registrationNumber?: string;
  taxIdNumber?: string;
  vatNumber?: string;
  reachCompliance?: string;
  rohsCompliance?: string;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  address?: Address;
  /** @uniqueItems true */
  companyOfficeDays?: string[];
  companyPublicHolidays?: string;
  minimumOrderValueUsd?: number;
  /** @uniqueItems true */
  priorityHandling?: OrderPriority[];
  returnPolicy?: string;
  timeZone?: string;
  /** @uniqueItems true */
  priorityFees?: PriorityFee[];
  blackListedProductsRecipientEmail?: string;
}

/** Master Shop Info */
export interface MasterShopInfo {
  masterShopId?: string;
  /** Forwarder Info */
  forwarder?: ForwarderInfo;
  /** Priority information to put for master shop. */
  priorityInfo?: PriorityInfo;
  /** @format date */
  namedDeliveryDate?: string;
  /** Representation of a Price */
  price?: Price;
  warehouseGroups?: number[];
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  shippingAddress?: Address;
  purchaseOrderNumber?: string;
  /** Order Type */
  orderType?: OrderType;
  masterShopLogo?: string;
  customerCode?: string;
}

export interface MaterialGroup {
  code?: string;
  name?: string;
}

/** Message */
export interface Message {
  /**
   * @format date-time
   * @example "2024-11-15T07:57:10Z"
   */
  createdDate?: string;
  authorName?: string;
  authorType?: string;
  body?: string;
  /** @deprecated */
  subject?: string;
  attachmentInfos?: MessageAttachmentInfo[];
}

/** Message Attachment Info */
export interface MessageAttachmentInfo {
  id?: string;
  name?: string;
  /** @format int64 */
  size?: number;
}

/** Request body parameter (DTO in xml or json format) which contains the file contents as Base64 as well as a filename */
export interface MessageRequest {
  subject?: string;
  body?: string;
  attachments?: MessageRequestAttachment[];
}

/** Message Request Attachment */
export interface MessageRequestAttachment {
  name?: string;
  content?: string;
}

/** Message Thread */
export interface MessageThread {
  threadId?: string;
  subject?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:10Z"
   */
  createdDate?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:10Z"
   */
  updatedDate?: string;
  messages?: Message[];
  /** @format int32 */
  totalMessageCount?: number;
  masterShopName?: string;
  locationDisplayName?: string;
}

/** Mini Cart */
export interface MiniCart {
  code?: string;
  /** @format int32 */
  entryCount?: number;
  entries?: MiniCartEntry[];
  /** @format int32 */
  warehouseGroupCount?: number;
  warehouseOrderEntryGroups?: MiniCartWarehouseOrderEntryGroup[];
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  deliveryAddress?: Address;
  masterShopInfos?: MasterShopInfo[];
}

/** Mini Cart Entry */
export interface MiniCartEntry {
  /** @format int32 */
  entryNumber?: number;
  /** @format int64 */
  quantity?: number;
  /** Mini Cart Entry Product */
  product?: MiniCartEntryProduct;
  offerInfo?: OfferInfo;
  /** Warehouse */
  warehouse?: Warehouse;
  orderableStatus?: string;
}

/** Mini Cart Entry Product */
export interface MiniCartEntryProduct {
  id?: string;
  /** @deprecated */
  code?: string;
  name?: string;
  manufacturerAid?: string;
  /** Manufacturer */
  manufacturerData?: Manufacturer;
  /** Certificate Type */
  certificateType?: CertificateType;
}

/** Mini Cart Warehouse Order Entry Group */
export interface MiniCartWarehouseOrderEntryGroup {
  /** Mini Cart Entry Product */
  product?: MiniCartEntryProduct;
  /** @format int64 */
  totalQuantity?: number;
  orderEntries?: number[];
  /** @format int32 */
  warehouseEntryNumber?: number;
  masterShopId?: string;
}

/** Offer */
export interface Offer {
  id?: string;
  /** Representation of a Product */
  product?: Product;
  /** @format int32 */
  quantity?: number;
  description?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  availableStartDate?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  availableEndDate?: string;
  /** @format int32 */
  leadTimeToShip?: number;
  /** @format int32 */
  maxOrderQuantity?: number;
  /** @format int32 */
  minOrderQuantity?: number;
  /** @format int32 */
  packageQuantity?: number;
  state?: string;
  /** @deprecated */
  shopCode?: string;
  /** @deprecated */
  shopName?: string;
  /** Shop */
  shop?: Shop;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  dateOfManufacture?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  expirationDate?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  combinedExpirationDate?: string;
  deliveryTerms?: string;
  visibleWear?: boolean;
  lastMaintenanceOrganisation?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  lastArcIssueDate?: string;
  serialNumber?: string;
  infoStatus?: string;
  mainPicture?: string;
  additionalPicture1?: string;
  additionalPicture2?: string;
  additionalPicture3?: string;
  document1Link?: string;
  document1Description?: string;
  document2Link?: string;
  document2Description?: string;
  document3Link?: string;
  document3Description?: string;
  document4Link?: string;
  document4Description?: string;
  warrantyType?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  warrantyExpirationDate?: string;
  timeRemaining?: number;
  cyclesRemaining?: number;
  timeSinceNew?: number;
  cyclesSinceNew?: number;
  traceType?: string;
  priceHidden?: boolean;
  allowQuoteRequests?: boolean;
  newSearchResultLogo?: string;
}

export interface OfferInfo {
  offerId?: string;
  shopId?: string;
  masterShopId?: string;
  shopName?: string;
  id?: string;
  locationDisplayName?: string;
  state?: string;
  /** @format int32 */
  packageQuantity?: number;
  /** @format int32 */
  minimumOrderQuantity?: number;
  /** Representation of a Price */
  originalPrice?: Price;
  /** Representation of a Price */
  discountPrice?: Price;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:03Z"
   */
  discountStartDate?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:03Z"
   */
  discountEndDate?: string;
  /** @format double */
  discountPercentage?: number;
  discountPercentageFormatted?: string;
}

export interface OfferList {
  offers?: Offer[];
}

/** Request body parameter (DTO in xml or json format) */
export interface OfferQuoteRequest {
  shippingAddressId?: string;
  message: string;
  subject: string;
  offerId: string;
  requestedQuantity?: string;
  offeredBasePrice?: string;
  /** @deprecated */
  referenceNumber?: string;
  purchaseOrderNumber?: string;
}

/** Offer Search */
export interface OfferSearch {
  code?: string;
  /** @uniqueItems true */
  certificates?: CertificateType[];
  airbusCertificateForSalesNew?: AirbusCertificateForSalesNew;
  offerId?: string;
  state?: string;
  materialNumberExternal?: string;
  name?: string;
  manufacturerAid?: string;
  manufacturerName?: string;
  masterShopId?: string;
  /** Master Shop */
  masterShop?: MasterShop;
  origin?: 'OPERATOR' | 'MARKETPLACE';
  cageCode?: string;
  /** Unit */
  salesUnit?: Unit;
  certificateType?: string;
  airbusProprietary?: boolean;
  isTool?: boolean;
  isKit?: boolean;
  /** @format int32 */
  crossPlantMaterialStatus?: number;
  blacklistedByCageCode?: boolean;
  /** @format double */
  quantityStep?: number;
  /** @format double */
  minimumOrderQuantity?: number;
  /** Hazardous Material */
  hazmat?: HazardousMaterial;
  /** Manufacturer */
  manufacturerData?: Manufacturer;
  id?: string;
  /** @deprecated */
  sellers?: string[];
  /** @uniqueItems true */
  unitConversions?: UnitConversion[];
  satairInterchangeables?: SatairInterchangeable[];
  partNumberInterchangeableMatch?: boolean;
  interchangeableMatchedPartNumber?: string;
  priceHidden?: boolean;
  allowQuoteRequests?: boolean;
  owningSystem?: 'RED' | 'MIRAKL' | 'BLUE' | 'LEGACY_BLUE' | 'INTERNAL';
  isAirbusHazmat?: boolean;
  flammable?: boolean;
  enginePart?: boolean;
  hasFHS?: boolean;
  serialNumberRequired?: boolean;
  mainPicture?: string;
}

/** POJO containing the result page for product offering search. */
export interface OfferSearchPage {
  freeTextSearch?: string;
  keywordRedirectUrl?: string;
  /** Representation of a Spell Checker Suggestion */
  spellingSuggestion?: SpellingSuggestion;
  offers?: OfferSearch[];
  sorts?: Sort[];
  /** Representation of a search results pagination */
  pagination?: DeprecatedPagination;
  /** Representation of a Search State */
  currentQuery?: SearchState;
  breadcrumbs?: Breadcrumb[];
  facets?: Facet[];
}

/** Request body parameter (DTO in xml or json format) */
export interface OpenItemsRequest {
  /** @format date */
  date?: string;
  /** @format int32 */
  count?: number;
  /** @format int32 */
  startIndex?: number;
  includeOnlyOverdueItems?: boolean;
}

/** Representation of an Opening schedule */
export interface OpeningSchedule {
  /** Name of the opening schedule */
  name?: string;
  /** Code of the opening schedule */
  code?: string;
  /** List of weekday opening days */
  weekDayOpeningList?: WeekdayOpeningDay[];
  /** List of special opening days */
  specialDayOpeningList?: SpecialOpeningDay[];
}

/** Request body parameter (DTO in xml or json format) which contains details like : tags */
export interface Order {
  /** Code number of order */
  code?: string;
  /** Flag stating iv value is net-value */
  net?: boolean;
  /** Representation of a Price */
  totalPriceWithTax?: Price;
  /** Representation of a Price */
  totalPrice?: Price;
  /** Representation of a Price */
  totalTax?: Price;
  /** Representation of a Price */
  subTotal?: Price;
  /** Representation of a Price */
  deliveryCost?: Price;
  /** List of order entries */
  entries?: OrderEntry[];
  /** List of entry groups */
  entryGroups?: EntryGroup[];
  /** @format int32 */
  totalItems?: number;
  /** Representation of a Delivery mode */
  deliveryMode?: DeliveryMode;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  deliveryAddress?: Address;
  /** Representation of a Payment Details */
  paymentInfo?: PaymentDetails;
  /** List of applied order promotions */
  appliedOrderPromotions?: PromotionResult[];
  /** List of applied product promotions */
  appliedProductPromotions?: PromotionResult[];
  /** Representation of a Price */
  productDiscounts?: Price;
  /** Representation of a Price */
  orderDiscounts?: Price;
  /** Representation of a Price */
  totalDiscounts?: Price;
  /** Site */
  site?: string;
  /** Store */
  store?: string;
  /** Guest user id identifier */
  guid?: string;
  /** Flag showing if order is calculated */
  calculated?: boolean;
  /** List of applied vouchers */
  appliedVouchers?: Voucher[];
  /** Representation of a Principal webservice DTO used for defining User data types */
  user?: Principal;
  /** List of pickup order entry group */
  pickupOrderGroups?: PickupOrderEntryGroup[];
  /** List of delivery order entries group */
  deliveryOrderGroups?: DeliveryOrderEntryGroup[];
  /**
   * Quantity of pickup items
   * @format int64
   */
  pickupItemsQuantity?: number;
  /**
   * Quantity of delivery items
   * @format int64
   */
  deliveryItemsQuantity?: number;
  /** Customer requested date for order retrieval */
  requestedRetrievalAt?: string;
  /** Tags for order */
  tags?: string[];
  /**
   * @format date-time
   * @example "2024-11-15T07:57:04Z"
   */
  modifiedTime?: string;
  unit?: B2BUnit;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:04Z"
   */
  creationTime?: string;
  projectID?: string;
  purchaseOrderNumber?: string;
  /** Priority information to put for master shop. */
  priorityInfo?: PriorityInfo;
  /** Terms Of Delivery */
  termsOfDelivery?: TermsOfDelivery;
  formattedCode?: string;
  userReference?: string;
  warehouseOrderEntryGroups?: WarehouseOrderEntryGroup[];
  /** @deprecated */
  termsOfPaymentCode?: string;
  /** @deprecated */
  termsOfPaymentDescription?: string;
  /** Terms Of Payment */
  termsOfPayment?: TermsOfPayment;
  shippingDetails?: string;
  /** Purchase Order Type */
  poType?: PurchaseOrderType;
  deliveryInfo?: string;
  originDisplay?: string;
  /** Forwarder Info */
  forwarderInfo?: ForwarderInfo;
  /** Master Shop */
  masterShop?: MasterShop;
  orderOrigin?: string;
  /**
   * Date of order creation
   * @format date-time
   * @example "2024-11-15T07:57:04Z"
   */
  created?: string;
  /** Status of order */
  status?: string;
  /** Status display */
  statusDisplay?: string;
  /** Flag showing if customer is Guest customer */
  guestCustomer?: boolean;
  /** List of consignment */
  consignments?: Consignment[];
  /** Order delivery status */
  deliveryStatus?: string;
  /** Order delivery status display */
  deliveryStatusDisplay?: string;
  /** List of unconsigned order entries */
  unconsignedEntries?: OrderEntry[];
  /**
   * Boolean flag showing if order is cancellable
   * @example true
   */
  cancellable?: boolean;
  /**
   * Boolean flag showing if order is returnable
   * @example true
   */
  returnable?: boolean;
  /** @format int32 */
  totalUnitCount?: number;
  vatID?: string;
  confirmedDeliveryDate?: string;
  quoteCode?: string;
  fees?: OrderFee[];
  /** @format date */
  namedDeliveryDate?: string;
  referencedQuotes?: RelatedQuote[];
  hasChanges?: boolean;
  minimumOrderValue?: number;
  placedBy?: string;
  eusRequiredOrExportBlocked?: boolean;
  /** Priority Fee */
  priorityFee?: PriorityFee;
  requiresCashInAdvance?: boolean;
  blockedPendingPayment?: boolean;
  owningSystem?: string;
  checkoutCartCode?: string;
  airbusSapOrderId?: string;
  airbusProcurementType?: string;
  /** Order Type */
  orderType?: OrderType;
  customerCode?: string;
  exportStatus?: 'NOTEXPORTED' | 'EXPORTED';
}

/** Order Document Info */
export interface OrderDocumentInfo {
  documentNumber?: string;
  documentCategory?: string;
  description?: string;
  referenceToBoxNumber?: string;
  referenceToBatch?: string;
  materialNumberExternal?: string;
}

/** Request body parameter (DTO in xml or json format) */
export interface OrderDocumentListRequest {
  requestEntries?: OrderDocumentRequest[];
}

/** Order Document Request */
export interface OrderDocumentRequest {
  docNum?: string;
  docCat?: string;
}

/** Request body parameter (DTO in xml or json format) which contains details like : quantity of product (quantity), pickup store name (deliveryPointOfService.name) */
export interface OrderEntry {
  /**
   * Entry number of the order entry
   * @format int32
   */
  entryNumber?: number;
  /**
   * Quantity number of items in order entry
   * @format int64
   */
  quantity?: number;
  /** Representation of a Price */
  basePrice?: Price;
  /** Representation of a Price */
  totalPrice?: Price;
  /** Representation of a Product */
  product?: Product;
  /** Flag defining if order entry item is updateable */
  updateable?: boolean;
  /** Representation of a Delivery mode */
  deliveryMode?: DeliveryMode;
  /** Configuration info of order entry */
  configurationInfos?: ConfigurationInfo[];
  /** List of aggregated status information per entry, relevant if the entry is configurable and its configuration contains one or many issues in different severities. Note that configurators typically raise such issues only in case the parent document is changeable. In this case the issues (depending on their severity) need to be fixed before a checkout can be done. This means this segment can be present for a cart entry, for order entries it will always be empty */
  statusSummaryList?: StatusSummary[];
  /** Representation of a Point of service */
  deliveryPointOfService?: PointOfService;
  /** Representation of a Price */
  cancelledItemsPrice?: Price;
  /**
   * Quantity number of cancellable items in order entry
   * @format int64
   * @example 5
   */
  cancellableQuantity?: number;
  /** Representation of a Price */
  returnedItemsPrice?: Price;
  /**
   * Quantity number of returnable items in order entry
   * @format int64
   * @example 5
   */
  returnableQuantity?: number;
  /** List of item arrival slot */
  arrivalSlots?: EntryArrivalSlot[];
  priceAvailable?: boolean;
  owningSystem?: string;
  eligibleForVolumePrices?: boolean;
  offerInfo?: OfferInfo;
  availabilityInfos?: AvailabilityInfo[];
  inStock?: boolean;
  zproPriceAvailable?: boolean;
  /** Priority information to put for master shop. */
  priorityInfo?: PriorityInfo;
  cancelReasonCode?: string;
  cancelReason?: string;
  changeReasons?: OrderEntryChangeReason[];
  /** Warehouse */
  warehouse?: Warehouse;
  /** Warehouse */
  defaultLocation?: Warehouse;
  sapWarehouseType?: string;
  plantsStockDetails?: PlantsStockDetail[];
  /** Unit */
  salesUnit?: Unit;
  /** Unit */
  priceUnit?: Unit;
  /** @format int64 */
  priceUnitFactor?: number;
  /** @deprecated */
  masterShopId?: string;
  /** Master Shop */
  masterShop?: MasterShop;
  /** @format int64 */
  remainingQuantity?: number;
  fees?: OrderFee[];
  infoStatus?: string;
  containsUnknownMaterial?: boolean;
  unknownPartNumber?: string;
  unknownCageCode?: string;
  unknownName?: string;
  productHasPrice?: boolean;
  /** Offer */
  offer?: Offer;
  /** Representation of a Price */
  offeredBasePrice?: Price;
  /** Representation of a Price */
  offeredTotalPrice?: Price;
  /** Terms Of Delivery */
  termsOfDelivery?: TermsOfDelivery;
  orderableStatus?: string;
  minimumOrderLineValue?: number;
  eusCleared?: boolean;
  exportBlockCleared?: boolean;
  airbusOrderItemStatus?: string;
  /** Item Category */
  airbusItemCategory?: ItemCategory;
  /** Interchangeability Info */
  airbusInterchangeabilityInfo?: InterchangeabilityInfo;
  airbusRemarks?: string;
}

/** Order Entry Change Reason */
export interface OrderEntryChangeReason {
  reason?: string;
  oldValue?: string;
  newValue?: string;
}

/** Representation of an Order entry list consumed */
export interface OrderEntryList {
  /** List of order entries */
  orderEntries?: OrderEntry[];
}

/** Order Fee */
export interface OrderFee {
  type?: string;
  label?: string;
  /** @format double */
  amount?: number;
  /** Representation of a Price */
  price?: Price;
}

/** The request to place the order from */
export interface OrderOfferQuoteRequest {
  purchaseOrderNumber?: string;
  projectID?: string;
  shippingAddressId?: string;
  /** @format date */
  namedDeliveryDate?: string;
  /** Priority information to put for master shop. */
  priorityInfo?: PriorityInfo;
  /** Input containing a custom: forwarder name, forwarder account Number, additional info and localContactInfo */
  customForwarderInfo?: CustomForwarderInfo;
  forwarderId?: string;
  forwarderAccountNumber?: string;
  serviceLevelAgreements?: string;
  forwarderInfo?: string;
  /** Representation of a Price */
  totalPrice?: Price;
  quoteEntries?: QuoteEntryToAdd[];
}

/** Order Priority */
export interface OrderPriority {
  name?: string;
  code: string;
}

/** Order Result */
export interface OrderResult {
  errorMessage?: string;
  masterShopId?: string;
  /** Request body parameter (DTO in xml or json format) which contains details like : tags */
  order?: Order;
}

/** Order Result List */
export interface OrderResultList {
  orderResults?: OrderResult[];
}

/** POJO containing the result page for order search. */
export interface OrderSearchPage {
  freeTextSearch?: string;
  /** Representation of a Spell Checker Suggestion */
  spellingSuggestion?: SpellingSuggestion;
  sorts?: Sort[];
  /** Representation of a search results pagination */
  pagination?: DeprecatedPagination;
  /** Representation of a Search State */
  currentQuery?: SearchState;
  breadcrumbs?: Breadcrumb[];
  facets?: Facet[];
  orders?: Order[];
}

/** Order Threads */
export interface OrderThreads {
  threads?: MessageThread[];
  orderCode?: string;
  /** Seek Pagination */
  seekPagination?: SeekPagination;
  masterShopName?: string;
  locationDisplayName?: string;
}

/** Order Type */
export interface OrderType {
  name?: string;
  code?: string;
}

/** Order Type Option */
export interface OrderTypeOption {
  code?: string;
  name?: string;
  applicableMastershops?: string[];
}

/** Part List Response */
export interface PartListResponse {
  partResponseEntries?: PartResponse[];
}

/** Part Response */
export interface PartResponse {
  productId?: string;
  masterShopId?: string;
  exclusionRemark?: string;
  /** Representation of a Product */
  productData?: Product;
  supplier?: string;
  approvedSuppliers?: Manufacturer[];
  procurements?: Procurement[];
  /** Product Rule */
  productRule?: ProductRule;
  interchangeables?: Interchangeable[];
  errorMessage?: string;
}

/** Password Change Object. */
export interface PasswordChange {
  oldPassword?: string;
  newPassword?: string;
}

/** Token to validate */
export interface PasswordResetToken {
  token?: string;
}

/** Representation of a Payment Details */
export interface PaymentDetails {
  /** Unique identifier of payment detail */
  id?: string;
  /** Name of account holder */
  accountHolderName?: string;
  /** Representation of a Card Type */
  cardType?: CardType;
  /** Payment card number */
  cardNumber?: string;
  /** Start month from which payment is valid */
  startMonth?: string;
  /** Start year from which payment is valid */
  startYear?: string;
  /** Month of expiration of payment */
  expiryMonth?: string;
  /** Year of expiration of payment */
  expiryYear?: string;
  /** Issue number */
  issueNumber?: string;
  /** Identifier of subscription */
  subscriptionId?: string;
  /** Flag to mark if payment is saved one */
  saved?: boolean;
  /** Flag to mark if payment the default one */
  defaultPayment?: boolean;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  billingAddress?: Address;
}

export interface PeriodicalCheck {
  code?: string;
  name?: string;
}

/** Representation of a Pickup Order Entry Group */
export interface PickupOrderEntryGroup {
  /** Representation of a Price */
  totalPriceWithTax?: Price;
  /** List of order entries */
  entries?: OrderEntry[];
  /**
   * Quantity of order entries in a group
   * @format int64
   */
  quantity?: number;
  /** Representation of a Point of service */
  deliveryPointOfService?: PointOfService;
  /**
   * Distance calculated to pickup place
   * @format double
   */
  distance?: number;
}

/** Plants Stock */
export interface PlantsStock {
  entries?: PlantsStockEntry[];
}

/** Plants Stock Detail */
export interface PlantsStockDetail {
  /** @format int64 */
  quantity?: number;
  /** Warehouse */
  warehouse?: Warehouse;
  /** Unit */
  unit?: Unit;
  inStock?: boolean;
}

/** Plants Stock Entry */
export interface PlantsStockEntry {
  id?: string;
  details?: PlantsStockDetail[];
  errorMessage?: string;
}

/** The codes of the products to return the plant stock information for. */
export interface PlantsStockInfoRequest {
  productEntries?: PlantsStockRequestEntry[];
}

/** Plants Stock Request Entry */
export interface PlantsStockRequestEntry {
  id?: string;
}

/** Representation of a Point of service */
export interface PointOfService {
  /**
   * Name of the point of service
   * @example "Misato"
   */
  name?: string;
  /** Display name of the point of service */
  displayName?: string;
  /** Url address of the point of service */
  url?: string;
  /** Description of the point of service */
  description?: string;
  /** Representation of an Opening schedule */
  openingHours?: OpeningSchedule;
  /** Store content of given point of service */
  storeContent?: string;
  /** List of features for a given point of service */
  features?: Record<string, string>;
  /** Representation of a GeoPoint */
  geoPoint?: GeoPoint;
  /** Distance to the point of service as text value */
  formattedDistance?: string;
  /**
   * Distance to the point of service as number value
   * @format double
   */
  distanceKm?: number;
  /** Representation of an Image */
  mapIcon?: Image;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  address?: Address;
  /** Collection of images associated with a point of service */
  storeImages?: Image[];
  /** Instructions used for picking up an order in store */
  pickUpInStoreInstructions?: string;
}

/** Representation of a Price */
export interface Price {
  /**
   * Currency iso format
   * @example "USD"
   */
  currencyIso?: string;
  /** Value of price in BigDecimal format */
  value?: number;
  /** Type of the price */
  priceType?: 'BUY' | 'FROM';
  /** Value of price formatted */
  formattedValue?: string;
  /**
   * Minimum quantity of the price value
   * @format int64
   */
  minQuantity?: number;
  /**
   * Maximum quantity of the price value
   * @format int64
   */
  maxQuantity?: number;
  formattedVolumeDiscountPercentage?: string;
  /** @format double */
  volumeDiscountPercentage?: number;
  infoStatus?: string;
}

/** Price Break Info */
export interface PriceBreakInfo {
  /**
   * @deprecated
   * @format int32
   */
  quantity?: number;
  /** @format int32 */
  minQuantity?: number;
  /** @format int32 */
  maxQuantity?: number;
  /** @format double */
  volumeDiscountPercentage?: number;
  formattedVolumeDiscountPercentage?: string;
  /** Representation of a Price */
  basePrice?: Price;
  /** Unit */
  priceUnit?: Unit;
  /** @format int64 */
  priceUnitFactor?: number;
}

export interface PriceData {
  currencyIso?: string;
  value?: number;
  priceType?: 'BUY' | 'FROM';
  formattedValue?: string;
  /** @format int64 */
  minQuantity?: number;
  /** @format int64 */
  maxQuantity?: number;
  formattedVolumeDiscountPercentage?: string;
  /** @format double */
  volumeDiscountPercentage?: number;
}

/** Request body parameter (DTO in xml or json format) */
export interface PriceInfoRequest {
  fullInterchangeables?: boolean;
  productEntries?: PriceInfoRequestEntry[];
}

/** Price Info Request Entry */
export interface PriceInfoRequestEntry {
  /** @format int64 */
  quantity?: number;
  id?: string;
}

/** Representation of a Price Range */
export interface PriceRange {
  /** Representation of a Price */
  maxPrice?: Price;
  /** Representation of a Price */
  minPrice?: Price;
}

/** Representation of a Principal webservice DTO used for defining User data types */
export interface Principal {
  /** Unique user identifier */
  uid?: string;
  /** Name of the user */
  name?: string;
}

/** Priority Fee */
export interface PriorityFee {
  /** Order Priority */
  priority?: OrderPriority;
  type?: string;
  /** @format double */
  value?: number;
  businessHours?: string;
}

/** Priority information to put for master shop. */
export interface PriorityInfo {
  /** Order Priority */
  priority: OrderPriority;
  airframe?: string;
  acType?: string;
  acReg?: string;
}

/** Procurement */
export interface Procurement {
  procurementType?: string;
  /** Product Rule */
  productRule?: ProductRule;
  /** Representation of a Price */
  basePrice?: Price;
  /** Representation of a Price */
  beforeDiscountPrice?: Price;
  priceBreakInfos?: PriceBreakInfo[];
  locationInfos?: LocationInfo[];
  documentInfos?: DocumentInfo[];
  discountPercentage?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:08Z"
   */
  discountEndDate?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:08Z"
   */
  priceExpirationDate?: string;
  /** @format int32 */
  sortIndex?: number;
  supplier?: string;
  /** Unit */
  priceUnit?: Unit;
  /** Unit */
  salesUnit?: Unit;
  warehouseLocationInitials?: string;
  warehouseLocationDescription?: string;
  /** @format int32 */
  availableStock?: number;
  /** @format int64 */
  priceUnitFactor?: number;
  eroPart?: boolean;
  serialNumber?: string;
  batchNumber?: string;
  equipmentNumber?: string;
  partStatusCode?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:08Z"
   */
  partExpirationDate?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:08Z"
   */
  shelfLifeExpirationDate?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:08Z"
   */
  manufactureDate?: string;
  sourceName?: string;
  certificateSource?: string;
  certificateTrackingNumber?: string;
  certificateType?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:08Z"
   */
  certificateDate?: string;
  /** Used Part Info */
  usedPartInfo?: UsedPartInfo;
  unknownDeliveryDate?: boolean;
  availabilityDate?: string;
  leaseInfo?: LeaseInfo;
}

/** Representation of a Product */
export interface Product {
  /** Code of the product */
  code?: string;
  /** Name of the product */
  name?: string;
  /** Url address of the product */
  url?: string;
  /** Description of the product */
  description?: string;
  /** Flag defining if product is purchasable */
  purchasable?: boolean;
  /** Representation of a Stock */
  stock?: Stock;
  /** List of future stocks */
  futureStocks?: FutureStock[];
  /** Flag defining if product is available for pickup */
  availableForPickup?: boolean;
  /**
   * Rating number of average value
   * @format double
   */
  averageRating?: number;
  /**
   * Number of reviews associated with the product
   * @format int32
   */
  numberOfReviews?: number;
  /** Product summary */
  summary?: string;
  /** Data of product manufacturer */
  manufacturer?: string;
  /** Variant type of the product */
  variantType?: string;
  /** Representation of a Price */
  price?: Price;
  /** Information about base product */
  baseProduct?: string;
  /** List of images linked to product */
  images?: Image[];
  /** List of categories product belongs to */
  categories?: Category[];
  /** List of reviews associated with the product */
  reviews?: Review[];
  /** List of classifications related to the product */
  classifications?: Classification[];
  /** List of potential promotions related to the product */
  potentialPromotions?: Promotion[];
  /** List of variant options related to the product */
  variantOptions?: VariantOption[];
  /** List of base options related to the product */
  baseOptions?: BaseOption[];
  /** Flag stating if volume price should be displayed */
  volumePricesFlag?: boolean;
  /** List of volume prices */
  volumePrices?: Price[];
  /** List of product references */
  productReferences?: ProductReference[];
  /** List of variant matrixes associated with the product */
  variantMatrix?: VariantMatrixElement[];
  /** Representation of a Price Range */
  priceRange?: PriceRange;
  /** Flag stating if product is multidimensional */
  multidimensional?: boolean;
  /** Configurator type related to the product */
  configuratorType?: string;
  /** Flag stating if product is configurable */
  configurable?: boolean;
  /**
   * Tags associated with the product
   * @uniqueItems true
   */
  tags?: string[];
  /**
   * Flag specifies whether product can be added to cart. When addToCartDisabled=true, the product is not allowed to be added into cart and the reason is explained through sapAddToCartDisabledMessage; when addToCartDisabled=false, the product is allowed to be added into cart.
   * @example true
   */
  sapAddToCartDisabled?: boolean;
  /**
   * Message shows why product can not be added to cart.
   * @example "'EF-S 60mm f/2.8 USM Macro' is not available with 'EOS450D + 18-55 IS Kit'"
   */
  sapAddToCartDisabledMessage?: string;
  manufacturerAid?: string;
  materialNumberExternal?: string;
  /** Hazardous Material */
  hazmat?: HazardousMaterial;
  /** @uniqueItems true */
  applicabilities?: Applicability[];
  /** @format double */
  length?: number;
  /** @format double */
  width?: number;
  /** @format double */
  height?: number;
  /** @format double */
  volume?: number;
  /** @format double */
  grossWeight?: number;
  /** @format double */
  netWeight?: number;
  /** @format double */
  quantityStep?: number;
  /** @format double */
  minimumOrderQuantity?: number;
  /** Unit */
  weightUnit?: Unit;
  /** Unit */
  volumeUnit?: Unit;
  /** Unit */
  dimensionsUnit?: Unit;
  crossPlantMaterialStatus?: string;
  /** Airbus Cross Plant Material Status */
  airbusCrossPlantMaterialStatus?: AirbusCrossPlantMaterialStatus;
  /** Manufacturer */
  manufacturerData?: Manufacturer;
  salesOrgData?: ProductSalesOrg[];
  offers?: Record<string, OfferList>;
  /** Product Rule */
  productRule?: ProductRule;
  /** Unit */
  salesUnit?: Unit;
  /** Certificate Type */
  certificateType?: CertificateType;
  airbusProprietary?: boolean;
  flammable?: boolean;
  enginePart?: boolean;
  /** @uniqueItems true */
  ataChapters?: ATAChapter[];
  applicability?: string;
  ataChapter?: string;
  /** @deprecated */
  sellers?: string[];
  origin?: 'OPERATOR' | 'MARKETPLACE';
  /** @format int64 */
  maxShelfLife?: number;
  lifeTimeIndicator?: string;
  /** @uniqueItems true */
  unitConversions?: UnitConversion[];
  id?: string;
  interchangeables?: Product[];
  satairInterchangeables?: SatairInterchangeable[];
  partNumberInterchangeableMatch?: boolean;
  function?: string;
  materialComposition?: string;
  nextHigherAssembly?: string;
  serialNumberRequired?: boolean;
  eccn?: string;
  colour?: string;
  cml?: string;
  /** @uniqueItems true */
  alternativePartNumbers?: string[];
  /** Response body fields which will be returned while fetching the list of countries. The DTO is in XML or .json format */
  manufacturingCountry?: Country;
  owningSystem?: string;
  isAirbusHazmat?: boolean;
  airbusMaterialGroup?: MaterialGroup;
  airbusSparePartClass?: SparePartClass;
  airbusPeriodicalCheck?: PeriodicalCheck;
  airbusShelfLife?: ShelfLife;
  airbusCertificateForSalesNew?: AirbusCertificateForSalesNew;
  airbusPeriodicalCheckInterval?: string;
  commodityCode?: string;
  newSearchResultLogo?: string;
  isKit?: boolean;
  isTool?: boolean;
  hasFHS?: boolean;
}

/** Product Additional Infos */
export interface ProductAdditionalInfos {
  /** @deprecated */
  seller?: string;
  /** @deprecated */
  sellerId?: string;
  /** Shop */
  shop?: Shop;
  inStock?: boolean;
  /** @format int64 */
  remainingOfferQuantity?: number;
  /** Warehouse */
  warehouse?: Warehouse;
  productAvailabilities?: AvailabilityInfo[];
  /** Product Rule */
  productRule?: ProductRule;
  /** Representation of a Price */
  price?: Price;
  /** Representation of a Price */
  basePrice?: Price;
  errorMessage?: string;
  sapWarehouseType?: string;
  /** Unit */
  priceUnit?: Unit;
  /** @format int64 */
  priceUnitFactor?: number;
  /** Unit */
  salesUnit?: Unit;
  zproPriceAvailable?: boolean;
  contractType?: string;
  infoStatus?: string;
  /** Representation of a Price */
  originalPrice?: Price;
  /** Representation of a Price */
  discountPrice?: Price;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  discountStartDate?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  discountEndDate?: string;
  /** @format double */
  discountPercentage?: number;
  discountPercentageFormatted?: string;
}

/** Product Availability Entry */
export interface ProductAvailabilityEntry {
  id?: string;
  /** @format int32 */
  quantity?: number;
  /** Product Rule */
  productRule?: ProductRule;
  /** Product Availability Info */
  productAvailabilityInfo?: ProductAvailabilityInfo;
  errorMessage?: string;
}

/** Product Availability Entry List */
export interface ProductAvailabilityEntryList {
  productAvailabilityEntries?: ProductAvailabilityEntry[];
}

/** Product Availability Info */
export interface ProductAvailabilityInfo {
  /** @deprecated */
  seller?: string;
  /** @deprecated */
  sellerId?: string;
  /** Shop */
  shop?: Shop;
  inStock?: boolean;
  /** Warehouse */
  warehouse?: Warehouse;
  productAvailabilities?: AvailabilityInfo[];
  sapWarehouseType?: string;
  errorMessage?: string;
}

/** Input containing product id and the requested quantity to validate for Satair Quotes */
export interface ProductEntry {
  id: string;
  requestedQuantity: string;
}

/** Product Flight Hour Services Details */
export interface ProductFlightHourServicesDetails {
  productId?: string;
  /** Master Shop */
  masterShop?: MasterShop;
  error?: string;
  fhsDetails?: FlightHourService[];
}

/** Request body parameter (DTO in xml or json format) */
export interface ProductInfoRequest {
  productEntries?: ProductInfoRequestEntry[];
  /**
   * @deprecated
   * @format date
   */
  requestedDeliveryDate?: string;
}

/** Product Info Request Entry */
export interface ProductInfoRequestEntry {
  /** @format int64 */
  quantity?: number;
  id?: string;
  warehouseCode?: string;
}

/** Product Price Entry */
export interface ProductPriceEntry {
  id?: string;
  /** @format int32 */
  quantity?: number;
  /** Product Rule */
  productRule?: ProductRule;
  /** Product Price Info */
  productPriceInfo?: ProductPriceInfo;
  errorMessage?: string;
}

/** Product Price Entry List */
export interface ProductPriceEntryList {
  productPriceEntries?: ProductPriceEntry[];
}

/** Product Price Info */
export interface ProductPriceInfo {
  /** @deprecated */
  seller?: string;
  /** @deprecated */
  sellerId?: string;
  /** Shop */
  shop?: Shop;
  /** Representation of a Price */
  price?: Price;
  /** Representation of a Price */
  basePrice?: Price;
  /** Unit */
  priceUnit?: Unit;
  /** @format int64 */
  priceUnitFactor?: number;
  /** Unit */
  salesUnit?: Unit;
  priceBreakInfos?: PriceBreakInfo[];
  errorMessage?: string;
  zproPriceAvailable?: boolean;
  infoStatus?: string;
  /** Representation of a Price */
  originalPrice?: Price;
  /** Representation of a Price */
  discountPrice?: Price;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:08Z"
   */
  discountStartDate?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:08Z"
   */
  discountEndDate?: string;
  /** @format double */
  discountPercentage?: number;
  discountPercentageFormatted?: string;
}

/** Representation of a Product Reference */
export interface ProductReference {
  /** Reference type */
  referenceType?: string;
  /** Reference description */
  description?: string;
  /**
   * Reference quantity
   * @format int32
   */
  quantity?: number;
  /** Representation of a Product */
  target?: Product;
  /** Flag stating if product reference is preselected */
  preselected?: boolean;
}

/** Product Rule */
export interface ProductRule {
  priceVisible?: string;
  stockVisible?: string;
  sellable?: string;
  quotable?: string;
}

/** Product Sales Org */
export interface ProductSalesOrg {
  salesOrganisation?: string;
  /** @format double */
  minOrderQuantity?: number;
  /** @format double */
  minDeliveryQuantity?: number;
  /** @format double */
  packageQuantity?: number;
}

/** POJO containing the result page for Satair product with statuses */
export interface ProductValidate {
  /** Input containing product id and the requested quantity to validate for Satair Quotes */
  product?: ProductEntry;
  errors?: string[];
  productHasPrice?: boolean;
}

/** Request body parameter (DTO in xml or json format) */
export interface ProductsFlightHourServicesDetailsRequest {
  productIds?: string[];
}

/** Products Flight Hour Services Details Response */
export interface ProductsFlightHourServicesDetailsResponse {
  results?: ProductFlightHourServicesDetails[];
}

/** The result of a validation attempt. Contains a list of ProductValidateWsDTO objects. */
export interface ProductsValidationList {
  results?: ProductValidate[];
  listValid?: boolean;
}

/** Representation of a Promotion */
export interface Promotion {
  /** Code of the promotion */
  code?: string;
  /** Promotion title */
  title?: string;
  /** Type of the promotion */
  promotionType?: string;
  /**
   * The initial date of the promotion
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  startDate?: string;
  /**
   * Last date of validity of the promotion
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  endDate?: string;
  /** Description of the promotion */
  description?: string;
  /** Message about promotion which is displayed when planning potential promotion. This field has higher priority over promotion description */
  couldFireMessages?: string[];
  /** Message fired while the promotion is active. This is info how much you will get when applying the promotion */
  firedMessages?: string[];
  /** Representation of an Image */
  productBanner?: Image;
  /** Boolean flag if promotion is enabled */
  enabled?: boolean;
  /**
   * Priority index as numeric value of the promotion. Higher number means higher priority
   * @format int32
   */
  priority?: number;
  /** Group of the promotion */
  promotionGroup?: string;
  /** List of promotion restrictions */
  restrictions?: PromotionRestriction[];
}

/** Representation of a Promotion order entry consumed */
export interface PromotionOrderEntryConsumed {
  /** Order entry code */
  code?: string;
  /**
   * Adjusted unit price for promotion order entry
   * @format double
   */
  adjustedUnitPrice?: number;
  /**
   * Order entry number
   * @format int32
   */
  orderEntryNumber?: number;
  /**
   * Quantity of promotion order entry
   * @format int64
   */
  quantity?: number;
}

/** Representation of a Promotion Restriction */
export interface PromotionRestriction {
  /** Type of the promotion restriction */
  restrictionType?: string;
  /** Description of the promotion restriction */
  description?: string;
}

/** Representation of a Promotion result */
export interface PromotionResult {
  /** Description of promotion result */
  description?: string;
  /** Representation of a Promotion */
  promotion?: Promotion;
  /** List of promotion order entries consumed */
  consumedEntries?: PromotionOrderEntryConsumed[];
}

/** Purchase Order Type */
export interface PurchaseOrderType {
  poType?: string;
  description?: string;
}

/** Quote */
export interface Quote {
  /** Code number of order */
  code?: string;
  /** Flag stating iv value is net-value */
  net?: boolean;
  /** Representation of a Price */
  totalPriceWithTax?: Price;
  /** Representation of a Price */
  totalPrice?: Price;
  /** Representation of a Price */
  totalTax?: Price;
  /** Representation of a Price */
  subTotal?: Price;
  /** Representation of a Price */
  deliveryCost?: Price;
  /** List of order entries */
  entries?: OrderEntry[];
  /** List of entry groups */
  entryGroups?: EntryGroup[];
  /** @format int32 */
  totalItems?: number;
  /** Representation of a Delivery mode */
  deliveryMode?: DeliveryMode;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  deliveryAddress?: Address;
  /** Representation of a Payment Details */
  paymentInfo?: PaymentDetails;
  /** List of applied order promotions */
  appliedOrderPromotions?: PromotionResult[];
  /** List of applied product promotions */
  appliedProductPromotions?: PromotionResult[];
  /** Representation of a Price */
  productDiscounts?: Price;
  /** Representation of a Price */
  orderDiscounts?: Price;
  /** Representation of a Price */
  totalDiscounts?: Price;
  /** Site */
  site?: string;
  /** Store */
  store?: string;
  /** Guest user id identifier */
  guid?: string;
  /** Flag showing if order is calculated */
  calculated?: boolean;
  /** List of applied vouchers */
  appliedVouchers?: Voucher[];
  /** Representation of a Principal webservice DTO used for defining User data types */
  user?: Principal;
  /** List of pickup order entry group */
  pickupOrderGroups?: PickupOrderEntryGroup[];
  /** List of delivery order entries group */
  deliveryOrderGroups?: DeliveryOrderEntryGroup[];
  /**
   * Quantity of pickup items
   * @format int64
   */
  pickupItemsQuantity?: number;
  /**
   * Quantity of delivery items
   * @format int64
   */
  deliveryItemsQuantity?: number;
  /** Customer requested date for order retrieval */
  requestedRetrievalAt?: string;
  /** Tags for order */
  tags?: string[];
  /**
   * @format date-time
   * @example "2024-11-15T07:57:03Z"
   */
  modifiedTime?: string;
  unit?: B2BUnit;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:03Z"
   */
  creationTime?: string;
  projectID?: string;
  purchaseOrderNumber?: string;
  /** Priority information to put for master shop. */
  priorityInfo?: PriorityInfo;
  /** Terms Of Delivery */
  termsOfDelivery?: TermsOfDelivery;
  formattedCode?: string;
  userReference?: string;
  warehouseOrderEntryGroups?: WarehouseOrderEntryGroup[];
  /** @deprecated */
  termsOfPaymentCode?: string;
  /** @deprecated */
  termsOfPaymentDescription?: string;
  /** Terms Of Payment */
  termsOfPayment?: TermsOfPayment;
  shippingDetails?: string;
  /** Purchase Order Type */
  poType?: PurchaseOrderType;
  deliveryInfo?: string;
  originDisplay?: string;
  /** Forwarder Info */
  forwarderInfo?: ForwarderInfo;
  /** Master Shop */
  masterShop?: MasterShop;
  orderOrigin?: string;
  /** @format int32 */
  version?: number;
  state?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:03Z"
   */
  updatedTime?: string;
  hasCart?: boolean;
  orderCode?: string;
  miraklQuoteRequestId?: string;
  /** Representation of a Price */
  previousEstimatedTotal?: Price;
  customerEmail?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:03Z"
   */
  validFrom?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:03Z"
   */
  quoteExpirationDate?: string;
  /** @format date */
  namedDeliveryDate?: string;
  sapQuoteId?: string;
  formattedSapQuoteId?: string;
  referencingOrders?: RelatedOrder[];
  /** @deprecated */
  referenceNumber?: string;
  quoteValidationStatus?: 'INVALID' | 'PENDING' | 'VALID';
  minimumOrderValue?: number;
  owningSystem?: string;
}

/** Quote Entry Prices */
export interface QuoteEntryPrices {
  /** @format int32 */
  entryNumber?: number;
  /** @format int64 */
  quantity?: number;
  /** Representation of a Price */
  totalPrice?: Price;
  /** Representation of a Price */
  basePrice?: Price;
  /** Unit */
  priceUnit?: Unit;
  /** @format int64 */
  priceUnitFactor?: number;
  /** Unit */
  salesUnit?: Unit;
}

/** Quote Entry To Add */
export interface QuoteEntryToAdd {
  /** @format int32 */
  entryNumber?: number;
  /** @format int64 */
  quantity?: number;
  /** Representation of a Price */
  totalPrice?: Price;
  /** Representation of a Price */
  basePrice?: Price;
}

/** Quote Message */
export interface QuoteMessage {
  id?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:10Z"
   */
  createdDate?: string;
  fromType?: string;
  toType?: string;
  body?: string;
  attachmentInfos?: MessageAttachmentInfo[];
}

/** List of part number and cage code combinations to contain in quote */
export interface QuoteRequest {
  customerEmail: string;
  reference?: string;
  poNumber?: string;
  shippingAddressId?: string;
  priority?: string;
  /** @format date */
  requestedDate?: string;
  quoteItems?: QuoteRequestItem[];
}

/** Quote Request Item */
export interface QuoteRequestItem {
  requestedQuantity: string;
  /** For Satair Quotes */
  id: string;
  /** For Lilly Quotes */
  materialNumberExternal: string;
  warehouseCode?: string;
  salesUnitCode?: string;
}

/** Quote Response */
export interface QuoteResponse {
  miraklQuoteResponseId?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:10Z"
   */
  expirationDate?: string;
  /** Representation of a Price */
  price?: Price;
  /** Representation of a Price */
  shippingPrice?: Price;
  /** Representation of a Price */
  totalPrice?: Price;
  state?: string;
  entries?: QuoteResponseEntry[];
}

/** Quote Response Entry */
export interface QuoteResponseEntry {
  miraklQuoteResponseLineId?: string;
  /** @format int64 */
  quantity?: number;
  /** Representation of a Price */
  price?: Price;
  /** Representation of a Price */
  totalPrice?: Price;
  /** Unit */
  priceUnit?: Unit;
}

/** Quote Responses */
export interface QuoteResponses {
  subject?: string;
  messages?: QuoteMessage[];
  quoteResponses?: QuoteResponse[];
  masterShopName?: string;
  locationDisplayName?: string;
}

/** POJO containing the result page for order search. */
export interface QuoteSearchPage {
  freeTextSearch?: string;
  /** Representation of a Spell Checker Suggestion */
  spellingSuggestion?: SpellingSuggestion;
  sorts?: Sort[];
  /** Representation of a search results pagination */
  pagination?: DeprecatedPagination;
  /** Representation of a Search State */
  currentQuery?: SearchState;
  breadcrumbs?: Breadcrumb[];
  facets?: Facet[];
  quotes?: Quote[];
}

/** Quote Totals */
export interface QuoteTotals {
  /** Representation of a Price */
  totalPrice?: Price;
  entries?: QuoteEntryPrices[];
}

/** Response body fields which will be returned while fetching the list of country's regions. */
export interface Region {
  /**
   * Country and Region code in iso format
   * @example "US-NY"
   */
  isocode?: string;
  /**
   * Region code in short iso form
   * @example "NY"
   */
  isocodeShort?: string;
  /**
   * Country code in iso format
   * @example "US"
   */
  countryIso?: string;
  /**
   * Name of the region
   * @example "New York"
   */
  name?: string;
}

/** Related Order */
export interface RelatedOrder {
  code?: string;
  purchaseOrderNumber?: string;
  /** @format double */
  totalPrice?: number;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:03Z"
   */
  creationTime?: string;
  /** Priority information to put for master shop. */
  priorityInfo?: PriorityInfo;
  statusDisplay?: string;
}

/** Related Quote */
export interface RelatedQuote {
  code?: string;
  formattedCode?: string;
  purchaseOrderNumber?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:04Z"
   */
  creationTime?: string;
  customerEmail?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:04Z"
   */
  quoteExpirationDate?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:04Z"
   */
  modifiedTime?: string;
  statusDisplay?: string;
  sapQuoteId?: string;
  formattedSapQuoteId?: string;
}

/** Removed Cart Entry */
export interface RemovedCartEntry {
  /** @format int32 */
  entryNumber?: number;
  partNumber?: string;
  cageCode?: string;
  offerId?: string;
  /** @format int64 */
  quantity?: number;
  removalReason?: string;
}

/** Representation of a Review */
export interface Review {
  /** Identifier of review */
  id?: string;
  /** Review headline */
  headline?: string;
  /** Review comment */
  comment?: string;
  /**
   * Review rating value
   * @format double
   */
  rating?: number;
  /**
   * Date of the review
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  date?: string;
  /** Alias name for the review */
  alias?: string;
  /** Representation of a User */
  principal?: User;
}

/** Satair Interchangeable */
export interface SatairInterchangeable {
  id?: string;
  partNumber?: string;
  cageCode?: string;
  referenceType?:
    | 'ACCESSORIES'
    | 'ONE_WAY_INTERCHANGEABLE'
    | 'BASE_PRODUCT'
    | 'TWO_WAY_INTERCHANGEABLE'
    | 'CONSISTS_OF'
    | 'DIFF_ORDERUNIT'
    | 'FOLLOWUP'
    | 'MANDATORY'
    | 'SIMILAR'
    | 'SELECT'
    | 'SPAREPART'
    | 'OTHERS'
    | 'UPSELLING'
    | 'CROSSELLING';
}

/** Representation of a Search Query */
export interface SearchQuery {
  /** Value of search query */
  value?: string;
}

/** Representation of a Search State */
export interface SearchState {
  /** Url address of search state */
  url?: string;
  /** Representation of a Search Query */
  query?: SearchQuery;
}

/** Seek Pagination */
export interface SeekPagination {
  nextPageToken?: string;
  previousPageToken?: string;
}

/** The required information to send an email regarding quote creation. */
export interface SendQuoteEmailToAirbus {
  materialNumberExternal?: string;
  locationInfo?: string;
  customerName?: string;
  customerEmail?: string;
  companyData?: string;
  quantity?: string;
  productId?: string;
  purchaseOrderNumber?: string;
  procurementType?: string;
  serialNumber?: string;
  message?: string;
  fromDate?: string;
  toDate?: string;
  shipToAddress?: string;
  flightHours?: string;
  acReg?: string;
  removalDate?: string;
  removalReason?: string;
  priority?: string;
  /** File */
  removalComponentTechnicalDocumentSheet?: File;
  /** @uniqueItems true */
  serviceNeeded?: string[];
  preferredRepairStation?: string;
  certificateRequiredAfterRepair?: string;
}

/** Send Quote Email To Airbus Response */
export interface SendQuoteEmailToAirbusResponse {
  /** @format int64 */
  emailMessageId?: number;
}

export interface ShelfLife {
  code?: string;
  name?: string;
}

/** Shop */
export interface Shop {
  id?: string;
  name?: string;
  /** Master Shop */
  masterShop?: MasterShop;
  locationDisplayName?: string;
  /** Terms Of Delivery */
  termsOfDelivery?: TermsOfDelivery;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  customerServiceContact?: Address;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  financeContact?: Address;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  warrantyOrClaimsContact?: Address;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  aogSupportContact?: Address;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  shipFromAddress?: Address;
  leadShop?: boolean;
  authorisations?: ShopAuthorisation[];
  otherAuthorisations?: boolean;
  otherAuthorisationsDetails?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  otherAuthorisationsExpirationDate?: string;
  restrictedCountries?: Country[];
}

/** Shop Authorisation */
export interface ShopAuthorisation {
  label?: string;
  status?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  expirationDate?: string;
}

/** Shop Document Info */
export interface ShopDocumentInfo {
  id?: string;
  name?: string;
  type?: string;
}

/** Representation a Sort option */
export interface Sort {
  /** Code of Sort */
  code?: string;
  /** Name of Sort */
  name?: string;
  /** Flag stating when Sort is selected */
  selected?: boolean;
}

export interface SparePartClass {
  code?: string;
  name?: string;
}

/** Representation of a special opening day */
export interface SpecialOpeningDay {
  /** Representation of a Time */
  openingTime?: Time;
  /** Representation of a Time */
  closingTime?: Time;
  /**
   * Date of special opening day
   * @format date-time
   * @example "2024-11-15T07:57:03Z"
   */
  date?: string;
  /** Text representation of the date of special opening day */
  formattedDate?: string;
  /** Flag stating if special opening day is closed */
  closed?: boolean;
  /** Name of the special opening day event */
  name?: string;
  /** Comment field */
  comment?: string;
}

/** Representation of a Spell Checker Suggestion */
export interface SpellingSuggestion {
  /** Spelling suggestion */
  suggestion?: string;
  /** Query for spelling suggestion */
  query?: string;
}

/** Representation of a status summary, an aggregated view on issues for a specific status or severity. These issues are attached to configurations of products or order entries */
export interface StatusSummary {
  /**
   * Status or severity indicator, can be one of ERROR, WARNING, INFO or SUCCESS
   * @example "ERROR"
   */
  status?: string;
  /**
   * Number of issues per status
   * @format int32
   * @example 3
   */
  numberOfIssues?: number;
}

/** Representation of a Stock */
export interface Stock {
  /**
   * Status of stock level
   * @example "inStock"
   */
  stockLevelStatus?: string;
  /**
   * Stock level expressed as number
   * @format int64
   * @example 25
   */
  stockLevel?: number;
  /**
   * Indicate whether Stock level value is rounded
   * @example false
   */
  isValueRounded?: boolean;
}

/** Tags List */
export interface TagsList {
  tags?: string[];
}

/** Terms Of Delivery */
export interface TermsOfDelivery {
  name?: string;
  code?: string;
}

/** Terms Of Payment */
export interface TermsOfPayment {
  code?: string;
  description?: string;
}

/** Thread Created */
export interface ThreadCreated {
  messageId?: string;
  threadId?: string;
}

/** Representation of a Time */
export interface Time {
  /**
   * Hour part of the time data
   * @format byte
   */
  hour?: string;
  /**
   * Minute part of the time data
   * @format byte
   */
  minute?: string;
  /** Formatted hour */
  formattedHour?: string;
}

/** Unit */
export interface Unit {
  code?: string;
  sapCode?: string;
  name?: string;
  infoStatus?: string;
}

/** Unit Conversion */
export interface UnitConversion {
  /** Unit */
  unit?: Unit;
  /** Unit */
  baseUnit?: Unit;
  /** @format int64 */
  unitQuantity?: number;
  /** @format int64 */
  baseUnitQuantity?: number;
  isSalesUnitConversion?: boolean;
}

export interface UnitData {
  code?: string;
  name?: string;
  unitType?: string;
  sapCode?: string;
}

/** Used Part Info */
export interface UsedPartInfo {
  totalCycleReference?: string;
  /** @format int32 */
  operatingTime?: number;
  /** @format int32 */
  operatingCycleCount?: number;
}

/** Representation of a User */
export interface User {
  /** Unique user identifier */
  uid?: string;
  /** Name of the user */
  name?: string;
  /** Request body fields required and optional to operate on address data. The DTO is in XML or .json format */
  defaultAddress?: Address;
  /** User title code */
  titleCode?: string;
  /** User title */
  title?: string;
  /** User first name */
  firstName?: string;
  /** User last name */
  lastName?: string;
  /** Representation of a Currency */
  currency?: Currency;
  /** Representation of a Language */
  language?: Language;
  /** User identifier */
  displayUid?: string;
  /** Customer identifier */
  customerId?: string;
  /**
   * Deactivation date
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  deactivationDate?: string;
  /**
   * Name of the default pick up location
   * @example "Misato"
   */
  defaultPointOfServiceName?: string;
  roles?: string[];
  defaultUnitUid?: string;
  contactNumber?: string;
  countryCode?: string;
  timeZone?: string;
  primaryWorkArea?: string;
  loginDisabled?: boolean;
  globalId?: string;
  confirmedInformation?: boolean;
  admin?: boolean;
  active?: boolean;
  readOnly?: boolean;
  financeCustomer?: boolean;
  inAccount?: boolean;
  marketplaceEnabled?: boolean;
  betaCustomer?: boolean;
  redCustomer?: boolean;
  blueCustomer?: boolean;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  activationDate?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:02Z"
   */
  lastLogin?: string;
  provisionedByAirbusWorld?: boolean;
  email?: string;
  airbusMSSOUsername?: string;
  atosDAHUserId?: string;
  dahMemberships?: AtosMembership[];
}

/** User Login Type */
export interface UserLoginType {
  type?: 'AIRBUS_WORLD_SINGLE_SIGN_ON_USER' | 'LOCAL_USER' | 'UNKNOWN_USER' | 'MULTIPLE_USERS';
}

/** Request body parameter (DTO in xml or json format) which contains details of the new product list */
export interface UserProductList {
  code?: string;
  name?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:03Z"
   */
  creationDate?: string;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:03Z"
   */
  lastModifiedDate?: string;
  /** Representation of a User */
  createdBy?: User;
  /** @format int32 */
  numberOfEntries?: number;
  entries?: UserProductListEntry[];
  productListType?: string;
  unresolvedEntries?: UserUnresolvedProductListEntry[];
  errors?: string[];
}

export interface UserProductListEntry {
  /** Offer */
  offer?: Offer;
  /** Representation of a Product */
  product?: Product;
  id?: string;
  /** @format int64 */
  quantity?: number;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:03Z"
   */
  addedDate?: string;
}

/** POJO containing the result page for product list search. */
export interface UserProductListSearchPage {
  freeTextSearch?: string;
  categoryCode?: string;
  keywordRedirectUrl?: string;
  /** Representation of a Spell Checker Suggestion */
  spellingSuggestion?: SpellingSuggestion;
  productLists?: UserProductList[];
  sorts?: Sort[];
  /** Representation of a search results pagination */
  pagination?: DeprecatedPagination;
  /** Representation of a Search State */
  currentQuery?: SearchState;
  breadcrumbs?: Breadcrumb[];
  facets?: Facet[];
}

/** POJO containing the result page for user search. */
export interface UserSearchPage {
  freeTextSearch?: string;
  categoryCode?: string;
  keywordRedirectUrl?: string;
  /** Representation of a Spell Checker Suggestion */
  spellingSuggestion?: SpellingSuggestion;
  results?: User[];
  sorts?: Sort[];
  /** Representation of a search results pagination */
  pagination?: DeprecatedPagination;
  /** Representation of a Search State */
  currentQuery?: SearchState;
  breadcrumbs?: Breadcrumb[];
  facets?: Facet[];
}

/** User's object. */
export interface UserSignUp {
  /**
   * user id, unique string required to create new user. It can be email
   * @example "akiro.nakamura@rustic-hw.com"
   */
  uid: string;
  /**
   * first name of the user
   * @example "Arika"
   */
  firstName: string;
  /**
   * last name of the user
   * @example "Nakamura"
   */
  lastName: string;
  /** @example "mr" */
  titleCode?: string;
  /** user password */
  password: string;
  contactNumber?: string;
  countryCode?: string;
  primaryWorkArea?: string;
  timeZone?: string;
}

export interface UserUnresolvedProductListEntry {
  partNumber?: string;
  cageCode?: string;
  /** @format int64 */
  quantity?: number;
  /**
   * @format date-time
   * @example "2024-11-15T07:57:03Z"
   */
  addedDate?: string;
  reason?: string;
}

/** User's object. */
export interface UserUpdate {
  firstName?: string;
  lastName?: string;
  titleCode?: string;
  countryCode?: string;
  contactNumber?: string;
  primaryWorkArea?: string;
  timeZone?: string;
  confirmedInformation?: string;
}

/** Representation of a Variant Category */
export interface VariantCategory {
  /** Variant category name */
  name?: string;
  /** Flag if varian category has image assigned */
  hasImage?: boolean;
  /**
   * Priority number of variant category
   * @format int32
   */
  priority?: number;
}

/** Representation of a Variant Matrix Element */
export interface VariantMatrixElement {
  /** Representation of a Variant Value Category */
  variantValueCategory?: VariantValueCategory;
  /** Representation of a Variant Category */
  parentVariantCategory?: VariantCategory;
  /** Representation of a Variant Option */
  variantOption?: VariantOption;
  /** List of elements with the type of variant matrix element */
  elements?: VariantMatrixElement[];
  isLeaf?: boolean;
}

/** Representation of a Variant Option */
export interface VariantOption {
  /** Code of the variant option */
  code?: string;
  /** Representation of a Stock */
  stock?: Stock;
  /** Url address of the variant option */
  url?: string;
  /** Representation of a Price */
  priceData?: Price;
  /** List of variant option qualifiers */
  variantOptionQualifiers?: VariantOptionQualifier[];
}

/** Representation of a Variant Option Qualifier */
export interface VariantOptionQualifier {
  /** Qualifier */
  qualifier?: string;
  /** Name of variant option qualifier */
  name?: string;
  /** Value of variant option qualifier */
  value?: string;
  /** Representation of an Image */
  image?: Image;
}

/** Representation of a Variant Value Category */
export interface VariantValueCategory {
  /** Name of the variant value category */
  name?: string;
  /**
   * Sequence number of variant value category
   * @format int32
   */
  sequence?: number;
  /** Parent category of variant value category */
  superCategories?: VariantCategory[];
}

/** Representation of a Voucher */
export interface Voucher {
  /** The identifier of the Voucher. This is the first part of voucher code which holds first 3 letters, like: 123 */
  code?: string;
  /** Voucher code, is the holder for keeping specific occasional voucher related to business usage. It can be generated and looks like: 123-H8BC-Y3D5-34AL */
  voucherCode?: string;
  /** Name of the voucher */
  name?: string;
  /** Description of the voucher */
  description?: string;
  /**
   * Value of the voucher. Example of such value is: 15.0d
   * @format double
   */
  value?: number;
  /** Formatted value of the voucher */
  valueFormatted?: string;
  /** The value of the voucher to display. Example: 15.0% */
  valueString?: string;
  /** Specifies if the order this voucher is applied to is shipped for free (true) or not (false). Defaults to false. */
  freeShipping?: boolean;
  /** Representation of a Currency */
  currency?: Currency;
  /** Representation of a Price */
  appliedValue?: Price;
}

/** Warehouse */
export interface Warehouse {
  code?: string;
  name?: string;
}

/** Warehouse Order Entry Group */
export interface WarehouseOrderEntryGroup {
  /** Representation of a Product */
  product?: Product;
  /** @format int64 */
  totalQuantity?: number;
  /** Representation of a Price */
  totalPrice?: Price;
  /** Representation of a Price */
  basePrice?: Price;
  orderEntries?: number[];
  /** Unit */
  salesUnit?: Unit;
  /** @deprecated */
  sellerId?: string;
  masterShopId?: string;
  /** @format int32 */
  warehouseEntryNumber?: number;
}

/** Representation of a Weekday Opening Day */
export interface WeekdayOpeningDay {
  /** Representation of a Time */
  openingTime?: Time;
  /** Representation of a Time */
  closingTime?: Time;
  /** Text representation of week day opening day */
  weekDay?: string;
  /** Flag stating if weekday opening day is closed */
  closed?: boolean;
}

/** Representation of a search results pagination */
export interface DeprecatedPagination {
  /**
   * The number of results per page. A page may have less results if there are less than a full page of results, only on the last page in the results
   * @format int32
   */
  pageSize?: number;
  /**
   * The current page number. The first page is number zero (0), the second page is number one (1), and so on
   * @format int32
   */
  currentPage?: number;
  /** The selected sort code */
  sort?: string;
  /**
   * The total number of pages. This is the number of pages, each of pageSize, required to display the totalResults.
   * @format int32
   */
  totalPages?: number;
  /**
   * The total number of matched results across all pages
   * @format int64
   */
  totalResults?: number;
}

/** Error message */
export interface Error {
  /** Type of the error e.g. 'LowStockError'. */
  type?: string;
  /** Additional classification specific for each error type e.g. 'noStock'. */
  reason?: string;
  /** Descriptive, human readable error message. */
  message?: string;
  /** Type of the object related to the error e.g. 'entry'. */
  subjectType?: string;
  /** Identifier of the related object e.g. '1'. */
  subject?: string;
  /** Error code */
  errorCode?: string;
}

/** List of errors */
export interface ErrorList {
  errors?: Error[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'https://sapphire-daily.isbc.link/occ/v2';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string' ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
        body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
      },
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Commerce Webservices
 * @version 2.2.1
 * @license Use of this file is subject to the terms of your agreement with SAP SE or its affiliates respecting the use of the SAP product for which this file relates.
 * @baseUrl https://sapphire-daily.isbc.link/occ/v2
 * @externalDocs https://www.sap.com
 *
 * These services manage all of the common commerce functionality, and also include customizations from installed AddOns. The implementing extension is called commercewebservices.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  baseSiteId = {
    /**
     * @description Sends an email with FHS quote information to the configured recipient. Only available for 'Blue' users.
     *
     * @tags Airbus Quotes
     * @name SendFhsQuoteEmail
     * @summary Send FHS Quote Email
     * @request POST:/{baseSiteId}/airbus/quotes/fhs/sendemail
     * @secure
     */
    sendFhsQuoteEmail: (
      baseSiteId: string,
      data: FHSQuoteEmailToAirbus,
      params: RequestParams = {},
    ) =>
      this.request<SendQuoteEmailToAirbusResponse, ErrorList>({
        path: `/${baseSiteId}/airbus/quotes/fhs/sendemail`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description sends an email to Airbus with quote information for procurements
     *
     * @tags Airbus Quotes
     * @name SendNonNewQuoteEmailToAirbusCustomerOrderFulfillment
     * @summary Send Airbus Quote information
     * @request POST:/{baseSiteId}/airbus/quotes/nonsalenew/sendemail
     * @secure
     */
    sendNonNewQuoteEmailToAirbusCustomerOrderFulfillment: (
      baseSiteId: string,
      data: SendQuoteEmailToAirbus,
      params: RequestParams = {},
    ) =>
      this.request<SendQuoteEmailToAirbusResponse, ErrorList>({
        path: `/${baseSiteId}/airbus/quotes/nonsalenew/sendemail`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Airbus Quotes
     * @name GetAirbusQuotePrice
     * @summary Get Airbus quote price and the option to change the quantity
     * @request POST:/{baseSiteId}/airbus/quotes/price
     * @secure
     */
    getAirbusQuotePrice: (
      baseSiteId: string,
      data: AirbusQuotePriceRequest,
      params: RequestParams = {},
    ) =>
      this.request<AirbusQuotePriceResponse, ErrorList>({
        path: `/${baseSiteId}/airbus/quotes/price`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Airbus Service Orders
     * @name GetAirbusLeaseServiceOrders
     * @summary Get lease service orders based on provided filter criteria
     * @request POST:/{baseSiteId}/airbus/service-orders/lease
     * @secure
     */
    getAirbusLeaseServiceOrders: (
      baseSiteId: string,
      data: AirbusServiceOrderRequest,
      params: RequestParams = {},
    ) =>
      this.request<AirbusServiceOrder[], ErrorList>({
        path: `/${baseSiteId}/airbus/service-orders/lease`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description From date should not be blank and should be valid date of format yyyy-mm-dd.<br/> To date should not be blank and should be valid date of format yyyy-mm-dd. <br/> Order status should not be blank with possible values (OPEN, CLOSED, CANCEL, ALL). <br/> Max allowed purchaseOrderNumber length is 35. <br/> Max allowed orderNumber length is 12. <br/> Max allowed partNumber length is 40. <br/> Max allowed cageCode length is 10. <br/> Max allowed aircraftRegistrationNumber length is 10. <br/> <b>Note:</b>From AirbusServiceOrderWsDTO, airCraftRegistrationNumber, shippingDetails and remarks are not part of response
     *
     * @tags Airbus Service Orders
     * @name ExportAirbusLeaseServiceOrders
     * @summary Get Lease service orders based on provided filter criteria in CSV or Excel format
     * @request POST:/{baseSiteId}/airbus/service-orders/lease/export
     * @secure
     */
    exportAirbusLeaseServiceOrders: (
      baseSiteId: string,
      query: {
        /**
         * File formats to download the service orders as
         * @default "EXCEL"
         */
        fileFormat: 'EXCEL' | 'CSV';
      },
      data: AirbusServiceOrderRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/airbus/service-orders/lease/export`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description From date should not be blank and should be valid date of format yyyy-mm-dd.<br/> To date should not be blank and should be valid date of format yyyy-mm-dd. <br/> Order status should not be blank with possible values (OPEN, CLOSED, CANCEL, ALL). <br/> Max allowed purchaseOrderNumber length is 35. <br/> Max allowed orderNumber length is 12. <br/> Max allowed partNumber length is 40. <br/> Max allowed cageCode length is 10. <br/> Max allowed aircraftRegistrationNumber length is 10. <br/> <b>Note:</b>From AirbusServiceOrderWsDTO, airCraftRegistrationNumber, shippingDetails and remarks are not part of response
     *
     * @tags Airbus Service Orders
     * @name GetAirbusServiceOrders
     * @summary Get service orders based on provided filter criteria
     * @request POST:/{baseSiteId}/airbus/service-orders/repair-exchange
     * @secure
     */
    getAirbusServiceOrders: (
      baseSiteId: string,
      data: AirbusServiceOrderRequest,
      params: RequestParams = {},
    ) =>
      this.request<AirbusServiceOrder[], ErrorList>({
        path: `/${baseSiteId}/airbus/service-orders/repair-exchange`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description From date should not be blank and should be valid date of format yyyy-mm-dd.<br/> To date should not be blank and should be valid date of format yyyy-mm-dd. <br/> Order status should not be blank with possible values (OPEN, CLOSED, CANCEL, ALL). <br/> Max allowed purchaseOrderNumber length is 35. <br/> Max allowed orderNumber length is 12. <br/> Max allowed partNumber length is 40. <br/> Max allowed cageCode length is 10. <br/> Max allowed aircraftRegistrationNumber length is 10. <br/> <b>Note:</b>From AirbusServiceOrderWsDTO, airCraftRegistrationNumber, shippingDetails and remarks are not part of response
     *
     * @tags Airbus Service Orders
     * @name ExportAirbusServiceOrders
     * @summary Get service orders based on provided filter criteria in CSV or Excel format
     * @request POST:/{baseSiteId}/airbus/service-orders/repair-exchange/export
     * @secure
     */
    exportAirbusServiceOrders: (
      baseSiteId: string,
      query: {
        /**
         * File formats to download the service orders as
         * @default "EXCEL"
         */
        fileFormat: 'EXCEL' | 'CSV';
      },
      data: AirbusServiceOrderRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/airbus/service-orders/repair-exchange/export`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Airbus Service Orders
     * @name GetAirbusServiceOrderById
     * @summary Get a service order based on the Airbus SAP Order ID with an optional lease filter
     * @request GET:/{baseSiteId}/airbus/service-orders/{airbusSapOrderId}
     * @secure
     */
    getAirbusServiceOrderById: (
      airbusSapOrderId: string,
      baseSiteId: string,
      query?: {
        /**
         * Flag to indicate if the request is for a lease service order
         * @default false
         */
        isLease?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<AirbusServiceOrder, ErrorList>({
        path: `/${baseSiteId}/airbus/service-orders/${airbusSapOrderId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Endpoint to get Airbus Customer Profile Information by UniversalLogon
     *
     * @tags Users
     * @name GetAirbusUserProfileInformation
     * @summary Returns Airbus Customer Profile Information
     * @request GET:/{baseSiteId}/airbuscustomerprofileinformation/{universalLogon}
     * @secure
     */
    getAirbusUserProfileInformation: (
      baseSiteId: string,
      universalLogon: string,
      params: RequestParams = {},
    ) =>
      this.request<CustomerProfileInformation, ErrorList>({
        path: `/${baseSiteId}/airbuscustomerprofileinformation/${universalLogon}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Airbus Repair Stations
     * @name GetAllAirbusRepairStations
     * @summary Get all Airbus Repair Stations
     * @request GET:/{baseSiteId}/airbusrepairstations
     * @secure
     */
    getAllAirbusRepairStations: (
      baseSiteId: string,
      query?: {
        /** Response configuration (list of fields, which should be returned in response) */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<AirbusRepairStation[], ErrorList>({
        path: `/${baseSiteId}/airbusrepairstations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Downloads documents for a list of orders and document numbers
     *
     * @tags Finance
     * @name DownloadBillingDocuments1
     * @summary Download documents
     * @request POST:/{baseSiteId}/billingdocuments/download
     * @secure
     */
    downloadBillingDocuments1: (
      baseSiteId: string,
      data: OrderDocumentListRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/billingdocuments/download`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Download an Airbus certificate document by id
     *
     * @tags Airbus Documents
     * @name DownloadCertificateDocument
     * @summary Download Airbus certificate document
     * @request GET:/{baseSiteId}/certificates/airbuscertificatedocument/{boxNumber}
     * @secure
     */
    downloadCertificateDocument: (
      baseSiteId: string,
      boxNumber: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/certificates/airbuscertificatedocument/${boxNumber}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get all Certificate Types
     *
     * @tags Certificate Types
     * @name GetAllCertificateTypes
     * @summary Get all Certificate Types
     * @request GET:/{baseSiteId}/certificatetypes
     * @secure
     */
    getAllCertificateTypes: (
      baseSiteId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<CertificateType[], ErrorList>({
        path: `/${baseSiteId}/certificatetypes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns all Order Types that are available for checkout.
     *
     * @tags Checkout Options
     * @name GetCheckoutOptions
     * @summary Get a list of checkout options
     * @request GET:/{baseSiteId}/checkoutoptions
     * @secure
     */
    getCheckoutOptions: (baseSiteId: string, params: RequestParams = {}) =>
      this.request<CheckoutOptions, ErrorList>({
        path: `/${baseSiteId}/checkoutoptions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Download an Airbus customs invoice document by airbus Sap Order Id and order entry number
     *
     * @tags Airbus Documents
     * @name DownloadCustomsInvoiceDocument
     * @summary Download Airbus customs invoice document
     * @request GET:/{baseSiteId}/customsinvoices/airbuscustomsinvoicedocument/order/{orderCode}
     * @secure
     */
    downloadCustomsInvoiceDocument: (
      baseSiteId: string,
      orderCode: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/customsinvoices/airbuscustomsinvoicedocument/order/${orderCode}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Adds a new DAH Membership to the customer by userId
     *
     * @tags Atos DAH
     * @name AddDahMembership
     * @summary Add DAH Membership
     * @request POST:/{baseSiteId}/dah/{userId}/adddahmembership
     * @secure
     */
    addDahMembership: (
      baseSiteId: string,
      userId: string,
      data: AddMembershipRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/dah/${userId}/adddahmembership`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes a DAH Membership from the customer by userId.
     *
     * @tags Atos DAH
     * @name RemoveDahMembership
     * @summary Remove DAH Membership
     * @request DELETE:/{baseSiteId}/dah/{userId}/removedahmembership
     * @secure
     */
    removeDahMembership: (
      baseSiteId: string,
      userId: string,
      query: {
        /** DAH Membership Id to delete */
        membershipId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/dah/${userId}/removedahmembership`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Get all Initial Provisioning Documents
     *
     * @tags Airbus Documents
     * @name GetAllIntialProvisioningDocuments
     * @summary Get all Initial Provisioning Documents
     * @request GET:/{baseSiteId}/documents/initialprovisioning
     * @secure
     */
    getAllIntialProvisioningDocuments: (
      baseSiteId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<InitialProvisioningDocumentList, ErrorList>({
        path: `/${baseSiteId}/documents/initialprovisioning`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Downloads documents for a initial provisioning documents
     *
     * @tags Airbus Documents
     * @name DownloadBillingDocuments
     * @summary Download documents
     * @request POST:/{baseSiteId}/documents/initialprovisioning/download
     * @secure
     */
    downloadBillingDocuments: (
      baseSiteId: string,
      data: InitialProvisioningDocumentListRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/documents/initialprovisioning/download`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get feature flags
     *
     * @tags Feature Flags
     * @name GetFeatureFlags
     * @summary Get feature flags
     * @request GET:/{baseSiteId}/feature-flags
     * @secure
     */
    getFeatureFlags: (
      baseSiteId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<FeatureFlag[], ErrorList>({
        path: `/${baseSiteId}/feature-flags`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Results are paginated
     *
     * @tags Feeds
     * @name OfferFeed
     * @summary Gets a product and offer feed from SOLR used to construct sitemap.
     * @request GET:/{baseSiteId}/feeds/offers
     * @secure
     */
    offerFeed: (
      baseSiteId: string,
      query: {
        /**
         * Starting offset
         * @default "0"
         */
        offset: string;
        /**
         * Number of rows to get
         * @default "20"
         */
        rows: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, ErrorList>({
        path: `/${baseSiteId}/feeds/offers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Return a list of account statements
     *
     * @tags Airbus Finance
     * @name GetAccountStatements1
     * @request POST:/{baseSiteId}/finance/accountstatements
     * @secure
     */
    getAccountStatements1: (
      baseSiteId: string,
      data: AirbusAccountStatementsRequest,
      params: RequestParams = {},
    ) =>
      this.request<AirbusAccountStatements, ErrorList>({
        path: `/${baseSiteId}/finance/accountstatements`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a file of account statements in the specified file format
     *
     * @tags Airbus Finance
     * @name ExportAccountStatements
     * @request POST:/{baseSiteId}/finance/accountstatements/export
     * @secure
     */
    exportAccountStatements: (
      baseSiteId: string,
      query: {
        /**
         * File formats to download the Airbus account statements as
         * @default "EXCEL"
         */
        fileFormat: 'EXCEL' | 'CSV' | 'PDF';
        /** Date to show Airbus account statements until (YYYY-MM-DD) */
        keyDate: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/finance/accountstatements/export`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Return a list of invoices
     *
     * @tags Airbus Finance
     * @name GetInvoiceList
     * @request POST:/{baseSiteId}/finance/invoicelist
     * @secure
     */
    getInvoiceList: (
      baseSiteId: string,
      data: AirbusInvoiceListRequest,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<AirbusInvoiceListItem, ErrorList>({
        path: `/${baseSiteId}/finance/invoicelist`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Generates a token to restore customer's forgotten password.
     *
     * @tags Forgotten Passwords
     * @name CreateToken
     * @summary Generates a token to restore customer's forgotten password.
     * @request GET:/{baseSiteId}/forgottenpasswordtokens/new
     * @secure
     */
    createToken: (
      baseSiteId: string,
      query: {
        /**
         * Customer's user id. Customer user id is case insensitive.
         * @default "{{userId}}"
         */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/forgottenpasswordtokens/new`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Use generated token to restore customer's forgotten password.
     *
     * @tags Forgotten Passwords
     * @name UseTokenToChangePassword
     * @summary Use generated token to restore customer's forgotten password.
     * @request POST:/{baseSiteId}/forgottenpasswordtokens/token
     * @secure
     */
    useTokenToChangePassword: (
      baseSiteId: string,
      data: ForgottenPassword,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/forgottenpasswordtokens/token`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Checks if token is valid or  not.
     *
     * @tags Forgotten Passwords
     * @name ValidateToken
     * @summary Checks if token is valid or  not.
     * @request POST:/{baseSiteId}/forgottenpasswordtokens/validate
     * @secure
     */
    validateToken: (baseSiteId: string, data: PasswordResetToken, params: RequestParams = {}) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/forgottenpasswordtokens/validate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Airbus Heavy Maintenance
     * @name GetHeavyMaintenanceFormInfo
     * @summary Gets data to fulfill heavy maintenance form
     * @request GET:/{baseSiteId}/heavymaintenanceforminfo
     * @secure
     */
    getHeavyMaintenanceFormInfo: (
      baseSiteId: string,
      query?: {
        /** Response configuration (list of fields, which should be returned in response) */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<AirbusHeavyMaintenanceFormInfo, ErrorList>({
        path: `/${baseSiteId}/heavymaintenanceforminfo`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Downloads multiple Airbus invoice documents by IDs. If more than one document is requested, the documents are returned zipped.
     *
     * @tags Airbus Documents
     * @name DownloadInvoiceDocument
     * @summary Download multiple Airbus invoice documents
     * @request POST:/{baseSiteId}/invoices/airbusinvoicedocuments
     * @secure
     */
    downloadInvoiceDocument: (
      baseSiteId: string,
      data: AirbusDocumentsListRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/invoices/airbusinvoicedocuments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Creates a quote for the given list of materialNumberExternal and quantity pairs. <br/>Max allowed quantity is 999999. <br/>Reference and priority are ignored. <br/>Max allowed poNumber length is 35. <br/>Max allowed amount of products is satairocc.LillyController.maxProducts<br><b>Note:</b>The salesUnitCode on entries is ignored
     *
     * @tags Lilly
     * @name CreateLillyQuote
     * @summary Creates a new quote for Satair products
     * @request POST:/{baseSiteId}/lilly/{unitId}/quote
     * @secure
     */
    createLillyQuote: (
      baseSiteId: string,
      unitId: string,
      data: QuoteRequest,
      params: RequestParams = {},
    ) =>
      this.request<Quote, ErrorList>({
        path: `/${baseSiteId}/lilly/${unitId}/quote`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets a quote by code
     *
     * @tags Lilly
     * @name GetLillyQuote
     * @summary Gets a quote by code
     * @request GET:/{baseSiteId}/lilly/{unitId}/quote/{quoteId}
     * @secure
     */
    getLillyQuote: (
      baseSiteId: string,
      quoteId: string,
      unitId: string,
      query?: {
        /** Response configuration (list of fields, which should be returned in response) */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<Quote, ErrorList>({
        path: `/${baseSiteId}/lilly/${unitId}/quote/${quoteId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Set validation status for a lilly quote. Validation status is valid, invalid or pending
     *
     * @tags Lilly
     * @name UpdateLillyQuoteValidationStatus
     * @summary Set validation status for a lilly quote
     * @request PUT:/{baseSiteId}/lilly/{unitId}/quote/{quoteId}
     * @secure
     */
    updateLillyQuoteValidationStatus: (
      baseSiteId: string,
      quoteId: string,
      unitId: string,
      query: {
        /** Quote Validation Status Value */
        validationStatus: 'INVALID' | 'VALID' | 'PENDING';
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/lilly/${unitId}/quote/${quoteId}`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Gets a quote document by code
     *
     * @tags Lilly
     * @name GetLillyQuoteDocument
     * @summary Gets a quote document by code
     * @request GET:/{baseSiteId}/lilly/{unitId}/quote/{quoteId}/document
     * @secure
     */
    getLillyQuoteDocument: (
      baseSiteId: string,
      quoteId: string,
      unitId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/lilly/${unitId}/quote/${quoteId}/document`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lilly
     * @name PlaceLillyOrder
     * @summary Place an order based on a Lilly quote
     * @request POST:/{baseSiteId}/lilly/{unitId}/quote/{quoteId}/orders
     * @secure
     */
    placeLillyOrder: (
      baseSiteId: string,
      quoteId: string,
      unitId: string,
      data: LillyOrderQuoteRequest,
      query?: {
        /** Response configuration (list of fields, which should be returned in response) */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<Order, ErrorList>({
        path: `/${baseSiteId}/lilly/${unitId}/quote/${quoteId}/orders`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Validates if pairs of part number and cage code prefix match existing satair products
     *
     * @tags Lilly
     * @name ValidateParts1
     * @summary Validate list of part number and cage code prefixes
     * @request POST:/{baseSiteId}/lilly/{unitId}/validateparts
     * @secure
     */
    validateParts1: (
      baseSiteId: string,
      unitId: string,
      data: LillyProductSearchElement[],
      query?: {
        /**
         * Whether to only return results that are exact matches
         * @default "false"
         */
        exactMatchOnly?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<LillyProductsValidationList, ErrorList>({
        path: `/${baseSiteId}/lilly/${unitId}/validateparts`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Validates if pairs of part number and cage code prefix match existing satair products
     *
     * @tags Lilly
     * @name ValidateParts
     * @summary Validate list of part number and cage code prefixes
     * @request HEAD:/{baseSiteId}/lilly/{unitId}/validateparts
     * @secure
     */
    validateParts: (
      baseSiteId: string,
      unitId: string,
      data: LillyProductSearchElement[],
      query?: {
        /**
         * Whether to only return results that are exact matches
         * @default "false"
         */
        exactMatchOnly?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<LillyProductsValidationList, ErrorList>({
        path: `/${baseSiteId}/lilly/${unitId}/validateparts`,
        method: 'HEAD',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description SSO Logout
     *
     * @tags SSO
     * @name Logout
     * @summary SSO Logout
     * @request POST:/{baseSiteId}/logout
     * @secure
     */
    logout: (baseSiteId: string, params: RequestParams = {}) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/logout`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Master Shops
     * @name GetMasterShop
     * @summary Look up a master shop by id
     * @request GET:/{baseSiteId}/mastershops/{id}
     * @secure
     */
    getMasterShop: (
      baseSiteId: string,
      id: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<MasterShop, ErrorList>({
        path: `/${baseSiteId}/mastershops/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetOffer
     * @summary Look up an offer by id
     * @request GET:/{baseSiteId}/offers/{id}
     * @secure
     */
    getOffer: (
      baseSiteId: string,
      id: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<Offer, ErrorList>({
        path: `/${baseSiteId}/offers/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns specific Airbus order details based on a specific sap order id. The response contains detailed order information.
     *
     * @tags Airbus Order
     * @name GetAirbusOrderBySapOrderId
     * @summary Get an Airbus orders details
     * @request GET:/{baseSiteId}/orders/airbusorderdetails/{sapOrderId}
     * @secure
     */
    getAirbusOrderBySapOrderId: (
      baseSiteId: string,
      sapOrderId: string,
      query?: {
        /** Response configuration (list of fields, which should be returned in response) */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<AirbusOrderDetails, ErrorList>({
        path: `/${baseSiteId}/orders/airbusorderdetails/${sapOrderId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Airbus Order
     * @name ExportAirbusSalesOrders
     * @summary Export sales orders based on provided purchase order numbers and filter criteria in CSV or Excel format
     * @request POST:/{baseSiteId}/orders/exportsalesorders
     * @secure
     */
    exportAirbusSalesOrders: (
      baseSiteId: string,
      query: {
        /**
         * File format to download the Airbus Sales Orders
         * @default "EXCEL"
         */
        fileFormat: 'EXCEL' | 'CSV';
      },
      data: AirbusExportSalesOrdersRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/orders/exportsalesorders`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns a list of orders and additional data such as: available facets, available sorting and pagination options.It can include spelling suggestions. To make spelling suggestions work you need to: 1. Make sure enableSpellCheck on the SearchQuery is set to true. By default it should be already set to true. 2. Have indexed properties configured to be used for spellchecking. +Orders are restricted according to group visibility
     *
     * @tags Orders
     * @name SearchOrders1
     * @summary Get a list of orders and additional data
     * @request GET:/{baseSiteId}/orders/search
     * @secure
     */
    searchOrders1: (
      baseSiteId: string,
      query?: {
        /**
         * The current result page requested.
         * @default "0"
         */
        currentPage?: string;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * From date for search query. in the format 'yyyy-MM-dd'T'HH:mm:ss'Z'' e.g '2022-01-01T01:00:00Z' or '2022-01-03T01:00:00-02:00'
         * @format date-time
         */
        fromDate?: string;
        /**
         * The number of results returned per page.
         * @default "20"
         */
        pageSize?: string;
        /**
         * Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
         * @default "{{q}}"
         */
        q?: string;
        /**
         * The context to be used in the search query.
         * @default "{{searchQueryContext}}"
         */
        searchQueryContext?: string;
        /** The value to sort by */
        sort?: 'relevance' | 'date-asc' | 'date-desc';
        /**
         * To date for search query.  in the format 'yyyy-MM-dd'T'HH:mm:ss'Z'' e.g '2022-01-01T01:00:00Z' or '2022-01-03T01:00:00-02:00'
         * @format date-time
         */
        toDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderSearchPage, ErrorList>({
        path: `/${baseSiteId}/orders/search`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of orders and additional data such as: available facets, available sorting and pagination options.It can include spelling suggestions. To make spelling suggestions work you need to: 1. Make sure enableSpellCheck on the SearchQuery is set to true. By default it should be already set to true. 2. Have indexed properties configured to be used for spellchecking. +Orders are restricted according to group visibility
     *
     * @tags Orders
     * @name SearchOrders
     * @summary Get a list of orders and additional data
     * @request HEAD:/{baseSiteId}/orders/search
     * @secure
     */
    searchOrders: (
      baseSiteId: string,
      query?: {
        /**
         * The current result page requested.
         * @default "0"
         */
        currentPage?: string;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * From date for search query. in the format 'yyyy-MM-dd'T'HH:mm:ss'Z'' e.g '2022-01-01T01:00:00Z' or '2022-01-03T01:00:00-02:00'
         * @format date-time
         */
        fromDate?: string;
        /**
         * The number of results returned per page.
         * @default "20"
         */
        pageSize?: string;
        /**
         * Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
         * @default "{{q}}"
         */
        q?: string;
        /**
         * The context to be used in the search query.
         * @default "{{searchQueryContext}}"
         */
        searchQueryContext?: string;
        /** The value to sort by */
        sort?: 'relevance' | 'date-asc' | 'date-desc';
        /**
         * To date for search query.  in the format 'yyyy-MM-dd'T'HH:mm:ss'Z'' e.g '2022-01-01T01:00:00Z' or '2022-01-03T01:00:00-02:00'
         * @format date-time
         */
        toDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderSearchPage, ErrorList>({
        path: `/${baseSiteId}/orders/search`,
        method: 'HEAD',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Lookup additional info for multiple products, requestedDeliveryDate is ignored. Returns 429 when the call to PriceAndAtp is dropped due to throttling.
     *
     * @tags Products
     * @name GetProductAdditionalInfo
     * @request POST:/{baseSiteId}/products/additionalinfo
     * @secure
     */
    getProductAdditionalInfo: (
      baseSiteId: string,
      data: ProductInfoRequest,
      query?: {
        /** Response configuration (list of fields, which should be returned in response) */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<AdditionalProductInfoList, ErrorList>({
        path: `/${baseSiteId}/products/additionalinfo`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Download an Airbus part document by id
     *
     * @tags Airbus Product
     * @name DownloadPartDocument
     * @summary Download Airbus part document
     * @request GET:/{baseSiteId}/products/airbuspartdocument/{documentId}
     * @secure
     */
    downloadPartDocument: (
      baseSiteId: string,
      documentId: string,
      query: {
        /**
         * The desired name of the downloaded document
         * @default "{{fileName}}"
         */
        fileName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/products/airbuspartdocument/${documentId}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Airbus Product
     * @name GetAirbusPartRequest
     * @summary Lookup price info for one or multiple Airbus products
     * @request POST:/{baseSiteId}/products/airbuspartrequest
     * @secure
     */
    getAirbusPartRequest: (
      baseSiteId: string,
      data: PriceInfoRequest,
      query?: {
        /** Response configuration (list of fields, which should be returned in response) */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<PartListResponse, ErrorList>({
        path: `/${baseSiteId}/products/airbuspartrequest`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Lookup availability info for multiple products
     *
     * @tags Products
     * @name GetProductAvailabilityInfo
     * @request POST:/{baseSiteId}/products/availabilityinfo
     * @secure
     */
    getProductAvailabilityInfo: (
      baseSiteId: string,
      data: AvailabilityInfoRequest,
      query?: {
        /** Response configuration (list of fields, which should be returned in response) */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductAvailabilityEntryList, ErrorList>({
        path: `/${baseSiteId}/products/availabilityinfo`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Airbus Product
     * @name GetFhsDetails
     * @summary Lookup for product flight hours service (FHS) details
     * @request POST:/{baseSiteId}/products/fhsdetails
     * @secure
     */
    getFhsDetails: (
      baseSiteId: string,
      data: ProductsFlightHourServicesDetailsRequest,
      query?: {
        /** Response configuration (list of fields, which should be returned in response) */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductsFlightHourServicesDetailsResponse, ErrorList>({
        path: `/${baseSiteId}/products/fhsdetails`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get a list of products and offers
     *
     * @tags Products
     * @name SearchOffers1
     * @summary Returns a list of products or offers for marketplace products
     * @request GET:/{baseSiteId}/products/offersearch
     * @secure
     */
    searchOffers1: (
      baseSiteId: string,
      query?: {
        /**
         * The current result page requested.
         * @default "0"
         */
        currentPage?: string;
        /** Response configuration (list of fields, which should be returned in response) */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * The number of results returned per page.
         * @default "20"
         */
        pageSize?: string;
        /** {{q}} */
        q?: string;
        /**
         * The context to be used in the search query.
         * @default "{{searchQueryContext}}"
         */
        searchQueryContext?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OfferSearchPage, ErrorList>({
        path: `/${baseSiteId}/products/offersearch`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get a list of products and offers
     *
     * @tags Products
     * @name SearchOffers
     * @summary Returns a list of products or offers for marketplace products
     * @request HEAD:/{baseSiteId}/products/offersearch
     * @secure
     */
    searchOffers: (
      baseSiteId: string,
      query?: {
        /**
         * The current result page requested.
         * @default "0"
         */
        currentPage?: string;
        /** Response configuration (list of fields, which should be returned in response) */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * The number of results returned per page.
         * @default "20"
         */
        pageSize?: string;
        /** {{q}} */
        q?: string;
        /**
         * The context to be used in the search query.
         * @default "{{searchQueryContext}}"
         */
        searchQueryContext?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OfferSearchPage, ErrorList>({
        path: `/${baseSiteId}/products/offersearch`,
        method: 'HEAD',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Lookup ATP across plants for a list of products
     *
     * @tags Products
     * @name Plants
     * @summary Returns stock on each location for the given products
     * @request POST:/{baseSiteId}/products/plants
     * @secure
     */
    plants: (
      baseSiteId: string,
      data: PlantsStockInfoRequest,
      query?: {
        /** Response configuration (list of fields, which should be returned in response). */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<PlantsStock, ErrorList>({
        path: `/${baseSiteId}/products/plants`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Lookup price info for multiple products
     *
     * @tags Products
     * @name GetProductPriceInfo
     * @request POST:/{baseSiteId}/products/priceinfo
     * @secure
     */
    getProductPriceInfo: (
      baseSiteId: string,
      data: PriceInfoRequest,
      query?: {
        /** Response configuration (list of fields, which should be returned in response) */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductPriceEntryList, ErrorList>({
        path: `/${baseSiteId}/products/priceinfo`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Lookup a product by partNumber, cageCode and owningSystem. This returns Satair or Airbus products.
     *
     * @tags Products
     * @name LookupProductWithOwningSystem
     * @request GET:/{baseSiteId}/products/{partNumber}/manufacturer/{cageCode}/owningsystem/{owningSystem}
     * @secure
     */
    lookupProductWithOwningSystem: (
      baseSiteId: string,
      cageCode: string,
      owningSystem: string,
      partNumber: string,
      query?: {
        /** Response configuration (list of fields, which should be returned in response) */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<Product, ErrorList>({
        path: `/${baseSiteId}/products/${partNumber}/manufacturer/${cageCode}/owningsystem/${owningSystem}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Lookup a product by partNumber and cageCode. This returns Satair products.
     *
     * @tags Products
     * @name LookupProduct
     * @request GET:/{baseSiteId}/products/{partNumber}/{cageCode}
     * @secure
     */
    lookupProduct: (
      baseSiteId: string,
      cageCode: string,
      partNumber: string,
      query?: {
        /** Response configuration (list of fields, which should be returned in response) */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<Product, ErrorList>({
        path: `/${baseSiteId}/products/${partNumber}/${cageCode}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Lookup a product by id
     *
     * @tags Products
     * @name GetProductById
     * @request GET:/{baseSiteId}/products/{productCode}
     * @secure
     */
    getProductById: (
      baseSiteId: string,
      productCode: string,
      query?: {
        /** Response configuration (list of fields, which should be returned in response) */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<Product, ErrorList>({
        path: `/${baseSiteId}/products/${productCode}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of quotes and additional data such as: available facets, available sorting and pagination options.It can include spelling suggestions. To make spelling suggestions work you need to: 1. Make sure enableSpellCheck on the SearchQuery is set to true. By default it should be already set to true. 2. Have indexed properties configured to be used for spellchecking. +Quotes are restricted according to group visibility
     *
     * @tags Quotes
     * @name SearchQuotes1
     * @summary Get a list of quotes and additional data
     * @request GET:/{baseSiteId}/quotes/search
     * @secure
     */
    searchQuotes1: (
      baseSiteId: string,
      query?: {
        /**
         * The current result page requested.
         * @default 0
         */
        currentPage?: number;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * The number of results returned per page.
         * @default 20
         */
        pageSize?: number;
        /**
         * Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
         * @default "{{q}}"
         */
        q?: string;
        /**
         * The context to be used in the search query.
         * @default "{{searchQueryContext}}"
         */
        searchQueryContext?: string;
        /** The value to sort by */
        sort?: 'relevance' | 'date-asc' | 'date-desc';
      },
      params: RequestParams = {},
    ) =>
      this.request<QuoteSearchPage, ErrorList>({
        path: `/${baseSiteId}/quotes/search`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of quotes and additional data such as: available facets, available sorting and pagination options.It can include spelling suggestions. To make spelling suggestions work you need to: 1. Make sure enableSpellCheck on the SearchQuery is set to true. By default it should be already set to true. 2. Have indexed properties configured to be used for spellchecking. +Quotes are restricted according to group visibility
     *
     * @tags Quotes
     * @name SearchQuotes
     * @summary Get a list of quotes and additional data
     * @request HEAD:/{baseSiteId}/quotes/search
     * @secure
     */
    searchQuotes: (
      baseSiteId: string,
      query?: {
        /**
         * The current result page requested.
         * @default 0
         */
        currentPage?: number;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * The number of results returned per page.
         * @default 20
         */
        pageSize?: number;
        /**
         * Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
         * @default "{{q}}"
         */
        q?: string;
        /**
         * The context to be used in the search query.
         * @default "{{searchQueryContext}}"
         */
        searchQueryContext?: string;
        /** The value to sort by */
        sort?: 'relevance' | 'date-asc' | 'date-desc';
      },
      params: RequestParams = {},
    ) =>
      this.request<QuoteSearchPage, ErrorList>({
        path: `/${baseSiteId}/quotes/search`,
        method: 'HEAD',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Airbus Heavy Maintenance
     * @name SaveHeavyMaintenanceForm
     * @summary Validates and saves the heavy maintenance form
     * @request POST:/{baseSiteId}/saveheavymaintenanceform
     * @secure
     */
    saveHeavyMaintenanceForm: (
      baseSiteId: string,
      data: AirbusHeavyMaintenanceFormRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/saveheavymaintenanceform`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shops
     * @name GetShop
     * @summary Look up a shop by id
     * @request GET:/{baseSiteId}/shops/{id}
     * @secure
     */
    getShop: (
      baseSiteId: string,
      id: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<Shop, ErrorList>({
        path: `/${baseSiteId}/shops/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shops
     * @name GetShopDocuments
     * @summary Get a shops documents
     * @request GET:/{baseSiteId}/shops/{id}/documents
     * @secure
     */
    getShopDocuments: (
      baseSiteId: string,
      id: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<ShopDocumentInfo[], ErrorList>({
        path: `/${baseSiteId}/shops/${id}/documents`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shops
     * @name DownloadDocument1
     * @summary Download a shop document
     * @request GET:/{baseSiteId}/shops/{id}/documents/{documentId}
     * @secure
     */
    downloadDocument1: (
      baseSiteId: string,
      documentId: string,
      id: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/shops/${id}/documents/${documentId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Get a list of spec2000 messages of the given Order
     *
     * @tags Airbus Spec Orders
     * @name GetAirbusSpecOrderHistory
     * @summary Returns Airbus Order Spec200
     * @request GET:/{baseSiteId}/specorders/history/{orderNumber}
     * @secure
     */
    getAirbusSpecOrderHistory: (
      baseSiteId: string,
      orderNumber: string,
      params: RequestParams = {},
    ) =>
      this.request<AirbusSpecOrderHistoryResponse, ErrorList>({
        path: `/${baseSiteId}/specorders/history/${orderNumber}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns details about a given master b2bUnit.
     *
     * @tags B2BUnits
     * @name GetB2BUnit
     * @summary Gets details about a Master B2BUnit
     * @request GET:/{baseSiteId}/units/{masterB2BUnitUid}
     * @secure
     */
    getB2BUnit: (
      baseSiteId: string,
      masterB2BUnitUid: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<MasterB2BUnit, ErrorList>({
        path: `/${baseSiteId}/units/${masterB2BUnitUid}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get account statements
     *
     * @tags Finance
     * @name GetAccountStatements
     * @summary Get account statements
     * @request POST:/{baseSiteId}/units/{masterB2BUnitUid}/accountstatements
     * @secure
     */
    getAccountStatements: (
      baseSiteId: string,
      masterB2BUnitUid: string,
      data: InvoiceItemsRequest,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceItemList, ErrorList>({
        path: `/${baseSiteId}/units/${masterB2BUnitUid}/accountstatements`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get account statements as .xlsx
     *
     * @tags Finance
     * @name GetAccountStatementsAsExcel
     * @summary Get account statements as .xlsx
     * @request POST:/{baseSiteId}/units/{masterB2BUnitUid}/accountstatements/excel
     * @secure
     */
    getAccountStatementsAsExcel: (
      baseSiteId: string,
      masterB2BUnitUid: string,
      data: InvoiceItemsRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/units/${masterB2BUnitUid}/accountstatements/excel`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get account statements pdf
     *
     * @tags Finance
     * @name GetAccountStatementsPdf
     * @summary Get account statements pdf
     * @request POST:/{baseSiteId}/units/{masterB2BUnitUid}/accountstatements/pdf
     * @secure
     */
    getAccountStatementsPdf: (
      baseSiteId: string,
      masterB2BUnitUid: string,
      data: InvoiceItemsRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/units/${masterB2BUnitUid}/accountstatements/pdf`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Search billing document info
     *
     * @tags Finance
     * @name SearchBillingDocumentInfo
     * @summary Search billing document info
     * @request POST:/{baseSiteId}/units/{masterB2BUnitUid}/billingdocuments
     * @secure
     */
    searchBillingDocumentInfo: (
      baseSiteId: string,
      masterB2BUnitUid: string,
      data: BillingDocumentInfoRequest,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<BillingDocumentInfoResponseList, ErrorList>({
        path: `/${baseSiteId}/units/${masterB2BUnitUid}/billingdocuments`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get info about finance items that are due
     *
     * @tags Finance
     * @name GetOpenItems
     * @summary Get open items
     * @request POST:/{baseSiteId}/units/{masterB2BUnitUid}/openitems
     * @secure
     */
    getOpenItems: (
      baseSiteId: string,
      masterB2BUnitUid: string,
      data: OpenItemsRequest,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<InvoiceItemList, ErrorList>({
        path: `/${baseSiteId}/units/${masterB2BUnitUid}/openitems`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get info about finance items that are due as .xlsx
     *
     * @tags Finance
     * @name GetOpenItemsAsExcel
     * @summary Get open items as .xlsx
     * @request POST:/{baseSiteId}/units/{masterB2BUnitUid}/openitems/excel
     * @secure
     */
    getOpenItemsAsExcel: (
      baseSiteId: string,
      masterB2BUnitUid: string,
      data: OpenItemsRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/units/${masterB2BUnitUid}/openitems/excel`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get info about finance items that are due as pdf
     *
     * @tags Finance
     * @name GetOpenItemsAsPdf
     * @summary Get open items as pdf
     * @request POST:/{baseSiteId}/units/{masterB2BUnitUid}/openitems/pdf
     * @secure
     */
    getOpenItemsAsPdf: (
      baseSiteId: string,
      masterB2BUnitUid: string,
      data: OpenItemsRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/units/${masterB2BUnitUid}/openitems/pdf`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns the login type for the user with the specified username
     *
     * @tags Users
     * @name GetUserLoginType
     * @summary Get the login type for a username
     * @request GET:/{baseSiteId}/user/{username}/logintype
     * @secure
     */
    getUserLoginType: (baseSiteId: string, username: string, params: RequestParams = {}) =>
      this.request<UserLoginType, ErrorList>({
        path: `/${baseSiteId}/user/${username}/logintype`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Set the user to b2badmingroup or removed it from b2badmingroup based on the parameter
     *
     * @tags UserAdmin
     * @name EnableAdmin
     * @request PATCH:/{baseSiteId}/useradmin/{userId}/enableadmin
     * @secure
     */
    enableAdmin: (
      baseSiteId: string,
      userId: string,
      query: {
        /** @default "{{enableAdmin}}" */
        enableAdmin: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/useradmin/${userId}/enableadmin`,
        method: 'PATCH',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Registers a customer. The following two sets of parameters are available: First set is used to register a customer. In this case the required parameters are: login,password, countryCode, contactNumber, firstName, lastName. Second set is used to convert a guest to a customer. In this case the required parameters are: guid, password.
     *
     * @tags Users
     * @name RegisterUser
     * @summary  Registers a customer
     * @request POST:/{baseSiteId}/users
     * @secure
     */
    registerUser: (
      baseSiteId: string,
      data: UserSignUp,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<User, ErrorList>({
        path: `/${baseSiteId}/users`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns customer profile.
     *
     * @tags Users
     * @name GetUser
     * @summary Get customer profile
     * @request GET:/{baseSiteId}/users/{userId}
     * @secure
     */
    getUser: (
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<User, ErrorList>({
        path: `/${baseSiteId}/users/${userId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes customer profile.
     *
     * @tags Users
     * @name DeleteUser
     * @summary Delete customer profile
     * @request DELETE:/{baseSiteId}/users/{userId}
     * @secure
     */
    deleteUser: (baseSiteId: string, userId: string, params: RequestParams = {}) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Updates customer profile. Only the listed attributes in the request body will be changed:, firstName, lastName, titleCode, countryCode, contactNumber, timeZone, primaryWorkArea, confirmedInformation
     *
     * @tags Users
     * @name UpdateUser
     * @summary Updates customer profile
     * @request PATCH:/{baseSiteId}/users/{userId}
     * @secure
     */
    updateUser: (
      baseSiteId: string,
      userId: string,
      data: UserUpdate,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Initiates a quote request for a SALE_NEW procurement from Airbus. Ensure the following:<br/>- Purchase Order Number: 1-11 characters alphanumeric string (dashes allowed).<br/>- Part ID: Mandatory, must correspond to a quotable Airbus product not excluded by Airbus.<br/>- Quantity: Optional (defaults to 1 if omitted), may be adjusted to meet part's MOQ and SPQ.<br/>- Remark: Optional, up to 320 characters allowed.<br/>
     *
     * @tags Airbus Quotes
     * @name CreateAirbusQuoteRequestForSaleNewProcurement
     * @summary Initiate Airbus Quote for SALE_NEW Procurement
     * @request POST:/{baseSiteId}/users/{userId}/airbus/quotes/quoterequest/salenew
     * @secure
     */
    createAirbusQuoteRequestForSaleNewProcurement: (
      baseSiteId: string,
      userId: string,
      data: AirbusQuoteRequest,
      params: RequestParams = {},
    ) =>
      this.request<Quote, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/airbus/quotes/quoterequest/salenew`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns all carriers for a user.
     *
     * @tags Users
     * @name GetSupportedCarriers
     * @summary Get all carriers for a user
     * @request GET:/{baseSiteId}/users/{userId}/carriers
     * @secure
     */
    getSupportedCarriers: (
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<CarrierList, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carriers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Lists all customer carts.
     *
     * @tags Carts
     * @name GetCarts
     * @summary Get all customer carts.
     * @request GET:/{baseSiteId}/users/{userId}/carts
     * @secure
     */
    getCarts: (
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Optional pagination parameter in case of savedCartsOnly == true. Default value 0.
         * @default 0
         */
        currentPage?: number;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * Optional {@link PaginationData} parameter in case of savedCartsOnly == true. Default value 20.
         * @default 20
         */
        pageSize?: number;
        /**
         * Optional parameter. If the parameter is provided and its value is true only saved carts are returned.
         * @default false
         */
        savedCartsOnly?: boolean;
        /**
         * Optional sort criterion in case of savedCartsOnly == true. No default value.
         * @default "{{sort}}"
         */
        sort?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CartList, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new cart or restores an anonymous cart as a user's cart (if an old Cart Id is given in the request).
     *
     * @tags Carts
     * @name CreateCart
     * @summary Creates or restore a cart for a user.
     * @request POST:/{baseSiteId}/users/{userId}/carts
     * @secure
     */
    createCart: (
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * Anonymous cart GUID.
         * @default "{{oldCartId}}"
         */
        oldCartId?: string;
        /**
         * User's cart GUID to merge anonymous cart to.
         * @default "{{toMergeCartGuid}}"
         */
        toMergeCartGuid?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Cart, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the session cart - the given identifier is ignored.
     *
     * @tags Carts
     * @name GetCart
     * @summary Get the session cart - the given identifier is ignored.
     * @request GET:/{baseSiteId}/users/{userId}/carts/{cartId}
     * @secure
     */
    getCart: (
      baseSiteId: string,
      cartId: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<Cart, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes a cart with a given cart id.
     *
     * @tags Carts
     * @name DeleteCart
     * @summary Deletes a cart with a given cart id.
     * @request DELETE:/{baseSiteId}/users/{userId}/carts/{cartId}
     * @secure
     */
    deleteCart: (baseSiteId: string, cartId: string, userId: string, params: RequestParams = {}) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Get all the addresses of the current user's unit, with each address containing a list of the master shop IDs, that are restricted for the given cart.
     *
     * @tags Carts
     * @name GetCartAddresses
     * @summary Gets addresses for current cart.
     * @request GET:/{baseSiteId}/users/{userId}/carts/{cartId}/addresses
     * @secure
     */
    getCartAddresses: (
      baseSiteId: string,
      cartId: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<AddressList, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/addresses`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns cart entries.
     *
     * @tags Carts
     * @name GetCartEntries
     * @summary Get cart entries.
     * @request GET:/{baseSiteId}/users/{userId}/carts/{cartId}/entries
     * @secure
     */
    getCartEntries: (
      baseSiteId: string,
      cartId: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderEntryList, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/entries`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Add entries to a cart.
     *
     * @tags Carts
     * @name AddCartEntries
     * @summary Add entries to a cart.
     * @request POST:/{baseSiteId}/users/{userId}/carts/{cartId}/entries
     * @secure
     */
    addCartEntries: (
      baseSiteId: string,
      cartId: string,
      userId: string,
      data: ListOfCartEntriesToAdd,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<CartModificationList, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/entries`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes cart entry.
     *
     * @tags Carts
     * @name RemoveCartEntry
     * @summary Deletes cart entry.
     * @request DELETE:/{baseSiteId}/users/{userId}/carts/{cartId}/entries/{entryNumber}
     * @secure
     */
    removeCartEntry: (
      baseSiteId: string,
      cartId: string,
      entryNumber: number,
      userId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/entries/${entryNumber}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Updates the quantity of a single cart entry and details of the store where the cart entry will be picked.
     *
     * @tags Carts
     * @name UpdateCartEntry
     * @summary Update quantity and store details of a cart entry.
     * @request PATCH:/{baseSiteId}/users/{userId}/carts/{cartId}/entries/{entryNumber}
     * @secure
     */
    updateCartEntry: (
      baseSiteId: string,
      cartId: string,
      entryNumber: number,
      userId: string,
      data: OrderEntry,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<CartModification, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/entries/${entryNumber}`,
        method: 'PATCH',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the remarks of a single cart entry.
     *
     * @tags Carts
     * @name UpdateEntryRemark
     * @summary Update remarks of a cart entry.
     * @request PATCH:/{baseSiteId}/users/{userId}/carts/{cartId}/entries/{entryNumber}/remarks
     * @secure
     */
    updateEntryRemark: (
      baseSiteId: string,
      cartId: string,
      entryNumber: number,
      userId: string,
      data: AddEntryRemarksRequest,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderEntry, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/entries/${entryNumber}/remarks`,
        method: 'PATCH',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Sets custom forwarder for given master shop on the cart, along with an optional message for the forwarder, up to 255 characters long. <br/>(400) message:<b>Required String parameter 'forwarderName' is not present</b> - When not supplying an forwarderName <br/>(400) message:<b> Invalid value: [$INVALID_VALUE], reason: [Forwarder name must be between 2 and 40 characters long] </b> - When supplying an invalid forwarderName <br/>(400) message:<b>Forwarder information too long</b> - When forwarderInfo exceeds 255 characters<br/>(400) message:<b>Forwarder name must be between 2 and 40 characters long</b> - When forwarderName is not between 2 & 40 characters<br/>(400) message:<b> Invalid value: [$INVALID_VALUE], reason: [Forwarder account number must be between 2 and 40 characters long] </b> - When supplying an invalid forwarderAccountNumber <br/>(400) message:<b>Required String parameter 'forwarderAccountNumber' is not present</b> - When not supplying an forwarderAccountNumber <br/>
     *
     * @tags Cart forwarders
     * @name SetUnknownForwarderForMasterShop
     * @summary Sets custom forwarder for a given master shop on the cart.
     * @request PUT:/{baseSiteId}/users/{userId}/carts/{cartId}/forwarders/custom/{masterShopId}
     * @secure
     */
    setUnknownForwarderForMasterShop: (
      baseSiteId: string,
      cartId: string,
      masterShopId: string,
      userId: string,
      query: {
        /**
         * Forwarder account number
         * @default "{{forwarderAccountNumber}}"
         */
        forwarderAccountNumber: string;
        /**
         * Forwarder additional information
         * @minLength 0
         * @maxLength 255
         * @default "{{forwarderInfo}}"
         */
        forwarderInfo?: string;
        /**
         * Forwarder name
         * @default "{{forwarderName}}"
         */
        forwarderName: string;
        /**
         * Local Contact Info
         * @default "{{localContactInfo}}"
         */
        localContactInfo?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/forwarders/custom/${masterShopId}`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Sets the forwarder for given master shop on the cart, along with an optional message for the forwarder, up to 255 characters long. <br/>(400) message:<b>Cart not found.</b> - When supplying an unknown cartID <br/> (400) message:<b>Base site XXX doesn't exist</b> - When supplying an unknown base site XXX <br/>(400) message:<b>Required String parameter 'forwarderId' is not present</b> - When not supplying an forwarderId <br/>(400) message:<b>Forwarder (XXX) not found</b> - When supplying an unknown forwarderId XXX<br/>(400) message:<b>Forwarder information too long</b> - When forwarderInfo exceeds 255 characters<br/>(400) message:<b>Required String parameter 'forwarderAccountNumber' is not present</b> - When not supplying a forwarderAccountNumber <br/>(400) message:<b> Invalid value: [$INVALID_VALUE], reason: [Forwarder account number must be between 2 and 40 characters long] </b> - When supplying an invalid forwarderAccountNumber <br/>(400) message:<b>Forwarder service level agreements too long</b> - When serviceLevelAgreements exceeds 40 characters<br/>(401) message:<b>Full authentication is required to access this resource</b> - If not logged in with a user<br/>(403) message:<b>Access is denied</b> - When supplying a userId different from the logged in user
     *
     * @tags Cart forwarders
     * @name SetForwarderForMasterShop
     * @summary Sets the forwarder for a given master shop on the cart.
     * @request PUT:/{baseSiteId}/users/{userId}/carts/{cartId}/forwarders/{masterShopId}
     * @secure
     */
    setForwarderForMasterShop: (
      baseSiteId: string,
      cartId: string,
      masterShopId: string,
      userId: string,
      query: {
        /**
         * Forwarder account number
         * @default "{{forwarderAccountNumber}}"
         */
        forwarderAccountNumber: string;
        /**
         * Forwarder identifier (code)
         * @default "{{forwarderId}}"
         */
        forwarderId: string;
        /**
         * Forwarder information
         * @minLength 0
         * @maxLength 255
         * @default "{{forwarderInfo}}"
         */
        forwarderInfo?: string;
        /**
         * Forwarder service level agreements
         * @minLength 0
         * @maxLength 40
         * @default "{{serviceLevelAgreements}}"
         */
        serviceLevelAgreements?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/forwarders/${masterShopId}`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Removes the forwarder from the cart.
     *
     * @tags Cart forwarders
     * @name RemoveForwarderForMasterShop
     * @summary Delete the forwarder from the cart.
     * @request DELETE:/{baseSiteId}/users/{userId}/carts/{cartId}/forwarders/{masterShopId}
     * @secure
     */
    removeForwarderForMasterShop: (
      baseSiteId: string,
      cartId: string,
      masterShopId: string,
      userId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/forwarders/${masterShopId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Sets delivery address of a master shop. <br/>(400) message:<b>Cart not found.</b> - When supplying an unknown cartID <br/> (400) message:<b>Base site XXX doesn't exist</b> - When supplying an unknown base site XXX <br/>(400) message:<b>Required String parameter 'addressId' is not present</b> - When not supplying an addressId <br/>(400) message:<b>Required String parameter 'masterShopId' is not present</b> - When not supplying a masterShopId <br/>(400) message:<b>Address given by id XXX cannot be set as delivery address in this cart</b> - When supplying an unknown addressId, a NON shippingAddress or an address not related to the customer <br/>(401) message:<b>Full authentication is required to access this resource</b> - If not logged in with a user<br/>(403) message:<b>Access is denied</b> - When supplying a userId different from the logged in user
     *
     * @tags Carts
     * @name SetCartDeliveryAddressForMasterShop
     * @summary Sets delivery address of cart for master shop.
     * @request PUT:/{baseSiteId}/users/{userId}/carts/{cartId}/mastershops/{masterShopId}/addresses/delivery
     * @secure
     */
    setCartDeliveryAddressForMasterShop: (
      baseSiteId: string,
      cartId: string,
      masterShopId: string,
      userId: string,
      query: {
        /**
         * ID of address to use
         * @default "{{addressId}}"
         */
        addressId: string;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<Cart, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/mastershops/${masterShopId}/addresses/delivery`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Sets customer code for a master shop <br/>(400) message:<b>Invalid value: [$INVALID_VALUE], reason: [The customer code must be between 1 and 5 character]</b> - When supplying an invalid customerCode <br/> (400) message:<b>Invalid value: [$INVALID_VALUE], reason: [The customer code must not be blank]</b> - When supplying a blank customerCode <br/>
     *
     * @tags Carts
     * @name SetCustomerCode
     * @summary Sets customer code for a master shop
     * @request PUT:/{baseSiteId}/users/{userId}/carts/{cartId}/mastershops/{masterShopId}/customercode
     * @secure
     */
    setCustomerCode: (
      baseSiteId: string,
      cartId: string,
      masterShopId: string,
      userId: string,
      query: {
        /**
         * Customer Code
         * @default "{{customercode}}"
         */
        customerCode: string;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<Cart, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/mastershops/${masterShopId}/customercode`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Sets order type for a master shop <br/>(400) message:<b>Invalid value: [$INVALID_VALUE], reason: [The order type must be a valid order type]</b> - When supplying an invalid orderType <br/> (400) message:<b>Required String parameter 'orderType' is not present</b> - When not supplying a orderType <br/>
     *
     * @tags Carts
     * @name SetOrderType
     * @summary Sets order type for a master shop
     * @request PUT:/{baseSiteId}/users/{userId}/carts/{cartId}/mastershops/{masterShopId}/ordertype
     * @secure
     */
    setOrderType: (
      baseSiteId: string,
      cartId: string,
      masterShopId: string,
      userId: string,
      query: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * Order Type
         * @default "{{orderType}}"
         */
        orderType: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Cart, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/mastershops/${masterShopId}/ordertype`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Sets purchase order number for a master shop <br/>(400) message:<b>Invalid value: [$INVALID_VALUE], reason: [The purchase order number must be less than 35 non-whitespace characters]</b> - When supplying an invalid purchaseOrderNumber <br/> (400) message:<b>Invalid value: [$INVALID_VALUE], reason: [The purchase order number must not be blank]</b> - When supplying a blank purchaseOrderNumber <br/> (400) message:<b>Required String parameter 'purchaseOrderNumber' is not present</b> - When not supplying a purchaseOrderNumber <br/>
     *
     * @tags Carts
     * @name SetPurchaseOrderNumber
     * @summary Sets purchase order number for a master shop
     * @request PUT:/{baseSiteId}/users/{userId}/carts/{cartId}/mastershops/{masterShopId}/purchaseordernumber
     * @secure
     */
    setPurchaseOrderNumber: (
      baseSiteId: string,
      cartId: string,
      masterShopId: string,
      userId: string,
      query: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * Purchase Order Number
         * @default "{{purchaseOrderNumber}}"
         */
        purchaseOrderNumber: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Cart, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/mastershops/${masterShopId}/purchaseordernumber`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets minicart info about current cart.
     *
     * @tags Carts
     * @name GetCurrentMiniCart
     * @summary Gets minicart info about current cart.
     * @request GET:/{baseSiteId}/users/{userId}/carts/{cartId}/minicart
     * @secure
     */
    getCurrentMiniCart: (
      baseSiteId: string,
      cartId: string,
      userId: string,
      params: RequestParams = {},
    ) =>
      this.request<MiniCart, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/minicart`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Assigns named delivery to the cart.
     *
     * @tags Carts
     * @name SetNamedDeliveryDateForMasterShop
     * @summary Assigns named delivery to the cart.
     * @request PUT:/{baseSiteId}/users/{userId}/carts/{cartId}/nameddeliverydate/{masterShopId}
     * @secure
     */
    setNamedDeliveryDateForMasterShop: (
      baseSiteId: string,
      cartId: string,
      masterShopId: string,
      userId: string,
      query?: {
        /**
         * Named delivery date
         * @default "{{namedDeliveryDate}}"
         */
        namedDeliveryDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/nameddeliverydate/${masterShopId}`,
        method: 'PUT',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Sets payment type of cart. <br/>(400) message:<b>Cart not found.</b> - When supplying an unknown cartID <br/> (400) message:<b>Base site XXX doesn't exist</b> - When supplying an unknown base site XXX <br/>(400) message:<b>Required String parameter 'paymentType' is not present</b> - When not supplying a paymentType <br/>(400) message:<b>Required String parameter 'purchaseOrderNumber' is not present</b> - When not supplying a purchaseOrderNumber <br/>(400) message:<b>Invalid value: [$INVALID_VALUE], reason: [The purchase order number must be less than 35 non-whitespace characters]</b> - When supplying an invalid purchaseOrderNumber <br/>(400) message:<b>This field must not be more than 8 characters long.</b> subject:<b>projectID</b> - When supplying a projectID of invalid length<br/>(401) message:<b>Full authentication is required to access this resource</b> - If not logged in with a user<br/>(403) message:<b>Access is denied</b> - When supplying a userId different from the logged in user
     *
     * @tags Carts
     * @name SetPaymentType
     * @summary Sets payment type of cart.
     * @request PUT:/{baseSiteId}/users/{userId}/carts/{cartId}/paymenttype
     * @secure
     */
    setPaymentType: (
      baseSiteId: string,
      cartId: string,
      userId: string,
      query: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /** Payment Type to use */
        paymentType: 'CARD' | 'ACCOUNT';
        /**
         * Project ID
         * @default "{{projectID}}"
         */
        projectID?: string;
        /**
         * Purchase Order Number
         * @default "{{purchaseOrderNumber}}"
         */
        purchaseOrderNumber?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Cart, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/paymenttype`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Fetches detailed pricing information for a specific cart, including any cart-level or entry-level errors.
     *
     * @tags Carts
     * @name GetCartPricing
     * @summary Get pricing information for a specific cart
     * @request GET:/{baseSiteId}/users/{userId}/carts/{cartId}/pricing
     * @secure
     */
    getCartPricing: (
      baseSiteId: string,
      cartId: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<CartPricing, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/pricing`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Assigns priority information for the master shop.
     *
     * @tags Carts
     * @name SetPriorityForMasterShop
     * @summary Assigns priority information for the master shop
     * @request PUT:/{baseSiteId}/users/{userId}/carts/{cartId}/priority/{masterShopId}
     * @secure
     */
    setPriorityForMasterShop: (
      baseSiteId: string,
      cartId: string,
      masterShopId: string,
      userId: string,
      data: PriorityInfo,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/carts/${cartId}/priority/${masterShopId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get Airbus CustomerCodes from CIC
     *
     * @tags B2BUnits
     * @name GetCustomerCodes
     * @summary Get Airbus CustomerCodes
     * @request GET:/{baseSiteId}/users/{userId}/customercode
     * @secure
     */
    getCustomerCodes: (
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<AirbusCustomerCodeResponse, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/customercode`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description List of linked accounts containing companyId together with stored properties for the linked account. The current active linked account is marked
     *
     * @tags Users
     * @name GetLinkedAccountsB2B
     * @summary Get all linked accounts for this user
     * @request GET:/{baseSiteId}/users/{userId}/linkedaccounts
     * @secure
     */
    getLinkedAccountsB2B: (
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<LinkedAccountList, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/linkedaccounts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Select the linked account to be the current, if changed the oAuth token is removed, use refresh token to re-authorize with new roles.If the user does not have a linked account to the given company an error is returned.
     *
     * @tags Users
     * @name UpdateLinkedAccounts
     * @summary Set current account
     * @request PATCH:/{baseSiteId}/users/{userId}/linkedaccounts
     * @secure
     */
    updateLinkedAccounts: (
      baseSiteId: string,
      userId: string,
      query: {
        /**
         * The UID of the master B2B unit to activate as current
         * @default "{{parentUnitUid}}"
         */
        parentUnitUid: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/linkedaccounts`,
        method: 'PATCH',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Changes customer's login.
     *
     * @tags Users
     * @name ChangeLogin
     * @summary Changes customer's login
     * @request PUT:/{baseSiteId}/users/{userId}/login
     * @secure
     */
    changeLogin: (
      baseSiteId: string,
      userId: string,
      data: LoginChange,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/login`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Place an order. <br/> (400) message:<b>Cart not found.</b> - When supplying an unknown cartID <br/> (400) message:<b>Base site XXX doesn't exist</b> - When supplying an unknown base site XXX <br/> (400) message:<b>Required boolean parameter 'termsChecked' is not present</b> - When not setting termsChecked <br/> (400) message:<b>Term is not checked.</b> - When termsChecked is set to false <br/> (400) message:<b>No Purchase Order Number on Cart</b> - When no PO is set on cart <br/> (400) message:<b>No Carrier selected on Cart</b> - When no carrier is set on cart <br/> (400) message:<b>Delivery address is not a valid value.</b> - When no delivery address is set on cart <br/> (401) message:<b>Full authentication is required to access this resource</b> - If not logged in with a user<br/> (403) message:<b>Access is denied</b> - When supplying a userId different from the logged in user
     *
     * @tags Orders
     * @name PlaceOrders
     * @summary Place an order.
     * @request POST:/{baseSiteId}/users/{userId}/mastershoporders
     * @secure
     */
    placeOrders: (
      baseSiteId: string,
      userId: string,
      query: {
        /**
         * ID of Cart
         * @default "{{cartId}}"
         */
        cartId: string;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * Terms checked?
         * @default "{{termsChecked}}"
         */
        termsChecked: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderResultList, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/mastershoporders`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging
     * @name GetSpecificThreadForUserAndOffer
     * @summary Gets the thread for a user and offer
     * @request GET:/{baseSiteId}/users/{userId}/offers/{offerId}/thread
     * @secure
     */
    getSpecificThreadForUserAndOffer: (
      baseSiteId: string,
      offerId: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<MessageThread, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/offers/${offerId}/thread`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging
     * @name ReplyToThread
     * @summary Post a message to the offers thread
     * @request POST:/{baseSiteId}/users/{userId}/offers/{offerId}/thread
     * @secure
     */
    replyToThread: (
      baseSiteId: string,
      offerId: string,
      userId: string,
      data: MessageRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/offers/${offerId}/thread`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Download a document attached to an offer's thread
     *
     * @tags Messaging
     * @name GetAttachedDocumentForUserAndOffer
     * @summary Download offer attachment.
     * @request GET:/{baseSiteId}/users/{userId}/offers/{offerId}/thread/documents/{documentId}
     * @secure
     */
    getAttachedDocumentForUserAndOffer: (
      baseSiteId: string,
      documentId: string,
      offerId: string,
      userId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/offers/${offerId}/thread/documents/${documentId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging
     * @name CreateThreadForUserAndOffer
     * @summary Create thread
     * @request POST:/{baseSiteId}/users/{userId}/offers/{offerId}/threads
     * @secure
     */
    createThreadForUserAndOffer: (
      baseSiteId: string,
      offerId: string,
      userId: string,
      data: MessageRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/offers/${offerId}/threads`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get a list of used tags visible to user
     *
     * @tags Orders
     * @name GetTags
     * @summary Get a list of used tags visible to user
     * @request GET:/{baseSiteId}/users/{userId}/orders/tags
     * @secure
     */
    getTags: (baseSiteId: string, userId: string, params: RequestParams = {}) =>
      this.request<TagsList, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/orders/tags`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns specific order details based on a specific order code. The response contains detailed order information.
     *
     * @tags Orders
     * @name GetOrderForUserByCode
     * @summary Get an order
     * @request GET:/{baseSiteId}/users/{userId}/orders/{code}
     * @secure
     */
    getOrderForUserByCode: (
      baseSiteId: string,
      code: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<Order, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/orders/${code}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replaces specific values on an order.
     *
     * @tags Orders
     * @name ReplaceValuesOnOrder
     * @summary Replaces values on order
     * @request PUT:/{baseSiteId}/users/{userId}/orders/{code}
     * @secure
     */
    replaceValuesOnOrder: (
      baseSiteId: string,
      code: string,
      userId: string,
      data: Order,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/orders/${code}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Appends specific values on an order.
     *
     * @tags Orders
     * @name AppendValuesOnOrder
     * @summary Appends values on order
     * @request PATCH:/{baseSiteId}/users/{userId}/orders/{code}
     * @secure
     */
    appendValuesOnOrder: (
      baseSiteId: string,
      code: string,
      userId: string,
      data: Order,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/orders/${code}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns specific order changes based on a specific order code.
     *
     * @tags Orders
     * @name GetOrderChanges
     * @summary Get an orders changes
     * @request GET:/{baseSiteId}/users/{userId}/orders/{code}/changes
     * @secure
     */
    getOrderChanges: (
      baseSiteId: string,
      code: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<ChangeValueList, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/orders/${code}/changes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns specific document for the given order code, consignment code and document id, or if there is a list of document ids returns a zip with all related documents. <br/>(400) message:<b>Invalid value: [$INVALID_VALUE], reason: [Document id must be present]</b> - In case of empty or null documentIds <br/>
     *
     * @tags Order Document Controller
     * @name DownloadDocument
     * @summary Get a document or a zip of documents
     * @request GET:/{baseSiteId}/users/{userId}/orders/{orderCode}/consignments/{consignmentCode}/documents
     * @secure
     */
    downloadDocument: (
      baseSiteId: string,
      consignmentCode: string,
      orderCode: string,
      userId: string,
      query: {
        /** The documentId's to download */
        documentIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/orders/${orderCode}/consignments/${consignmentCode}/documents`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging
     * @name GetOrderThreads
     * @summary Get a list of threads for a customer and an order
     * @request GET:/{baseSiteId}/users/{userId}/orders/{orderCode}/consignments/{consignmentCode}/threads
     * @secure
     */
    getOrderThreads: (
      baseSiteId: string,
      consignmentCode: string,
      orderCode: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * Limit
         * @default "20"
         */
        limit?: string;
        /**
         * Page token
         * @default "{{pageToken}}"
         */
        pageToken?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderThreads, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/orders/${orderCode}/consignments/${consignmentCode}/threads`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging
     * @name CreateThreadForUserAndOrder
     * @summary Create thread
     * @request POST:/{baseSiteId}/users/{userId}/orders/{orderCode}/consignments/{consignmentCode}/threads
     * @secure
     */
    createThreadForUserAndOrder: (
      baseSiteId: string,
      consignmentCode: string,
      orderCode: string,
      userId: string,
      data: MessageRequest,
      params: RequestParams = {},
    ) =>
      this.request<ThreadCreated, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/orders/${orderCode}/consignments/${consignmentCode}/threads`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging
     * @name GetSpecificThreadForUserAndOrder
     * @summary Gets the specified thread for a user and order
     * @request GET:/{baseSiteId}/users/{userId}/orders/{orderCode}/consignments/{consignmentCode}/threads/{threadId}
     * @secure
     */
    getSpecificThreadForUserAndOrder: (
      baseSiteId: string,
      consignmentCode: string,
      orderCode: string,
      threadId: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<MessageThread, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/orders/${orderCode}/consignments/${consignmentCode}/threads/${threadId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging
     * @name ReplyToOrderThread
     * @summary Post a message to specified thread
     * @request POST:/{baseSiteId}/users/{userId}/orders/{orderCode}/consignments/{consignmentCode}/threads/{threadId}
     * @secure
     */
    replyToOrderThread: (
      baseSiteId: string,
      consignmentCode: string,
      orderCode: string,
      threadId: string,
      userId: string,
      data: MessageRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/orders/${orderCode}/consignments/${consignmentCode}/threads/${threadId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Download a document attached to an offer's thread
     *
     * @tags Messaging
     * @name GetAttachedDocumentFromOrderChat
     * @summary Download offer attachment.
     * @request GET:/{baseSiteId}/users/{userId}/orders/{orderCode}/consignments/{consignmentCode}/threads/{threadId}/documents/{documentId}
     * @secure
     */
    getAttachedDocumentFromOrderChat: (
      baseSiteId: string,
      consignmentCode: string,
      documentId: string,
      orderCode: string,
      threadId: string,
      userId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/orders/${orderCode}/consignments/${consignmentCode}/threads/${threadId}/documents/${documentId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Returns a list of details about the documents available for an order
     *
     * @tags Order Document Controller
     * @name GetDocumentInfo
     * @summary Get the list of details for documents available for an order
     * @request GET:/{baseSiteId}/users/{userId}/orders/{orderCode}/documentinfos
     * @secure
     */
    getDocumentInfo: (
      baseSiteId: string,
      orderCode: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderDocumentInfo[], ErrorList>({
        path: `/${baseSiteId}/users/${userId}/orders/${orderCode}/documentinfos`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Downloads all documents for the order
     *
     * @tags Order Document Controller
     * @name DownloadAllOrderDocuments
     * @summary Download all documents.
     * @request GET:/{baseSiteId}/users/{userId}/orders/{orderCode}/documents
     * @secure
     */
    downloadAllOrderDocuments: (
      baseSiteId: string,
      orderCode: string,
      userId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/orders/${orderCode}/documents`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Downloads the document with the specified number and category
     *
     * @tags Order Document Controller
     * @name DownloadOrderDocuments
     * @summary Download documents.
     * @request GET:/{baseSiteId}/users/{userId}/orders/{orderCode}/documents/{docNum}
     * @secure
     */
    downloadOrderDocuments: (
      baseSiteId: string,
      docNum: string,
      orderCode: string,
      userId: string,
      query: {
        /**
         * The document category
         * @default "{{docCat}}"
         */
        docCat: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/orders/${orderCode}/documents/${docNum}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Download the End User Statement form as an editable PDF
     *
     * @tags Order Document Controller
     * @name DownloadEusPdfDocument
     * @summary Download EUS form
     * @request GET:/{baseSiteId}/users/{userId}/orders/{orderCode}/forms/eus
     * @secure
     */
    downloadEusPdfDocument: (
      baseSiteId: string,
      orderCode: string,
      userId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/orders/${orderCode}/forms/eus`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Changes customer's password.
     *
     * @tags Users
     * @name ChangePassword
     * @summary Changes customer's password
     * @request PUT:/{baseSiteId}/users/{userId}/password
     * @secure
     */
    changePassword: (
      baseSiteId: string,
      userId: string,
      data: PasswordChange,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/password`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Create a new product list
     *
     * @tags Product List
     * @name CreateProductList
     * @summary Create a new product list.
     * @request POST:/{baseSiteId}/users/{userId}/productlists
     * @secure
     */
    createProductList: (
      baseSiteId: string,
      userId: string,
      data: UserProductList,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /** Usecase of the list */
        productListType?: 'USER_CREATED' | 'MULTILINE_SEARCH' | 'BUILDING_QUOTE';
      },
      params: RequestParams = {},
    ) =>
      this.request<UserProductList, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/productlists`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Import file as product list. Only Satair products can be imported
     *
     * @tags Product List
     * @name ImportProductList
     * @summary Import file as product list. Only Satair products can be imported
     * @request POST:/{baseSiteId}/users/{userId}/productlists/import
     * @secure
     */
    importProductList: (
      baseSiteId: string,
      userId: string,
      data: ImportProductList,
      params: RequestParams = {},
    ) =>
      this.request<CorrelationId, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/productlists/import`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get imported product list from correlationId.
     *
     * @tags Product List
     * @name GetImportedProductList
     * @summary Get imported product list from correlationId.
     * @request GET:/{baseSiteId}/users/{userId}/productlists/import/{correlationId}
     * @secure
     */
    getImportedProductList: (
      baseSiteId: string,
      correlationId: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<ImportProductListStatus, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/productlists/import/${correlationId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of products and additional data such as: available facets, available sorting and pagination options.It can include spelling suggestions. To make spelling suggestions work you need to: 1. Make sure enableSpellCheck on the SearchQuery is set to true. By default it should be already set to true. 2. Have indexed properties configured to be used for spellchecking.
     *
     * @tags Product List
     * @name SearchProductLists1
     * @summary Get a list of products and additional data
     * @request GET:/{baseSiteId}/users/{userId}/productlists/search
     * @secure
     */
    searchProductLists1: (
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * The current result page requested.
         * @default "0"
         */
        currentPage?: string;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * The number of results returned per page.
         * @default 20
         */
        pageSize?: number;
        /**
         * Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
         * @default "{{q}}"
         */
        q?: string;
        /**
         * The context to be used in the search query.
         * @default "{{searchQueryContext}}"
         */
        searchQueryContext?: string;
        /** The value to sort by */
        sort?:
          | 'relevance'
          | 'creationTime-asc'
          | 'creationTime-desc'
          | 'modifiedTime-asc'
          | 'modifiedTime-desc';
      },
      params: RequestParams = {},
    ) =>
      this.request<UserProductListSearchPage, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/productlists/search`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of products and additional data such as: available facets, available sorting and pagination options.It can include spelling suggestions. To make spelling suggestions work you need to: 1. Make sure enableSpellCheck on the SearchQuery is set to true. By default it should be already set to true. 2. Have indexed properties configured to be used for spellchecking.
     *
     * @tags Product List
     * @name SearchProductLists
     * @summary Get a list of products and additional data
     * @request HEAD:/{baseSiteId}/users/{userId}/productlists/search
     * @secure
     */
    searchProductLists: (
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * The current result page requested.
         * @default "0"
         */
        currentPage?: string;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * The number of results returned per page.
         * @default 20
         */
        pageSize?: number;
        /**
         * Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
         * @default "{{q}}"
         */
        q?: string;
        /**
         * The context to be used in the search query.
         * @default "{{searchQueryContext}}"
         */
        searchQueryContext?: string;
        /** The value to sort by */
        sort?:
          | 'relevance'
          | 'creationTime-asc'
          | 'creationTime-desc'
          | 'modifiedTime-asc'
          | 'modifiedTime-desc';
      },
      params: RequestParams = {},
    ) =>
      this.request<UserProductListSearchPage, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/productlists/search`,
        method: 'HEAD',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the product list as an Excel file.
     *
     * @tags Product List
     * @name ExportProductListAsExcel
     * @summary Export a specific product list to Excel
     * @request GET:/{baseSiteId}/users/{userId}/productlists/{productlistCode}/export
     * @secure
     */
    exportProductListAsExcel: (
      baseSiteId: string,
      productlistCode: string,
      userId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/productlists/${productlistCode}/export`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Returns the productlist.
     *
     * @tags Product List
     * @name GetProductList
     * @summary Get a specific productlist
     * @request GET:/{baseSiteId}/users/{userId}/productlists/{productlistcode}
     * @secure
     */
    getProductList: (
      baseSiteId: string,
      productlistcode: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<UserProductList, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/productlists/${productlistcode}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Changes productlist name.
     *
     * @tags Product List
     * @name ChangeProductListName
     * @summary Changes productlist name
     * @request POST:/{baseSiteId}/users/{userId}/productlists/{productlistcode}
     * @secure
     */
    changeProductListName: (
      baseSiteId: string,
      productlistcode: string,
      userId: string,
      query: {
        /**
         * ProductList new name.
         * @default "{{newName}}"
         */
        newName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/productlists/${productlistcode}`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Delete customer's productlist.
     *
     * @tags Product List
     * @name DeleteProductList
     * @summary Delete customer's productlist
     * @request DELETE:/{baseSiteId}/users/{userId}/productlists/{productlistcode}
     * @secure
     */
    deleteProductList: (
      baseSiteId: string,
      productlistcode: string,
      userId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/productlists/${productlistcode}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Adds a productList entry to the ProductList or overrides the quantity if entry exists.
     *
     * @tags Product List
     * @name AddOrUpdateProductListEntryOverridingQuantity
     * @summary Adds a productList entry to the ProductList or overrides the quantity if entry exists.
     * @request PUT:/{baseSiteId}/users/{userId}/productlists/{productlistcode}/entries
     * @secure
     */
    addOrUpdateProductListEntryOverridingQuantity: (
      baseSiteId: string,
      productlistcode: string,
      userId: string,
      query: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * Product or offer id.
         * @default "{{id}}"
         */
        id?: string;
        /**
         * Product code.
         * @default "{{productCode}}"
         */
        productCode?: string;
        /**
         * Product quantity.
         * @default "{{quantity}}"
         */
        quantity: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserProductList, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/productlists/${productlistcode}/entries`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Adds a productList entry to the ProductList or sums up the quantities if entry exists.
     *
     * @tags Product List
     * @name AddOrUpdateProductListEntry
     * @summary Adds a productList entry to the ProductList or sums up the quantities if entry exists.
     * @request POST:/{baseSiteId}/users/{userId}/productlists/{productlistcode}/entries
     * @secure
     */
    addOrUpdateProductListEntry: (
      baseSiteId: string,
      productlistcode: string,
      userId: string,
      query: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * Product or offer id.
         * @default "{{id}}"
         */
        id?: string;
        /**
         * Product code.
         * @default "{{productCode}}"
         */
        productCode?: string;
        /**
         * Product quantity.
         * @default "{{quantity}}"
         */
        quantity: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserProductList, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/productlists/${productlistcode}/entries`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Removes a productList entry from the productList
     *
     * @tags Product List
     * @name DeleteProductListEntry
     * @summary Removes a productList entry from ProductList.
     * @request DELETE:/{baseSiteId}/users/{userId}/productlists/{productlistcode}/entries
     * @secure
     */
    deleteProductListEntry: (
      baseSiteId: string,
      productlistcode: string,
      userId: string,
      query?: {
        /**
         * Product id.
         * @default "{{id}}"
         */
        id?: string;
        /**
         * Product code.
         * @default "{{productCode}}"
         */
        productCode?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/productlists/${productlistcode}/entries`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Removes a productList unresolved entry from the productList
     *
     * @tags Product List
     * @name DeleteProductListUnresolvedEntry
     * @summary Removes a productList unresolved entry from ProductList.
     * @request DELETE:/{baseSiteId}/users/{userId}/productlists/{productlistcode}/unresolvedentry
     * @secure
     */
    deleteProductListUnresolvedEntry: (
      baseSiteId: string,
      productlistcode: string,
      userId: string,
      query?: {
        /**
         * Product manufacturer cage code.
         * @default "{{cageCode}}"
         */
        cageCode?: string;
        /**
         * Product part number.
         * @default "{{partNumber}}"
         */
        partNumber?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/productlists/${productlistcode}/unresolvedentry`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Creates a quote for the given offer ID and quantity. Must contain subject and message.
     *
     * @tags Quotes
     * @name CreateQuoteRequest
     * @summary Creates a new marketplace quote for a single offer
     * @request POST:/{baseSiteId}/users/{userId}/quotes
     * @secure
     */
    createQuoteRequest: (
      baseSiteId: string,
      userId: string,
      data: OfferQuoteRequest,
      params: RequestParams = {},
    ) =>
      this.request<Quote, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/quotes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messaging
     * @name ReplyToQuote
     * @summary Post a message to specified quote response
     * @request POST:/{baseSiteId}/users/{userId}/quotes/{quoteCode}/shopQuotes/{miraklQuoteRequestId}/messages
     * @secure
     */
    replyToQuote: (
      baseSiteId: string,
      miraklQuoteRequestId: string,
      quoteCode: string,
      userId: string,
      data: MessageRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/quotes/${quoteCode}/shopQuotes/${miraklQuoteRequestId}/messages`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Download an attachment from a specific message on a quote
     *
     * @tags Messaging
     * @name GetAttachmentFromMessageOnQuote
     * @summary Download quote attachment.
     * @request GET:/{baseSiteId}/users/{userId}/quotes/{quoteCode}/shopQuotes/{miraklQuoteRequestId}/messages/{messageId}/attachments
     * @secure
     */
    getAttachmentFromMessageOnQuote: (
      baseSiteId: string,
      messageId: string,
      miraklQuoteRequestId: string,
      quoteCode: string,
      userId: string,
      query?: {
        /**
         * Attachment ID
         * @default "{{attachmentId}}"
         */
        attachmentId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/quotes/${quoteCode}/shopQuotes/${miraklQuoteRequestId}/messages/${messageId}/attachments`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Gets detailed information about a quote.
     *
     * @tags Quotes
     * @name GetQuote
     * @summary Get a quote by code
     * @request GET:/{baseSiteId}/users/{userId}/quotes/{quoteId}
     * @secure
     */
    getQuote: (
      baseSiteId: string,
      quoteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<Quote, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/quotes/${quoteId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Calculate entry totals and Quote total based on quote prices
     *
     * @tags Quotes
     * @name CalculateTotals
     * @summary Calculate a quote
     * @request POST:/{baseSiteId}/users/{userId}/quotes/{quoteId}/calculatetotals
     * @secure
     */
    calculateTotals: (
      baseSiteId: string,
      quoteId: string,
      userId: string,
      data: ListOfQuoteEntriesToAdd,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<QuoteTotals, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/quotes/${quoteId}/calculatetotals`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets a quote document by code
     *
     * @tags Quotes
     * @name GetQuoteDocument
     * @summary Gets a quote document by code
     * @request GET:/{baseSiteId}/users/{userId}/quotes/{quoteId}/document
     * @secure
     */
    getQuoteDocument: (
      baseSiteId: string,
      quoteId: string,
      userId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/quotes/${quoteId}/document`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quotes
     * @name PlaceOrder
     * @summary Place an order based on a Satair quote
     * @request POST:/{baseSiteId}/users/{userId}/quotes/{quoteId}/orders
     * @secure
     */
    placeOrder: (
      baseSiteId: string,
      quoteId: string,
      userId: string,
      data: OrderOfferQuoteRequest,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<Order, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/quotes/${quoteId}/orders`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description The body needs only forwarderId, purchaseOrderNumber, and namedDeliveryDate. The rest of the attributes are ignored. All lines of the quote response will be included in the order.
     *
     * @tags Quotes
     * @name PlaceMarketplaceOrder
     * @summary Place an order based on a Mirakl quote response.
     * @request POST:/{baseSiteId}/users/{userId}/quotes/{quoteId}/quoteresponses/{quoteResponseId}/order
     * @secure
     */
    placeMarketplaceOrder: (
      baseSiteId: string,
      quoteId: string,
      quoteResponseId: string,
      userId: string,
      data: OrderOfferQuoteRequest,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<Order, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/quotes/${quoteId}/quoteresponses/${quoteResponseId}/order`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets the list of responses, i.e., actual quotes, from Mirakl, including all messages.
     *
     * @tags Quotes
     * @name GetMiraklQuoteResponses
     * @summary Get responses to a Mirakl quote
     * @request GET:/{baseSiteId}/users/{userId}/quotes/{quoteId}/responses
     * @secure
     */
    getMiraklQuoteResponses: (
      baseSiteId: string,
      quoteId: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<QuoteResponses, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/quotes/${quoteId}/responses`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a quote for the given list of materialNumberExternal and quantity pairs. <br/>Max allowed quantity is 999999. <br/>Max allowed reference length is 255. <br/>Max allowed poNumber length is 35. <br/>Max allowed amount of products is satairocc.QuotesController.maxProducts<br><b>Note:</b>The salesUnitCode on entries is ignored
     *
     * @tags Quotes
     * @name CreateSatairQuote
     * @summary Creates a new quote for Satair products
     * @request POST:/{baseSiteId}/users/{userId}/satairquotes
     * @secure
     */
    createSatairQuote: (
      baseSiteId: string,
      userId: string,
      data: QuoteRequest,
      params: RequestParams = {},
    ) =>
      this.request<Quote, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/satairquotes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Validates a list of products to give an overview of all problems that the list might have<br/>Max allowed quantity for each product is 999999. <br/>Max allowed amount of products per request is satairocc.QuotesController.maxProducts
     *
     * @tags Quotes
     * @name Validate
     * @summary Validate a list of products to give an overview of all problems that list might have
     * @request POST:/{baseSiteId}/users/{userId}/satairquotes/validate
     * @secure
     */
    validate: (
      baseSiteId: string,
      userId: string,
      data: ProductEntry[],
      params: RequestParams = {},
    ) =>
      this.request<ProductsValidationList, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/satairquotes/validate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name SearchUsers1
     * @summary Search for users
     * @request GET:/{baseSiteId}/users/{userId}/search
     * @secure
     */
    searchUsers1: (
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * The current result page requested.
         * @default "0"
         */
        currentPage?: string;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * The number of results returned per page.
         * @default "20"
         */
        pageSize?: string;
        /**
         * Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
         * @default "{{q}}"
         */
        q?: string;
        /**
         * The context to be used in the search query.
         * @default "{{searchQueryContext}}"
         */
        searchQueryContext?: string;
        /** The value to sort by */
        sort?: 'relevance' | 'name-asc' | 'name-desc';
      },
      params: RequestParams = {},
    ) =>
      this.request<UserSearchPage, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/search`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name SearchUsers
     * @summary Search for users
     * @request HEAD:/{baseSiteId}/users/{userId}/search
     * @secure
     */
    searchUsers: (
      baseSiteId: string,
      userId: string,
      query?: {
        /**
         * The current result page requested.
         * @default "0"
         */
        currentPage?: string;
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
        /**
         * The number of results returned per page.
         * @default "20"
         */
        pageSize?: string;
        /**
         * Serialized query, free text search, facets. The format of a serialized query: freeTextSearch:sort:facetKey1:facetValue1:facetKey2:facetValue2
         * @default "{{q}}"
         */
        q?: string;
        /**
         * The context to be used in the search query.
         * @default "{{searchQueryContext}}"
         */
        searchQueryContext?: string;
        /** The value to sort by */
        sort?: 'relevance' | 'name-asc' | 'name-desc';
      },
      params: RequestParams = {},
    ) =>
      this.request<UserSearchPage, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/search`,
        method: 'HEAD',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Adds the user to finance role for linked account of the calling admin or remove itcustomergroup or remove it
     *
     * @tags UserAdmin
     * @name SetFinance
     * @request POST:/{baseSiteId}/users/{userId}/setfinance
     * @secure
     */
    setFinance: (
      baseSiteId: string,
      userId: string,
      query: {
        /** @default "{{financeCustomer}}" */
        financeCustomer: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/setfinance`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Adds the user to 'buyerCustomerGroup' when 'readOnly' is 'false' or remove the user from 'buyerCustomerGroup' when 'readOnly' is 'true'
     *
     * @tags UserAdmin
     * @name SetReadOnly
     * @request POST:/{baseSiteId}/users/{userId}/setreadonly
     * @secure
     */
    setReadOnly: (
      baseSiteId: string,
      userId: string,
      query: {
        /** @default "{{readOnly}})" */
        readOnly: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/setreadonly`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Get all the current unit’s ship-to addresses, where restricted addresses are marked with restrictedAddress: true(401) message:<b>Full authentication is required to access this resource</b> - If not logged in with a user<br/>(403) message:<b>Access is denied</b> - When supplying a userId different from the logged in user
     *
     * @tags Shops
     * @name GetShippingAddresses
     * @summary Gets all ship-to addresses for a specific shop.
     * @request GET:/{baseSiteId}/users/{userId}/shops/{id}/shippingaddresses
     * @secure
     */
    getShippingAddresses: (
      baseSiteId: string,
      id: string,
      userId: string,
      query?: {
        /**
         * Response configuration (list of fields, which should be returned in response)
         * @default "DEFAULT"
         */
        fields?: 'BASIC' | 'DEFAULT' | 'FULL';
      },
      params: RequestParams = {},
    ) =>
      this.request<AddressList, ErrorList>({
        path: `/${baseSiteId}/users/${userId}/shops/${id}/shippingaddresses`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
