<template>
  <StandardDialogModal class="modal-export-sales-orders" :size="uiSize.Medium">
    <template #header>
      <h3>
        {{ textK('UI_ORDERS_DOWNLOAD_MODAL_AIRBUS_TITLE') }}
      </h3>
    </template>

    <template #body>
      <p>
        <span v-html="descriptionPartOne"></span>
        (
        <span v-if="isFetchingOrders" class="modal-export-sales-orders__loader"
          ><DotLoader :loading="true" to-color="#000"
        /></span>
        <span v-else
          ><b>{{ totalCount }}</b></span
        >
        )
        <span v-html="descriptionPartTwo"></span>
      </p>
    </template>

    <template #actions>
      <VButton
        @click="exportOrders('CSV')"
        :loading="isDownloadCsvBusy || isFetchingOrders"
        :disabled="isExportButtonDisabled"
        :type="uiVariant.Secondary"
        class="modal-export-sales-orders__button"
        >{{ textK('UI_SERVICE_ORDERS_LEASE_EXPORT_MODAL_EXPORT_CSV') }}</VButton
      >
      <VButton
        @click="exportOrders('EXCEL')"
        :loading="isDownloadExcelBusy || isFetchingOrders"
        :disabled="isExportButtonDisabled"
        :type="uiVariant.Secondary"
        class="modal-export-sales-orders__button"
        >{{ textK('UI_SERVICE_ORDERS_LEASE_EXPORT_MODAL_EXPORT_EXCEL') }}</VButton
      >
    </template>
  </StandardDialogModal>
</template>

<style src="./modal-export-sales-orders.scss" lang="scss" scoped></style>

<script setup lang="ts">
import { OrderApi } from '@/src/core/api';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { TextKey } from '@/src/core/services/text-key';
import { useDownloadsStore } from '@/src/core/stores/downloads';
import { useOrdersStore } from '@/src/market/stores/orders';
import { computed, onMounted, ref } from 'vue';

const textK = useText();
const ordersStore = useOrdersStore();
const downloadsStore = useDownloadsStore();

const uiSize = UiSize;
const uiVariant = UiVariant;

const isDownloadExcelBusy = ref<boolean>(false);
const isDownloadCsvBusy = ref<boolean>(false);
const descriptionPartOne = ref<string>('');
const descriptionPartTwo = ref<string>('');
const MAX_EXPORT_COUNT = 100;

const airbusOrders = computed(() => {
  return ordersStore.airbusOrderIds;
});

const totalCount = computed(() => {
  return airbusOrders.value.length <= MAX_EXPORT_COUNT
    ? airbusOrders.value.length
    : MAX_EXPORT_COUNT;
});

const isFetchingOrders = computed(() => {
  return ordersStore.isBusy;
});

const exportOrderNumbers = computed(() => {
  return ordersStore.airbusOrderIds;
});

const isExportButtonDisabled = computed(() => {
  return (
    totalCount.value === 0 ||
    isFetchingOrders.value ||
    isDownloadExcelBusy.value ||
    isDownloadCsvBusy.value
  );
});

const exportOrders = async (format: 'EXCEL' | 'CSV') => {
  if (format == 'EXCEL') {
    isDownloadExcelBusy.value = true;
  } else {
    isDownloadCsvBusy.value = true;
  }

  await downloadsStore.downloadFile(
    {
      url: OrderApi.ExportSalesOrders,
      method: 'POST',
      params: { fileFormat: format },
      data: { orderNumbers: exportOrderNumbers.value },
    },
    undefined,
    OrderApi.HandleExportSalesOrders,
  );

  if (format == 'EXCEL') {
    isDownloadExcelBusy.value = false;
  } else {
    isDownloadCsvBusy.value = false;
  }
};

onMounted(() => {
  const text = TextKey('UI_ORDERS_DOWNLOAD_MODAL_AIRBUS_DESCRIPTION').split('[[number]]');
  descriptionPartOne.value = text[0];
  descriptionPartTwo.value = text[1];
});
</script>
