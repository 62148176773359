<template>
  <form @submit.prevent="resetPassword">
    <StandardDialogModal :has-cancel-button="true" :size="uiSize.Medium">
      <template #header>
        <div class="typeset">
          <h3>
            {{ textK('UI_ADMINPAGE_RESETPASSWORDCONFIRMATION') }}
            {{ !!userData ? userData.Name : textK('UI_ADMINPAGE_THISUSER') }}
          </h3>
          <p>{{ textK('UI_ADMINPAGE_RESETPASSWORDBODYTEXT') }}</p>
        </div>
      </template>
      <template #actions>
        <VButton
          :type="uiVariant.Default"
          v-ui-test="'reset-password-confirm-button'"
          :loading="isBusy"
          @click="resetPassword"
          >{{ textK('UI_COMMON_RESETPASSWORD') }}</VButton
        >
      </template>
    </StandardDialogModal>
  </form>
</template>

<script lang="ts" setup>
import { UserApi } from '@/src/core/api';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { Req } from '@/src/core/services/requester';
import { useModalStore } from '@/src/core/stores/modal';
import { IUser } from '@/src/core/types/interfaces';
import { useUserStore } from '@/src/profile/stores/user';
import { computed, onMounted, ref, watch } from 'vue';

const textK = useText();
const modalStore = useModalStore();
const uiVariant = UiVariant;
const uiSize = UiSize;
const userData = ref<IUser>({} as IUser);
const isBusy = ref(false);
const userStore = useUserStore();

const resetPassword = async (event: Event) => {
  event.preventDefault();
  isBusy.value = true;
  modalStore.lockCurrentModal(true);

  const { IsSuccess } = await Req({
    url: UserApi.ResetUserPassword(
      userData.value ? encodeURIComponent(userData.value.Uid) : 'current',
    ),
    method: 'POST',
  });

  if (IsSuccess) {
    modalStore.closeModal();
    removeBusy();
  } else {
    removeBusy();
  }
};

const removeBusy = () => {
  modalStore.lockCurrentModal(false);
  isBusy.value = false;
};

const modalParams = computed(() => modalStore.params as IUser);

onMounted(() => {
  userData.value = modalParams.value || ({} as IUser);
});

watch(
  () => userStore.currentUser,
  () => {
    modalStore.closeModal();
  },
);
</script>
