<template>
  <StandardDialogModal class="list-modal" :size="uiSize.Small" :has-close-button="false">
    <template #header>
      <h3>{{ textK('UI_QUOTES_ACCOUNT_SWITCH_TITLE') }}</h3>
    </template>

    <template #body>
      <p>{{ textK('UI_QUOTES_ACCOUNT_SWITCH_DESCRIPTION') }}</p>
    </template>

    <template #actions>
      <VButton
        v-ui-test="'disable-account-ok-button'"
        :type="uiVariant.Default"
        @click="closeModal()"
        >{{ textK('UI_COMMON_OK') }}</VButton
      >
    </template>
  </StandardDialogModal>
</template>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';

const textK = useText();
const modalStore = useModalStore();
const uiSize = UiSize;
const uiVariant = UiVariant;

const closeModal = () => {
  modalStore.closeModal();
};
</script>
