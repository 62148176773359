<template>
  <div class="seller-conversation-messaging-disabled__header">
    <h3 class="seller-conversation-messaging-disabled__title">
      {{ textK('UI_SELLER_CONVERSATION_QUOTE_CHAT_CLOSED_HEADER') }}
    </h3>
    <p class="seller-conversation-messaging-disabled__sub-title">
      {{ textK('UI_SELLER_CONVERSATION_QOUTE_CHAT_CLOSED_DESCRIPTION') }}
    </p>
  </div>
</template>

<style lang="scss" src="./seller-conversation-messaging-disabled.scss" scoped />

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import useText from '@/src/core/hooks/useText';

@Component({})
export class SellerConversationMessagingDisabled extends Vue {
  public textK = useText();
}

export default toNative(SellerConversationMessagingDisabled);
</script>
