import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

/**
 * We're using dayjs to format all dates
 * * available formats: https://day.js.org/docs/en/display/format
 */

// TODO: Rename constants below to agreed format
export const InputDateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
export const InputDateFormatWithoutZone = 'YYYY-MM-DDTHH:mm:ss';
export const SAPDataFormat = 'YYYY/MM/DD';
export const MonthDayFormat = 'MM/DD';
export const LocalTime = 'h:mm A (Z)';
export const DisplayDateFormat = 'YYYY-MM-DD';
export const HYBRIS_DATE_FORMAT = 'YYYY-MM-DD';

dayjs.extend(utc);

export function DateFormatUTC(value: string): string {
  if (!value || !dayjs(value).isValid()) {
    return '';
  }
  // Ensure parsing and formatting are done in UTC
  return dayjs(value).utc().format('YYYY/MM/DD');
}

export function DateFormat(value: string): string {
  // ! .NET Legacy DisplayFriendly()
  if (!value || !dayjs(value, InputDateFormat).isValid()) {
    return '';
  }
  return dayjs(value, InputDateFormat).format(SAPDataFormat);
}

export function TimeFormat(value: string): string {
  if (!value || !dayjs(value, InputDateFormat).isValid()) {
    return '';
  }
  return dayjs(value, InputDateFormat).format(LocalTime);
}

export function TimeLeadingZero(value: string): string {
  return value.toString().length === 1 ? '0' + value : value;
}

export function DateTimeFormat(value: string): string {
  if (!value || !dayjs(value, InputDateFormat).isValid()) {
    return '';
  }
  return dayjs(value, InputDateFormat).format(SAPDataFormat + ', ' + LocalTime);
}

export function DateTimeDiffFormat(value: string): string {
  if (!value || !dayjs(value, InputDateFormat).isValid()) {
    return '';
  }
  dayjs.extend(relativeTime);
  const date = dayjs(value, InputDateFormat);

  if (date.diff(dayjs(), 'day') >= 0) {
    return date.fromNow();
  } else {
    return date.format(SAPDataFormat + ', ' + LocalTime);
  }
}

export function MonthDayDate(value: string): string {
  if (!value || !dayjs(value, InputDateFormat).isValid()) {
    return '';
  }
  return dayjs(value, InputDateFormat).format(MonthDayFormat);
}

export const getStartOfCurrentMonth = () => dayjs().set('date', 1);

export const getStartOfLastMonth = () => {
  const currentMonth = dayjs().month();
  const lastMonth = currentMonth - 1;

  return dayjs()
    .set('month', lastMonth) // * lastMonth = -1 will safely rollback selected year
    .set('date', 1);
};

export const getEndOfLastMonth = () => {
  const lastMonth = getStartOfLastMonth();
  const daysInMonth = lastMonth.daysInMonth();

  return lastMonth.set('date', daysInMonth);
};

export const getStartOfCurrentYear = () =>
  dayjs()
    .set('month', 0) // * months are 0 based
    .set('date', 1); // but not days :)

export const toHybrisFormat = (dayJsDate: Dayjs) => dayJsDate.format(HYBRIS_DATE_FORMAT);
