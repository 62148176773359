<template>
  <div class="upload-wrapper typeset">
    <transition
      name="fade"
      enter-active-class="animated fadeInTranslateUp"
      leave-active-class="animated fadeOutTranslateDown"
      mode="out-in"
    >
      <div v-if="isBusy" class="upload-options-loader" :key="0">
        <AssistantIcon :mode="assistantMode.loading" class="assistant-icon"></AssistantIcon>
      </div>

      <div v-else class="upload-options" :key="1">
        <h3 class="upload-options__header">{{ textK('UI_UPLOAD_OPTIONS_HEADER') }}</h3>
        <form class="upload-options__form" @submit.prevent="importlist()">
          <div class="upload-options__form__input" v-ui-test="'upload-options'">
            <VTextField
              ref="namefield"
              v-model="name"
              :size="uiSize.Large"
              :label="textK('UI_UPLOAD_OPTIONS_LISTNAME_LABEL')"
            ></VTextField>
          </div>
          <VButton
            :disabled="name.length === 0 || !isMappingValid || !name.trim()"
            class="upload-options__button"
            :loading="isBusy"
            >{{ textK('UI_UPLOAD_OPTIONS_IMPORT_BTN') }}</VButton
          >
        </form>

        <div class="upload-options__preview" v-if="importPreview">
          <h6>
            {{
              !importPreview.HeadersUnidentified
                ? textK('UI_UPLOAD_OPTIONS_PREVIEW_SUCCESS')
                : textK('UI_UPLOAD_OPTIONS_PREVIEW_NOMATCH')
            }}
          </h6>
          <div class="upload-options__preview__wrapper">
            <transition-group
              name="upload-option"
              tag="div"
              class="upload-options__table-wrapper"
              :class="{ 'upload-options__table-wrapper--oversize': importPreview.Data.length > 5 }"
            >
              <template v-for="(column, i) in importPreview.Data[0]">
                <div
                  class="upload-options__table-column"
                  v-if="importPreviewColumnsIndex.includes(i)"
                  :key="column + i"
                >
                  <div
                    class="upload-options__table-cell upload-options__table-cell--select"
                    :class="{
                      'upload-options__table-cell--not-used':
                        getSelectedMappingIndexForColumn(i) === -1,
                      'upload-options__table-cell--edit': editMode,
                    }"
                  >
                    <VSelect
                      :type="uiVariant.Transparent"
                      :options="headerOptions"
                      :disabled="!editMode"
                      :placeholder="textK('UI_UPLOAD_NOTUSED')"
                      :modelValue="getSelectedMappingIndexForColumn(i)"
                      :is-assistant="true"
                      @update:modelValue="setSelectedMappingIndexForColumn($event, i)"
                    >
                    </VSelect>
                  </div>
                  <div
                    v-for="(row, ri) in importPreview.Data.slice(0, 5)"
                    :key="ri"
                    :title="titleTag(row[i])"
                    class="upload-options__table-cell"
                    :class="{
                      'upload-options__table-cell--header':
                        ri === 0 && importPreview.Mapping.HasHeaders,
                      'upload-options__table-cell--not-used':
                        getSelectedMappingIndexForColumn(i) === -1,
                    }"
                  >
                    {{ row[i] || '&nbsp;' }}
                  </div>
                </div>
              </template>
            </transition-group>
          </div>
        </div>
        <VButton
          :disabled="isMappingEmpty"
          class="upload-options__button"
          :type="uiVariant.Secondary"
          @click="changeEditMode"
          >{{
            editMode
              ? textK('UI_UPLOAD_OPTIONS_PREVIEW_HIDE')
              : textK('UI_UPLOAD_OPTIONS_PREVIEW_CHANGE')
          }}</VButton
        >
      </div>
    </transition>
  </div>
</template>

<style src="./upload-options.scss" lang="scss" scoped></style>

<script lang="ts" setup>
import AssistantIcon from '@/src/core/components/assistant/assistant-icon/assistant-icon.vue';
import { AssistantMode } from '@/src/core/components/assistant/assistant-icon/assistant-mode';
import VButton from '@/src/core/components/ui/button/button.vue';
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import useTitleTag from '@/src/core/hooks/useTitleTag';
import { useAssistantStore } from '@/src/core/stores/assistant';
import { IListImportHeaders, IListImportPreview } from '@/src/core/types/interfaces';
import { IVSelect } from '@/src/core/types/ui';
import { SubContentEventBus } from '@/src/core/utils/sub-content-event-bus';
import { useListsStore } from '@/src/market/stores/list';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const textK = useText();
const listsStore = useListsStore();
const assistantStore = useAssistantStore();
const router = useRouter();
const titleTag = useTitleTag;
const uiSize = UiSize;
const uiVariant = UiVariant;
const name = ref('');
const assistantMode = AssistantMode;
const editMode = ref(false);
const namefield = ref<VTextField | null>(null);

const importPreview = computed<IListImportPreview | undefined>(() => {
  return listsStore.importPreview;
});

const importPreviewColumnsIndex = computed<number[]>(() => {
  if (editMode.value) {
    return (
      (importPreview.value &&
        importPreview.value.Data &&
        importPreview.value.Data[0].map((x, i) => i)) ||
      []
    );
  } else {
    return headerMappings.value.filter((x) => x.Value >= 0).map((x) => x.Value);
  }
});

const headerMappings = computed<IListImportHeaders[]>(() => {
  return (importPreview.value && importPreview.value.HeaderMappings) || [];
});

const isBusy = computed<boolean>(() => {
  return listsStore.isBusy;
});

const headerOptions = computed(() => {
  const headers = importPreview.value && importPreview.value.HeaderMappings;
  const formattedHeaders: IVSelect[] | undefined =
    headers &&
    headers.map((item) => {
      return { label: item.Label, value: '' + item.IdxName };
    });

  if (formattedHeaders) {
    formattedHeaders.push({ label: 'Deselect', value: '' });
  }
  return formattedHeaders;
});

const isMappingValid = computed<boolean>(() => {
  return !!headerMappings.value.find(
    (x) => (x.IdxName === 'SkuIdx' && x.Value !== -1) || (x.IdxName === 'PnoIdx' && x.Value !== -1),
  );
});

const isMappingEmpty = computed<boolean>(() => {
  return !headerMappings.value.find((x) => x.Value !== -1);
});

const getSelectedMappingIndexForColumn = (index: number): number => {
  return headerMappings.value.findIndex((x) => x.Value === index);
};

const setSelectedMappingIndexForColumn = (headerIndex: number, columnIndex: number) => {
  if (importPreview.value && importPreview.value.HeaderMappings) {
    const headerIdxName: string | undefined =
      (importPreview.value.HeaderMappings[headerIndex] &&
        importPreview.value.HeaderMappings[headerIndex].IdxName) ||
      undefined;

    if (headerIdxName) {
      listsStore.updateImportMapping({
        headerIndex: importPreview.value.HeaderMappings[headerIndex].IdxName,
        columnIndex,
      });
    } else {
      const currentHeaderIdx = importPreview.value.HeaderMappings.find(
        (x) => x.Value === columnIndex,
      );

      if (currentHeaderIdx) {
        listsStore.updateImportMapping({
          headerIndex: currentHeaderIdx.IdxName,
          columnIndex: -1,
        });
      }
    }
  }
};

const changeEditMode = () => {
  editMode.value = !editMode.value;
};

const importlist = async () => {
  if (
    importPreview.value &&
    importPreview.value.Data &&
    importPreview.value.Mapping &&
    name.value
  ) {
    assistantStore.lockAssistant({ assistantLocked: true });
    const { IsSuccess, Data } = await listsStore.importUploadedList({ name: name.value });

    if (IsSuccess) {
      // close all open subcontents before opening the list detail page subcontent
      // and make sure the subcontent is closed before opening a new one
      SubContentEventBus.$emit('closeSubContent');
      await nextTick();

      router.push({ name: 'list', params: { listid: Data.ListCode } });
    }
  }
};

onMounted(() => {
  name.value = (importPreview.value && importPreview.value.Filename) || '';
  editMode.value = (importPreview.value && importPreview.value.HeadersUnidentified) || false;
  namefield.value?.setFocus();
});
</script>
