<template>
  <div class="account-statement-filter-modal">
    <StandardDialogModal :size="uiSize.Large" :has-cancel-button="false">
      <template #header>
        <h3>
          {{ textK('UI_DOCUMENTS_FILTERMODAL_HEADER') }}
        </h3>
      </template>
      <template #body>
        <AccountStatementAirbusFilter v-if="isAirbus" :is-modal="true" />
        <AccountStatementFilters v-else :is-modal="true" />
      </template>
      <template #actions>
        <VButton :type="uiVariant.Primary" @click="close">
          {{ textK('UI_DOCUMENTS_FILTERMODAL_ACTION') }}
        </VButton>
      </template>
    </StandardDialogModal>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import AccountStatementFilters from '@/src/finance/components/account-statement/account-statement-filter/account-statement-filters.vue';
import AccountStatementAirbusFilter from '@/src/finance/components/account-statement-airbus/account-statement-airbus-filter/account-statement-airbus-filter.vue';

interface Params {
  isAirbus: boolean;
}

const textK = useText();
const modalStore = useModalStore();
const uiVariant = UiVariant;
const uiSize = UiSize;

const params = computed(() => modalStore.params as Params);
const isAirbus = computed(() => params.value.isAirbus);

const close = () => {
  modalStore.closeModal();
};
</script>
