<template>
  <div class="faq-header" :class="{ 'faq-header--collapsed': !hasCategories }">
    <div class="faq-header__inner-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<style src="./faq-header.scss" lang="scss"></style>

<script lang="ts" setup>
import { useFaqStore } from '@/src/content/stores/faq';
import { computed } from 'vue';

const faqStore = useFaqStore();

const hasCategories = computed(() => {
  return faqStore.categories?.length > 0;
});
</script>
