<template>
  <div
    class="modal-column"
    :class="{
      'one-third': size === uiColumnSize.OneThird,
      'two-thirds': size === uiColumnSize.TwoThirds,
    }"
    ref="scroll"
  >
    <div v-if="$slots.header" class="modal-column__header">
      <slot name="header" />
    </div>
    <slot />
  </div>
</template>

<style scoped src="./modal-column.scss" lang="scss"></style>

<script lang="ts" setup>
import { UiColumnSize } from '@/src/core/components/ui/ui.types';
import { useModalStore } from '@/src/core/stores/modal';
import { computed, ref, watch } from 'vue';

interface Props {
  size?: UiColumnSize;
}

withDefaults(defineProps<Props>(), {
  size: UiColumnSize.Default,
});

const uiColumnSize = UiColumnSize;
const modalStore = useModalStore();
const scroll = ref<HTMLElement | null>(null);

const modals = computed(() => {
  return modalStore;
});

watch(
  () => modals,
  () => {
    if (scroll.value) {
      scroll.value.scrollTop = 0;
    }
  },
);
</script>
