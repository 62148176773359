import { UserApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';

const ValidateToken = async (token: string) => {
  const { IsSuccess } = await Req({
    url: UserApi.ValidateUserToken(token),
    method: 'POST',
    data: {
      token,
    },
  });
  return { IsSuccess };
};

export default ValidateToken;
