import { NotificationTypes, ValidationError } from '@/src/core/types/api';
import { INotificationItem } from '@/src/core/types/ui';

export const validationErrorToNotification = (error: ValidationError) => ({
  CloseDrawer: false,
  Title: 'Error',
  Description: error.message,
  Timing: 10000,
  Type: 'Error' as NotificationTypes.Error,
});

export const mapValidationErrorToNotification = (errors?: ValidationError[]): INotificationItem[] =>
  errors?.map(validationErrorToNotification) ?? [];
