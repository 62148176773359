import { NotificationTypes } from '@/src/core/types/api';
import { INotificationAction } from '../types/ui';

type NotificationType = keyof typeof NotificationTypes;

export interface Notification {
  title: string;
  description?: string;
  type: NotificationTypes;
  timing?: number;
  closeDrawer?: boolean;
  notificationAction?: INotificationAction | null;
}

export const getNotificationMapping = (notification: Notification): Notification => {
  const timing = getNotificationTimeout(notification.type);
  return {
    title: notification.title,
    description: notification.description,
    type: notification.type,
    timing,
    closeDrawer: notification?.closeDrawer || false,
    notificationAction: notification?.notificationAction || null,
  };
};

const getNotificationTimeout = (type: NotificationTypes) => {
  switch (type) {
    case NotificationTypes.Information:
      return 7000;
    case NotificationTypes.Success:
      return 5000;
    case NotificationTypes.Warning:
      return 10000;
    case NotificationTypes.Error:
      return 10000;
    default:
      return 7000;
  }
};
