<template>
  <div
    v-if="hasServiceReferences"
    class="product-details-service-references"
    v-ui-test="'product-details-service-references'"
  >
    <LinkBoxes>
      <h2 class="product-details-service-references__header">
        {{ textK('UI_PRODUCT_SERVICE_REFERENCE_HEADER') }}
      </h2>
      <div class="link-boxes-module__boxes content-theme-series--green">
        <div
          v-for="(reference, index) in serviceReferences"
          :key="reference.Title"
          class="link-boxes-module__box"
          @mouseover="() => handleMouseOver(index)"
          @mouseleave="() => handleMouseLeave()"
        >
          <CtaBoxModule :has-link="Boolean(reference.Link)">
            <CtaBoxWrapper
              :href="reference.Link"
              class="hero-module-ctabox typeset no-article-image"
            >
              <div class="hero-module-ctabox__inner">
                <div>
                  <ArrowIcon
                    class="ani-icon-arrow-container hero-module-arrow arrow--h3"
                    :direction="hoverIndex === index ? 'right' : 'right-down'"
                  />
                </div>
                <div class="hero-module-ctabox__title">
                  <h3>{{ reference.Title }}</h3>
                </div>
                <div class="hero-module-ctabox__subtitle">{{ reference.Text }}</div>
              </div></CtaBoxWrapper
            >
          </CtaBoxModule>
        </div>
      </div>
    </LinkBoxes>
  </div>
</template>

<style src="./product-details-service-references.scss" lang="scss" scoped></style>

<script setup lang="ts">
import CtaBoxModule from '@/src/content/components/content-modules/cta-box-module/cta-box-module.vue';
import CtaBoxWrapper from '@/src/content/components/content-modules/cta-box-wrapper/cta-box-wrapper.vue';
import LinkBoxes from '@/src/content/components/content-modules/link-boxes-module/link-boxes.vue';
import ArrowIcon from '@/src/core/components/ui/ani-icons/arrow/arrow-icon.vue';
import useText from '@/src/core/hooks/useText';
import { useServiceReferenceStore } from '@/src/market/stores/service-reference';
import { computed, onUnmounted, ref } from 'vue';

const serviceReferencesStore = useServiceReferenceStore();
const textK = useText();

const hoverIndex = ref<number | null>(null);

const handleMouseOver = (index: number) => {
  hoverIndex.value = index;
};

const handleMouseLeave = () => {
  hoverIndex.value = null;
};

const serviceReferences = computed(() => {
  return serviceReferencesStore.serviceReferences;
});

const hasServiceReferences = computed(() => {
  return serviceReferences.value.length > 0;
});

onUnmounted(() => {
  serviceReferencesStore.$reset();
});
</script>
