<template>
  <div class="target-product-item" v-if="product">
    <div class="target-product-item__wrapper">
      <div class="target-product-item__information">
        <router-link
          class="target-product-item__link"
          :to="product.MarketOfferUrl"
          v-if="product.ProductManufacturer.CageCode"
        >
          <p class="target-product-item__number" v-ui-test="'manufacturer-aid'">
            {{
              product.ManufacturerAid && product.ManufacturerAid.length > 0
                ? product.ManufacturerAid
                : '-'
            }}:<wbr /><span class="target-product-item__number__cagecode">{{
              product.ProductManufacturer.CageCode
            }}</span>
          </p>
        </router-link>
        <router-link
          class="target-product-item__link"
          :to="product.MarketOfferUrl"
          v-if="product.ProductManufacturer.CageCode"
        >
          <p class="target-product-item__title__name" v-ui-test="'name'">
            {{ product.Name && product.Name.length > 0 ? product.Name : '-' }}
          </p>
        </router-link>
        <ProductMoqSpq v-if="product.AddToBasketQty > 1 || product.PackageQuantity > 1">
          <ProductMoqSpqUnits
            v-if="product.AddToBasketQty > 1"
            v-ui-test="'moq-tag'"
            :title="titleTag(textK('UI_COMMON_MIN_QUANTITY'))"
          >
            {{ textK('UI_COMMON_MIN_QUANTITY_SHORTHAND') }}:
            <span v-ui-test="'moq-amount'">{{ product.AddToBasketQty }}</span>
          </ProductMoqSpqUnits>
          <ProductMoqSpqUnits
            v-if="product.PackageQuantity > 1"
            v-ui-test="'spq-tag'"
            :title="titleTag(textK('UI_COMMON_SPQ_QUANTITY'))"
          >
            {{ textK('UI_COMMON_PACKAGE_QUANTITY_SHORTHAND') }}:
            <span v-ui-test="'spq-amount'">{{ product.PackageQuantity }}</span>
          </ProductMoqSpqUnits>
        </ProductMoqSpq>
      </div>
      <div class="target-product-item__price-quantity" v-if="isAuthenticated">
        <ProductVolumeDiscounts
          class="target-product-item__price-quantity__volume-discount"
          v-if="productAdditionalData && productAdditionalData.RuleStatus === 'OK'"
          :id="product.Id"
          :owning-system="product.OwningSystem"
        />
        <div class="target-product-item__price-quantity__price">
          <ProductUnitPrice
            :entry="{ Product: product }"
            :use-additional-product-info="true"
            :additional-product-info="productAdditionalData"
          />
        </div>
        <div class="target-product-item__price-quantity__quantity">
          <QuantityController
            v-if="
              productAdditionalData &&
              parseFloat(productAdditionalData.Price && productAdditionalData.Price.Value) > 0
            "
            :product-id="product.Code"
            :has-add-to-cart="true"
            :min-value="product.AddToBasketQty"
            :step="product.CartStep"
            :unit="productAdditionalData.SalesUnit.SapCode"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./target-product-item.scss" scoped></style>

<script lang="ts">
import { Vue, Component, Prop, Provide, Watch, toNative } from 'vue-facing-decorator';
import VButton from '@/src/core/components/ui/button/button.vue';
import SkeletonLoader from '@/src/core/components/ui/skeleton-loader/skeleton-loader.vue';
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import useTitleTag from '@/src/core/hooks/useTitleTag';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { Product } from '@/src/core/types/api';
import ProductMoqSpqUnits from '@/src/market/components/product/product-moq-spq/product-moq-spq-units/product-moq-spq-units.vue';
import ProductMoqSpq from '@/src/market/components/product/product-moq-spq/product-moq-spq.vue';
import ProductPriceQuantity from '@/src/market/components/product/product-price-quantity/product-price-quantity.vue';
import ProductUnitConversion from '@/src/market/components/product/product-unit-conversion/product-unit-conversion.vue';
import ProductUnitPrice from '@/src/market/components/product/product-unit-price/product-unit-price.vue';
import ProductVolumeDiscounts from '@/src/market/components/product/product-volume-discounts/product-volume-discounts.vue';
import QuantityController from '@/src/market/components/utils/quantity-controller/quantity-controller.vue';
import { isAirbusProduct } from '@/src/market/services/product-parts';
import { useProductStore } from '@/src/market/stores/product';
import { useProductAdditionalInfoStore } from '@/src/market/stores/product-additional-info';
import { useProductPriceInfoStore } from '@/src/market/stores/product-price-info';
import Media from '@/src/core/components/util/media-query/media-query.vue';

@Component({
  components: {
    MQ: Media,
    Tooltip,
    VButton,
    ProductVolumeDiscounts,
    ProductPriceQuantity,
    ProductMoqSpq,
    ProductMoqSpqUnits,
    ProductUnitPrice,
    ProductUnitConversion,
    QuantityController,
    SkeletonLoader,
  },
})
export class TargetSearchItem extends Vue {
  public textK = useText();
  public productStore = useProductStore();
  public productAdditionalInfoStore = useProductAdditionalInfoStore();
  public productPriceInfoStore = useProductPriceInfoStore();
  public authenticationStore = useAuthenticationStore();

  public titleTag = useTitleTag;
  @Prop() public id: string;
  @Prop({ default: UiVariant.Primary }) private type: UiVariant;
  @Prop({ default: false }) private loading: boolean;
  @Provide() public productSku = () => this.compSku;

  private uiVariant = UiVariant;
  private uiSize = UiSize;

  private get compSku() {
    return this.id;
  }

  public get product(): Product | undefined {
    return this.productStore.productById(this.id);
  }

  private additionalDataLoaded: boolean = false;

  public get isAuthenticated() {
    return this.authenticationStore.isAuthenticated;
  }

  public get productAdditionalData() {
    return (
      (this.product && this.productAdditionalInfoStore.additionalDataByOfferId(this.product.Id)) ||
      null
    );
  }

  private fetchProductAdditionalData() {
    if (this.isAuthenticated && !this.additionalDataLoaded) {
      if (this.product) {
        if (isAirbusProduct(this.product.OwningSystem)) {
          return;
        }
        this.productAdditionalInfoStore.queueAdditionalProductData({
          OfferId: this.product.Id,
          Quantity: this.product.AddToBasketQty || 1,
        });
        this.productPriceInfoStore.queueAdditionalProductData({
          OfferId: this.product.Id,
          Quantity: this.product.AddToBasketQty || 1,
        });
        this.additionalDataLoaded = true;
        this.fetchProductAdditionalData();
      }
    }
  }

  @Watch('isAuthenticated') private refreshSearchResult() {
    if (this.isAuthenticated) {
      this.fetchProductAdditionalData();
    }
  }

  public mounted() {
    this.productStore.fetchAndUpdateProductByOfferId({ id: this.id, force: false }).then(() => {
      this.$nextTick(() => {
        if (this.isAuthenticated) {
          this.fetchProductAdditionalData();
        }
      });
    });
  }
}

export default toNative(TargetSearchItem);
</script>
