<template>
  <span></span>
</template>

<script lang="ts" setup>
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { IASM } from '@/src/core/types/interfaces';
import Cookies from 'js-cookie';
import { computed, onMounted, ref } from 'vue';

const authenticationStore = useAuthenticationStore();
const asmEvaluationTimerRunning = ref(false);

const asm = computed(() => authenticationStore.asm);

const evaluateAsm = () => {
  // Start continuous validating the ASM cookie
  if (!asmEvaluationTimerRunning.value) {
    setInterval(() => {
      asmEvaluationTimerRunning.value = true;
      evaluateAsm();
    }, 5000);
  }

  if (authenticationStore.isAuthenticated) {
    const asmInfoCookie = Cookies.get('asmInfo');

    if (asmInfoCookie && asmInfoCookie.length > 0 && asmInfoCookie.indexOf('||') > 0) {
      const asmCookieVal = {
        Active: true,
        Id: asmInfoCookie.split('||')[0],
        Name: decodeURIComponent(window.atob(asmInfoCookie.split('||')[1])),
        Email: asmInfoCookie.split('||')[2],
      };
      authenticationStore.updateAsm({ asm: asmCookieVal });
    } else if (asm.value.Id) {
      authenticationStore.updateAsm({ asm: {} as IASM });
    }
  }
};

onMounted(() => {
  evaluateAsm();
});
</script>
