import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export enum Site {
  Content,
  Market,
  Profile,
  Finance,
  Account,
  Planning,
}

export const useUIStore = defineStore('ui', () => {
  const site = ref<Site | null>(null);
  const lastMarketRoute = ref<string>('');
  const showGlobalLoader = ref<boolean>(false);
  const showGlobalOverlay = ref<boolean>(false);
  const showSplashScreen = ref<boolean>(false);
  const siteIsLoaded = ref<boolean>(false);
  const subContentOpen = ref<boolean>(false);
  const dropDownOpen = ref<string>('');
  const isNavigationMinimized = ref<boolean>(false);
  const disableAnimations = ref<boolean>(false);
  const navigationDropdownId = ref<string | null>(null);
  const lastScrollPos = ref<number>(0);
  const showHelper = ref<boolean>(true);
  const clickedFromPDP = ref<boolean>(false);
  const openDiscountTableId = ref<string | null>(null);

  const navigationMinimized = computed(() => {
    return (
      isNavigationMinimized.value ||
      navigationDropdownId.value !== null ||
      lastScrollPos.value >= 50
    );
  });

  const dropdownIsOpen = computed(() => {
    return dropDownOpen.value;
  });

  const showContentOverlay = (payload: { showOverlay: boolean; showLoader?: boolean }) => {
    showGlobalOverlay.value = payload.showOverlay;
    showGlobalLoader.value = payload.showLoader || false;
  };

  const setCurrentSite = (payload: { site: Site }) => {
    site.value = payload.site;
  };

  const setNavigationDropdownId = (payload: { id: string | null }) => {
    navigationDropdownId.value = payload.id;
  };
  const setNavigationMinimized = (payload: { minimized: boolean }) => {
    isNavigationMinimized.value = payload.minimized;
  };
  const setlastMarketRoute = (payload: { lastRoute: string }) => {
    lastMarketRoute.value = payload.lastRoute;
  };

  const setDisableUIAnimations = (payload: { disable: boolean }) => {
    disableAnimations.value = payload.disable;
  };

  const setSiteLoaded = (payload: { siteLoaded: boolean }) => {
    siteIsLoaded.value = payload.siteLoaded;
  };

  const setSplashScreen = (payload: { splashScreen: boolean }) => {
    showSplashScreen.value = payload.splashScreen;
  };

  const setSubContentOpen = (payload: { open: boolean }) => {
    subContentOpen.value = payload.open;
  };

  const setDropdownOpen = (payload: { id: string }) => {
    dropDownOpen.value = payload.id;
  };

  const setLastScrollPosition = (payload: { scrollPos: number }) => {
    lastScrollPos.value = payload.scrollPos;
  };

  const setShowHelper = (payload: { show: boolean }) => {
    showHelper.value = payload.show;
  };

  const toggleNavigationDropdown = (id: string) => {
    if (navigationDropdownId.value === id) {
      navigationDropdownId.value = null;
    } else {
      navigationDropdownId.value = id;
    }
  };

  const setClickFromPDP = (clicked: boolean) => {
    clickedFromPDP.value = clicked;
  };

  const setDiscountModaId = (id: string | null) => {
    openDiscountTableId.value = id;
  };

  const isNavigationDropdownActive = (id: string) => {
    return navigationDropdownId.value === id;
  };

  return {
    site,
    lastMarketRoute,
    showGlobalLoader,
    showGlobalOverlay,
    showSplashScreen,
    siteIsLoaded,
    subContentOpen,
    dropDownOpen,
    disableAnimations,
    navigationDropdownId,
    lastScrollPos,
    showHelper,
    clickedFromPDP,
    openDiscountTableId,
    navigationMinimized,
    dropdownIsOpen,
    showContentOverlay,
    setCurrentSite,
    setNavigationDropdownId,
    setNavigationMinimized,
    setlastMarketRoute,
    setDisableUIAnimations,
    setSiteLoaded,
    setSplashScreen,
    setSubContentOpen,
    setDropdownOpen,
    setLastScrollPosition,
    setShowHelper,
    toggleNavigationDropdown,
    setClickFromPDP,
    setDiscountModaId,
    isNavigationDropdownActive,
  };
});
