<template>
  <InViewWatcher>
    <slot></slot>
  </InViewWatcher>
</template>

<style lang="scss" src="./article-module.scss"></style>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import BackButton from '@/src/core/components/ui/back-button/back-button.vue';
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';

@Component({
  components: {
    InViewWatcher,
    BackButton,
  },
})
export class ContentArticleModule extends Vue {}

export default toNative(ContentArticleModule);
</script>
