import { NotificationTypes } from '@/src/core/types/api';

const notificationsStoreModule = () => import('@/src/core/stores/notifications');

export const showErrorNotification = async (description: string) => {
  const { useNotificationsStore } = await notificationsStoreModule();
  const notificationsStore = useNotificationsStore();

  notificationsStore.addNotification({
    notificationItem: {
      Type: NotificationTypes.Error,
      Description: description,
      Timing: 10_000,
    },
  });
};
