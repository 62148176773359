<template>
  <div ref="modal" class="modal">
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-if="visible" class="modal__overlay"></div>
    </transition>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInScale"
      leave-active-class="animated fadeOutScale"
    >
      <div
        v-if="visible"
        class="modal__content"
        @mousedown.self="closeModal"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal"
      >
        <div class="modal__inner-content">
          <transition
            name="fade"
            mode="out-in"
            enter-active-class="animated fadeInTranslateDown"
            leave-active-class="animated fadeOutTranslateUp"
          >
            <component :is="component"></component>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" src="./modal.scss" scoped></style>

<script lang="ts" setup>
import { RouteLocationNormalized, useRoute } from 'vue-router';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useModalStore } from '@/src/core/stores/modal';
import { useScrollLockStore } from '@/src/core/stores/scroll-lock';
import { useUIStore } from '@/src/core/stores/ui';
import { IeKeys } from '@/src/core/types/keys';
import { Key } from 'ts-key-enum';
import modals, { ModalType } from '../modals';

const scrollLockStore = useScrollLockStore();
const uiStore = useUIStore();
const modalStore = useModalStore();
const route = useRoute();

const modal = ref<HTMLElement | null>(null);

const component = computed(() => {
  if (!modalComponent.value) {
    return null;
  }

  return modals[modalComponent.value as ModalType];
});

const modalComponent = computed(() => {
  return modalStore.component;
});

const visible = computed(() => {
  return modalStore.isVisible;
});

const modalIsLocked = computed(() => {
  return modalStore.isLocked;
});

const closeModal = () => {
  if (!modalIsLocked.value) {
    modalStore.closeModal();
  }
};

const handleEscButton = ($event: KeyboardEvent) => {
  const key: string = $event.key;

  if (uiStore.dropdownIsOpen) {
    return;
  }

  switch (key) {
    case Key.Escape:
    case IeKeys.Escape:
      $event.preventDefault();
      closeModal();
      break;
  }
};

watch(
  () => visible.value,
  (newValue: boolean) => {
    if (newValue) {
      scrollLockStore.scrollLock({ id: 'modal' });
    } else {
      scrollLockStore.reset();
      uiStore.setDropdownOpen({ id: '' });
    }
  },
);

watch(route, (value: RouteLocationNormalized, oldValue: RouteLocationNormalized) => {
  // CHECK IF ROUTE HAS EXISTED BEFORE JUST CLOSING
  // + CHECK IF IT NEEDS CLOSING
  if (component.value && oldValue.name && oldValue.name !== value.name) {
    closeModal();
  }
});

onMounted(() => {
  document.addEventListener('keyup', handleEscButton);
});

onBeforeUnmount(() => {
  document.removeEventListener('keyup', handleEscButton);
});
</script>
