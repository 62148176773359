import { getEnv } from '@/src/core/services/environment';
import { urlSafeBase64 } from '@/src/core/utils/encode';
import {
  SSO_COOKIE_KEY_STATE,
  SSO_COOKIE_KEY_VERIFIER,
  dehydrateState,
  generateState,
} from '@/src/core/utils/sso';
import { getRandomString } from '@/src/core/utils/string';
import { extractURI } from '@/src/core/utils/urls';
import axios from 'axios';
import Cookies from 'js-cookie';
import qs from 'qs';

const SSO_BASE_URL = getEnv('VUE_APP_SSO_AUTH_URI') ?? '';
const PATHS = {
  REDIRECT_PATH: '/FedBroker/as/authorization.oauth2',
  TOKENS_PATH: '/FedBroker/as/token.oauth2',
};

const SSO_ENCRYPTION_ALGORITHM = 'SHA-256';
const SSO_ENCRYPTION_ALGORITHM_ABW = 'S256';

const SSO_AUTH_CALLBACK_PATH = '/oidc/authorizationcode';

const SSO_CLIENT_ID = getEnv('VUE_APP_SSO_CLIENT_ID');
const SSO_SCOPE = getEnv('VUE_APP_SSO_SCOPE');
const SSO_RESPONSE_TYPE = 'code';
const SSO_AUTH_GRANT_TYPE = 'authorization_code';

type AUTH_TOKEN_RESPONSE = {
  access_token: string;
  token_type: 'Bearer';
  expires_in: number;
  refresh_token: string;
};

const generateChallenge = async (verifier: string) => {
  const encoder = new TextEncoder();
  const encodedVerifier = encoder.encode(verifier);
  const hashBuffer = await crypto.subtle.digest(SSO_ENCRYPTION_ALGORITHM, encodedVerifier);
  const hash = String.fromCharCode.apply(null, new Uint8Array(hashBuffer));
  const urlSafeBase64Hash = urlSafeBase64(hash);

  return urlSafeBase64Hash;
};
const getCurrentEnvironmentUri = () => extractURI(location.href);
const generateCallbackUrl = (env: string) => `${env}${SSO_AUTH_CALLBACK_PATH}`;

const generateRedirectUrl = (challenge: string, state: string) => {
  const url = new URL(`${SSO_BASE_URL}${PATHS.REDIRECT_PATH}`);
  const redirect_uri = generateCallbackUrl(getCurrentEnvironmentUri());
  const params = {
    response_type: SSO_RESPONSE_TYPE,
    client_id: SSO_CLIENT_ID,
    redirect_uri,
    scope: SSO_SCOPE,
    code_challenge: challenge,
    code_challenge_method: SSO_ENCRYPTION_ALGORITHM_ABW,
    state: state,
  };

  for (const [key, value] of Object.entries(params)) {
    url.searchParams.append(key, value);
  }

  return url.href;
};

export const redirectToSSO = async (stateLink?: string) => {
  const verifier = getRandomString({ length: 43 });
  const challenge = await generateChallenge(verifier);
  const state = generateState(stateLink ?? location.href);
  const dehydratedState = dehydrateState(state);

  Cookies.set(SSO_COOKIE_KEY_VERIFIER, verifier);
  Cookies.set(SSO_COOKIE_KEY_STATE, dehydratedState);

  const redirectUrl = generateRedirectUrl(challenge, dehydratedState);
  window.location.href = redirectUrl;
};

export const getAuthTokens = async (
  authorizationCode: string,
  codeVerifier: string,
  state: string,
) => {
  const url = new URL(`${SSO_BASE_URL}${PATHS.TOKENS_PATH}`);
  const redirect_uri = generateCallbackUrl(getCurrentEnvironmentUri());
  const body = {
    grant_type: SSO_AUTH_GRANT_TYPE,
    client_id: SSO_CLIENT_ID,
    code_verifier: codeVerifier,
    code: authorizationCode,
    state,
    redirect_uri,
  };
  const data = qs.stringify(body);

  return axios<AUTH_TOKEN_RESPONSE>({
    url: url.href,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};
