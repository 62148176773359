<template>
  <div class="content-modal" v-ui-test="'quote-terms-and-conditions-modal'">
    <StandardDialogModal :size="uiSize.Large" :use-padding="true" :has-close-button="true">
      <template #header>
        <h3>{{ textK('UI_QUOTES_CREATE_TERMS_AND_CONDITIONS') }}</h3>
      </template>
      <template #body>
        <ModalColumnContainer>
          <ModalColumn>
            <div class="content-modal-wrapper">
              <ContentBlock :modal="true" link-key="GeneralTerms_ModalUrl"></ContentBlock>
            </div>
          </ModalColumn>
        </ModalColumnContainer>
        <ModalColumnContainer>
          <ModalColumn class="terms">
            <h4>{{ textK('UI_QUOTES_CREATE_TERMS_AND_CONDITIONS') }}</h4>
            <Checkbox
              v-model="terms"
              :id="'terms'"
              v-ui-test="'quote-terms-and-conditions-modal-accept'"
            >
              {{ textK('UI_QUOTES_CREATE_TERMS_CHECKBOX_LABEL') }}
            </Checkbox>
          </ModalColumn>
        </ModalColumnContainer>
      </template>
      <template #actions>
        <VButton :type="uiVariant.Secondary" @click="back">{{
          textK('UI_COMMON_BACK_BUTTON')
        }}</VButton>
        <VButton
          input-type="button"
          :loading="isLoading"
          :v-ui-test="'quote-terms-and-conditions-modal-continue'"
          :disabled="!terms"
          @click="submit"
          >{{ textK('UI_QUOTES_CREATE_QUOTE_BTN') }}</VButton
        >
      </template>
    </StandardDialogModal>
  </div>
</template>
<style lang="scss" src="./modal-quote-terms-and-conditions.scss" scoped></style>

<script lang="ts" setup>
import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import ContentBlock from '@/src/core/components/ui/content-block/content-block.vue';
import Checkbox from '@/src/core/components/ui/form/checkbox/checkbox.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { IModalCreateQuoteParams } from '@/src/core/types/interfaces';
import { useListsStore } from '@/src/market/stores/list';
import { useQuoteDraftsStore } from '@/src/market/stores/quote-drafts';
import { useQuoteListStore } from '@/src/market/stores/quote-list';
import { useQuoteSingleStore } from '@/src/market/stores/quote-single';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const textK = useText();
const listsStore = useListsStore();
const quoteDraftsStore = useQuoteDraftsStore();
const quoteListStore = useQuoteListStore();
const quoteSingleStore = useQuoteSingleStore();
const modalStore = useModalStore();
const router = useRouter();

const isLoading = ref(false);
const terms = ref(false);

const uiVariant = UiVariant;
const uiSize = UiSize;

const params = computed(() => modalStore.params as IModalCreateQuoteParams);
const listCode = computed(() => listsStore.currentList.Code);
const isSingleLine = computed(() => (params.value ? params.value.single : false));

const back = () => {
  isSingleLine.value
    ? modalStore.showModal({
        modalComponent: 'ModalCreateQuoteSelectAddress',
        params: {
          id: params.value.id,
          single: true,
          listCode: quoteSingleStore.quoteSingle.ListCode,
          isTerms: terms.value,
        },
      })
    : modalStore.showModal({
        modalComponent: 'ModalCreateQuoteSelectAddress',
        params: {
          single: true,
          listCode: quoteSingleStore.quoteSingle.ListCode,
          isTerms: terms.value,
        },
      });
  modalStore.closeModal({ modalComponent: 'ModalQuoteTermsAndConditions' });
};

const submit = async () => {
  if (terms.value) {
    const isSingle = params.value.single;
    isLoading.value = true;
    await quoteListStore
      .populateQuoteList({ listCode: params.value.listCode, single: params.value.single })
      .then(() => {
        quoteListStore.submitQuoteList().then(({ IsSuccess }) => {
          if (IsSuccess) {
            isLoading.value = false;
            modalStore.closeModal();
            quoteDraftsStore.deleteList({ listCode: listCode.value });
            quoteDraftsStore.fetchAllQuoteLists();
            quoteSingleStore.reset();
            if (!isSingle) {
              router.push({ name: 'drafts' });
            }
          } else {
            isLoading.value = false;
            modalStore.closeModal();
          }
        });
      });
  }
};

onMounted(async () => {
  if (params.value.isTerms) {
    terms.value = params.value.isTerms;
  }
  await listsStore.getCurrentList({ listCode: params.value.listCode });
});
</script>
