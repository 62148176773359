export const urlSafeBase64 = (str: string) => {
  // Encode the input string using the built-in btoa function
  const base64Encoded = btoa(str);

  // Replace characters to make the base64 string URL-safe
  const urlSafeBase64 = base64Encoded
    .replace(/\+/g, '-') // Replace '+' with '-'
    .replace(/\//g, '_') // Replace '/' with '_'
    .replace(/=+$/, ''); // Remove trailing '=' characters

  // Return the URL-safe base64-encoded string
  return urlSafeBase64;
};

export const urlSafeBase64Decode = (urlSafeBase64: string) => {
  // Replace '-' with '+' and '_' with '/' to revert the URL-safe encoding
  const base64 = urlSafeBase64
    .replace(/-/g, '+') // Replace '-' with '+'
    .replace(/_/g, '/'); // Replace '_' with '/'

  // Add padding if necessary (multiple of 4 characters)
  const paddedBase64 = base64.padEnd(Math.ceil(base64.length / 4) * 4, '=');

  // Decode the base64 string
  const decodedStr = atob(paddedBase64);

  return decodedStr;
};
