import { IVSelect } from '@/src/core/types/ui';
import { DisplayDateFormat } from '@/src/core/utils/dates';
import dayjs from 'dayjs';

export const PeriodOptions: IVSelect[] = [
  {
    label: 'Current month',
    value:
      dayjs().startOf('month').format(DisplayDateFormat) +
      ' - ' +
      dayjs().format(DisplayDateFormat),
  },
  {
    label: 'Last month',
    value:
      dayjs().subtract(1, 'month').startOf('month').format(DisplayDateFormat) +
      ' - ' +
      dayjs().subtract(1, 'month').endOf('month').format(DisplayDateFormat),
  },
  {
    label: 'Current year',
    value:
      dayjs().startOf('year').format(DisplayDateFormat) + ' - ' + dayjs().format(DisplayDateFormat),
  },
  {
    label: 'Custom',
    value: '',
  },
];
