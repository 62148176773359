import ModalAddToList from '@/src/market/components/modals/modal-add-to-list/modal-add-to-list.vue';
import ModalSearchFilter from './modal-search-filter/modal-search-filter.vue';
import ModalRequestPartLease from '@/src/market/components/modals/modal-request-part-lease/modal-request-part-lease.vue';
import ModalAirbusRequestQuoteAddress from '@/src/market/components/modals/modal-airbus-request-quote-address/modal-airbus-request-quote-address.vue';
import ModalExportLeaseOrders from '@/src/market/components/modals/modal-export-lease-orders/modal-export-lease-orders.vue';
import ModalExportRepairExchangeOrders from '@/src/market/components/modals/modal-export-repair-exchange-orders/modal-export-repair-exchange-orders.vue';
import ModalRequestToolUsed from '@/src/market/components/modals/modal-request-tool-used/modal-request-tool-used.vue';
import ModalQuoteEmailNotification from '@/src/market/components/modals/modal-quote-email-notification/modal-quote-email-notification.vue';
import ModalCreateAirbusQuote from '@/src/market/components/modals/modal-create-airbus-quote/modal-create-airbus-quote.vue';
import ModalAirbusProprietaryPart from './modal-airbus-proprietary-part/modal-airbus-proprietary-part.vue';
import ModalCheckoutSelectAddress from './modal-checkout-select-address/modal-checkout-select-address.vue';
import ModalCheckoutSelectForwarder from './modal-checkout-select-forwarder/modal-checkout-select-forwarder.vue';
import ModalClearCart from './modal-clear-cart/modal-clear-cart.vue';
import ModalCreateList from './modal-create-list/modal-create-cart.vue';
import ModalDeleteList from './modal-delete-cart/modal-delete-cart.vue';
import ModalDownloadDocuments from './modal-download-documents/modal-download-documents.vue';
import ModalRenameList from './modal-rename-list/modal-rename-list.vue';
import ModalSaveAsList from './modal-save-as-list/modal-save-cart.vue';
import ModalCartChanged from './modal-cart-changed/ModalCartChanged.vue';
import ModalSelectWarehouse from './modal-select-warehouse/modal-select-warehouse.vue';
import ModalConfirmEntryDelete from './modal-confirm-entry-delete/modal-confirm-entry-delete.vue';
import ModalCartCurrency from './modal-cart-currency/ModalCartCurrency.vue';
import ModalSellerConversation from './modal-seller-conversation/modal-seller-conversation.vue';
import ModalImage from './modal-image/modal-image.vue';
import ModalRequestQuote from './modal-request-quote/modal-request-quote.vue';
import ModalRequestQuoteSelectAddress from './modal-request-quote-select-address/modal-request-quote-select-address.vue';
import ModalSellerQuoteConversation from './modal-seller-quote-conversation/modal-seller-quote-conversation.vue';
import ModalCreateQuote from './modal-create-quote/modal-create-quote.vue';
import ModalCreateQuoteSelectAddress from './modal-create-quote-select-address/modal-create-quote-select-address.vue';
import ModalCreateSingleLineQuote from './modal-create-single-line-quote/modal-create-single-line-quote.vue';
import ModalForwarderInformation from './modal-forwarder-information/modal-forwarder-information.vue';
import ModalCreateQuoteList from './modal-create-quote-list/modal-create-quote-list.vue';
import ModalMinimOrderValue from './modal-minim-order-value/modal-minim-order-value.vue';
import ModalOrdersFilter from './modal-orders-filter/modal-orders-filter.vue';
import ModalMaterialStatus from './modal-material-status/modal-material-status.vue';
import ModalUpdateRemark from './modal-update-remark/modal-update-remark.vue';
import ModalRemark from './modal-remark/modal-remark.vue';
import ModalBoxDetails from './modal-box-details/modal-box-details.vue';
import ModalLeasePrice from './modal-lease-price/modal-lease-price.vue';
import ModalHeavyMaintenance from './modal-heavy-maintenance-form/modal-heavy-maintenance-form.vue';
import ModalRequestExchange from './modal-request-exchange/modal-request-exchange.vue';
import ModalRequestToolLease from './modal-request-tool-lease/modal-request-tool-lease.vue';
import ModalAirbusRepairRequestQuote from './modal-airbus-repair-request-quote/modal-airbus-repair-request-quote.vue';
import ModalRequestQuoteFHS from './modal-request-quote-fhs/modal-request-quote-fhs.vue';
import ModalExportSalesOrders from './modal-export-sales-orders/modal-export-sales-orders.vue';

const MarketModals = {
  ModalSearchFilter,
  ModalRequestPartLease,
  ModalAirbusRequestQuoteAddress,
  ModalExportLeaseOrders,
  ModalExportRepairExchangeOrders,
  ModalRequestToolUsed,
  ModalQuoteEmailNotification,
  ModalCreateAirbusQuote,
  ModalAirbusProprietaryPart,
  ModalAddToList,
  ModalCheckoutSelectAddress,
  ModalCheckoutSelectForwarder,
  ModalClearCart,
  ModalCreateList,
  ModalDeleteList,
  ModalDownloadDocuments,
  ModalRenameList,
  ModalSaveAsList,
  ModalCartChanged,
  ModalSelectWarehouse,
  ModalConfirmEntryDelete,
  ModalCartCurrency,
  ModalSellerConversation,
  ModalImage,
  ModalRequestQuote,
  ModalRequestQuoteSelectAddress,
  ModalSellerQuoteConversation,
  ModalCreateQuote,
  ModalCreateQuoteSelectAddress,
  ModalCreateSingleLineQuote,
  ModalForwarderInformation,
  ModalCreateQuoteList,
  ModalMinimOrderValue,
  ModalOrdersFilter,
  ModalMaterialStatus,
  ModalUpdateRemark,
  ModalRemark,
  ModalBoxDetails,
  ModalLeasePrice,
  ModalHeavyMaintenance,
  ModalRequestExchange,
  ModalRequestToolLease,
  ModalAirbusRepairRequestQuote,
  ModalRequestQuoteFHS,
  ModalExportSalesOrders,
};

export default MarketModals;
