<template>
  <transition name="fade">
    <div
      v-if="showSpinner"
      class="spinner-overlay"
      :class="{ 'spinner-overlay--visible': hasOverlay, 'dark-theme': darkTheme }"
    >
      <div class="spinner-wrapper" ref="loaderWrapper">
        <DotLoader :loading="true"> </DotLoader>
        <div class="spinner-text" v-if="$slots.default">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<style src="./spinner.scss" lang="scss"></style>

<script lang="ts" setup>
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import { ref } from 'vue';

interface Props {
  hasOverlay?: boolean;
  darkTheme?: boolean;
  delay?: number;
}

const props = withDefaults(defineProps<Props>(), {
  hasOverlay: false,
  darkTheme: false,
  delay: 800,
});

const showSpinner = ref(false);

setTimeout(() => {
  showSpinner.value = true;
}, props.delay);
</script>
