import { CartModification, OrderEntry } from '@/src/core/apim';
import { useNotificationsStore } from '@/src/core/stores/notifications';
import { NotificationTypes } from '@/src/core/types/api';
import {
  getProductWithMapping,
  ProductWithMapping,
} from '@/src/market/services/legacy-mapping-product';
import { Notification } from '@/src/market/services/notification-mapping';

enum FailIndicators {
  Full,
  Partial,
  None,
  IncorrectCurrency,
  MultipleOffers,
  MaxAllowedQuantityReached,
  InActiveOfferRemoved,
}

enum ActionType {
  Login = 'Login',
  VueRedirect = 'VueRedirect',
  TriggerModal = 'TriggerModal',
}

interface ModalData {
  name: string;
  data: string;
}

export interface CustomAction {
  ActionType: ActionType;
  ModalData: ModalData;
}

interface NotificationWithAction {
  CustomActions?: CustomAction[];
}

interface Entry extends Omit<OrderEntry, 'product'> {
  product?: ProductWithMapping;
  cartEntryRoundedFromSap: boolean;
}

interface CartModificationValue extends Omit<CartModification, 'entry'> {
  successAdded: boolean;
  entry: Entry[];
}

export interface CartModificationData extends NotificationWithAction {
  cartModifications: CartModificationValue[];
  failIndicator: FailIndicators;
  failCount: number;
  modificationName?: string;
}

export const extendCartModification = (cm: CartModification): CartModificationValue => ({
  ...cm,
  entry: [
    {
      ...cm.entry,
      cartEntryRoundedFromSap: productSapRoundingMapping(cm.entry),
      product: cm.entry?.product && getProductWithMapping(cm.entry?.product),
    },
  ],
  successAdded: Boolean(cm.quantityAdded && cm.quantityAdded > 0),
});

const productSapRoundingMapping = (entry?: OrderEntry) => {
  return Boolean(entry?.changeReasons?.some((y) => y.reason === 'quantityRoundedBySap'));
};

const getFailIndicator = (cartModifications: CartModificationValue[]) => {
  const allFailed = cartModifications.every((x) => !x.successAdded);

  if (cartModifications.every((x) => x.successAdded)) {
    return FailIndicators.None;
  } else if (allFailed) {
    if (cartModifications.every((x) => x.statusCode === 'incorrectCartCurrency')) {
      return FailIndicators.IncorrectCurrency;
    } else if (cartModifications.every((x) => x.statusCode === 'multipleOffersFound')) {
      return FailIndicators.MultipleOffers;
    } else if (cartModifications.every((x) => x.statusCode === 'maxAllowedQuantityReached')) {
      return FailIndicators.MaxAllowedQuantityReached;
    }
    return FailIndicators.Full;
  }

  return FailIndicators.Partial;
};

export const getCartData = (cartModifications?: CartModification[]): CartModificationData => {
  const extendedModifications = cartModifications?.map(extendCartModification) ?? [];
  const failIndicator = getFailIndicator(extendedModifications);
  const failCount = extendedModifications.filter((x) => !x.successAdded).length;

  return {
    cartModifications: extendedModifications,
    failIndicator,
    failCount,
  };
};

export const getMaxQuantityNotification = (
  cartMod?: CartModificationData,
  cartEntriesCount = 0,
  isFromBulk = false,
): Notification | null => {
  if (!cartMod) return null;

  let totalAdded = 0;

  if (cartEntriesCount > 0) {
    totalAdded = cartEntriesCount - cartMod.failCount;
  }

  const title = isFromBulk ? `${totalAdded} product(s) added to cart` : 'Product not added to cart';

  const description = !isFromBulk
    ? `We could not add the product to your cart as it is limited to 100 products from ${cartMod.cartModifications[0]?.entry[0]?.product?.supplierDisplay}.`
    : `We could only add ${totalAdded} product(s) to your cart as it is limited to 100 products from ${cartMod.cartModifications[0]?.entry[0]?.product?.supplierDisplay}.`;

  if (cartMod.cartModifications.some((x) => x?.statusCode === 'maxAllowedCartSizeReached')) {
    return {
      title,
      description,
      type: NotificationTypes.Error,
    };
  }

  return null;
};

export const orderAddToCardNotification = (cart: CartModificationData): Notification | null => {
  switch (cart.failIndicator) {
    case FailIndicators.None:
      return {
        title: `${cart.modificationName} added to cart`,
        notificationAction: { Name: 'Go to cart', Url: '/market/cart' },
        type: NotificationTypes.Success,
      };

    case FailIndicators.Partial:
      return {
        type: NotificationTypes.Success,
        title: `${cart.modificationName} added to cart`,
        notificationAction: {
          Name: `Please note that ${cart.failCount} of your products could not be added.`,
          Url: '/market/cart',
        },
      };

    case FailIndicators.Full:
      return {
        title: `This order could not be added to cart, as it is not available in your region.`,
        type: NotificationTypes.Error,
      };

    default:
      return null;
  }
};

export const handleCustomAction = (cart: CartModificationData): CustomAction | null => {
  return cart.failIndicator === FailIndicators.IncorrectCurrency
    ? {
        ActionType: ActionType.TriggerModal,
        ModalData: {
          name: 'ModalCartCurrency',
          data: 'order',
        },
      }
    : null;
};
