<template>
  <VSimpleTableRow v-if="productData" v-ui-test="productData.TestTag">
    <VSimpleTableCell
      class="product-details-cell--index"
      :label="'cell-label'"
      :style="{ width: '50%' }"
    >
      <template v-if="compareText('hazmat un code:')">
        <svg
          class="master-data-icon"
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="vertical-align: 'text-top'"
        >
          <path
            d="M2.34845 10.8754L7 2.60599L11.6516 10.8754H2.34845Z"
            fill="#FFA800"
            stroke="black"
            stroke-width="0.7"
          />
          <path d="M7 5.39204V8.01704M7 9.76704V8.89204" stroke="black" stroke-width="0.7" />
        </svg>
        {{ textK('UI_PRODUCTPAGE_HAZMAT_UN_CODE') }}
      </template>
      <template v-else-if="compareText('hazmat description:')">
        {{ productData.Name }}
      </template>
      <template v-else-if="compareText('flammable:')">
        <svg
          class="master-data-icon"
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style="vertical-align: 'text-top'"
        >
          <path
            d="M12.437 7.14205L6.99994 1.70499L1.56288 7.14205L6.99994 12.5791L12.437 7.14205Z"
            fill="#FFA800"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.99994 1.30876L12.8333 7.14209L6.99994 12.9754L1.1666 7.14209L6.99994 1.30876ZM6.99994 2.10131L1.95916 7.14209L6.99994 12.1829L12.0407 7.14209L6.99994 2.10131Z"
            fill="black"
          />
          <path
            d="M7.93421 5.30208C7.52116 4.97799 6.91111 4.74922 6.37731 4.64119C6.65692 5.71513 6.04052 6.21715 5.71007 6.60478C5.34786 7.03055 5.10638 7.5135 5.12544 8.09813C5.13815 8.54932 5.37963 9.0005 5.73549 9.28646C5.75456 9.29917 5.77362 9.31823 5.79268 9.33094C6.00239 8.91789 6.33919 8.3968 6.86663 8.00281C7.375 8.51119 7.67367 9.03863 7.81347 9.37542C8.22017 9.10853 8.71584 8.91789 9.03993 8.07907C9.45298 7.01148 8.72219 5.9439 7.92786 5.31479L7.93421 5.30208Z"
            fill="black"
          />
        </svg>
        {{ productData.Name }}
      </template>
      <template v-else-if="compareText('engine part:')">
        <svg
          class="master-data-icon"
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.9635 8.0887V5.45698L4.30835 4.88409L8.9101 5.32271L11.8834 6.45954L13.027 8.0887L11.8834 9.7089L8.9101 10.8457L5.68064 11.159L2.9635 10.7204V8.0887Z"
            stroke="black"
            stroke-width="1.2"
            stroke-miterlimit="10"
          />
          <path
            d="M8.45248 11.2217V8.53625H4.79303V11.2217"
            stroke="black"
            stroke-width="1.2"
            stroke-miterlimit="10"
          />
          <path
            d="M2.97504 6.26709H2.10004V9.76709H2.97504"
            stroke="black"
            stroke-width="1.2"
            stroke-miterlimit="10"
          />
          <path d="M4.72504 4.95459V3.20459" stroke="black" stroke-width="1.5" />
          <path d="M8.22504 4.95459V3.20459" stroke="black" stroke-width="1.5" />
        </svg>
        {{ productData.Name }}
      </template>
      <template v-else>
        {{ productData.Name }}
      </template>
    </VSimpleTableCell>
    <VSimpleTableCell class="align-right" :label="'cell-value'" :style="{ width: '50%' }">
      <template v-if="isAirbus && productData.Name.toLowerCase() === 'hazmat description:'">
        {{ textK('UI_PRODUCTPAGE_MASTERDATA_NODATA') }}
      </template>
      <template v-else-if="isAirbus && hazmatUNCode && productData.Value == 'N/A'">
        <template v-if="isAuthenticated"> {{ hazmatUNCode }} </template>
        <template v-else>{{ textK('UI_PRODUCTPAGE_MASTERDATA_NODATA') }}</template>
      </template>
      <template v-else-if="isAirbus && productData.Name.toLowerCase() === 'flammable:'">
        {{ productData.Value }}
      </template>
      <template v-else-if="isArray(productData.Value)">
        <template v-for="(item, index) in productData.Value" :key="index">
          <p>{{ item }}</p>
        </template>
      </template>
      <template v-else>
        {{ productData.Value }}
      </template>
    </VSimpleTableCell>
  </VSimpleTableRow>
</template>

<style scoped src="./product-details-master-data.scss" lang="scss"></style>

<script lang="ts" setup>
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import useText from '@/src/core/hooks/useText';
import { TextKey } from '@/src/core/services/text-key';
import { UN_PREFIX } from '@/src/core/settings/materials';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { isAirbusProduct } from '@/src/market/services/product-parts';
import { useAirbusPartStore } from '@/src/market/stores/airbus-part';
import { computed } from 'vue';

interface MasterData {
  Name: string;
  Value: object;
  TestTag: string;
}

interface Props {
  productId: string;
  owningSystem: string;
  masterData: MasterData;
}

const props = defineProps<Props>();

const textK = useText();
const authenticationStore = useAuthenticationStore();
const airbusPartStore = useAirbusPartStore();

const productData = computed<MasterData>(() => props.masterData);
const isAuthenticated = computed(() => authenticationStore.isAuthenticated);

const hazmatUNCode = computed(() => {
  const airbusPart = airbusPartStore.airbusPartByOfferId(props.productId);
  if (!airbusPart) return TextKey('UI_PRODUCTPAGE_MASTERDATA_NODATA');

  if (
    airbusPart.productData?.hazmatStandardCode &&
    productData.value.Name.toLowerCase() === 'hazmat un code:'
  ) {
    return `${UN_PREFIX}${airbusPart.productData.hazmatStandardCode}`;
  }
  return TextKey('UI_PRODUCTPAGE_MASTERDATA_NODATA');
});

const isAirbus = computed(() => isAirbusProduct(props.owningSystem));

const compareText = (name: string) => productData.value.Name.toLowerCase() === name;

const isArray = (arrayValue: string[]) => Array.isArray(arrayValue);
</script>
