<template>
  <div class="priorities">
    <RadioButton
      v-for="prio in priorities"
      class="priority"
      v-ui-test="prio.Code"
      :key="prio.Code"
      :model-value="prio.Code"
      :checked="picked(prio.Code)"
      @update:modelValue="setPriority"
      >{{ textK(prio.Name) }}
    </RadioButton>
  </div>
</template>

<style src="./quote-priority.scss" lang="scss" scoped></style>

<script lang="ts">
import { Vue, Component, Watch, toNative } from 'vue-facing-decorator';
import VButton from '@/src/core/components/ui/button/button.vue';
import RadioButton from '@/src/core/components/ui/form/radiobutton/radio-button.vue';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import {
  IModalCreateQuoteParams,
  IOrderPriority,
  IQuoteList,
  IQuoteSingle,
} from '@/src/core/types/interfaces';
import { DisplayDateFormat } from '@/src/core/utils/dates';
import { useQuoteListStore } from '@/src/market/stores/quote-list';
import { useQuoteSingleStore } from '@/src/market/stores/quote-single';
import dayjs from 'dayjs';

@Component({
  components: {
    VButton,
    RadioButton,
  },
})
export class QuotePriority extends Vue {
  public textK = useText();
  public quoteListStore = useQuoteListStore();
  public quoteSingleStore = useQuoteSingleStore();
  public modalStore = useModalStore();

  public priorities: IOrderPriority[] = [
    {
      Code: 'RTN',
      Name: 'UI_CART_CHECKOUTPAGE_RTN_HEADER',
      Description: 'UI_CART_CHECKOUTPAGE_RTN_HEADER_DELIVERY',
    },
    {
      Code: 'USR',
      Name: 'UI_CART_CHECKOUTPAGE_USR_HEADER',
      Description: 'UI_CART_CHECKOUTPAGE_RTN_HEADER_DELIVERY',
      Restricted: true,
    },
    {
      Code: 'WSP',
      Name: 'UI_CART_CHECKOUTPAGE_WSP_HEADER',
      Description: 'UI_CART_CHECKOUTPAGE_RTN_HEADER_DELIVERY',
      Restricted: true,
    },
    {
      Code: 'AOG',
      Name: 'UI_CART_CHECKOUTPAGE_AOG_HEADER',
      Description: 'UI_CART_CHECKOUTPAGE_RTN_HEADER_DELIVERY',
      Restricted: true,
    },
  ];

  private date: string = '';

  private get params() {
    return this.modalStore.params as IModalCreateQuoteParams;
  }

  private get isSingleLine(): boolean {
    return this.params ? this.params.single : false;
  }

  public picked(priority: string) {
    if (this.priorityCode !== '') {
      return this.priorityCode === priority;
    } else {
      return false;
    }
  }

  private get deliveryDate() {
    return this.quoteList.RequestedDate;
  }

  private get priorityCode() {
    return this.quoteList.Priority || 'RTN';
  }

  private get quoteList() {
    return this.isSingleLine ? this.quoteSingleStore.quoteSingle : this.quoteListStore.quoteList;
  }

  @Watch('deliveryDate') private setDisplayedShipDate(val: string) {
    if (this.date !== val && this.quoteList.Priority !== 'AOG') {
      this.date = val !== '' ? dayjs(new Date(val)).format(DisplayDateFormat) : val;
      this.setPrio();
    }
  }

  public async setPriority(priority: string) {
    this.isSingleLine
      ? this.quoteSingleStore.quoteSingleUpdated({ Priority: priority } as IQuoteSingle)
      : this.quoteListStore.updateQuoteProductList({ Priority: priority } as IQuoteList);

    if (priority !== 'AOG') {
      if (this.deliveryDate) {
        this.date = dayjs(new Date(this.deliveryDate)).format(DisplayDateFormat);
      }
      this.setPrio();
    }
  }

  public setPrio() {
    if (this.date) {
      this.date = dayjs(new Date(this.date)).format(DisplayDateFormat);
    }
  }
}

export default toNative(QuotePriority);
</script>
