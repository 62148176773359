<template>
  <div class="content-modal">
    <StandardDialogModal
      :size="size"
      :use-padding="false"
      :has-close-button="Boolean(params.showCloseButton)"
    >
      <template #header v-if="title">
        <div class="typeset">
          <h2>{{ title }}</h2>
        </div>
      </template>
      <template #body>
        <ModalColumnContainer>
          <ModalColumn>
            <div class="content-modal-wrapper" :class="{ medium: size === uiSize.Medium }">
              <ContentBlock :modal="true" :link-key="params.key" :url="params.url"></ContentBlock>
            </div>
          </ModalColumn>
        </ModalColumnContainer>
      </template>
      <template #actions v-if="!clickOutside">
        <VButton
          :type="buttonType"
          input-type="button"
          :v-ui-test="'modal-close'"
          @click="closeModal"
        >
          {{ params.buttonText || textK('UI_COMMON_CLOSE') }}</VButton
        >
      </template>
    </StandardDialogModal>
  </div>
</template>

<style lang="scss" src="./modal-load-content.scss" scoped></style>

<script setup lang="ts">
import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import ContentBlock from '@/src/core/components/ui/content-block/content-block.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useLinksStore } from '@/src/core/stores/links';
import { useModalStore } from '@/src/core/stores/modal';
import { IModalLoadContent } from '@/src/core/types/interfaces';
import { computed, onMounted, ref, watchEffect } from 'vue';

interface IModalLoadContentParams extends IModalLoadContent {
  size: UiSize;
  showCloseButton?: boolean;
  buttonText?: string;
  buttonType?: UiVariant;
}

const textK = useText();
const linksStore = useLinksStore();
const modalStore = useModalStore();
const uiVariant = UiVariant;
const uiSize = UiSize;

const params = ref<Readonly<IModalLoadContentParams>>(modalStore.params as IModalLoadContentParams);

const title = computed(() => {
  if (params.value.url) {
    return params.value.title;
  } else {
    return (params.value.title && linksStore.getKey(params.value.title)) || undefined;
  }
});

const clickOutside = computed(() => {
  return params.value.targetOptions?.clickOutside === undefined
    ? true
    : params.value.targetOptions?.clickOutside;
});

const size = computed(() => {
  return params.value?.size ?? UiSize.Large;
});

const closeModal = () => {
  modalStore.closeModal();
};

const buttonType = computed(() => {
  return params.value.buttonType !== undefined ? params.value.buttonType : uiVariant.Secondary;
});

onMounted(() => {
  watchEffect(() => {
    if (clickOutside.value) {
      modalStore.lockCurrentModal(false);
    } else {
      modalStore.lockCurrentModal(true);
    }
  });
});
</script>
