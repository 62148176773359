/* tslint:disable:max-line-length */
export const emailRegEx: RegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
/* tslint:enable:max-line-length */

export const passwordRegEx: RegExp = /^(?=.*\d)(?=.*[A-Z]).{6,}$/;

export const numberRegEx: RegExp = /([0-9,.])/;

export const uppercaseRegEx: RegExp = /^(.*[A-Z].*)/;
