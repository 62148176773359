import { Req } from '@/src/core/services/requester';
import { ServiceOrder } from '@/src/core/types/api';
import { ILeaseOrderListFilters, ILeaseOrderListQuery } from '@/src/core/types/interfaces';
import { toHybrisFormat } from '@/src/core/utils/dates';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { OrderApi } from '../api/orderApi';

export const useLeaseOrdersStore = defineStore('lease-orders', () => {
  const orders = ref<ServiceOrder[]>([]);
  const orderDetails = ref<ServiceOrder>({} as ServiceOrder);
  const isBusy = ref<boolean>(false);
  const totalCount = ref<number>(0);
  const filters = ref<ILeaseOrderListFilters>({} as ILeaseOrderListFilters);

  const fetchLeaseOrders = async () => {
    setIsBusy({ isBusy: true });

    const params: ILeaseOrderListQuery = {
      ...filters.value,
    };

    // default search period should be the last 12 months
    if (!params.fromDate) {
      params.fromDate = toHybrisFormat(dayjs().subtract(12, 'month'));
    }
    if (!params.toDate) {
      params.toDate = toHybrisFormat(dayjs());
    }

    const { IsSuccess, Data } = await Req({
      url: OrderApi.LeaseOrders,
      method: 'POST',
      data: params,
    });

    if (IsSuccess && Data) {
      orders.value = Data;

      if (totalCount.value === 0) {
        totalCount.value = Data.length;
      }
    }

    setIsBusy({ isBusy: false });
    return { IsSuccess };
  };

  const fetchLeaseOrderDetails = async (payload: { orderNumber: string }) => {
    setIsBusy({ isBusy: true });

    const { IsSuccess, Data } = await Req({
      url: OrderApi.LeaseOrderDetails(payload.orderNumber),
      method: 'GET',
    });

    if (IsSuccess && Data) {
      orderDetails.value = Data;
    }

    setIsBusy({ isBusy: false });
    return { IsSuccess };
  };

  const setIsBusy = (payload: { isBusy: boolean }) => {
    isBusy.value = payload.isBusy;
  };

  const updateOrderListFilters = (payload: { Query: ILeaseOrderListFilters }) => {
    filters.value = Object.assign({}, filters.value, payload.Query);
  };

  const deleteOrderListFilter = (payload: { Key: string }) => {
    delete filters.value[payload.Key];
    filters.value = {
      ...filters.value,
    };
  };

  const clearFilterGroup = (group: readonly string[]) => {
    for (const key of group) {
      delete filters.value[key];
    }

    filters.value = {
      ...filters.value,
    };
  };

  const clearFilters = () => {
    filters.value = {};
  };

  const clearOrderDetails = () => {
    orderDetails.value = {} as ServiceOrder;
  };

  const clearOrders = () => {
    orders.value = [];
    totalCount.value = 0;
  };

  return {
    orders,
    orderDetails,
    totalCount,
    isBusy,
    filters,
    fetchLeaseOrders,
    fetchLeaseOrderDetails,
    clearOrderDetails,
    updateOrderListFilters,
    deleteOrderListFilter,
    clearFilterGroup,
    clearFilters,
    clearOrders,
  };
});
