// tslint:disable
import { apim } from '@/src/core/apim';
import { getEnv } from '@/src/core/services/environment';
import { toStandardLegacyResponse } from '@/src/core/services/legacy-response-utils';
import { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class QuoteApi {
  public static AllQuotes = `${baseUrl}/api/quote/search`;
  public static AllQuotesPaging = (page: number, size: number = 20) =>
    `${baseUrl}/api/quote/search?size=${size}&page=${page ? page : '0'}`;
  public static CreateQuote = `${baseUrl}/api/quote/createquote`;
  public static QuoteInfo = (quoteCode: string) => `${baseUrl}/api/quote/${quoteCode}`;
  public static DownloadQuoteDocument = (quoteCode: string) =>
    `${baseUrl}/api/quote/${quoteCode}/document`;
  public static OrderQuote = (quoteCode: string) => `${baseUrl}/api/quote/${quoteCode}/order`;
  public static CalculateQuoteTotal = (quoteCode: string) =>
    `${baseUrl}/api/quote/${quoteCode}/calculatetotal`;
  public static CalculateAirbusQuoteTotal = `${baseUrl}/api/airbus/quotes/price`;
  public static CreateAirbusQuote = (userId: string) =>
    `${baseUrl}/api/quote/createairbusquote/${userId}/submit`;
  public static CreateAirbusQuoteNew = (userId: string) =>
    `${baseUrl}/api/quote/createairbusquotessalesnew/${userId}/submit`;
  public static CertificateTypes = () => `${baseUrl}/api/certificatetypes`;
  public static CreateAirbusQuoteFHS = `${baseUrl}/api/quote/fhs/sendemail`;

  public static async handleCertificateTypes(settings: AxiosRequestConfig) {
    const resp = await apim.getAllCertificateTypes('satairb2b', {
      asm: Cookies.get('asm') === 'true',
    });

    return toStandardLegacyResponse(resp);
  }
}
