import { upperFirst } from '@/src/core/utils/string';

export const formatDepartmentInfoKey = (key: string) => {
  // Insert spaces before uppercase letters, convert to lowercase, and capitalize the first word only
  const formatted = key
    .replace(/([A-Z])/g, ' $1')
    .toLowerCase()
    .trim();

  // Capitalize the first letter of the first word
  return upperFirst(formatted);
};
