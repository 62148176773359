<template>
  <VSimpleTable class="product-details-table" :headers="['Remark(s)', '']" v-if="showTable">
    <tbody>
      <VSimpleTableRow v-ui-test="'product-exclusion-remark'" v-if="exclusionRemark">
        <VSimpleTableCell :style="{ width: '100%' }"> Material exclusion remark </VSimpleTableCell>
        <VSimpleTableCell>
          <button @click="showModal" class="product-details-table__link">
            <div>{{ textK('UI_ORDERS_SEE_REMARK') }}</div>
          </button>
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow v-ui-test="'product-remark'" v-if="isSaleUsed || isLease || isExchange">
        <VSimpleTableCell :style="{ width: '100%' }"> Procurement remark</VSimpleTableCell>
        <VSimpleTableCell>
          <button @click="showProcurementRemarkModal" class="product-details-table__link">
            <div>{{ textK('UI_ORDERS_SEE_REMARK') }}</div>
          </button>
        </VSimpleTableCell>
      </VSimpleTableRow>
    </tbody>
  </VSimpleTable>
</template>

<style scoped src="./product-details-remarks.scss" lang="scss"></style>

<script setup lang="ts">
import VSimpleTable from '@/src/core/components/ui/simple-table/simple-table.vue';
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import {
  IProductAdditionalData,
  Procurement,
  ProductAirbusPart,
} from '@/src/core/types/interfaces';
import {
  ACCOUNT_GROUP_NON_US,
  ACCOUNT_GROUP_US,
  PROCUREMENT_REMARK_FHS_EXCHANGE,
  PROCUREMENT_REMARK_FHS_LEASE,
  PROCUREMENT_REMARK_NON_US,
  PROCUREMENT_REMARK_US,
} from '@/src/core/types/ui';
import { useUserStore } from '@/src/profile/stores/user';
import { computed } from 'vue';

interface Props {
  id: string;
  additionalData?: IProductAdditionalData | ProductAirbusPart | null;
  procurement?: Procurement;
  isFhs?: boolean;
}

const props = defineProps<Props>();
const textK = useText();
const openModal = useModal();
const uiSize = UiSize;
const userStore = useUserStore();

const userAccountGroup = computed(() => userStore.accountGroup);
const exclusionRemark = computed(
  () => (props.additionalData as ProductAirbusPart)?.exclusionRemark,
);

const isSaleUsed = computed(() => props.procurement?.procurementType === ProcurementType.Used);
const isLease = computed(() => props.procurement?.procurementType === ProcurementType.Lease);
const isExchange = computed(
  () => props.procurement?.procurementType === ProcurementType.ExchangeNew,
);
const showTable = computed(() => {
  return (
    isSaleUsed.value ||
    exclusionRemark.value ||
    (props.isFhs && (isLease.value || isExchange.value))
  );
});

const modalKey = computed(() => {
  if (isSaleUsed.value && userAccountGroup.value === ACCOUNT_GROUP_US) {
    return PROCUREMENT_REMARK_US;
  }

  if (isSaleUsed.value && userAccountGroup.value === ACCOUNT_GROUP_NON_US) {
    return PROCUREMENT_REMARK_NON_US;
  }

  if (props.isFhs && isExchange.value) {
    return PROCUREMENT_REMARK_FHS_EXCHANGE;
  }

  if (props.isFhs && isLease.value) {
    return PROCUREMENT_REMARK_FHS_LEASE;
  }

  return null;
});

const showModal = () => {
  openModal({
    modalComponent: 'ModalRemark',
    params: {
      heading: textK('UI_PRODUCTPAGE_MATERIAL_REMARK_LABEL'),
      remark: exclusionRemark.value,
    },
  });
};

const showProcurementRemarkModal = () => {
  if (modalKey.value) {
    openModal({
      modalComponent: 'ModalLoadContent',
      params: {
        key: `${modalKey.value}_ModalUrl`,
        size: uiSize.Small,
      },
    });
  }
};
</script>
