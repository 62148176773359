<template>
  <transition
    name="animated"
    enter-active-class="animated fadeInDelayed"
    leave-active-class="animated fadeOut"
  >
    <div class="content-overlay" v-if="isOverlayActive">
      <Spinner v-if="isLoaderActive"></Spinner>
    </div>
  </transition>
</template>

<style lang="scss" src="./content-overlay.scss"></style>

<script lang="ts" setup>
import Spinner from '@/src/core/components/ui/spinner/spinner.vue';
import { Site, useUIStore } from '@/src/core/stores/ui';
import { computed, watch } from 'vue';
import { RouteLocationNormalized, useRoute } from 'vue-router';

const uiStore = useUIStore();
const route = useRoute();

const site = computed(() => {
  return uiStore.site;
});

const isOverlayActive = computed(() => {
  return uiStore.showGlobalOverlay;
});

const isLoaderActive = computed(() => {
  return uiStore.showGlobalLoader;
});

watch(
  () => route,
  (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    if (to.fullPath !== from.fullPath) {
      uiStore.showContentOverlay({ showOverlay: false });
    }
  },
);

watch(
  () => site.value,
  (to: Site, from: Site) => {
    if (to !== from) {
      uiStore.showContentOverlay({ showOverlay: false });
    }
  },
);
</script>
