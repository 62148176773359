import { HttpResponse } from '@/src/core/apim';

/**
 * Changes the data model of an HTTP response easily.
 * @param resp fetch HTTP Response
 * @param data the data model that should match the legacy Umbraco
 * @returns same HTTP response, with the data model applied.
 * @example `
 *  toLegacyResponse(resp, {
 *    Data: obj,
 *    Notification: [],
 *    CustomActions: []
 *   )
 * `
 */
export const toLegacyResponse = <T>(resp: HttpResponse<unknown>, data: T) => {
  resp.data = data;
  return resp as HttpResponse<T>;
};

/**
 * Changes the data model of an HTTP response to the
 * standard legacy Umbraco model. If there is an endpoint,
 * that has been exposed directly, we can simply use
 * this function to align to the legacy model.
 * @param resp fetch HTTP Response
 * @returns same HTTP response, with the data model applied.
 * @example `
 *  toStandardLegacyResponse(resp); // this will move resp.data to resp.data.Data
 * `
 */
export const toStandardLegacyResponse = <T>(resp: HttpResponse<T>) =>
  toLegacyResponse(resp, { Data: resp.data ?? null });
