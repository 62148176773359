<template>
  <div ref="lottieContainer" />
</template>

<script lang="ts" setup>
import lottie, { AnimationConfigWithPath } from 'lottie-web';
import { onMounted, ref, watch } from 'vue';

interface Props {
  animationData: string;
  startLoopFrame?: number;
  endLoopFrame?: number;
  loop?: boolean | number;
  autoplay?: boolean;
  forceLoopFrames?: boolean;
  stopAtFirstFrameAfterComplete?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  startLoopFrame: 0,
  endLoopFrame: 0,
  loop: false,
  autoplay: false,
  forceLoopFrames: false,
  stopAtFirstFrameAfterComplete: false,
});

let anim = lottie.loadAnimation({} as AnimationConfigWithPath);
const lottieContainer = ref<HTMLElement | null>(null);
const totalFrames = ref(0);
const emit = defineEmits(['complete']);

const playSegment = () => {
  if (props.startLoopFrame && props.endLoopFrame) {
    anim.playSegments([props.startLoopFrame, props.endLoopFrame], props.forceLoopFrames);
  } else if (totalFrames.value) {
    anim.playSegments([0, totalFrames.value], props.forceLoopFrames);
  }
};

const complete = () => {
  emit('complete');
};

onMounted(async () => {
  anim = lottie.loadAnimation({
    container: lottieContainer?.value as HTMLElement,
    renderer: 'svg',
    loop: props.loop,
    autoplay: props.autoplay,
    animationData: props.animationData,
  });
  totalFrames.value = anim.getDuration(true);
  anim.addEventListener('complete', complete);

  if (props.startLoopFrame && props.endLoopFrame) {
    playSegment();
  }

  if (props.stopAtFirstFrameAfterComplete && typeof props.loop === 'number') {
    anim.addEventListener('complete', () => {
      anim.goToAndStop(anim.firstFrame, true);
    });
  }
});

watch(
  () => props.loop,
  () => {
    anim.loop = props.loop;
  },
);

watch(
  () => props.startLoopFrame,
  () => {
    playSegment();
  },
);

watch(
  () => props.endLoopFrame,
  () => {
    playSegment();
  },
);
</script>
