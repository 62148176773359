import { defineStore } from 'pinia';
import { ref } from 'vue';
import { UmbracoApi } from '../api';
import { Req } from '../services/requester';
import { MenuQuicklinkGroup } from '../types/api';

export const useHelpMenuStore = defineStore('help-menu', () => {
  const helpLinks = ref<MenuQuicklinkGroup[]>([]);
  const isBusy = ref<boolean>(false);

  const isBusyUpdated = (payload: { isBusy: boolean }) => {
    isBusy.value = payload.isBusy;
  };

  const setQuickLinks = (payload: { quickLinks: MenuQuicklinkGroup[] }) => {
    helpLinks.value = [];
    helpLinks.value.push(...payload.quickLinks);
  };

  const fetchHelpLinks = async () => {
    isBusyUpdated({ isBusy: true });
    const { IsSuccess, Data } = await Req({
      url: UmbracoApi.getMenuHelpLinks,
      method: 'POST',
    });

    if (IsSuccess) {
      setQuickLinks({ quickLinks: Data });
    }
    isBusyUpdated({ isBusy: false });
  };

  return {
    helpLinks,
    isBusy,
    fetchHelpLinks,
  };
});
