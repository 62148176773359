import { BreakpointWidth } from '@/src/core/settings/media-queries.model';

export default class MobileDetection {
  public static isMobile(): boolean {
    return window.innerWidth < BreakpointWidth.Cat;
  }

  public static isMobileAndTablet(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent,
      );
    return isTablet || window.matchMedia('(max-width: 767px)').matches;
  }
}
