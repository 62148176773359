<template>
  <StandardDialogModal class="create-airbus-quote" :size="uiSize.Large" :loading="isBusy">
    <template #header>
      <ModalRequestQuoteHeader
        :offer-id="id"
        :header-title="textK('UI_QUOTES_QUOTE_SINGLE_TITLE')"
        :procurement="procurement"
      />
    </template>

    <template #body>
      <p class="pb-xl">
        {{ textK('UI_QUOTES_REQUEST_MODAL_DESCRIPTION') }}
      </p>
      <VTextField
        v-model="formData.poNumber"
        input-type="text"
        :type="uiVariant.Secondary"
        :label="textK('UI_QUOTES_REQUEST_REFERENCE_TEXT')"
        name="referenceNumber"
        :required="true"
        :vmax-length="11"
      />
      <br />
      <VTextarea
        v-model="formData.remark"
        :vmax-length="128"
        :type="uiVariant.Secondary"
        :label="textK('UI_QUOTES_REQUEST_MESSAGE_TEXT')"
      />
      <p class="create-airbus-quote__disclaimer pt-md">
        {{ textK('UI_QUOTES_REQUEST_MESSAGE_DISCLAIMER_TEXT') }}
      </p>
    </template>

    <template #actions>
      <VButton
        @click.prevent="onSubmit"
        :loading="isBusy"
        :disabled="formData.poNumber.length == 0"
        :type="uiVariant.Default"
        v-ui-test="'disable-account-ok-button'"
        >{{ textK('UI_QUOTES_CREATE_QUOTE_BTN') }}
      </VButton>
    </template>
  </StandardDialogModal>
</template>

<style src="./modal-create-airbus-quote.scss" lang="scss" scoped></style>
<script setup lang="ts">
import { QuoteApi } from '@/src/core/api';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { Req } from '@/src/core/services/requester';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { useModalStore } from '@/src/core/stores/modal';
import { useNotificationsStore } from '@/src/core/stores/notifications';
import { NotificationTypes } from '@/src/core/types/api';
import { AirbusPart } from '@/src/core/types/interfaces';
import ModalRequestQuoteHeader from '@/src/market/components/modals/modal-request-quote/modal-request-quote-header/modal-request-quote-header.vue';
import { productAdditionalData } from '@/src/market/services/product-parts';
import { useProductStore } from '@/src/market/stores/product';
import { useUserStore } from '@/src/profile/stores/user';
import { computed, reactive, ref } from 'vue';

interface Params {
  id: string;
}

interface Form {
  poNumber: string;
  remark?: string;
}

const textK = useText();
const notificationsStore = useNotificationsStore();
const modalStore = useModalStore();
const uiSize = UiSize;
const uiVariant = UiVariant;
const userStore = useUserStore();
const productStore = useProductStore();
const formData = reactive<Form>({ poNumber: '' });
const isBusy = ref(false);

const id = computed(() => {
  return (modalStore.params as Params)?.id;
});

const product = computed(() => {
  return productStore.productById(id.value);
});

const additionalData = computed(() => {
  return productAdditionalData(id.value, product.value?.OwningSystem ?? '');
});

const procurement = computed(() => {
  return (additionalData.value as AirbusPart)?.procurements.find(
    ({ procurementType }) => procurementType === ProcurementType.New,
  );
});

const closeModal = () => {
  modalStore.closeModal();
};

const isBlueLightQuoteEnabled = computed(() => hasFeature(FEATURES.BLUE_LIGHT_QUOTE));
const isBlueAllQuoteEnabled = computed(() => hasFeature(FEATURES.BLUE_QUOTES));

const onSubmit = async () => {
  isBusy.value = true;
  const { GlobalId } = userStore.currentUser;

  const requestObejct = {
    ...formData,
    productId: id.value,
    requestedQuantity: 1,
  };

  const { IsSuccess, Data } = await Req({
    url: QuoteApi.CreateAirbusQuoteNew(GlobalId),
    method: 'POST',
    data: requestObejct,
  });

  if (IsSuccess) {
    closeModal();
    if (isBlueAllQuoteEnabled.value) {
      notificationsStore.addNotification({
        notificationItem: {
          NotificationAction: {
            Url: `/market/quotes/${Data.code}`,
            Name: 'Follow your request.',
          },
          Title: 'Your quote request was submitted.',
          Type: NotificationTypes.Success,
          Timing: 5000,
        },
      });
      return;
    }

    if (isBlueLightQuoteEnabled.value) {
      notificationsStore.addNotification({
        notificationItem: {
          Title: 'Thank you for your request!',
          Description: 'The Customer Resolution Services in Airbus will handle your request.',
          Type: NotificationTypes.Success,
          Timing: 5000,
        },
      });
      return;
    }
  }

  isBusy.value = false;
};
</script>
