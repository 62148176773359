import { Ref, onBeforeUnmount, onMounted } from 'vue';

export default function (
  observedElement: Ref<HTMLElement | null>,
  action: () => void,
  timeout: number = 0,
) {
  const options = {
    root: null,
    threshold: 0.5,
  };

  const callback: IntersectionObserverCallback = (entries) => {
    if (!entries || entries.length === 0) return;

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        action();
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);

  onMounted(() => {
    setTimeout(() => {
      if (observedElement.value) {
        observer.observe(observedElement.value!);
      }
    }, timeout);
  });

  onBeforeUnmount(() => {
    observer.unobserve(observedElement.value!);
  });
}
