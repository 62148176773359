<template>
  <transition name="fade">
    <div class="splash-screen" v-if="show">
      <div class="splash-screen__wrapper">
        <PlanningSplash v-if="isPlanning" @isFinished="onIsFinished" />
        <MarketSplash v-else @isFinished="onIsFinished" />
      </div>
    </div>
  </transition>
</template>

<style src="./splash-screen.scss" lang="scss" scoped></style>

<script lang="ts" setup>
import { useScrollLockStore } from '@/src/core/stores/scroll-lock';
import { useUIStore } from '@/src/core/stores/ui';
import { PageTransitionEventBus } from '@/src/core/utils/page-transition-event-bus';
import MarketSplash from '@/src/market/components/ui/market-splash/market-splash.vue';
import PlanningSplash from '@/src/planning/components/ui/planning-splash/planning-splash.vue';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const isFinished = ref(true);

const scrollLockStore = useScrollLockStore();
const uiStore = useUIStore();
const route = useRoute();

const onIsFinished = (event: boolean) => {
  isFinished.value = event;
};

const active = computed(() => {
  return uiStore.showSplashScreen;
});

const show = computed(() => {
  return active.value || !isFinished.value;
});

const isPlanning = computed(() => {
  return window.location.pathname.includes('planning');
});

watch(
  () => show.value,
  (value: boolean) => {
    const splashElemId = 'splashScreen';
    if (value) {
      setTimeout(() => {
        scrollLockStore.scrollLock({ id: splashElemId });
      }, 100);
    } else {
      PageTransitionEventBus.$emit('ended');
      scrollLockStore.removeScrollLock({ id: splashElemId });
    }
  },
);

watch(
  () => route,
  () => {
    uiStore.setSplashScreen({ splashScreen: false });
  },
);
</script>
