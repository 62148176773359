export class FilterStringArrayUtils {
  public static stringToFilterArray(value: string | string, separator?: string): string[] {
    separator = separator || ' ';
    return value
      .replace(/([ ]{2,}|[.,])/g, separator)
      .split(separator)
      .filter((item) => item);
  }

  public static filterArrayToString(filterArray?: string[] | string, seperator?: string): string {
    seperator = seperator || ' ';
    if (typeof filterArray === 'string') {
      filterArray = [filterArray];
    }
    return filterArray?.join(seperator) || '';
  }
}
