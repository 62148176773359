import { useUIStore } from '@/src/core/stores/ui';
import { nextTick } from 'vue';

const UiAnimationGate = async () => {
  const uiStore = useUIStore();

  const removeLoadClass = () => {
    const body: HTMLElement | null = document.querySelector('body');
    if (body) {
      body.classList.remove('loading');
    }
  };

  if (!uiStore.siteIsLoaded) {
    uiStore.setSiteLoaded({ siteLoaded: true });
    nextTick(() => {
      setTimeout(() => {
        removeLoadClass();
      }, 250);
    });
  }
};

export default UiAnimationGate;
