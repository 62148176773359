<template>
  <div class="search-list-component">
    <div
      class="search-list__search-field"
      v-ui-test="'search-list'"
      v-if="searchable && data && data.length > 3"
    >
      <VTextField
        v-ui-test="uiTestName"
        :type="uiVariant.Secondary"
        :label="label"
        v-model="searchQuery"
        @blur="onBlur"
      ></VTextField>
    </div>
    <InlineNotification
      class="search-list__inline-notification"
      v-if="isMarketplace && hasRestrictedAddress()"
      :type="notificationTypes.Warning"
      :size="uiSize.Small"
    >
      {{ textK('UI_PRODUCT_RESTRICTED_SHIPPING_ADDRESS') }}
    </InlineNotification>
    <InlineNotification
      class="search-list__inline-notification"
      v-if="!isMarketplace && hasRestrictedAddress()"
      :type="notificationTypes.Warning"
      :size="uiSize.Small"
    >
      {{ textK('UI_PRODUCT_RESTRICTED_SHIPPING_DELIVERY_ADDRESS') }}
    </InlineNotification>
    <div class="search-list-scroll">
      <ListAnimation :tag="'ul'" class="search-list">
        <li
          class="search-list-item list-animation-item"
          :class="item.RestrictedAddress ? 'disabled' : ''"
          v-for="item in result"
          :key="item[bindKey]"
          @click="select(item)"
        >
          <slot :item="item" />
        </li>
      </ListAnimation>
    </div>
  </div>
</template>

<style src="./search-list.scss" lang="scss" scoped></style>

<script lang="ts" setup>
import ListAnimation from '@/src/core/components/ui/animations/list-animation/list-animation.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import InlineNotification from '@/src/core/components/ui/inline-notification/inline-notification.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { NotificationTypes } from '@/src/core/types/api';
import { IModalRequestQuoteParams } from '@/src/market/components/modals/modal-request-quote/modal-request-quote.vue';
import Fuse from 'fuse.js';
import { computed, ref } from 'vue';

interface Props {
  label: string;
  uiTestName?: string;
  data?: [];
  searchKeys?: string[];
  bindKey?: string;
  searchable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  uiTestName: '',
  data: () => [],
  searchKeys: () => ['Name'],
  bindKey: 'Id',
  searchable: true,
});
const textK = useText();
const modalStore = useModalStore();
const uiVariant = UiVariant;
const uiSize = UiSize;
const notificationTypes = NotificationTypes;
const searchQuery = ref('');
const restrictedAddress = ref(false);
const emit = defineEmits(['selected', 'blur', 'searchused']);

const fuseOptions = computed<Fuse.IFuseOptions<any>>(() => {
  return {
    findAllMatches: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    minMatchCharLength: 0,
    keys: props.searchKeys,
  };
});

const params = computed(() => {
  return modalStore.params as IModalRequestQuoteParams;
});

const isMarketplace = computed(() => {
  return params.value?.isMarketplace;
});

const hasRestrictedAddress = () => {
  if (!props.data) {
    return undefined;
  }
  restrictedAddress.value = false;
  const keys = Object.keys(props.data);
  for (const key of keys) {
    const value = props.data[key];
    if (value.DefaultAddress === true && value.RestrictedAddress === true) {
      restrictedAddress.value = true;
    }
  }
  return restrictedAddress.value;
};

const result = computed(() => {
  if (searchQuery.value.length > 0) {
    const fuse = new Fuse(props.data, fuseOptions.value);
    emit('searchused', searchQuery.value);
    return fuse.search(searchQuery.value).map(({ item }) => item);
  }
  return props.data;
});

const select = (item: object) => {
  emit('selected', item);
};

const onBlur = (text: string) => {
  emit('blur', text);
};
</script>
