<template>
  <div class="market-splash">
    <MarketLogo ref="logo" class="market-splash__logo" />
    <h4 class="market-splash__text" ref="text">Welcome to Satair Market</h4>
  </div>
</template>

<style src="./market-splash.scss" lang="scss" scoped></style>

<script lang="ts" setup>
import { useUIStore } from '@/src/core/stores/ui';
import MarketLogo from '@/src/market/components/ui/market-logo/market-logo.vue';
import { gsap } from 'gsap';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

const logo = ref<InstanceType<typeof MarketLogo> | null>(null);
const text = ref<HTMLElement | null>(null);

const uiStore = useUIStore();
const timeline: GSAPTimeline = gsap.timeline({ paused: true });
const easeOut: string = 'power4.out';
const easeIn: string = 'back.in';

const emit = defineEmits(['isFinished']);

const show = computed(() => {
  return uiStore.showSplashScreen;
});

const createTimeline = () => {
  if (
    logo.value?.leftside &&
    logo.value?.rightside &&
    logo.value?.blacksquare &&
    logo.value?.whitesquare &&
    logo.value?.whitesquare50
  ) {
    timeline
      .add('staging', 0.5)
      .from(logo.value?.leftside, { x: -350, y: -350, ease: easeOut, duration: 0.6 }, 'staging')
      .from(logo.value?.rightside, { x: 350, y: 350, ease: easeOut, duration: 0.6 }, 'staging')
      .from(text.value, { y: 15, opacity: 0, ease: easeOut, duration: 1 }, 'staging+=0.6')
      .from(
        logo.value?.whitesquare50,
        { x: 400, y: 400, ease: easeOut, duration: 0.6 },
        'staging+=0.3',
      )
      .from(
        logo.value?.whitesquare,
        { x: 400, y: 400, ease: easeOut, duration: 0.6 },
        'staging+=0.3',
      )
      .from(
        logo.value?.blacksquare,
        { x: -400, y: -400, ease: easeOut, duration: 0.6 },
        'staging+=0.3',
      )
      .add(() => {
        if (!show.value) {
          timeline.play('end');
        }
      })
      .to(logo.value?.whitesquare50, { x: -60, y: -60, ease: easeIn, duration: 0.6 }, 'loopIn')
      .to(logo.value?.whitesquare, { x: -60, y: -60, ease: easeIn, duration: 0.6 }, 'loopIn')
      .to(logo.value?.blacksquare, { x: 60, y: 60, ease: easeIn, duration: 0.6 }, 'loopIn')
      .to(
        logo.value?.whitesquare50,
        { scale: 0, transformOrigin: 'center center', duration: 0.3 },
        'loopIn+=0.6',
      )
      .to(
        logo.value?.whitesquare,
        { scale: 0, transformOrigin: 'center center', duration: 0.3 },
        'loopIn+=0.6',
      )
      .to(
        logo.value?.blacksquare,
        { scale: 0, transformOrigin: 'center center', duration: 0.3 },
        'loopIn+=0.6',
      )
      .set(logo.value?.whitesquare50, { x: 350, y: 350, scale: 1 }, 'loopIn+=0.9')
      .set(logo.value?.whitesquare, { x: 350, y: 350, scale: 1 }, 'loopIn+=0.9')
      .set(logo.value?.blacksquare, { x: -350, y: -350, scale: 1 }, 'loopIn+=0.9')
      .to(
        logo.value?.whitesquare50,
        { x: 0, y: 0, scale: 1, ease: easeOut, duration: 1.2 },
        'loopIn+=0.9',
      )
      .to(
        logo.value?.whitesquare,
        { x: 0, y: 0, scale: 1, ease: easeOut, duration: 1.2 },
        'loopIn+=0.9',
      )
      .to(
        logo.value?.blacksquare,
        { x: 0, y: 0, scale: 1, ease: easeOut, duration: 1.2 },
        'loopIn+=0.9',
      )
      .add(() => {
        if (show.value) {
          timeline.play('loopIn');
        }
      })
      .to(text.value, { opacity: 0, ease: easeOut, duration: 0.3 }, 'end')
      .to(logo.value?.leftside, { opacity: 0, ease: easeOut, duration: 0.3 }, 'end')
      .to(logo.value?.rightside, { opacity: 0, ease: easeOut, duration: 0.3 }, 'end')
      .to(logo.value?.whitesquare50, { opacity: 0, ease: easeOut, duration: 0.3 }, 'end')
      .to(logo.value?.whitesquare, { opacity: 0, ease: easeOut, duration: 0.3 }, 'end')
      .to(
        logo.value?.blacksquare,
        {
          opacity: 0,
          ease: easeOut,
          duration: 0.3,
          onComplete: () => {
            emit('isFinished', true);
          },
        },
        'end',
      );
  }
};

watch(
  () => show.value,
  () => {
    if (show.value) {
      emit('isFinished', false);
      timeline.play('staging');
    }
  },
  { immediate: true },
);

onMounted(() => {
  createTimeline();
});

onUnmounted(() => {
  timeline.kill();
});
</script>
