<template>
  <InViewWatcher class="pull-up-wide">
    <div class="full-width-element-module-wrapper">
      <slot></slot>
    </div>
  </InViewWatcher>
</template>

<style lang="scss" src="./full-width-element.scss"></style>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';

@Component({
  components: {
    InViewWatcher,
  },
})
export class ContentFullWidthMediaModule extends Vue {}

export default toNative(ContentFullWidthMediaModule);
</script>
