<template>
  <div class="comparison-boxes-module-wrapper">
    <slot></slot>
  </div>
</template>

<style lang="scss" src="./comparison-boxes.scss"></style>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';

@Component({
  components: {
    InViewWatcher,
  },
})
export class ContentComparisonBoxesModule extends Vue {}

export default toNative(ContentComparisonBoxesModule);
</script>
