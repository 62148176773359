<template>
  <div
    ref="viewWatcher"
    :style="{ minHeight: initialMinHeight }"
    :class="{
      'in-view': inView,
      'above-viewport': aboveViewPort,
      'below-viewport': belowViewPort,
    }"
  >
    <transition :css="false" name="fade">
      <slot v-if="visible"></slot>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { ResizeEventBus } from '@/src/core/utils/resize-event-bus';
import { nextTick, onMounted, onUnmounted, ref } from 'vue';

const viewWatcher = ref<HTMLElement | null>();
const visible = ref(true);

const elementWatcher = ref();
const initialMinHeight = ref<string | null>(window.outerHeight / 6 + 'px');
const visibilityThreshold = ref(window.outerHeight / 6);
const initialVisible = ref(false);
const aboveViewPort = ref(false);
const belowViewPort = ref(true);
const inView = ref(false);

const isInViewport = () => {
  visible.value = true;
  inView.value = true;
  aboveViewPort.value = false;
  belowViewPort.value = false;
  initialMinHeight.value = null;
};

const isAboveViewport = () => {
  if (elementWatcher.value.isAboveViewport) {
    aboveViewPort.value = true;
    belowViewPort.value = false;
  }
};

const isBelowViewport = () => {
  if (elementWatcher.value.isBelowViewport) {
    aboveViewPort.value = false;
    belowViewPort.value = true;
  }
};

const visibilityChange = () => {
  if (elementWatcher.value) {
    elementWatcher.value.visibilityChange(() => {
      isAboveViewport();
      isBelowViewport();
    });
  }
};

const exitViewport = () => {
  if (elementWatcher.value) {
    elementWatcher.value.exitViewport(() => {
      if (initialVisible.value) {
        return;
      }
    });
  }
};

const enterViewport = () => {
  if (elementWatcher.value) {
    elementWatcher.value.enterViewport(() => {
      if (initialVisible.value) {
        return;
      }
      isInViewport();
    });
  }
};

const initialInView = () => {
  aboveViewPort.value = true;
  visible.value = true;
  exitViewport();
  enterViewport();
  nextTick(() => {
    inView.value = true;
    initialVisible.value = false;
    initialMinHeight.value = null;
    belowViewPort.value = false;
  });
};

onMounted(() => {
  elementWatcher.value = (window as unknown as any)?.scrollMonitor?.create?.(viewWatcher.value, {
    top: 0,
  });

  // INITIAL VISIBLE ITEMS
  if (elementWatcher.value && elementWatcher.value.top < visibilityThreshold.value) {
    initialVisible.value = true;
    initialInView();
    return;
  }

  exitViewport();
  enterViewport();
  visibilityChange();

  ResizeEventBus.$on('resize', initialInView);
});

onUnmounted(() => {
  ResizeEventBus.$off('resize', initialInView);
});
</script>
