<template>
  <div class="blog-post-overview">
    <div class="content-theme--default">
      <div class="content-module-section">
        <section class="content-module-section__inner">
          <BlogFilters
            :filters="filters"
            :toggle-filter="toggleFilter"
            :filter-is-selected="(filter) => setOfFilters.has(filter)"
          />
        </section>
      </div>
    </div>

    <transition @leave="onLeave" tag="div">
      <slot v-if="includedInSelectedFilters(featuredArticleFilters)"></slot>
    </transition>
    <div class="content-theme--default">
      <div class="content-module-section">
        <section class="content-module-section__inner">
          <ContentLinkBoxesModule>
            <section class="link-boxes-module">
              <div class="link-boxes-module__inner" ref="listContainerRef">
                <transition-group
                  @enter="onEnter"
                  @leave="onLeave"
                  tag="div"
                  class="link-boxes-module__boxes"
                >
                  <div
                    class="link-boxes-module__box link-boxes-module__box--no-transitions"
                    v-for="(bpost, i) in allVisibleBlogPosts"
                    :key="bpost.dataset.uid"
                    :data-delay="getDelayByIndex(i)"
                  >
                    <BlogPostItem :blog-post-content="bpost" :key-name="i"></BlogPostItem>
                  </div>
                </transition-group>
              </div>
            </section>
          </ContentLinkBoxesModule>
        </section>
      </div>
    </div>
    <div v-if="!hideLoadMore" class="load-more">
      <VButton :type="uiVariant.Secondary" :loading="isBusy" @click="loadMorePosts()">
        {{ textK('UI_BLOG_LOAD_MORE') }}
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" src="./blog-post-overview.scss" scoped></style>

<script lang="ts">
import { Vue, Component, Prop, Watch, toNative } from 'vue-facing-decorator';
import BlogPostItem from '@/src/content/components/content-modules/blog-post-item/blog-post-item.vue';
import BlogFilters from '@/src/content/components/content-modules/blog-post-overview/blog-filters/blog-filters.vue';
import ContentCtaBoxModule from '@/src/content/components/content-modules/cta-box-module/cta-box-module.vue';
import ContentLinkBoxesModule from '@/src/content/components/content-modules/link-boxes-module/link-boxes.vue';
import ContentModuleSection from '@/src/content/layouts/module-section/content-module-section.vue';
import { BlogPostsApi } from '@/src/core/api';
import MediaHandler from '@/src/core/components/media-handler/media-handler.vue';
import ArrowIcon from '@/src/core/components/ui/ani-icons/arrow/arrow-icon.vue';
import ListAnimation from '@/src/core/components/ui/animations/list-animation/list-animation.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { GenerateUniqueIdentifier } from '@/src/core/plugins/uuid4';
import { Req } from '@/src/core/services/requester';
import { gsap } from 'gsap';

@Component({
  components: {
    ContentModuleSection,
    ContentLinkBoxesModule,
    ContentCtaBoxModule,
    MediaHandler,
    ArrowIcon,
    VButton,
    BlogPostItem,
    ListAnimation,
    BlogFilters,
  },
})
export class BlogPostOverview extends Vue {
  public textK = useText();
  @Prop({ default: [] }) public filters: string[];
  @Prop({ default: [] }) public featuredArticleFilters: string[];

  public htmlStringContent: string = '';

  private component: string | null = null;
  public uiVariant = UiVariant;
  public isBusy: boolean = false;
  private pageNumber = 1;
  private pageSize = 9;
  private blogPosts: [] = [];
  private inView = false;
  public hideLoadMore = false;
  public setOfFilters: Set<string> = new Set();

  public $refs: {
    listContainerRef: HTMLElement;
  };

  /*tslint:disable:no-any*/
  // TYPE: ScrollMonitor
  private elementWatcher: any;
  /*tslint:enable:no-any*/

  public get content(): string {
    return this.htmlStringContent;
  }

  public get allVisibleBlogPosts() {
    if (this.inView) {
      return this.blogPosts;
    } else {
      return [];
    }
  }

  public get selectedFilters() {
    const fromUrl = this.$route.query.filters as string;

    if (fromUrl) {
      return fromUrl.split(',').map(decodeURIComponent);
    }

    return [];
  }

  private async getPosts(pageNumber: number) {
    this.isBusy = true;
    const { IsSuccess, Data } = await Req({
      url: BlogPostsApi.GetBlogPosts(pageNumber, this.selectedFilters.map(encodeURIComponent)),
    });

    if (IsSuccess) {
      const content: string = Data.Content;
      const totalPages = Data?.Paging?.TotalPages || 0;

      this.hideLoadMore = totalPages <= pageNumber;

      const blogPosts = [
        ...(pageNumber === 1 ? [] : this.blogPosts),
        ...this.findNewBlogPosts(content),
      ];

      this.blogPosts = blogPosts as [];
      /* tslint:disable-next-line */
      this.allVisibleBlogPosts;
    }

    this.isBusy = false;
  }

  private findNewBlogPosts(content: string) {
    const parser = new DOMParser();
    const htmlNewContent = parser.parseFromString(content, 'text/html');
    const blogPosts = Array.from(
      htmlNewContent.getElementsByClassName('blog-post-cta'),
    ) as HTMLElement[];

    for (const blogPostElement of blogPosts) {
      const firstAnchor = blogPostElement.querySelector('a');
      const urlToPost = firstAnchor?.getAttribute('href');

      blogPostElement.dataset.uid = urlToPost || new GenerateUniqueIdentifier().uuid4();
    }

    return blogPosts;
  }

  public mounted() {
    this.elementWatcher = (window as unknown as any).scrollMonitor?.create?.(
      (this.$refs as any).listContainerRef,
      {
        top: 0,
      },
    );

    this.elementWatcher.enterViewport(() => {
      this.inView = true;
    });
  }

  public async loadMorePosts() {
    this.pageNumber += 1;
    await this.getPosts(this.pageNumber);
  }

  public onEnter(el: HTMLElement, done: () => void) {
    const delay = Number(el.dataset.delay) * 0.3;

    gsap.fromTo(
      el,
      {
        opacity: 0,
        y: '5vh',
      },
      {
        duration: 1.5,
        opacity: 1,
        y: 0,
        delay,
        ease: 'ease-in-out',
        onComplete: done,
      },
    );
  }

  public onLeave(el: HTMLElement, done: () => void) {
    gsap.to(el, {
      duration: 0.5,
      opacity: 0,
      y: '5vh',
      ease: 'ease-in-out',
      onComplete: done,
    });
  }

  public getDelayByIndex(index: number) {
    return index % this.pageSize;
  }

  @Watch('selectedFilters', { immediate: true }) public selectedFiltersWatcher() {
    this.setOfFilters = new Set(this.selectedFilters);
    this.pageNumber = 1;
    this.getPosts(this.pageNumber);
  }

  public toggleFilter(filter: string) {
    if (this.setOfFilters.has(filter)) {
      this.setOfFilters.delete(filter);
    } else {
      this.setOfFilters.add(filter);
    }
    const hasFilters = this.setOfFilters.size;

    this.$router.push({
      path: this.$route.path,
      query: {
        filters: hasFilters
          ? Array.from(this.setOfFilters).map(encodeURIComponent).join(',')
          : undefined,
      },
    });
    this.inView = true;
  }

  public includedInSelectedFilters(filters: string[]) {
    if (!filters.length || !this.setOfFilters.size) {
      return true;
    }

    return filters.some((filter) => this.setOfFilters.has(filter));
  }
}

export default toNative(BlogPostOverview);
</script>
