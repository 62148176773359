<template>
  <p>
    {{ textK('UI_TOOL_QUOTATION_SERIAL_NUMBER_LABEL') }}
    <span
      ><strong>{{ serialNumber }}</strong></span
    >
  </p>
</template>

<script setup lang="ts">
import useText from '@/src/core/hooks/useText';

export interface Props {
  serialNumber?: string;
}

defineProps<Props>();

const textK = useText();
</script>
