import { QuoteApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { CodeNamePair } from '@/src/core/types/api';
import { RepairRequestQuoteForm, RepairRequestQuoteFormRequest } from '@/src/core/types/interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useRequestRepairQuoteStore = defineStore('requestRepairQuoteStore', () => {
  const isBusy = ref<boolean>(false);
  const certificateTypes = ref<CodeNamePair[]>([]);
  const formFields = ref<{ [id: string]: RepairRequestQuoteForm }>({});
  const error = ref<any>(null);

  const reset = () => {
    certificateTypes.value = [];
    formFields.value = {};
    isBusy.value = false;
    error.value = null;
  };

  const fetchCertificates = async () => {
    isBusy.value = true;
    try {
      if (certificateTypes.value.length === 0) {
        const { Data, IsSuccess } = await Req(
          {
            url: QuoteApi.CertificateTypes(),
          },
          undefined,
          QuoteApi.handleCertificateTypes,
        );

        if (IsSuccess && Data) {
          certificateTypes.value = Data;
        }
      }
    } catch (error) {
      error.value = error;
    } finally {
      isBusy.value = false;
    }
  };

  const saveForm = (productId: string, fields: RepairRequestQuoteForm) => {
    formFields.value[productId] = { ...fields };
  };

  const clearForm = (productId: string) => {
    delete formFields.value[productId];
  };

  const getFieldsByProductId = (productId: string) => {
    return formFields.value[productId] || null;
  };

  const onSubmit = async (
    productData: {
      productId: string;
      procurementType: string;
      globalId: string;
      serialNumberRequired: boolean;
    },
    formData: RepairRequestQuoteFormRequest,
  ) => {
    isBusy.value = true;

    const processData = processFormData(
      formData,
      productData.serialNumberRequired,
      formData.flightHoursUnkown,
    );
    const payload = {
      productId: productData.productId,
      procurementType: productData.procurementType,
      ...processData,
    };

    const { IsSuccess } = await Req({
      url: QuoteApi.CreateAirbusQuote(productData.globalId),
      method: 'POST',
      data: payload,
    });

    isBusy.value = false;
    return IsSuccess;
  };

  const processFormData = (
    formData: RepairRequestQuoteFormRequest,
    serialNumberRequired: boolean,
    flightHoursUnkown: boolean,
  ) => {
    const processedData = {};
    for (const [key, val] of Object.entries(formData)) {
      if (key === 'serialNumber' && serialNumberRequired && val !== '') {
        processedData[key] = val;
      } else if (key === 'flightHours' && val !== '') {
        if (!flightHoursUnkown) {
          processedData[key] = val;
        }
      } else if (val !== '' && key !== 'flightHoursUnkown') {
        processedData[key] = val;
      }
    }

    return processedData;
  };

  return {
    isBusy,
    certificateTypes,
    formFields,
    error,
    reset,
    fetchCertificates,
    saveForm,
    clearForm,
    getFieldsByProductId,
    onSubmit,
  };
});
