<template>
  <div class="faq-categories">
    <div class="faq-categories__wrapper" :style="{ minHeight: wrapperHeight + 'px' }">
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInTranslate"
        leave-active-class="animated fadeOutTranslate"
      >
        <FaqBackButton v-if="categories && categories.length > 0 && parent"></FaqBackButton>
      </transition>
      <transition-group
        ref="items"
        name="content-nav-ani"
        tag="ul"
        :css="false"
        @before-enter="navItemsBeforeEnter"
        @enter="navItemsEnter"
        @leave="navItemsLeave"
        @before-leave="navItemsLeaveActive"
        class="faq-categories__items"
      >
        <li
          v-for="(item, i) in categories"
          :key="item.label"
          :data-index="i"
          class="faq-categories__items__item"
        >
          <router-link class="faq-categories__link" :to="item.url"
            ><span class="faq-categories__link--title" :title="titleTag(item?.label || '')">{{
              item.label
            }}</span></router-link
          >
        </li>
      </transition-group>
    </div>
  </div>
</template>

<style lang="scss" src="./faq-categories.scss"></style>

<script lang="ts" setup>
import FaqBackButton from '@/src/content/components/faq/faq-back-button/faq-back-button.vue';
import { useFaqStore } from '@/src/content/stores/faq';
import useTitleTag from '@/src/core/hooks/useTitleTag';
import { BreakpointWidth } from '@/src/core/settings/media-queries.model';
import { ResizeEventBus } from '@/src/core/utils/resize-event-bus';
import { gsap } from 'gsap';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

const titleTag = useTitleTag;
const faqStore = useFaqStore();

const items = ref<Element | null>(null);
const cols = ref(0);

const BREAKPOINTS = BreakpointWidth;
const ITEM_HEIGHT = 50;
const ANIMATION_DURATION = 0.35;
const ANIMATION_EASING: string = 'power4.inOut';
const DELAY_FACTOR = 0.05;

const categoryColumns = computed(() => {
  if (BREAKPOINTS.Dog < window.innerWidth) {
    return 4;
  } else if (BREAKPOINTS.Cat < window.innerWidth) {
    return 3;
  } else if (BREAKPOINTS.Mouse < window.innerWidth) {
    return 2;
  } else {
    return 1;
  }
});

const categories = computed(() => {
  return faqStore.categories;
});

const setCols = () => {
  if (cols.value !== categoryColumns.value) {
    cols.value = categoryColumns.value;
  }
};

const wrapperHeight = computed(() => {
  return (categories.value && ITEM_HEIGHT * Math.ceil(categories.value.length / cols.value)) || 0;
});

const parent = computed(() => {
  return faqStore.parent;
});

const navItemsBeforeEnter = (el: HTMLElement) => {
  gsap.set(el, {
    x: 200,
    opacity: 0,
  });
};

const navItemsEnter = (el: HTMLElement, done: () => void) => {
  const index: number = parseFloat(el.dataset.index as string);
  const delay: number = index * DELAY_FACTOR;

  gsap.to(el, {
    x: 0,
    opacity: 1,
    delay,
    duration: ANIMATION_DURATION,
    onComplete: done,
    ease: ANIMATION_EASING,
  });
};

const navItemsLeave = (el: HTMLElement, done: () => void) => {
  const index: number = parseFloat(el.dataset.index as string);
  const delay: number = index * DELAY_FACTOR;

  gsap.to(el, {
    x: -200,
    opacity: 0,
    delay,
    duration: ANIMATION_DURATION,
    onComplete: done,
    ease: ANIMATION_EASING,
  });
};

const navItemsLeaveActive = (el: HTMLElement) => {
  const width: number = el.offsetWidth;
  const top: number = el.offsetTop;
  const left: number = el.offsetLeft;
  setTimeout(() => {
    el.style.position = 'absolute';
    el.style.top = top + 'px';
    el.style.left = left + 'px';
    el.style.width = width + 'px';
  });
};

onMounted(() => {
  setCols();
  ResizeEventBus.$on('resize', setCols);
});

onBeforeUnmount(() => {
  ResizeEventBus.$off('resize', setCols);
});
</script>
