const storage = localStorage;

export const useLocalStorageSetItem = (key: string, value: any, expiryInSeconds: number): void => {
  try {
    const now = new Date().getTime();
    const item = {
      value: value,
      expiry: now + expiryInSeconds * 1000, // Convert seconds to milliseconds
    };
    storage.setItem(key, JSON.stringify(item));
  } catch (error) {
    console.error('Error storing data in localStorage: ', error);
  }
};

export const useLocalStorageRemoveItem = (key: string) => {
  try {
    storage.removeItem(key);
  } catch (error) {
    console.error('Error removing data from localStorage: ', error);
  }
};

export const useLocalStorageGetItem = <T>(key: string): T | null => {
  try {
    const itemStr = storage.getItem(key);
    if (itemStr === null) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date().getTime();

    if (item.expiry && item.expiry < now) {
      // Item has expired, remove it and return null
      useLocalStorageRemoveItem(key);
      return null;
    }

    return item.value as T;
  } catch (error) {
    console.error('Error retrieving data from localStorage:', error);
    return null;
  }
};
