<template>
  <div class="sticky-footer">
    <div class="sticky-footer-inner">
      <div v-if="$slots.left" class="left">
        <slot name="left" />
      </div>
      <div v-if="$slots.right" class="right" :class="{ 'single-item': !$slots.left }">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./sticky-footer.scss" scoped></style>
<style scoped lang="scss">
:slotted(.sticky-footer) {
  background-color: red;
  width: 100%;
  height: 100%;
  min-height: 4em;
}
</style>

<script lang="ts" setup></script>
