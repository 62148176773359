<template>
  <div class="input-group-container">
    <div class="input-container">
      <Checkbox
        :model-value="true"
        :disabled="true"
        :id="'buyer'"
        v-ui-test="'create-user-buyer-field'"
      >
        {{ textK('UI_COMMON_BUYER') }}
      </Checkbox>
    </div>
    <div class="input-container">
      <Checkbox
        v-model="user.IsAdmin"
        :id="'b2badmingroup'"
        v-ui-test="'create-user-admin-field'"
        :disabled="isBusy || (user && user.Uid === currentUser.Uid)"
      >
        {{ textK('UI_COMMON_ADMIN') }}
      </Checkbox>
      <Tooltip>
        <svgicon class="icon" name="ui-info"></svgicon>
        <template #tooltip-content>
          <span v-ui-test="'tooltip-admin'">{{ textK('UI_ADMINPAGE_ADMIN_TOOLTIP') }}</span>
        </template>
      </Tooltip>
    </div>
    <div class="input-container">
      <Checkbox
        :disabled="isBusy"
        v-model="user.IsReadOnly"
        :id="'readonlyUser'"
        v-ui-test="'create-user-readonly-field'"
      >
        {{ textK('UI_ADMINPAGE_READONLYUSER') }}
      </Checkbox>
      <Tooltip>
        <svgicon class="icon" name="ui-info"></svgicon>
        <template #tooltip-content>
          <span v-ui-test="'tooltip-readonly'">{{
            textK('UI_ADMINPAGE_READONLYUSER_TOOLTIP')
          }}</span>
        </template>
      </Tooltip>
    </div>
    <div class="input-container" v-if="isFinancePublic">
      <Checkbox
        :disabled="isBusy"
        v-model="user.IsFinance"
        :id="'financeUser'"
        v-ui-test="'create-user-finance-field'"
      >
        {{ textK('UI_ADMINPAGE_FINANCEUSER') }}
      </Checkbox>
      <Tooltip>
        <svgicon class="icon" name="ui-info"></svgicon>
        <template #tooltip-content>
          <span v-ui-test="'tooltip-readonly'">{{
            textK('UI_ADMINPAGE_FINANCEUSER_TOOLTIP')
          }}</span>
        </template>
      </Tooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, toNative } from 'vue-facing-decorator';
import Checkbox from '@/src/core/components/ui/form/checkbox/checkbox.vue';
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import useText from '@/src/core/hooks/useText';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useRouteConfigStore } from '@/src/core/stores/route-config';
import { User } from '@/src/core/types/api';
import { useUserStore } from '@/src/profile/stores/user';

@Component({
  components: {
    Checkbox,
    Tooltip,
  },
})
export class UserRoles extends Vue {
  public routeConfigStore = useRouteConfigStore();
  public textK = useText();
  public authenticationStore = useAuthenticationStore();
  public userStore = useUserStore();

  @Prop() public user: User;
  @Prop() public isBusy: boolean;

  // Current user (is Admin)
  public get currentUser() {
    return this.userStore.currentUser;
  }

  public get isFinancePublic() {
    return (
      (!this.routeConfigStore.getMenuConfigBeta('finance') ||
        (this.routeConfigStore.getMenuConfigBeta('finance') &&
          this.authenticationStore.betaCustomer)) &&
      hasFeature(FEATURES.FINANCE_SECTION)
    );
  }
}

export default toNative(UserRoles);
</script>
