/**
 * Extracts the origin (including protocol and port) from a given URL.
 *
 * @param url - The URL from which to extract the origin.
 * @returns The origin part of the URL.
 * @throws {Error} If the URL is empty or invalid.
 */
export const extractURI = (url: string) => {
  if (!url) {
    throw new Error('failed to generate URI');
  }

  const { origin } = new URL(url);

  return origin;
};

/**
 * Extracts the domain from a given hostname.
 *
 * @param hostname - The full hostname from which to extract the domain.
 * @returns The domain part of the hostname.
 */
export const getDomainByHostname = (hostname: string): string => {
  // Split the hostname into its parts using '.' as the delimiter
  const parts = hostname.split('.');

  // Remove parts from the beginning until only two parts remain
  while (parts.length > 2) {
    parts.shift();
  }

  // Join the remaining parts to form the domain
  const domain = parts.join('.');

  return domain;
};
