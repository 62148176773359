<template>
  <div v-ui-test="'select-account-step'">
    <div class="login__back">
      <a class="login__back__text" @click="goBack">
        <ArrowIcon class="login__back__icon" direction="left" />{{ textK('UI_COMMON_BACK_BUTTON') }}
      </a>
    </div>
    <div class="login__header">
      <h3>{{ textK('UI_LOGIN_SELECT_ACCOUNT_TITLE') }}</h3>
      <p class="select-account-step__description">
        {{ textK('UI_LOGIN_SELECT_ACCOUNT_DESCRIPTION') }}
      </p>
    </div>
    <div class="select-account-step__buttons">
      <button class="select-account-step__button" @click="handleRedirectToABW">
        <div class="select-account-step__button--logo">
          <AirbusLogo />
          <p class="login__paragraph">
            {{ textK('UI_LOGIN_SELECT_ACCOUNT_AIRBUS') }}
          </p>
        </div>
        <ArrowIcon class="select-account-step__button--icon" direction="right" />
      </button>
      <button class="select-account-step__button" @click="handleNextStep">
        <div class="select-account-step__button--logo">
          <SatairLogo />
          <p class="login__paragraph">
            {{ textK('UI_LOGIN_SELECT_ACCOUNT_SATAIR') }}
          </p>
        </div>
        <ArrowIcon class="select-account-step__button--icon" direction="right" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import ArrowIcon from '@/src/core/components/ui/ani-icons/arrow/arrow-icon.vue';
import AirbusLogo from '@/src/core/components/ui/detailed-icons/airbus-logo/airbus-logo.vue';
import SatairLogo from '@/src/core/components/ui/satair-logo/satair-logo.vue';
import useText from '@/src/core/hooks/useText';
import { LOGIN_STEPS } from '@/src/core/stores/login';
import { redirectToSSO } from '@/src/oidc/api/oidcApi';

const textK = useText();
const emit = defineEmits(['go-back', 'next-step']);

const goBack = () => {
  emit('go-back', LOGIN_STEPS.SELECT_ACCOUNT);
};

const handleNextStep = () => {
  emit('next-step');
};

const handleRedirectToABW = () => {
  redirectToSSO();
};
</script>

<style lang="scss" src="./select-account-step.scss" scoped />
