import { CertificateType, OfferSearch } from '@/src/core/apim';
import { BaseOwningSystem, Seller } from '@/src/core/types/interfaces';
import { addCommerceCDNtoUrl, calculateMaxInt } from '@/src/market/services/legacy-mapping';
import { getFriendlyName } from '@/src/market/services/legacy-mapping-product';

export const getProductSearchItemWithMapping = (product: OfferSearch) => {
  const code = product.id ?? product.code;
  const offerUrl = getOfferUrl(product);
  const marketOfferUrl = `/market/product${getOfferUrl(product)}`;
  const productManufacturer = product.manufacturerData;
  const sku = `${product.manufacturerAid}:${product.manufacturerData?.cageCode}`;
  const certificate = getCertificateType(product.certificateType);
  const certificates = getCertificates(product);
  const isAirbusHazmat = product.isAirbusHazmat || false;
  const hazmatCode = product.hazmat != null ? 'UN' + getFriendlyName(product.hazmat.code) : 'N/A';
  const hazmatDescription = product.hazmat != null ? getFriendlyName(product.hazmat.name) : 'N/A';
  const hasHazmat = product.hazmat?.name || false;
  const hasCertificate = Boolean(product.certificateType);
  const isMarketplace = product.owningSystem === 'MIRAKL';
  const supplierDisplay = getSupplierDisplay(product);

  const zTelCageCode = 'C1572';
  const isSatairOrAirbusMasterShop =
    product.owningSystem?.toLowerCase() === BaseOwningSystem.Blue ||
    product.owningSystem?.toLowerCase() === BaseOwningSystem.Red;

  const isZtel = product.manufacturerData?.cageCode === zTelCageCode && isSatairOrAirbusMasterShop;
  const supplierLogoUrl = product.masterShop?.searchResultLogo
    ? product.masterShop.searchResultLogo
    : '';
  const supplierLogoCdnUrl = product.masterShop?.searchResultLogo
    ? addCommerceCDNtoUrl(product.masterShop?.searchResultLogo)
    : '';
  const showSupplierLogo = Boolean(product.masterShop?.searchResultLogo);
  const newSupplierLogoUrl = product.masterShop ? product.masterShop.newSearchResultLogo : '';
  const newSupplierLogoCdnUrl = product.masterShop?.newSearchResultLogo
    ? addCommerceCDNtoUrl(product.masterShop?.newSearchResultLogo)
    : '';
  const showNewSupplierLogo = Boolean(product.masterShop?.newSearchResultLogo);
  const flammable = product.flammable || false;
  const enginePart = product.enginePart || false;
  const isTool = product.isTool || false;
  const serialNumberRequired = product.serialNumberRequired || false;
  const hasFHS = product.hasFHS || false;

  const maxInt = Math.max(
    product.minOrderQuantity ?? 0,
    product.minimumOrderQuantity ?? 0,
    product.quantityStep ?? 0,
    product.roundingValue ?? 0,
    product.packageQuantity ?? 0,
  );

  const packageQuantity =
    product.quantityStep &&
    product.quantityStep > 1 &&
    product.quantityStep > (product.packageQuantity ?? 0)
      ? product.quantityStep
      : product.packageQuantity && product.packageQuantity > 1
        ? product.packageQuantity
        : 1;

  const adjustedMaxInt =
    maxInt % packageQuantity === 0 ? maxInt : calculateMaxInt(maxInt, packageQuantity);

  const addToBasketQty = packageQuantity < adjustedMaxInt ? adjustedMaxInt : packageQuantity;

  const cartStep =
    product.roundingValue && product.roundingValue > packageQuantity
      ? product.roundingValue
      : packageQuantity;

  const images = [];
  const hasImage = false;
  const roundingValue = 0;
  const interchangeable = false;

  return {
    ...product,
    code,
    offerUrl,
    marketOfferUrl,
    productManufacturer,
    sku,
    certificate,
    certificates,
    isAirbusHazmat,
    hazmatCode,
    hazmatDescription,
    hasHazmat,
    hasCertificate,
    isMarketplace,
    supplierDisplay,
    isZtel,
    supplierLogoUrl,
    supplierLogoCdnUrl,
    showSupplierLogo,
    newSupplierLogoUrl,
    newSupplierLogoCdnUrl,
    showNewSupplierLogo,
    flammable,
    enginePart,
    isTool,
    serialNumberRequired,
    hasFHS,
    cartStep,
    packageQuantity,
    addToBasketQty,

    // properties below are static and should probably be removed
    images,
    hasImage,
    roundingValue,
    interchangeable,
  };
};

const getOfferUrl = (product: OfferSearch) => {
  return `/${product.manufacturerData?.cageCode}/${product.manufacturerAid}/${
    product.owningSystem?.toLowerCase() === BaseOwningSystem.Mirakl
      ? product.id
      : product.owningSystem != null && product.owningSystem.toLowerCase() === BaseOwningSystem.Blue
        ? Seller.Airbus
        : Seller.Satair
  }`.toLowerCase();
};

const getCertificateType = (certificate?: string) => {
  if (!certificate || certificate.trim() === '') {
    return 'N/A';
  }
  return getFriendlyName(certificate);
};

const getSupplierDisplay = (product: OfferSearch) => {
  if (product.owningSystem?.toLowerCase() === BaseOwningSystem.Blue) {
    return Seller.Airbus;
  } else if (product.owningSystem?.toLowerCase() === BaseOwningSystem.Red) {
    return Seller.Satair;
  } else if (product.masterShop) {
    return product.masterShop.name;
  } else {
    return Seller.Satair;
  }
};

const getCertificates = (product: OfferSearch) => {
  return product.certificates != null
    ? populateCertificates(
        product.certificates,
        product.certificateType ? getFriendlyName(product.certificateType) : 'N/A',
      )
    : null;
};

const populateCertificates = (certificates: CertificateType[], certificate: string): string[] => {
  const list: string[] = [];

  if (certificates.length > 0) {
    certificates.forEach((item) => {
      if (item.name) {
        list.push(item.name);
      }
    });
  } else {
    list.push(certificate);
  }

  return list;
};
