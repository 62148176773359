<template>
  <div class="content-block">
    <template v-if="animation">
      <TransitionExpand mode="out-in">
        <DotLoader class="loader" v-if="isLoading || isLinkStoreBusy" :loading="true"></DotLoader>
        <div v-else class="content-block typeset">
          <Component :is="component"></Component>
        </div>
      </TransitionExpand>
    </template>
    <template v-else>
      <div class="content-block typeset">
        <Component :is="component"></Component>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped src="./content-block.scss"></style>

<script lang="ts" setup>
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import { useContentBlocksStore } from '@/src/core/stores/content-blocks';
import { useLinksStore } from '@/src/core/stores/links';
import { compile, type Component, computed, watch } from 'vue';

interface Props {
  linkKey?: string;
  url?: string;
  modal?: boolean;
  force?: boolean;
  animation?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  linkKey: '',
  url: '',
  modal: false,
  force: false,
  animation: true,
});

const contentBlocksStore = useContentBlocksStore();
const linksStore = useLinksStore();

const getUrl = computed(() => {
  let newUrl: string | undefined;

  if (props.url.length > 0) {
    newUrl = props.url;
  } else {
    newUrl = linksStore.getKey(props.linkKey as any) || undefined;
  }
  return newUrl;
});

const content = computed(() => {
  if (props.modal) {
    return contentBlocksStore.contentByUrl(getUrl.value).replace(/router-link/gi, 'modal-link');
  } else {
    return contentBlocksStore.contentByUrl(getUrl.value);
  }
});

const isLoading = computed(() => {
  return contentBlocksStore.isBlockBusy(getUrl.value);
});

const isLinkStoreBusy = computed(() => {
  return linksStore.isBusy;
});

const fetchContent = (force?: boolean) => {
  if (getUrl.value) {
    contentBlocksStore.fetchUmbracoContentBlock({ url: getUrl.value, force });
  }
};

const component = computed(
  () => (content.value ? { render: compile(content.value) } : null) as Component,
);

watch(
  () => getUrl.value,
  () => {
    fetchContent(props.force);
  },
  { immediate: true },
);
</script>
