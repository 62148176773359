<template>
  <div class="native-banner typeset">
    <h3>{{ textK('UI_SIGNUP_REQUEST_ACCESS_BANNER_TITLE') }}</h3>
    <p>{{ textK('UI_SIGNUP_REQUEST_ACCESS_BANNER_DESCRIPTION') }}</p>
    <VButton :type="uiVariant.Secondary">{{
      textK('UI_SIGNUP_REQUEST_ACCESS_BANNER_CTA')
    }}</VButton>
  </div>
</template>

<style lang="scss" src="./request-account-native-banner.scss" scoped></style>

<script lang="ts" setup>
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';

const textK = useText();
const uiVariant = UiVariant;
</script>
