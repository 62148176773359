<template>
  <VSimpleTable
    class="product-details-table"
    :headers="['Documentation files', '']"
    v-if="isAuthenticated && attachments.length > 0 && !isRestricted"
  >
    <tbody>
      <VSimpleTableRow
        v-for="attachment in attachments"
        :key="attachment.name"
        v-ui-test="'product-documentation-files'"
      >
        <VSimpleTableCell :style="{ width: '75%' }">
          {{ attachment.name }}
        </VSimpleTableCell>
        <VSimpleTableCell class="align-right">
          <DotLoader v-if="isDownloading && clickDocument === attachment.name" :loading="true" />
          <button
            class="download-link"
            v-else
            :disabled="isDownloading"
            type="button"
            @click="downloadDocument(attachment)"
          >
            Download
          </button>
        </VSimpleTableCell>
      </VSimpleTableRow>
    </tbody>
  </VSimpleTable>
</template>

<style scoped src="./product-details-attachments.scss" lang="scss"></style>

<script lang="ts" setup>
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import VSimpleTable from '@/src/core/components/ui/simple-table/simple-table.vue';
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import { TextKey } from '@/src/core/services/text-key';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useNotificationsStore } from '@/src/core/stores/notifications';
import { NotificationTypes, OwningSystem } from '@/src/core/types/api';
import { AttachmentLink } from '@/src/core/types/interfaces';
import { isBlueOwningSystem } from '@/src/market/services/product-rules';
import { computed, ref } from 'vue';

interface Props {
  id: string;
  documentLinks?: string[];
  documentNames?: string[];
  owningSystem: OwningSystem;
}

const props = defineProps<Props>();

const authenticationStore = useAuthenticationStore();
const notificationsStore = useNotificationsStore();
const isDownloading = ref(false);
const clickDocument = ref('');

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);

const attachments = computed(() => {
  if (props.documentNames) {
    const links = props.documentLinks || [];
    const names = props.documentNames;

    return names.map((name, index) => ({ name, link: links[index] }));
  }
  return [];
});

const isRestricted = computed(() => isBlueOwningSystem(props.owningSystem));

const downloadDocument = async (attachment: AttachmentLink) => {
  isDownloading.value = true;
  clickDocument.value = attachment.name;

  if (attachment.link) {
    notificationsStore.addNotification({
      notificationId: `pendingDownload${attachment.name}`,
      notificationItem: {
        Title: TextKey('UI_COMMON_PENDING_DOWNLOAD_TITLE'),
        Description: TextKey('UI_COMMON_PENDING_DOWNLOAD_DESCRIPTION'),
        Type: NotificationTypes.Information,
        Timing: 1000,
      },
    });
  }

  try {
    const response = await fetch(attachment.link);
    const blob = await response.blob();

    const blobURL = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobURL;
    a.download = attachment.name || '';
    a.click();
  } catch (error) {
    notificationsStore.addNotification({
      notificationId: `errorDownload${attachment.name}`,
      notificationItem: {
        Title: 'Failed to download document',
        Description: 'Document download failed',
        Type: NotificationTypes.Error,
        Timing: 1000,
      },
    });
  } finally {
    isDownloading.value = false;
    clickDocument.value = '';
  }
};
</script>
