<template>
  <li
    class="dropdown-option"
    ref="item"
    v-ui-test="uiTestName"
    @mouseenter="hoverActive = true"
    @mouseleave="hoverActive = false"
    :disabled="disabled"
    @click="!disabled ? clickHandler($event) : null"
    :class="{
      'dropdown-option--selected': selected,
      'dropdown-option--active': (active || (!active && hoverActive)) && !disabled,
      'dropdown-option--disabled': disabled,
      'shop-site': context === uiContext.ShopSite,
      'content-site': context === uiContext.ContentSite,
    }"
  >
    <slot />
  </li>
</template>

<style scoped src="./dropdown-option.scss" lang="scss"></style>

<script lang="ts" setup>
import { UiContext } from '@/src/core/components/ui/ui.types';
import { useUIStore } from '@/src/core/stores/ui';
import { ref } from 'vue';

interface Props {
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
  context?: UiContext;
  uiTestName?: string;
}

withDefaults(defineProps<Props>(), {
  active: false,
  selected: false,
  disabled: false,
  context: UiContext.ShopSite,
  uiTestName: 'dropdown-option',
});

const uiStore = useUIStore();
const uiContext = UiContext;
const hoverActive = ref(false);
const emit = defineEmits(['click']);

const clickHandler = ($event: Event) => {
  emit('click', $event);
  uiStore.setDropdownOpen({ id: '' }); // Close the dropdown when clicked;
};
</script>
