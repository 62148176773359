import { ProcurementType } from '@/src/core/settings/procurement-types';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { OwningSystem, Product } from '@/src/core/types/api';
import { AirbusPart, IProductAdditionalData, Procurement } from '@/src/core/types/interfaces';
import { isAirbus, isSatair } from '@/src/market/services/product-parts';

export const hasNoPrice = (
  productData?: IProductAdditionalData | AirbusPart | null,
  procurement?: Procurement | null,
) => {
  if (isSatair(productData)) {
    return productData.RuleStatus === 'NOPRICE';
  }

  if (isAirbus(productData)) {
    const productRules = procurement?.productRule || productData.productRule;
    return productRules.priceVisible !== 'YES';
  }

  return false;
};

export const isPriceVisible = (
  productData?: IProductAdditionalData | AirbusPart | null,
  procurement?: Procurement | null,
) => {
  if (isSatair(productData)) {
    return productData.RuleStatus === 'OK';
  }

  if (isAirbus(productData)) {
    const productRules = procurement?.productRule || productData.productRule;
    return productRules.priceVisible === 'YES';
  }

  return false;
};

export const isSellable = (
  productData?: IProductAdditionalData | AirbusPart | null,
  procurement?: Procurement | null,
) => {
  if (isSatair(productData)) {
    return productData.RuleStatus === 'OK';
  }

  if (isAirbus(productData)) {
    const productRules = procurement?.productRule || productData.productRule;
    return productRules.sellable === 'YES';
  }

  return false;
};

export const isQuotable = (
  productData?: IProductAdditionalData | AirbusPart | null,
  procurement?: Procurement | null,
) => {
  if (isSatair(productData)) {
    return productData.Quotable;
  }

  if (isAirbus(productData)) {
    const productRules = procurement?.productRule || productData.productRule;
    return productRules.quotable === 'YES';
  }

  return false;
};

export const limitedByCrossPlantMaterialStatus = (
  productData?: IProductAdditionalData | AirbusPart | null,
  procurement?: Procurement | null,
) => {
  if (isSatair(productData)) {
    return productData.RuleStatus === 'CPMS';
  }

  if (isAirbus(productData)) {
    const productRules = procurement?.productRule || productData.productRule;
    return productRules.sellable === 'LIMITED_BY_AIRBUS_CROSS_PLANT_MATERIAL_STATUS';
  }

  return false;
};

export const crossPlantMaterialOutOfStock = (
  productData?: IProductAdditionalData | AirbusPart | null,
  procurement?: Procurement | null,
) => {
  if (isSatair(productData)) {
    return productData.RuleStatus === 'CPMS30';
  }

  if (isAirbus(productData)) {
    const productRules = procurement?.productRule || productData.productRule;
    return productRules.sellable === 'CROSS_PLANT_MATERIAL_STATUS_OUT_OF_STOCK';
  }

  return false;
};

export const isRestricted = (
  productData?: IProductAdditionalData | AirbusPart | null,
  procurement?: Procurement | null,
) => {
  if (isSatair(productData)) {
    return productData.RuleStatus === 'Restricted';
  }

  if (isAirbus(productData)) {
    const productRules = procurement?.productRule || productData.productRule;
    return productRules.sellable === 'RESTRICTED';
  }

  return false;
};

export const isAuthenticated = () => {
  const authenticationStore = useAuthenticationStore();
  return authenticationStore.isAuthenticated;
};

export const getAirbusHazmatUNCode = (product?: IProductAdditionalData | AirbusPart | null) => {
  if (isAirbus(product)) {
    return product?.productData?.hazmatStandardCode ?? '';
  }

  return '';
};

export const airbusProductNotQuotableOrSellable = (
  productData?: IProductAdditionalData | AirbusPart | null,
  procurement?: Procurement | null,
) => {
  if (isAirbus(productData)) {
    const productRules = procurement?.productRule || productData.productRule;
    return productRules.sellable === 'AIRBUS_PRODUCT_NOT_SELLABLE_OR_QUOTEABLE';
  }

  return false;
};

export const isBlueOwningSystem = (owningSystem: OwningSystem) =>
  owningSystem === OwningSystem.BLUE;

export const materialStatus = (
  status: string,
  product?: IProductAdditionalData | AirbusPart | null,
) => {
  if (isAirbus(product)) {
    const { productData } = product;
    if (!productData?.airbusCrossPlantMaterialStatus) {
      return;
    }

    return productData?.airbusCrossPlantMaterialStatus?.code === status;
  }

  return false;
};

export const useProductCertificate = (product: Product, procurement?: Procurement | null) => {
  if (!product) {
    return;
  }
  const isAirbus = product.OwningSystem === OwningSystem.BLUE;
  if (isAirbus) {
    const { AirbusCertificateForSalesNew } = product;

    if (AirbusCertificateForSalesNew) {
      return AirbusCertificateForSalesNew?.FormattedName;
    }

    if (
      procurement &&
      procurement.procurementType === ProcurementType.Used &&
      procurement.certificateType
    ) {
      return procurement.certificateType;
    }
  }

  return product.Certificate;
};

/**
 * Check if the airbus product procurement is qutable and can request repair
 * @param procurement Procurement
 * @param enablePropPartServiceQuotation - feature flag
 * @returns boolean
 */
export const isAirbusPartRepairQuotable = (
  procurement: Procurement,
  enablePropPartServiceQuotation: boolean,
) => {
  return (
    enablePropPartServiceQuotation &&
    procurement.procurementType === ProcurementType.Repair &&
    procurement.productRule.quotable === 'YES'
  );
};

/**
 * Check if the product is ztel and not an Airbus
 *
 * @param product Product
 * @returns boolean
 */
export const isProductZtel = (product: Product | undefined | null) => {
  return product && product.IsZtel && product.OwningSystem !== 'BLUE';
};
