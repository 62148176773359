import { UmbracoApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { IReleaseNotes } from '@/src/core/types/interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useReleaseNotesStore = defineStore('release-notes', () => {
  const releaseNotes = ref<IReleaseNotes>({
    BlockTitle: '',
    BlockContent: [],
    Url: '',
    IsLillyStyling: false,
  });

  const reset = () => {
    releaseNotes.value = {
      BlockTitle: '',
      BlockContent: [],
      Url: '',
      IsLillyStyling: false,
    };
  };

  const releaseNotesUpdated = (payload: IReleaseNotes) => {
    releaseNotes.value = Object.assign({}, payload);
  };

  const fetchReleaseNotes = async () => {
    const { IsSuccess, Data } = await Req({ url: UmbracoApi.getReleaseNotes });

    if (IsSuccess) {
      releaseNotesUpdated(Data);
    }
  };

  return {
    releaseNotes,
    fetchReleaseNotes,
    reset,
  };
});
