<template>
  <div class="seller-conversation-messaging">
    <VTextarea
      ref="textarea"
      class="seller-conversation-messaging__input"
      :vmax-length="5000"
      :compressed-view="true"
      :class="{ 'has-message': newMessage.length > 0 }"
      :label="textK('UI_SELLER_CONVERSATION_NEW_MESSAGE_LABEL')"
      :placeholder="textK('UI_SELLER_CONVERSATION_NEW_MESSAGE_PLACEHOLDER')"
      v-model="newMessage"
      :type="uiVariant.Secondary"
    >
      <template #append>
        <MQ :query="{ minWidth: breakpointWidth.Rabbit }">
          <Tooltip position="top">
            <label class="seller-conversation-messaging__upload">
              <input
                ref="fileInput"
                type="file"
                :accept="acceptedFiles"
                multiple="multiple"
                v-ui-test="'seller-conversation-upload'"
                @change="uploadFile($event.target.files)"
              />
              <input
                ref="fileInput"
                type="file"
                :accept="acceptedFiles"
                multiple="multiple"
                v-ui-test="'seller-conversation-upload'"
                @change="uploadFile($event.target.files)"
              />
              <svgicon class="seller-conversation-messaging__upload__icon" name="ui-upload-file" />
            </label>
            <template #tooltip-content>{{
              textK('UI_SELLER_CONVERSATION_NEW_MESSAGE_ATTACH_FILE')
            }}</template>
          </Tooltip>
        </MQ>
      </template>
    </VTextarea>
    <div class="seller-conversation-messaging__file-and-action">
      <div class="seller-conversation-messaging__chosen-files" v-if="fileInfoList">
        <span v-for="(file, i) in fileInfoList" :key="i">
          <p class="filename">{{ file.name }}</p>
          <Tooltip position="top">
            <svgicon name="ui-delete" @click.stop="removeFile(i)" />
            <template #tooltip-content>
              {{ textK('UI_SELLER_CONVERSATION_NEW_MESSAGE_REMOVE_ATTACHMENT') }}
            </template>
          </Tooltip>
        </span>
      </div>
      <VButton
        class="seller-conversation-messaging__action"
        :disabled="newMessage.length === 0"
        v-ui-test="'seller-conversation-send'"
        @click="quoteCode ? postQuoteMessage() : postMessage()"
        :disable="newMessage.length <= 0"
      >
        {{ textK('UI_COMMON_SEND') }}
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" src="./seller-conversation-messaging.scss" scoped />

<script lang="ts">
import { Vue, Component, Prop, Ref, toNative } from 'vue-facing-decorator';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { TextKey } from '@/src/core/services/text-key';
import { BreakpointWidth } from '@/src/core/settings/media-queries.model';
import { useNotificationsStore } from '@/src/core/stores/notifications';
import {
  MarketplaceMessageReq,
  MarketplaceMessagingConsignemtThreads,
  NotificationTypes,
} from '@/src/core/types/api';
import { useOrderDetailsStore } from '@/src/market/stores/order-details';
import { useProductStore } from '@/src/market/stores/product';
import { useQuotesStore } from '@/src/market/stores/quotes';
import { useSellerConversationStore } from '@/src/market/stores/seller-conversation';
import { useSellerQuoteConversationStore } from '@/src/market/stores/seller-quote-conversation';
import Media from '@/src/core/components/util/media-query/media-query.vue';

@Component({
  components: {
    MQ: Media,
    VButton,
    VTextarea,
    Tooltip,
  },
})
export class SellerConversationMessaging extends Vue {
  @Prop() public id: string;
  @Prop() private thread: MarketplaceMessagingConsignemtThreads;
  @Prop() private miraklQuoteRequestId: string;

  public textK = useText();
  public productStore = useProductStore();
  public notificationsStore = useNotificationsStore();
  public quotesStore = useQuotesStore();
  public orderDetailsStore = useOrderDetailsStore();

  public breakpointWidth = BreakpointWidth;
  public uiVariant = UiVariant;
  public newMessage: string = '';
  public acceptedFiles: string = '.png, .jpg, .jpeg, .xlsx, .xls, .pdf, .doc, .docx, .csv';
  public fileInfoList: File[] = [];
  public sellerConversationStore = useSellerConversationStore();
  public sellerQuoteConversationStore = useSellerQuoteConversationStore();

  @Ref() readonly textarea!: InstanceType<typeof VTextarea>;
  @Ref() readonly fileInput: HTMLInputElement;

  private get product() {
    return this.productStore.productById(this.id);
  }

  private get orderCode() {
    return this.thread && this.orderDetailsStore.order.Code;
  }

  public get quoteCode() {
    return this.miraklQuoteRequestId && this.quotesStore.currentQuoteView.Code;
  }

  private get threadId() {
    return this.sellerConversationStore.threadId;
  }

  public setFocus() {
    (this.$refs.textarea as any).setFocus();
  }

  public async postMessage() {
    if (this.newMessage.length > 0) {
      const message = this.newMessage.length < 3 ? ' ' + this.newMessage + '.' : this.newMessage;
      const files = Array.from(this.fileInfoList);

      const postData: MarketplaceMessageReq = {
        ThreadId: this.threadId || '',
        Subject: this.product?.Code || this.orderCode,
        Body: message,
        Attachments: [],
      };
      const fileData = new FormData();

      for (const file of files) {
        fileData.append(file.name, file);
      }
      fileData.append('Subject', postData.Subject);
      fileData.append('Body', postData.Body);
      fileData.append('ThreadId', postData.ThreadId);

      this.sellerConversationStore
        .postMessage({
          id: this.id,
          orderCode: this.orderCode,
          data: fileData,
        })
        .then(({ IsSuccess }) => {
          if (IsSuccess) {
            this.newMessage = '';
            this.resetFileInfoList();
          }
        });
    }
  }

  public async postQuoteMessage() {
    if (this.newMessage.length > 0) {
      const message = this.newMessage.length < 3 ? ' ' + this.newMessage + '.' : this.newMessage;
      const files = Array.from(this.fileInfoList);

      const postData: MarketplaceMessageReq = {
        ThreadId: this.threadId || '',
        Subject: this.product?.Code || this.quoteCode,
        Body: message,
        Attachments: [],
      };
      const fileData = new FormData();

      for (const file of files) {
        fileData.append(file.name, file);
      }
      fileData.append('Subject', postData.Subject);
      fileData.append('Body', postData.Body);
      fileData.append('ThreadId', postData.ThreadId);

      this.sellerQuoteConversationStore
        .postMessage({
          miraklQuoteRequestId: this.miraklQuoteRequestId,
          quoteCode: this.quoteCode,
          data: fileData,
        })
        .then(({ IsSuccess }) => {
          if (IsSuccess) {
            this.newMessage = '';
            this.resetFileInfoList();
            this.quotesStore.setCheckoutQuoteMessages({
              Messages: this.sellerQuoteConversationStore.messages,
            });
          }
        });
    }
  }

  public async uploadFile(files: FileList) {
    Array.from(files).forEach((file) => {
      if (!this.fileTypeValid(file)) {
        this.notificationsStore.addNotification({
          notificationItem: {
            Title: TextKey('UI_SELLER_CONVERSATION_UPLOAD_WRONG_FILETYPE_TITLE') + ': ' + file.name,
            Description:
              TextKey('UI_SELLER_CONVERSATION_UPLOAD_WRONG_FILETYPE_TEXT') +
              ': ' +
              this.acceptedFiles,
            Type: NotificationTypes.Error,
            Timing: 10000,
          },
        });
      } else if (file.size > 10000000) {
        this.notificationsStore.addNotification({
          notificationItem: {
            Title: TextKey('UI_SELLER_CONVERSATION_UPLOAD_FAILED_TITLE') + ': ' + file.name,
            Description: TextKey('UI_SELLER_CONVERSATION_UPLOAD_FAILED_TEXT'),
            Type: NotificationTypes.Error,
            Timing: 10000,
          },
        });
      } else if (this.fileExists(file)) {
        this.notificationsStore.addNotification({
          notificationItem: {
            Title: TextKey('UI_SELLER_CONVERSATION_DUPLICATE_FILE_TITLE') + ': ' + file.name,
            Description: TextKey('UI_SELLER_CONVERSATION_DUPLICATE_FILE_TEXT'),
            Type: NotificationTypes.Error,
            Timing: 10000,
          },
        });
      } else {
        this.fileInfoList.push(file);
      }
      this.fileInput.value = '';
    });
  }

  private fileExists(file: File) {
    return this.fileInfoList.some((item) => item.name === file.name);
  }

  private fileTypeValid(file: File) {
    const name = file?.name?.split('.');
    const fileType: string = (name.length > 1 && name[name.length - 1]) || '';
    return this.acceptedFiles.split(', ').some((type) => type === '.' + fileType);
  }

  private resetFileInfoList() {
    this.fileInfoList = [];
  }

  public removeFile(index: number) {
    this.fileInfoList.splice(index, 1);
  }
}

export default toNative(SellerConversationMessaging);
</script>
