import { urlSafeBase64, urlSafeBase64Decode } from '@/src/core/utils/encode';

export const SSO_COOKIE_KEY_STATE = urlSafeBase64('state');
export const SSO_COOKIE_KEY_VERIFIER = urlSafeBase64('verifier');

export const generateState = (url: string) => {
  const csrf = crypto.randomUUID();

  return { url, csrf };
};

type State = ReturnType<typeof generateState>;

export const dehydrateState = (hydratedState: State) =>
  urlSafeBase64(JSON.stringify(hydratedState));

export const hydrateState = (dehydratedState: string) =>
  JSON.parse(urlSafeBase64Decode(dehydratedState)) as State;
