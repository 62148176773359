import { DirectiveBinding, ObjectDirective } from 'vue';

type ClickOutsideHandler = (event: MouseEvent) => void;

interface ClickOutsideElement extends HTMLElement {
  __clickOutsideHandler__?: ClickOutsideHandler;
}

const clickOutside: ObjectDirective<ClickOutsideElement, ClickOutsideHandler> = {
  mounted(el: ClickOutsideElement, binding: DirectiveBinding<ClickOutsideHandler>) {
    const clickOutsideHandler: ClickOutsideHandler = (event: MouseEvent) => {
      if (!(el === event.target || el.contains(event.target as Node)) && binding.value) {
        binding.value(event);
      }
    };

    el.__clickOutsideHandler__ = clickOutsideHandler;
    document.addEventListener('click', clickOutsideHandler);
  },

  unmounted(el: ClickOutsideElement) {
    if (el.__clickOutsideHandler__) {
      document.removeEventListener('click', el.__clickOutsideHandler__);
      delete el.__clickOutsideHandler__;
    }
  },
};

export default clickOutside;
