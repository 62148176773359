import { SellerLink } from '@/src/core/types/interfaces';

// A-Z first, then numbers and special characters last
const sortFn = (a: SellerLink, b: SellerLink) => {
  const aValue = a.title;
  const bValue = b.title;
  const startsWithNumberA = !isNaN(Number(aValue.charAt(0)));
  const startsWithNumberB = !isNaN(Number(bValue.charAt(0)));
  const startsWithSpecialA = /^\W/.test(aValue.charAt(0));
  const startsWithSpecialB = /^\W/.test(bValue.charAt(0));

  if (startsWithSpecialA) return 1;
  if (startsWithSpecialB) return -1;

  if (startsWithNumberA && !startsWithNumberB) return 1;
  if (!startsWithNumberA && startsWithNumberB) return -1;

  return aValue.localeCompare(bValue);
};

export const groupByAlphabeticalOrder = (list: SellerLink[]) => {
  const ranges = [
    { start: 'A', end: 'D' },
    { start: 'E', end: 'H' },
    { start: 'I', end: 'L' },
    { start: 'M', end: 'P' },
    { start: 'O', end: 'T' },
    { start: 'U', end: 'Z' },
    { start: '0', end: '9' },
    { start: '!', end: '/' },
  ];

  const specialCharacters = /^\W/;

  const sorted = list.sort(sortFn);
  const groupedMap = new Map();

  sorted.forEach((item) => {
    const firstLetter = item.title.charAt(0).toUpperCase();
    let group;

    for (const range of ranges) {
      if (specialCharacters.test(firstLetter)) {
        group = ranges[ranges.length - 1];
        break;
      }

      if (firstLetter >= range.start && firstLetter <= range.end) {
        group = range;
        break;
      }
    }

    if (group) {
      const key = `${group.start} - ${group.end}`;

      if (!groupedMap.has(key)) {
        groupedMap.set(key, []);
      }

      groupedMap.get(key).push(item);
    }
  });

  return groupedMap;
};
