<template>
  <div v-if="isAuthenticated && isAirbusValue && expirationDate">
    <p>{{ textK('UI_PRODUCTPAGE_PRICE_EXPIRATION_LABEL') }} {{ date(expirationDate) }}.</p>
  </div>
</template>

<script setup lang="ts">
import useText from '@/src/core/hooks/useText';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { ProductAirbusPart } from '@/src/core/types/interfaces';
import { DateFormat } from '@/src/core/utils/dates';
import { getProcurementByRouteParams } from '@/src/market/services/procurements';
import { isAirbus, productAdditionalData } from '@/src/market/services/product-parts';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

interface Props {
  id: string;
  owningSystem: string;
}

const props = defineProps<Props>();

const textK = useText();
const route = useRoute();
const authenticationStore = useAuthenticationStore();
const date = DateFormat;

const productData = computed(() => productAdditionalData(props.id, props.owningSystem));
const isAirbusValue = computed(() => isAirbus(productData.value));

const procurementData = computed(() => {
  if (productData.value && isAirbusValue.value) {
    const { params } = route;
    return getProcurementByRouteParams(
      params,
      (productData.value as ProductAirbusPart).procurements,
    );
  }
  return null;
});

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);
const expirationDate = computed(() => procurementData.value?.priceExpirationDate || null);
</script>
