<template>
  <label :for="uniqueId" class="label">
    <input
      :id="uniqueId"
      :name="name"
      type="radio"
      :checked="checked"
      @change="$emit('update:modelValue', modelValue)"
      :disabled="disabled"
    />
    <div class="radiobutton"></div>
    <span><slot /></span>
  </label>
</template>

<style lang="scss" scoped src="./radio-button.scss"></style>

<script lang="ts" setup>
import { GenerateUniqueIdentifier } from '@/src/core/plugins/uuid4';
import { computed } from 'vue';

interface Props {
  checked?: boolean;
  name?: string;
  modelValue?: string | boolean;
  disabled?: boolean;
}

withDefaults(defineProps<Props>(), {
  name: '',
  modelValue: '',
});

const uniqueId = computed(() => {
  return new GenerateUniqueIdentifier().uuid4();
});
</script>
