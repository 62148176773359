<template>
  <p v-ui-test="'pdp-terms-link'" v-if="isAuthenticated">
    {{ termsText }}
    <a class="terms-link" @click="showTermsAndConditions">{{ termsLinkText }}</a>
  </p>
</template>

<script lang="ts" setup>
import { TextKey } from '@/src/core/services/text-key';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useDownloadsStore } from '@/src/core/stores/downloads';
import { useModalStore } from '@/src/core/stores/modal';
import { OwningSystem } from '@/src/core/types/api';
import {
  AIRBUS_LEASE_TERMS,
  AIRBUS_NON_US_TERMS,
  AIRBUS_US_TERMS,
  GENERAL_TERMS,
} from '@/src/core/types/ui';
import { useUserStore } from '@/src/profile/stores/user';
import { computed } from 'vue';

type TermsType = 'general-terms' | 'lease-terms';

interface Props {
  owningSystem: OwningSystem;
  isDownloadable?: boolean;
  downloadLink?: string;
  label?: string;
  termsType?: TermsType;
}

const props = withDefaults(defineProps<Props>(), {
  isDownloadable: false,
  downloadLink: '',
  label: '',
  termsType: 'general-terms',
});

const authenticationStore = useAuthenticationStore();
const downloadsStore = useDownloadsStore();
const modalStore = useModalStore();
const userStore = useUserStore();

const accountGroup = computed(() => userStore.accountGroup);
const isAuthenticated = computed(() => authenticationStore.isAuthenticated);

const modalKey = computed(() => {
  if (props.termsType === 'lease-terms') {
    return AIRBUS_LEASE_TERMS;
  }

  if (props.owningSystem === OwningSystem.BLUE) {
    if (accountGroup.value === 'NON_US') {
      return AIRBUS_NON_US_TERMS;
    } else if (accountGroup.value === 'US') {
      return AIRBUS_US_TERMS;
    }
  }

  return GENERAL_TERMS;
});

const termsText = computed(() => {
  if (props.termsType === 'lease-terms') {
    return TextKey('UI_PRODUCTPAGE_AIRBUS_LEASE_TERMS_PREFIX_TEXT');
  }

  return TextKey('UI_PRODUCTPAGE_GENERALTERMSANDCONDITIONS');
});

const termsLinkText = computed(() => {
  if (props.isDownloadable) {
    return props.label || '';
  }

  if (props.termsType === 'lease-terms') {
    return TextKey('UI_PRODUCTPAGE_AIRBUS_LEASE_TERMS_LINK_TEXT');
  }

  return TextKey('UI_PRODUCTPAGE_TERMSLABEL');
});

const showTermsAndConditions = () => {
  if (props.isDownloadable) {
    downloadsStore.downloadFile({ url: props.downloadLink || '' });
  } else {
    modalStore.showModal({
      modalComponent: 'ModalLoadContent',
      params: { key: `${modalKey.value}_ModalUrl`, title: `${modalKey.value}_Name` },
    });
  }
};
</script>
