<template>
  <div class="assistant-icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 0 63 74">
      <path
        d="M58.5 26.4c-1.1-.7-13-8.4-28-8.4s-26.9 7.7-28 8.4C.4 27.4-1 29.7-1 32.1v22.1c0 2.4 1.4 4.7 3.6 5.7l25.2 12c.8.4 1.7.6 2.7.6s1.8-.2 2.7-.6l25.2-12c2.2-1 3.6-3.3 3.6-5.7V32.1c0-2.4-1.4-4.6-3.5-5.7zM60 54.2c0 1.7-1 3.2-2.5 3.9l-25.2 12c-1.1.5-2.4.5-3.6 0l-25.2-12C2 57.4 1 55.9 1 54.2V32.1c0-1.7 1-3.2 2.5-3.9l.1-.1C3.7 28 15.7 20 30.5 20c14.8 0 26.8 8 26.9 8.1l.1.1c1.5.7 2.5 2.2 2.5 3.9v22.1z"
        class="lilly-black"
      ></path>
      <transition name="fade" mode="out-in">
        <circle
          v-if="mode !== assistantMode.loading"
          cx="44.5"
          cy="43.5"
          r="4.5"
          class="lilly-black"
        ></circle>
      </transition>
      <circle cx="42.5" cy="9.5" r="2.5" class="lilly-white"></circle>
      <circle cx="31.5" cy="1.5" r="1.5" class="lilly-white"></circle>
    </svg>

    <DotLoader
      class="loading-animation"
      :loading="true"
      :to-color="'#000'"
      v-if="mode === assistantMode.loading"
    >
      <div></div>
    </DotLoader>
  </div>
</template>

<style lang="scss" src="./assistant-icon.scss" scoped></style>

<script lang="ts" setup>
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import { AssistantMode } from './assistant-mode';

interface Props {
  mode: AssistantMode;
}

withDefaults(defineProps<Props>(), {
  mode: 0,
});

const assistantMode = AssistantMode;
</script>
