<template>
  <div>
    <div class="login__header login__header--username">
      <h3>{{ textK('UI_LOGIN_HEADER') }}</h3>
    </div>
    <form @submit.prevent="handleNextStep">
      <div class="input-container">
        <p class="input-container__label pb-sm">{{ textK('UI_LOGIN_EMAIL_SUBTITLE') }}</p>
        <VTextField
          v-ui-test="'login-username'"
          :required="true"
          :type="uiVariant.Secondary"
          name="email"
          :label="textK('UI_LOGIN_IDENTIFIER_INPUT_LABEL')"
          :model-value="username"
          :valid="!usernameHasError"
          :error-message="usernameHasError ? textK('UI_LOGIN_USER_NOT_FOUND') : null"
          :size="uiSize.Medium"
          @update:modelValue="onUpdate"
          autocomplete="email"
          ref="emailInput"
          disable-type-validation
        ></VTextField>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import useText from '@/src/core/hooks/useText';
import VTextField from '../../ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '../../ui/ui.types';

export interface Props {
  username: string;
  usernameHasError: boolean;
}

const uiVariant = UiVariant;
const uiSize = UiSize;
const textK = useText();
defineProps<Props>();
const emit = defineEmits(['update-username', 'next-step']);

const onUpdate = (value: string) => {
  emit('update-username', value);
};

const handleNextStep = () => {
  emit('next-step');
};
</script>
