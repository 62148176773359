<template>
  <transition
    :mode="mode"
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <slot />
  </transition>
</template>

<style lang="scss" src="./transition-expand.scss" scoped></style>

<script lang="ts" setup>
import { ref } from 'vue';

type TransitionMode = 'in-out' | 'out-in' | 'default' | undefined;

interface Props {
  mode?: TransitionMode;
  transform?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'in-out',
  transform: false,
});

const emit = defineEmits(['after-enter', 'leave', 'after-leave']);

const yOffset = ref('100%');
const timeout = ref<number>();

const enter = (element: HTMLElement) => {
  element.style.position = 'absolute';
  element.style.visibility = 'hidden';
  element.style.height = 'auto';
  element.style.width = '100%';
  element.style.transform = props.transform ? `translateY(-${yOffset.value})` : '';

  const height = getComputedStyle(element).height;

  element.style.removeProperty('zoom');
  element.style.removeProperty('zoom');
  element.style.removeProperty('position');
  element.style.removeProperty('visibility');
  element.style.removeProperty('width');
  element.style.height = '0';
  clearTimeout(timeout.value);

  timeout.value = window.setTimeout(() => {
    element.style.height = height;
    element.style.removeProperty('transform');
  }, 50);
};

const afterEnter = (element: HTMLElement) => {
  clearTimeout(timeout.value);
  element.style.removeProperty('height');
  emit('after-enter');
};

const leave = (element: HTMLElement) => {
  const height = getComputedStyle(element).height;

  element.style.height = height;
  clearTimeout(timeout.value);

  timeout.value = window.setTimeout(() => {
    element.style.height = '0';
    element.style.transform = props.transform ? `translateY(-${yOffset.value})` : '';
    emit('leave');
  }, 50);
};

const afterLeave = (element: HTMLElement) => {
  clearTimeout(timeout.value);
  element.style.removeProperty('height');
  element.style.transform = props.transform ? `translateY(-${yOffset.value})` : '';
  emit('after-leave');
};
</script>
