<template>
  <form @submit.prevent="submitChanges">
    <StandardDialogModal
      :locked-modal="true"
      :loading="isBusy"
      class="modal-content"
      :has-close-button="false"
      :has-cancel-button="false"
      :size="uiSize.Medium"
    >
      <template #header>
        <h3>{{ textK('UI_USER_WELCOME_RIGHT_INFORMATION') }}</h3>
      </template>
      <template #body>
        <div class="inner-body">
          <UserProfile
            @userInput="handleInputChange"
            @valid="formIsValid = $event"
            :user="currentUser"
            :is-busy="isBusy"
          ></UserProfile>
          <small class="disclaimer"
            >The phone number is used when contacting you regarding orders and can be used for
            sending order updates if you explicitly specify this later.</small
          >
        </div>
      </template>

      <template #actions>
        <small class="disclaimer"
          >{{ textK('UI_USER_BY_REGISTERING') }}
          <a :href="linK('TermsOfUse_PageUrl')">{{ linK('TermsOfUse_Name') }}</a></small
        >
        <VButton
          @click="submitChanges"
          input-type="button"
          :disabled="!formIsValid"
          :type="uiVariant.Default"
          :loading="isLoading"
          >{{ textK('UI_COMMON_CONTINUE') }}</VButton
        >
      </template>
    </StandardDialogModal>
  </form>
</template>

<style lang="scss" scoped src="./modal-first-time-user.scss"></style>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useLink from '@/src/core/hooks/useLink';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import UserProfile from '@/src/profile/components/user/user-profile/user-profile.vue';
import { useUserStore } from '@/src/profile/stores/user';
import { computed, onMounted, ref } from 'vue';

const linK = useLink();
const textK = useText();
const modalStore = useModalStore();
const userStore = useUserStore();
const uiSize = UiSize;
const uiVariant = UiVariant;
const formIsValid = ref(false);
const isLoading = ref(false);

const currentUser = computed(() => {
  return userStore.currentUser;
});

const handleInputChange = ({ fieldName, value }: { fieldName: string; value: string }) => {
  currentUser.value[fieldName] = value;
};

const isBusy = computed(() => {
  return userStore.isBusy;
});

const submitChanges = async () => {
  if (currentUser.value.GlobalId) {
    isLoading.value = true;

    const user = currentUser.value;
    const { Unit, ...newUser } = user;

    const { IsSuccess } = await userStore.updateUser({
      userId: currentUser.value.GlobalId,
      user: newUser,
    });

    if (IsSuccess) {
      modalStore.closeModal({ modalComponent: 'ModalFirstTimeUser' });
    }

    isLoading.value = false;
  }
};

onMounted(() => {
  userStore.fetchUserProfile();
});
</script>
