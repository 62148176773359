<template>
  <div class="video-handler" v-if="showVideo">
    <video ref="video" width="1280" height="720" autoplay muted loop playsinline>
      <source :src="currentSource" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<style src="./video-handler.scss" lang="scss" scoped></style>

<script lang="ts">
import { Vue, Component, Prop, Watch, toNative } from 'vue-facing-decorator';
import Spinner from '@/src/core/components/ui/spinner/spinner.vue';
import isMobile from '@/src/core/utils/mobile-detection';

@Component({
  components: {
    Spinner,
  },
})
export class VideoHandler extends Vue {
  @Prop({ default: '' }) public source: string;

  public currentSource: string = '';
  public showVideo: boolean = true;

  public $refs!: {
    video: HTMLVideoElement;
  };

  private mounted() {
    if (isMobile.isMobile()) {
      this.currentSource = ''; // kill video request
      this.showVideo = false;
    } else {
      this.currentSource = this.source;
      this.$refs.video.addEventListener('canplaythrough', this.videoLoaded);
    }
  }

  private beforeDestroy() {
    if (this.showVideo) {
      this.$refs.video.removeEventListener('canplaythrough', this.videoLoaded);
    }
  }

  private videoLoaded() {
    this.$emit('videoLoad', true);
  }

  @Watch('source') private onSourceChange(val: string) {
    this.currentSource = val;
  }
}

export default toNative(VideoHandler);
</script>
