import { IOrderListQuery } from '@/src/core/types/interfaces';

export class SearchService {
  /**
   * @param  {IOrderListQuery} data
   */
  public static escape(data: IOrderListQuery) {
    const escapedFilters = {};
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const value = data[key];

        if (value != null && typeof value === 'string') {
          escapedFilters[key] = value.replace(/:/g, '\\:');
        } else {
          escapedFilters[key] = value;
        }
      }
    }
    return escapedFilters;
  }
}
