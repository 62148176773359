<template>
  <div class="orders-filters" :class="{ 'orders-filters--modal': modal }">
    <div class="orders-filters__group">
      <div class="orders-filters__group-name">
        {{ textK('UI_COMMON_SEARCH_FILTER_TITLE') }}
        <button
          v-if="isShowSearchForClear"
          class="orders-filters__clear-filter text-button"
          @click="clearSearchFilters"
        >
          {{ textK('UI_COMMON_CLEAR_FILTERS') }}
        </button>
      </div>
      <VTextField
        v-ui-test="'product-number'"
        class="orders-filters__group-input"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
        name="product-number"
        :label="textK('UI_COMMON_PART_NO')"
        :model-value="orderFilters.partNumber"
        @update:modelValue="(value: string) => updateTextFilter('partNumber', value)"
      >
      </VTextField>
      <VTextField
        v-ui-test="'purchase-order-number'"
        class="orders-filters__group-input"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
        name="purchase-order-number"
        :label="textK('UI_COMMON_PO_NO')"
        :model-value="orderFilters.purchaseOrderNumber"
        @update:modelValue="(value: string) => updateTextFilter('purchaseOrderNumber', value)"
      >
      </VTextField>
      <VTextField
        v-ui-test="'order-number'"
        class="orders-filters__group-input"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
        name="order-number"
        :label="textK('UI_COMMON_ORDER_NO')"
        :model-value="orderFilters.orderNumber"
        @update:modelValue="(value: string) => updateTextFilter('orderNumber', value)"
      >
      </VTextField>
      <VTextField
        v-ui-test="'cage-code'"
        class="orders-filters__group-input"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
        name="cage-code"
        :label="textK('UI_COMMON_MANUFACTURER_CODE')"
        :model-value="orderFilters.cageCode"
        @update:modelValue="(value: string) => updateTextFilter('cageCode', value)"
      >
      </VTextField>
      <VTextField
        v-ui-test="'ac-reg'"
        class="orders-filters__group-input"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
        name="master-shop-name"
        :label="textK('UI_COMMON_AC_REG')"
        :model-value="orderFilters.airCraftRegistrationNumber"
        @update:modelValue="
          (value: string) => updateTextFilter('airCraftRegistrationNumber', value)
        "
      >
      </VTextField>
    </div>
    <div class="orders-filters__group">
      <div class="orders-filters__group-name">
        {{ textK('UI_COMMON_SEARCH_FILTER_BY_LABEL') }}
      </div>
      <VSelect
        v-ui-test="'toolbar-filter-orders'"
        class="orders-filters__group-input"
        name="status-select"
        :options="status"
        v-model="selectedStatus"
        :label="textK('UI_ORDERS_SEARCH_STATUS_LABEL')"
        @update:modelValue="searchHandler"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
      ></VSelect>
    </div>

    <div class="orders-filters__group">
      <div class="orders-filters__group-name">
        {{ textK('UI_COMMON_PERIOD_TITLE') }}
        <button
          v-if="selectedTimePeriod"
          class="orders-filters__clear-filter text-button"
          @click="clearDateFilters()"
        >
          {{ textK('UI_COMMON_CLEAR_PERIOD') }}
        </button>
      </div>
      <VSelect
        v-ui-test="'toolbar-filter-period'"
        class="orders-filters__group-input"
        name="period-select"
        :options="timePeriod"
        v-model="selectedTimePeriod"
        :label="textK('UI_COMMON_PERIOD_SELECT_LABEL')"
        @update:modelValue="searchHandler"
        :type="modal ? uiVariant.Secondary : uiVariant.Primary"
      ></VSelect>
      <DatePickerWrapper
        ref="datepicker"
        class="orders-filters__group-input"
        :from-date="fromDate"
        :to-date="toDate"
        :min-date="dayjs().subtract(2, 'year').format(DisplayDateFormat)"
        :max-date="dayjs().format(DisplayDateFormat)"
        :range="true"
        :multi-calendars="true"
        :auto-position="true"
        @apply="applyDate"
      >
        <template #label v-if="selectedTimePeriod">
          <VTextField
            class="orders-filters__group-input"
            :label="textK('UI_COMMON_PERIOD_DATEPICKER_LABEL')"
            name="selected-time-period"
            :model-value="dates"
            :type="modal ? uiVariant.Secondary : uiVariant.Primary"
            :silent-disabled="true"
            :as-span="true"
          />
        </template>
      </DatePickerWrapper>
    </div>
  </div>
</template>

<style src="./repair-exchange-orders-filters.scss" lang="scss" scoped></style>

<script lang="ts" setup>
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import DatePickerWrapper from '@/src/core/components/ui/form/datepicker/datepicker.vue';
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { IVSelect } from '@/src/core/types/ui';
import { DisplayDateFormat, InputDateFormat } from '@/src/core/utils/dates';
import { RepairExchangeOrdersFiltersEventBus } from '@/src/core/utils/repair-exchange-filters';
import {
  ORDER_DATE_FILTER_LIST,
  OrdersService,
  REPAIR_EXCHANGE_ORDER_SEARCH_FACET_LIST,
} from '@/src/market/services/orders-service';
import { useRepairExchangeOrdersStore } from '@/src/market/stores/repair-exchange-orders';

interface Props {
  modal?: boolean;
}

withDefaults(defineProps<Props>(), {
  modal: false,
});

const textK = useText();
const authenticationStore = useAuthenticationStore();
const repairExchangeOrdersStore = useRepairExchangeOrdersStore();
const uiVariant = UiVariant;
const datepicker = ref<typeof DatePickerWrapper | null>(null);

const orderFilters = computed(() => {
  return repairExchangeOrdersStore.filters;
});

const updateTextFilter = (key: string, value: string) => {
  if (!value) {
    repairExchangeOrdersStore.deleteOrderListFilter({
      Key: key,
    });
  } else {
    repairExchangeOrdersStore.updateOrderListFilters({
      Query: { [key]: value },
    });
  }
  search();
};

const isShowSearchForClear = computed(() => {
  return (
    orderFilters.value.partNumber ||
    orderFilters.value.purchaseOrderNumber ||
    orderFilters.value.orderNumber ||
    orderFilters.value.cageCode ||
    orderFilters.value.airCraftRegistrationNumber
  );
});

const selectedStatus = computed({
  get: () => {
    const statusIndex = status.findIndex((status) => status.value === orderFilters.value?.status);
    return statusIndex === -1 ? 0 : statusIndex;
  },
  set: (index: number) => {
    const currentValue = status[index].value;
    repairExchangeOrdersStore.updateOrderListFilters({
      Query: { status: currentValue },
    });
  },
});

const status: IVSelect[] = [
  { label: 'All orders', value: 'ALL', hideValueWhenSelected: true },
  { label: 'Open orders', value: 'OPEN' },
  { label: 'Closed orders', value: 'CLOSED' },
  { label: 'Cancelled orders', value: 'CANCEL' },
];

const selectedTimePeriod = computed({
  get: () => {
    const timePeriodIndex = timePeriod.findIndex(
      (timePeriod) => timePeriod.value === orderFilters.value?.timePeriod,
    );
    return timePeriodIndex === -1 ? 0 : timePeriodIndex;
  },
  set: (index: number) => {
    const currentValue = timePeriod[index].value;

    if (currentValue === 'custom') {
      openDatepicker();
      return;
    }

    repairExchangeOrdersStore.updateOrderListFilters({
      Query: { timePeriod: currentValue },
    });

    fromDate.value = OrdersService.getFromDate(repairExchangeOrdersStore.filters);
    toDate.value = OrdersService.getToDate(repairExchangeOrdersStore.filters);
  },
});

const timePeriod: IVSelect[] = [
  { label: 'To date', value: '', hideValueWhenSelected: true },
  { label: 'Current month', value: 'current-month' },
  { label: 'Last month', value: 'last-month' },
  { label: 'Current year', value: 'current-year' },
  { label: 'Custom', value: 'custom' },
];

const fromDate = computed({
  get: () => {
    return orderFilters.value?.fromDate;
  },
  set: (value) => {
    if (value === '') {
      repairExchangeOrdersStore.deleteOrderListFilter({
        Key: 'fromDate',
      });
    } else {
      repairExchangeOrdersStore.updateOrderListFilters({
        Query: {
          fromDate: value ? dayjs(value, InputDateFormat).format(DisplayDateFormat) : undefined,
        },
      });
    }
  },
});

const toDate = computed({
  get: () => {
    return orderFilters.value?.toDate;
  },
  set: (value) => {
    if (value === '') {
      repairExchangeOrdersStore.deleteOrderListFilter({
        Key: 'toDate',
      });
    } else {
      repairExchangeOrdersStore.updateOrderListFilters({
        Query: {
          toDate: value ? dayjs(value, InputDateFormat).format(DisplayDateFormat) : undefined,
        },
      });
    }
  },
});

const dates = computed({
  get: () => {
    return fromDate.value + ' - ' + toDate.value;
  },
  set: (value) => {
    if (Array.isArray(value)) {
      fromDate.value = value[0];
      toDate.value = value[1];
    }

    repairExchangeOrdersStore.updateOrderListFilters({
      Query: { timePeriod: 'custom' },
    });
  },
});

const readOnlyAccount = computed(() => {
  return authenticationStore.readOnlyAccount;
});

const openDatepicker = () => {
  if (!datepicker.value) {
    return;
  }

  datepicker.value.openMenu();
};

const applyDate = (datepickerValue: string) => {
  dates.value = datepickerValue;
  search();
};

const searchHandler = debounce(() => {
  search();
}, 300);

const search = async () => {
  if (!readOnlyAccount.value) {
    repairExchangeOrdersStore.fetchRepairAndExchangeOrders();
  }
};

const clearSearchFilters = () => {
  repairExchangeOrdersStore.clearFilterGroup(REPAIR_EXCHANGE_ORDER_SEARCH_FACET_LIST);
  search();
};

const clearDateFilters = () => {
  repairExchangeOrdersStore.clearFilterGroup(ORDER_DATE_FILTER_LIST);
  search();
};

const clearAllFilters = () => {
  repairExchangeOrdersStore.clearFilterGroup(REPAIR_EXCHANGE_ORDER_SEARCH_FACET_LIST);
  repairExchangeOrdersStore.clearFilterGroup(ORDER_DATE_FILTER_LIST);
};

onMounted(() => {
  RepairExchangeOrdersFiltersEventBus.$on('clear', clearAllFilters);
});

onUnmounted(() => {
  RepairExchangeOrdersFiltersEventBus.$off('clear', clearAllFilters);
});
</script>
