<template>
  <form @submit.prevent="createList">
    <StandardDialogModal :has-cancel-button="true" :size="uiSize.Small">
      <template #header>
        <h3>Create new list</h3>
      </template>
      <template #body>
        <div class="inner-body">
          <VTextField
            :type="uiVariant.Secondary"
            name="List name"
            label="Name"
            v-model="listName"
            ref="textfield"
          ></VTextField>
        </div>
      </template>
      <template #actions>
        <VButton
          v-ui-test="'create-list-button'"
          :disabled="listName.length <= 0"
          :loading="isBusy"
          input-type="button"
          @click.prevent="createList"
          :type="uiVariant.Default"
          >Save</VButton
        >
      </template>
    </StandardDialogModal>
  </form>
</template>

<style lang="scss" src="./modal-create-cart.scss" scoped></style>

<script lang="ts">
import { Vue, Component, Ref, toNative } from 'vue-facing-decorator';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import { useModalStore } from '@/src/core/stores/modal';
import { IList } from '@/src/core/types/interfaces';
import { useListsStore } from '@/src/market/stores/list';

@Component({
  components: {
    VTextField,
    VButton,
    StandardDialogModal,
  },
})
export class ModalCreateList extends Vue {
  @Ref() readonly textfield!: InstanceType<typeof VTextField>;

  public listsStore = useListsStore();
  public modalStore = useModalStore();
  public listName: string = '';
  public uiSize = UiSize;
  public uiVariant = UiVariant;

  public get isBusy(): boolean {
    return this.listsStore.isBusy;
  }

  public async createList() {
    this.modalStore.lockCurrentModal(true);
    const result = await this.listsStore.createList({ listName: this.listName });

    if (result.IsSuccess) {
      const myList = {} as IList;
      myList.Code = result.Data?.Code;
      this.modalStore.closeModal();
    } else {
      this.modalStore.lockCurrentModal(false);
    }
  }

  public mounted() {
    (this.$refs.textfield as any).setFocus();
  }
}

export default toNative(ModalCreateList);
</script>
