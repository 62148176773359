export const getRandomString = ({ length }: { length: number }) =>
  Array.from({ length })
    .map(() => 'x')
    .join('')
    .replace(/[x]/g, () => {
      const r = Math.floor(Math.random() * 16);
      return r.toString(16);
    });

export const upperFirst = (str: string) => str.charAt(0).toLocaleUpperCase() + str.slice(1);

export const lowerFirst = (str: string) => str.charAt(0).toLocaleLowerCase() + str.slice(1);
