// tslint:disable
import { getEnv } from '@/src/core/services/environment';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class CartApi {
  public static AddProduct = `${baseUrl}/api/addproduct`;
  public static GetCart = `${baseUrl}/api/getcart`;
  public static ClearCart = `${baseUrl}/api/clearcart`;
  public static DeleteCartEntry = (cartCode: string, entryId: number) =>
    `${baseUrl}/api/cart/${cartCode}/delete/${entryId}`;
  public static CartProductQuantity = (cartCode: string, entryId: string) =>
    `${baseUrl}/api/cart/${cartCode}/${entryId}/quantity`;
  public static CartEntries = `${baseUrl}/api/cart/entries`;
  public static FetchCarriers = (cartCode: string, sellerId: string) =>
    `${baseUrl}/api/cart/carriers/${cartCode}/${sellerId}`;
  public static FetchWarehouses = (productCode: string) =>
    `${baseUrl}/api/cart/entry/details?cartCode=current&productCode=${productCode}`;
  public static UpdateWarehouses = (productCode: string, calculated?: boolean) =>
    `${baseUrl}/api/cart/entry/details/update?cartCode=current&productCode=${productCode}&calculated=${calculated}`;
  public static UpdateCarrier = (cartCode: string, carrierCode: string) =>
    `${baseUrl}/api/cart/carrier/${cartCode}?code=${carrierCode}`;
  public static FetchDeliveryAddresses = (cartCode: string) =>
    `${baseUrl}/api/cart/deliveryaddresses/${cartCode}`;
  public static UpdateDeliveryAddresses = (cartCode: string, addressId: string) =>
    `${baseUrl}/api/cart/deliveryaddress/${cartCode}/${addressId}`;
  public static UpdateCartInfo = (cartCode: string) => `${baseUrl}/api/cart/info/${cartCode}`;
  public static UpdateCartInfoWithProjectId = (
    cartCode: string,
    ponumber: string,
    projectId: string,
  ) => `${baseUrl}/api/cart/info/${cartCode}/${ponumber}/${projectId}`;
  public static UpdatePriority = (cartCode: string, sellerKey: string) =>
    `${baseUrl}/api/cart/priority/${cartCode}?shopId=${sellerKey}`;
  public static UpdateRemark = (cartCode: string, entryNumber: string) =>
    `${baseUrl}/api/cart/${cartCode}/entries/${entryNumber}`;
  public static UpdateOrderType = (orderType: string, cartId: string, masterShopId: string) =>
    `${baseUrl}/api/cart/ordertype?orderType=${orderType}&cartId=${cartId}&masterShopId=${masterShopId}`;
  public static SetCustomerCode = (customerCode: string, cartId: string, masterShopId: string) =>
    `${baseUrl}/api/cart/customercode?customerCode=${customerCode}&cartId=${cartId}&masterShopId=${masterShopId}`;
  public static GetCartPricing = (globalId: string, cartId: string) =>
    `${baseUrl}/api/users/${globalId}/carts/${cartId}/pricing`;
}
