<template>
  <div class="seller-offer-conversation">
    <div class="seller-offer-conversation__header" v-ui-test="'seller-conversation-header'">
      <h3 class="seller-offer-conversation__title">
        {{ textK('UI_SELLER_CONVERSATION_MODAL_TITLE') }} ({{ sellerName }})
      </h3>
      <p class="seller-offer-conversation__sub-title">
        {{ textK('UI_SELLER_CONVERSATION_MODAL_DISCLAIMER_ 1') }} <strong>{{ sellerName }}</strong>
        {{ textK('UI_SELLER_CONVERSATION_MODAL_DISCLAIMER_ 2') }}
      </p>
    </div>
    <div
      class="seller-offer-conversation__product"
      v-if="offer"
      v-ui-test="'seller-conversation-offer'"
    >
      <div
        class="seller-offer-conversation__product__image"
        v-ui-test="'seller-conversation-offer-image'"
      >
        <img
          v-if="offer.HasImage && offer.Images.length > 0"
          :src="offer.Images[0].CdnUrl + '&width=100&height=100&mode=crop'"
        />
        <svg
          v-else
          class="placeholder-image"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 110 110"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M75.4 12.4v47.4l-59-59L.8 16.4l59 59H12.4L.8 97.4h96.6V.8z"
          />
        </svg>
      </div>
      <div class="seller-offer-conversation__product__details">
        <div
          class="seller-offer-conversation__product__number"
          v-ui-test="'seller-conversation-offer-sku'"
        >
          {{ offer.ManufacturerAid
          }}<span
            v-if="offer.ProductManufacturer && offer.ProductManufacturer.CageCode.length > 0"
            class="seller-offer-conversation__product__cagecode"
            >:{{ offer.ProductManufacturer.CageCode }}</span
          >
        </div>
        <div
          class="seller-offer-conversation__product__title"
          v-ui-test="'seller-conversation-offer-name'"
        >
          {{ offer.Name && offer.Name.length > 0 ? offer.Name : '-' }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./seller-offer-conversation-header.scss" scoped />

<script lang="ts">
import { Vue, Component, Prop, toNative } from 'vue-facing-decorator';
import useText from '@/src/core/hooks/useText';
import { useProductStore } from '@/src/market/stores/product';
import { useSellerConversationStore } from '@/src/market/stores/seller-conversation';

@Component({})
export class SellerOfferConversationHeader extends Vue {
  @Prop() private offerId: string;
  public textK = useText();
  public productStore = useProductStore();
  public sellerConversationStore = useSellerConversationStore();

  private get masterShopName() {
    return this.sellerConversationStore.masterShopName;
  }
  private get locationDisplayName() {
    return this.sellerConversationStore.locationDisplayName;
  }
  public get offer() {
    return this.productStore.productById(this.offerId);
  }

  public get sellerName() {
    return this.masterShopName + ', ' + this.locationDisplayName;
  }
}

export default toNative(SellerOfferConversationHeader);
</script>
