import { AssistantStates } from '@/src/core/types/ui';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

interface Params {
  loading?: boolean;
}

export const useAssistantStore = defineStore('assistant', () => {
  const assistantState = ref<AssistantStates>(AssistantStates.Hidden);
  const assistantComponent = ref<string | null>(null);
  const assistantLocked = ref<boolean | undefined>(false);
  const params = ref<Params>({});

  const assistantActive = computed(() => {
    return assistantState.value !== AssistantStates.Hidden;
  });

  const assistantUpdated = (payload: {
    assistantState: AssistantStates;
    assistantComponent: string | null;
    params?: Params;
    isLocked?: boolean;
  }) => {
    assistantState.value = payload.assistantState;
    assistantComponent.value = payload.assistantComponent;
    params.value = payload.params || {};
    assistantLocked.value = payload.isLocked || false;
  };

  const reset = () => {
    assistantState.value = AssistantStates.Hidden;
    assistantComponent.value = null;
    params.value = {};
    assistantLocked.value = false;
  };

  const assistantStateUpdated = (payload: { assistantState: AssistantStates }) => {
    assistantState.value = payload.assistantState;
  };

  const assistantLockUpdated = (payload: { assistantLocked: boolean }) => {
    assistantLocked.value = payload.assistantLocked;
  };

  const showAssistant = (payload: {
    assistantState: AssistantStates;
    assistantComponent: string | null;
    params?: Params;
    isLocked?: boolean;
  }) => {
    assistantUpdated(payload);
  };

  const lockAssistant = (payload: { assistantLocked: boolean }) => {
    assistantLockUpdated(payload);
  };

  const changeAssistantState = (payload: { assistantState: AssistantStates }) => {
    assistantStateUpdated(payload);
  };

  const closeAssistant = () => {
    reset();
  };

  return {
    assistantState,
    assistantComponent,
    assistantLocked,
    params,
    assistantActive,
    reset,
    assistantStateUpdated,
    showAssistant,
    lockAssistant,
    changeAssistantState,
    closeAssistant,
  };
});
