<script>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import json2mq from 'json2mq';

export default {
  name: 'MediaQuery',
  props: {
    query: {
      type: Object,
      required: true,
    },
  },
  setup(props, { slots, emit }) {
    const matches = ref(false);
    const mediaQueryList = ref(null);

    const updateMatches = () => {
      matches.value = Boolean(mediaQueryList.value?.matches);
    };

    watch(matches, (newMatch) => {
      if (mediaQueryList.value) {
        newMatch
          ? emit('media-enter', mediaQueryList.value.media)
          : emit('media-leave', mediaQueryList.value.media);
      }
    });

    onMounted(() => {
      if (typeof window === 'undefined') {
        return;
      }

      const mediaQuery = json2mq(props.query ?? {});
      mediaQueryList.value = window.matchMedia(mediaQuery);
      updateMatches();
      mediaQueryList.value.addEventListener('change', updateMatches);
    });

    onBeforeUnmount(() => {
      if (mediaQueryList.value) {
        mediaQueryList.value.removeEventListener('change', updateMatches);
      }
    });

    return () =>
      matches.value && slots.default && slots.default().length > 0 ? slots.default?.() : null;
  },
};
</script>
