import { QuoteApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { OfferQuoteRequest } from '@/src/core/types/api';
import { defineStore } from 'pinia';

interface QuotesRequestState {
  quoteRequest: OfferQuoteRequest;
  isBusy: boolean;
}

export const useQuotesRequestStore = defineStore('quotesRequest', {
  state: (): QuotesRequestState => ({
    quoteRequest: {
      Message: '',
      OfferId: '',
      OfferedBasePrice: '',
      ReferenceNumber: '',
      RequestedQuantity: '1',
      ShippingAddressId: '',
      Subject: '',
    },
    isBusy: false,
  }),
  actions: {
    updateQuoteRequest(payload: OfferQuoteRequest) {
      this.quoteRequest = { ...this.quoteRequest, ...payload };
    },
    async submitQuoteRequest() {
      this.isBusy = true;

      const { IsSuccess } = await Req({
        url: QuoteApi.CreateQuote,
        data: this.quoteRequest,
        method: 'POST',
      });

      if (IsSuccess) {
        this.$reset();
      }

      this.isBusy = false;

      return { IsSuccess };
    },
  },
});
