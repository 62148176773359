<template>
  <span class="copy-button-wrapper" @mouseenter="displayMessage = title">
    <Tooltip>
      <button class="copy-button" @click="copy()" v-ui-test="'copy-button'">
        <svgicon name="ui-copy"></svgicon>
      </button>
      <template #tooltip-content>{{ displayMessage }}</template>
    </Tooltip>
    <input class="copy-button-string" type="text" ref="copyRef" :value="copyString" />
  </span>
</template>

<style lang="scss" src="./copy-button.scss" scoped></style>

<script lang="ts" setup>
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import useText from '@/src/core/hooks/useText';
import { computed, ref, watch } from 'vue';

interface Props {
  title?: string;
  success?: string;
  copyString?: string;
}

const props = withDefaults(defineProps<Props>(), {
  title: 'Copy',
  success: '',
  copyString: '',
});
const textK = useText();

const displayMessage = ref('');
const copyRef = ref<HTMLInputElement>();

const successMessage = computed(() => {
  return props.success.length > 0 ? props.success : textK('UI_COMMON_COPY_SUCCESS') || '';
});

const copy = () => {
  copyRef.value?.select();
  document.execCommand('copy');
  copyRef.value?.blur();
  displayMessage.value = successMessage.value;
};

watch(
  () => props.title,
  (newValue: string) => {
    displayMessage.value = newValue;
  },
  { immediate: true },
);
</script>
