<template>
  <div
    class="input-feedback"
    :class="{
      'input-feedback--default': type === notificationTypes.Information,
      'input-feedback--error': type === notificationTypes.Error,
      'input-feedback--warning': type === notificationTypes.Warning,
      'input-feedback--success': type === notificationTypes.Success,
    }"
  >
    <slot />
  </div>
</template>

<style lang="scss" src="./input-feedback.scss" scoped></style>

<script lang="ts" setup>
import { NotificationTypes } from '@/src/core/types/api';

interface Props {
  type?: NotificationTypes;
}

withDefaults(defineProps<Props>(), {
  type: NotificationTypes.Information,
});

const notificationTypes = NotificationTypes;
</script>
