<template>
  <StandardDialogModal
    class="list-modal"
    :size="uiSize.Large"
    :loading="!isFetched || changingAddress"
  >
    <template #header>
      <h3>{{ textK('UI_CART_CHANGE_DELIVERY_ADDRESS_TITLE') }}</h3>
    </template>
    <template #body>
      <ModalColumnContainer>
        <ModalColumn>
          <AddressSelector v-model="selected" @isFetched="adressesUpdated" :is-airbus="isAirbus" />
        </ModalColumn>
        <ModalColumn>
          <div class="list-modal-paragraph">
            <h4 class="title">{{ textK('UI_CART_CURRENT_DELIVERY_ADDRESS') }}</h4>
            <AddressCard :data="selected"></AddressCard>
          </div>
          <div class="list-modal-paragraph">
            <h4 class="title">{{ textK('UI_CART_CHANGE_DELIVERY_ADDRESS_SUB_TITLE') }}</h4>
            <p>{{ textK('UI_CART_CHANGE_DELIVERY_ADDRESS') }}</p>
          </div>
        </ModalColumn>
      </ModalColumnContainer>
    </template>
  </StandardDialogModal>
</template>

<style lang="scss" src="./modal-checkout-select-address.scss" scoped></style>

<script lang="ts" setup>
import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import AddressCard from '@/src/core/components/ui/addressCard/address-card.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { Address } from '@/src/core/types/api';
import AddressSelector from '@/src/market/components/ui/address-selector/address-selector.vue';
import { useCartStore } from '@/src/market/stores/cart';
import { useCheckoutStore } from '@/src/market/stores/checkout';
import { useDeliveryAddressStore } from '@/src/market/stores/delivery-addresses';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

interface Params {
  sellerId: string;
}

const textK = useText();
const router = useRouter();
const modalStore = useModalStore();
const cartStore = useCartStore();
const checkoutStore = useCheckoutStore();
const deliveryAddressesStore = useDeliveryAddressStore();
const changingAddress = ref(false);
const isFetched = ref(false);
const uiSize = UiSize;

const fetchDeliveryAddress = () => {
  deliveryAddressesStore.fetchDeliveryAddresses(modalParams.value?.sellerId);
};

const fetchCurrentCart = async () => {
  cartStore.fetchCart({ calculated: true });
};

const adressesUpdated = () => {
  if (!isFetched.value) {
    isFetched.value = true;
  }
};

const isAirbus = computed(() => {
  return sellerId.value?.toLowerCase() === 'airbus';
});

const modalParams = computed(() => {
  return modalStore.params as Params;
});

const sellerId = computed(() => {
  return modalParams.value.sellerId;
});

const pageName = computed(() => {
  return router.currentRoute.value.name;
});

const selected = computed({
  get: () => checkoutStore.seller(sellerId.value)?.DeliveryAddress || ({} as Address),
  set: (item: Address) => {
    changingAddress.value = true;
    checkoutStore
      .updateDeliveryAddress({ sellerId: sellerId.value, addressId: item.Id })
      .then(() => {
        modalStore.closeModal({ modalComponent: 'ModalCheckoutSelectAddress' });
        if (pageName.value === 'cart') {
          fetchCurrentCart();
        }
      });
  },
});

onMounted(() => {
  fetchDeliveryAddress();
});
</script>
