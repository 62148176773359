<template>
  <a @click.prevent="downloadHandler">{{ fileName }}</a>
</template>

<script lang="ts" setup>
import useDownload from '@/src/core/hooks/useDownload';

export interface DownloadLinkProps {
  url: string;
  fileName: string;
}

const props = withDefaults(defineProps<DownloadLinkProps>(), {
  url: '',
  fileName: '',
});
const downloadFile = useDownload();

const downloadHandler = () => {
  downloadFile({ url: props.url });
};
</script>
