<template>
  <div class="blog-information image-box-module__subtitle">
    <p>
      <slot></slot>
    </p>
  </div>
</template>

<style lang="scss" scoped src="./blog-information.scss"></style>

<script lang="ts" setup></script>
