import { PriceBreakInfo } from '@/src/core/types/api';

export const getCurrentPriceBreakIndex = (priceBreaks: PriceBreakInfo[], cartQty: number) => {
  const condition = (entry: PriceBreakInfo) => {
    return entry.MinQuantity <= cartQty;
  };
  const matchingIndexes = priceBreaks.reduce((indexes: number[], currentElement, currentIndex) => {
    if (condition(currentElement)) {
      indexes.push(currentIndex);
    }
    return indexes;
  }, []);
  return Math.max(...matchingIndexes) || 0;
};
