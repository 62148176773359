import { Site, useUIStore } from '@/src/core/stores/ui';
import { RouteLocationNormalized } from 'vue-router';

const FinanceLayout = () => import('@/src/finance/layouts/finance-layout.vue');
const DocumentsLayout = () => import('@/src/finance/layouts/documents/documents-layout.vue');
const AccountStatementLayout = () =>
  import('@/src/finance/layouts/account-statement/account-statement-layout.vue');
const AccountStatement = () =>
  import('@/src/finance/components/account-statement/account-statement.vue');
const AccountStatementAirbus = () =>
  import('@/src/finance/components/account-statement-airbus/account-statement-airbus.vue');

const financeRoutes = [
  {
    path: '/finance',
    name: 'finance',
    redirect: { name: 'documents' },
    component: FinanceLayout,
    meta: {
      loginRestricted: true,
      betaRestricted: 'finance',
    },
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: () => void) => {
      const uiStore = useUIStore();
      uiStore.setCurrentSite({ site: Site.Finance });
      next();
    },
    children: [
      {
        path: '/finance/documents',
        name: 'documents',
        meta: {
          title: 'Billing documents',
          type: 'Finance page',
          loginRestricted: true,
          betaRestricted: 'finance',
        },
        component: DocumentsLayout,
        children: [
          {
            path: '/finance/documents/satair',
            name: 'documentsSatair',
            meta: {
              title: 'Billing Documents Satair',
              type: 'Documents Satair page',
              loginRestricted: true,
            },
            components: {
              accountRoute: DocumentsLayout,
            },
          },
          {
            path: '/finance/documents/airbus',
            name: 'documentsAirbus',
            meta: {
              title: 'Billing Documents Airbus',
              type: 'Documents Airbus page',
              loginRestricted: true,
            },
            components: {
              accountRoute: DocumentsLayout,
            },
          },
        ],
      },
      {
        path: '/finance/account-statement',
        name: 'account-statement',
        meta: {
          title: 'Account Statement',
          type: 'Finance page',
          loginRestricted: true,
          betaRestricted: 'finance',
        },
        component: AccountStatementLayout,
        children: [
          {
            path: '/finance/account-statement/satair',
            name: 'accountStatementSatair',
            meta: {
              title: 'Account Statement Satair',
              type: 'Account Statement Satair page',
              loginRestricted: true,
            },
            components: {
              accountRoute: AccountStatement,
            },
          },
          {
            path: '/finance/account-statement/airbus',
            name: 'accountStatementAirbus',
            meta: {
              title: 'Account Statement Airbus',
              type: 'Account Statement Airbus page',
              loginRestricted: true,
            },
            components: {
              accountRoute: AccountStatementAirbus,
            },
          },
        ],
      },
    ],
  },
];

export default financeRoutes;
