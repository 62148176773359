import { AirbusHeavyMaintenanceFormInfo } from '@/src/core/apim';
import { Req } from '@/src/core/services/requester';
import { HeavyMaintenanceApi } from '@/src/market/api/heavymaintenanceApi';
import { reactive, toRefs } from 'vue';

interface State {
  isBusy: boolean;
  heavyMaintenanceFormInfo: AirbusHeavyMaintenanceFormInfo | null;
  error: any;
}

export const useFetchHeavyMaintenanceFormInfo = () => {
  const state = reactive<State>({
    isBusy: false,
    heavyMaintenanceFormInfo: null,
    error: null,
  });

  const fetchFormInfo = async (userId: string) => {
    state.isBusy = true;

    try {
      if (!userId) {
        return;
      }

      const { Data, IsSuccess } = await Req(
        {
          url: HeavyMaintenanceApi.HeavyMaintenanceFormInfo(userId),
        },
        undefined,
        HeavyMaintenanceApi.handleHeavyMaintenanceFormInfo,
      );

      if (Data && IsSuccess) {
        state.heavyMaintenanceFormInfo = Data;
      }
    } catch (error) {
      error.value = error;
    } finally {
      state.isBusy = false;
    }
  };

  return { ...toRefs(state), fetchFormInfo };
};
