<template>
  <ContentBlock :url="umbracoUrl" :force="true" :animation="false"></ContentBlock>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch, toNative } from 'vue-facing-decorator';
import ContentBlock from '@/src/core/components/ui/content-block/content-block.vue';
import { useContentBlocksStore } from '@/src/core/stores/content-blocks';
import { IAdobeTargetOffer } from '@/src/core/types/adobe';
import { ResizeEventBus } from '@/src/core/utils/resize-event-bus';

@Component({
  components: {
    ContentBlock,
  },
})
export class TargetUmbracoWrapper extends Vue {
  @Prop() private offer: IAdobeTargetOffer<ITargetUmbracoOffer>;

  public contentBlocksStore = useContentBlocksStore();

  private loading: boolean;

  public get umbracoUrl(): string {
    const content = this.offer.content && this.offer.content[0];
    return content?.content.umbracoUrl || '';
  }

  private get blockIsLoading() {
    return this.contentBlocksStore.isBlockBusy(this.umbracoUrl);
  }

  @Watch('blockIsLoading') private onBlockLoadChange() {
    if (!this.blockIsLoading) {
      this.$emit('loading', false);
      ResizeEventBus.$emit('resize');
    }
  }
}

export interface ITargetUmbracoOffer {
  umbracoUrl: string;
}

export default toNative(TargetUmbracoWrapper);
</script>
