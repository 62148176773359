<template>
  <div class="search-filter-modal" v-ui-test="'search-filter-modal'">
    <StandardDialogModal :size="uiSize.Large" :has-cancel-button="false">
      <template #header>
        <h3>
          {{ textK('UI_SEARCHRESULT_FILTERMODALHEADER') }}
        </h3>
      </template>
      <template #body>
        <div class="facet-filter">
          <div class="facet-filter-group">
            <template v-for="facet in searchFacets" :key="facet.Name">
              <div class="facet-filter__group-name">{{ facet.DisplayName }}</div>
              <SearchResultsFacetGroup :facet="facet"></SearchResultsFacetGroup>
            </template>
          </div>
        </div>
      </template>
      <template #actions>
        <VButton v-if="facetsActive" @click="resetFacets">
          {{ textK('UI_SEARCHRESULT_FILTERMODALRESET') }}
        </VButton>
        <VButton
          :type="uiVariant.Primary"
          @click="close"
          v-ui-test="'modal-search-filter-see-results-button'"
        >
          {{ textK('UI_SEARCHRESULT_FILTERMODALRESULT') }}
        </VButton>
      </template>
    </StandardDialogModal>
  </div>
</template>

<style scoped lang="scss" src="./modal-search-filter.scss"></style>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { Facets } from '@/src/core/types/api';
import SearchResultsFacetGroup from '@/src/market/components/search/search-results-facets/search-result-facet-group/search-result-facet-group.vue';
import { useSearchStore } from '@/src/market/stores/search';

@Component({
  components: {
    VButton,
    StandardDialogModal,
    SearchResultsFacetGroup,
  },
})
export class ModalSearchFilter extends Vue {
  public textK = useText();
  public searchStore = useSearchStore();
  public modalStore = useModalStore();
  public uiVariant = UiVariant;
  public uiSize = UiSize;

  public get searchFacets(): Facets[] {
    return this.searchStore.cleanedFacets;
  }

  public get facetsActive(): boolean {
    return this.searchStore.facetAsString !== '';
  }

  public close() {
    this.modalStore.closeModal();
  }

  public resetFacets() {
    this.searchStore.resetFacets();
  }
}

export default toNative(ModalSearchFilter);
</script>
