<template>
  <StandardDialogModal :size="uiSize.Large">
    <template #body>
      <div class="image-modal" :style="{ height: containerHeight + 'px' }">
        <transition name="fade">
          <ImageHandler
            v-if="activeImage"
            :images="imageSources"
            :container-width="1000"
            :load-image="true"
            @imageLoad="containerHeight = $event.offsetHeight"
            @busy="isBusy = $event"
          />
        </transition>
        <Spinner v-if="isBusy" />
        <template v-if="images.length > 1">
          <button
            @click.stop="prevImage()"
            class="text-button image-modal__navigation image-modal__navigation prev"
          >
            <svgicon class="image-modal__navigation__icon" name="ui-arrow" />
          </button>
          <button
            @click.stop="nextImage()"
            class="text-button image-modal__navigation image-modal__navigation next"
          >
            <svgicon class="image-modal__navigation__icon" name="ui-arrow" />
          </button>
        </template>
      </div>
    </template>
  </StandardDialogModal>
</template>

<style lang="scss" src="./modal-image.scss" scoped></style>

<script lang="ts">
import { Vue, Component, Watch, toNative } from 'vue-facing-decorator';
import ImageHandler from '@/src/core/components/media-handler/image-handler/image-handler.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import Spinner from '@/src/core/components/ui/spinner/spinner.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import { useModalStore } from '@/src/core/stores/modal';
import { ISources } from '@/src/core/types/interfaces';
import { useProductStore } from '@/src/market/stores/product';

@Component({
  components: {
    StandardDialogModal,
    ImageHandler,
    Spinner,
  },
})
export class ModalImage extends Vue {
  public productStore = useProductStore();
  public modalStore = useModalStore();
  private uiVariant = UiVariant;
  public uiSize = UiSize;
  private localIndex: number = -1;
  public containerHeight: number = 0;
  public isBusy: boolean = false;
  public imageSources: ISources[] = [];

  private get params() {
    return this.modalStore.params as IModalImage;
  }

  private get activeIndex() {
    return this.localIndex !== -1 ? this.localIndex : this.params.activeIndex;
  }

  private set activeIndex(value: number) {
    this.localIndex = value;
  }

  public get images() {
    return this.productStore.productById(this.params.id)?.Images || [];
  }

  public get activeImage() {
    return this.images[this.activeIndex];
  }

  public nextImage() {
    if (this.activeIndex >= this.images.length - 1) {
      this.activeIndex = 0;
    } else {
      this.activeIndex++;
    }
  }

  public prevImage() {
    if (this.activeIndex <= 0) {
      this.activeIndex = this.images.length - 1;
    } else {
      this.activeIndex--;
    }
  }

  @Watch('activeImage', { immediate: true }) private onImageChange() {
    if (this.activeImage) {
      this.imageSources = [
        {
          url: this.activeImage.CdnUrl + '&width=500&height=500',
          size: 200,
        },
        {
          url: this.activeImage.CdnUrl + '&width=1000&height=1000',
          size: 800,
        },
      ];
    }
  }
}

export interface IModalImage {
  id: string;
  activeIndex: number;
}

export default toNative(ModalImage);
</script>
