<template>
  <section class="product-details-section product-details-header-wrapper">
    <div class="product-details-header__left">
      <!-- Product Image -->
      <div class="product-details-small-image">
        <img
          v-if="hasImage"
          @click="
            openModal({ modalComponent: 'ModalImage', params: { id: product.Id, activeIndex: 0 } })
          "
          :alt="defaultImage?.alt"
          :src="defaultImage?.url"
        />
        <svg
          v-else
          class="placeholder-image"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 110 110"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M75.4 12.4v47.4l-59-59L.8 16.4l59 59H12.4L.8 97.4h96.6V.8z"
          />
        </svg>
      </div>
      <!-- /Product Image -->
      <div class="product-details-header">
        <VLabel v-if="hasOpenBids" class="product-details__condition-label-bids" :size="1">
          {{ textK('UI_SEARCHRESULT_OPENFORBIDS') }}
        </VLabel>

        <h1 class="product-details-title">
          <span v-ui-test="'productDetailsProductCode'"
            >{{ product.ManufacturerAid }}:<wbr /><span class="title-cageCode">{{
              product.ProductManufacturer.CageCode
            }}</span></span
          >
        </h1>
        <h2
          class="product-details-subtitle"
          v-ui-test="'productDetailsProductName'"
          v-html="product.Name"
        ></h2>
      </div>
    </div>

    <ProductDetailsSupplierLogo :logo="supplierLogo" />
  </section>
</template>

<script setup lang="ts">
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import { OwningSystem, Product, ProductOffer } from '@/src/core/types/api';
import { computed, ref } from 'vue';

import VLabel from '@/src/core/components/ui/label/label.vue';
import ProductDetailsSupplierLogo from '@/src/market/components/product/product-details/product-details-supplier-logo/product-details-supplier-logo.vue';
import { AirbusFHSMasterShop } from '@/src/core/types/interfaces';

interface Props {
  product: Product;
  offer: ProductOffer;
  fhsMastershop?: AirbusFHSMasterShop | null;
}

const props = defineProps<Props>();
const textK = useText();
const openModal = useModal();

const isMarketPlace = ref(props.product.IsMarketplace);

const hasImage = ref(props.product.HasImage);

const defaultImage = computed(() => {
  if (hasImage.value) {
    return {
      url: `${props.product.Images[0].CdnUrl}&width=100&height=100&mode=crop`,
      alt: props.product.Images[0].AltText,
    };
  }

  return null;
});

const supplierLogo = computed(() => {
  if (props.fhsMastershop) {
    return { url: props.fhsMastershop.newSearchResultLogo, alt: props.fhsMastershop.name };
  }

  if (props.product.OwningSystem === OwningSystem.MIRAKL) {
    return { url: props.offer.SupplierLogo, alt: props.offer.MasterShopName };
  }

  return { url: props.product.SupplierLogo, alt: props.product.SupplierDisplay };
});

const hasOpenBids = computed(
  () =>
    !props.product.PriceHidden &&
    isMarketPlace.value &&
    props.product.AllowQuoteRequests &&
    !props.offer.Inactive,
);

const owningSystem = computed(() => props.product.OwningSystem as OwningSystem);
</script>
