<template>
  <div class="l_standard-sub-page">
    <UserDetails v-if="isAuthenticated"></UserDetails>
  </div>
</template>

<style src="./edit-user-layout.scss" lang="scss" scoped></style>

<script lang="ts" setup>
import UserDetails from '@/src/account/components/admin/user-details/user-details.vue';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { computed } from 'vue';

const authenticationStore = useAuthenticationStore();

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);
</script>
