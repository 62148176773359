import svgicon from '@/src/core/components/ui/svgicon/svgicon.vue';
import UiTests from '@/src/core/plugins/ui-test-tags';
import { getEnv } from '@/src/core/services/environment';
import { WhatInputHandler } from '@/src/core/utils/what-input-handler';
import vClickOutside from '@/src/core/components/util/click-outside';
import { createPinia } from 'pinia';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { createApp } from 'vue';
import App from './main.vue';
import router from '@/src/core/router';

import ContentAccordionModule from '@/src/content/components/content-modules/accordion-module/accordion-module.vue';
import AlphabeticalLinkList from '@/src/content/components/content-modules/alphabetical-link-list/alphabetical-link-list.vue';
import ContentArticleModule from '@/src/content/components/content-modules/article-module/article-module.vue';
import BlogContentWrapper from '@/src/content/components/content-modules/blog-content-wrapper/blog-content-wrapper.vue';
import BlogPostModule from '@/src/content/components/content-modules/blog-post-module/blog-post-module.vue';
import BlogFilters from '@/src/content/components/content-modules/blog-post-overview/blog-filters/blog-filters.vue';
import BlogPostOverview from '@/src/content/components/content-modules/blog-post-overview/blog-post-overview.vue';
import ContentBoxCtaModule from '@/src/content/components/content-modules/box-cta-module/box-cta-module.vue';
import ContentBoxListModule from '@/src/content/components/content-modules/box-list-module/box-list-module.vue';
import ContentBoxSpotModule from '@/src/content/components/content-modules/box-spot-module/box-spot-module.vue';
import ContentComparisonBoxesModule from '@/src/content/components/content-modules/comparison-boxes-module/comparison-boxes.vue';
import ContactFormModule from '@/src/content/components/content-modules/contact-form-module/contact-form-module.vue';
import ContactInfoModule from '@/src/content/components/content-modules/contact-info-module/contact-info-module.vue';
import ConvertCalculatorModule from '@/src/content/components/content-modules/convert-calculator-module/convert-calculator-module.vue';
import ContentCtaBoxModule from '@/src/content/components/content-modules/cta-box-module/cta-box-module.vue';
import DownloadListModule from '@/src/content/components/content-modules/download-list-module/download-list-module.vue';
import EventListModule from '@/src/content/components/content-modules/event-list-module/event-list-module.vue';
import ContentFullWidthMediaModule from '@/src/content/components/content-modules/full-width-element-module/full-width-element.vue';
import ContentHeroModule from '@/src/content/components/content-modules/hero-module/hero-module.vue';
import ContentImageBoxModule from '@/src/content/components/content-modules/image-box-module/image-box-module.vue';
import ContentLinkBoxesModule from '@/src/content/components/content-modules/link-boxes-module/link-boxes.vue';
import ContentLinkPortfolioModule from '@/src/content/components/content-modules/link-portfolio-module/link-portfolio.vue';
import LoginModule from '@/src/content/components/content-modules/login-module/login-module.vue';
import ContentNewsletterModule from '@/src/content/components/content-modules/newsletter-module/newsletter-module.vue';
import NotFoundModule from '@/src/content/components/content-modules/not-found-module/not-found-module.vue';
import ContentQuoteModule from '@/src/content/components/content-modules/quote-module/quote-module.vue';
import ContentTableModule from '@/src/content/components/content-modules/table-module/table-module.vue';
import ContentTextBoxesModule from '@/src/content/components/content-modules/textboxes-module/text-boxes.vue';
import TwentyThreeVideo from '@/src/content/components/content-modules/twenty-three-video/twenty-three-video.vue';
import ContentTypeformModule from '@/src/content/components/content-modules/typeform-module/typeform-module.vue';
import ContentModuleSection from '@/src/content/layouts/module-section/content-module-section.vue';
import AdobeTargetWrapper from '@/src/core/components/adobe-target-wrapper/adobe-target-wrapper.vue';
import Login from '@/src/core/components/login/login.vue';
import MediaHandler from '@/src/core/components/media-handler/media-handler.vue';
import AccordionItem from '@/src/core/components/ui/accordion/accordion-item/accordion-item.vue';
import Accordion from '@/src/core/components/ui/accordion/accordion.vue';
import ArrowIcon from '@/src/core/components/ui/ani-icons/arrow/arrow-icon.vue';
import ArrowLinkItem from '@/src/core/components/ui/arrow-link-wrapper/arrow-link-item/arrow-link-item.vue';
import ArrowLinkWrapper from '@/src/core/components/ui/arrow-link-wrapper/arrow-link-wrapper.vue';
import BackButton from '@/src/core/components/ui/back-button/back-button.vue';
import BlogInformation from '@/src/core/components/ui/blog-information/blog-information.vue';
import BlogTag from '@/src/core/components/ui/blog-information/blog-tag/blog-tag.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import ContentButton from '@/src/core/components/ui/content-button/content-button.vue';
import Checkbox from '@/src/core/components/ui/form/checkbox/checkbox.vue';
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import Spinner from '@/src/core/components/ui/spinner/spinner.vue';
import FormHandler from '@/src/core/components/util/form-handler/form-handler.vue';
import UploadHandler from '@/src/core/components/assistant/tools/upload/upload-handler/upload-handler.vue';
import UploadOptions from '@/src/core/components/assistant/tools/upload/upload-options/upload-options.vue';
import TargetAlternateGetAccessBanner from '@/src/core/components/adobe-target-wrapper/adobe-target-components/target-alternate-get-access-banner/target-alternate-get-access-banner.vue';
import TargetSmallImageCampaign from '@/src/core/components/adobe-target-wrapper/adobe-target-components/target-small-image-campaign/target-small-image-campaign.vue';
import TargetProductLists from '@/src/core/components/adobe-target-wrapper/adobe-target-components/target-product-lists/target-product-lists.vue';
import TargetUmbracoComponent from '@/src/core/components/adobe-target-wrapper/adobe-target-components/target-umbraco-component/target-umbraco-component.vue';

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.directive('clickOutside', vClickOutside);
app.use(router);
app.use(UiTests, {
  showTestAttr: getEnv('VUE_APP_UITEST'),
});
app.component('Svgicon', svgicon);
app.component('VueDatePicker', VueDatePicker);

WhatInputHandler.init();

// TODO: Find a way to load these asynchronously
app.component('ContentAccordionModule', ContentAccordionModule);
app.component('AlphabeticalLinkList', AlphabeticalLinkList);
app.component('ContentArticleModule', ContentArticleModule);
app.component('BlogContentWrapper', BlogContentWrapper);
app.component('BlogPostModule', BlogPostModule);
app.component('BlogFilters', BlogFilters);
app.component('BlogPostOverview', BlogPostOverview);
app.component('ContentBoxCtaModule', ContentBoxCtaModule);
app.component('ContentBoxListModule', ContentBoxListModule);
app.component('ContentBoxSpotModule', ContentBoxSpotModule);
app.component('ContentComparisonBoxesModule', ContentComparisonBoxesModule);
app.component('ContactFormModule', ContactFormModule);
app.component('ContactInfoModule', ContactInfoModule);
app.component('ConvertCalculatorModule', ConvertCalculatorModule);
app.component('ContentCtaBoxModule', ContentCtaBoxModule);
app.component('DownloadListModule', DownloadListModule);
app.component('EventListModule', EventListModule);
app.component('ContentFullWidthMediaModule', ContentFullWidthMediaModule);
app.component('ContentHeroModule', ContentHeroModule);
app.component('ContentImageBoxModule', ContentImageBoxModule);
app.component('ContentLinkBoxesModule', ContentLinkBoxesModule);
app.component('ContentLinkPortfolioModule', ContentLinkPortfolioModule);
app.component('LoginModule', LoginModule);
app.component('ContentNewsletterModule', ContentNewsletterModule);
app.component('NotFoundModule', NotFoundModule);
app.component('ContentQuoteModule', ContentQuoteModule);
app.component('ContentTableModule', ContentTableModule);
app.component('ContentTextBoxesModule', ContentTextBoxesModule);
app.component('TwentyThreeVideo', TwentyThreeVideo);
app.component('ContentTypeformModule', ContentTypeformModule);
app.component('ContentModuleSection', ContentModuleSection);
app.component('AdobeTargetWrapper', AdobeTargetWrapper);
app.component('Login', Login);
app.component('MediaHandler', MediaHandler);
app.component('AccordionItem', AccordionItem);
app.component('Accordion', Accordion);
app.component('ArrowIcon', ArrowIcon);
app.component('ArrowLinkItem', ArrowLinkItem);
app.component('ArrowLinkWrapper', ArrowLinkWrapper);
app.component('BackButton', BackButton);
app.component('BlogInformation', BlogInformation);
app.component('BlogTag', BlogTag);
app.component('VButton', VButton);
app.component('ContentButton', ContentButton);
app.component('Checkbox', Checkbox);
app.component('VSelect', VSelect);
app.component('VTextField', VTextField);
app.component('VTextarea', VTextarea);
app.component('Spinner', Spinner);
app.component('FormHandler', FormHandler);
app.component('UploadHandler', UploadHandler);
app.component('UploadOptions', UploadOptions);
app.component('TargetAlternateGetAccessBanner', TargetAlternateGetAccessBanner);
app.component('TargetSmallImageCampaign', TargetSmallImageCampaign);
app.component('TargetProductLists', TargetProductLists);
app.component('TargetUmbracoComponent', TargetUmbracoComponent);

app.mount('#app');
