<template>
  <div class="product__detail">
    <p class="product__detail__label">{{ textK('UI_PRODUCT_CERTIFICATE') }}</p>
    <p
      v-if="product"
      class="product__detail__value product__detail__value--certificate"
      :title="certificate"
    >
      {{ certificate }}
    </p>
    <SkeletonLoader v-else size="0" />
  </div>
</template>

<script lang="ts" setup>
import SkeletonLoader from '@/src/core/components/ui/skeleton-loader/skeleton-loader.vue';
import useText from '@/src/core/hooks/useText';
import { Product } from '@/src/core/types/api';
import { Procurement } from '@/src/core/types/interfaces';
import { useProductCertificate } from '@/src/market/services/product-rules';
import { computed } from 'vue';

interface Props {
  product: Product;
  procurement?: Procurement;
}

const props = defineProps<Props>();
const textK = useText();
const product = computed(() => props.product);
const procurement = computed(() => props.procurement);

const certificate = computed(() => {
  return useProductCertificate(product.value, procurement.value);
});
</script>
