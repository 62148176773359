import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { InteractionEventBus } from '@/src/core/utils/interaction-event-bus';
import { safeSetTimeout } from '@/src/core/utils/timing';
import { Ref, onBeforeUnmount, onMounted, ref } from 'vue';

interface TimerOptions {
  onComplete: () => void;
  time: number;
  resetOnInteraction?: boolean;
}

export function useTimer(options: TimerOptions): Ref<number | undefined> {
  const timer = ref<number | undefined>(undefined);
  const authenticationStore = useAuthenticationStore();

  const startTimer = (time?: number) => {
    timer.value = safeSetTimeout(options.onComplete, time ? time : options.time);
  };

  const resetTimer = () => {
    if (timer.value) {
      clearTimeout(timer.value);
      timer.value = undefined;
      authenticationStore.saveLastActivityTimestamp();
      startTimer();
    }
  };

  const handleWindowFocus = () => {
    if (authenticationStore.getRemainingActivityTime(options.time) <= 0) {
      options.onComplete();
    } else {
      clearTimeout(timer.value);
      timer.value = undefined;
      startTimer(authenticationStore.getRemainingActivityTime(options.time));
    }
  };

  const handleWindowBlur = () => {
    if (timer.value) {
      clearTimeout(timer.value);
      timer.value = undefined;
    }
  };

  onMounted(() => {
    // wait 300ms to prevent the modal from flashing before splash screen
    setTimeout(() => {
      startTimer();
    }, 300);
    if (options.resetOnInteraction) {
      InteractionEventBus.$on('request', resetTimer);
      window.addEventListener('focus', handleWindowFocus);
      window.addEventListener('blur', handleWindowBlur);
    }
  });

  onBeforeUnmount(() => {
    if (timer.value) {
      clearTimeout(timer.value);
      timer.value = undefined;
    }
    if (options.resetOnInteraction) {
      InteractionEventBus.$off('request', resetTimer);
      window.removeEventListener('focus', handleWindowFocus);
      window.removeEventListener('blur', handleWindowBlur);
    }
  });

  return timer;
}
