<template>
  <SellerConversationIndication
    :author="authorName"
    :author-type="message.AuthorType"
    class="seller-conversation-item-wrapper"
    :class="message.AuthorType && message.AuthorType.toLowerCase()"
    v-if="message"
  >
    <div class="seller-conversation-item typeset">
      <div class="seller-conversation-item__name">
        {{ authorName }}
        <span class="seller-conversation-item__time">{{ date(message.CreatedDate) }}</span>
      </div>
      <div class="seller-conversation-item__message" v-html="body"></div>
      <MQ :query="{ minWidth: breakpointWidth.Rabbit }">
        <div
          class="seller-conversation-download"
          v-if="message.AttachmentInfos && message.AttachmentInfos.length > 0"
        >
          <SellerConversationDownloadItem
            v-for="attachment of message.AttachmentInfos"
            :key="attachment.Id"
            :attachment="attachment"
            :offer-id="offerId"
            :thread-id="threadId"
            :order-code="orderCode"
            :consignment-code="consignmentCode"
            :message-id="message.Id"
            :is-quote="isQuote"
          />
        </div>
      </MQ>
    </div>
  </SellerConversationIndication>
</template>

<style lang="scss" src="./seller-conversation-item.scss" scoped />

<script lang="ts">
import { Vue, Component, Prop, toNative } from 'vue-facing-decorator';
import { BreakpointWidth } from '@/src/core/settings/media-queries.model';
import { MarketplaceMessage } from '@/src/core/types/api';
import { DateFormat } from '@/src/core/utils/dates';
import SellerConversationIndication from '@/src/market/components/seller/seller-conversation/seller-conversation-indication/seller-conversation-indication.vue';
import SellerConversationDownloadItem from '@/src/market/components/seller/seller-conversation/seller-conversation-item/seller-conversation-download-item/seller-conversation-download-item.vue';
import { useQuotesStore } from '@/src/market/stores/quotes';
import { useUserStore } from '@/src/profile/stores/user';
import Media from '@/src/core/components/util/media-query/media-query.vue';

@Component({
  components: {
    MQ: Media,
    SellerConversationIndication,
    SellerConversationDownloadItem,
  },
})
export class SellerConversationItem extends Vue {
  @Prop() public message: MarketplaceMessage;
  @Prop() public offerId: string;
  @Prop() public threadId: string;
  @Prop() public consignmentCode: string;
  @Prop() public orderCode: string;
  @Prop({ default: false }) public isQuote: boolean;

  public date = DateFormat;
  public breakpointWidth = BreakpointWidth;
  public userStore = useUserStore();
  public quotesStore = useQuotesStore();

  public get body() {
    return this.message.Body.replace(/(?:\r\n|\r|\n)/g, '<br/>');
  }
  public get authorName() {
    if (this.isQuote) {
      if (this.message.AuthorType.toLowerCase() === 'customer_user') {
        return this.userStore.company.Name;
      }
      if (
        this.message.AuthorType.toLowerCase() === 'shop_user' ||
        this.message.AuthorType.toLowerCase() === 'operator_user'
      ) {
        return (
          this.quotesStore.currentQuoteView.MasterShopName +
          ', ' +
          this.quotesStore.currentQuoteView.LocationDisplayName
        );
      }
    }
    return this.message.AuthorName;
  }
}

export default toNative(SellerConversationItem);
</script>
