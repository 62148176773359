import { CartModification, OrderSearchPage } from '@/src/core/apim';
import { Order } from '@/src/core/types/api';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import {
  getCartData,
  getMaxQuantityNotification,
  handleCustomAction,
  orderAddToCardNotification,
} from '@/src/market/services/legacy-mapping-cart-modification';
import { Notification } from '@/src/core/services/notification-mapping';
import { MASTERSHOP_ID } from '@/src/market/settings/mastershop';

/**
 * Maps a SAP CC plants response to an expanded version of the plants.
 *
 * @param {PlantsStock} plants - A SAP CC plants response.
 * @returns {LegacyPlants} - An expanded version of the plant.
 */
export const getOrderSearchPageWithMapping = (orderSearchPage: OrderSearchPage) => {
  const count = orderSearchPage.pagination?.totalResults;
  const page = orderSearchPage.pagination?.currentPage;
  const totalPages = orderSearchPage.pagination?.totalPages;
  const orders = orderSearchPage.orders?.map((order) => ({
    ...order,
    totalPrice: {
      ...order.totalPrice,
      isAsmHidden: false,
    },
    subTotal: {
      ...order.subTotal,
      isAsmHidden: false,
    },
    deliveryCost: {
      ...order.deliveryCost,
      isAsmHidden: false,
    },
    priorityInfo: {
      airframe: order.priorityInfo?.airframe ?? '',
      priority: {
        code: order.priorityInfo?.priority?.code ?? '',
        name: order.priorityInfo?.priority?.name ?? '',
      },
    },
    masterShopId: order.masterShop?.id ?? '',
    isSatairOrder: order.masterShop?.id === MASTERSHOP_ID.SATAIR,
    supplierDisplay: order.masterShop?.name,
  }));

  const toReturn = {
    ...orderSearchPage,
    count,
    page,
    totalPages,
    orders,
  };

  return {
    Data: objectKeysToPascalCaseRecursive(toReturn, [], ['AirbusExtendedOrderDetails']),
  };
};

export const addOrderToCartData = (
  cartModifications?: CartModification[],
  currentOrder?: Order,
) => {
  const notifications: Notification[] = [];
  const cartData = getCartData(cartModifications);

  if (currentOrder?.PurchaseOrderNumber) {
    cartData.modificationName = currentOrder.PurchaseOrderNumber;
  }

  const addToCartNotification = orderAddToCardNotification(cartData);
  const customAction = handleCustomAction(cartData);
  const maxQuantityNotification = getMaxQuantityNotification(
    cartData,
    currentOrder?.Entries.length,
    true,
  );

  if (maxQuantityNotification) {
    notifications.push(maxQuantityNotification);
  }

  if (addToCartNotification) {
    notifications.push(addToCartNotification);
  }

  return {
    Data: { ...objectKeysToPascalCaseRecursive(cartData) },
    Notification: objectKeysToPascalCaseRecursive(notifications) || [],
    ...(customAction && { CustomActions: objectKeysToPascalCaseRecursive([customAction]) }),
  };
};

export type CartModificationResponseData = ReturnType<typeof addOrderToCartData>;
