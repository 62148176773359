<template>
  <StandardDialogModal class="modal-export-lease-orders" :size="uiSize.Medium">
    <template #header>
      <h3>
        {{ textK('UI_SERVICE_ORDERS_LEASE_EXPORT_MODAL_TITLE') }}
      </h3>
    </template>

    <template #body>
      <p>
        <span v-html="descriptionPartOne"></span>
        (
        <span v-if="isFetchingLeaseOrders" class="modal-export-lease-orders__loader"
          ><DotLoader :loading="true" to-color="#000"
        /></span>
        <span v-else
          ><b>{{ totalCount }}</b></span
        >
        )
        <span v-html="descriptionPartTwo"></span>
      </p>
    </template>

    <template #actions>
      <VButton
        @click="exportOrders('CSV')"
        :loading="isDownloadCsvBusy || isFetchingLeaseOrders"
        :disabled="isDownloadExcelBusy"
        :type="uiVariant.Secondary"
        class="modal-export-lease-orders__button"
        >{{ textK('UI_SERVICE_ORDERS_LEASE_EXPORT_MODAL_EXPORT_CSV') }}</VButton
      >
      <VButton
        @click="exportOrders('EXCEL')"
        :loading="isDownloadExcelBusy || isFetchingLeaseOrders"
        :disabled="isDownloadCsvBusy"
        :type="uiVariant.Secondary"
        class="modal-export-lease-orders__button"
        >{{ textK('UI_SERVICE_ORDERS_LEASE_EXPORT_MODAL_EXPORT_EXCEL') }}</VButton
      >
    </template>
  </StandardDialogModal>
</template>

<style src="./modal-export-lease-orders.scss" lang="scss" scoped></style>

<script setup lang="ts">
import { OrderApi } from '@/src/core/api';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { TextKey } from '@/src/core/services/text-key';
import { useDownloadsStore } from '@/src/core/stores/downloads';
import { ServiceOrderRequestParams } from '@/src/core/types/api';
import { toHybrisFormat } from '@/src/core/utils/dates';
import { useLeaseOrdersStore } from '@/src/market/stores/lease-orders';
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';

const textK = useText();
const leaseOrdersStore = useLeaseOrdersStore();
const downloadsStore = useDownloadsStore();
const { filters } = storeToRefs(leaseOrdersStore);

const uiSize = UiSize;
const uiVariant = UiVariant;

const isDownloadExcelBusy = ref<boolean>(false);
const isDownloadCsvBusy = ref<boolean>(false);
const descriptionPartOne = ref<string>('');
const descriptionPartTwo = ref<string>('');

const totalCount = computed(() => {
  return leaseOrdersStore.orders.length;
});

const isFetchingLeaseOrders = computed(() => {
  return totalCount.value <= 0;
});

const params = computed<ServiceOrderRequestParams>(() => {
  return {
    ...filters.value,
    fromDate: filters.value.fromDate || toHybrisFormat(dayjs().subtract(12, 'month')),
    toDate: filters.value.toDate || toHybrisFormat(dayjs()),
    status: filters.value.status || 'ALL',
  };
});

const exportOrders = async (format: 'EXCEL' | 'CSV') => {
  if (format == 'EXCEL') {
    isDownloadExcelBusy.value = true;
  } else {
    isDownloadCsvBusy.value = true;
  }

  await downloadsStore.downloadFile({
    url: OrderApi.ExportLeaseOrders,
    method: 'POST',
    params: { fileFormat: format },
    data: params.value,
  });

  if (format == 'EXCEL') {
    isDownloadExcelBusy.value = false;
  } else {
    isDownloadCsvBusy.value = false;
  }
};

onMounted(() => {
  const text = TextKey('UI_SERVICE_ORDERS_LEASE_EXPORT_MODAL_DESCRIPTION').split('[[number]]');
  descriptionPartOne.value = text[0];
  descriptionPartTwo.value = text[1];
});
</script>
