<template>
  <ul class="header-tools" ref="wrapper">
    <li class="header-tools__item">
      <div class="header-tools__item__profile" v-if="isAuthenticated">
        <HeaderToolButton
          v-if="!asm.Active"
          icon="ui-profile"
          v-ui-test="'header-tools-profile-menu'"
          ref="profile"
          :is-active="isDropdownActive('profile')"
          :variant="uiVariant.Secondary"
          @click="toggleNavigationDropdown('profile')"
        >
          <template #additional>
            <span class="profile-name" v-ui-test="'header-user-name'" data-hj-suppress>{{
              user && user.Name
            }}</span>
            <span class="profile-company" v-ui-test="'header-company'">{{
              company && company.Name
            }}</span>
          </template>
        </HeaderToolButton>
        <HeaderToolButton
          v-else
          :key="'profile' + isAuthenticated"
          class="impersonating"
          icon="ui-profile"
          :is-asm="true"
          v-ui-test="'header-tools-profile-menu'"
          ref="profile"
          :is-active="isDropdownActive('profile')"
          :variant="uiVariant.Secondary"
          @click="toggleNavigationDropdown('profile')"
        >
          <template #additional>
            <span class="profile-info">{{ textK('UI_COMMON_IMPERSONATING') }}</span>
            <span class="profile-name" v-ui-test="'header-asm-name'">{{ asm.Name }}</span>
          </template>
        </HeaderToolButton>

        <NavigationDropdown
          dropdown-id="profile"
          :anchor-parent="$refs.wrapper"
          :anchor-element="$refs.profile"
          :size="uiSize.Small"
          :variant="uiVariant.Secondary"
          :lock-scroll="true"
          :force-top="true"
        >
          <ProfileMenu :is-asm="asm.Active" />
        </NavigationDropdown>
      </div>

      <div v-else class="header-tools__item__profile">
        <HeaderToolButton
          :key="'login' + isAuthenticated"
          icon="ui-login"
          v-ui-test="'header-tools-login'"
          @click="openModal({ modalComponent: 'ModalLogin' })"
          :variant="uiVariant.Secondary"
        >
          <template #additional>
            <span class="profile-name">{{ textK('UI_LOGIN_LOG_IN_BUTTON') }}</span>
          </template>
        </HeaderToolButton>
      </div>
    </li>

    <li class="header-tools__item">
      <HeaderToolButton
        v-ui-test="'header-tools-help-menu'"
        icon="ui-help"
        ref="help"
        @click="toggleNavigationDropdown('help')"
        :variant="uiVariant.Default"
        :is-active="isDropdownActive('help')"
      />
      <NavigationDropdown
        dropdown-id="help"
        :variant="uiVariant.Secondary"
        :anchor-parent="$refs.wrapper"
        :anchor-element="$refs.help"
        :size="uiSize.Small"
        :lock-scroll="true"
        :force-top="true"
      >
        <HelpMenu />
      </NavigationDropdown>
    </li>

    <li class="header-tools__item">
      <HeaderToolButton
        v-ui-test="'header-tools-solution-menu'"
        :variant="isDropdownActive('solutions') ? uiVariant.Secondary : uiVariant.Primary"
        icon="ui-apps"
        @click="toggleNavigationDropdown('solutions')"
        :is-active="isDropdownActive('solutions')"
      />
      <NavigationDropdown dropdown-id="solutions" :lock-scroll="true">
        <SolutionMenu />
      </NavigationDropdown>
      <SolutionHelper></SolutionHelper>
    </li>
  </ul>
</template>

<style src="./header-tools.scss" lang="scss" />

<script lang="ts">
import { Vue, Component, Watch, toNative } from 'vue-facing-decorator';
import HeaderToolButton from '@/src/core/components/header/header-tools/header-tool-button/header-tool-button.vue';
import NavigationDropdown from '@/src/core/components/navigation/navigation-dropdown/navigation-dropdown.vue';
import HelpMenu from '@/src/core/components/navigation/navigation-tools/help-menu/help-menu.vue';
import ProfileMenu from '@/src/core/components/navigation/navigation-tools/profile-menu/profile-menu.vue';
import SolutionHelper from '@/src/core/components/navigation/navigation-tools/solution-menu/solution-helper/solution-helper.vue';
import SolutionMenu from '@/src/core/components/navigation/navigation-tools/solution-menu/solution-menu.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import router from '@/src/core/router';
import { useAdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { Site, useUIStore } from '@/src/core/stores/ui';
import { IASM } from '@/src/core/types/interfaces';
import { useUserStore } from '@/src/profile/stores/user';

@Component({
  components: {
    HeaderToolButton,
    NavigationDropdown,
    SolutionMenu,
    SolutionHelper,
    ProfileMenu,
    HelpMenu,
  },
})
export class HeaderTools extends Vue {
  public textK = useText();
  public openModal = useModal();
  public authenticationStore = useAuthenticationStore();
  public uiStore = useUIStore();
  public uiVariant = UiVariant;
  public uiSize = UiSize;
  public toggleNavigationDropdown = this.uiStore.toggleNavigationDropdown;
  public isDropdownActive = this.uiStore.isNavigationDropdownActive;
  private site = Site;
  public userStore = useUserStore();

  public get isAuthenticated() {
    return this.authenticationStore.isAuthenticated;
  }

  public get user() {
    return this.userStore.currentUser;
  }

  public get company() {
    return this.userStore.company;
  }

  private get isMarket() {
    return this.uiStore.site === this.site.Market;
  }

  protected get asm(): IASM {
    return this.authenticationStore.asm;
  }

  @Watch('isAuthenticated') private authChange(isAuthenticated: boolean) {
    if (isAuthenticated) {
      this.userStore
        .fetchUserProfile()
        .finally(() => useAdobeLaunchTracking().trackLogin(router.currentRoute.value));
    } else {
      // NOTE: is not "reset", it will open when you login again
      this.uiStore.setNavigationDropdownId({ id: null });
    }
  }

  public mounted() {
    if (!this.user?.Uid && this.isAuthenticated) {
      this.userStore.fetchUserProfile();
    }
  }
}

export default toNative(HeaderTools);
</script>
