<template>
  <VSimpleTable
    class="product-details-table"
    :headers="['Export controls', '']"
    v-if="isAuthenticated && hasValues && !isRestricted"
  >
    <tbody>
      <template v-for="data in datas" :key="data.Name">
        <VSimpleTableRow v-if="data.Value" v-ui-test="data.TestTag">
          <VSimpleTableCell :style="{ width: '75%' }">
            {{ data.Name }}
          </VSimpleTableCell>
          <VSimpleTableCell class="align-right">
            {{ data.Value }}
          </VSimpleTableCell>
        </VSimpleTableRow>
      </template>
    </tbody>
  </VSimpleTable>
</template>

<script lang="ts" setup>
import VSimpleTable from '@/src/core/components/ui/simple-table/simple-table.vue';
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { DisplayListItemProduct } from '@/src/core/types/api';
import { useUserStore } from '@/src/profile/stores/user';
import { computed } from 'vue';

interface Props {
  exportControls: DisplayListItemProduct[];
  owningSystem: string;
}

const props = defineProps<Props>();

const authenticationStore = useAuthenticationStore();
const userStore = useUserStore();

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);
const datas = computed(() => (props.exportControls?.length > 0 ? props.exportControls : []));

const hasValues = computed(() => {
  let hasValueCount = 0;
  datas.value.forEach((control) => {
    if (control.Value) {
      hasValueCount++;
    }
  });
  return hasValueCount;
});

const isRestricted = computed(() => !userStore.redCustomer);
</script>
