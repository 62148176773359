<template>
  <a
    :is="!!routeName ? 'a' : 'div'"
    class="market"
    v-ui-test="'logo-link'"
    :href="routeName || ''"
    @click.prevent="closeNavigationDropdown()"
  >
    <svg class="market__logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163 163">
      <path fill="#2eeca5" d="M0 163h163V0H0z" />
      <path fill="#7ef9cb" d="M0 163L163 0H0" />
      <path fill="#fff" d="M66 66h63v63H66z" />
      <path fill="#2e2e2e" d="M34 34h63v63H34z" />
      <path opacity=".5" fill="#fff" d="M66 66h31v31H66z" />
    </svg>
    <span v-if="showLabel" class="market__label" v-ui-test="'market-label'">Market</span>
  </a>
</template>

<style lang="scss" src="./market-header-logo.scss" scoped></style>

<script lang="ts" setup>
import { useUIStore } from '@/src/core/stores/ui';
import { useSearchStore } from '@/src/market/stores/search';
import { useRoute, useRouter } from 'vue-router';

interface Props {
  routeName: string;
  showLabel?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
});

const searchStore = useSearchStore();
const uiStore = useUIStore();
const route = useRoute();
const router = useRouter();

const goTo = () => {
  if (props.routeName === 'main') {
    if (route.path !== '/') {
      router.push({ path: '/' });
    }
  } else if (props.routeName !== route.name) {
    router.push({ name: props.routeName });
    searchStore.clearSearchResult();
  }
};

const closeNavigationDropdown = () => {
  uiStore.setNavigationDropdownId({ id: null });
  if (props.routeName) {
    goTo();
  }
};
</script>
