import { TextKey } from '@/src/core/services/text-key';
import { useTextsStore } from '../stores/texts';
import { DateFormat, DateTimeFormat, TimeFormat } from '../utils/dates';

export default function useTitleTag(value: string, type?: string) {
  const textsStore = useTextsStore();

  const storeReady = () => !textsStore.isBusy;
  switch (type) {
    case 'date':
      return DateFormat(value);
    case 'time':
      return TimeFormat(value);
    case 'dateTime':
      return DateTimeFormat(value);
    default:
      switch (value) {
        case 'AOG':
          return (storeReady() && TextKey('UI_COMMON_AIRCRAFT_ON_GROUND')) || value;
        case 'RTN':
          return (storeReady() && TextKey('UI_COMMON_ROUTINE')) || value;
        case 'WSP':
          return (storeReady() && TextKey('UI_COMMON_WORK_STOPPAGE')) || value;
        case 'USR':
          return (storeReady() && TextKey('UI_COMMON_URGENT_STOCK_REPLENISHMENT')) || value;
        case 'N/A':
          return (storeReady() && TextKey('UI_COMMON_NOT_AVAILABLE')) || value;
        case '-':
          return '';
        default:
          return value;
      }
  }
}
