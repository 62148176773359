<template>
  <div class="product__detail">
    <template v-if="isAuthenticated">
      <p class="product__detail__label">Location</p>
      <transition name="fade" mode="out-in">
        <span v-if="productAdditionalData" class="product__detail__value">
          <span
            class="search-item-specs__value-additional"
            :class="{ 'item-location-wrapper--loading': !productAdditionalData }"
          >
            <template v-if="showChangeWarehouse">
              <span class="search-item-specs__link" :title="warehouse">
                {{ warehouse }}
              </span>
              <a
                class="link"
                v-ui-test="'show-change-warehouse-modal'"
                @click.stop="showWarehouseModal($event)"
                >{{ locationLinkText }}</a
              >
            </template>
            <template v-else>
              <span class="search-item-specs__link" :title="warehouse">
                {{ warehouse }}
              </span>
            </template>
          </span>
        </span>
        <SkeletonLoader v-else :size="UiSize.Small" />
      </transition>
    </template>
  </div>
</template>

<style lang="scss" src="./product-location.scss" scoped></style>

<script lang="ts" setup>
import { computed } from 'vue';

import SkeletonLoader from '@/src/core/components/ui/skeleton-loader/skeleton-loader.vue';
import { OwningSystem, Product, ProductAvailable } from '@/src/core/types/api';

import { useAdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { TextKey } from '@/src/core/services/text-key';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useModalStore } from '@/src/core/stores/modal';
import { LocationInfo, Procurement } from '@/src/core/types/interfaces';
import { getAirbusPartLocation } from '@/src/market/services/airbus-parts';
import {
  isAirbus,
  isSatair,
  productAdditionalData as productAdditionalDataUtil,
} from '@/src/market/services/product-parts';
import {
  hasNoPrice,
  isBlueOwningSystem as isBlueOwningSystemUtil,
  isPriceVisible,
} from '@/src/market/services/product-rules';
import { useProductFHSDetailsStore } from '@/src/market/stores/product-fhs-details';
import { UiSize } from '@/src/core/components/ui/ui.types';

interface Props {
  product: Product;
  procurement?: Procurement;
  isFhs?: boolean;
}

const props = defineProps<Props>();
const authenticationStore = useAuthenticationStore();
const modalStore = useModalStore();
const fhsDetailsStore = useProductFHSDetailsStore();
const product = computed(() => props.product);
const procurement = computed(() => props.procurement);

const productAdditionalData = computed(() => {
  return productAdditionalDataUtil(product.value.Id, product.value.OwningSystem);
});

const fhsDetailsProcurement = computed(() =>
  fhsDetailsStore.getFhsDetailsByProductIdAndProcurement(
    props.product.Id,
    props.procurement?.procurementType,
  ),
);

const fhsDetailsLocations = computed(() => {
  return fhsDetailsProcurement.value?.locations || [];
});

const warehouse = computed(() => {
  if (props.isFhs) {
    if (fhsDetailsLocations.value.length > 0) {
      return fhsDetailsLocations.value[0];
    }
    return '-';
  }

  if (isSatair(productAdditionalData.value)) {
    return productAdditionalData.value.Warehouse?.Name || '-';
  }

  if (isAirbus(productAdditionalData.value)) {
    return getAirbusPartLocation(procurement.value);
  }

  return '-';
});

const isMarketplace = computed(() => {
  return product.value?.IsMarketplace;
});

const ruleStatusOK = computed(() => {
  return isPriceVisible(productAdditionalData.value, procurement.value);
});

const ruleStatusNoPrice = computed(() => {
  return hasNoPrice(productAdditionalData.value, procurement.value);
});

const showChangeWarehouse = computed(() => {
  if (props.isFhs && fhsDetailsLocations.value.length > 1) {
    return true;
  }

  if (isMarketplace.value || !(ruleStatusOK.value || ruleStatusNoPrice.value)) {
    return false;
  }

  if (isSatair(productAdditionalData.value)) {
    return availabilityList.value.length > 0;
  }

  if (isAirbus(productAdditionalData.value)) {
    // Don't show the link if we only have one location
    return availabilityList.value.length > 1;
  }

  return false;
});

const availabilityList = computed<ProductAvailable[] | LocationInfo[]>(() => {
  if (isSatair(productAdditionalData.value)) {
    return productAdditionalData.value?.Availabilities || [];
  }

  if (isAirbus(productAdditionalData.value)) {
    return procurement.value?.locationInfos ?? [];
  }

  return [];
});

const isAuthenticated = computed(() => {
  return authenticationStore.isAuthenticated;
});

const canCustomizeWarehouse = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return productAdditionalData.value?.EnableCustomizeWarehouse;
  }

  return false;
});

const isBlueOwningSystem = computed(() => {
  return isBlueOwningSystemUtil(product.value.OwningSystem as OwningSystem);
});

const locationLinkText = computed(() => {
  return isBlueOwningSystem.value || ruleStatusNoPrice.value
    ? TextKey('UI_PRODUCT_SEE_ALL_LOCATIONS')
    : TextKey('UI_PRODUCT_CHANGE_LOCATION');
});

const showWarehouseModal = ($event: Event) => {
  $event.stopPropagation();
  if (ruleStatusOK.value || ruleStatusNoPrice.value || props.isFhs) {
    $event.stopPropagation();
    modalStore.showModal({
      modalComponent: 'ModalSelectWarehouse',
      params: {
        offerId: product.value.Id,
        procurement: procurement.value,
        readonly: !canCustomizeWarehouse.value,
        owningSystem: product.value.OwningSystem,
        isFhs: props.isFhs,
        fhsDetailsProcurement: fhsDetailsProcurement.value,
      },
    });

    useAdobeLaunchTracking().interaction({
      action: 'click',
      label: 'Avilability location',
      component: 'link',
      location: 'Search result',
    });
  }
};
</script>
