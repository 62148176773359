<template>
  <button
    class="button"
    ref="buttonRef"
    :disabled="disabled"
    :class="{
      small: size === uiSize.Small,
      medium: size === uiSize.Medium,
      large: size === uiSize.Large,
      auto: size === uiSize.Auto,

      default: type === uiVariant.Default,
      primary: type === uiVariant.Primary,
      secondary: type === uiVariant.Secondary,
      tertiary: type === uiVariant.Tertiary,
      transparent: type === uiVariant.Transparent,
      ghost: type === uiVariant.Ghost,
      link: type === uiVariant.Link,

      'market-site': context === uiContext.ShopSite,
      'content-site': context === uiContext.ContentSite,

      'icon-prepended': $slots.iconPrepend,
      'icon-appended': $slots.iconAppend,

      'is-loading': loading,
    }"
    :type="inputType"
    @click="click($event)"
  >
    <div class="icon icon-prepend" v-if="$slots.iconPrepend">
      <slot name="iconPrepend"></slot>
    </div>

    <span class="button-content" :class="{ 'button-content--hidden': loading }">
      <slot></slot>
    </span>
    <DotLoader
      to-color="#fff"
      class="button-loader"
      v-if="loading"
      :class="{ 'button-loader--visible': loading }"
      :loading="loading"
    >
    </DotLoader>
    <div class="icon icon-append" v-if="$slots.iconAppend">
      <slot name="iconAppend"></slot>
    </div>
  </button>
</template>

<style lang="scss" src="./button.scss" scoped></style>

<script lang="ts">
// Declare the component name to avoid the warning of using a built-in or reserved HTML elements
export default { name: 'VButton' };
</script>

<script lang="ts" setup>
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import { UiContext, UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import { ref } from 'vue';
import { ButtonHTMLAttributes } from 'vue/types/jsx';

interface Props {
  isProp?: string;
  to?: string;
  context?: UiContext;
  size?: UiSize;
  type?: UiVariant;
  inputType?: ButtonHTMLAttributes['type'];
  loading?: boolean;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isProp: 'button',
  to: '',
  context: UiContext.ShopSite,
  size: UiSize.Medium,
  type: UiVariant.Default,
  inputType: 'submit',
  loading: false,
  disabled: false,
});

const buttonRef = ref<HTMLButtonElement>();
const uiContext = UiContext;
const uiSize = UiSize;
const uiVariant = UiVariant;

const emit = defineEmits(['click']);

const click = ($event: Event) => {
  props.loading ? $event.preventDefault() : emit('click', $event);
};

const focus = () => {
  buttonRef.value?.focus();
};

defineExpose({
  focus,
});
</script>
