<template>
  <StandardDialogModal :size="uiSize.Medium" :ui-test-name-header="'modal-box-details-header'">
    <template #header>
      <h3>Box details</h3>
    </template>
    <template #body>
      <div class="modal-box-details__container">
        <div>
          <div v-if="params.boxNumber" class="modal-box-details__section">
            <h4 class="modal-box-details__section--label">Box number</h4>
            <p>{{ params.boxNumber }}</p>
          </div>
          <div v-if="params.packingSheetNumber" class="modal-box-details__section">
            <h4 class="modal-box-details__section--label">Packing sheet number</h4>
            <p>{{ params.packingSheetNumber }}</p>
          </div>
          <div v-if="params.sizeDimentions" class="modal-box-details__section">
            <h4 class="modal-box-details__section--label">Size dimensions</h4>
            <p>{{ params.sizeDimentions }}</p>
          </div>
          <div v-if="params.carrierInfo" class="modal-box-details__section">
            <h4 class="modal-box-details__section--label">Carrier information</h4>
            <p>{{ params.carrierInfo }}</p>
          </div>
          <div v-if="params.batchNumber" class="modal-box-details__section">
            <h4 class="modal-box-details__section--label">Batch number</h4>
            <p>{{ params.batchNumber }}</p>
          </div>
        </div>
        <div>
          <div v-if="params.shipmentNumber" class="modal-box-details__section">
            <h4 class="modal-box-details__section--label">Shipment number</h4>
            <p>{{ params.shipmentNumber }}</p>
          </div>
          <div v-if="params.quantity" class="modal-box-details__section">
            <h4 class="modal-box-details__section--label">Quantity</h4>
            <p>{{ params.quantity }}</p>
          </div>
          <div v-if="params.weight" class="modal-box-details__section">
            <h4 class="modal-box-details__section--label">Weight</h4>
            <p>{{ params.weight }}</p>
          </div>
        </div>
      </div>
    </template>
  </StandardDialogModal>
</template>

<style lang="scss" src="./modal-box-details.scss" scoped></style>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import { useModalStore } from '@/src/core/stores/modal';
import { computed } from 'vue';

interface Params {
  boxNumber: string;
  shipmentNumber: string;
  packingSheetNumber: string;
  quantity: string;
  sizeDimentions: string;
  weight: string;
  carrierInfo: string;
  batchNumber: string;
}

const uiSize = UiSize;
const modalStore = useModalStore();

const params = computed(() => {
  return modalStore.params as Params;
});
</script>
