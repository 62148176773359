<template>
  <div ref="detailsWrapper" class="seller-details-wrapper">
    <div v-if="!isBusy && mastershop" class="seller-details-wrapper-wrapper">
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInTranslate"
        leave-active-class="animated fadeOutTranslate"
        mode="out-in"
      >
        <SellerDetailsContent :mastershop="mastershop" :marketplace-shops="marketplaceShops" />
      </transition>
    </div>
    <div v-else class="seller-details-wrapper-wrapper">
      <SellerDetailsSkeleton />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getRouteParamAsString } from '@/src/core/utils/router';
import SellerDetailsContent from '@/src/market/components/seller/seller-details/seller-details-content/seller-details-content.vue';
import SellerDetailsSkeleton from '@/src/market/components/seller/seller-details/seller-details-skeleton/seller-details-skeleton.vue';
import { useSellerDetailsStore } from '@/src/market/stores/seller-details';
import { storeToRefs } from 'pinia';
import { computed, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';

const sellerDetailsStore = useSellerDetailsStore();
const { mastershop, marketplaceShops } = storeToRefs(sellerDetailsStore);
const route = useRoute();

const isBusy = computed(() => {
  return sellerDetailsStore.isBusy;
});

onMounted(async () => {
  const id = getRouteParamAsString(route.params.sellerId);
  await sellerDetailsStore.fetchMastershopById({ id });
});

onUnmounted(() => {
  sellerDetailsStore.reset();
});
</script>
