<template>
  <VSelect
    :disabled="disabled"
    :required="required"
    :type="type"
    :options-skeleton="countries.length === 0"
    :label="textK('UI_COMMON_COUNTRY')"
    :placeholder="modelValue"
    :is-input-field="false"
    :options="countries"
    option-label="CountryName"
    :modelValue="
      countries
        .map((country) => {
          return country.CountryCode;
        })
        .indexOf(modelValue)
    "
    @update:modelValue="$emit('update:modelValue', countries[$event].CountryCode)"
    @valid="$emit('valid', $event)"
    @click="fetch()"
  />
</template>

<script lang="ts" setup>
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useTextsStore } from '@/src/core/stores/texts';
import { computed } from 'vue';

interface Props {
  modelValue?: string;
  disabled?: boolean;
  type: UiVariant;
  required?: boolean;
}

withDefaults(defineProps<Props>(), {
  disabled: false,
  required: false,
});

const textK = useText();
const textStore = useTextsStore();

const countries = computed(() => {
  return textStore.countries;
});

const fetch = () => {
  if (countries.value.length <= 0) {
    textStore.fetchCountries();
  }
};
</script>
