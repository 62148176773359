export interface ErrorModule {
  hasError?: boolean;
  fatalError?: boolean;
  errorDescription?: string;
}

export const defaultError: ErrorModule = {
  hasError: true,
  fatalError: true,
};
