import { UmbracoApi } from '@/src/core/api';
import { HttpResponse } from '@/src/core/apim';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { AxiosResponse } from 'axios';

export type ResponseWithRetryAttributes<T> = (
  | Promise<AxiosResponse<any>>
  | Promise<HttpResponse<T>>
) & {
  shouldLogOut?: boolean;
  retryRequest?: () => Promise<ResponseWithRetryAttributes<T>>;
};

export const intercepTokenRefresh = async () => {
  const authenticationStore = useAuthenticationStore();
  try {
    await authenticationStore.refreshAccessToken();
    return Promise.resolve(true);
  } catch (error) {
    return Promise.reject(error);
  }
};

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  resp: ResponseWithRetryAttributes<any>;
}

let refreshAndRetryQueue: RetryQueueItem[] = [];
let isRefreshTokenInProgress = false;
export const generateRefreshTokenValidator = () => {
  const interceptAndRefreshToken = async <T>(
    error: AxiosResponse<any> | HttpResponse<T>,
    resp: ResponseWithRetryAttributes<any>,
    url?: string,
  ) => {
    const authenticationStore = useAuthenticationStore();

    if (
      error.status === 401 &&
      authenticationStore.isAuthenticated &&
      url !== UmbracoApi.getRefreshToken
    ) {
      if (!isRefreshTokenInProgress) {
        try {
          isRefreshTokenInProgress = true;

          await authenticationStore.refreshAccessToken();
          refreshAndRetryQueue.forEach(async ({ resp, resolve, reject }) => {
            if (resp.retryRequest) {
              resp.retryRequest().then(resolve).catch(reject);
            }
          });
          // Clear the queue
          refreshAndRetryQueue = [];
          return resp.retryRequest && resp.retryRequest();
        } catch (error) {
          authenticationStore.doLogout();
          return Promise.reject(error);
        } finally {
          isRefreshTokenInProgress = false;
        }
      }

      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({
          resp,
          resolve,
          reject,
        });
      });
    }

    return Promise.reject(error);
  };

  return interceptAndRefreshToken;
};
