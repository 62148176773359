<template v-if="mounted">
  <div>
    <Teleport to="#main-navigation" :class="{ 'navigation-dropdown--above-navigation': forceTop }">
      <transition name="fadeUp">
        <div
          v-ui-test="'navigation-dropdown'"
          class="navigation-dropdown"
          v-if="show"
          @click="hide()"
          :class="{
            'navigation-dropdown--primary': UiVariant.Primary === variant,
            'navigation-dropdown--secondary': UiVariant.Secondary === variant,
            'navigation-dropdown--large': UiSize.Large === size,
            'navigation-dropdown--small': UiSize.Small === size,
            'navigation-dropdown--above-navigation': forceTop,
          }"
          :style="position"
        >
          <div class="navigation-dropdown__wrapper" @click.stop>
            <slot></slot>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="show" class="navigation-dropdown__bg" @click="hide()"></div>
      </transition>
    </Teleport>
  </div>
</template>

<style lang="scss" src="./navigation-dropdown.scss" />

<script lang="ts" setup>
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import { BreakpointWidth } from '@/src/core/settings/media-queries.model';
import { useScrollLockStore } from '@/src/core/stores/scroll-lock';
import { useUIStore } from '@/src/core/stores/ui';
import { ResizeEventBus } from '@/src/core/utils/resize-event-bus';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

interface Props {
  dropdownId: string | number | null;
  size?: UiSize;
  variant?: UiVariant;
  lockScroll: boolean;
  forceTop?: boolean;
  anchorElement?: any; // TODO: Fix type
  anchorParent?: HTMLElement;
}

const props = withDefaults(defineProps<Props>(), {
  dropdownId: null,
  size: UiSize.Large,
  variant: UiVariant.Default,
  lockScroll: false,
  forceTop: false,
  anchorElement: undefined,
  anchorParent: undefined,
});

const scrollLockStore = useScrollLockStore();
const uiStore = useUIStore();
const position = ref({});
const mounted = ref(false);

const show = computed(() => {
  return currentDropdownId.value === props.dropdownId;
});

const currentDropdownId = computed(() => {
  return uiStore.navigationDropdownId;
});

const calcPosition = () => {
  const windowWidth = window.innerWidth;

  if (props.size === UiSize.Small && BreakpointWidth.Mouse < window.innerWidth) {
    const element = props.anchorElement?.$el;
    const elementPosition = element?.getBoundingClientRect();
    const elementParent = props.anchorParent;

    if ((elementPosition?.left || 0) > windowWidth / 2) {
      position.value = {
        right:
          (elementParent?.getBoundingClientRect()?.right || 0) -
          (elementPosition?.right || 0) +
          'px',
      };
    } else {
      position.value = {
        left:
          (elementPosition?.left || 0) - (elementParent?.getBoundingClientRect()?.left || 0) + 'px',
      };
    }
  } else {
    position.value = {};
  }
};

const hide = () => {
  ResizeEventBus.$off('resize', calcPosition);
  uiStore.setNavigationDropdownId({ id: null });
};

watch(
  () => show.value,
  (show: boolean) => {
    if (show) {
      calcPosition();
      ResizeEventBus.$on('resize', calcPosition);

      if (props.lockScroll) {
        scrollLockStore.scrollLock({ id: 'navigationDropdown' });
      }
    } else {
      scrollLockStore.removeScrollLock({ id: 'navigationDropdown' });
    }
  },
);
onMounted(() => {
  mounted.value = true;
});

onUnmounted(() => {
  scrollLockStore.removeScrollLock({ id: 'navigationDropdown' });
});
</script>
