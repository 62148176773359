// tslint:disable
import { apim } from '@/src/core/apim';
import { getEnv } from '@/src/core/services/environment';
import { toLegacyResponse } from '@/src/core/services/legacy-response-utils';
import { legacyErrorHandler } from '@/src/market/services/error-notification-handling';
import { toLegacyDownloadResponse } from '@/src/market/services/legacy-download-response';
import { getFileNameFromHeaders } from '@/src/market/services/legacy-mapping-filename-headers';
import { InitialProvisioningDocumentListRequest } from '@/src/core/apim/spec';
import { AxiosRequestConfig } from 'axios';
import { SATAIR_BASESITE_ID } from '@/src/market/settings/apim';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class PlanningApi {
  public static InitialProvisioningDocuments = `${baseUrl}/api/documents/initialprovisioning`;
  public static InitialProvisioningDocumentDownload = `${baseUrl}/api/documents/initialprovisioning/download`;

  public static async handleInitialProvisioningDocuments() {
    try {
      const resp = await apim.getAllIntialProvisioningDocuments(SATAIR_BASESITE_ID);

      return toLegacyResponse(resp, {
        Data: resp.data,
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleInitialProvisioningDocumentDownload(settings: AxiosRequestConfig) {
    const request: InitialProvisioningDocumentListRequest = {
      codes: settings.data?.codes,
    };

    try {
      const resp = await apim.downloadBillingDocuments(SATAIR_BASESITE_ID, request);
      const fileName = getFileNameFromHeaders(resp.headers) || undefined;
      const documentsResponse = await toLegacyDownloadResponse(resp, fileName);

      return toLegacyResponse(resp, {
        Data: documentsResponse,
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }
}
