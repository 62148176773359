<template>
  <form @submit.prevent="closeModal" class="cartchanged-modal">
    <StandardDialogModal
      :locked-modal="true"
      :has-cancel-button="false"
      :has-close-button="true"
      :size="uiSize.Medium"
    >
      <template #header>
        <div class="typeset">
          <h3>{{ textK('UI_CART_CART_CHANGED_HEADER') }}</h3>
        </div>
      </template>
      <template #body>
        <div class="inner-body">
          {{ isCart ? textK('UI_CART_CART_CHANGED_BODY') : textK('UI_CART_CHECKOUT_CHANGED_BODY') }}
        </div>
      </template>
      <template #actions>
        <VButton
          :type="uiVariant.Secondary"
          v-ui-test="'cartchanged-tocart-button'"
          @click="goToCart"
          v-if="!isCart"
          >{{ textK('UI_COMMON_GO_TO_CART') }}</VButton
        >
        <VButton
          :type="uiVariant.Default"
          v-ui-test="'cartchanged-continue-button'"
          @click="closeModal"
          >{{ textK('UI_COMMON_CONTINUE') }}</VButton
        >
      </template>
    </StandardDialogModal>
  </form>
</template>

<style lang="scss" src="./ModalCartChanged.scss" scoped></style>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { useCartStore } from '@/src/market/stores/cart';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const textK = useText();
const modalStore = useModalStore();
const cartStore = useCartStore();
const route = useRoute();
const router = useRouter();
const uiSize = UiSize;
const uiVariant = UiVariant;
const isCart = ref(false);

const closeModal = () => {
  modalStore.closeModal();
  cartStore.fetchCart({ calculated: true });
};

const goToCart = (e: Event) => {
  e.preventDefault();
  modalStore.closeModal();
  router.push({ name: 'cart' });
};

onMounted(() => {
  if (route.name === 'cart') {
    isCart.value = true;
  }
});
</script>
