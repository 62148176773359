<template>
  <TransitionExpand mode="out-in">
    <div class="faq-search-result" v-if="searchResult.length > 0" :key="1">
      <ul class="faq-search-result__list" @mouseout="hoverItem(-1)">
        <li
          v-for="(result, i) in searchResult"
          :key="i"
          ref="result"
          class="faq-search-result__list__item"
          @mouseover="hoverItem(i)"
        >
          <router-link
            :to="result.Document.Url"
            class="faq-search-result__item"
            :class="{ 'faq-search-result__item--hovered': i === hoveredSearchResult }"
          >
            <span class="faq-search-result__item__title">{{ result.Document.PageName }}</span>
            <VLabel class="faq-search-result__item__category">{{
              result.Document.Category
            }}</VLabel>
          </router-link>
        </li>
      </ul>
    </div>
    <div
      class="faq-search-result"
      v-else-if="searchResult.length === 0 && searchQuery.length > 0"
      :key="2"
    >
      <span class="faq-search-result__no-result">No result</span>
      <!-- TODO Microcopy (Hasan) -->
    </div>
  </TransitionExpand>
</template>

<style lang="scss" src="./faq-search-result.scss" scoped></style>

<script lang="ts" setup>
import { useFaqStore } from '@/src/content/stores/faq';
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import VLabel from '@/src/core/components/ui/label/label.vue';
import { computed, onBeforeUnmount, ref, watch } from 'vue';

const resultRef = ref<HTMLElement | null>(null);
const faqStore = useFaqStore();

const searchResult = computed(() => {
  return faqStore.searchResult || [];
});

const hoveredSearchResult = computed(() => {
  return faqStore.hoveredSearchResult;
});

const searchQuery = computed(() => {
  return faqStore.searchQuery;
});

const hoverItem = (index: number) => {
  faqStore.hoverSearchResult({ Index: index });
};

const checkInView = () => {
  if (resultRef.value && hoveredSearchResult.value >= 0) {
    resultRef.value[hoveredSearchResult.value].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  }
};

watch(hoveredSearchResult, () => {
  checkInView();
});

onBeforeUnmount(() => {
  faqStore.clearFaqSearch();
});
</script>
