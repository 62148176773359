<template>
  <div>
    <div class="input-container">
      <VTextField
        v-if="user.ProvisionedByAirbusWorld"
        v-model="user.airbusMSSOUsername"
        v-ui-test="'profile-details-email'"
        :label="textK('UI_COMMON_USERNAME')"
        :input-type="'email'"
        :type="uiVariant.Secondary"
        :readonly="true"
        :skeleton="isBusy"
      >
      </VTextField>
      <VTextField
        v-else
        v-model="user.Uid"
        v-ui-test="'profile-details-email'"
        :label="textK('UI_COMMON_EMAIL')"
        :input-type="'email'"
        :type="uiVariant.Secondary"
        :readonly="true"
        :skeleton="isBusy"
      >
        <button
          :disabled="isBusy || disabled"
          v-if="user && user.GlobalId === adminUserId"
          @click="changeEmailModal"
          v-ui-test="'profile-change-email-triggermodal'"
          class="button-invisible change-action"
        >
          {{ textK('UI_PROFILE_DETAILS_CHANGE_EMAIL') }}
        </button>
      </VTextField>
    </div>

    <div class="input-container">
      <VTextField
        v-ui-test="'profile-details-password'"
        :input-type="'text'"
        :readonly="true"
        :skeleton="isBusy"
        :type="uiVariant.Secondary"
        :label="textK('UI_COMMON_PASSWORD')"
        :model-value="'********'"
      >
        <template #append v-if="!user.ProvisionedByAirbusWorld">
          <button
            :disabled="isBusy || disabled"
            v-if="user && user.GlobalId === adminUserId"
            @click="changePasswordModal"
            v-ui-test="'profile-change-password-triggermodal'"
            class="button-invisible change-action"
          >
            {{ textK('UI_PROFILE_DETAILS_CHANGE_PASSWORD') }}
          </button>
          <button
            :disabled="isBusy || disabled"
            v-else
            @click="resetPasswordModal"
            v-ui-test="'profile-reset-password-triggermodal'"
            class="button-invisible change-action"
          >
            {{ textK('UI_COMMON_RESETPASSWORD') }}
          </button>
        </template>
      </VTextField>
    </div>
    <div class="input-container">
      <a
        v-if="user.ProvisionedByAirbusWorld && isOpenedFromProfileDetails"
        v-ui-test="'profile-details-change-password'"
        class="button-invisible discrete-link"
        :href="changePasswordLink"
        target="_blank"
      >
        {{ textK('UI_PROFILE_DETAILS_CHANGE_PASSWORD') }}
      </a>
      <button
        v-else-if="!user.ProvisionedByAirbusWorld"
        v-ui-test="'profile-details-delete'"
        class="button-invisible discrete-link"
        :disabled="isBusy || disabled"
        @click="deleteUserModal()"
      >
        {{ textK('UI_PROFILE_DETAILS_DELETE_PROFILE_BUTTON') }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" src="./user-login-details.scss" scoped></style>

<script lang="ts" setup>
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { getEnv } from '@/src/core/services/environment';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useModalStore } from '@/src/core/stores/modal';
import { User } from '@/src/core/types/api';
import { computed } from 'vue';

interface Props {
  user: User;
  isBusy: boolean;
  disabled?: boolean;
  isOpenedFromProfileDetails?: boolean;
}

const textK = useText();
const authenticationStore = useAuthenticationStore();
const modalStore = useModalStore();
const props = defineProps<Props>();

const uiVariant = UiVariant;

const changeEmailModal = () => {
  modalStore.showModal({
    modalComponent: 'ModalChangeEmail',
    params: { Email: props.user?.Email },
  });
};

const changePasswordModal = () => {
  modalStore.showModal({
    modalComponent: 'ModalChangePassword',
    params: { GlobalId: props.user?.GlobalId },
  });
};

const resetPasswordModal = () => {
  modalStore.showModal({
    modalComponent: 'ModalConfirmResetPassword',
    params: props.user,
  });
};

const deleteUserModal = () => {
  modalStore.showModal({
    modalComponent: 'ModalDeleteUserProfile',
    params: props.user,
  });
};

const adminUserId = computed(() => {
  return authenticationStore.getGlobalId;
});

const changePasswordLink = getEnv('VUE_APP_SSO_AUTH_URI');
</script>
