<template>
  <div class="ask-seller-button" v-if="isAuthenticated && isMarketplace">
    <VButton
      :type="uiVariant.Tertiary"
      @click="openModal({ modalComponent: 'ModalSellerConversation', params: { id } })"
    >
      <template #iconPrepend>
        <svgicon name="ui-chat"></svgicon
        ><span v-if="count > 0" class="ask-seller-button__count">{{ count }}</span>
      </template>
      <span class="ask-seller-button__text">{{ textK('UI_SELLER_CONVERSATION_CTA') }}</span>
    </VButton>
  </div>
</template>

<style lang="scss" src="./ask-seller-button.scss" scoped />

<script lang="ts">
import { Vue, Component, Prop, toNative } from 'vue-facing-decorator';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useProductStore } from '@/src/market/stores/product';
import { useSellerConversationStore } from '@/src/market/stores/seller-conversation';

@Component({
  components: {
    VButton,
  },
})
export class AskSellerButton extends Vue {
  @Prop({ default: '' }) public id: string;

  public textK = useText();
  public openModal = useModal();
  public productStore = useProductStore();
  public authenticationStore = useAuthenticationStore();
  public sellerConversationStore = useSellerConversationStore();

  public uiVariant = UiVariant;
  private uiSize = UiSize;

  private get product() {
    return this.productStore.productById(this.id);
  }

  public get count() {
    return this.sellerConversationStore.count;
  }

  public get isAuthenticated() {
    return this.authenticationStore.isAuthenticated;
  }

  public get isMarketplace() {
    return this.product?.IsMarketplace;
  }

  private get priceHidden() {
    return this.product?.PriceHidden;
  }

  public created() {
    this.sellerConversationStore.reset();
    this.sellerConversationStore.getMessages({ id: this.id });
  }
}

export default toNative(AskSellerButton);
</script>
