import { ProductApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { AirbusFHS, AirbusFHSMasterShop, ProductAirbusFHS } from '@/src/core/types/interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';

const PRODUCT_CHUNK_LIMIT = 5;
let queueTimer: number;
const loadThreshold: number = 1500;

export const useProductFHSDetailsStore = defineStore('product-fhs-details', () => {
  const products = ref<ProductAirbusFHS[]>([]);
  const queue = ref<string[]>([]);

  const productQueued = (productId: string) => {
    queue.value.push(productId);
  };

  const queueCleared = () => {
    queue.value = [];
  };

  const isProductInCue = (productId: string) => {
    return queue.value.find((product) => product === productId);
  };

  const productBundleUpdated = (payload: { products: AirbusFHS[]; failed?: boolean }) => {
    payload.products.map((fhs: ProductAirbusFHS) => {
      const hasFailed: object = payload.failed ? { HasFailed: true } : {};
      const fhsData = Object.assign({}, fhs, { IsBusy: false }, hasFailed) as ProductAirbusFHS;

      if (products.value.length <= 0) {
        products.value.push(fhsData);
      } else {
        const productExistOnIndex = products.value.findIndex(
          (product) => product.productId === fhsData.productId,
        );

        if (productExistOnIndex > -1) {
          products.value.splice(productExistOnIndex, 1, fhsData);
        } else {
          products.value.push(fhsData);
        }
      }
    });
  };

  const reset = () => {
    products.value = [];
    queue.value = [];
  };

  const fetchBundle = async () => {
    if (queue.value.length === 0) {
      return;
    }
    // make sure we only send requests bundled by 5
    const chunkBy = PRODUCT_CHUNK_LIMIT;
    const chunkedProducts: string[][] = [];
    const chunkSize = Math.ceil(queue.value.length / chunkBy);

    for (let i = 0; i < chunkSize; i++) {
      chunkedProducts.push(queue.value.splice(0, chunkBy));
    }

    for (const productBundle of chunkedProducts) {
      const { IsSuccess, Data } = await Req({
        method: 'POST',
        url: ProductApi.ProductFHSDetails,
        data: { productIds: productBundle },
      });

      // Pass data every time — even if error
      // This gives the user fail-feedback
      const success = !!Data && IsSuccess;

      productBundleUpdated({
        products: success ? Data.results : productBundle,
        failed: !success,
      });
    }
  };

  const queueAirbusFHS = async (productId: string) => {
    if (isProductInCue(productId)) {
      return;
    }
    clearTimeout(queueTimer);
    const productIndex = products.value.findIndex((product) => product.productId === productId);

    if (productIndex > -1) {
      products.value[productIndex].IsBusy = true;
    }
    productQueued(productId);

    // if we have 5 in cue send the request - otherwise start the timer
    if (queue.value.length >= 5) {
      fetchBundle();
      queueCleared();
    } else {
      // make sure we send the request if the user only have 3 products in view and stops scrolling
      queueTimer = window.setTimeout(() => {
        if (queue.value.length > 0) {
          fetchBundle();
          queueCleared();
        }
      }, loadThreshold);
    }
  };

  const fetchFhsDetails = async (productId: string) => {
    const { IsSuccess, Data } = await Req({
      method: 'POST',
      url: ProductApi.ProductFHSDetails,
      data: { productIds: [productId] },
    });

    // Pass data every time — even if error
    // This gives the user fail-feedback
    const success = !!Data && IsSuccess;

    productBundleUpdated({
      products: success ? Data.results : [productId],
      failed: !success,
    });

    if (IsSuccess) {
      return Data;
    }
  };

  const getFhsDetailsByProductId = (id: string) =>
    products.value.find((product) => product.productId === id)?.fhsDetails;
  const getMasterShopByProductId = (id: string) =>
    products.value.find((product) => product.productId === id)?.masterShop;
  const getFhsDetailsByProductIdAndProcurement = (id: string, procurementType?: string) =>
    getFhsDetailsByProductId(id)?.find((fhs) => fhs.procurementType === procurementType);

  return {
    queue,
    products,
    reset,
    queueAirbusFHS,
    fetchFhsDetails,
    getFhsDetailsByProductId,
    getMasterShopByProductId,
    getFhsDetailsByProductIdAndProcurement,
  };
});
