<template>
  <Component :is="component" />
</template>

<script lang="ts" setup>
import { compile, type Component, computed } from 'vue';

interface Props {
  blogPostContent: HTMLDivElement;
  keyName: number;
}

const props = defineProps<Props>();

const content = computed(() => props.blogPostContent.outerHTML.toString());

const component = computed(
  () => (content.value ? { render: compile(content.value) } : null) as Component,
);
</script>
