<template>
  <VSimpleTable
    class="product-details-table"
    :headers="['Offer details', '']"
    v-if="isAuthenticated && procurement"
  >
    <tbody>
      <VSimpleTableRow v-ui-test="'product-offer-part-condition'" v-if="procurement.partStatusCode">
        <VSimpleTableCell :style="{ width: '75%' }"> Part condition: </VSimpleTableCell>
        <VSimpleTableCell :style="{ width: '25%' }" class="align-right">
          {{ procurement.partStatusCode }}
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow
        v-ui-test="'product-offer-manufacturer-date'"
        v-if="procurement.manufactureDate"
      >
        <VSimpleTableCell :style="{ width: '75%' }"> Manufacture date: </VSimpleTableCell>
        <VSimpleTableCell :style="{ width: '25%' }" class="align-right">
          {{ date(procurement.manufactureDate) }}
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow
        v-ui-test="'product-offer-flying-hours-since-new'"
        v-if="procurement.usedPartInfo && procurement.usedPartInfo.operatingTime"
      >
        <VSimpleTableCell :style="{ width: '75%' }"> Flying hours since new: </VSimpleTableCell>
        <VSimpleTableCell :style="{ width: '25%' }" class="align-right">
          {{ procurement.usedPartInfo.operatingTime }}
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow
        v-ui-test="'product-offer-flying-cycles-since-new'"
        v-if="procurement.usedPartInfo && procurement.usedPartInfo.operatingCycleCount"
      >
        <VSimpleTableCell :style="{ width: '75%' }"> Flying cycles since new: </VSimpleTableCell>
        <VSimpleTableCell :style="{ width: '25%' }" class="align-right">
          {{ procurement.usedPartInfo.operatingCycleCount }}
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow
        v-ui-test="'product-offer-expiry-date'"
        v-if="procurement.partExpirationDate"
      >
        <VSimpleTableCell :style="{ width: '75%' }"> Expiry date: </VSimpleTableCell>
        <VSimpleTableCell :style="{ width: '25%' }" class="align-right">
          {{ date(procurement.partExpirationDate) }}
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow v-ui-test="'product-offer-serial-number'" v-if="procurement.serialNumber">
        <VSimpleTableCell :style="{ width: '75%' }"> Serial number: </VSimpleTableCell>
        <VSimpleTableCell :style="{ width: '25%' }" class="align-right">
          {{ procurement.serialNumber }}
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow
        v-ui-test="'product-offer-universal-serial-tracking-number'"
        v-if="procurement.equipmentNumber"
      >
        <VSimpleTableCell :style="{ width: '75%' }">
          Universal serial tracking number:
        </VSimpleTableCell>
        <VSimpleTableCell :style="{ width: '25%' }" class="align-right">
          {{ procurement.equipmentNumber }}
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow v-ui-test="'product-offer-batch-number'" v-if="procurement.batchNumber">
        <VSimpleTableCell :style="{ width: '75%' }"> Batch number: </VSimpleTableCell>
        <VSimpleTableCell :style="{ width: '25%' }" class="align-right">
          {{ procurement.batchNumber }}
        </VSimpleTableCell>
      </VSimpleTableRow>
    </tbody>
  </VSimpleTable>
</template>
<script setup lang="ts">
import VSimpleTable from '@/src/core/components/ui/simple-table/simple-table.vue';
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { OwningSystem } from '@/src/core/types/api';
import { Procurement, ProductAirbusPart } from '@/src/core/types/interfaces';
import { DateFormat } from '@/src/core/utils/dates';
import { getProcurementByRouteParams } from '@/src/market/services/procurements';
import { isBlueOwningSystem } from '@/src/market/services/product-rules';
import { useAirbusPartStore } from '@/src/market/stores/airbus-part';
import { useProductStore } from '@/src/market/stores/product';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

const date = DateFormat;
const route = useRoute();
const productStore = useProductStore();
const authenticationStore = useAuthenticationStore();
const airbusPartStore = useAirbusPartStore();

const id = computed(() => {
  return props.id;
});

const product = computed(() => {
  return productStore.productById(id.value);
});

const isAirbusProduct = computed(() => {
  return isBlueOwningSystem((product.value?.OwningSystem as OwningSystem) ?? '');
});

const additionalData = computed<ProductAirbusPart | null | undefined>(() => {
  if (product.value && isAirbusProduct.value) {
    return airbusPartStore.airbusPartByOfferId(product.value.Id);
  }

  return null;
});

const isAuthenticated = computed(() => {
  return authenticationStore.isAuthenticated;
});

const procurement = computed(() => {
  if (isAuthenticated.value && additionalData.value && isAirbusProduct.value) {
    const procurement = getProcurementByRouteParams(
      route.params,
      additionalData.value.procurements,
    );

    const properties = [
      'partStatusCode',
      'manufactureDate',
      'usedPartInfo',
      'partExpirationDate',
      'serialNumber',
      'equipmentNumber',
      'batchNumber',
    ];

    if (!checkProcurementOfferProperties(properties, procurement)) {
      return null;
    }

    return procurement;
  }
  return null;
});

const checkProcurementOfferProperties = (
  properties: string[],
  procurement: Procurement | undefined,
) => {
  if (procurement) {
    return properties.some((property) => {
      return property in procurement && procurement[property] !== '';
    });
  }

  return false;
};
</script>
