<template>
  <StandardDialogModal class="modal-quote-email-notification" :size="size" :has-close-button="true">
    <template #header v-if="title">
      <div class="typeset">
        <h3>{{ title }}</h3>
      </div>
    </template>
    <template #body>
      <ModalColumnContainer>
        <ModalColumn>
          <ContentBlock :modal="true" :link-key="params.key" :url="params.url"></ContentBlock>
        </ModalColumn>
      </ModalColumnContainer>
    </template>
    <template #actions>
      <VButton
        v-if="params.withCloseButton"
        input-type="button"
        v-ui-test="'modal-close'"
        @click="close"
        >{{ textK('UI_COMMON_CLOSE') }}</VButton
      >
    </template>
  </StandardDialogModal>
</template>

<script setup lang="ts">
import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import ContentBlock from '@/src/core/components/ui/content-block/content-block.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useLinksStore } from '@/src/core/stores/links';
import { useModalStore } from '@/src/core/stores/modal';
import { IModalLoadContent } from '@/src/core/types/interfaces';
import { computed, ref } from 'vue';

interface IModalLoadContentParams extends IModalLoadContent {
  size: UiSize;
  withCloseButton: boolean;
}

const modalStore = useModalStore();
const linksStore = useLinksStore();
const textK = useText();

const params = ref<Readonly<IModalLoadContentParams>>(modalStore.params as IModalLoadContentParams);

const size = computed(() => {
  return params.value.size;
});

const title = computed(() => {
  return linksStore.getKey(params.value.title as never);
});

const close = () => {
  modalStore.closeModal();
};
</script>
