<template>
  <form @submit.prevent="updateRemark">
    <StandardDialogModal
      :size="uiSize.Medium"
      :has-cancel-button="true"
      :can-submit="isValid"
      :on-submit="updateRemark"
      :ui-test-name-header="'modal-update-remark-header'"
    >
      <template #header>
        <h3>{{ textK('UI_CHECKOUT_REMARK_HEADER_TITLE') }}</h3>
      </template>
      <template #body>
        <p :class="['modal-description', isRequired && 'required']">
          {{ textK('UI_CHECKOUT_REMARK_MODAL_DESCRIPTION') }}
        </p>
        <InlineNotification v-if="isRequired" :type="notificationTypes.Warning">
          {{ textK('UI_CHECKOUT_MSN_REQUIRED_KIT_ENTRY') }}
        </InlineNotification>

        <div class="inner-body">
          <VTextarea
            :required="isRequired"
            :vmax-length="128"
            :type="uiVariant.Secondary"
            :placeholder="' '"
            name="remark"
            v-model="remark"
          >
          </VTextarea>
          <div class="inner-body__placeholder" v-if="remark === ''">
            {{ textK('UI_CHECKOUT_REMARK_PLACEHOLDER') }} <span v-if="isRequired">*</span>
          </div>
        </div>
        <p v-if="hasInvalidCharacter" class="invalid-remark-text">
          Remarks cannot contain the character "/"
        </p>
      </template>
      <template #actions>
        <VButton
          :type="uiVariant.Default"
          v-ui-test="'update-remark-button'"
          input-type="submit"
          :disabled="!isValid"
          :loading="isBusy"
          @click.prevent="updateRemark"
          >Save</VButton
        >
      </template>
    </StandardDialogModal>
  </form>
</template>

<style lang="scss" src="./modal-update-remark.scss"></style>

<script lang="ts">
import { Vue, Component, Watch, toNative } from 'vue-facing-decorator';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';

import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import InlineNotification from '@/src/core/components/ui/inline-notification/inline-notification.vue';
import { useModalStore } from '@/src/core/stores/modal';
import { CartEntry, NotificationTypes } from '@/src/core/types/api';
import { useCartStore } from '@/src/market/stores/cart';
import { useCheckoutStore } from '@/src/market/stores/checkout';

interface RemarkParams {
  cartCode: string;
  entry: CartEntry;
  sellerKey: string;
}

const MAX_REMARK_LENGTH = 128;
const IVNALID_CHARACTER = '/';

@Component({
  components: {
    VTextField,
    VTextarea,
    VButton,
    StandardDialogModal,
    InlineNotification,
  },
})
export class ModalUpdateRemark extends Vue {
  public textK = useText();
  public modalStore = useModalStore();
  public cartStore = useCartStore();
  public checkoutStore = useCheckoutStore();
  public uiVariant = UiVariant;
  public uiSize = UiSize;
  public remark: string = '';
  public notificationTypes = NotificationTypes;

  private get params(): RemarkParams {
    return this.modalStore.params as RemarkParams;
  }
  public get isRequired() {
    return this.params.entry.Product.IsKit;
  }

  private get remarkFromParams() {
    return this.params.entry.AirbusRemarks;
  }

  private get seller() {
    const sellerIndex = this.checkoutStore.sellerGroup.findIndex(
      (seller) => seller.Key === this.params.sellerKey,
    );

    return this.checkoutStore.sellerGroup[sellerIndex];
  }

  public async updateRemark() {
    const response = await this.cartStore.updateRemark({
      cartCode: this.params.cartCode,
      entryNumher: this.params.entry?.EntryNumber?.toString(),
      remarks: this.remark,
    });

    if (response.IsSuccess) {
      this.checkoutStore.updateRemark({
        sellerId: this.params.sellerKey,
        entryId: this.params.entry.Id,
        remark: this.remark,
      });

      this.modalStore.closeModal();
    }
  }

  public get isBusy() {
    return this.cartStore.isBusy;
  }

  public get isValid() {
    return (
      !this.remarkContainsOnlyWhitespaces &&
      this.remark.length <= MAX_REMARK_LENGTH &&
      !this.hasInvalidCharacter
    );
  }

  public get remarkContainsOnlyWhitespaces() {
    const whitespaceRegex = /^\s*$/;
    return this.remark.length > 0 && whitespaceRegex.test(this.remark);
  }

  public get hasInvalidCharacter() {
    return this.remark.includes(IVNALID_CHARACTER);
  }

  @Watch('remarkFromParams', { immediate: true })
  private setRemarkFromParams() {
    if (this.params.entry.AirbusRemarks) {
      this.remark = this.params.entry.AirbusRemarks;
    }
  }
}

export default toNative(ModalUpdateRemark);
</script>
