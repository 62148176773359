import { AirbusInvoice, BillingDocuments } from '@/src/core/types/api';

export const mapAirbusDocuments = (documents: AirbusInvoice[]) => {
  const mappedDocuments: BillingDocuments[] = [];

  documents.forEach((document) => {
    const mappedDocument: BillingDocuments = {
      OrderNumber: document.salesOrderNumber,
      FormattedOrderNumber: document.salesOrderNumber,
      DocumentNumber: document.documentNumber,
      FormattedDocumentNumber: document.documentNumber,
      DocumentType: document.documentType.type,
      DocumentTypeDescription: document.documentType.type,
      CreateDate: document.billingDate,
      PoNumber: document.customerPONumber,
      TotalAmount: {
        CurrencyIso: document.currency,
        FormattedValue: document.total,
        PriceType: '',
        Value: '',
        InfoStatus: '',
        IsAsmHidden: false,
        MinQuantity: 0,
        MaxQuantity: 0,
        FormattedVolumeDiscountPercentage: '',
        VolumeDiscountPercentage: 0,
      },
    };

    mappedDocuments.push(mappedDocument);
  });

  return mappedDocuments;
};
