import type microcopiesScheme from '@/src/../microcopies.json';
import { TextKey } from '@/src/core/services/text-key';
import { MicrocopyTypesWithoutDefaults } from '@/src/core/types/microcopy-types';

type GetMicrocopy = <T extends MicrocopyTypesWithoutDefaults<typeof microcopiesScheme>>(
  input: T,
) => string;

export default function () {
  return TextKey as unknown as GetMicrocopy;
}
