export enum BreakpointWidth {
  Elephant = 2000,
  Rhino = 1800,
  Bear = 1680,
  Lion = 1480,
  Dog = 1330,
  Fox = 1280,
  Cat = 1025,
  Rabbit = 750,
  Mouse = 530,
  Ant = 0,
}
