import { Address } from '@/src/core/types/api';

export class AddressService {
  public static eusRequired(address: Address) {
    return address.EusRequired || address.ExportBlocked;
  }

  public static isDefault(address: Address) {
    return address.DefaultAddress;
  }
}
