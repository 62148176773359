import { MessagingApi } from '@/src/core/api';
import { Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { MarketplaceMessage, MarketplaceMessagingThread, Pagination } from '@/src/core/types/api';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useSellerConversationStore = defineStore('sellerConversation', () => {
  const messages = ref<MarketplaceMessage[]>([]);
  const threadId = ref<string>('');
  const consignmentId = ref<string>('');
  const pagination = ref<Pagination>({} as Pagination);
  const isBusy = ref<boolean>(false);
  const count = ref<number>(0);
  const masterShopName = ref<string>('');
  const locationDisplayName = ref<string>('');
  const size = ref<number>(10);

  const messagesUpdated = (payload: { data: MarketplaceMessagingThread; loadMore?: boolean }) => {
    if (!payload.loadMore) {
      messages.value = [];
    }
    const { Messages, ThreadId, ConsignmentId, MasterShopName, LocationDisplayName } = payload.data;
    count.value = Messages.length;
    messages.value.unshift(...Messages);
    threadId.value = ThreadId;
    consignmentId.value = ConsignmentId;
    masterShopName.value = MasterShopName;
    locationDisplayName.value = LocationDisplayName;
  };

  const paginationUpdated = (payload: Pagination) => {
    pagination.value = Object.assign({}, pagination.value, payload);
  };

  const resetMessages = () => {
    messages.value = [];
  };

  const reset = () => {
    messages.value = [];
    pagination.value = {} as Pagination;
    isBusy.value = false;
    count.value = 0;
    threadId.value = '';
    consignmentId.value = '';
    masterShopName.value = '';
    locationDisplayName.value = '';
    size.value = 10;
  };

  const getMessages = async (payload: { id: string; orderCode?: string }) => {
    isBusy.value = true;
    let url: string = '';
    if (payload.orderCode && payload.id) {
      url = MessagingApi.GetOrderMessages(payload.orderCode, payload.id);
    } else if (payload.id) {
      url = MessagingApi.GetMessages(payload.id);
    }

    const { IsSuccess, Data } = await Req({ url }, new ReqQueue(ReqQueueTypes.Default, 'messages'));

    if (IsSuccess && Data) {
      messagesUpdated({ data: Data, loadMore: false });
      paginationUpdated(Data.Pagination);
    }

    isBusy.value = false;

    return { IsSuccess };
  };

  const getMoreMessages = async (payload: { id: string; orderCode?: string }) => {
    isBusy.value = true;
    const { IsSuccess, Data } = await Req(
      {
        url: MessagingApi.GetMessages(payload.id),
        params: { size: size.value, p: parseInt(pagination.value.CurrentPage, 10) + 1 },
      },
      new ReqQueue(ReqQueueTypes.Default, 'messages'),
    );

    if (IsSuccess && Data) {
      messagesUpdated({ data: Data, loadMore: true });
      paginationUpdated(Data.Pagination);
    }

    isBusy.value = false;

    return { IsSuccess };
  };

  const postMessage = async (payload: { id: string; orderCode?: string; data: FormData }) => {
    isBusy.value = true;

    const { IsSuccess } = await Req(
      {
        url: payload.orderCode
          ? MessagingApi.PostOrderMessage(payload.orderCode, payload.id)
          : MessagingApi.PostMessage(payload.id),
        method: 'POST',
        data: payload.data,
      },
      new ReqQueue(ReqQueueTypes.Default, 'messages'),
    );

    if (IsSuccess) {
      await getMessages({ id: payload.id, orderCode: payload.orderCode });
    }

    isBusy.value = false;

    return { IsSuccess };
  };

  return {
    messages,
    threadId,
    consignmentId,
    pagination,
    isBusy,
    count,
    masterShopName,
    locationDisplayName,
    resetMessages,
    reset,
    getMessages,
    getMoreMessages,
    postMessage,
  };
});
