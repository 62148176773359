import { lowerFirst, upperFirst } from '@/src/core/utils/string';

/**
 * Object Values
 * The Object.values provides the correct type, so we just have this function for consistency for the `keys` and `entries` functions.
 * @see keys
 * @see entries
 * @typed
 */
export const oValues = Object.values as <T extends object>(o: T) => Array<T[keyof T]>;

/**
 * Object Keys
 * The Object.keys method doesn't provide the correct type for the key, hence the creation of this function.
 * @typed
 */
export const oKeys = Object.keys as <T extends object>(o: T) => Array<keyof T>;

/**
 * Object Entries
 * The Object.entries method doesn't provide the correct type for the key, hence the creation of this function.
 * @typed
 */
export const oEntries = Object.entries as <T extends object>(o: T) => Array<[keyof T, T[keyof T]]>;

type Primitive = string | number | boolean | null | undefined;

export type ConvertToPascalCase<T> = T extends Primitive | Function
  ? T
  : T extends Array<infer U>
    ? Array<ConvertToPascalCase<U>>
    : T extends object
      ? {
          [K in keyof T as K extends string ? Capitalize<K> : K]: ConvertToPascalCase<T[K]>;
        }
      : T;

export type ConvertToCamelCase<T> = T extends Primitive | Function
  ? T
  : T extends Array<infer U>
    ? Array<ConvertToCamelCase<U>>
    : T extends object
      ? {
          [K in keyof T as K extends string ? Uncapitalize<K> : K]: ConvertToCamelCase<T[K]>;
        }
      : T;

/**
 * This function clones an object and converts it's keys from CamelCase to PascalCase.
 * @param obj any object
 * @returns clone of object with keys to PascalCase
 * @example `
 * objectKeysToPascalCaseRecursive(obj);
 * `
 */
export const objectKeysToPascalCaseRecursive = <T>(
  obj: T,
  exceptions: string[] = [],
  skipObjectKeys: string[] = [],
): ConvertToPascalCase<T> => {
  if (obj === null || typeof obj !== 'object') {
    return obj as ConvertToPascalCase<T>;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) =>
      objectKeysToPascalCaseRecursive(item, exceptions, skipObjectKeys),
    ) as ConvertToPascalCase<T>;
  }

  const result: any = {};

  for (const [key, value] of Object.entries(obj)) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // Check if the key is in the exceptions array
      const pascalKey = exceptions.includes(key) ? key : upperFirst(key);

      // Check if the key should skip recursion, including array of objects
      if (skipObjectKeys.includes(key)) {
        result[pascalKey] = value; // Assign the object or array as-is without recursion
      } else {
        result[pascalKey] = objectKeysToPascalCaseRecursive(value, exceptions, skipObjectKeys);
      }
    }
  }

  return result as ConvertToPascalCase<T>;
};
/**
 * This function clones an object and converts its keys from PascalCase to camelCase.
 * @param obj any object
 * @returns clone of object with keys in camelCase
 * @example `
 * objectKeysToCamelCaseRecursive(obj);
 * `
 */
export const objectKeysToCamelCaseRecursive = <T>(obj: T): ConvertToCamelCase<T> => {
  if (obj === null || typeof obj !== 'object') {
    return obj as ConvertToCamelCase<T>;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => objectKeysToCamelCaseRecursive(item)) as ConvertToCamelCase<T>;
  }

  const result: any = {};

  for (const [key, value] of Object.entries(obj)) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelKey = lowerFirst(key);
      result[camelKey] = objectKeysToCamelCaseRecursive(value);
    }
  }

  return result as ConvertToCamelCase<T>;
};

/**
 * A type guard function that checks if a value is neither `null` nor `undefined`.
 *
 * Made specifically for TypeScript when used with methods like `Array.prototype.filter`,
 * as it avoids type errors that can arise when using `filter(Boolean)` on arrays containing `null` or `undefined`.
 *
 * @template T - The type of the value being checked.
 * @param {T | null | undefined} value - The value to check.
 * @returns {value is NonNullable<T>} - Returns `true` if the value is defined (not `null` or `undefined`), otherwise `false`.
 */
export function isDefined<T>(value: T | null | undefined): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

/**
 * Checks if the given object is empty.
 *
 * An object is considered empty if it is `null`, `undefined`, or has no own enumerable properties.
 *
 * @param obj - The object to check.
 * @returns `true` if the object is empty, otherwise `false`.
 */
export const objectIsEmpty = (obj: any) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};
