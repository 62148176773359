<template>
  <p v-ui-test="'pdp-fees-link'" v-if="isAuthenticated && !isAirbus">
    {{ textK('UI_PRODUCTPAGE_FEES_DISCLAIMER') }}
    <a
      class="terms-link"
      @click="
        openModal({
          modalComponent: 'ModalLoadContent',
          params: {
            key: 'SatairOrderHandlingFees_ModalUrl',
            title: 'SatairOrderHandlingFees_Name',
          },
        })
      "
    >
      {{ textK('UI_PRODUCTPAGE_FEESLABEL') }}</a
    >
  </p>
</template>

<script lang="ts" setup>
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { OwningSystem } from '@/src/core/types/api';
import { computed } from 'vue';

interface Props {
  owningSystem: string;
}

const props = defineProps<Props>();

const textK = useText();
const openModal = useModal();
const authenticationStore = useAuthenticationStore();

const isAuthenticated = computed(() => {
  return authenticationStore.isAuthenticated;
});

const isAirbus = computed(() => {
  return props.owningSystem === OwningSystem.BLUE;
});
</script>
