<template>
  <div class="product__detail">
    <template v-if="isAuthenticated">
      <p class="product__detail__label">{{ textK('UI_PRODUCT_AVAILABILITY') }}</p>
      <transition name="fade" mode="out-in">
        <ProductAvailability
          :id="product.Id"
          :owning-system="product.OwningSystem"
          :offer-quantity="remainingQuantity"
          :add-to-basket-quantity="product.AddToBasketQty"
          :procurement="procurement"
          :is-fhs="isFhs"
        />
      </transition>
    </template>
  </div>
</template>

<style lang="scss" src="./product-stock.scss" scoped></style>

<script lang="ts" setup>
import useText from '@/src/core/hooks/useText';
import { computed } from 'vue';

import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { Product } from '@/src/core/types/api';
import { Procurement } from '@/src/core/types/interfaces';
import ProductAvailability from '@/src/market/components/product/product-availability/product-availability.vue';
import {
  isSatair,
  productAdditionalData as productAdditionalDataUtil,
} from '@/src/market/services/product-parts';

interface Props {
  product: Product;
  procurement?: Procurement;
  isFhs?: boolean;
}

const props = defineProps<Props>();
const authenticationStore = useAuthenticationStore();
const textK = useText();
const product = computed(() => props.product);
const procurement = computed(() => props.procurement);

const productAdditionalData = computed(() => {
  return productAdditionalDataUtil(product.value.Id, product.value.OwningSystem);
});

const remainingQuantity = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return productAdditionalData.value?.RemainingOfferQuantity;
  }

  return 0;
});

const isAuthenticated = computed(() => {
  return authenticationStore.isAuthenticated;
});
</script>
