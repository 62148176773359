<template>
  <StandardDialogModal
    class="modal-request-part-lease"
    :size="uiSize.Large"
    :has-close-button="true"
    :loading="isBusy"
  >
    <template #header>
      <ModalRequestQuoteHeader
        :offer-id="productId"
        :header-title="textK('UI_TOOL_QUOTATION_REQUEST_LEASE_HEADER')"
        :procurement="procurement"
      />
    </template>

    <template #body>
      <p class="pb-xl">
        {{ textK('UI_TOOL_QUOTATION_AIRBUS_RFQ_PART_LEASE_MODAL_DESCRIPTION') }}
      </p>
      <form class="modal-request-part-lease__form">
        <VTextField
          v-model="formData.acReg"
          input-type="text"
          :type="uiVariant.Secondary"
          :label="textK('UI_TOOL_QUOTATION_AIRBUS_RFQ_PART_LEASE_AC_REG')"
          name="acReg"
          :required="true"
          :vmin-length="AC_REG_MIN_LENGTH"
          :vmax-length="MAX_TEXT_LENGTH"
        />
        <VSelect
          class="flex-grow dropdown"
          @update:modelValue="(value: number) => handlePriorityInput(value)"
          :required="true"
          :options-skeleton="priorityOptions.length === 0"
          label="Priority"
          :modelValue="priorityValue"
          :is-input-field="false"
          :options="priorityOptions"
          :type="UiVariant.Secondary"
          ui-test-name-dropdown="priority-dropdown-option"
        />
        <VTextField
          v-model="formData.purchaseOrderNumber"
          input-type="text"
          :type="uiVariant.Secondary"
          :label="textK('UI_TOOL_QUOTATION_AIRBUS_RFQ_PART_LEASE_REFERENCE')"
          name="referenceNumber"
          :required="true"
          :vmin-length="NUMBER_LENGTH_MIN_LENGTH"
          :vmax-length="NUMBER_LENGTH_MAX_LENGTH"
        />
        <VTextarea
          class="pb-xl"
          v-model="formData.message"
          :vmax-length="MAX_TEXT_LENGTH"
          :type="uiVariant.Secondary"
          :label="textK('UI_TOOL_QUOTATION_REQUEST_MESSAGE')"
        />
      </form>
    </template>

    <template #actions>
      <VButton
        v-ui-test="'request-part-lease-continue'"
        @click.prevent="onContinue"
        :disabled="!isValid"
        :loading="isBusy"
        :type="uiVariant.Default"
        >{{ textK('UI_COMMON_CONTINUE') }}</VButton
      >
    </template>
  </StandardDialogModal>
</template>

<style src="./modal-request-part-lease.scss" lang="scss" scoped></style>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { flatten, maxLength, minLength, object, parse, string } from 'valibot';
import { storeToRefs } from 'pinia';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import ModalRequestQuoteHeader from '@/src/market/components/modals/modal-request-quote/modal-request-quote-header/modal-request-quote-header.vue';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { useModalStore } from '@/src/core/stores/modal';
import { AirbusPart } from '@/src/core/types/interfaces';
import { productAdditionalData } from '@/src/market/services/product-parts';
import { priorityOptions } from '@/src/market/settings/priority';
import { useAirbusQuotesStore } from '@/src/market/stores/airbus-quotes';
import { useProductStore } from '@/src/market/stores/product';

interface Params {
  id: string;
}

const MAX_TEXT_LENGTH = 255;
const NUMBER_LENGTH_MAX_LENGTH = 11;
const NUMBER_LENGTH_MIN_LENGTH = 3;
const AC_REG_MIN_LENGTH = 2;

const textK = useText();
const modalStore = useModalStore();
const airbusQuotesStore = useAirbusQuotesStore();
const productStore = useProductStore();
const { formData } = storeToRefs(airbusQuotesStore);

const isValid = ref(false);
const isBusy = ref(false);
const priorityValue = ref(0); // RTN
const errors = ref<Partial<Record<any, [string, ...string[]]>> | null>(null);

const uiSize = UiSize;
const uiVariant = UiVariant;

const RequestPartLeaseSchema = object({
  acReg: string([minLength(AC_REG_MIN_LENGTH), maxLength(MAX_TEXT_LENGTH)]),
  purchaseOrderNumber: string([
    minLength(NUMBER_LENGTH_MIN_LENGTH),
    maxLength(NUMBER_LENGTH_MAX_LENGTH),
  ]),
  priority: string([minLength(1)]),
});

const productId = computed(() => {
  return (modalStore.params as Params)?.id ?? '';
});

const product = computed(() => {
  return productStore.productById(productId.value);
});

const additionalData = computed(() => {
  return productAdditionalData(productId.value, product.value?.OwningSystem ?? '');
});

const procurement = computed(() => {
  return (additionalData.value as AirbusPart)?.procurements.find(
    (p) => p.procurementType === ProcurementType.Lease,
  );
});

const handlePriorityInput = (value: number) => {
  priorityValue.value = value;
  airbusQuotesStore.setPriority({ priority: priorityOptions[value].value });
};

const closeModal = () => {
  modalStore.closeModal();
};

const onContinue = async () => {
  closeModal();
  modalStore.showModal({
    modalComponent: 'ModalAirbusRequestQuoteAddress',
    params: { id: productId.value },
    single: true,
  });
};

watch(
  () => airbusQuotesStore.formData,
  (newValue) => {
    try {
      parse(RequestPartLeaseSchema, newValue);
      isValid.value = true;
      errors.value = null;
    } catch (error) {
      isValid.value = false;
      errors.value = flatten<typeof RequestPartLeaseSchema>(error).nested;
    }
  },
  { immediate: true, deep: true },
);

onMounted(() => {
  if (productId.value !== airbusQuotesStore.productId) {
    airbusQuotesStore.$reset();
    airbusQuotesStore.setPriority({ priority: priorityOptions[0].value });
    airbusQuotesStore.setProductId({ productId: productId.value });
  }
});
</script>
