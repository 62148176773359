<template>
  <div class="planning-solution-icon">
    <PlanningLogo ref="logo" />
  </div>
</template>

<script setup lang="ts">
import PlanningLogo from '@/src/planning/components/ui/planning-logo/planning-logo.vue';
import { gsap } from 'gsap';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';

interface Props {
  isHovered: boolean;
}

const props = defineProps<Props>();
const logo = ref<InstanceType<typeof PlanningLogo> | null>(null);
const timeline = gsap.timeline({ paused: true });

const hovered = computed(() => props.isHovered);

const createTimeline = () => {
  const firstRect = logo.value?.$refs.firstRect;
  const secondRect = logo.value?.$refs.secondRect;
  const thirdRect = logo.value?.$refs.thirdRect;
  const duration = 0.4;
  const delay = 0.4;

  gsap.defaults({
    ease: 'linear',
    transformOrigin: 'center',
  });

  timeline
    // move down
    .to([thirdRect], { x: 10, y: -10, duration: 0.1, delay }, 'moveDown')
    .to([thirdRect], { x: -136, y: 136, duration })
    .to([secondRect], { x: -68, y: 68, duration: duration / 2 }, `-=${duration / 2}`)
    .to([thirdRect, secondRect, firstRect], {
      scale: 0,
      duration: 0.16,
    })
    // move up
    .to([thirdRect, secondRect, firstRect], {
      scale: 1,
      duration: 0.16,
    })
    .to([thirdRect], { x: 0, y: 0, duration })
    .to(
      [secondRect],
      {
        x: 0,
        y: 0,
        duration,

        onComplete: () => {
          if (hovered.value) {
            timeline.play('moveDown');
          }
        },
      },
      '<',
    );
};

watch(
  () => hovered.value,
  (newValue) => {
    if (newValue && !timeline.isActive()) {
      timeline.play('moveDown');
    }
  },
);

onMounted(() => {
  createTimeline();
});

onBeforeUnmount(() => {
  timeline.kill();
});
</script>

<style src="./planning-solution-icon.scss" lang="scss" scoped></style>
