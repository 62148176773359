<template>
  <svg
    class="airbus-logo"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMid meet"
    viewBox="0 0 515 115"
  >
    <path
      d="M298.62,103.36H266.37V85.3h32.19c5.29.09,9.57,4,9.57,9a9.37,9.37,0,0,1-9.59,9.06M266.37,49.83h31.47a7.88,7.88,0,0,1,8,7.73,8,8,0,0,1-8.14,7.75H266.37ZM317.44,75c6.06-4.24,8.34-11.27,8.34-19,0-14.55-12.3-26.21-27.37-26.21H243.79v92.88h56.76v0c15.94,0,29.21-12.25,29.21-27.26A23.23,23.23,0,0,0,317.44,75m83.73-45.21V82.09c0,16-9.27,22.68-20.66,22.68s-20.62-6.68-20.62-22.66V29.83H336.67V82.25c0,33.43,19.61,43.16,43.84,43.16s43.88-9.73,43.88-43.16V29.83Zm81.71,36.11c-18.75-4.07-25.08-5.88-25.08-11.83,0-5.24,6.79-7.89,17.5-7.89,10.12,0,22.35,4.32,29.52,8.61l7.32-18.91c-10.88-4.52-24-8.85-41.26-8.85-22.68,0-36.83,13.26-36.83,29.46s11.66,23.37,34.78,28.26c17.93,3.79,22.79,6.07,22.79,11.65s-7.2,7.63-16.91,7.63c-12.58,0-24.68-4.19-36.08-9.09l-6.9,18.44c13.63,6.31,27.79,11.38,44.66,11.38,28.12,0,39.59-13.43,39.59-29.1,0-13.93-9.65-24.66-33.1-29.76M46.93,29.83,0,122.71l27.9,0,7-14.85H67L56.89,87.24H44.21l12-25.54,30.13,61h27.85L65.41,29.83ZM235.61,61.14c0-16.93-11.61-30.66-35.48-30.66H151.56v92.88l22.57,0V49.83h27.65c8.17,0,11.56,5.06,11.56,11.07s-3.57,10.86-11.74,10.86H182.31l29,51.6h22.93s-19.86-33.7-19.75-33.71c14.33-4.46,21.08-15.32,21.08-28.56M118.67,122.71h23.22V29.83H118.67Z"
      transform="translate(0 -27.07)"
      style="fill: #00205b"
    ></path>
  </svg>
</template>

<style lang="scss" src="./airbus-logo.scss" scoped></style>

<script lang="ts" setup></script>
