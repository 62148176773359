<template>
  <div class="close" :class="{ 'close--is-lilly': isLilly }">
    <button type="button" v-ui-test="'modal-close'" class="close-button" @click="closeModal">
      <svgicon name="ui-close"></svgicon>
    </button>
  </div>
</template>

<style scoped src="./CloseModalButton.scss" lang="scss"></style>

<script lang="ts" setup>
import { UiVariant } from '@/src/core/components/ui/ui.types';
import { useModalStore } from '@/src/core/stores/modal';
import { useReleaseNotesStore } from '@/src/core/stores/release-notes';
import { computed } from 'vue';

interface Props {
  variant?: UiVariant;
}

withDefaults(defineProps<Props>(), {
  variant: UiVariant.Default,
});

const modalStore = useModalStore();
const releaseNotesStore = useReleaseNotesStore();

const isLilly = computed(() => releaseNotesStore.releaseNotes.IsLillyStyling);

const closeModal = () => {
  modalStore.closeModalButton();
};
</script>
