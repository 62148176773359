// tslint:disable
import { getEnv } from '@/src/core/services/environment';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class RequestExchangeApi {
  public static RequestExchangeFormSubmit = (userId: string) =>
    `${baseUrl}/api/quote/createairbusquote/${userId}/submit`;
  public static DownloadTemplate = `${baseUrl}/api/RemovalComponentTechnicalDocumentTemplateUrl`;
}
