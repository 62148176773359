<template>
  <span
    class="label"
    :class="{
      'label--small': size === uiSize.Small,
      'label--medium': size === uiSize.Medium,
      'label--large': size === uiSize.Large,
    }"
  >
    <slot />
  </span>
</template>

<style lang="scss" src="./label.scss" scoped />

<script lang="ts">
// Declare the component name to avoid the warning of using a built-in or reserved HTML elements
export default { name: 'VLabel' };
</script>

<script lang="ts" setup>
import { UiSize } from '@/src/core/components/ui/ui.types';

interface Props {
  size?: UiSize;
}

withDefaults(defineProps<Props>(), {
  size: UiSize.Small,
});

const uiSize = UiSize;
</script>
