<template>
  <div ref="wrapperRef" class="oneway-icon__wrapper">
    <svg
      ref="iconRef"
      class="oneway-icon"
      width="81"
      height="32"
      viewBox="0 0 81 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="81" height="32" fill="#2E2E2E" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.23 15.66V15.67L20.55 22.34H16.19L21.29 17.23H21.28L21.29 17.21H0V14.11H21.29L16.19 9H20.57L27.23 15.66Z"
        fill="white"
      />
      <path
        d="M53.3745 8.44279C53.5439 7.95245 53.7935 7.52451 54.1145 7.1679C54.4354 6.80238 54.8277 6.51709 55.3002 6.31203C55.7727 6.10698 56.2898 6 56.8782 6C57.4666 6 58.0016 6.10698 58.4652 6.31203C58.9287 6.51709 59.321 6.80238 59.642 7.1679C59.9629 7.53343 60.2126 7.96136 60.3819 8.44279C60.5513 8.93313 60.6405 9.45913 60.6405 10.0297C60.6405 10.6003 60.5513 11.1085 60.3819 11.5899C60.2126 12.0713 59.9629 12.4903 59.642 12.8469C59.321 13.2035 58.9287 13.4799 58.4652 13.685C58.0016 13.89 57.4756 13.9881 56.8782 13.9881C56.2809 13.9881 55.7638 13.89 55.3002 13.685C54.8366 13.4799 54.4354 13.2035 54.1145 12.8469C53.7935 12.4903 53.5439 12.0713 53.3745 11.5899C53.2051 11.1085 53.116 10.5914 53.116 10.0297C53.116 9.46805 53.2051 8.93313 53.3745 8.44279ZM54.9079 10.9658C54.9793 11.2689 55.1041 11.5453 55.2646 11.786C55.425 12.0267 55.639 12.2229 55.9065 12.3655C56.1739 12.5082 56.4949 12.5884 56.8693 12.5884C57.2438 12.5884 57.5647 12.5171 57.8322 12.3655C58.0996 12.214 58.3136 12.0267 58.4741 11.786C58.6345 11.5453 58.7594 11.2689 58.8307 10.9658C58.902 10.6627 58.9466 10.3507 58.9466 10.0297C58.9466 9.70876 58.9109 9.36998 58.8307 9.05794C58.7504 8.74591 58.6345 8.46062 58.4741 8.21991C58.3136 7.97028 58.0996 7.77414 57.8322 7.6315C57.5647 7.48885 57.2438 7.40861 56.8693 7.40861C56.4949 7.40861 56.1739 7.47994 55.9065 7.6315C55.639 7.78306 55.425 7.97028 55.2646 8.21991C55.1041 8.46953 54.9793 8.74591 54.9079 9.05794C54.8366 9.36998 54.792 9.69984 54.792 10.0297C54.792 10.3596 54.8277 10.6627 54.9079 10.9658Z"
        fill="white"
      />
      <path
        d="M63.8677 6.1875L67.0594 11.3138H67.0772V6.1875H68.6463V13.8279H66.9702L63.7964 8.71053H63.7786V13.8279H62.2095V6.1875H63.8766H63.8677Z"
        fill="white"
      />
      <path
        d="M76.688 6.1875V7.59611H72.6583V9.23653H76.3581V10.5382H72.6583V12.4104H76.7771V13.819H70.9822V6.1875H76.6969H76.688Z"
        fill="white"
      />
      <path
        d="M59.312 26.3006L58.0193 21.1029H58.0015L56.7266 26.3006H55.0238L53 18.6602H54.6761L55.8886 23.8578H55.9064L57.2348 18.6602H58.8039L60.1055 23.9202H60.1233L61.3715 18.6602H63.0208L60.9703 26.3006H59.3031H59.312Z"
        fill="white"
      />
      <path
        d="M67.5766 18.668L70.4295 26.3084H68.6821L68.1026 24.6055H65.2497L64.6524 26.3084H62.9585L65.8471 18.668H67.5677H67.5766ZM67.6747 23.3574L66.7118 20.558H66.694L65.6955 23.3574H67.6747Z"
        fill="white"
      />
      <path
        d="M69.6895 18.668H71.5706L73.3536 21.6813L75.1278 18.668H77L74.1649 23.3752V26.3084H72.4889V23.3307L69.6895 18.668Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script lang="ts" setup>
import useIntersectionObserver from '@/src/core/hooks/useIntersectionObserver';
import { ref } from 'vue';

const wrapperRef = ref<HTMLDivElement | null>(null);
const iconRef = ref<SVGElement | null>(null);

useIntersectionObserver(
  wrapperRef,
  () => {
    iconRef.value?.classList.add('animate');
  },
  1000,
);
</script>

<style lang="scss" src="./oneway-interchangeable.scss"></style>
