<template>
  <button
    @click="download()"
    :disabled="isLoading"
    class="seller-conversation-download-item"
    :class="{ disabled: isLoading }"
  >
    <span class="seller-conversation-download-item__name"
      >{{ attachment.Name
      }}<span class="seller-conversation-download-item__size" v-if="!isQuote">
        ({{ (attachment.Size / 1000).toFixed(2) + ' kb' }})</span
      ></span
    >
    <svgicon class="seller-conversation-download-item__icon" name="ui-download" />
  </button>
</template>

<style lang="scss" src="./seller-conversation-download-item.scss" scoped />

<script lang="ts">
import { Vue, Component, Prop, toNative } from 'vue-facing-decorator';
import { MessagingApi } from '@/src/core/api';
import DownloadFile from '@/src/core/services/download';

import { MarketplaceMessageAttachmentInfo } from '@/src/core/types/api';
import { useQuotesStore } from '@/src/market/stores/quotes';
import { useSellerConversationStore } from '@/src/market/stores/seller-conversation';

@Component({})
export class SellerConversationDownloadItem extends Vue {
  @Prop() public attachment: MarketplaceMessageAttachmentInfo;
  @Prop() private offerId: string;
  @Prop() private orderCode: string;
  @Prop() private threadId: string;
  @Prop() private consignmentCode: string;
  @Prop() private messageId: string;
  @Prop({ default: false }) public isQuote: boolean;

  public isLoading: boolean = false;

  public sellerConversationStore = useSellerConversationStore();
  public quotesStore = useQuotesStore();

  public download() {
    this.isLoading = true;
    const threadId =
      this.threadId ||
      this.sellerConversationStore.threadId ||
      this.quotesStore.currentQuoteView.MiraklQuoteRequestId;
    const quoteCode = this.quotesStore.currentQuoteView.Code;
    const messageId = this.messageId;
    if (this.orderCode && this.consignmentCode && threadId) {
      DownloadFile({
        url: MessagingApi.DownloadOrderMessageDocument(
          this.orderCode,
          threadId,
          this.consignmentCode,
        ),
        data: this.attachment,
        method: 'POST',
      }).then(() => {
        this.isLoading = false;
      });
    } else if (this.isQuote || quoteCode) {
      DownloadFile({
        url: MessagingApi.DownloadQuoteMessageDocument(quoteCode, threadId, messageId),
        data: this.attachment,
        method: 'POST',
      }).then(() => {
        this.isLoading = false;
      });
    } else {
      DownloadFile({
        url: MessagingApi.DownloadOfferMessageDocument(this.offerId),
        data: this.attachment,
        method: 'POST',
      }).then(() => {
        this.isLoading = false;
      });
    }
  }
}

export default toNative(SellerConversationDownloadItem);
</script>
