<template>
  <transition name="fadeDown">
    <div class="solution-helper" v-if="isShown && !isMenuMinimized">
      Access <strong>Satair Market</strong> and <strong>Airbus Spares</strong>.
    </div>
  </transition>
</template>

<style lang="scss" src="./solution-helper.scss" scoped />

<script lang="ts" setup>
import { Site, useUIStore } from '@/src/core/stores/ui';
import { computed, onMounted, ref, watch } from 'vue';

const uiStore = useUIStore();

const isShown = ref(false);
const delay = ref(2000);

const isMenuMinimized = computed(() => {
  return uiStore.navigationMinimized;
});

const showHelper = computed(() => {
  return uiStore.showHelper;
});

const isContentSite = computed(() => {
  return uiStore.site === Site.Content;
});

const show = () => {
  if (showHelper.value) {
    isShown.value = true;
  }
};

const hide = () => {
  isShown.value = false;
};

watch(
  () => showHelper.value,
  (value: boolean) => {
    if (value) {
      show();
    } else {
      hide();
    }
  },
);

onMounted(() => {
  if (showHelper.value && isContentSite.value) {
    setTimeout(() => {
      show();
    }, delay.value);
  }
});
</script>
