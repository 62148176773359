<template>
  <div
    class="global-notification"
    v-if="notification.Body"
    :class="{
      error: type === notificationTypes.Error,
      success: type === notificationTypes.Success,
      info: type === notificationTypes.Information,
      warning: type === notificationTypes.Warning,
    }"
  >
    <div class="global-notification__info" v-ui-test="'global-notification-info'">
      <div class="global-notification__icon">
        <svgicon name="ui-notification-error" v-if="type === notificationTypes.Error"></svgicon>
        <svgicon name="ui-notification-success" v-if="type === notificationTypes.Success"></svgicon>
        <svgicon
          name="ui-notification-default"
          v-if="type === notificationTypes.Information"
        ></svgicon>
        <svgicon name="ui-notification-warning" v-if="type === notificationTypes.Warning"></svgicon>
      </div>
      <div class="global-notification__body">
        {{ notification.Body }}
        <template v-if="action"
          ><router-link :to="action.Url || ''">{{ action.Name }}</router-link></template
        >
      </div>
      <div class="global-notification__action">
        <button class="button-invisible" aria-label="Remove notification" @click="close">
          <svgicon name="ui-close"></svgicon>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./global-notification.scss" scoped />

<script lang="ts" setup>
import { useGlobalNotificationStore } from '@/src/core/stores/global-notification';
import { NotificationTypes } from '@/src/core/types/api';
import { computed, onMounted } from 'vue';

const globalNotificationStore = useGlobalNotificationStore();
const notificationTypes = NotificationTypes;

const notification = computed(() => {
  return globalNotificationStore.notification;
});

const type = computed(() => {
  return notification.value.Type || NotificationTypes.Information;
});

const action = computed(() => {
  return notification.value.NotificationAction;
});

const close = () => {
  globalNotificationStore.reset();
};

onMounted(() => {
  globalNotificationStore.fetchNotification();
});
</script>
