import { CodeNamePair } from '@/src/core/types/api';
import { IVSelect } from '@/src/core/types/ui';

export const getSelectedOption = (selectedIndex: number | null, options: IVSelect[]) => {
  if (selectedIndex !== null && selectedIndex >= 0 && selectedIndex < options.length) {
    return options[selectedIndex];
  }
  return null;
};

export const getSelectedValue = (...args: Parameters<typeof getSelectedOption>) => {
  const option = getSelectedOption(...args);

  if (!option) {
    return '';
  }

  return option.value;
};

export const mapToSelectOption = ({ code, name }: CodeNamePair): IVSelect => ({
  value: code,
  label: name,
});
