<template>
  <div
    class="simple-table"
    :class="{
      'simple-table--last-value-number': numberValue,
      'simple-table--secondary': uiVariant === variant.Secondary,
    }"
  >
    <div class="simple-table__header" v-if="headers.length > 0">
      <div class="simple-table__row">
        <div class="simple-table__item" v-for="(header, i) in headers" :key="header + i">
          <span>{{ header || '&nbsp;' }}</span>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
export default { name: 'VSimpleTable' };
</script>

<style lang="scss" src="./table.scss"></style>

<script lang="ts" setup>
import { UiVariant } from '@/src/core/components/ui/ui.types';

interface Props {
  headers?: string[];
  numberValue?: boolean;
  uiVariant?: UiVariant;
}

withDefaults(defineProps<Props>(), {
  headers: () => [],
  numberValue: false,
  uiVariant: UiVariant.Primary,
});

const variant = UiVariant;
</script>
