<template>
  <div class="product-details-gallery">
    <template v-if="id.length === 0 && containerHeight === 0">
      <div class="product-details-gallery__primary-image">
        <slot />
      </div>
    </template>
    <template v-else>
      <div
        class="product-details-gallery__primary-image"
        @click="
          openModal({
            modalComponent: 'ModalImage',
            params: { id: id, activeIndex: activeIndex },
          })
        "
        :style="{ height: containerHeight + 'px' }"
      >
        <ImageHandler
          ref="imageHandler"
          v-if="activeImage"
          :alt="activeImage.AltText"
          :container-width="500"
          :load-image="true"
          :images="imageSources"
          @imageLoad="containerHeight = $event.offsetHeight"
        />
        <template v-if="images.length > 1 && containerHeight > 0">
          <button
            @click.stop="prevImage()"
            class="product-details-gallery__navigation product-details-gallery__navigation prev"
          >
            <svgicon class="product-details-gallery__navigation__icon" name="ui-arrow" />
          </button>
          <button
            @click.stop="nextImage()"
            class="product-details-gallery__navigation product-details-gallery__navigation next"
          >
            <svgicon class="product-details-gallery__navigation__icon" name="ui-arrow" />
          </button>
        </template>
      </div>
      <div
        class="product-details-gallery__indicators"
        v-if="images.length > 1 && containerHeight > 0"
      >
        <a
          v-for="(item, i) in images"
          :key="i"
          class="product-details-gallery__indicators__item"
          @click="activeIndex = i"
          :title="item.AltText"
          :class="{ active: i === activeIndex }"
        ></a>
      </div>
    </template>
  </div>
</template>

<style lang="scss" src="./product-details-gallery.scss" />

<script setup lang="ts">
import ImageHandler from '@/src/core/components/media-handler/image-handler/image-handler.vue';
import useModal from '@/src/core/hooks/useModal';
import type { ProductImages } from '@/src/core/types/api';
import type { ISources } from '@/src/core/types/interfaces';
import { ResizeEventBus } from '@/src/core/utils/resize-event-bus';
import { useProductStore } from '@/src/market/stores/product';
import debounce from 'lodash/debounce';
import { computed, onBeforeUnmount, onMounted, ref, watch, withDefaults } from 'vue';

interface Props {
  id?: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: '',
});

const imageHandler = ref<InstanceType<typeof ImageHandler>>();
const openModal = useModal();
const productStore = useProductStore();
const activeIndex = ref(0);
const containerWidth = ref(0);
const containerHeight = ref(0);
const imageSources = ref<ISources[]>([]);

const images = computed((): ProductImages[] => {
  return productStore.productById(props.id)?.Images || [];
});

const activeImage = computed(() => images.value[activeIndex.value]);

const nextImage = () => {
  if (activeIndex.value >= images.value.length - 1) {
    activeIndex.value = 0;
  } else {
    activeIndex.value++;
  }
};

const prevImage = () => {
  if (activeIndex.value <= 0) {
    activeIndex.value = images.value.length - 1;
  } else {
    activeIndex.value--;
  }
};

const calculateDimensions = debounce(() => {
  const clientRect = imageHandler.value?.$el.getBoundingClientRect();

  if (clientRect?.width && clientRect?.width > 0 && clientRect?.width !== containerWidth.value) {
    containerWidth.value = imageHandler.value?.$el.getBoundingClientRect().width || 0;
  }

  if (
    clientRect?.height &&
    clientRect?.height > 0 &&
    clientRect?.height !== containerHeight.value
  ) {
    containerHeight.value = imageHandler.value?.$el.getBoundingClientRect().height || 0;
  }
}, 150);

const onResize = () => {
  calculateDimensions();
};

watch(
  () => props.id,
  () => {
    if (props.id && !(images.value?.length > 0)) {
      productStore.fetchAndUpdateProductByOfferId({ id: props.id, force: true });
    }
  },
  { immediate: true },
);

watch(
  () => activeImage.value,
  () => {
    if (activeImage.value) {
      imageSources.value = [
        {
          url: activeImage.value.CdnUrl + '&width=500&height=500&mode=crop',
          size: 500,
        },
      ];
    }
  },
  { immediate: true },
);

onMounted(() => {
  calculateDimensions();
  ResizeEventBus.$on('resize', onResize);
});

onBeforeUnmount(() => {
  ResizeEventBus.$off('resize', onResize);
});
</script>
