<template>
  <div
    class="inline-notification"
    v-ui-test="uiTestName"
    :class="{
      secondary: variant === uiVariant.Secondary,
      error: type === notificationTypes.Error,
      success: type === notificationTypes.Success,
      info: type === notificationTypes.Information,
      warning: type === notificationTypes.Warning,
      small: size === uiSize.Small,
    }"
  >
    <div class="inline-notification__info">
      <div class="inline-notification__icon">
        <svgicon name="ui-notification-error" v-if="type === notificationTypes.Error"></svgicon>
        <svgicon name="ui-notification-success" v-if="type === notificationTypes.Success"></svgicon>
        <svgicon
          name="ui-notification-default"
          v-if="type === notificationTypes.Information"
        ></svgicon>
        <svgicon name="ui-notification-warning" v-if="type === notificationTypes.Warning"></svgicon>
      </div>
      <div class="inline-notification__body">
        <slot></slot>
      </div>
    </div>
    <div class="inline-notification__action">
      <slot name="action"></slot>
    </div>
  </div>
</template>

<style lang="scss" src="./inline-notification.scss" scoped />

<script lang="ts" setup>
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import { NotificationTypes } from '@/src/core/types/api';

interface Props {
  type?: NotificationTypes;
  variant?: UiVariant;
  size?: UiSize;
  uiTestName?: string;
}

withDefaults(defineProps<Props>(), {
  type: NotificationTypes.Information,
  variant: UiVariant.Default,
  size: UiSize.Medium,
  uiTestName: 'inline-notification',
});

const notificationTypes = NotificationTypes;
const uiVariant = UiVariant;
const uiSize = UiSize;
</script>
