import { Site, useUIStore } from '@/src/core/stores/ui';
import { RouteLocationNormalized } from 'vue-router';

const ProfileLayout = () => import('@/src/profile/layouts/profile-layout.vue');
const ProfileDetails = () =>
  import('@/src/profile/components/profile/profile-details/profile-details.vue');
const ProfilePermissions = () =>
  import('@/src/profile/components/profile/profile-permissions/profile-permissions.vue');

const profileRoutes = [
  {
    path: '/user',
    component: ProfileLayout,
    redirect: { name: 'profile' },
    meta: {
      loginRestricted: true,
    },
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: () => void) {
      const uiStore = useUIStore();
      uiStore.setCurrentSite({ site: Site.Profile });
      next();
    },
    children: [
      {
        path: '/user/profile',
        name: 'profile',
        meta: {
          title: 'Personal Details',
          type: 'Profile page',
          loginRestricted: true,
        },
        component: ProfileDetails,
      },
      {
        path: '/user/notifications',
        name: 'profilePermissions',
        meta: {
          title: 'Notifications Preferences',
          type: 'Profile page',
          loginRestricted: true,
        },
        component: ProfilePermissions,
      },
    ],
  },
];
export default profileRoutes;
