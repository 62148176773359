<template>
  <div
    ref="imageHandler"
    class="image-handler"
    :class="{
      'background-image': type === 'background',
      loaded: hasLoaded,
      failed: loadError,
    }"
  >
    <template v-if="!loadError">
      <div v-if="type === 'background'" class="background-item" :style="setBackgroundCover"></div>
      <img
        v-else
        :src="url"
        :alt="alt"
        :class="{
          'image-position': hasFocalPoints,
        }"
        :style="[hasFocalPoints ? setImageInlineStyle : null]"
      />
    </template>
    <div v-else class="load-error">
      <svgicon name="ui-notification-error"></svgicon>
      Error loading image
    </div>
  </div>
</template>

<style src="./image-handler.scss" lang="scss" scoped></style>

<script setup lang="ts">
import { ISources } from '@/src/core/types/interfaces';
import { asPercentage } from '@/src/core/utils/math';
import { computed, ref, watch } from 'vue';

export interface Props {
  alt?: string;
  images: ISources[];
  containerWidth: number;
  loadImage: boolean;
  type?: 'inline' | 'background';
}

const props = withDefaults(defineProps<Props>(), {
  loadImage: false,
  alt: '',
  type: 'inline',
});

const imageHandler = ref<HTMLElement | null>(null);
const loadError = ref<boolean>(false);
const hasLoaded = ref<boolean>(false);
const hasFocalPoints = ref<boolean>(false);
const fetchInProgress = ref<boolean>(false);
const url = ref<string>('');
const currentSizeIdx = ref<number>(-1);
const focalPointTop = ref<number | undefined>();
const focalPointLeft = ref<number | undefined>();
const emit = defineEmits(['busy', 'imageLoad']);

const loadImage = computed(() => {
  return props.loadImage;
});

const containerWidth = computed(() => {
  return props.containerWidth;
});

const images = computed(() => {
  return props.images;
});

const setBackgroundCover = computed(() => {
  return {
    backgroundImage: 'url(' + url.value + ')',
    backgroundPosition: setImagePosition(),
  };
});

const setImageInlineStyle = computed(() => {
  return {
    objectFit: 'cover',
    objectPosition: setImagePosition(),
  };
});

const setImagePosition = () => {
  if (!focalPointLeft.value || !focalPointTop.value) {
    return 'center center';
  }

  return `${asPercentage(focalPointLeft.value)}% ${asPercentage(focalPointTop.value)}%`;
};

const fetchImage = async () => {
  const lastIdx = currentSizeIdx.value;

  // check which size we should use - bases on containers width
  let requestSize = images.value.findIndex((asset) => asset.size >= containerWidth.value);

  // check if we need the mobile image or the largest image (if container width is higher than the last image
  if (requestSize === -1 && containerWidth.value > images.value[images.value.length - 1].size) {
    requestSize = images.value.length - 1;
  } else if (containerWidth.value <= images.value[0].size) {
    requestSize = 0;
  }

  // make sure we only scale up and not down
  currentSizeIdx.value = requestSize > currentSizeIdx.value ? requestSize : currentSizeIdx.value;

  // set img url string based on the selected index
  const currentImage = images.value[currentSizeIdx.value];
  const imgSource = currentImage.url;
  const focalPointLeftValue = currentImage.focalPointLeft;
  const focalPointTopValue = currentImage.focalPointTop;

  // if the new requested image has changed and we are still fetching the old one - abort
  if (lastIdx !== currentSizeIdx.value && fetchInProgress.value) {
    fetchInProgress.value = false;
  }

  // have we loaded the image before, is the string set, are we currently loading another image
  if (lastIdx !== currentSizeIdx.value && imgSource.length > 0 && !fetchInProgress.value) {
    const image = new Image();
    image.src = imgSource;
    fetchInProgress.value = true;
    hasLoaded.value = false;

    image.onload = () => {
      url.value = imgSource;
      focalPointLeft.value = focalPointLeftValue;
      focalPointTop.value = focalPointTopValue;
      hasFocalPoints.value = Boolean(focalPointLeft) || Boolean(focalPointTop);

      hasLoaded.value = true;
      fetchInProgress.value = false;

      setTimeout(() => {
        emit('imageLoad', imageHandler.value);
      }, 100); // duration of the img fade in
    };
  }
};

// parent will set loadImage to true if its in view - will be set on mounted and onscroll
watch(
  () => loadImage.value,
  () => {
    if (loadImage.value && !hasLoaded.value && images.value) {
      fetchImage();
    }
  },
);

// check if the container width is altered
watch(
  () => containerWidth.value,
  () => {
    if (loadImage.value && images.value) {
      fetchImage();
    }
  },
);

watch(
  () => fetchInProgress.value,
  () => {
    if (loadImage.value && images.value) {
      emit('busy', fetchInProgress.value);
    }
  },
);

watch(
  () => images.value,
  (newValue: ISources[], oldValue: ISources[]) => {
    const oldUrl = oldValue && oldValue[0].url;
    const newUrl = newValue && newValue[0].url;

    if (!images.value || oldUrl === newUrl) {
      return;
    }

    hasLoaded.value = false;
    fetchInProgress.value = false;

    setTimeout(() => {
      if (loadImage.value) {
        url.value = '';
        currentSizeIdx.value = -1;
        fetchImage();
      }
    }, 150);
  },
  { immediate: true },
);
</script>
