<template>
  <div class="modal-inactivity-warning">
    <StandardDialogModal
      class="modal-inactivity-warning"
      :size="uiSize.Medium"
      :has-close-button="false"
      :locked-modal="true"
    >
      <template #header>
        <h3>{{ textK('UI_LOGIN_INACTIVE_MODAL_TITLE') }}</h3>
      </template>

      <template #body>
        <p v-html="description"></p>
      </template>

      <template #actions>
        <VButton v-ui-test="'logout-user-button'" :type="uiVariant.Secondary" @click="logoutUser">{{
          textK('UI_LOGIN_LOGOUT_NOW')
        }}</VButton>
        <VButton
          v-ui-test="'stay-logged-in-button'"
          :type="uiVariant.Default"
          @click="stayLoggedIn"
          class="stay-logged-in"
          >{{ textK('UI_LOGIN_STAY_LOGGED_IN') }}</VButton
        >
      </template>
    </StandardDialogModal>
  </div>
</template>

<script setup lang="ts">
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useModalStore } from '@/src/core/stores/modal';
import { TOTAL_MAX_INACTIVE_TIME } from '@/src/core/utils/time';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

const textK = useText();
const modalStore = useModalStore();
const authenticationStore = useAuthenticationStore();
const uiSize = UiSize;
const uiVariant = UiVariant;
const remainingTime = ref(60);
const timer = ref<NodeJS.Timeout | null>(null);

const description = computed(() => {
  const minutes = Math.floor(remainingTime.value / 60);
  const seconds = remainingTime.value % 60;

  return textK('UI_LOGIN_INACTIVE_MODAL_DESCRIPTION').replace(
    '[[remaining_time]]',
    `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`,
  );
});

const closeModal = () => {
  modalStore.closeModal();
};

const logoutUser = () => {
  if (timer.value) {
    clearInterval(timer.value);
    timer.value = null;
  }
  authenticationStore.doLogout();
};

const initializeInterval = () => {
  timer.value = setInterval(() => {
    if (remainingTime.value > 0) {
      remainingTime.value--;
    }
  }, 1000);
};

const stayLoggedIn = () => {
  if (timer.value) {
    clearInterval(timer.value);
    timer.value = null;
  }
  // make a request to reset inactivity timer
  authenticationStore.refreshAccessToken();
  closeModal();
};

const syncCountdownBetweenWindows = () => {
  if (!authenticationStore.isAuthenticated) {
    modalStore.closeModal();
  }
  // update the counter in the modal between tabs
  const remainingActivityTime =
    authenticationStore.getRemainingActivityTime(TOTAL_MAX_INACTIVE_TIME);
  if (remainingActivityTime > 0) {
    remainingTime.value = Math.floor(remainingActivityTime / 1000);
  }
};

onMounted(() => {
  initializeInterval();
  syncCountdownBetweenWindows();
  window.addEventListener('focus', syncCountdownBetweenWindows);
  window.addEventListener('blur', closeModal);
});

onBeforeUnmount(() => {
  window.removeEventListener('focus', syncCountdownBetweenWindows);
  window.removeEventListener('blur', closeModal);
});
</script>
