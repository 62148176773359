/**
 * Removes an HTML element if that element is appended
 * @param { Element } element
 * @returns { Element | undefined } Element to be removed
 */
export const removeElement = (element: Element) => {
  const parent = element?.parentElement;

  return parent?.removeChild?.(element);
};
