<template>
  <div class="table-module-wrapper">
    <div class="table-module">
      <div class="table-module__inner">
        <Component :is="headerComponent" />
        <div
          class="table-module-component-wrapper"
          :class="{
            'theme--dark': theme === 'dark',
            'theme--light': theme === 'light',
            'fixed-col': fixedFirstCol,
          }"
        >
          <div>
            <div class="content-table-wrapper">
              <div
                class="content-table-overflow"
                ref="scroller"
                @scroll.passive="fixedFirstCol ? onScroll() : () => {}"
              >
                <Component :is="tableComponent" />
              </div>
              <div
                v-if="fixedFirstCol && loaded"
                :class="{ 'is-scrolled': isScrolled }"
                class="content-table-follower"
              >
                <div class="content-table">
                  <Component :is="tableFollower" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./table.scss"></style>

<script lang="ts" setup>
import { onMounted, ref, useSlots, VNode } from 'vue';

interface Props {
  theme: string;
  fixedFirstCol: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  fixedFirstCol: false,
});

const slots = useSlots();
const scroller = ref<HTMLElement | null>(null);
const isScrolled = ref(false);
const loaded = ref(false);

const tableComponent = ref<VNode | null>(null);
const tableFollower = ref<VNode | null>(null);
const headerComponent = ref<VNode | null>(null);

const onScroll = () => {
  const scrolled = scroller.value?.scrollLeft;
  isScrolled.value = scrolled ? scrolled > 0 : false;
};

onMounted(() => {
  if (typeof slots.default === 'function') {
    const defaultContent = slots.default();
    const table = defaultContent.find((c) => c.props?.slot === 'table');

    if (table) {
      tableComponent.value = table;
      const children = table.children;
      const header = defaultContent.find((c) => c.type === 'header');

      if (header) {
        headerComponent.value = header;
      }

      if (props.fixedFirstCol && children && Array.isArray(children)) {
        tableFollower.value = table;
        loaded.value = true;
      }
    }
  }
});
</script>
