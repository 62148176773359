<template>
  <BackButton :destination="destination" :link="parent"></BackButton>
</template>

<script lang="ts" setup>
import { useFaqStore } from '@/src/content/stores/faq';
import BackButton from '@/src/core/components/ui/back-button/back-button.vue';
import { computed } from 'vue';

interface Props {
  destination: boolean;
}

withDefaults(defineProps<Props>(), {
  destination: false,
});

const faqStore = useFaqStore();

const parent = computed(() => {
  return faqStore.parent;
});
</script>
