export const getFileNameFromHeaders = (headers?: Headers): string | null => {
  const defaultFileName = 'file';
  const contentDispositionHeader = headers?.get('content-disposition');

  if (!contentDispositionHeader) {
    return defaultFileName;
  }

  const contentDispositionString = contentDispositionHeader.replace(/\s/g, '');
  const matches = contentDispositionString.match(/filename="?(.+?)"?$/);
  return matches ? matches[1] : defaultFileName;
};
