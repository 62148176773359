export const getFriendlyRoles = (roles: string[]): string[] => {
  const rolesList: string[] = [];

  if (roles.includes('b2badmingroup')) {
    rolesList.push('Admin');
  }
  if (roles.includes('b2bcustomergroup')) {
    rolesList.push('Buyer');
  }
  return rolesList;
};
