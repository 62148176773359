import { HttpResponse } from '@/src/core/apim';
import router from '@/src/core/router';
import { useAdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { getEnv, isDevelopment } from '@/src/core/services/environment';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { mapValidationErrorToNotification } from '@/src/core/services/notifications';
import { InteractionEventBus } from '@/src/core/utils/interaction-event-bus';
import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import { useAuthenticationStore } from '../stores/authentication';
import { useModalStore } from '../stores/modal';
import { useNotificationsStore } from '../stores/notifications';
import { SubContentEventBus } from '../utils/sub-content-event-bus';
import { UmbracoApi } from '../api';
import { generateRefreshTokenValidator } from './refreshTokenInterceptor';

const LOGGED_OUT_MESSAGE = 'User not logged in.';

/* tslint:disable */

export type UmbracoResponseModel<T extends { [key: string]: any }> = ReturnType<
  typeof runModules<T>
>;

export enum ReqQueueTypes {
  Default,
  FirstOnly,
  LastResponse,
}

// to register call as AJAX in MVC
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * ReqQue is used to specify how we handle concurrent requests
 *
 * When we have a second request, to the same @Category before
 * the first request has settled, the response for the second
 * request will be as follows:
 * @Type - ReqQueueTypes:
 *  - Default: undefined (cancelled)
 *  - FirstOnly: undefined
 *  - LastResponse: null | last saved value
 *  - Batch: wait for response from first request
 */
export class ReqQueue {
  constructor(
    public Type: ReqQueueTypes = ReqQueueTypes.Default,
    public Category?: string,
    public Id?: string,
    public Uid?: string,
    public Cancel: boolean = false,
    public CancelToken: CancelTokenSource = axios.CancelToken.source(),
    public IsLast: boolean = true,
  ) {}
}

interface RetryQueueItem {
  resolve: (value: any) => void;
  reject: (reason: any) => void;
  config: AxiosRequestConfig;
  apim?: (settings: AxiosRequestConfig) => Promise<HttpResponse<any>>;
}

let axiosQueue: ReqQueue[] = [];
let isTokenRefreshing = false;
let tokenRefreshQueue: RetryQueueItem[] = [];

export async function Req<T extends { [key: string]: any } = any>(
  settings: AxiosRequestConfig,
  queueSettings: ReqQueue = new ReqQueue(),
  apimHandler?: (settings: AxiosRequestConfig) => Promise<HttpResponse<T>>,
): Promise<UmbracoResponseModel<T>> {
  const authenticationStore = useAuthenticationStore();
  const interceptAndRefreshToken = generateRefreshTokenValidator();

  runPreModules(settings);
  handlePreQueue(queueSettings);

  // reset activity timer unless the user is logging out
  if (!settings.url?.includes('api/logout')) {
    InteractionEventBus.$emit('request');
  }

  if (queueSettings.Cancel) {
    return new Promise((resolve) => {
      resolve({ IsSuccess: false, Data: null });
    });
  }

  settings.cancelToken = queueSettings.CancelToken.token;

  const useApim = typeof apimHandler !== 'undefined' && hasFeature(FEATURES.ENABLE_APIM);

  // handle refresh token when access token expires
  if (
    authenticationStore.isAuthenticated &&
    authenticationStore.isAccessTokenExpired() &&
    settings.url !== UmbracoApi.getRefreshToken
  ) {
    if (!isTokenRefreshing) {
      isTokenRefreshing = true;
      try {
        await authenticationStore.refreshAccessToken();
        tokenRefreshQueue.forEach(({ resolve, reject, config, apim }) => {
          const newProm = handlePromise(config, apim);
          newProm
            .then((resp) => resolve(runModules<T>(resp, queueSettings)))
            .catch((error) => reject(runModules<T>(error?.response || error, queueSettings)));
        });
        tokenRefreshQueue = [];
        const newProm = handlePromise(settings, apimHandler);
        return newProm
          .then((resp) => runModules<T>(resp, queueSettings))
          .catch((error) => runModules<T>(error?.response || error, queueSettings));
      } catch (error) {
        authenticationStore.doLogout();
        return Promise.reject('Error refreshing token');
      } finally {
        isTokenRefreshing = false;
      }
    }

    return new Promise<UmbracoResponseModel<T>>((resolve, reject) => {
      tokenRefreshQueue.push({ resolve, reject, config: settings, apim: apimHandler });
    });
  }

  const getPromise = () => handlePromise(settings, apimHandler);

  const prom = getPromise();

  (prom as any).retryRequest = getPromise;

  setTimeout(async () => {
    const apimDebug = getEnv('VUE_APP_APIM_DEBUG') === 'true';

    if (!useApim || !apimDebug) {
      return;
    }

    const [apim, umbraco] = await Promise.all([
      prom,
      axios({
        ...settings,
        withCredentials: isDevelopment(),
      }),
    ]);

    console.groupCollapsed(settings.url);
    console.log('APIM', apim.data);
    console.log('.NET', umbraco.data);
    console.groupEnd();
  }, 0);

  return prom
    .catch(async (error) => interceptAndRefreshToken(error.response, prom, settings.url))
    .then((resp) => runModules<T>(resp, queueSettings))
    .catch((error) => runModules<T>(error?.response || error, queueSettings));
}

function handlePromise<T>(
  settings: AxiosRequestConfig,
  apimHandler?: (settings: AxiosRequestConfig) => Promise<HttpResponse<T>>,
) {
  const useApim = typeof apimHandler !== 'undefined' && hasFeature(FEATURES.ENABLE_APIM);
  return useApim
    ? apimHandler!(settings)
    : axios({ ...settings, withCredentials: isDevelopment() });
}

export function CancelReqCategory(category: string) {
  const categoryQ = axiosQueue.filter((q) => {
    return q.Category === category;
  });

  categoryQ.forEach((item) => {
    item.CancelToken.cancel();
  });
}

function handlePreQueue(queueSettings: ReqQueue): ReqQueue {
  if (!queueSettings.Category) {
    return queueSettings;
  }

  queueSettings.Uid = Guid.newGuid();

  let categoryQ = axiosQueue.filter((q) => {
    return q.Category === queueSettings.Category;
  });

  if (queueSettings.Id) {
    categoryQ = categoryQ.filter((q) => {
      return q.Id === queueSettings.Id;
    });
  }
  if (queueSettings.Type === ReqQueueTypes.FirstOnly) {
    if (categoryQ.length > 0) {
      // cancel
      queueSettings.Cancel = true;
      return queueSettings;
    }
  } else if (queueSettings.Type === ReqQueueTypes.LastResponse) {
    axiosQueue.forEach((item) => {
      item.IsLast = false;
    });
  } else {
    categoryQ.forEach((item) => {
      item.CancelToken.cancel();
    });
  }

  axiosQueue.push(queueSettings);

  return queueSettings;
}

function runModules<T extends { [key: string]: any }>(
  resp: AxiosResponse<any> | HttpResponse<T>,
  queueSettings?: ReqQueue,
) {
  const authenticationStore = useAuthenticationStore();
  const notificationsStore = useNotificationsStore();
  const modalStore = useModalStore();

  if (queueSettings) {
    // remove queue item
    axiosQueue = axiosQueue.filter((q) => {
      return q.Uid !== queueSettings.Uid;
    });
  }

  const okStatuses = [200, 201];
  if (resp?.data) {
    const { data } = resp;
    const validationErrors = mapValidationErrorToNotification(data.Data?.errors);
    const notifications = [...(data.Notification ?? []), ...validationErrors];

    if (notifications.length) {
      for (const notificationItem of notifications) {
        if (notificationItem.CloseDrawer) {
          setTimeout(() => {
            SubContentEventBus.$emit('closeSubContent');
          }, 2500);
        }
        notificationsStore.addNotification({ notificationItem });
      }
    }

    if (data.Authentication) {
      authenticationStore.setUserCookies({
        globalId: data.Authentication.GlobalId,
        isAdmin: data.Authentication.IsAdmin,
        isReadOnly: data.Authentication.IsReadOnly,
        isFinance: data.Authentication.IsFinance,
        betaCustomer: data.Authentication.BetaCustomer,
        readOnlyAccount: data.Authentication.ReadOnlyAccount,
        isFirstTime: data.Authentication.IsFirstTime,
        rememberMe: data.Authentication.RememberMe,
        hasAcceptedTou: data.Authentication.HasAcceptedTou,
        hasAcceptedReleaseNotes: data.Authentication.HasAcceptedReleaseNotes,
        userUnitType: data.Authentication.UserUnitType,
      });
    }
    // Handle auth and modal in our router
    if (data.CustomActions) {
      for (const entry of data.CustomActions) {
        if (entry.ActionType === 'Login') {
          authenticationStore.doLogout(true);
          modalStore.showModal({
            modalComponent: 'ModalLogin',
            single: true,
          });
        } else if (entry.ActionType === 'VueRedirect') {
          router.push({ name: entry.VueRedirectName });
        } else if (entry.ActionType === 'TriggerModal') {
          modalStore.showModal({
            modalComponent: entry.ModalData.Name,
            params: entry.ModalData.Data,
            first: true,
          });
        }
      }
    }

    if (data?.Data === LOGGED_OUT_MESSAGE) {
      authenticationStore.doLogout(true);
      modalStore.showModal({
        modalComponent: 'ModalLogin',
        single: true,
      });
    }

    if (resp?.headers) {
      const titlekey = 'title';
      if (titlekey in resp.headers) {
        document.title = resp.headers[titlekey];
      }

      const trackkey = 'track';
      if (trackkey in resp.headers) {
        useAdobeLaunchTracking().applyTrackingHeader(resp.headers[trackkey]);
      }
    }

    if (
      !okStatuses.includes(resp.status) ||
      (data.Error && data.Error.FatalError) ||
      (queueSettings && !queueSettings.IsLast)
    ) {
      return { IsSuccess: false, Data: null, Error: resp?.data?.Error } as const;
    }
    return {
      IsSuccess: true,
      Data: resp.data.Data as T['Data'],
      Error: resp?.data?.Error,
    } as const;
  }

  if (resp && okStatuses.includes(resp.status)) {
    return { IsSuccess: true, Data: null, Error: resp?.data?.Error } as const;
  }

  return {
    IsSuccess: resp?.data?.IsSuccess ?? false,
    Data: null,
    Error: resp?.data?.Error,
  } as const;
}

function runPreModules(settings: AxiosRequestConfig) {
  const authenticationStore = useAuthenticationStore();
  if (authenticationStore.isAuthenticated) {
    settings.headers = Object.assign(
      settings.headers || {},
      authenticationStore.getAuthenticationTokenHeaders,
    );
  } else {
    settings.headers = Object.assign(
      settings.headers || {},
      authenticationStore.getUnauthorizedHeader,
    );
  }
}

class Guid {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
