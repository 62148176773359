import { FinanceApi } from '@/src/core/api';
import { CancelReqCategory, Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { AirbusInvoice, BillingDocuments } from '@/src/core/types/api';
import { DisplayDateFormat } from '@/src/core/utils/dates';
import { mapAirbusDocuments } from '@/src/finance/services/documents-service';
import { useUserStore } from '@/src/profile/stores/user';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export interface IDocumentQuery {
  unitId?: string;
  fromDate?: string;
  toDate?: string;
  documentNumbers?: string[];
  emailAddresses?: string[] | string;
  orderNumbers?: string[];
  materialNumberExternals?: string[];
  poNumbers?: string;
  excludeInvoices?: boolean | string;
  excludeCredits?: boolean | string;
}

type DocumentType = 'CREDIT_NOTE' | 'INVOICE' | undefined;

export interface AirbusDocumentQuery {
  billingDateFrom?: string;
  billingDateTo?: string;
  cpoNumber?: string;
  documentType?: DocumentType;
  materialNumber?: string[];
  salesOrderNumber?: string[];
  invoiceDocumentNumber?: string[];
}

export const initialFilter: IDocumentQuery = {
  fromDate: dayjs().startOf('month').format(DisplayDateFormat),
  toDate: dayjs().format(DisplayDateFormat),
};

export const useDocumentsStore = defineStore('documents', () => {
  const userStore = useUserStore();
  const documents = ref<BillingDocuments[]>([]);
  const airbusDocuments = ref<BillingDocuments[]>([]);
  const isBusy = ref<boolean>(true);
  const selected = ref<boolean[]>([]);
  const filters = ref<IDocumentQuery>(Object.assign({}, initialFilter));

  const documentsUpdated = (payload: { documents: BillingDocuments[] }) => {
    documents.value = [];
    documents.value.push(...payload.documents);
  };

  const airbusDocumentsUpdated = (payload: { documents: AirbusInvoice[] }) => {
    airbusDocuments.value = mapAirbusDocuments(payload.documents);
  };

  const isBusyUpdated = (payload: { IsBusy: boolean }) => {
    isBusy.value = payload.IsBusy;
  };

  const reset = () => {
    filters.value = Object.assign({}, initialFilter);
    documents.value = [];
    airbusDocuments.value = [];
    isBusy.value = false;
  };

  const setFilters = (payload: IDocumentQuery) => {
    filters.value = Object.assign({}, filters.value, payload);
  };

  const resetFilters = () => {
    CancelReqCategory('documents');
    CancelReqCategory('airbus-documents');

    filters.value = Object.assign(
      {},
      { fromDate: filters.value.fromDate, toDate: filters.value.toDate },
    );
  };

  const fetchDocuments = async () => {
    const unitId = userStore.currentUser?.DefaultUnitUid;
    if (!unitId) {
      return;
    }
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess, Data } = await Req(
      {
        url: FinanceApi.GetDocuments,
        data: Object.assign({}, filters.value, { unitId }),
        method: 'POST',
      },
      new ReqQueue(ReqQueueTypes.Default, 'documents'),
      FinanceApi.handleGetDocuments,
    );

    if (IsSuccess && Data?.BillingDocumentInfos) {
      documentsUpdated({ documents: Data.BillingDocumentInfos });
    }
    isBusyUpdated({ IsBusy: false });

    return { IsSuccess };
  };

  const fetchAirbusDocuments = async () => {
    const unitId = userStore.currentUser?.DefaultUnitUid;
    if (!unitId) {
      return;
    }
    isBusyUpdated({ IsBusy: true });

    let documentType: DocumentType = undefined;

    if (filters.value.excludeCredits) {
      documentType = 'INVOICE';
    } else if (filters.value.excludeInvoices) {
      documentType = 'CREDIT_NOTE';
    }

    const airbusFilters: AirbusDocumentQuery = {
      billingDateFrom: filters.value.fromDate,
      billingDateTo: filters.value.toDate,
      cpoNumber: filters.value.poNumbers,
      materialNumber: filters.value.materialNumberExternals,
      salesOrderNumber: filters.value.orderNumbers,
      invoiceDocumentNumber: filters.value.documentNumbers,
      documentType,
    };

    const { IsSuccess, Data } = await Req(
      {
        url: FinanceApi.GetAirbusInvoices,
        data: Object.assign({}, airbusFilters, { unitId }),
        method: 'POST',
      },
      new ReqQueue(ReqQueueTypes.Default, 'airbus-documents'),
      FinanceApi.handleGetAirbusInvoices,
    );

    if (IsSuccess && Data?.invoice) {
      airbusDocumentsUpdated({ documents: Data.invoice as AirbusInvoice[] });
    }
    isBusyUpdated({ IsBusy: false });

    return { IsSuccess };
  };

  return {
    documents,
    isBusy,
    selected,
    filters,
    airbusDocuments,
    documentsUpdated,
    isBusyUpdated,
    reset,
    setFilters,
    resetFilters,
    fetchDocuments,
    fetchAirbusDocuments,
  };
});
