<template>
  <div class="app-default-layout">
    <slot name="header"></slot>
    <div class="app-default-layout__content">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<style src="./app-default-layout.scss" lang="scss" scoped></style>
