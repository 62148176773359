<template>
  <div>
    <div
      class="text-field-container"
      :class="{
        'is-active': isFocused || currentValue.length > 0 || placeholder || prefix,
        error: (hasError && isDirty) || (!valid && isDirty),
        'is-focused': isFocused || (hasError && isDirty) || (isDirty && !valid && isDirty),
        loading: loading,
        small: size === UiSize.Small,
        medium: size === UiSize.Medium,
        large: size === UiSize.Large,
        auto: size === UiSize.Auto,
        'shop-site': context === UiContext.ShopSite,
        'content-site': context === UiContext.ContentSite,
        default: type === UiVariant.Default || type === UiVariant.Primary,
        secondary: type === UiVariant.Secondary,
        transparent: type === UiVariant.Transparent,
        'has-icon-after': $slots.append,
        'has-icon-before': $slots.prepend,
        'is-required': required,
        'no-label': !label,
        skeleton: skeleton,
        disabled: disabled,
        readonly: readonly,
      }"
    >
      <template v-if="skeleton">
        <SkeletonLoader class="text-field-skeleton" :size="UiSize.Auto" />
      </template>
      <template v-else>
        <div class="text-field-icon-before" v-if="$slots.prepend">
          <slot name="prepend"></slot>
        </div>

        <div v-else-if="prefix" ref="prefixElement" class="text-field-prefix">{{ prefix }}</div>

        <label
          class="text-field-label"
          v-if="label"
          :for="id"
          :class="{
            'can-animate': canAnimate,
            hasError: (hasError && isDirty) || (!valid && isDirty),
          }"
          :title="titleTag(label)"
          >{{ label }}</label
        >
        <span
          v-if="asSpan"
          ref="inputfield"
          @click="click()"
          @focus="focus()"
          @blur="blur()"
          @keyup="keyup($event)"
          @input="input()"
          :id="id"
          class="text-field"
          >{{ currentValue }}</span
        >

        <input
          v-else
          ref="inputfield"
          @click="click()"
          @focus="focus()"
          @blur="blur()"
          @keydown="keydown($event)"
          @keyup="keyup($event)"
          @input="input()"
          @animationstart="checkAutoFill"
          :value="modelValue"
          :type="inputType"
          :name="name"
          :id="id || uniqueId()"
          :disabled="!!disabled || silentDisabled"
          :required="!!required"
          :readonly="!!readonly"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          v-model="currentValue"
          class="text-field"
          :class="{
            'silent-disabled': silentDisabled,
            'data-hj-whitelist': dataWhitelist,
            'data-hj-suppress': dataSuppress,
          }"
          :maxlength="vmaxLength"
          :style="prefixPush"
        />
        <!-- icon -->
        <div class="text-field-icon-after" v-if="$slots.append">
          <slot name="append"></slot>
        </div>
        <!-- icon -->
      </template>
    </div>
    <TransitionExpand transform>
      <InputFeedback
        :type="NotificationTypes.Error"
        v-if="(isDirty && hasError) || (isDirty && errorMessage)"
      >
        <transition name="fade" mode="out-in">
          <span key="1" v-if="required && currentValue.length <= 0">{{
            textK('UI_INPUTERROR_EMPTY')
          }}</span>
          <span key="2" v-else-if="vminLength > currentValue.length"
            >{{ textK('UI_INPUTERROR_MINLENGTH') }} {{ vminLength }}
            {{ textK('UI_COMMON_CHARACTERS') }}</span
          >
          <span key="3" v-else-if="errorMessage">{{ errorMessage }}</span>
          <span key="4" v-else-if="inputType === 'email'">{{ textK('UI_INPUTERROR_EMAIL') }}</span>
          <span key="5" v-else-if="inputType === 'password'">Password doesn't match pattern</span>
        </transition>
      </InputFeedback>
    </TransitionExpand>
  </div>
</template>

<style lang="scss" src="./text-field.scss" scoped></style>

<script lang="ts" setup>
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import InputFeedback from '@/src/core/components/ui/form/input-feedback/input-feedback.vue';
import SkeletonLoader from '@/src/core/components/ui/skeleton-loader/skeleton-loader.vue';
import { UiContext, UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import useTitleTag from '@/src/core/hooks/useTitleTag';
import { GenerateUniqueIdentifier } from '@/src/core/plugins/uuid4';
import { NotificationTypes } from '@/src/core/types/api';
import { IeKeys } from '@/src/core/types/keys';
import { emailRegEx, numberRegEx, passwordRegEx } from '@/src/core/utils/regex-library';
import { Key } from 'ts-key-enum';
import { onMounted, ref, watch } from 'vue';

interface Props {
  modelValue?: string;
  prefix?: string;
  inputType?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  autocomplete?: string;
  regex?: string | null;
  errorMessage?: string | null;
  id?: string;
  size?: UiSize;
  type?: UiVariant;
  context?: UiContext;
  vmaxLength?: number;
  vminLength?: number;
  loading?: boolean;
  skeleton?: boolean;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  disableTypeValidation?: boolean;
  silentDisabled?: boolean;
  asSpan?: boolean;
  dataWhitelist?: boolean;
  dataSuppress?: boolean;
  valid?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  autocomplete: 'off',
  modelValue: '',
  name: '',
  prefix: undefined,
  inputType: 'text',
  placeholder: '',
  regex: '',
  errorMessage: null,
  id: undefined,
  size: UiSize.Medium,
  type: UiVariant.Default,
  context: UiContext.ShopSite,
  vmaxLength: 255,
  vminLength: 0,
  loading: false,
  skeleton: false,
  required: false,
  disabled: false,
  readonly: false,
  disableTypeValidation: false,
  silentDisabled: false,
  asSpan: false,
  dataWhitelist: false,
  dataSuppress: false,
  valid: true,
});

const textK = useText();
const titleTag = useTitleTag;

const emit = defineEmits([
  'blur',
  'change',
  'click',
  'focus',
  'input', // levacy, this can possibly be replaced completely by 'update:modelValue'
  'keydown',
  'keyup',
  'valid',
  'update:modelValue',
]);

const inputfield = ref<HTMLInputElement | null>(null);
const prefixElement = ref<HTMLElement | null>(null);
const currentValue = ref('');
const regexPattern = ref<RegExp | null>(null);
const isFocused = ref(false);
const canAnimate = ref(false);
const isDirty = ref(false);
const hasError = ref(false);

// TODO: Fix typing
const prefixPush = ref({});

const validate = (currentValue: string) => {
  if (props.required && currentValue.length <= 0) {
    hasError.value = true;
  } else if (props.vminLength > currentValue.length && currentValue.length > 0) {
    hasError.value = true;
  } else if (currentValue.length > props.vmaxLength && currentValue.length > 0) {
    hasError.value = true;
  } else if (
    !props.disableTypeValidation &&
    currentValue.length > 0 &&
    props.inputType === 'email'
  ) {
    hasError.value = !emailRegEx.test(currentValue);
  } else if (
    !props.disableTypeValidation &&
    currentValue.length > 0 &&
    props.inputType === 'password'
  ) {
    hasError.value = !passwordRegEx.test(currentValue);
  } else {
    hasError.value = false;
  }

  emitIsValid(!hasError.value);
};

const typingValidation = ($event: KeyboardEvent) => {
  if (props.inputType === 'number' || props.inputType === 'tel') {
    numberRescriction($event);
  }
};

const numberRescriction = ($event: KeyboardEvent) => {
  const key: string = $event.key;
  const stringyValue: string = currentValue.value.toString();
  const selection: Selection | null = window.getSelection();

  if ($event.metaKey || $event.ctrlKey) {
    return;
  }

  switch (key) {
    case Key.Backspace:
    case Key.Tab:
    case Key.ArrowUp:
    case Key.ArrowRight:
    case Key.ArrowDown:
    case Key.ArrowLeft:
    case Key.Delete:
    case IeKeys.ArrowDown:
    case IeKeys.ArrowUp:
    case IeKeys.ArrowLeft:
    case IeKeys.ArrowRight:
      break;
    default:
      // Programmatic handling "maxlength" (when dealing with number)
      // ... and check if the input-data is "selected" (Range)

      if (selection && selection.type !== 'Range' && stringyValue.length >= props.vmaxLength) {
        $event.returnValue = false;
      }

      // REG-test for numbers
      if (!numberRegEx.test(key)) {
        $event.returnValue = false;
        $event.preventDefault();
      }
  }
};

const focus = () => {
  if (props.readonly) {
    return;
  }

  isFocused.value = true;
  emit('focus', currentValue.value);
};

const click = () => {
  emit('click', currentValue.value);
};

const blur = () => {
  const cv: string = '' + currentValue.value;
  validate(cv);
  isFocused.value = false;
  emit('blur', cv);
  isDirty.value = props.required || cv.length > 0;
  inputfield.value?.blur();
};

const keydown = ($event: KeyboardEvent) => {
  typingValidation($event);
  emit('keydown', $event);
};

const keyup = ($event: KeyboardEvent) => {
  emit('keyup', $event);
};

const input = () => {
  if (regexPattern.value) {
    checkAgainstRegex();
  }

  emit('input', currentValue.value);
  emit('update:modelValue', currentValue.value);
  emit('change', currentValue.value);
};

const checkAgainstRegex = () => {
  const inputValue = currentValue.value;

  // If a regex pattern is provided and the input value doesn't match it, remove invalid characters
  if (!regexPattern.value?.test(inputValue)) {
    currentValue.value = inputValue
      .split('')
      .filter((char) => regexPattern.value?.test(char))
      .join('');
  }
};

const emitIsValid = (valid: boolean) => {
  emit('valid', valid);
};

// // Chrome autofill hack
const checkAutoFill = (event: AnimationEvent) => {
  if (event.animationName.indexOf('onAutoFillStart') > -1) {
    isFocused.value = true;
  }
};

const uniqueId = () => {
  return new GenerateUniqueIdentifier().uuid4();
};

watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    if (newValue === oldValue) {
      return;
    }
    currentValue.value = '' + newValue;
  },
);

watch(
  () => currentValue.value,
  (newVal) => {
    validate(newVal);
  },
  { immediate: true },
);

watch(
  () => props.required,
  () => {
    validate('' + currentValue.value);
  },
);

onMounted(() => {
  currentValue.value = props.modelValue;

  if (props.regex) {
    regexPattern.value = new RegExp(props.regex);
  }

  if (props.prefix && prefixElement.value) {
    prefixPush.value = {
      paddingLeft: 'calc(1em + ' + (prefixElement.value.offsetWidth + 5) + 'px)',
    };
  }

  if (!props.required) {
    emitIsValid(true);
  }

  // AVOID ANIMATING LABEL ON LOAD
  setTimeout(() => {
    canAnimate.value = true;
  }, 250);
});

const resetValidation = () => {
  isDirty.value = false;
};

const setFocus = () => {
  inputfield.value?.focus();
};

const setSelect = () => {
  inputfield?.value?.select();
};

defineExpose({
  resetValidation,
  setFocus,
  setSelect,
});
</script>
