<template>
  <StandardDialogModal class="modal-forced-logout" :size="uiSize.Medium" :has-close-button="true">
    <template #header>
      <h3>{{ textK('UI_LOGIN_LOGOUT_MODAL_TITLE') }}</h3>
    </template>

    <template #body>
      <p>{{ textK('UI_LOGIN_LOGOUT_MODAL_DESCRIPTION') }}</p>
    </template>

    <template #actions>
      <VButton
        v-ui-test="'disable-account-ok-button'"
        :type="uiVariant.Default"
        @click="openLoginModal()"
        >{{ textK('UI_LOGIN_LOGOUT_MODAL_CTA') }}</VButton
      >
    </template>
  </StandardDialogModal>
</template>

<script setup lang="ts">
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';

const textK = useText();
const modalStore = useModalStore();
const openModal = useModal();
const uiSize = UiSize;
const uiVariant = UiVariant;

const closeModal = () => {
  modalStore.closeModal();
};

const openLoginModal = () => {
  closeModal();
  openModal({ modalComponent: 'ModalLogin' });
};
</script>
