import useElementIsInViewport from '@/src/core/hooks/useElementIsInViewport';
import router from '@/src/core/router';
import { PageType } from '@/src/core/types/adobe';
import { CartEntry, Product, QuoteDetail, UserLinkedAccount } from '@/src/core/types/api';
import { AirbusPart, ICart, IProductAdditionalData } from '@/src/core/types/interfaces';
import { DisplayDateFormat, SAPDataFormat } from '@/src/core/utils/dates';
import { getProcurementByRouteParams } from '@/src/market/services/procurements';
import {
  isAirbusProduct,
  isSatair,
  productAdditionalData,
} from '@/src/market/services/product-parts';
import { useProductStore } from '@/src/market/stores/product';
import { useUserStore } from '@/src/profile/stores/user';
import dayjs from 'dayjs';
import { RouteLocationNormalized } from 'vue-router';
import { useAuthenticationStore } from '../stores/authentication';

export const IN_STOCK = 'In Stock';
export const NOT_IN_STCOCK = 'Not in Stock';
export const NOT_APPLICABLE = 'N/A';
export const PRICED = 'Priced';
export const NO_PRICE = 'No Price';
export const LOGGED_IN = 'Logged in';
export const NOT_LOGGED_IN = 'Not logged in';
export const NOT_AVAILABLE = 'Not available';

export const getSku = (productId: string) => {
  const productStore = useProductStore();
  return productStore.productByCode(productId)?.Sku;
};
export const routeIncludesPath = (route: RouteLocationNormalized, path: string) =>
  route.fullPath.includes(path);

/**
 * Set default value for empty fields
 * @param entity - object entity
 */
export const defaultValueSetter = <T extends {}>(entity: T) =>
  Object.keys(entity).reduce((acc, key) => {
    const value = entity[key];
    const typeOfValue = typeof value;
    const isUndefined = typeOfValue === 'undefined' || value === '' || value === null;

    acc[key] = key === 'globalId' && isUndefined ? '' : isUndefined ? 'Not set' : value;

    return acc;
  }, {});

export const getCleanRouteName = (route: RouteLocationNormalized) => {
  const splittedRoute = route.fullPath.split('/');
  if (route.name === 'mainUmbracoContent') {
    const routeNameByFullPath = splittedRoute.filter((val) => val !== '').join(':');
    return routeNameByFullPath;
  }
  const routeNameByMatched = `${String(route.matched[0]?.name)}:${String(route?.name)}`;
  return routeNameByMatched;
};

export const getPageName = (route: RouteLocationNormalized) => {
  const pathMatch = route.params?.pathMatch;

  if (!pathMatch) {
    return route.path === '/' ? 'Home' : getCleanRouteName(route);
  }

  const sections = pathMatch;

  if (sections.length > 1) {
    return `${sections[0]}:${sections[1]}`;
  } else {
    return sections[0];
  }
};

/**
 * Get sections on the matched array in the route
 * @param route - route for vue-router
 * @returns list of sections
 */
export const getSections = (route: RouteLocationNormalized) => {
  if (route.params.pathMatch) {
    const result = route?.params.pathMatch;
    const mapped = result
      .map((values) => values)
      .reduce((acc, currentValue: string, index: number) => {
        acc['siteSection' + (index + 1)] = currentValue ?? 'Not set';
        return acc;
      }, {});
    return mapped;
  }

  return route.matched
    .slice(0, 3)
    .map((routeConfig) => routeConfig.name)
    .reduce((acc: { [x: string]: string }, name: string, index: number) => {
      acc['siteSection' + (index + 1)] = name ?? 'Not set';
      return acc;
    }, {});
};

/**
 * Get page info from vue router
 * @param to - target location being navigated to
 * @param from - target location being navigated away from
 * @returns page info
 */
export const getPageInfo = (
  to: RouteLocationNormalized,
  lastVisitedPage?: string,
  meta?: PageType,
) => {
  const sections = getSections(to);
  const pageType = meta?.type;
  const fromPath = lastVisitedPage?.split('/');
  const previousPageName =
    lastVisitedPage === '/' ? 'Home' : fromPath?.filter((path) => path !== '').join(':');

  const pageDetails = {
    prevPageName: previousPageName,
    pageName: getPageName(to),
    pageType,
  };

  const withDefaultValues = defaultValueSetter(pageDetails);
  return { ...withDefaultValues, ...sections };
};

/**
 * Receives cart and return if cart.Guid is not undefined
 * @param {ICart | QuoteDetail} cart
 * @returns boolean - true if cart.Guid is defined
 */
export const isFromCart = (cart: ICart | QuoteDetail): cart is ICart => {
  return typeof (cart as ICart).Guid !== 'undefined';
};

/**
 * Calculate time to conversion
 * @param {ICart} cart
 * @returns total number of days
 */
export const getTimeToConversion = (cart: ICart) => {
  if (!cart) {
    return 0;
  }
  const creationTime = dayjs(cart.CreationTime, DisplayDateFormat);
  const today = dayjs();
  const diff = today.diff(creationTime, 'day', true);
  const conversionDays = Math.floor(diff);

  return `${conversionDays} day(s)`;
};

/**
 * Determine lead time value
 * @param date - availability date
 * @returns lead time
 */
export const determineLeadTime = (date: string) => {
  const today = dayjs().format(SAPDataFormat);
  const isToday = dayjs(date).isSame(today);
  const availabilityDate = dayjs(date, SAPDataFormat);
  const diff = availabilityDate.diff(today, 'day', true);
  const leadTime = Math.floor(diff);

  return isToday ? IN_STOCK : leadTime < 0 ? 0 : leadTime;
};

/**
 * Get lead time from product
 * @param {IProductAdditionalData} product
 * @returns {string|number} Stock or Not in Stock
 */
export const getProductLeadTime = (
  product: Product,
  airbusLeadTime: number | string,
  productAdditionalInfo: IProductAdditionalData,
): string | number | undefined => {
  if (!product) {
    return 0;
  }
  if (isAirbusProduct(product.OwningSystem)) {
    return airbusLeadTime;
  }

  if (isSatair(product) && !isAirbusProduct(product.OwningSystem)) {
    const availabilityDate = productAdditionalInfo?.StockIndication;
    if (availabilityDate === 'N/A' || productAdditionalInfo?.UnknownDeliveryDate) {
      return NOT_APPLICABLE;
    } else if (availabilityDate === 'In stock') {
      return IN_STOCK;
    }
    return determineLeadTime(availabilityDate);
  }
  return NOT_APPLICABLE;
};

/**
 * Get lead time from cart
 * @param {CartEntry} cart
 * @returns {string|number} In Stock or leadtime
 */
export const getCartLeadTime = (cart: CartEntry): string | number => {
  const availabilityDate = cart.AvailabilityIndicator;
  if (availabilityDate === 'N/A' || cart.UnknownDeliveryDate) {
    return NOT_APPLICABLE;
  }

  return determineLeadTime(availabilityDate);
};

export class TrackingCache {
  private static productCode: string;
  private static productOwningSystem: string;
  private static productProcurementType: string = '';

  public static get productId() {
    return this.productCode;
  }

  public static setProductId(productCode: string) {
    this.productCode = productCode;
  }

  public static get owningSystem() {
    return this.productOwningSystem;
  }

  public static setOwningSystem(owningSystem: string) {
    this.productOwningSystem = owningSystem;
  }

  public static get procurementType() {
    return this.productProcurementType;
  }

  public static setProcurementType(procurementType: string) {
    this.productProcurementType = procurementType;
  }
}

/**
 * Get product information
 */
export const productData = () => {
  if (TrackingCache.productId && TrackingCache.owningSystem) {
    return productAdditionalData(TrackingCache.productId, TrackingCache.owningSystem);
  }

  return '';
};

/**
 * Get airbus procurement
 */
export const getAirbusProcurement = () => {
  const { params } = router.currentRoute.value;
  const airbusData = (productData() as AirbusPart)?.procurements;

  if (params) {
    return getProcurementByRouteParams(params, airbusData);
  } else {
    const type = TrackingCache.procurementType;
    return airbusData.filter((values) => values.procurementType === type).shift();
  }
};

/**
 * Get airbus leadtime
 */
export const airbusLeadTime = () => {
  const procurement = getAirbusProcurement();
  if (!procurement) {
    return NOT_APPLICABLE;
  }
  const { availabilityDate, availableStock } = procurement;
  if (availabilityDate) {
    return determineLeadTime(availabilityDate);
  } else if (availableStock > 0) {
    return IN_STOCK;
  } else {
    return NOT_APPLICABLE;
  }
};

/**
 * Get user authentication status
 * @returns {boolean} isAuthenticated
 */
export const isAuthenticated = (): boolean => {
  const authenticationStore = useAuthenticationStore();
  return authenticationStore.isAuthenticated;
};

/**
 * Tracking idle timer
 * @param {number} timer
 * @param {() => void} callback
 * @param {number} timeout
 */
export const trackingDelay = (timer: number, callback: () => void, timeout: number | undefined) => {
  clearTimeout(timer);
  timer = window.setTimeout(() => {
    callback();
  }, timeout);
};

/**
 * Header tool button click handler
 * @param {NodeListOf} elements <HTMLElement>
 * @param {() => void} callback
 */
export const headerButtonClick = (elements: NodeListOf<HTMLElement>, callback: () => void) => {
  elements.forEach((element) => {
    if (!element) {
      return;
    }
    element.addEventListener('click', (e) => {
      e.stopImmediatePropagation(); // prevent another listener to be called
      const activeElement = element.classList.contains('active');
      if (activeElement) {
        callback();
      }
    });
  });
};

/**
 * Intersection observer for viewport elements
 * @param {Product[]} queuedProducts
 * @param {IntersectionObserverInit} options
 * @returns Intersection observer setup
 */
export const trackingIntersectionObserver = (
  queuedProducts: Product[],
  options: IntersectionObserverInit,
) => {
  const productStore = useProductStore();

  return new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting || entry.intersectionRatio > 0) {
        const id = entry.target.getAttribute('id');
        const product = productStore.productById(id!) as Product;
        queuedProducts.push(product); // push visible elements in queue
      }
    }, options);
  });
};

/**
 * Reset/clear and detect elements in viewport
 * @param {NodeListOf<Element>} elements
 * @param {Product[]} queuedProducts
 */
export const clearAndDetectElements = (
  elements: NodeListOf<Element>,
  queuedProducts: Product[],
) => {
  const productStore = useProductStore();
  elements.forEach((element) => {
    if (useElementIsInViewport(element)) {
      const id = element.getAttribute('id');
      const product = productStore.productById(id!);
      queuedProducts.push(product as Product);
    }
  });
};

export const getUserCurrentAccount = () => {
  const userStore = useUserStore();
  return userStore.sortedLinkedAccounts.reduce((acc, item) => {
    if (item.CurrentAccount === true) {
      return item;
    }
    return acc;
  }, {} as UserLinkedAccount);
};

/**
 * Remove duplicates from array and merge into string
 * @param {string[]} data
 * @returns set of array
 */
export const removeDuplicates = (data: string[]) => {
  return [...new Set(data)].join(', ');
};
