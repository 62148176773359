import { IVSelect } from '@/src/core/types/ui';

export const priorityOptions: IVSelect[] = [
  {
    value: 'RTN',
    label: 'Routine (RTN)',
  },
  {
    value: 'USR',
    label: 'Urgent Stock Replenishment (USR)',
  },
  {
    value: 'WSP',
    label: 'Work Stoppage (WSP)',
  },
  {
    value: 'AOG',
    label: 'Aircraft on Ground (AOG)',
  },
];
