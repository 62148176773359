import { CartApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { Carrier } from '@/src/core/types/api';
import { ICarriers } from '@/src/core/types/interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCarriersStore = defineStore('carriers', () => {
  const allCarriers = ref<ICarriers>({} as ICarriers);
  const isBusy = ref<boolean>(false);

  const carriersUpdated = (payload: { Carriers: ICarriers }) => {
    allCarriers.value = Object.assign({}, payload.Carriers);
  };

  const isBusyUpdated = (payload: { IsBusy: boolean }) => {
    isBusy.value = payload.IsBusy;
  };

  const reset = () => {
    allCarriers.value = {} as ICarriers;
    isBusy.value = false;
  };

  const fetchCarriers = async (payload: { sellerId: string }) => {
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess, Data } = await Req({
      url: CartApi.FetchCarriers('|', payload.sellerId), // TODO: Backend should remove the need to have a PARAM here
    });

    if (IsSuccess) {
      carriersUpdated({ Carriers: Data });
    }

    isBusyUpdated({ IsBusy: false });
  };

  const carrier = (carrierCode: string) => {
    return (
      allCarriers.value.Carriers?.find((carrierEntry) => carrierEntry.Code === carrierCode) ||
      ({} as Carrier)
    );
  };

  return {
    allCarriers,
    isBusy,
    reset,
    fetchCarriers,
    carrier,
  };
});
