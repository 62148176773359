<template>
  <AccordionItem :index="0" :is-open="false" :title="shop.LocationDisplayName">
    <template
      v-if="
        shop.ShipFromAddress?.FormattedAddress ||
        shop.ShipFromAddress?.Country ||
        shop.TermsOfDelivery
      "
    >
      <VSimpleTable
        class="product-details-table"
        :headers="[textK('UI_SELLERPAGE_GENERAL_INFORMATION'), '']"
      >
        <VSimpleTableRow
          v-if="shop.ShipFromAddress?.FormattedAddress || shop.ShipFromAddress?.Country"
          v-ui-test="'ship-from-address'"
        >
          <VSimpleTableCell
            class="product-details-cell--index"
            :label="'cell-label'"
            :style="{ width: '50%' }"
          >
            {{ textK('UI_SELLERPAGE_SHIP_FROM_ADDRESS') }}
          </VSimpleTableCell>
          <VSimpleTableCell class="align-right" :label="'cell-value'" :style="{ width: '50%' }">
            <template v-if="shop.ShipFromAddress.Line1">{{ shop.ShipFromAddress.Line1 }}</template>
            <br v-if="shop.ShipFromAddress.Line2" />
            <template v-if="shop.ShipFromAddress.Line2">{{ shop.ShipFromAddress.Line2 }}</template>
            <br v-if="shop.ShipFromAddress.Town" />
            <template v-if="shop.ShipFromAddress.Town">{{ shop.ShipFromAddress.Town }}</template>
            <template v-if="shop.ShipFromAddress.PostalCode">
              <br />
              <span>, {{ shop.ShipFromAddress.PostalCode }}</span>
            </template>
            <br v-if="shop.ShipFromAddress.District" />
            <template v-if="shop.ShipFromAddress.District">{{
              shop.ShipFromAddress.District
            }}</template>
            <br v-if="shop.ShipFromAddress.Country" />
            <template v-if="shop.ShipFromAddress.Country">{{
              shop.ShipFromAddress.Country.Name
            }}</template>
          </VSimpleTableCell>
        </VSimpleTableRow>

        <VSimpleTableRow
          v-if="shop.TermsOfDelivery?.Name && shop.TermsOfDelivery?.Code"
          v-ui-test="'terms-of-delivery'"
        >
          <VSimpleTableCell
            class="product-details-cell--index"
            :label="'cell-label'"
            :style="{ width: '50%' }"
          >
            {{ textK('UI_SELLERPAGE_DELIVERY_TERMS') }}
          </VSimpleTableCell>
          <VSimpleTableCell class="align-right" :label="'cell-value'" :style="{ width: '50%' }">
            {{ shop.TermsOfDelivery.Name }} {{ shop.TermsOfDelivery.Code }}
          </VSimpleTableCell>
        </VSimpleTableRow>

        <VSimpleTableRow
          v-if="shop.RestrictedCountries && shop.RestrictedCountries.length > 0"
          v-ui-test="'restricted-countries'"
        >
          <VSimpleTableCell
            class="product-details-cell--index"
            :label="'cell-label'"
            :style="{ width: '50%' }"
          >
            {{ textK('UI_SELLERPAGE_RESTRICTED_COUNTRIES') }}
          </VSimpleTableCell>
          <VSimpleTableCell class="align-right" :label="'cell-value'" :style="{ width: '50%' }">
            <div v-for="country in shop.RestrictedCountries" :key="country.IsoCode">
              <span> {{ country.Name }}, {{ country.IsoCode }} </span>
              <br />
            </div>
          </VSimpleTableCell>
        </VSimpleTableRow>
      </VSimpleTable>
    </template>

    <VSimpleTable
      v-if="shop.Authorisations && shop.Authorisations.length > 0"
      class="product-details-table"
      :headers="[textK('UI_SELLERPAGE_AUTHORISATIONS'), '']"
    >
      <VSimpleTableRow v-for="auth in shop.Authorisations" :key="auth.AuthDocUrl">
        <VSimpleTableCell
          class="product-details-cell--index"
          :label="'cell-label'"
          :style="{ width: '50%' }"
        >
          {{ auth.Label }}
        </VSimpleTableCell>
        <VSimpleTableCell class="align-right" :label="'cell-value'" :style="{ width: '50%' }">
          <template v-if="auth.ExpirationDate">
            <span
              >{{ textK('UI_SELLERPAGE_EXPIRATIONDATE_TEXT') }}
              <span>{{ DateFormatUTC(auth.ExpirationDate) }}</span>
            </span>
          </template>
          <template v-if="auth.Document?.Url">
            <br />
            <DownloadLink
              :file-name="textK('UI_SELLERPAGE_DOWNLOAD_ATTACHMENT')"
              :url="auth.Document.Url"
            />
          </template>
        </VSimpleTableCell>
      </VSimpleTableRow>
    </VSimpleTable>

    <template v-if="shop.Departments && shop.Departments.length > 0">
      <h4>{{ textK('UI_SELLERPAGE_CONTACT_DETAILS') }}</h4>
      <VSimpleTable
        v-for="department in shop.Departments"
        :key="department.DepartmentName"
        class="product-details-table"
        :headers="[department.DepartmentName, '']"
      >
        <template v-for="info in department.ShopContactDepartmentInfos">
          <VSimpleTableRow
            v-for="[key, value] in Object.entries(info)"
            v-ui-test="'auths'"
            :key="key"
          >
            <template v-if="key && value !== ' '">
              <VSimpleTableCell
                class="product-details-cell--index"
                :label="'cell-label'"
                :style="{ width: '50%' }"
              >
                {{ formatDepartmentInfoKey(key) }}
              </VSimpleTableCell>
              <VSimpleTableCell class="align-right" :label="'cell-value'" :style="{ width: '50%' }">
                <span v-html="value"></span>
              </VSimpleTableCell>
            </template>
          </VSimpleTableRow>
        </template>
      </VSimpleTable>
    </template>
  </AccordionItem>
</template>

<script setup lang="ts">
import AccordionItem from '@/src/core/components/ui/accordion/accordion-item/accordion-item.vue';
import DownloadLink from '@/src/core/components/ui/download-link/download-link.vue';
import VSimpleTable from '@/src/core/components/ui/simple-table/simple-table.vue';
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import useText from '@/src/core/hooks/useText';
import { MarketplaceShop } from '@/src/core/types/api';
import { DateFormatUTC } from '@/src/core/utils/dates';
import { formatDepartmentInfoKey } from '@/src/market/utils/seller-details';

export interface Props {
  shop: MarketplaceShop;
}

const textK = useText();
defineProps<Props>();
</script>
