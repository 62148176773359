export const DEFAULT_ADDRESS_SEARCHABLE_KEYS = [
  'CompanyName',
  'LastName',
  'Line1',
  'Line2',
  'RegionCode',
  'PostalCode',
  'Town',
  'PostalCodeTown',
  'Country.IsoCode',
];

export const AIRBUS_ONLY_SEARCHABLE_KEYS = ['ShipToCode'];
