<template>
  <div class="content-navigation-toggle">
    <button
      class="content-navigation-toggle__trigger"
      v-ui-test="'toggle-content-navigation'"
      @click="toggleNavigation(navigationId)"
    >
      <MenuIcon :is-active="isNavigationActive" />
    </button>

    <NavigationDropdown :dropdown-id="navigationId" :lock-scroll="true">
      <NavigationDropdownContainer>
        <div class="content-navigation-toggle__items" v-ui-test="'content-navigation'">
          <router-link
            v-ui-test="'content-navigation-item'"
            v-for="navigationItem in navigation"
            :key="navigationItem.Id"
            :to="navigationItem.Url"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <ContentNavigationListItem
              class="content-navigation-toggle__list-item"
              :size="uiSize.Large"
              :navigation-item="navigationItem"
              :route-href="href"
              :no-link="navigationItem.NoLink"
              :route-navigate="navigate"
              :active="isActive"
              :exact-active="isExactActive"
            >
              <ContentNavigationList :navigation="navigationItem.SubItems" />
            </ContentNavigationListItem>
          </router-link>
        </div>
      </NavigationDropdownContainer>
    </NavigationDropdown>
  </div>
</template>

<style lang="scss" src="./content-navigation-toggle.scss" />

<script lang="ts">
import { Vue, Component, Watch, toNative } from 'vue-facing-decorator';
import ContentNavigationListItem from '@/src/content/components/content-navigation/content-navigation-list/content-navigation-list-item/content-navigation-list-item.vue';
import ContentNavigationList from '@/src/content/components/content-navigation/content-navigation-list/content-navigation-list.vue';
import NavigationDropdownContainer from '@/src/core/components/navigation/navigation-dropdown/navigation-dropdown-container/navigation-dropdown-container.vue';
import NavigationDropdown from '@/src/core/components/navigation/navigation-dropdown/navigation-dropdown.vue';
import MenuIcon from '@/src/core/components/ui/ani-icons/menu-icon/menu-icon.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import { useNavigationStore } from '@/src/core/stores/navigation';
import { useUIStore } from '@/src/core/stores/ui';

@Component({
  components: {
    NavigationDropdown,
    NavigationDropdownContainer,
    ContentNavigationList,
    ContentNavigationListItem,
    MenuIcon,
  },
})
export class ContentNavigationToggle extends Vue {
  public navigationStore = useNavigationStore();
  public uiStore = useUIStore();
  public navigationId: string = 'toggleNavigation';
  public toggleNavigation = this.uiStore.toggleNavigationDropdown;
  public uiSize = UiSize;

  public get navigation() {
    return this.navigationStore.menu;
  }

  public get isNavigationActive() {
    return this.uiStore.isNavigationDropdownActive(this.navigationId);
  }

  @Watch('$route') private routeChange() {
    this.uiStore.setNavigationDropdownId({ id: null });
  }

  public mounted() {
    this.navigationStore.fetchMenu();
  }
}

export default toNative(ContentNavigationToggle);
</script>
