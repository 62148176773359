<template>
  <div class="product-volume-discounts-table">
    <VSimpleTable
      v-if="showTable"
      :headers="[
        textK('UI_PRODUCT_QUANTITY'),
        textK('UI_PRODUCT_PRICEUNIT'),
        textK('UI_PRODUCT_PRICESAVE'),
      ]"
    >
      <VSimpleTableRow
        class="product-volume-discounts-table__row"
        v-for="(volumePrice, i) in volumePrices"
        :key="i"
        :on-click="
          () => {
            selectVolume(volumePrice);
          }
        "
        :selected="i === currentDiscountIndex"
      >
        <VSimpleTableCell class="product-volume-discounts-table__cell">
          {{ volumePrice.MinQuantity
          }}{{
            volumePrice.MaxQuantity === 0 ? '+' : ' - ' + volumePrice.MaxQuantity
          }}</VSimpleTableCell
        >
        <VSimpleTableCell
          class="product-volume-discounts-table__cell product-volume-discounts-table__cell--price"
        >
          {{ volumePrice.BasePrice.FormattedValue }}</VSimpleTableCell
        >
        <VSimpleTableCell class="product-volume-discounts-table__cell">{{
          volumePrice.VolumeDiscountPercentage === 0
            ? '0%'
            : volumePrice.FormattedVolumeDiscountPercentage
        }}</VSimpleTableCell>
      </VSimpleTableRow>
    </VSimpleTable>
    <span class="product-volume-discounts-table__disclaimer" v-if="slowMoverProduct">
      {{ textK('UI_PRODUCT_SLOWMOVER_PROMOTION_DESCRIPTION') }}
    </span>
  </div>
</template>

<style lang="scss" src="./product-volume-discounts-table.scss" scoped></style>

<script setup lang="ts">
import VSimpleTable from '@/src/core/components/ui/simple-table/simple-table.vue';
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import useText from '@/src/core/hooks/useText';
import { OwningSystem, PriceBreakInfo } from '@/src/core/types/api';
import { productAdditionalData } from '@/src/market/services/product-parts';
import { isBlueOwningSystem, isSellable } from '@/src/market/services/product-rules';
import { useCartStore } from '@/src/market/stores/cart';
import { useListsStore } from '@/src/market/stores/list';
import { computed } from 'vue';

interface Props {
  currentDiscountIndex: number;
  volumePrices: PriceBreakInfo[];
  id: string;
  listId?: string;
  slowMoverProduct: boolean;
  owningSystem: string;
}

const props = defineProps<Props>();
const emit = defineEmits(['change']);
const textK = useText();
const listStore = useListsStore();
const cartStore = useCartStore();

const productAdditionalDataValue = computed(() =>
  productAdditionalData(props.id, props.owningSystem),
);
const isBlueOwningSystemValue = computed(() =>
  isBlueOwningSystem(props.owningSystem as OwningSystem),
);
const showTable = computed(() => {
  if (isBlueOwningSystemValue.value) {
    return props.volumePrices.length > 0;
  }
  return props.volumePrices.length > 1;
});

const isBlueProductSellable = computed(() => isSellable(productAdditionalDataValue.value));

const selectVolume = (volume: PriceBreakInfo) => {
  emit('change');

  if (isBlueOwningSystemValue.value && !isBlueProductSellable.value) {
    return;
  }

  if (props.listId) {
    listStore.updateProductQuantity({
      listCode: props.listId,
      productId: props.id,
      quantity: volume.MinQuantity,
    });
  } else {
    cartStore.addProductToCurrentCart({
      productId: props.id,
      quantity: volume.MinQuantity,
      entryId: cartStore.getCartEntry(props.id).EntryNumber,
    });
  }
};
</script>
