<template>
  <StandardDialogModal :size="uiSize.Medium">
    <template #header>
      <h3 class="modal-lease__header">
        <div class="modal-lease__header--code">
          {{ product.ManufacturerAid
          }}<span class="cage">:{{ product.ProductManufacturer.CageCode }} </span>
        </div>
        <div class="modal-lease__header--name">
          {{ product.Name && product.Name.length > 0 ? product.Name : '-' }}
        </div>
      </h3>
    </template>
    <template #body>
      <VSimpleTable class="modal-lease__table" :headers="['Pricing details', '']">
        <tbody>
          <VSimpleTableRow v-ui-test="'product-document-infos'">
            <VSimpleTableCell class="fullwidth-cell">Unit price (sales new)</VSimpleTableCell>
            <VSimpleTableCell>
              <span class="modal-lease__table--cell"
                >{{
                  leaseInfo?.unitPrice ? `${currency} ${formatPrice(leaseInfo?.unitPrice)}` : 'N/A'
                }}
              </span></VSimpleTableCell
            >
          </VSimpleTableRow>
          <VSimpleTableRow v-ui-test="'product-document-infos'">
            <VSimpleTableCell class="fullwidth-cell">Minimum lease period</VSimpleTableCell>
            <VSimpleTableCell>
              <span class="modal-lease__table--cell">{{
                leaseInfo?.minimumLease?.numberOfDays
                  ? `${leaseInfo?.minimumLease?.numberOfDays} days`
                  : 'N/A'
              }}</span></VSimpleTableCell
            >
          </VSimpleTableRow>
          <VSimpleTableRow v-ui-test="'product-document-infos'">
            <VSimpleTableCell class="fullwidth-cell">Minimum lease charges</VSimpleTableCell>
            <VSimpleTableCell>
              <span class="modal-lease__table--cell"
                >{{
                  leaseInfo?.minimumLease?.subtotal
                    ? `${currency} ${formatPrice(leaseInfo.minimumLease.subtotal)}`
                    : 'N/A'
                }}
                <Tooltip v-if="leaseInfo?.minimumLease?.subtotal" position="top">
                  <span class="banner"> <svgicon name="ui-info" /></span>
                  <template #tooltip-content>
                    {{ minimumLeasePeriodText }}
                  </template>
                </Tooltip>
              </span>
            </VSimpleTableCell>
          </VSimpleTableRow>
          <VSimpleTableRow v-ui-test="'product-document-infos'">
            <VSimpleTableCell class="fullwidth-cell">Additional charges per day</VSimpleTableCell>
            <VSimpleTableCell>
              <span class="modal-lease__table--cell"
                >{{
                  leaseInfo?.additionalLease?.subtotal
                    ? `${currency} ${formatPrice(leaseInfo.additionalLease.subtotal)}`
                    : 'N/A'
                }}
                <Tooltip v-if="leaseInfo?.additionalLease?.subtotal" position="top">
                  <span class="banner"> <svgicon name="ui-info" /></span>
                  <template #tooltip-content>
                    {{ additionalLeaseChargeText }}
                  </template>
                </Tooltip>
              </span>
            </VSimpleTableCell>
          </VSimpleTableRow>
          <VSimpleTableRow v-ui-test="'product-document-infos'">
            <VSimpleTableCell class="fullwidth-cell">Lease handling fee</VSimpleTableCell>
            <VSimpleTableCell>
              <span class="modal-lease__table--cell">{{
                leaseInfo?.handlingFee
                  ? `${currency} ${formatPrice(leaseInfo?.handlingFee)}`
                  : 'N/A'
              }}</span></VSimpleTableCell
            >
          </VSimpleTableRow>
        </tbody>
      </VSimpleTable>
    </template>
  </StandardDialogModal>
</template>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VSimpleTable from '@/src/core/components/ui/simple-table/simple-table.vue';
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { Product } from '@/src/core/types/api';
import { Procurement } from '@/src/core/types/interfaces';
import { formatPrice } from '@/src/core/utils/math';
import { computed } from 'vue';

interface Params {
  procurement: Procurement;
  product: Product;
}
const uiSize = UiSize;
const textK = useText();
const modalStore = useModalStore();

const procurement = computed(() => {
  return (modalStore.params as Params)?.procurement;
});

const leaseInfo = computed(() => {
  return procurement.value.leaseInfo;
});

const currency = computed(() => {
  return leaseInfo.value?.currency;
});

const product = computed(() => {
  return (modalStore.params as Params)?.product;
});

const minimumLeasePeriodText = computed(() => {
  if (product.value.AirbusProprietary) {
    return textK('UI_PRODUCT_MINIMUM_LEASE_CHARGE_TEXT_PARTS');
  }
  return textK('UI_PRODUCT_MINIMUM_LEASE_CHARGE_TEXT_TOOL').replace(
    /\[\[minimum-lease-charge-percent\]\]/gi,
    procurement?.value.leaseInfo?.minimumLease.percentagePerDay as string,
  );
});

const additionalLeaseChargeText = computed(() => {
  if (product.value.AirbusProprietary) {
    return textK('UI_PRODUCT_ADDITIONAL_DAY_LEASE_CHARGE_PART_TEXT');
  }
  return textK('UI_PRODUCT_ADDITIONAL_DAY_LEASE_CHARGE_TEXT').replace(
    /\[\[additional-lease-charge-percent\]\]/gi,
    leaseInfo?.value?.additionalLease.percentagePerDay as string,
  );
});
</script>

<style lang="scss" src="./modal-lease-price.scss" scoped></style>
