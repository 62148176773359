<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 700 700"
  >
    <defs />
    <path fill="#8fa3aa" d="M0 0h700v700H0z" />
    <defs>
      <path id="finance-a" d="M0 351.9h349.6V700H0z" />
    </defs>
    <clipPath id="finance-b">
      <use overflow="visible" xlink:href="#finance-a" />
    </clipPath>
    <g
      fill="none"
      stroke="#a9c0cf"
      stroke-miterlimit="10"
      stroke-width="9"
      clip-path="url(#finance-b)"
    >
      <path
        d="M60.1 1903.1L-1114.2 728.8M76.8 1886.4L-1097.5 712.1M93.4 1869.7L-1080.9 695.5M110.1 1853.1L-1064.2 678.8M126.7 1836.4L-1047.5 662.1M143.4 1819.8L-1030.9 645.5M160.1 1803.1L-1014.2 628.8M176.7 1786.5L-997.6 612.2M193.4 1769.8L-980.9 595.5M210 1753.1L-964.2 578.9M226.7 1736.5L-947.6 562.2M243.4 1719.8L-930.9 545.5M260 1703.2L-914.3 528.9M276.7 1686.5L-897.6 512.2M293.3 1669.8L-881 495.6M310 1653.2L-864.3 478.9M326.6 1636.5L-847.6 462.2M343.3 1619.9L-831 445.6M360 1603.2L-814.3 428.9M376.6 1586.5L-797.7 412.3M393.3 1569.9L-781 395.6M409.9 1553.2L-764.3 378.9M426.6 1536.6L-747.7 362.3M443.3 1519.9L-731 345.6M459.9 1503.2L-714.4 329M476.6 1486.6L-697.7 312.3M493.2 1469.9L-681 295.6M509.9 1453.3L-664.4 279M526.6 1436.6L-647.7 262.3M543.2 1419.9L-631.1 245.7M559.9 1403.3L-614.4 229M576.5 1386.6L-597.7 212.3M593.2 1370L-581.1 195.7M609.9 1353.3L-564.4 179M626.5 1336.6L-547.8 162.4M643.2 1320L-531.1 145.7M659.8 1303.3L-514.4 129M676.5 1286.7L-497.8 112.4M693.2 1270L-481.1 95.7M709.8 1253.3L-464.5 79.1M726.5 1236.7L-447.8 62.4M743.1 1220L-431.1 45.7M759.8 1203.4L-414.5 29.1M776.5 1186.7L-397.8 12.4M793.1 1170L-381.2-4.2M809.8 1153.4L-364.5-20.9M826.4 1136.7L-347.8-37.6M843.1 1120.1L-331.2-54.2M859.8 1103.4L-314.5-70.9M876.4 1086.7L-297.9-87.5M893.1 1070.1L-281.2-104.2M909.7 1053.4L-264.5-120.9M926.4 1036.8L-247.9-137.5M943.1 1020.1L-231.2-154.2M959.7 1003.4L-214.6-170.8M976.4 986.8L-197.9-187.5M993 970.1L-181.2-204.2M1009.7 953.5L-164.6-220.8M1026.4 936.8L-147.9-237.5M1043 920.1L-131.3-254.1M1059.7 903.5L-114.6-270.8M1076.3 886.8L-97.9-287.5M1093 870.2L-81.3-304.1M1109.7 853.5L-64.6-320.8M1126.3 836.8L-48-337.4"
      />
    </g>
    <path fill="#e6e578" d="M174.9 291.2h116.7v116.7H174.9zM408.3 291.2H525v116.7H408.3z" />
    <path fill="#e1e000" d="M525 291.2H408.3V174.4z" />
    <path fill="#e6e578" d="M408.3 524.6V407.9H525zM174.9 407.9h116.7v116.6z" />
    <path fill="#e1e000" d="M291.6 174.4v116.8H174.9z" />
    <path fill="#2eeca5" d="M349.6 291.2H408v58.3z" />
    <path fill="#e6e578" d="M291.6 174.4h116.7v116.7H291.6z" />
    <path fill="#e1e000" d="M291.6 407.9h116.7v116.7H291.6z" />
  </svg>
</template>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
@Component({
  components: {},
})
export class FinanceLogo extends Vue {}

export default toNative(FinanceLogo);
</script>
