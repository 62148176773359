<template>
  <div
    class="standard-dialog-container"
    tabindex="-1"
    :class="{
      'no-padding': !usePadding,
      small: size === uiSize.Small,
      medium: size === uiSize.Medium,
      large: size === uiSize.Large,
      xlarge: size === uiSize.XLarge,
      lilly: size === uiSize.Lilly,
      auto: size === uiSize.Auto,
      'secondary-top-border': borderLine === uiVariant.Secondary,
    }"
  >
    <transition name="fadeUp">
      <CloseModalButton v-if="!loading && hasCloseButton"></CloseModalButton>
    </transition>

    <slot />

    <Spinner class="spinner" v-if="loading" :delay="100"></Spinner>
  </div>
</template>

<style lang="scss" src="./standard-dialog-container.scss" scoped></style>

<script lang="ts" setup>
import CloseModalButton from '@/src/core/components/modals/ui/close-modal-button/CloseModalButton.vue';
import Spinner from '@/src/core/components/ui/spinner/spinner.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';

const uiVariant = UiVariant;
const uiSize = UiSize;

interface Props {
  hasCloseButton?: boolean;
  usePadding?: boolean;
  size?: UiSize;
  borderLine?: UiVariant;
  loading?: boolean;
}

withDefaults(defineProps<Props>(), {
  hasCloseButton: true,
  usePadding: true,
  size: UiSize.Medium,
  borderLine: UiVariant.Default,
  loading: false,
});
</script>
