<template>
  <div>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { IeKeys } from '@/src/core/types/keys';
import { Key } from 'ts-key-enum';
import { onUnmounted, ref, watch } from 'vue';

interface Props {
  active?: boolean;
  listLength?: number;
}

const props = withDefaults(defineProps<Props>(), {
  active: true,
  listLength: 0,
});

const emit = defineEmits([
  'enter',
  'backspace',
  'escape',
  'arrowNavigation',
  'arrowHorizontalNavigation',
  'tab',
  'searchable',
]);

const hoveredIndex = ref(-1);

const onPressKey = ($event: KeyboardEvent) => {
  if (!props.active) {
    return;
  }

  const key = $event.key;

  switch (key) {
    case Key.Enter:
      emit('enter', $event);
      break;

    case Key.Backspace:
      emit('backspace', $event);
      break;

    case Key.Escape:
    case IeKeys.Escape:
      emit('escape', $event);
      break;

    case Key.ArrowUp:
    case IeKeys.ArrowUp:
      $event.preventDefault();
      emit('arrowNavigation', -1);
      break;

    case Key.ArrowDown:
    case IeKeys.ArrowDown:
      $event.preventDefault();
      emit('arrowNavigation', 1);
      break;

    case Key.ArrowLeft:
    case IeKeys.ArrowLeft:
      emit('arrowHorizontalNavigation', -1);
      break;

    case Key.ArrowRight:
    case IeKeys.ArrowRight:
      emit('arrowHorizontalNavigation', 1);
      break;

    case Key.Tab:
      emit('tab', $event);
      break;
  }

  if (!$event.metaKey && key.match(/^[a-zA-Z;0-9 ]+$/) && key.length <= 1) {
    emit('searchable', key);
    return;
  }
};

watch(
  () => props.active,
  (isActive: boolean) => {
    if (isActive) {
      window.addEventListener('keydown', onPressKey);
    } else {
      window.removeEventListener('keydown', onPressKey);
    }
  },
  { immediate: true },
);

onUnmounted(() => {
  window.removeEventListener('keydown', onPressKey);
});
</script>
