<template>
  <div
    class="product-volume-discounts"
    v-if="(hasVolumeDiscount && ruleStatusOk) || hasSlowMoverPromotionPrice"
    v-click-outside="closeTable"
  >
    <ProductInfoBanner
      :has-dropdown="true"
      :show-tooltip="false"
      v-if="hasVolumeDiscount && ruleStatusOk"
    >
      <template #banner-text>
        <div class="product-volume-discounts__label" v-ui-test="'volume-discount-label'">
          <template v-if="hasVolumeDiscount && ruleStatusOk">
            <template
              v-if="
                currentVolumeDiscount &&
                ((currentVolumeDiscount.MinQuantity === currentQuantity &&
                  currentVolumeDiscount.VolumeDiscountPercentage > 0) ||
                  currentVolumeDiscount.MaxQuantity === 0)
              "
            >
              <Tooltip position="top">
                <span @click.stop="handleDropdownOpen()">
                  {{ textK('UI_PRODUCT_PRICEBREAK_YOUSAVE') }}
                  {{ currentVolumeDiscount.FormattedVolumeDiscountPercentage }}</span
                >
                <template #tooltip-content>{{
                  textK('UI_PRODUCT_PRICEBREAK_SAVEUPTO_TOOLTIP')
                }}</template>
              </Tooltip>
            </template>
            <template
              v-else-if="stepsTillNextPrice && stepsTillNextPrice > 0 && currentQuantity > 0"
            >
              <Tooltip position="top">
                <span @click.stop="handleDropdownOpen()"
                  >{{ textK('UI_PRODUCT_PRICEBREAK_BUTMORE_1') }} {{ stepsTillNextPrice }}
                  {{ textK('UI_PRODUCT_PRICEBREAK_BUTMORE_2') }}
                  {{ nextPriceStep?.FormattedVolumeDiscountPercentage }}</span
                >
                <template #tooltip-content>{{
                  textK('UI_PRODUCT_PRICEBREAK_SAVEUPTO_TOOLTIP')
                }}</template>
              </Tooltip>
            </template>
            <template v-else>
              <Tooltip position="top">
                <span @click.stop="handleDropdownOpen()"
                  >{{ textK('UI_PRODUCT_PRICEBREAK_SAVEUPTO') }}
                  {{ lastVolumeDiscount.FormattedVolumeDiscountPercentage }}</span
                >
                <template #tooltip-content>{{
                  textK('UI_PRODUCT_PRICEBREAK_SAVEUPTO_TOOLTIP')
                }}</template>
              </Tooltip>
            </template>
          </template>
          <template v-else>
            <Tooltip position="top">
              <span @click.stop="handleDropdownOpen()"
                >{{ textK('UI_PRODUCT_PRICEBREAK_SAVEUPTO') }}
                {{ lastVolumeDiscount.FormattedVolumeDiscountPercentage }}</span
              >
              <template #tooltip-content>{{
                textK('UI_PRODUCT_PRICEBREAK_SAVEUPTO_TOOLTIP')
              }}</template>
            </Tooltip>
          </template>
        </div>
      </template>

      <template #dropdown-icon>
        <button
          :class="{
            'product-volume-discounts__icon__product__card': isProductCard,
            'product-volume-discounts__icon': !isProductCard,
          }"
          @click.stop="handleDropdownOpen()"
        >
          <svgicon name="ui-info"></svgicon>
        </button>
      </template>
      <template #dropdown>
        <Dropdown v-if="isTableOpen" :mobile-bottom-position="true">
          <div
            class="product-volume-discounts__table"
            :class="{ 'slow-mover-only': !hasVolumeDiscount && hasSlowMoverPromotionPrice }"
          >
            <ProductVolumeDiscountsTable
              @change="closeTable"
              :slow-mover-product="hasSlowMoverPromotionPrice"
              :id="id"
              :volume-prices="volumePrices"
              :current-discount-index="currentVolumeDiscountIndex"
              :list-id="listId"
              :owning-system="owningSystem"
            />
          </div>
        </Dropdown>
      </template>
    </ProductInfoBanner>

    <!-- POTENTIAL PROMOTION TOOLTIP -->
    <div v-else class="sale-price-wrapper">
      <div class="sale-price-banner">
        <Tooltip position="top">
          <span class="sale-price"
            ><span>{{ textK('UI_PRODUCT_SLOWMOVER_PROMOTION') }}</span>
            <span class="tooltip__icon"> <svgicon name="ui-info" /> </span
          ></span>
          <template #tooltip-content>{{
            textK('UI_PRODUCT_SLOWMOVER_PROMOTION_TOOLTIP')
          }}</template>
        </Tooltip>
      </div>
    </div>
  </div>
</template>
<style src="./product-volume-discounts.scss" lang="scss" scoped />

<script setup lang="ts">
import Dropdown from '@/src/core/components/ui/dropdown/dropdown.vue';
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import useText from '@/src/core/hooks/useText';
import { useUIStore } from '@/src/core/stores/ui';
import { CartEntry, PriceBreakInfo } from '@/src/core/types/api';
import ProductInfoBanner from '@/src/market/components/product/product-info-banner/product-info-banner.vue';
import ProductVolumeDiscountsTable from '@/src/market/components/product/product-volume-discounts/product-volume-discounts-table/product-volume-discounts-table.vue';
import { formatPriceBreakInfo } from '@/src/market/services/airbus-parts';
import { getCurrentPriceBreakIndex } from '@/src/market/services/price-service';
import { getProcurementByRouteParams } from '@/src/market/services/procurements';
import { isAirbus, isSatair, productAdditionalData } from '@/src/market/services/product-parts';
import { isPriceVisible } from '@/src/market/services/product-rules';
import { useCartStore } from '@/src/market/stores/cart';
import { useListsStore } from '@/src/market/stores/list';
import { useProductPriceInfoStore } from '@/src/market/stores/product-price-info';
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';

interface Props {
  id: string;
  entry?: CartEntry | null;
  owningSystem?: string;
  listId?: string;
  entryHasSlowMoverPrice?: boolean;
  entryHasPriceBreaks?: boolean;
  isProductCard?: boolean;
  isInCart?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  entry: null,
  listId: undefined,
  entryHasSlowMoverPrice: false,
  entryHasPriceBreaks: false,
  isProductCard: false,
  isInCart: false,
  owningSystem: '',
});

const textK = useText();
const listStore = useListsStore();
const uiStore = useUIStore();
const cartStore = useCartStore();
const productPriceInfoStore = useProductPriceInfoStore();
const route = useRoute();

const discountModalOpenId = computed(() => uiStore.openDiscountTableId);

const isTableOpen = computed(() => {
  const modalId = props.isProductCard || props.isInCart ? `${props.id}-card` : props.id;

  return discountModalOpenId.value === modalId;
});

const productAdditionalDataValue = computed(() =>
  productAdditionalData(props.id, props.owningSystem),
);

const procurement = computed(() => {
  const params = route.params;
  if (isAirbus(productAdditionalDataValue.value) && params) {
    return getProcurementByRouteParams(params, productAdditionalDataValue.value.procurements);
  }
  return undefined;
});

const ruleStatusOk = computed(() =>
  isPriceVisible(productAdditionalDataValue.value, procurement.value),
);

const productData = computed(() => {
  if (isSatair(productAdditionalDataValue.value)) {
    return productPriceInfoStore.priceDataByOfferId(props.id);
  }
  return null;
});

const hasVolumeDiscount = computed(() => {
  if (isSatair(productAdditionalDataValue.value)) {
    return (
      productData.value?.ProductPriceInfo?.PriceBreakInfos &&
      productData.value.ProductPriceInfo.PriceBreakInfos.length > 1
    );
  }

  if (isAirbus(productAdditionalDataValue.value)) {
    return Boolean(procurement.value?.priceBreakInfos?.length);
  }

  return false;
});

const hasSlowMoverPromotionPrice = computed(() => {
  if (isSatair(productAdditionalDataValue.value)) {
    return (
      productData.value?.ProductPriceInfo?.SlowMoverPriceAvailable || props.entryHasSlowMoverPrice
    );
  }
  return false;
});

const currentQuantity = computed(() => {
  if (props.listId) {
    return listStore.getListEntry(props.id)?.Quantity || 0;
  } else {
    return cartStore.getCartEntry(props.id)?.Quantity || 0;
  }
});

const volumePrices = computed(() => {
  if (isSatair(productAdditionalDataValue.value)) {
    return productData.value?.ProductPriceInfo?.PriceBreakInfos || [];
  }

  if (isAirbus(productAdditionalDataValue.value)) {
    return formatPriceBreakInfo(procurement.value);
  }

  return [];
});

const currentVolumeDiscountIndex = computed(() => {
  return getCurrentPriceBreakIndex(volumePrices.value, currentQuantity.value);
});

const currentVolumeDiscount = computed<PriceBreakInfo | undefined>(() => {
  return volumePrices.value[currentVolumeDiscountIndex.value] || undefined;
});

const nextPriceStep = computed<PriceBreakInfo | undefined>(() => {
  return volumePrices.value[currentVolumeDiscountIndex.value + 1] || undefined;
});

const stepsTillNextPrice = computed(() => {
  if (!nextPriceStep.value) {
    return undefined;
  }
  return nextPriceStep.value.BasePrice.MinQuantity - currentQuantity.value;
});

const lastVolumeDiscount = computed<PriceBreakInfo>(() => {
  return volumePrices.value[volumePrices.value.length - 1] || undefined;
});

const closeTable = () => {
  uiStore.setDiscountModaId(null);
};

const handleDropdownOpen = () => {
  if (isTableOpen.value) {
    closeTable();
  } else {
    const modalId = props.isProductCard || props.isInCart ? `${props.id}-card` : props.id;
    uiStore.setDiscountModaId(modalId);
  }
};

watch(
  () => route,
  () => {
    closeTable();
  },
  { immediate: true, deep: true },
);
</script>
