<template>
  <li class="product-details-list-item" v-if="serialNumber">
    <div class="product-details-list-item-content">
      <p class="product-details-list-item-title product-details-title-label">
        {{ textK('UI_PRODUCT_SERIAL_NUMBER') }}
      </p>
      <p class="product-details-list-item-value">
        {{ serialNumber }}
      </p>
    </div>
  </li>
</template>

<script lang="ts" setup>
import useText from '@/src/core/hooks/useText';
import { getProcurementByRouteParams } from '@/src/market/services/procurements';
import { isAirbus, productAdditionalData } from '@/src/market/services/product-parts';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

interface Props {
  id: string;
  owningSystem: string;
  offerSerialNumber?: string;
}

const props = defineProps<Props>();

const textK = useText();
const route = useRoute();

const productData = computed(() => productAdditionalData(props.id, props.owningSystem));

const serialNumber = computed(() => {
  if (isAirbus(productData.value)) {
    return procurementData.value?.serialNumber;
  }
  return props.offerSerialNumber;
});

const procurementData = computed(() => {
  if (isAirbus(productData.value)) {
    return getProcurementByRouteParams(route.params, productData.value.procurements);
  }
  return null;
});
</script>
