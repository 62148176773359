import { Facets } from '@/src/core/types/api';

const PAGE_SIZE = 20;
const RELEVANCE = ':relevance';

/**
 * Generates a URL query string for offer-search.
 *
 * @param {string} query - The search query.
 * @param {string} [facet=''] - The facet query.
 * @param {number} [page=0] - The current page number.
 * @returns {string} The final query string.
 */
export const offerSearchURLParams = (query: string, facet = '', page = 0) => {
  const encodedQuery = encodeURIComponent(query.replace(/:/g, '\\:'));
  let newFacet = facet;

  if (facet.length > 0) {
    newFacet = RELEVANCE + newFacet;
  }

  return `q=${encodedQuery}${newFacet}&currentPage=${page}&pageSize=${PAGE_SIZE}&fields=FULL`;
};

/**
 * Removes all occurrences of ":relevance" from the Value property
 * of the Query object in each item of the Values array of each facet.
 *
 * @param {Array} facets - The array of facets.
 * @returns {Array} - The updated array of facets.
 */
export const cleanFacets = (facets: Facets[]) => {
  return facets.map((facet) => {
    if (facet.Values && Array.isArray(facet.Values)) {
      facet.Values = facet.Values.map((valueItem) => {
        // keep only what comes after :relevance
        if (valueItem.Query?.Query?.Value.includes(RELEVANCE)) {
          const parts = valueItem.Query.Query.Value.split(RELEVANCE);
          valueItem.Query.Query.Value = parts.length > 1 ? parts[1] : '';
        }
        return valueItem;
      });
    }
    return facet;
  });
};
