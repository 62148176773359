import { getEnv } from '@/src/core/services/environment';

/**
 * Adds the Commerce CDN URL as a prefix to the provided URL.
 *
 * @param {string} [url] - The URL to which the Commerce CDN should be added.
 * @returns {string | undefined} - The full URL with the Commerce CDN prefix, or the original URL if the CDN is not set.
 *
 * @example
 * const originalUrl = 'https://example.com/image.png';
 * const fullUrl = addCommerceCDNtoUrl(originalUrl);
 * // If VUE_APP_COMMERCE_CDN is 'https://cdn.example.com',
 * // fullUrl will be 'https://cdn.example.com?source=https://example.com/image.png'
 */
export const addCommerceCDNtoUrl = (url?: string) => {
  const cdn = getEnv('VUE_APP_COMMERCE_CDN');

  if (!cdn) {
    return url;
  }

  return `${cdn}?source=${url}`;
};

/**
 * Constructs a type by making specific keys in a type required.
 *
 * @template T - The original type.
 * @template K - The keys from the original type that should be required.
 *
 * @typedef {T & { [P in K]-?: T[P] }} WithRequired
 *
 * @example
 * interface Person {
 *   name?: string;
 *   age?: number;
 *   address?: string;
 * }
 *
 * // `name` and `age` are now required in `PersonWithRequired`
 * type PersonWithRequired = WithRequired<Person, 'name' | 'age'>;
 *
 * const person: PersonWithRequired = {
 *   name: 'John',
 *   age: 30,
 *   // address is still optional
 * };
 */
export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

/**
 * Calculates the maximum integer multiple of a given factor.
 * Ensures that the result is the smallest integer greater than or equal to `value`
 * that is a multiple of `factor`.
 *
 * @param {number} value - The value to be adjusted.
 * @param {number} factor - The factor by which the value is to be rounded up.
 * @returns {number} The smallest integer greater than or equal to `value` that is a multiple of `factor`.
 * @example `
 * // Returns 12. 4*3 = 12 -> closest multiple of 4 that is above 10
 * const maxInt = calculateMaxInt(10, 4);
 *
 * // Returns 20. 5*4 = 20 -> closest multiple of 5 that is above 17
 * const maxInt = calculateMaxInt(17, 5);
 * `
 */
export const calculateMaxInt = (value: number, factor: number) =>
  Math.ceil(value / factor) * factor;

/**
 * When umbraco maps dates, it uses the following string to represent a missing date.
 */
export const LEGACY_NO_DATE = '0001-01-01T00:00:00';

/**
 * Maps a date string to a legacy date format with a time component.
 * If the date string is not provided, it returns a fallback value.
 *
 * @param {string} [dateStr] - The date string to map.
 * @param {string} [fallback=LEGACY_NO_DATE] - The fallback value to return if the date string is not provided.
 * @returns {string} - The formatted legacy date string with a time component or the fallback value.
 *
 * @example
 * // Returns '2023-07-26T00:00:00'
 * getLegacyDateMapping('2023-07-26');
 *
 * @example
 * // Returns '0001-01-01T00:00:00'
 * getLegacyDateMapping(undefined);
 *
 * @example
 * // Returns 'NO_DATE_PROVIDED'
 * getLegacyDateMapping(undefined, 'NO_DATE_PROVIDED');
 */
export const getLegacyDateMapping = (dateStr?: string, fallback = LEGACY_NO_DATE) => {
  if (!dateStr) {
    return fallback;
  }

  return dateStr + 'T00:00:00';
};

/**
 * Determines the type of a product ID.
 *
 * @param {string} [id] - The product ID to check.
 * @returns {string} - Returns 'offer' if the ID starts with 'offer_' or 'mp-', 'product' if it starts with 'product_', or 'unknown' if it does not match any known pattern or if the ID is not provided.
 *
 * @example
 * // Returns 'offer'
 * getProductIdType('offer_12345');
 *
 * @example
 * // Returns 'product'
 * getProductIdType('product_12345');
 *
 * @example
 * // Returns 'unknown'
 * getProductIdType('unknown_12345');
 *
 * @example
 * // Returns 'unknown'
 * getProductIdType();
 */
export const getProductIdType = (id?: string): string => {
  if (!id) {
    return 'unknown';
  }

  if (id.startsWith('offer_') || id.startsWith('mp-')) {
    return 'offer';
  }

  if (id.startsWith('product_')) {
    return 'product';
  }

  return 'unknown';
};

/**
 * Checks if a product ID is an offer.
 *
 * @param {string} [id] - The product ID to check.
 * @returns {boolean} - Returns true if the ID is of type 'offer', false otherwise.
 *
 * @example
 * // Returns true
 * productIdIsOffer('offer_12345');
 *
 * @example
 * // Returns false
 * productIdIsOffer('product_12345');
 */
export const productIdIsOffer = (id?: string): boolean => getProductIdType(id) === 'offer';

/**
 * Checks if a product ID is a product.
 *
 * @param {string} [id] - The product ID to check.
 * @returns {boolean} - Returns true if the ID is of type 'product', false otherwise.
 *
 * @example
 * // Returns true
 * productIdIsProduct('product_12345');
 *
 * @example
 * // Returns false
 * productIdIsProduct('offer_12345');
 */
export const productIdIsProduct = (id?: string): boolean => getProductIdType(id) === 'product';
