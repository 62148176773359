type Listener<T extends Array<any>> = (...args: T) => void;

export class EventBus<EventMap extends Record<string, Array<any>>> {
  private eventListeners: {
    [K in keyof EventMap]?: Set<Listener<EventMap[K]>>;
  } = {};

  $on<K extends keyof EventMap>(eventName: K, listener: Listener<EventMap[K]>) {
    const listeners = this.eventListeners[eventName] ?? new Set();
    listeners.add(listener);
    this.eventListeners[eventName] = listeners;
  }

  $onMany<K extends keyof EventMap>(eventNames: K[], listener: Listener<EventMap[K]>) {
    for (const eventName of eventNames) {
      const listeners = this.eventListeners[eventName] ?? new Set();
      listeners.add(listener);
      this.eventListeners[eventName] = listeners;
    }
  }

  $off<K extends keyof EventMap>(eventName: K, listener?: Listener<EventMap[K]>) {
    const listeners = this.eventListeners[eventName];
    if (listeners) {
      if (listener) {
        listeners.delete(listener);
      }
      if (listeners.size === 0) {
        delete this.eventListeners[eventName];
      }
    }
  }

  $emit<K extends keyof EventMap>(eventName: K, ...args: EventMap[K]) {
    const listeners = Array.from(this.eventListeners[eventName] ?? new Set()) as Listener<
      EventMap[K]
    >[];
    for (const listener of listeners) {
      listener(...args);
    }
  }
}
