<template>
  <div class="target-product-list" v-if="products && products.length > 0">
    <div class="target-product-list__wrapper">
      <div class="target-product-list__content typeset">
        <h3 class="target-product-list__title">{{ title }}</h3>
        <p class="target-product-list__description">{{ description }}</p>
      </div>
      <div class="target-product-list__items">
        <TargetSearchItem v-for="id in products" :id="id" :key="id"></TargetSearchItem>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./target-product-lists.scss" scoped />

<script lang="ts">
import { Vue, Component, Prop, toNative } from 'vue-facing-decorator';
import TargetSearchItem from '@/src/core/components/adobe-target-wrapper/adobe-target-components/target-product-lists/target-product-items/target-product-item.vue';
import ContentBlock from '@/src/core/components/ui/content-block/content-block.vue';
import { IAdobeTargetOffer } from '@/src/core/types/adobe';

@Component({
  components: {
    ContentBlock,
    TargetSearchItem,
  },
})
export class TargetProductLists extends Vue {
  @Prop() private offer: IAdobeTargetOffer<ITargetProductlist>;

  private get offerResponse() {
    const content = this.offer.content && this.offer.content[0];
    return content?.content;
  }

  public get products(): string[] {
    return this.offerResponse?.productIds || [];
  }

  public get title() {
    return this.offerResponse?.title;
  }

  public get description() {
    return this.offerResponse?.description;
  }
}

export interface ITargetProductlist {
  title: string;
  description: string;
  productIds: string[];
}

export default toNative(TargetProductLists);
</script>
