<template>
  <transition-group
    :tag="tag"
    class="list-animation-wrapper"
    name="list-animation"
    @before-enter="emit('before-enter')"
    @enter="emit('enter')"
    @leave="emit('leave')"
  >
    <slot></slot>
  </transition-group>
</template>

<style lang="scss" src="./list-animation.scss"></style>

<script lang="ts" setup>
interface Props {
  tag?: string;
}

withDefaults(defineProps<Props>(), {
  tag: 'div',
});

const emit = defineEmits(['before-enter', 'enter', 'leave']);
</script>
