<template>
  <div class="link-list-module">
    <h2 class="link-list-module__header">{{ title }}</h2>
    <div class="link-list-module__list-wrapper">
      <template v-if="links.length">
        <div v-for="[key, links] in mappedLinks" class="link-list-module__container" :key="key">
          <h3 class="link-list-module__list-header">{{ key }}</h3>
          <ul class="link-list-module__list" :style="links.length <= 8 ? { columnCount: 1 } : {}">
            <li v-for="link in links" :key="link.title" class="link-list-module__list-item">
              <a
                class="link-list-module__link"
                :href="link.url"
                :target="getAnchorTarget(link.newtab)"
                >{{ link.title }}</a
              >
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" src="./alphabetical-link-list.scss" scoped></style>

<script lang="ts" setup>
import { groupByAlphabeticalOrder } from '@/src/core/services/sellers';
import { SellerLink } from '@/src/core/types/interfaces';
import { computed } from 'vue';

export interface Props {
  title: string;
  links?: SellerLink[];
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  links: () => [] as SellerLink[],
});

const mappedLinks = computed(() => groupByAlphabeticalOrder(props.links));

const getAnchorTarget = (newTab: boolean) => {
  if (newTab) {
    return '_blank';
  }
  return '_self';
};
</script>
