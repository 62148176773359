import { WhatInputBus } from '@/src/core/utils/what-input-event-bus';
import Cookies from 'js-cookie';
import whatInput from 'what-input';

export const WhatInputHandler = {
  inputTypeChanged: (type: string) => {
    if (
      type === 'mouse' &&
      (Cookies.get('hasMouse') === 'false' || Cookies.get('hasMouse') === undefined)
    ) {
      Cookies.set('hasMouse', 'true');
      Cookies.set('hasTouch', 'false');
      WhatInputBus.$emit('inputTypeChanged', type);
    } else if (
      type === 'touch' &&
      (Cookies.get('hasTouch') === 'false' || Cookies.get('hasTouch') === undefined)
    ) {
      Cookies.set('hasMouse', 'false');
      Cookies.set('hasTouch', 'true');
      WhatInputBus.$emit('inputTypeChanged', type);
    }
  },
  hasMouse: () => Cookies.get('hasMouse') === 'true',
  hasTouch: () => Cookies.get('hasTouch') === 'true',
  init: () => {
    WhatInputHandler.inputTypeChanged(whatInput.ask('intent'));
    whatInput.registerOnChange(WhatInputHandler.inputTypeChanged, 'intent');
  },
};
