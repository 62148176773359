<template>
  <button
    class="tab-button"
    :class="{
      'tab-button--active': active,
      'tab-button--secondary': type === uiVariant.Secondary,
    }"
  >
    <slot></slot>
  </button>
</template>

<style lang="scss" src="./tab-button.scss" scoped></style>

<script lang="ts" setup>
import { UiVariant } from '@/src/core/components/ui/ui.types';

interface Props {
  active?: boolean;
  type?: UiVariant;
}

withDefaults(defineProps<Props>(), {
  active: false,
  type: UiVariant.Primary,
});

const uiVariant = UiVariant;
</script>
