export default function usePrice(value: string, scale: boolean = true) {
  if (!value) {
    return '';
  }

  const parts: string[] = value.split('.');

  let priceClass = '';
  if (value.length > 11 && scale) {
    priceClass = ' price--large';
  } else if (value.length > 8 && scale) {
    priceClass = ' price--medium';
  }

  const priceHtml = `<span class="price${priceClass}">
    ${parts[0] || '0'}<span class="price-decimal">${parts[1] || '00'}</span>
  </span>`;

  //Remove Line Breaks
  return priceHtml.replace(/[\r\n]+/gm, '');
}
