<template>
  <form @submit.prevent="clearCart" class="clear-cart-modal">
    <StandardDialogModal :has-cancel-button="true" :ui-test-name-header="'modal-clear-cart-header'">
      <template #header>
        <div class="typeset">
          <h3>Clear cart?</h3>
          <p>Are you sure you want to clear the cart?</p>
        </div>
      </template>
      <template #actions>
        <VButton
          :type="uiVariant.Default"
          v-ui-test="'clear-cart-button'"
          input-type="submit"
          :loading="isBusy"
          >Clear</VButton
        >
      </template>
    </StandardDialogModal>
  </form>
</template>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import { useAdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { useModalStore } from '@/src/core/stores/modal';
import { useCartStore } from '@/src/market/stores/cart';

@Component({
  components: {
    VTextField,
    VButton,
    StandardDialogModal,
  },
})
export class ModalClearCart extends Vue {
  public modalStore = useModalStore();
  public cartStore = useCartStore();
  public uiVariant = UiVariant;

  private get name(): string {
    return this.modalParams.Name;
  }

  public get isBusy(): boolean {
    return this.cartStore.isBusy;
  }

  private get modalParams(): IClearCartModalParams {
    return this.modalStore.params as IClearCartModalParams;
  }

  public clearCart() {
    const data = this.cartStore.currentCart.Entries;
    this.cartStore
      .clearCart()
      .then(() => this.modalStore.closeModal())
      .finally(() => useAdobeLaunchTracking().clearCart(data));
  }
}

export interface IClearCartModalParams {
  Name: string;
  Code: string;
}

export default toNative(ModalClearCart);
</script>
