<template>
  <div
    class="product-details-seller__logo-wrapper"
    v-ui-test="'product-details-supplier-logo'"
    :title="titleTag(logoAlt)"
  >
    <span class="product-details-seller__logo-title">{{
      textK('UI_PRODUCT_SOLD_HANDLED_BY')
    }}</span>
    <img v-if="logo.url" class="product-details-seller__logo" :src="logo.url" :alt="logoAlt" />
    <h3 v-else class="product-details-seller__title">{{ logoAlt || 'Supplier logo' }}</h3>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import useText from '@/src/core/hooks/useText';
import useTitleTag from '@/src/core/hooks/useTitleTag';
import { useUserStore } from '@/src/profile/stores/user';

interface Props {
  logo: { url: string; alt: string };
}

const props = defineProps<Props>();

const textK = useText();
const titleTag = useTitleTag;
const userStore = useUserStore();

const logoAlt = computed(() => {
  if (props.logo.alt === 'Airbus') {
    return userStore.airbusLegalName;
  }
  return props.logo.alt;
});
</script>
