import { Req } from '@/src/core/services/requester';
import { IProductPlantAvailabilities } from '@/src/core/types/interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ProductApi } from '../api/productApi';

const expireTime: number = 60000;

export const useProductPlantAvailabilityStore = defineStore('product-plant-availability', () => {
  const productPlantAvailabilities = ref<IProductPlantAvailabilities[]>([]);

  const productPlantAvailabilityUpdate = (payload: {
    ProductPlantAvailabilities: IProductPlantAvailabilities;
  }) => {
    const foundIndex = productPlantAvailabilities.value.findIndex(
      (x) => x.Code === payload.ProductPlantAvailabilities.Code,
    );
    if (foundIndex >= 0) {
      productPlantAvailabilities.value.splice(
        foundIndex,
        1,
        Object.assign(
          {},
          productPlantAvailabilities.value[foundIndex],
          payload.ProductPlantAvailabilities,
          { IsBusy: false },
        ),
      );
    } else {
      productPlantAvailabilities.value.push(payload.ProductPlantAvailabilities);
    }
  };

  const isBusyUpdated = (payload: { Index: number; IsBusy: boolean }) => {
    productPlantAvailabilities.value[payload.Index].IsBusy = payload.IsBusy;
  };

  const reset = () => {
    productPlantAvailabilities.value = [];
  };

  const fetchPlantAvailability = async (payload: { OfferId: string }) => {
    const foundIndex = productPlantAvailabilities.value.findIndex(
      (x) => x.Code === payload.OfferId,
    ); /* TODO is Code = OfferId in ProductPlantsList */

    if (foundIndex >= 0) {
      isBusyUpdated({ Index: foundIndex, IsBusy: true });
    }

    const { Data, IsSuccess } = await Req({
      url: ProductApi.ProductPlantAvailability(payload.OfferId),
    });

    if (IsSuccess && Data) {
      productPlantAvailabilityUpdate({
        ProductPlantAvailabilities: Object.assign({}, Data, { Timestamp: Date.now() }),
      });
    }
    return { IsSuccess };
  };

  const plantAvailabilityByProductOfferId = (id: string) => {
    return productPlantAvailabilities.value.find((x) => x.Code === id);
  };

  const isPlantAvailabilityValid = (id: string) => {
    const item = plantAvailabilityByProductOfferId(id);
    return (item && Date.now() - item.Timestamp < expireTime) || false;
  };

  return {
    isPlantAvailabilityValid,
    plantAvailabilityByProductOfferId,
    productPlantAvailabilities,
    reset,
    fetchPlantAvailability,
    isBusyUpdated,
  };
});
