export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const safeSetTimeout = (handler: TimerHandler, timeout = 0, ...args: any[]) => {
  // Maximum delay setTimeout allows (about 24.8 days)
  const MAX_TIMEOUT = 2147483647;

  if (timeout > MAX_TIMEOUT) {
    console.warn('setTimeout delay exceeds maximum. Capping to', MAX_TIMEOUT);

    // Schedule the next iteration after the maximum delay
    return window.setTimeout(() => {
      safeSetTimeout(handler, timeout - MAX_TIMEOUT, ...args);
    }, MAX_TIMEOUT);
  } else {
    // Within safe timeout limit, call setTimeout directly
    return window.setTimeout(handler, timeout, ...args);
  }
};
