<template>
  <StandardDialogModal :size="uiSize.Large" :has-cancel-button="false">
    <template #header>
      <h3>
        {{ textK('UI_ORDERS_FILTERMODAL_HEADER') }}
      </h3>
    </template>
    <template #body>
      <RepairExchangeOrdersFilters v-if="params.isRepairExchangeShown" modal />
      <LeaseOrdersFilters v-else-if="params.isLeaseShown" modal />
      <OrdersFilters v-else modal />
    </template>
    <template #actions>
      <VButton @click="close">
        {{ textK('UI_SEARCHRESULT_FILTERMODALRESULT') }}
      </VButton>
    </template>
  </StandardDialogModal>
</template>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import LeaseOrdersFilters from '@/src/market/components/orders/lease-orders-filters/lease-orders-filters.vue';
import OrdersFilters from '@/src/market/components/orders/orders-filters/orders-filters.vue';
import RepairExchangeOrdersFilters from '@/src/market/components/orders/repair-exchange-orders-filters/repair-exchange-orders-filters.vue';
import { computed } from 'vue';

export interface Params {
  isRepairExchangeShown: boolean;
  isLeaseShown: boolean;
}

const textK = useText();
const uiSize = UiSize;
const modalStore = useModalStore();

const close = () => {
  modalStore.closeModal();
};

const params = computed(() => {
  return modalStore.params as Params;
});
</script>
