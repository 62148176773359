<template>
  <div
    class="modal-create-single-line-quote typeset"
    v-ui-test="'modal-create-single-line-quote-modal'"
  >
    <StandardDialogModal :size="uiSize.Large">
      <template #header>
        <ModalRequestQuoteHeader :offer-id="itemId" :header-title="headerTitle" />
      </template>

      <template #body>
        <div class="modal-create-single-line-quote__inner" ref="scrollcontainer">
          <div class="advanced-modal__body">
            <div class="modal-create-single-line-quote__body-wrapper">
              <div class="modal-create-single-line-quote__input-wrapper">
                <div class="input-container modal-create-single-line-quote__input">
                  <QuantitySelector
                    :avoid-zero-value="true"
                    :min-value="minQty"
                    :step="stepQty"
                    :as-input="true"
                    :is-request-quote="true"
                    v-model="quoteSingle.ItemRequestedQuantity"
                    :unit="textK('UI_QUOTES_REQUEST_QTY_LABEL')"
                  >
                  </QuantitySelector>
                </div>
              </div>
              <div class="modal-create-single-line-quote__input-wrapper">
                <div class="input-container modal-create-single-line-quote__textarea">
                  <VTextField
                    input-type="text"
                    :type="uiVariant.Secondary"
                    :label="textK('UI_QUOTES_REQUEST_REFERENCE')"
                    name="referenceNumber"
                    :required="true"
                    v-model="quoteRef"
                  />
                  <span class="modal-create-single-line-quote__info-text">{{
                    textK('UI_QUOTES_REFERENCE_INFO_TEXT')
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="modal-create-single-line-quote__actions-wrapper"
              v-ui-test="'modal-create-single-line-quote-actions-wrapper'"
              :class="{ single: isMultilineRoute }"
            >
              <VButton
                v-if="!isMultilineRoute"
                v-ui-test="'modal-create-single-line-quote-add-to-quote'"
                :type="uiVariant.Secondary"
                @click="addProductToQuoteList"
                >{{ textK('UI_QUOTES_ADD_TO_QUOTE') }}</VButton
              >
              <VButton
                class="modal-create-single-line-quote__submit"
                v-ui-test="'modal-create-single-line-quote-actions-continue'"
                :type="uiVariant.Default"
                :disabled="quoteRef.length <= 0"
                @click="next"
                >{{ textK('UI_COMMON_CONTINUE') }}</VButton
              >
            </div>
          </div>
        </div>
      </template>
    </StandardDialogModal>
  </div>
</template>
<style lang="scss" src="./modal-create-single-line-quote.scss" scoped></style>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { TextKey } from '@/src/core/services/text-key';
import { useModalStore } from '@/src/core/stores/modal';
import { IModalCreateQuoteParams, IQuoteDrafts } from '@/src/core/types/interfaces';
import ModalRequestQuoteHeader from '@/src/market/components/modals/modal-request-quote/modal-request-quote-header/modal-request-quote-header.vue';
import QuantitySelector from '@/src/market/components/ui/quantity-selector/quantity-selector.vue';
import { useListsStore } from '@/src/market/stores/list';
import { useProductStore } from '@/src/market/stores/product';
import { useQuoteDraftsStore } from '@/src/market/stores/quote-drafts';
import { useQuoteSingleStore } from '@/src/market/stores/quote-single';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const textK = useText();
const productStore = useProductStore();
const listsStore = useListsStore();
const quoteDraftsStore = useQuoteDraftsStore();
const modalStore = useModalStore();
const quoteSingleStore = useQuoteSingleStore();
const uiSize = UiSize;
const uiVariant = UiVariant;
const lastViewedId = ref('');
const quoteRef = ref(quoteSingleStore.quoteSingle.Reference || '');
const route = useRoute();

const params = computed<IModalCreateQuoteParams>(
  () => modalStore.params as IModalCreateQuoteParams,
);

const id = computed(() => (params.value && params.value.id) || '');

const itemId = computed(() => {
  lastViewedId.value = quoteSingleStore.quoteSingle.ItemId;
  quoteSingleStore.quoteSingle.ItemId = id.value;
  return id.value;
});

const product = computed(() => productStore.productById(itemId.value));

const headerTitle = computed(() => TextKey('UI_QUOTES_QUOTE_SINGLE_TITLE'));

const minQty = computed(() => (product.value?.AddToBasketQty ? product.value.AddToBasketQty : 1));

const stepQty = computed(() => (product.value?.CartStep ? product.value.CartStep : 1));

const quoteSingle = computed({
  get: () => quoteSingleStore.quoteSingle,
  set: (value) => quoteSingleStore.quoteSingleUpdated(value),
});

const isMultilineRoute = computed(() => route.name === 'multilinequote');

const listCode = computed(() => params.value.listCode || quoteSingleStore.quoteSingle.ListCode);

const next = async () => {
  if (!listCode.value) {
    const quote = (await (
      await quoteDraftsStore.createList({ listName: quoteRef.value })
    ).Data) as IQuoteDrafts;
    await listsStore.addProductToList({
      listCode: quote.Code,
      productId: params.value.id,
      quantity: parseInt(quoteSingleStore.quoteSingle.ItemRequestedQuantity, 10),
      isQuoteList: true,
    });

    modalStore.showModal({
      modalComponent: 'ModalCreateQuoteSelectAddress',
      params: {
        id: params.value.id,
        quantity: quoteSingleStore.quoteSingle.ItemRequestedQuantity,
        single: true,
        listCode: quote.Code,
        referenceNumber: quoteRef.value,
      },
    });
    quoteSingleStore.quoteSingle.Reference = quoteRef.value;
    quoteSingleStore.quoteSingle.ListCode = quote.Code;
  } else {
    await quoteDraftsStore.renameList({
      listCode: listCode.value,
      listName: quoteRef.value,
    });
    await quoteDraftsStore.updateProductQuantity({
      listCode: listCode.value,
      productId: params.value.id,
      quantity: parseInt(quoteSingleStore.quoteSingle.ItemRequestedQuantity, 10),
    });
    modalStore.showModal({
      modalComponent: 'ModalCreateQuoteSelectAddress',
      params: {
        id: params.value.id,
        quantity: quoteSingleStore.quoteSingle.ItemRequestedQuantity,
        single: true,
        listCode: listCode.value,
        referenceNumber: quoteRef.value,
      },
    });
    quoteSingleStore.quoteSingle.Reference = quoteRef.value;
    quoteSingleStore.quoteSingle.ListCode = listCode.value;
    quoteSingleStore.quoteSingle.ItemId = itemId.value;
  }
  modalStore.closeModal({ modalComponent: 'ModalCreateSingleLineQuote' });
};

const addProductToQuoteList = async () => {
  modalStore.showModal({
    modalComponent: 'ModalAddToList',
    params: {
      Code: id.value,
      Quote: true,
      Quantity: parseInt(quoteSingleStore.quoteSingle.ItemRequestedQuantity, 10),
    },
  });
  modalStore.closeModal({ modalComponent: 'ModalCreateSingleLineQuote' });
};

onMounted(async () => {
  if (lastViewedId.value !== itemId.value) {
    quoteSingleStore.reset();
    quoteSingleStore.quoteSingle.ItemRequestedQuantity = minQty.value.toString();
  }
});
</script>
