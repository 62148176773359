<template>
  <div
    class="simple-table-item-cell"
    ref="simpleTableCellItemRef"
    v-ui-test="label"
    :title="titleTag"
  >
    <slot />
  </div>
</template>

<style lang="scss" src="./table-cell.scss"></style>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';

interface Props {
  label?: string;
  titleTag?: string;
  setWidth?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  titleTag: '',
  setWidth: false,
});

const isMounted = ref(false);
const simpleTableCellItemRef = ref<HTMLDivElement | null>();

const setCellWidth = computed(() => {
  if (props.setWidth && isMounted.value) {
    return simpleTableCellItemRef.value?.clientWidth;
  }

  return null;
});

onMounted(() => {
  isMounted.value = true;
});

defineExpose({
  setCellWidth,
});
</script>
