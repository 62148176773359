import { EventBus } from '@/src/market/services/event-bus';

type EventMap = {
  resize: [payload?: boolean | undefined | ResizeEvent];
};

export const ResizeEventBus = new EventBus<EventMap>();

export interface ResizeEvent {
  scrollPosition: number;
  windowSize: ResizeEventSizes;
}

export interface ResizeEventSizes {
  windowHeight: number;
  windowWidth: number;
}
