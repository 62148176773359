<template>
  <StandardDialogModal class="list-modal" :size="uiSize.Medium">
    <template #header>
      <h3>{{ textK('UI_MODAL_FORWARDER_CUSTOM_FORWARDER_DETAILS') }}</h3>
    </template>
    <template #body>
      <ModalColumnContainer>
        <ModalColumn>
          <div class="forwarder-information">
            <div class="forwarder-information__row" v-if="forwarderName">
              <span class="forwarder-information__row__label">{{
                textK('UI_MODAL_FORWARDER_NAME')
              }}</span>
              <span class="forwarder-information__row__value">{{ forwarderName }}</span>
            </div>
            <div class="forwarder-information__row" v-if="forwarderAccountNumber">
              <span class="forwarder-information__row__label">{{
                textK('UI_MODAL_FORWARDER_ACCOUNT_NO')
              }}</span>
              <span class="forwarder-information__row__value">{{ forwarderAccountNumber }}</span>
            </div>
            <div class="forwarder-information__row" v-if="forwarderServiceLevel">
              <span class="forwarder-information__row__label">{{
                textK('UI_MODAL_FORWARDER_SERVICE_LEVEL_LABEL')
              }}</span>
              <span class="forwarder-information__row__value">{{ forwarderServiceLevel }}</span>
            </div>
            <div class="forwarder-information__row" v-if="forwarderInstuctions">
              <span class="forwarder-information__row__label">{{
                textK('UI_MODAL_FORWARDER_ADDITIONAL_INSTRUCTION')
              }}</span>
              <span class="forwarder-information__row__value"> {{ forwarderInstuctions }}</span>
            </div>
          </div>
        </ModalColumn>
      </ModalColumnContainer>
    </template>
  </StandardDialogModal>
</template>

<style lang="scss" src="./modal-forwarder-information.scss" scoped></style>

<script lang="ts" setup>
import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useOrderDetailsStore } from '@/src/market/stores/order-details';
import { computed } from 'vue';

const textK = useText();
const orderDetailsStore = useOrderDetailsStore();
const uiSize = UiSize;

const forwarderName = computed(() => {
  return (
    orderDetailsStore.order.ForwarderInfo.Carrier?.Name ||
    orderDetailsStore.order.ForwarderInfo.ForwarderName
  );
});

const forwarderAccountNumber = computed(() => {
  return orderDetailsStore.order.ForwarderInfo.ForwarderAccountNumber;
});

const forwarderServiceLevel = computed(() => {
  return orderDetailsStore.order.ForwarderInfo.ServiceLevelAgreements;
});

const forwarderInstuctions = computed(() => {
  return orderDetailsStore.order.ForwarderInfo.AdditionalInfo;
});
</script>
