<template>
  <div
    class="table-row"
    :class="{ active: hovered, selected: selected, clickable: onClick }"
    @mouseover="mouseOver"
    @mouseout="mouseOut"
    @click="click"
  >
    <slot />
  </div>
</template>

<style lang="scss" src="./table-row.scss"></style>

<script lang="ts" setup>
import { ref } from 'vue';

interface Props {
  onClick?: () => void;
  selected?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  onClick: undefined,
  selected: false,
});

const hovered = ref(false);

const click = () => {
  if (props.onClick) {
    props.onClick();
  }
};

const mouseOver = () => {
  if (props.onClick !== undefined) {
    hovered.value = true;
  }
};

const mouseOut = () => {
  if (props.onClick !== undefined) {
    hovered.value = false;
  }
};
</script>
