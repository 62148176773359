import { UmbracoApi } from '@/src/core/api/umbracoApi';
import { Req } from '@/src/core/services/requester';
import { useTextsStore } from '../stores/texts';

export const TextKey = (idKey: string) => {
  const textsStore = useTextsStore();
  const status = textsStore.status;
  const isBusy = textsStore.isBusy;

  if (status === '' && !isBusy) {
    textsStore.fetchTexts();
  }
  const text = textsStore.texts[idKey];
  return text || '';
};

export const microcopiesFromUmraco = () =>
  Req({
    url: UmbracoApi.getMicrocopies,
  });

export const microcopiesFromDisk = () =>
  import('@/microcopies.json').then((json) => ({
    IsSuccess: true,
    Data: jsonToUmbracoReponse(json.default),
  }));

// this function is 🗑️ don't judge me
const jsonToUmbracoReponse = (obj: any, prefix = '') => {
  //store the result
  let output = {};

  //iterate the object
  for (const k in obj) {
    const val = obj[k];

    //new key
    const newKey = prefix ? prefix + '_' + k : k;

    //array and object both are object in js
    if (typeof val === 'object') {
      // if it is array
      if (Array.isArray(val)) {
        //use rest & spread together to convert
        //array to object
        const { ...arrToObj } = val;
        const newObj = jsonToUmbracoReponse(arrToObj, newKey);
        output = { ...output, ...newObj };
      }
      //if it is object
      else {
        const newObj = jsonToUmbracoReponse(val, newKey);
        output = { ...output, ...newObj };
      }
    }
    // normal value
    else {
      if (!newKey.endsWith('DefaultValue')) {
        continue;
      }

      const keyWithoutDefaultValue = newKey.substring(0, newKey.length - 13);

      output = { ...output, [keyWithoutDefaultValue]: val };
    }
  }

  return output;
};
