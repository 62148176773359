import { User, UserSearchPage } from '@/src/core/apim';
import { ConvertToPascalCase } from '@/src/core/utils/object';

export const getUserWithRolesMapping = (
  user: User,
  enableFinanceSection?: boolean,
  isFinanceBetaPage?: boolean,
  betaCustomer?: boolean,
) => {
  const userRoles = ['Buyer'];
  const {
    admin,
    readOnly,
    financeCustomer,
    blueCustomer,
    active,
    contactNumber,
    globalId,
    name,
    uid,
  } = user;

  if (admin) {
    userRoles.push('Admin');
  }

  if (readOnly) {
    userRoles.push('Read only');
  }

  if (enableFinanceSection && financeCustomer) {
    if ((isFinanceBetaPage && betaCustomer) || !isFinanceBetaPage) {
      userRoles.push('Finance');
    }
  }

  return {
    active,
    admin,
    contactNumber,
    financeCustomer,
    globalId,
    name,
    provisionedByAirbusWorld: blueCustomer,
    readOnly,
    uid,
    userRoles,
  };
};

export type UserWithRolesMapping = ReturnType<typeof getUserWithRolesMapping>;
export type LegacyUserWithRolesMapping = ConvertToPascalCase<UserWithRolesMapping>;

export const getUserSearchPageWithRolesMapping = (
  response: UserSearchPage,
  enableFinanceSection: boolean,
  isFinanceBetaPage: boolean,
  betaCustomer: boolean,
) => {
  const users = response.results?.map((user) => {
    return getUserWithRolesMapping(user, enableFinanceSection, isFinanceBetaPage, betaCustomer);
  });
  const pagination = response.pagination;

  return {
    users: users ?? [],
    page: pagination?.currentPage ?? 0,
    totalPages: pagination?.totalPages ?? 0,
    count: pagination?.totalResults ?? 0,
    pagination: {
      currentPage: pagination?.currentPage ?? 0,
      totalPages: pagination?.totalPages ?? 0,
      totalResults: pagination?.totalResults ?? 0,
    },
  };
};

export type UserSearchPageWithRolesMapping = ReturnType<typeof getUserSearchPageWithRolesMapping>;
export type LegacyUserSearchPageWithRolesMapping =
  ConvertToPascalCase<UserSearchPageWithRolesMapping>;
