<template>
  <StandardDialogModal :size="uiSize.Small" :ui-test-name-header="'modal-remark-header'">
    <template #header>
      <h3>{{ heading }}</h3>
    </template>
    <template #body>
      {{ airbusRemark }}
    </template>
  </StandardDialogModal>
</template>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import { useModalStore } from '@/src/core/stores/modal';
import { computed } from 'vue';

interface Params {
  heading: string;
  remark?: string;
}
const uiSize = UiSize;
const modalStore = useModalStore();

const airbusRemark = computed(() => {
  return (modalStore.params as Params)?.remark ?? '';
});

const heading = computed(() => {
  return (modalStore.params as Params)?.heading;
});
</script>
