import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { Req } from '../services/requester';
import { IUmbracoContent } from '../types/interfaces';

export const useContentBlocksStore = defineStore('content-blocks', () => {
  const blocks = ref<IUmbracoContent[]>([]);

  const umbracoContentBlocks = computed(() => {
    return blocks.value;
  });

  const contentUpdated = (payload: { url: string; content: string }) => {
    const blockIndex: number = blocks.value.findIndex((x) => x.Url === payload.url);

    if (blockIndex >= 0) {
      blocks.value.splice(
        blockIndex,
        1,
        Object.assign({}, blocks.value[blockIndex], { Content: payload.content }),
      );
    } else {
      blocks.value.push({ Url: payload.url, Content: payload.content, IsBusy: false });
    }
  };

  const isBusyUpdated = (payload: { url: string; isBusy: boolean }) => {
    const blockIndex: number = blocks.value.findIndex((x) => x.Url === payload.url);

    if (blockIndex > -1) {
      blocks.value[blockIndex].IsBusy = payload.isBusy;
    } else {
      blocks.value.push({ Url: payload.url, IsBusy: payload.isBusy });
    }
  };

  const reset = () => {
    blocks.value = [];
  };

  const fetchUmbracoContentBlock = async (payload: { url: string; force?: boolean }) => {
    if (!blockByUrl(payload.url) || payload.force) {
      isBusyUpdated({ url: payload.url, isBusy: true });
      const { IsSuccess, Data } = await Req({ url: payload.url });

      if (IsSuccess && Data) {
        contentUpdated({ content: Data, url: payload.url });
      }
      isBusyUpdated({ url: payload.url, isBusy: false });
    }
  };

  const blockByUrl = (url?: string) => {
    return blocks.value.find((x) => x.Url === url);
  };

  const contentByUrl = (url?: string) => {
    const block = blockByUrl(url);
    return block?.Content || '<span></span>';
  };

  const isBlockBusy = (url?: string) => {
    const block = blockByUrl(url);
    return (block && block.IsBusy) || false;
  };

  return {
    isBlockBusy,
    contentByUrl,
    blockByUrl,
    umbracoContentBlocks,
    fetchUmbracoContentBlock,
    reset,
  };
});
