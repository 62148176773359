<template>
  <div class="product-details-list-item-content" v-if="certificate">
    <p class="product-details-list-item-title product-details-title-label">
      {{ textK('UI_PRODUCT_CERTIFICATE') }}
    </p>
    <p class="product-details-list-item-value" v-ui-test="'productDetailsCertificate'">
      {{ certificate }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import useText from '@/src/core/hooks/useText';
import { getProcurementByRouteParams } from '@/src/market/services/procurements';
import { isAirbus, productAdditionalData } from '@/src/market/services/product-parts';
import { useProductCertificate } from '@/src/market/services/product-rules';
import { useProductStore } from '@/src/market/stores/product';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

interface Props {
  id: string;
  owningSystem: string;
}

const props = defineProps<Props>();

const textK = useText();
const productStore = useProductStore();
const route = useRoute();

const product = computed(() => productStore.productById(props.id));
const productData = computed(() => productAdditionalData(props.id, props.owningSystem));

const procurementData = computed(() => {
  if (isAirbus(productData.value)) {
    return getProcurementByRouteParams(route.params, productData.value.procurements);
  }
  return null;
});

const certificate = computed(() => {
  if (product.value) {
    return useProductCertificate(product.value, procurementData.value);
  }
  return null;
});
</script>
