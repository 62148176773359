import { EventBus } from '@/src/market/services/event-bus';

type EventMap = {
  scroll: [scrollEvent: ScrollEvent];
  modalScroll: [payload: ScrollEvent];
};

export const ScrollEventBus = new EventBus<EventMap>();

export interface ScrollEvent {
  scrollPosition: number;
  originalEvent: Event;
}
