<template>
  <span></span>
</template>

<script setup lang="ts">
import useModal from '@/src/core/hooks/useModal';
import { useTimer } from '@/src/core/hooks/useTimer';
import { getEnv } from '@/src/core/services/environment';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { onBeforeMount } from 'vue';

const authenticationStore = useAuthenticationStore();
const openModal = useModal();
const remainingSessionTime = authenticationStore.getRemainingSessionTime();
const sessionLogoutModalTimer = getEnv('VUE_APP_SESSION_LOGOUT_MODAL_TIMER');

const logoutUser = () => {
  authenticationStore.doLogout();
  openModal({ modalComponent: 'ModalForcedLogout' });
};

const openSessionLogoutModal = () => {
  openModal({
    modalComponent: 'ModalSessionLogout',
  });
};

// Session timer
useTimer({
  time: remainingSessionTime - +sessionLogoutModalTimer,
  onComplete: openSessionLogoutModal,
});
useTimer({ time: remainingSessionTime, onComplete: logoutUser });

onBeforeMount(() => {
  // set the activity timestamp when user gets authenticated
  if (authenticationStore.isAuthenticated) {
    authenticationStore.saveLastActivityTimestamp();
  }
});
</script>
