import { Address, Shop, ShopAuthorisation, ShopDocumentInfo } from '@/src/core/apim';

export const getLeadShopId = (shops: Shop[]) => {
  return shops.find((shop) => shop.leadShop)?.id;
};

export const orderWeekDays = (weekDays: string[]): string[] => {
  const weekOrder: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  return weekDays.sort((a, b) => weekOrder.indexOf(a) - weekOrder.indexOf(b));
};

export const extendDocuments = (documents: ShopDocumentInfo[], leadShopId: string) => {
  return documents?.map((document) => ({
    ...document,
    friendlyName: toSentenceCase(document.type),
    url: `/market/seller/${leadShopId}/${document.id}?name=${document.name}`,
  }));
};

export const toSentenceCase = (str?: string): string => {
  if (!str) {
    return '';
  }

  // Replace hyphens with spaces
  str = str.replace(/-/g, ' ');

  // Capitalize the first letter
  str = str.charAt(0).toUpperCase() + str.slice(1);

  return str;
};

export const getDisplayAddress = (address: Address) => {
  let displayAddress: string = address.line1 ? address.line1 : '';
  displayAddress += address.line2 ? '<br />' + address.line2 : '';
  displayAddress += address.town ? '<br />' + address.town : '';
  displayAddress += address.postalCode ? ', ' + address.postalCode : '';
  displayAddress += address.district ? '<br />' + address.district : '';
  displayAddress += address.country?.name ? '<br />' + address.country.name : '';

  return displayAddress;
};

export const addPostalCodeTownToAddresses = (addresses?: Address[]) => {
  if (!addresses) {
    return [];
  }

  return addresses.map((address) => ({
    ...address,
    postalCodeTown: `${address.postalCode} ${address.town}`,
  }));
};

export const addDocumentsToAuthorization = (
  authorizations?: ShopAuthorisation[],
  documents?: ShopDocumentInfo[],
) => {
  if (!authorizations) {
    return [];
  }

  const formatLabelToDocType = (label: string) =>
    label.toLowerCase().replace(' ', '-').replace('/', '-');

  return authorizations.map((authorization) => {
    if (authorization.label && documents) {
      return {
        ...authorization,
        document: documents.find(
          (document) => document.type === formatLabelToDocType(authorization.label as string),
        ),
      };
    } else {
      return authorization;
    }
  });
};

export const getDepartments = (mastershop: Shop) => {
  const departments = [];

  if (mastershop.aogSupportContact != null) {
    const departmentInfoList = [];
    const brString = mastershop.aogSupportContact.title ? '<br />' : '';

    const contactInfo: any = {
      email: mastershop.aogSupportContact.email,
      phoneNumber: mastershop.aogSupportContact.phone,
      mobilePhoneNumber: mastershop.aogSupportContact.cellphone,
      openingHours: mastershop.aogSupportContact.openingHours,
      timeZone: mastershop.aogSupportContact.timeZone,
    };

    if (
      mastershop.aogSupportContact.title &&
      mastershop.aogSupportContact.firstName &&
      mastershop.aogSupportContact.lastName
    ) {
      contactInfo.mainContactPerson = `${mastershop.aogSupportContact.title}${brString}${mastershop.aogSupportContact.firstName} ${mastershop.aogSupportContact.lastName}`;
    }

    departmentInfoList.push(contactInfo);
    departments.push({ departmentName: 'AOG', shopContactDepartmentInfos: departmentInfoList });
  }

  if (mastershop.customerServiceContact != null) {
    const departmentInfoList = [];
    const brString = mastershop.customerServiceContact.title ? '<br />' : '';

    const contactInfo: any = {
      email: mastershop.customerServiceContact.email,
      phoneNumber: mastershop.customerServiceContact.phone,
      mobilePhoneNumber: mastershop.customerServiceContact.cellphone,
      openingHours: mastershop.customerServiceContact.openingHours,
      timeZone: mastershop.customerServiceContact.timeZone,
    };

    if (
      mastershop.customerServiceContact.title &&
      mastershop.customerServiceContact.firstName &&
      mastershop.customerServiceContact.lastName
    ) {
      contactInfo.mainContactPerson = `${mastershop.customerServiceContact.title}${brString}${mastershop.customerServiceContact.firstName} ${mastershop.customerServiceContact.lastName}`;
    }

    departmentInfoList.push(contactInfo);
    departments.push({
      departmentName: 'Customer support',
      shopContactDepartmentInfos: departmentInfoList,
    });
  }

  if (mastershop.financeContact != null) {
    const departmentInfoList = [];
    const brString = mastershop.financeContact.title ? '<br />' : '';

    const contactInfo: any = {
      email: mastershop.financeContact.email,
      phoneNumber: mastershop.financeContact.phone,
      mobilePhoneNumber: mastershop.financeContact.cellphone,
      openingHours: mastershop.financeContact.openingHours,
      timeZone: mastershop.financeContact.timeZone,
    };

    if (
      mastershop.financeContact.title &&
      mastershop.financeContact.firstName &&
      mastershop.financeContact.lastName
    ) {
      contactInfo.mainContactPerson = `${mastershop.financeContact.title}${brString}${mastershop.financeContact.firstName} ${mastershop.financeContact.lastName}`;
    }

    departmentInfoList.push(contactInfo);
    departments.push({ departmentName: 'Finance', shopContactDepartmentInfos: departmentInfoList });
  }

  if (mastershop.warrantyOrClaimsContact != null) {
    const departmentInfoList = [];
    const brString = mastershop.warrantyOrClaimsContact.title ? '<br />' : '';

    const contactInfo: any = {
      email: mastershop.warrantyOrClaimsContact.email,
      phoneNumber: mastershop.warrantyOrClaimsContact.phone,
      mobilePhoneNumber: mastershop.warrantyOrClaimsContact.cellphone,
      openingHours: mastershop.warrantyOrClaimsContact.openingHours,
      timeZone: mastershop.warrantyOrClaimsContact.timeZone,
    };

    if (
      mastershop.warrantyOrClaimsContact.title &&
      mastershop.warrantyOrClaimsContact.firstName &&
      mastershop.warrantyOrClaimsContact.lastName
    ) {
      contactInfo.mainContactPerson = `${mastershop.warrantyOrClaimsContact.title}${brString}${mastershop.warrantyOrClaimsContact.firstName} ${mastershop.warrantyOrClaimsContact.lastName}`;
    }

    departmentInfoList.push(contactInfo);
    departments.push({
      departmentName: 'Claims and returns',
      shopContactDepartmentInfos: departmentInfoList,
    });
  }

  return departments;
};
