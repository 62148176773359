<template>
  <StandardDialogModal class="list-modal" :size="uiSize.Medium" :loading="isBusy">
    <template #header>
      <h3>{{ textK('UI_PROFILE_DETAILS_SHIP_TO_ADDRESSES') }}</h3>
    </template>
    <template #body>
      <ModalColumnContainer>
        <ModalColumn>
          <SearchList
            v-if="shippingAddresses"
            :data="shippingAddresses"
            :search-keys="searcheableKeys"
            :label="'Search address'"
          >
            <template #default="{ item }">
              <SimpleListItem class="item">
                <AddressCard :data="item"></AddressCard>
              </SimpleListItem>
            </template>
          </SearchList>
        </ModalColumn>
      </ModalColumnContainer>
    </template>
  </StandardDialogModal>
</template>

<style lang="scss" src="./modal-profile-select-address.scss" scoped></style>

<script lang="ts" setup>
import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import AddressCard from '@/src/core/components/ui/addressCard/address-card.vue';
import SearchList from '@/src/core/components/ui/search-list/search-list.vue';
import SimpleListItem from '@/src/core/components/ui/simple-list-item/simple-list-item.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import {
  AIRBUS_ONLY_SEARCHABLE_KEYS,
  DEFAULT_ADDRESS_SEARCHABLE_KEYS,
} from '@/src/core/settings/address';
import { useModalStore } from '@/src/core/stores/modal';
import { CompanyDetailsTabs } from '@/src/core/types/interfaces';
import { useUserStore } from '@/src/profile/stores/user';
import { computed } from 'vue';

interface IModalProfileAddressParam {
  selectedCompany: string;
}

const textK = useText();
const modalStore = useModalStore();
const uiSize = UiSize;
const userStore = useUserStore();

const params = computed(() => {
  return modalStore.params as IModalProfileAddressParam;
});

const selectedCompany = computed(() => {
  return params.value?.selectedCompany;
});

const isBusy = computed(() => {
  return userStore.isBusy;
});

const isAirbus = computed(() => {
  return selectedCompany.value === CompanyDetailsTabs.AIRBUS;
});

const searcheableKeys = computed(() => {
  return [
    ...DEFAULT_ADDRESS_SEARCHABLE_KEYS,
    ...(isAirbus.value ? AIRBUS_ONLY_SEARCHABLE_KEYS : []),
  ];
});

const allDeliveryAddresses = computed(() => {
  if (selectedCompany.value === CompanyDetailsTabs.AIRBUS) {
    return userStore.company.BlueB2BUnit?.Addresses ?? [];
  } else if (selectedCompany.value === CompanyDetailsTabs.SATAIR) {
    return userStore.company.RedB2BUnit?.Addresses ?? [];
  }
  return userStore.company.Addresses;
});

const shippingAddresses = computed(() => {
  return allDeliveryAddresses.value?.filter(
    (address) => address.ShippingAddress === true && address.FormattedAddress !== '',
  );
});

const fetchDeliveryAddress = () => {
  userStore.fetchCompany({ userId: userStore.currentUser.GlobalId });
};

if (allDeliveryAddresses.value?.length <= 0) {
  fetchDeliveryAddress();
}
</script>
