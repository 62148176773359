<template>
  <form @submit.prevent="deleteEntry" class="clear-cart-modal">
    <StandardDialogModal :has-cancel-button="true">
      <template #header>
        <div class="typeset">
          <h3>{{ textK('UI_COMMON_REMOVE') }} {{ product.Sku }}?</h3>
          <p>
            {{ textK('UI_CART_DELETE_MODAL_BODY') }}
            <b>{{ entry.RoundedMOQ }} {{ product.Unit }}</b>
          </p>
        </div>
      </template>
      <template #actions>
        <VButton
          :type="uiVariant.Default"
          ref="button"
          v-ui-test="'delete-entry-button'"
          input-type="submit"
          :loading="isBusy"
          >{{ textK('UI_COMMON_REMOVE') }}</VButton
        >
      </template>
    </StandardDialogModal>
  </form>
</template>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useAdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { useModalStore } from '@/src/core/stores/modal';
import { CartEntry, Product } from '@/src/core/types/api';
import { useCartStore } from '@/src/market/stores/cart';
import { useListsStore } from '@/src/market/stores/list';
import { useProductStore } from '@/src/market/stores/product';
import { computed, onMounted, ref } from 'vue';

const textK = useText();
const productStore = useProductStore();
const listsStore = useListsStore();
const modalStore = useModalStore();
const cartStore = useCartStore();
const uiVariant = UiVariant;

const button = ref<InstanceType<typeof VButton> | null>(null);

const isBusy = computed(() => cartStore.isBusy);

const entry = computed(() => modalStore.params as IDeleteEntryModalParams);

const product = computed(() => {
  return entry.value.Product.AddToBasketQty > 0 ? entry.value.Product : productFromStore.value;
});

const productFromStore = computed(() => {
  return (entry.value.Id !== undefined && productStore.productById(entry.value.Id)) as Product;
});

const cartEntryNumber = computed(() => entry.value?.EntryNumber);

const listId = computed(() => entry.value?.ListId);

const deleteEntry = () => {
  if (cartEntryNumber.value !== undefined) {
    cartStore.deleteCartEntry({
      cartCode: cartStore.currentCartCode,
      entryId: cartEntryNumber.value,
    });
    const cartEntry = cartStore.getCartEntry(product.value.Id);
    if (cartEntry) {
      useAdobeLaunchTracking().removeFromCart(cartEntry);
    }
  } else if (listId.value) {
    listsStore.deleteListEntry({ listCode: listId.value, productId: product.value.Id });
  }
  modalStore.closeModal();
};

onMounted(() => {
  setTimeout(() => {
    button.value?.focus();
  }, 250);
});

interface IDeleteEntryModalParams extends CartEntry {
  Code?: string;
  Sku?: string;
  ListId?: string;
  RoundedMOQ?: string;
}
</script>
