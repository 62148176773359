<template>
  <span></span>
</template>

<script setup lang="ts">
import useModal from '@/src/core/hooks/useModal';
import { useTimer } from '@/src/core/hooks/useTimer';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { MAX_INACTIVE_TIME, TOTAL_MAX_INACTIVE_TIME } from '@/src/core/utils/time';

const authenticationStore = useAuthenticationStore();
const openModal = useModal();

const logoutUser = () => {
  authenticationStore.doLogout();
  openModal({ modalComponent: 'ModalForcedLogout' });
};

const showInactivityWarningModal = () => {
  openModal({ modalComponent: 'ModalInactivityWarning' });
};

useTimer({
  time: MAX_INACTIVE_TIME,
  onComplete: showInactivityWarningModal,
  resetOnInteraction: true,
});
useTimer({
  time: TOTAL_MAX_INACTIVE_TIME,
  onComplete: logoutUser,
  resetOnInteraction: true,
});
</script>
