/*
 * GENERATE Unique identifier (uuid4)
 */

export class GenerateUniqueIdentifier {
  /* tslint:disable */
  public uuid4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r: number = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  public number(): number {
    return parseInt(
      new Date().getUTCMilliseconds() + Math.round(99999999 / Math.random()).toString(),
    );
  }
  /* tslint:enable */
}
