<template>
  <form @submit.prevent="saveName">
    <StandardDialogModal
      :size="uiSize.Small"
      :has-cancel-button="true"
      :can-submit="list.Name.length > 0"
      :on-submit="saveName"
    >
      <template #header>
        <h3>Set name</h3>
      </template>
      <template #body>
        <div class="inner-body">
          <VTextField
            :type="uiVariant.Secondary"
            :disabled="isBusy"
            label="List name"
            name="List name"
            v-model="list.Name"
            ref="textfield"
          ></VTextField>
        </div>
      </template>
      <template #actions>
        <VButton
          v-ui-test="'rename-list-button'"
          input-type="submit"
          :disabled="list.Name.length <= 0"
          :loading="isBusy"
          @click.prevent="saveName"
          :type="uiVariant.Default"
          >Save</VButton
        >
      </template>
    </StandardDialogModal>
  </form>
</template>

<style lang="scss" src="./modal-rename-list.scss" scoped></style>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import { useModalStore } from '@/src/core/stores/modal';
import { ListType } from '@/src/core/types/api';
import { IList } from '@/src/core/types/interfaces';
import { useListsStore } from '@/src/market/stores/list';
import { useQuoteDraftsStore } from '@/src/market/stores/quote-drafts';
import { computed, onMounted, ref, watch } from 'vue';

interface IModalRenameListParams {
  Type: ListType;
}

const listsStore = useListsStore();
const quoteDraftsStore = useQuoteDraftsStore();
const modalStore = useModalStore();
const uiVariant = UiVariant;
const uiSize = UiSize;
const textfield = ref<InstanceType<typeof VTextField>>();

const params = computed<IModalRenameListParams>(() => modalStore.params as IModalRenameListParams);

const isBusy = computed(() => {
  return params.value.Type === ListType.QuoteDraft ? quoteDraftsStore.isBusy : listsStore.isBusy;
});

const list = computed<IList>(() => modalStore.params as IList);

const saveName = () => {
  modalStore.lockCurrentModal(true);
  if (params.value.Type === ListType.QuoteDraft) {
    quoteDraftsStore.renameList({ listCode: list.value.Code, listName: list.value.Name });
  } else {
    listsStore.renameList({ listCode: list.value.Code, listName: list.value.Name });
  }
};

// Watchers
watch(isBusy, (busy) => {
  if (!busy) {
    modalStore.closeModal();
  }
});

onMounted(() => {
  textfield.value?.setSelect();
});
</script>
