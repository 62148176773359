import {
  BillingDocumentInfoResponse,
  BillingDocumentInfoResponseList,
  InvoiceItem,
  InvoiceItemList,
  Price,
} from '@/core/apim';
import { ConvertToPascalCase } from '@/core/utils/object';

export const getPriceWithMapping = (price: Price) => {
  return {
    ...price,
    minQuantity: price?.minQuantity ?? 0,
    maxQuantity: price?.maxQuantity ?? 0,
    infoStatus: price?.infoStatus ?? '',
    isAsmHidden: price?.infoStatus === 'ASM_HIDDEN',
    volumeDiscountPercentage: price?.volumeDiscountPercentage ?? 0,
  };
};

export const getBillingDocumentInfoWithMapping = (document: BillingDocumentInfoResponse) => {
  const { totalAmount, netAmount, vatAmount, orderNumber, documentNumber, documentType } = document;
  const documentUrl = `/api/order/${orderNumber}/download?id=${documentNumber}&cat=${documentType}`;
  return {
    ...document,
    totalAmount: totalAmount ? getPriceWithMapping(totalAmount) : totalAmount,
    netAmount: netAmount ? getPriceWithMapping(netAmount) : netAmount,
    vatAmount: vatAmount ? getPriceWithMapping(vatAmount) : vatAmount,
    documentUrl,
  };
};
export type BillingDocumentInfoWithPriceMapping = ReturnType<
  typeof getBillingDocumentInfoWithMapping
>;
export type LegacyBillingDocumentInfo = ConvertToPascalCase<BillingDocumentInfoWithPriceMapping>;

export const getBillingDocumentInfoListWithPriceMapping = (
  documents: BillingDocumentInfoResponseList,
) => {
  const financeDocumentsWithPriceMapping = documents.billingDocumentInfos?.map(
    getBillingDocumentInfoWithMapping,
  );

  return {
    billingDocumentInfos: financeDocumentsWithPriceMapping,
    documentsCount: financeDocumentsWithPriceMapping?.length,
  };
};

export type BillingDocumentInfoListWithPriceMapping = ReturnType<
  typeof getBillingDocumentInfoListWithPriceMapping
>;
export type LegacyBillingDocumentInfoList =
  ConvertToPascalCase<BillingDocumentInfoListWithPriceMapping>;

export const getAccountStatementInvoiceItemsWithPriceMapping = (invoiceItem: InvoiceItem) => {
  const { amount } = invoiceItem;
  return {
    ...invoiceItem,
    amount: amount ? getPriceWithMapping(amount) : amount,
  };
};

export type AccountStatementInvoiceItemsWithPriceMapping = ReturnType<
  typeof getAccountStatementInvoiceItemsWithPriceMapping
>;
export type LegacyAccountStatementInvoiceItem =
  ConvertToPascalCase<AccountStatementInvoiceItemsWithPriceMapping>;

export const getAccountStatementInvoiceItemListWithPriceMapping = (invoices: InvoiceItemList) => {
  const invoiceItemsWithPriceMapping = invoices.invoiceItems?.map(
    getAccountStatementInvoiceItemsWithPriceMapping,
  );

  return {
    invoiceItems: invoiceItemsWithPriceMapping,
  };
};

export type AccountStatementInvoiceItemListWithPriceMapping = ReturnType<
  typeof getAccountStatementInvoiceItemListWithPriceMapping
>;
export type LegacyAccountStatementInvoiceItemList =
  ConvertToPascalCase<AccountStatementInvoiceItemListWithPriceMapping>;
