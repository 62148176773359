/**
 * Returns a script element if it exists in the parent.
 * @param {string} url
 * @param {HTMLElement | Document} parent
 * @returns {Element | null} The script if it exists
 */
export const getScriptByUrl = (url: string, parent: HTMLElement | Document = document) =>
  parent.querySelector(`script[src="${url}"]`);

/**
 * Adds a script in the document head.
 * @param {string} url
 */
export const addScript = (url: string) => {
  const script = document.createElement('script');
  script.setAttribute('src', url);
  document.head.appendChild(script);
};
