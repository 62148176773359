<template>
  <div class="additional-data">
    <button
      class="header-tool-button"
      :class="{
        'header-tool-button--primary': variant === uiVariant.Primary,
        'header-tool-button--secondary': variant === uiVariant.Secondary,
        'header-tool-button--default': variant === uiVariant.Default,
        'header-tool-button--auto': $slots.additional,
        'header-tool-button--asm': isAsm,
        active: isActive,
      }"
      @click="$emit('click', $event)"
    >
      <div class="additional-data__content" v-if="$slots.additional">
        <slot name="additional"></slot>
      </div>
      <svgicon class="header-tool-button__icon" :name="icon"></svgicon>
    </button>
  </div>
</template>

<style lang="scss" src="./header-tool-button.scss" />

<script lang="ts">
import { Vue, Component, Prop, toNative } from 'vue-facing-decorator';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import { WhatInputBus } from '@/src/core/utils/what-input-event-bus';
import { WhatInputHandler } from '@/src/core/utils/what-input-handler';

@Component({
  components: {
    VButton,
  },
})
export class HeaderToolButton extends Vue {
  @Prop() public icon: string;
  @Prop() public variant: UiVariant;
  @Prop({ required: false }) private onClick: ($event?: Event) => void;
  @Prop({ default: false }) public isAsm: boolean;
  @Prop() public isActive: boolean;

  public uiVariant = UiVariant;
  private uiSize = UiSize;
  private isMouseInput: boolean = false;

  private inputTypeChanged(type: string): void {
    this.isMouseInput = type === 'mouse';
  }

  public created() {
    this.isMouseInput = WhatInputHandler.hasMouse();
    WhatInputBus.$on('inputTypeChanged', this.inputTypeChanged);
  }
  public destroyed() {
    WhatInputBus.$off('inputTypeChanged', this.inputTypeChanged);
  }
}

export default toNative(HeaderToolButton);
</script>
