<template>
  <product-details-seller class="product-details-section">
    <template v-if="isMarketPlace">
      <div
        v-if="sellerInfo"
        class="product-details-seller__wrapper product-details-seller__wrapper--marketplace"
      >
        <div class="product-details-seller__description typeset">
          <div class="product-details-seller__description__inner">
            <h3 class="product-details-seller__title">{{ offer.MasterShopName }}</h3>
            <p>{{ sellerInfo.ShortDescription }}</p>
            <p>
              <router-link
                class="product-details-seller__link"
                :to="sellerDetailsUrl(sellerInfo.Id)"
                v-ui-test="'product-details-seller-link'"
                >{{ textK('UI_PRODUCTPAGE_ABOUTSELLER') }}</router-link
              >
            </p>
          </div>
        </div>
        <div class="product-details-seller__information typeset">
          <router-link :to="'/market/seller/' + sellerInfo.Id">
            <img
              class="product-details-seller__marketplace-logo"
              :src="sellerInfo.LogoCdnUrl"
              alt="seller-logo"
            />
          </router-link>
        </div>
      </div>
    </template>
    <template v-else>
      <product-details-plant-availability
        v-if="isAuthenticated"
        :id="product.Id"
        :owning-system="product.OwningSystem"
        :is-fhs="isFhs"
      ></product-details-plant-availability>
    </template>
  </product-details-seller>
</template>

<script lang="ts" setup>
import useText from '@/src/core/hooks/useText';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { MarketplaceMasterShop, Product, ProductOffer } from '@/src/core/types/api';
import ProductDetailsPlantAvailability from '@/src/market/components/product/product-details/product-details-plant-availability/product-details-plant-availability.vue';
import ProductDetailsSeller from '@/src/market/components/product/product-details/product-details-seller/product-details-seller.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

interface Props {
  offer: ProductOffer;
  product: Product;
  sellerInfo?: MarketplaceMasterShop | null;
  isFhs?: boolean;
}

const props = defineProps<Props>();

const textK = useText();
const authenticationStore = useAuthenticationStore();
const router = useRoute();

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);
const isMarketPlace = computed(() => props.product.IsMarketplace);

const sellerDetailsUrl = (sellerId: string) => {
  if (router.path.includes('cart')) {
    return `/market/cart/seller/${sellerId}`;
  }

  if (router.path.includes('lists')) {
    return `/market/lists/seller/${sellerId}`;
  }

  return `/market/seller/${sellerId}`;
};
</script>
