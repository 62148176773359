import { useAdminStore } from '@/src/account/stores/admin';
import { useContentStore } from '@/src/content/stores/content';
import { useFaqStore } from '@/src/content/stores/faq';
import { useAssistantStore } from '@/src/core/stores/assistant';
import { useContentBlocksStore } from '@/src/core/stores/content-blocks';
import { useDownloadsStore } from '@/src/core/stores/downloads';
import { useMaintenanceStore } from '@/src/core/stores/maintenance';
import { useModalStore } from '@/src/core/stores/modal';
import { useNotificationsStore } from '@/src/core/stores/notifications';
import { useReleaseNotesStore } from '@/src/core/stores/release-notes';
import { useAccountStatementStore } from '@/src/finance/stores/account-statement';
import { useDocumentsStore } from '@/src/finance/stores/documents';
import { useAirbusPartStore } from '@/src/market/stores/airbus-part';
import { useCarriersStore } from '@/src/market/stores/carriers';
import { useCartStore } from '@/src/market/stores/cart';
import { useCheckoutStore } from '@/src/market/stores/checkout';
import { useDeliveryAddressStore } from '@/src/market/stores/delivery-addresses';
import { useDeliveryAddressesQuotesStore } from '@/src/market/stores/delivery-addresses-quotes';
import { useListsStore } from '@/src/market/stores/list';
import { useMasterShopStore } from '@/src/market/stores/mastershops';
import { useOrderDetailsStore } from '@/src/market/stores/order-details';
import { useOrdersStore } from '@/src/market/stores/orders';
import { useProductStore } from '@/src/market/stores/product';
import { useProductAdditionalInfoStore } from '@/src/market/stores/product-additional-info';
import { useProductDetailsStore } from '@/src/market/stores/product-details';
import { useProductPlantAvailabilityStore } from '@/src/market/stores/product-plant-availability';
import { useProductRelationsStore } from '@/src/market/stores/product-relations';
import { useProductSuggestionsStore } from '@/src/market/stores/product-suggestions';
import { useQuoteDraftsStore } from '@/src/market/stores/quote-drafts';
import { useQuoteListStore } from '@/src/market/stores/quote-list';
import { useQuoteSingleStore } from '@/src/market/stores/quote-single';
import { useQuotesStore } from '@/src/market/stores/quotes';
import { useSearchStore } from '@/src/market/stores/search';
import { useSellerConversationStore } from '@/src/market/stores/seller-conversation';
import { useSellerDetailsStore } from '@/src/market/stores/seller-details';
import { useSellerQuoteConversationStore } from '@/src/market/stores/seller-quote-conversation';
import { useServiceReferenceStore } from '@/src/market/stores/service-reference';
import { useUserStore } from '@/src/profile/stores/user';

export const clearAllStores = () => {
  useAirbusPartStore().reset();
  useUserStore().reset();
  useAssistantStore().reset();
  useListsStore().reset();
  useQuoteDraftsStore().reset();
  useQuoteListStore().reset();
  useContentBlocksStore().reset();
  useDownloadsStore().reset();
  useMaintenanceStore().reset();
  useNotificationsStore().reset();
  useReleaseNotesStore().reset();
  useModalStore().reset();
  useServiceReferenceStore().$reset();
  useAdminStore().reset();
  useMasterShopStore().$reset();
  useContentStore().reset();
  useFaqStore().reset();
  useAccountStatementStore().reset();
  useDocumentsStore().reset();
  useCarriersStore().reset();
  useCartStore().reset();
  useCheckoutStore().reset();
  useDeliveryAddressStore().reset();
  useProductStore().reset();
  useSearchStore().reset();
  useProductAdditionalInfoStore().reset();
  useProductDetailsStore().reset();
  useProductPlantAvailabilityStore().reset();
  useProductRelationsStore().reset();
  useProductSuggestionsStore().reset();
  useSellerConversationStore().reset();
  useSellerQuoteConversationStore().reset();
  useDeliveryAddressesQuotesStore().$reset();
  useQuotesStore().$reset();
  useQuoteSingleStore().$reset();
  useOrderDetailsStore().reset();
  useOrdersStore().$reset();
  useDeliveryAddressStore().reset();
};

export const clearCriticalStores = () => {
  useAirbusPartStore().reset();
  useAssistantStore().reset();
  useListsStore().reset();
  useQuoteDraftsStore().reset();
  useQuoteListStore().reset();
  useMaintenanceStore().reset();
  useServiceReferenceStore().$reset();
  useAdminStore().reset();
  useMasterShopStore().$reset();
  useAccountStatementStore().reset();
  useDocumentsStore().reset();
  useCarriersStore().reset();
  useCartStore().reset();
  useCheckoutStore().reset();
  useDeliveryAddressStore().reset();
  useSellerDetailsStore().reset();
  useDeliveryAddressesQuotesStore().$reset();
  useProductStore().reset();
  useSearchStore().resetProductSkus();
  useProductAdditionalInfoStore().reset();
  useProductDetailsStore().reset();
  useProductPlantAvailabilityStore().reset();
  useProductRelationsStore().reset();
  useProductSuggestionsStore().reset();
  useSellerConversationStore().reset();
  useSellerQuoteConversationStore().reset();
  useOrderDetailsStore().reset();
  useOrdersStore().$reset();
  useDeliveryAddressStore().reset();
};
