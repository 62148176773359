<template>
  <InViewWatcher>
    <div class="typeform-module-wrapper">
      <slot></slot>
    </div>
  </InViewWatcher>
</template>

<style lang="scss" src="./typeform-module.scss"></style>
<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';
import { TYPEFORM_EMBED_URL } from '@/src/core/settings/integrations';
import { removeElement } from '@/src/core/utils/dom';
import { addScript, getScriptByUrl } from '@/src/core/utils/script';

@Component({
  components: {
    InViewWatcher,
  },
})
export class TypformModule extends Vue {
  public mounted() {
    const script = getScriptByUrl(TYPEFORM_EMBED_URL);

    if (script) {
      removeElement(script);
    }

    addScript(TYPEFORM_EMBED_URL);
  }
}

export default toNative(TypformModule);
</script>
