export enum ProcurementType {
  ExchangeNew = 'EXCHANGE_NEW',
  ExchangeUsed = 'EXCHANGE_USED',
  Exchange = 'EXCHANGE',
  Inspection = 'INSPECTION',
  Lease = 'LEASE',
  New = 'SALE_NEW',
  Repair = 'REPAIR',
  Scrapping = 'SCRAPPING',
  Servicable = 'SERVICEABLE',
  Used = 'SALE_USED',
}
