<template>
  <li class="navigation-dropdown-menu-item-wrapper">
    <template v-if="!raw">
      <router-link
        v-if="route && !blank"
        class="navigation-dropdown-menu-item navigation-dropdown-menu-item--clickable"
        :to="route"
        @click="
          closeNavigationDropdown(() => {
            $emit('click', $event);
          })
        "
      >
        <svgicon v-if="icon" class="navigation-dropdown-menu-item__icon" :name="icon"></svgicon>
        <span
          class="navigation-dropdown-menu-item__descriptor"
          :class="{ 'data-hj-suppress': dataSuppress }"
          ><slot></slot
        ></span>
      </router-link>
      <a
        v-else-if="route && blank"
        class="navigation-dropdown-menu-item navigation-dropdown-menu-item--clickable"
        :href="route.path"
        target="_blank"
        @click="
          closeNavigationDropdown(() => {
            $emit('click', $event);
          })
        "
      >
        <svgicon v-if="icon" class="navigation-dropdown-menu-item__icon" :name="icon"></svgicon>
        <span
          class="navigation-dropdown-menu-item__descriptor"
          :class="{ 'data-hj-suppress': dataSuppress }"
          ><slot></slot
        ></span>
      </a>
      <button
        v-else-if="button"
        class="navigation-dropdown-menu-item navigation-dropdown-menu-item--clickable"
        @click="
          closeNavigationDropdown(() => {
            $emit('click', $event);
          })
        "
      >
        <svgicon v-if="icon" class="navigation-dropdown-menu-item__icon" :name="icon"></svgicon>
        <span
          class="navigation-dropdown-menu-item__descriptor"
          :class="{ 'data-hj-suppress': dataSuppress }"
          ><slot></slot
        ></span>
      </button>
      <div v-else class="navigation-dropdown-menu-item">
        <svgicon v-if="icon" class="navigation-dropdown-menu-item__icon" :name="icon"></svgicon>
        <span
          class="navigation-dropdown-menu-item__descriptor"
          :class="{ 'data-hj-suppress': dataSuppress }"
          ><slot></slot
        ></span>
      </div>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </li>
</template>

<style lang="scss" src="./navigation-dropdown-menu-item.scss" scoped />

<script lang="ts" setup>
import { useUIStore } from '@/src/core/stores/ui';
import { RouteLocationNormalized } from 'vue-router';

interface Props {
  icon?: string;
  route?: Partial<RouteLocationNormalized>;
  blank?: boolean;
  raw?: boolean;
  button?: boolean;
  dataSuppress?: boolean;
}

withDefaults(defineProps<Props>(), {
  icon: '',
  route: undefined,
  blank: false,
  raw: false,
  button: false,
  dataSuppress: false,
});

const uiStore = useUIStore();

const closeNavigationDropdown = (cb?: () => void) => {
  uiStore.setNavigationDropdownId({ id: null });
  if (cb) {
    cb();
  }
};
</script>
