<template>
  <form @submit.prevent="deleteUser">
    <StandardDialogModal :has-cancel-button="true" :size="uiSize.Small">
      <template #header>
        <div class="typeset">
          <h3>Confirm delete profile</h3>
          <p>Are you sure?</p>
        </div>
      </template>
      <template #actions>
        <VButton
          :type="uiVariant.Default"
          v-ui-test="'delete-user-confirm-button'"
          @click="deleteUser"
          >Delete</VButton
        >
      </template>
    </StandardDialogModal>
  </form>
</template>

<script lang="ts" setup>
import { UserApi } from '@/src/core/api';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import { Req } from '@/src/core/services/requester';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useNotificationsStore } from '@/src/core/stores/notifications';
import { NotificationTypes } from '@/src/core/types/api';

const authenticationStore = useAuthenticationStore();
const notificationsStore = useNotificationsStore();

const uiVariant = UiVariant;
const uiSize = UiSize;

const deleteUser = async () => {
  const { IsSuccess } = await Req({
    url: UserApi.DeleteUser(authenticationStore.getGlobalId),
    method: 'DELETE',
  });

  if (IsSuccess) {
    authenticationStore.doLogout();
    notificationsStore.addNotification({
      notificationItem: {
        Description: 'Profile deleted',
        Type: NotificationTypes.Success,
        Timing: 5000,
      },
    });
  }
};
</script>
