<template>
  <form @submit.prevent>
    <StandardDialogModal
      :has-cancel-button="false"
      :size="tempUsers.length > 0 ? uiSize.Large : uiSize.Medium"
      :loading="isBusy || loading"
    >
      <template #header>
        <div class="typeset">
          <h3>{{ textK('UI_ADMINPAGE_CREATE') }}</h3>
          <p class="">{{ textK('UI_ADMINPAGE_CREATEINFOTEXT') }}</p>
        </div>
      </template>
      <template #body>
        <ModalColumnContainer>
          <ModalColumn>
            <div class="inner-body border-bottom">
              <div class="input-container">
                <VTextField
                  ref="emailInput"
                  input-type="email"
                  @valid="setValid('Email', $event)"
                  v-ui-test="'profile-details-email'"
                  :skeleton="isBusy"
                  :type="uiVariant.Secondary"
                  :label="textK('UI_COMMON_EMAIL')"
                  v-model="email"
                  :required="true"
                  name="email"
                  autocomplete="email"
                ></VTextField>
                <InputFeedback
                  :type="notificationTypes.Warning"
                  v-if="emailExists && email.length > 0"
                >
                  {{ textK('UI_ADMINPAGE_USER_EXISTS') }}
                </InputFeedback>
              </div>
              <div class="input-container">
                <VTextField
                  v-ui-test="'profile-details-name'"
                  :skeleton="isBusy"
                  :type="uiVariant.Secondary"
                  :label="textK('UI_COMMON_NAME') || 'Name'"
                  v-model="user.Name"
                  :required="false"
                  name="name"
                  autocomplete="name"
                ></VTextField>
              </div>

              <UserRoles :user="user" :is-busy="isBusy"></UserRoles>

              <div class="add-another typeset">
                <VButton
                  @click="addUser()"
                  :disabled="!formIsvalid || emailExists || tempUsers.length >= 30"
                  :type="uiVariant.Ghost"
                  :size="uiSize.Small"
                >
                  <template #iconPrepend>
                    <svgicon name="ui-new"></svgicon>
                  </template>
                  {{ textK('UI_ADMINPAGE_ADD_ANOTHER_USER') }}
                </VButton>
              </div>
            </div>
          </ModalColumn>

          <ModalColumn class="typeset" v-if="tempUsers.length > 0">
            <SimpleListItem
              class="temp-user"
              :key="tempUser.Email"
              v-for="tempUser of tempUsers"
              :class="{ 'already-exists': !tempUser.Created, created: tempUser.Created }"
            >
              <div class="temp-user-info">
                <span class="temp-user-name" v-if="tempUser.FirstName"
                  >{{ tempUser.FirstName }} {{ tempUser.LastName }}</span
                >
                <span class="temp-user-email" :class="{ 'temp-user-name': !tempUser.FirstName }">{{
                  tempUser.Email
                }}</span>
              </div>

              <div class="temp-user-roles">
                <VLabel class="temp-user-role label" v-if="tempUser.IsAdmin">{{
                  textK('UI_COMMON_ADMIN')
                }}</VLabel>
                <VLabel class="temp-user-role" v-if="tempUser.IsReadOnly">{{
                  textK('UI_ADMINPAGE_READONLYUSER')
                }}</VLabel>
                <VLabel class="temp-user-role" v-if="tempUser.IsFinance">{{
                  textK('UI_ADMINPAGE_FINANCEUSER')
                }}</VLabel>
                <VLabel class="temp-user-role">{{ textK('UI_COMMON_BUYER') }}</VLabel>
              </div>

              <button
                class="button-invisible temp-user-delete"
                @click="deleteTempUser(tempUser.Email)"
                :v-ui-test="'delete-user'"
              >
                <svgicon name="ui-delete"></svgicon>
              </button>
            </SimpleListItem>
          </ModalColumn>
        </ModalColumnContainer>
      </template>
      <template #actions>
        <VButton
          v-if="tempUsers.length <= 0"
          v-ui-test="'create-user-button'"
          :disabled="!formIsvalid"
          :loading="isBusy"
          input-type="button"
          @click="createUser()"
          :type="uiVariant.Default"
        >
          {{ textK('UI_COMMON_CREATE') }}
        </VButton>
        <VButton
          v-else
          v-ui-test="'create-user-button'"
          :loading="isBusy"
          input-type="button"
          @click="bulkCreateUsers()"
          :type="uiVariant.Default"
        >
          {{ textK('UI_ADMINPAGE_INVITE_USERS') }}
        </VButton>
      </template>
    </StandardDialogModal>
  </form>
</template>

<style lang="scss" src="./modal-create-new-user.scss" scoped></style>

<script setup lang="ts">
import { useAdminStore } from '@/src/account/stores/admin';
import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import InputFeedback from '@/src/core/components/ui/form/input-feedback/input-feedback.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import VLabel from '@/src/core/components/ui/label/label.vue';
import SimpleListItem from '@/src/core/components/ui/simple-list-item/simple-list-item.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { NotificationTypes } from '@/src/core/types/api';
import { TempUser } from '@/src/core/types/interfaces';
import UserRoles from '@/src/profile/components/user/user-roles/user-roles.vue';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';

const textK = useText();
const adminStore = useAdminStore();
const modalStore = useModalStore();

const uiSize = UiSize;
const uiVariant = UiVariant;

const validatationCollection = ref({});
const loading = ref(false);
const emailExists = ref(false);

const notificationTypes = NotificationTypes;

const emailInput = ref<InstanceType<typeof VTextField>>();

const email = ref('');
const user = ref<TempUser>({
  Email: '',
  Name: '',
} as TempUser);

const isBusy = computed(() => adminStore.isBusy);
const tempUsers = computed(() => adminStore.tempUsers);

const setValid = (key: string, valid: boolean) => {
  validatationCollection.value = {
    ...validatationCollection.value,
    [key]: valid,
  };
};

const formIsvalid = computed(() => {
  return !Object.keys(validatationCollection.value).find(
    (key) => validatationCollection.value[key] === false,
  );
});

const deleteTempUser = (email: string) => {
  emailInput.value?.resetValidation();
  adminStore.deleteTempUser({ email });
};

watch(email, () => {
  const emailExistsIndex = tempUsers.value.findIndex((tempUser) => {
    return tempUser.Email === email.value;
  });
  emailExists.value = emailExistsIndex > -1;
});

const processData = (user: TempUser) => {
  if (user.Name) {
    if (user.Name.split(' ').length > 1) {
      const FirstName = user.Name.split(' ').slice(0, -1).join(' ');
      const LastName = user.Name.split(' ').slice(-1).join(' ');
      user = { ...user, FirstName, LastName };
    } else {
      user = { ...user, FirstName: user.Name };
    }
  }
  user = { ...user, Email: email.value };
  return user;
};

const addUser = async () => {
  const tempUser = processData(user.value);
  await adminStore.addTempUser({ user: tempUser });
  resetFormInputs();
  emailInput.value?.resetValidation();
  emailInput.value?.setFocus();
};

const resetFormInputs = () => {
  user.value.Email = '';
  user.value.Name = '';
  email.value = '';
};

const bulkCreateUsers = async () => {
  await modalStore.lockCurrentModal(true);
  loading.value = true;

  await adminStore.addUsers({ users: tempUsers.value }).then(({ IsSuccess }) => {
    emailInput.value?.resetValidation();
    loading.value = false;

    if (IsSuccess) {
      modalStore.closeModal();
      adminStore.resetTemptUsers();
    }
  });
};

const createUser = async () => {
  await modalStore.lockCurrentModal(true);
  loading.value = true;

  const tempUser = processData(user.value);

  await adminStore.addUser({ user: tempUser }).then(({ IsSuccess }) => {
    emailInput.value?.resetValidation();

    if (IsSuccess) {
      resetFormInputs();
      modalStore.closeModal();
    } else {
      emailExists.value = true;
    }

    emailInput.value?.setFocus();
  });

  loading.value = false;
};

onMounted(() => {
  emailInput.value?.setFocus();
});

onBeforeUnmount(() => {
  adminStore.fetchUsers();
});
</script>
