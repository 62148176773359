<template>
  <StandardDialogModal class="modal-session-logout" :size="uiSize.Medium" :has-close-button="true">
    <template #header>
      <h3>{{ textK('UI_LOGIN_SESSION_LOGOUT_MODAL_TITLE') }}</h3>
    </template>

    <template #body>
      <p v-html="description"></p>
    </template>

    <template #actions>
      <VButton v-ui-test="'log-out-now-button'" :type="uiVariant.Default" @click="logout()">{{
        textK('UI_LOGIN_SESSION_LOGOUT_MODAL_CTA')
      }}</VButton>
    </template>
  </StandardDialogModal>
</template>

<script setup lang="ts">
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { getEnv } from '@/src/core/services/environment';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useModalStore } from '@/src/core/stores/modal';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

const textK = useText();
const modalStore = useModalStore();
const authenticationStore = useAuthenticationStore();
const uiSize = UiSize;
const uiVariant = UiVariant;
const sessionLogoutModalTimer = getEnv('VUE_APP_SESSION_LOGOUT_MODAL_TIMER');
const sessionLogoutModalTimerInSeconds = +sessionLogoutModalTimer / 1000;
const remainingTime = ref(sessionLogoutModalTimerInSeconds);
const timer = ref<NodeJS.Timeout | null>(null);

const description = computed(() => {
  const minutes = Math.floor(remainingTime.value / 60);
  const seconds = remainingTime.value % 60;
  return textK('UI_LOGIN_SESSION_LOGOUT_MODAL_DESCRIPTION').replace(
    '[[remaining_time]]',
    `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`,
  );
});

const logout = () => {
  if (timer.value) {
    clearInterval(timer.value);
    timer.value = null;
  }
  authenticationStore.doLogout();
  modalStore.closeModal();
};

const initializeInterval = () => {
  timer.value = setInterval(() => {
    if (remainingTime.value > 0) {
      remainingTime.value--;
    }
  }, 1000);
};

const syncCountdownBetweenWindows = () => {
  if (!authenticationStore.isAuthenticated) {
    modalStore.closeModal();
  }

  // update the counter in the modal between tabs
  const remainingSessionTime = authenticationStore.getRemainingSessionTime();
  if (remainingSessionTime > 0) {
    remainingTime.value = Math.floor(remainingSessionTime / 1000);
  }
};

onMounted(() => {
  initializeInterval();
  syncCountdownBetweenWindows();
  window.addEventListener('focus', syncCountdownBetweenWindows);
});

onBeforeUnmount(() => {
  window.removeEventListener('focus', syncCountdownBetweenWindows);
});
</script>
