<template>
  <p class="product-details-list-item-value" v-ui-test="'productDetailsSupplier'">
    <span class="supplier-name" :title="supplierName">{{ supplierName }}</span>
  </p>
</template>

<style lang="scss" src="./product-details-sold-by.scss" scoped></style>

<script lang="ts" setup>
import { useUserStore } from '@/src/profile/stores/user';
import { computed } from 'vue';

interface Props {
  seller: string;
  isFhs?: boolean;
}

const props = defineProps<Props>();
const userStore = useUserStore();

const supplierName = computed(() => {
  if (props.seller === 'Airbus' && !props.isFhs) {
    return userStore.airbusLegalName;
  }
  return props.seller;
});
</script>
