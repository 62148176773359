// tslint:disable
import { ShopDocumentInfo, apim } from '@/src/core/apim';
import { getEnv } from '@/src/core/services/environment';
import { toLegacyResponse } from '@/src/core/services/legacy-response-utils';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import { addCommerceCDNtoUrl } from '@/src/market/services/legacy-mapping';
import {
  addDocumentsToAuthorization,
  addPostalCodeTownToAddresses,
  extendDocuments,
  getDepartments,
  getDisplayAddress,
  getLeadShopId,
  orderWeekDays,
} from '@/src/market/services/seller';
import Cookies from 'js-cookie';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class SellerApi {
  public static SellerDetails = (sellerId: string) => `${baseUrl}/market/seller/${sellerId}`;
  public static FetchDeliveryAddresses = (sellerId: string) =>
    `${baseUrl}/api/seller/${sellerId}/shippingaddresses`;
  public static FetchMastershopById = (sellerId: string) => `${baseUrl}/api/mastershop/${sellerId}`;
  public static FetchShopById = (shopId: string) => `${baseUrl}/api/seller/${shopId}/shop`;

  public static async handleMastershopById({ id }: any) {
    const resp = await apim.getMasterShop('satairb2b', id, {
      asm: Cookies.get('asm') === 'true',
      fields: 'FULL',
    });

    const mastershopData = resp.data;
    const termsAndPoliciesDocumentTypes = [
      'terms-and-conditions',
      'additional-policies',
      'warranty-return-policy',
    ];
    let documents = null;
    const leadShopId = getLeadShopId(resp?.data?.shops || []);

    // make additional call to get documents
    if (mastershopData && leadShopId) {
      const documentsResp = await apim.getShopDocuments('satairb2b', leadShopId, {
        asm: Cookies.get('asm') === 'true',
        fields: 'FULL',
      });
      documents = extendDocuments(documentsResp.data, leadShopId);
    }

    // add extended data to match Umbraco logic
    const additionalMastershopData = {
      shortDescription: mastershopData.description?.substring(0, 255),
      bannerUrl: addCommerceCDNtoUrl(mastershopData.banner),
      logoCdnUrl: addCommerceCDNtoUrl(mastershopData.logo),
      leadShopId,
      documents,
      qualitySelfAssesmentUrl:
        documents?.find((document) => document.type === 'quality-self-assesment')?.url ?? undefined,
      termsAndPolicies: documents?.filter((document) => {
        if (document.type && termsAndPoliciesDocumentTypes.includes(document.type)) {
          return true;
        }
        return false;
      }),
      priorities: mastershopData?.priorityHandling?.map((prio) => prio.code),
      priorityFee:
        mastershopData?.priorityFees && mastershopData.priorityFees.length > 0
          ? mastershopData.priorityFees[0]
          : undefined,
      displayAddress: mastershopData?.address
        ? getDisplayAddress(mastershopData.address)
        : undefined,
      showTermsSection: Boolean(
        mastershopData.address ||
          mastershopData.companyOfficeDays ||
          mastershopData.companyPublicHolidays ||
          mastershopData.companyDunsNumber ||
          mastershopData.taxIdNumber,
      ),
      showCompanyDetailsSection: Boolean(
        mastershopData.address ||
          mastershopData.companyOfficeDays ||
          mastershopData.companyPublicHolidays ||
          mastershopData.companyDunsNumber ||
          mastershopData.taxIdNumber,
      ),
      companyOfficeDays: mastershopData.companyOfficeDays
        ? orderWeekDays(mastershopData.companyOfficeDays)
        : undefined,
    };

    // fix the casing so that it get transformed to IsoCode instead of Isocode
    if (mastershopData?.address?.country && 'isoCode' in mastershopData?.address?.country) {
      mastershopData.address.country.isoCode = mastershopData.address?.country?.isocode as string;
      delete mastershopData.address?.country['isocode'];
    }

    return toLegacyResponse(resp, {
      Data: objectKeysToPascalCaseRecursive({ ...mastershopData, ...additionalMastershopData }),
    });
  }

  public static async handleDeliveryAddresses({ id }: any) {
    const globalId = Cookies.get('globalId') || '';

    const resp = await apim.getShippingAddresses('satairb2b', id, globalId, {
      asm: Cookies.get('asm') === 'true',
      fields: 'FULL',
    });

    const addressesData = resp.data;
    const additionalAddressesData = {
      addresses: addPostalCodeTownToAddresses(addressesData.addresses),
    };

    return toLegacyResponse(resp, {
      Data: objectKeysToPascalCaseRecursive({ ...addressesData, ...additionalAddressesData }),
    });
  }

  public static async handleShopById({ id }: any) {
    const resp = await apim.getShop('satairb2b', id, {
      asm: Cookies.get('asm') === 'true',
      fields: 'FULL',
    });

    const shopData = resp.data;
    let documents = null;

    // make additional call to get documents
    if (shopData) {
      const documentsResp = await apim.getShopDocuments('satairb2b', id, {
        asm: Cookies.get('asm') === 'true',
        fields: 'FULL',
      });
      documents = extendDocuments(documentsResp.data, id);
    }

    // add extended data to match Umbraco logic
    const additionalShopData = {
      authorisations: addDocumentsToAuthorization(
        shopData.authorisations,
        documents as ShopDocumentInfo[],
      ),
      otherAuthorisationsDetails: shopData.otherAuthorisationsDetails || 'Further Specification',
      restrictedCountries: shopData.restrictedCountries?.map((country) => ({
        name: country.name,
        isoCode: country.isocode,
      })),
      customerServiceContact: {
        ...shopData.customerServiceContact,
        addressTypeFriendlyName: 'Customer support',
      },
      financeContact: { ...shopData.financeContact, addressTypeFriendlyName: 'Finance' },
      warrantyOrClaimsContact: {
        ...shopData.warrantyOrClaimsContact,
        addressTypeFriendlyName: 'Claims and returns',
      },
      departments: getDepartments(shopData),
      documents,
    };

    return toLegacyResponse(resp, {
      Data: objectKeysToPascalCaseRecursive({ ...shopData, ...additionalShopData }),
    });
  }
}
