<template>
  <div class="market-solution-icon">
    <MarketLogo ref="logo" />
  </div>
</template>

<style src="./market-solution-icon.scss" lang="scss" scoped></style>

<script lang="ts" setup>
import MarketLogo from '@/src/market/components/ui/market-logo/market-logo.vue';
import { gsap } from 'gsap';
import { onMounted, onUnmounted, ref, watch } from 'vue';

const EASE_IN = 'back.in';
const EASE_OUT = 'power4.out';

const timeline = gsap.timeline({ paused: true });
const hovered = ref(false);
const logo = ref<MarketLogo | null>(null);

const createTimeline = () => {
  if (
    logo.value?.$refs.leftside &&
    logo.value?.$refs.rightside &&
    logo.value?.$refs.blacksquare &&
    logo.value?.$refs.whitesquare &&
    logo.value?.$refs.whitesquare50
  ) {
    timeline
      .to(
        logo.value?.$refs.whitesquare50,
        { x: -60, y: -60, ease: EASE_IN, duration: 0.6 },
        'loopIn',
      )
      .to(logo.value?.$refs.whitesquare, { x: -60, y: -60, ease: EASE_IN, duration: 0.6 }, 'loopIn')
      .to(logo.value?.$refs.blacksquare, { x: 60, y: 60, ease: EASE_IN, duration: 0.6 }, 'loopIn')
      .to(
        logo.value?.$refs.whitesquare50,
        { scale: 0, transformOrigin: 'center center', duration: 0.3 },
        'loopIn+=0.6',
      )
      .to(
        logo.value?.$refs.whitesquare,
        { scale: 0, transformOrigin: 'center center', duration: 0.3 },
        'loopIn+=0.6',
      )
      .to(
        logo.value?.$refs.blacksquare,
        { scale: 0, transformOrigin: 'center center', duration: 0.3 },
        'loopIn+=0.6',
      )
      .set(logo.value?.$refs.whitesquare50, { x: 350, y: 350, scale: 1 }, 'loopIn+=0.9')
      .set(logo.value?.$refs.whitesquare, { x: 350, y: 350, scale: 1 }, 'loopIn+=0.9')
      .set(logo.value?.$refs.blacksquare, { x: -350, y: -350, scale: 1 }, 'loopIn+=0.9')
      .to(
        logo.value?.$refs.whitesquare50,
        { x: 0, y: 0, scale: 1, ease: EASE_OUT, duration: 1.2 },
        'loopIn+=0.9',
      )
      .to(
        logo.value?.$refs.whitesquare,
        { x: 0, y: 0, scale: 1, ease: EASE_OUT, duration: 1.2 },
        'loopIn+=0.9',
      )
      .to(
        logo.value?.$refs.blacksquare,
        {
          x: 0,
          y: 0,
          scale: 1,
          ease: EASE_OUT,
          duration: 1.2,
          onComplete: () => {
            if (hovered.value) {
              timeline.play('loopIn');
            }
          },
        },
        'loopIn+=0.9',
      );
  }
};

const onEnter = () => {
  hovered.value = true;
};

const onLeave = () => {
  hovered.value = false;
};

watch(
  () => hovered.value,
  () => {
    if (hovered.value && !timeline.isActive()) {
      timeline.play('loopIn');
    }
  },
);

onMounted(() => {
  createTimeline();
});

onUnmounted(() => {
  timeline.kill();
});

defineExpose({
  onEnter,
  onLeave,
});
</script>
