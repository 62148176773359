export enum UiContext {
  ContentSite,
  ShopSite,
}

export enum UiVariant {
  Default,
  Primary,
  Secondary,
  Ghost,
  Transparent,
  Tertiary,
  Link,
  Warning,
}

export enum UiSize {
  Small,
  Medium,
  Large,
  XLarge,
  Auto,
  Lilly,
}

export enum UiColumnSize {
  Default, // 50%
  OneThird, // 33%
  OneFourth, // 25%
  TwoThirds, // 67%
}
