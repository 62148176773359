<template>
  <StandardDialogModal
    :locked-modal="false"
    :loading="busy"
    :has-cancel-button="false"
    :has-close-button="true"
    :size="uiSize.Medium"
  >
    <template #header>
      <div class="typeset">
        <h3>{{ textK('UI_ACCOUNT_STATEMENT_DOWNLOAD_MODAL_TITLE') }}</h3>
      </div>
    </template>
    <template #body>
      <div class="inner-body">{{ textK('UI_ACCOUNT_STATEMENT_DOWNLOAD_MODAL_BODY') }}</div>
    </template>
    <template #actions>
      <div class="actions" :class="{ 'actions--airbus': isAirbus }">
        <VButton
          :type="btnUiVariant"
          v-ui-test="'account-statement-download-excel'"
          @click="download(fileTypes.excel)"
          >{{ textK('UI_ACCOUNT_STATEMENT_DOWNLOAD_XLS') }}</VButton
        >
        <VButton
          v-if="isAirbus"
          :type="btnUiVariant"
          v-ui-test="'account-statement-download-csv'"
          @click="download(fileTypes.csv)"
          >{{ textK('UI_ACCOUNT_STATEMENT_DOWNLOAD_CSV') }}</VButton
        >
        <VButton
          :type="btnUiVariant"
          :disabled="isAirbus ? false : filter.overdueItems"
          v-ui-test="'account-statement-download-pdf'"
          @click="download(fileTypes.pdf)"
          >{{ textK('UI_ACCOUNT_STATEMENT_DOWNLOAD_PDF') }}</VButton
        >
      </div>
    </template>
  </StandardDialogModal>
</template>

<style lang="scss" src="./modal-account-statement-download.scss" scoped></style>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { FinanceApi } from '@/src/core/api';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import DownloadFile from '@/src/core/services/download';
import { ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { useModalStore } from '@/src/core/stores/modal';
import { AccountStatementRequest, FileTypes } from '@/src/core/types/api';
import { useAccountStatementStore } from '@/src/finance/stores/account-statement';
import { useUserStore } from '@/src/profile/stores/user';

interface Params {
  isAirbus?: boolean;
}

const textK = useText();
const modalStore = useModalStore();
const uiSize = UiSize;
const uiVariant = UiVariant;
const fileTypes = FileTypes;
const busy = ref(false);
const accountStatementStore = useAccountStatementStore();
const userStore = useUserStore();

const params = computed(() => modalStore.params as Params);
const isAirbus = computed(() => params.value?.isAirbus || false);
const unitId = computed(() => userStore.currentUser?.DefaultUnitUid);
const filter = computed(() => accountStatementStore.filters);
const btnUiVariant = computed(() => (isAirbus.value ? uiVariant.Secondary : uiVariant.Default));

const download = (fileType: FileTypes) => {
  if (isAirbus.value) {
    downloadAirbus(fileType);
  } else {
    downloadSatair(fileType);
  }
};

const downloadSatair = (fileType: FileTypes) => {
  const accountStatementRequest: AccountStatementRequest = {
    UnitId: unitId.value,
    FromDate: filter.value.fromDate || '',
    ToDate: filter.value.toDate || '',
    FileType: fileType,
    OpenItems: filter.value.openItems || false,
    OverdueItems: filter.value.overdueItems || false,
  };

  busy.value = true;
  DownloadFile(
    {
      url: FinanceApi.DownloadAccountStatement,
      data: accountStatementRequest,
      method: 'POST',
    },
    new ReqQueue(ReqQueueTypes.Default, `account_statement_download_${fileType}`),
    FinanceApi.handleDownloadAccountStatement,
  ).then(({ IsSuccess }) => {
    busy.value = false;
    if (IsSuccess) {
      modalStore.closeModal();
    }
  });
};

const downloadAirbus = (fileType: FileTypes) => {
  const data = {
    keyDate: filter.value.keyDate || '',
    fileFormat: fileType,
  };

  busy.value = true;
  DownloadFile(
    {
      url: FinanceApi.DownloadAirbusAccountStatement,
      data: data,
      method: 'POST',
    },
    new ReqQueue(ReqQueueTypes.Default, `account_statement_download_${fileType}`),
  ).then(({ IsSuccess }) => {
    busy.value = false;
    if (IsSuccess) {
      modalStore.closeModal();
    }
  });
};
</script>
