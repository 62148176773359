<template>
  <div ref="mediaHandler" class="media-handler" :style="setInlineStyle">
    <transition name="slowfade">
      <ImageHandler
        v-if="!disablePlaceholder"
        :alt="altText"
        :images="placeholder"
        :type="imgType"
        :load-image="shouldLoadImg"
        :container-width="containerWidth"
        @error="loadError"
        @imageLoad="removeInlineStyle"
      ></ImageHandler>
    </transition>

    <VideoHandler v-if="video" :source="video" @videoLoad="hidePlaceholder"></VideoHandler>
  </div>
</template>

<style src="./media-handler.scss" lang="scss" scoped></style>

<script setup lang="ts">
import ImageHandler from '@/src/core/components/media-handler/image-handler/image-handler.vue';
import VideoHandler from '@/src/core/components/media-handler/video-handler/video-handler.vue';
import { ResizeEventBus } from '@/src/core/utils/resize-event-bus';
import { ScrollEventBus } from '@/src/core/utils/scroll-event-bus';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

interface ISources {
  url: string;
  size: number;
  focalPointTop?: number;
  focalPointLeft?: number;
  originalWidth?: number;
  originalHeight?: number;
}

interface Props {
  altText?: string;
  placeholder: ISources[];
  video?: string;
  hex?: string;
  height?: number;
  width?: number;
  imgType?: 'inline' | 'background';
}

const props = withDefaults(defineProps<Props>(), {
  imgType: 'inline',
  altText: '',
  video: undefined,
  hex: undefined,
  height: undefined,
  width: undefined,
});

const containerWidth = ref<number>(0);
const evTimeout = ref<number>(150);
const calculatedHeight = ref<number>(0);
const ratio = ref<number>(0);
const addInlineStyle = ref<boolean>(true);
const disablePlaceholder = ref<boolean>(false);
const shouldLoadImg = ref<boolean>(false);
const imageFailed = ref<boolean>(false);
const mediaHandler = ref<HTMLElement | null>(null);

const placeholder = computed(() => {
  return props.placeholder;
});

const setInlineStyle = computed(() => {
  // if the image is loaded we dont need to set the container height any more
  if (!addInlineStyle.value && !imageFailed.value) {
    return {};
  }

  return {
    backgroundColor: backgroundShouldBeTransparent() ? 'transparent' : props.hex,
    height: props.imgType === 'background' ? null : calculatedHeight.value + 'px',
  };
});

const backgroundShouldBeTransparent = (): boolean => {
  if (
    placeholder.value[0] !== undefined &&
    Object.prototype.hasOwnProperty.call(placeholder.value[0], 'url')
  ) {
    const firstImage = placeholder.value[0].url;
    const imageIsPNG = firstImage.match(/\.png$/g);
    return imageIsPNG !== null;
  } else {
    return false;
  }
};

onMounted(() => {
  ratio.value = props.width / props.height;

  if (mediaHandler.value) {
    calculatedHeight.value = mediaHandler.value.offsetWidth / ratio.value;
    containerWidth.value = mediaHandler.value.offsetWidth;

    throttleLoad();
  }

  ResizeEventBus.$on('resize', debounce(recalcRatio, evTimeout.value));
  ScrollEventBus.$on('scroll', throttle(throttleLoad, evTimeout.value));
});

onBeforeUnmount(() => {
  ResizeEventBus.$off('resize', debounce(recalcRatio, evTimeout.value));
  ScrollEventBus.$off('scroll', throttle(throttleLoad, evTimeout.value));
});

const loadError = () => {
  imageFailed.value = true;
};

const hidePlaceholder = () => {
  disablePlaceholder.value = true;
};

const removeInlineStyle = () => {
  addInlineStyle.value = false;
  ScrollEventBus.$off('scroll', throttle(throttleLoad, evTimeout.value));
};

const recalcRatio = () => {
  if (mediaHandler.value) {
    if (addInlineStyle.value) {
      calculatedHeight.value = mediaHandler.value.offsetWidth / ratio.value;
    }

    containerWidth.value = mediaHandler.value.offsetWidth;
  }
};

// check if the container is partially in viewport
const isInViewport = () => {
  if (!mediaHandler.value) {
    return false;
  } else {
    const rect = mediaHandler.value.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;
    const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
    const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;
    return vertInView && horInView;
  }
};

// throttle image load
const throttleLoad = () => {
  setTimeout(() => {
    if (isInViewport()) {
      shouldLoadImg.value = true;
    }
  }, 250); // duration of the img fade in
};
</script>
