import { IAdobe, IAdobeTriggerViewOptions, IWindowAdobe } from '@/src/core/types/adobe';

export function adobeTargetTriggerView(viewName: string, options: IAdobeTriggerViewOptions) {
  const adobe: IAdobe = (window as unknown as IWindowAdobe).adobe;

  if (viewName && adobe && adobe.target) {
    viewName = viewName.replace(/^\/+/, '');

    if (viewName.length > 0) {
      adobe.target.triggerView(viewName, options);
    }
  }
}
