<template>
  <div class="seller-order-conversation">
    <div class="seller-order-conversation__header" v-ui-test="'seller-conversation-header'">
      <h3 class="seller-order-conversation__title">
        {{ textK('UI_SELLER_CONVERSATION_MODAL_TITLE') }} ({{ sellerName }})
      </h3>
      <p class="seller-order-conversation__sub-title">
        {{ textK('UI_SELLER_CONVERSATION_MODAL_DISCLAIMER_ 1') }} <strong>{{ sellerName }}</strong>
        {{ textK('UI_SELLER_CONVERSATION_MODAL_DISCLAIMER_ 2') }}
      </p>
      <div
        class="seller-order-conversation__order"
        v-if="showDetails"
        v-ui-test="'seller-conversation-order'"
      >
        <div class="seller-order-conversation__order__po" v-ui-test="'seller-conversation-code'">
          <span v-if="quoteId">
            {{ textK('UI_QUOTES_QUOTE_NO') }}
            {{ quoteId }}
          </span>
          <span v-else-if="orderRef">
            {{ textK('UI_ORDERS_PONO') }}
            {{ orderRef }}
          </span>
        </div>
        <div class="seller-order-conversation__order__code" v-ui-test="'seller-conversation-ref'">
          <span v-if="quoteRef">
            {{ textK('UI_QUOTES_QUOTE_PO_NO') }}
            {{ quoteRef }}
          </span>
          <span v-else-if="orderId">
            {{ textK('UI_ORDERS_ORDERNO') }}
            {{ orderId }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./seller-order-conversation-header.scss" scoped />

<script lang="ts">
import { Vue, Component, Prop, toNative } from 'vue-facing-decorator';
import useText from '@/src/core/hooks/useText';
import { MarketplaceMessagingConsignemtThreads, Warehouse } from '@/src/core/types/api';

@Component({})
export class SellerOrderConversationHeader extends Vue {
  @Prop() private thread: MarketplaceMessagingConsignemtThreads;
  @Prop() private wareHouse: Warehouse;
  @Prop({ default: '' }) private masterShopName: string;
  @Prop({ default: '' }) private locationDisplayName: string;
  @Prop({ default: '' }) public orderId: string;
  @Prop({ default: '' }) public orderRef: string;
  @Prop({ default: '' }) public quoteRef: string;
  @Prop({ default: '' }) public quoteId: string;
  @Prop({ default: false }) private isQuote: boolean;
  @Prop({ default: false }) public isBusy: boolean;

  public textK = useText();

  public get sellerName() {
    if (this.isBusy) {
      return '';
    }

    if (this.isQuote) {
      return this.masterShopName + ', ' + this.locationDisplayName;
    }
    return this.thread.MasterShopName + ', ' + this.thread.LocationDisplayName;
  }

  public get showDetails() {
    return this.quoteId || this.quoteRef || this.orderId || this.orderRef;
  }
}

export default toNative(SellerOrderConversationHeader);
</script>
