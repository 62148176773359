<template>
  <div>
    <div class="seller-cover">
      <div class="seller-cover-loader"></div>
      <div class="seller-cover__container">
        <ArrowIcon class="hero-module-arrow arrow--h1" :direction="'right-down'"></ArrowIcon>
        <h1>
          <SkeletonLoader></SkeletonLoader>
        </h1>
      </div>
    </div>
    <div class="seller-details">
      <div class="seller-details__main">
        <div class="seller-details__main__inner typeset">
          <p>
            <SkeletonLoader class="details-skeleton-1"></SkeletonLoader>
            <SkeletonLoader class="details-skeleton-2"></SkeletonLoader>
            <SkeletonLoader class="details-skeleton-3"></SkeletonLoader>
            <SkeletonLoader class="details-skeleton-2"></SkeletonLoader>
          </p>
        </div>
      </div>
      <div class="seller-details__info">
        <div class="seller-details__media">
          <div class="logo-loader"></div>
        </div>

        <ul class="seller-details-data">
          <li>
            <span class="seller-details-data__label"
              ><SkeletonLoader :size="uiSize.Small"></SkeletonLoader
            ></span>
            <p><SkeletonLoader :size="uiSize.Medium"></SkeletonLoader></p>
          </li>
          <li>
            <span class="seller-details-data__label"
              ><SkeletonLoader :size="uiSize.Small"></SkeletonLoader
            ></span>
            <p><SkeletonLoader :size="uiSize.Small"></SkeletonLoader></p>
          </li>
          <li>
            <span class="seller-details-data__label"
              ><SkeletonLoader :size="uiSize.Small"></SkeletonLoader
            ></span>
            <p><SkeletonLoader :size="uiSize.Small"></SkeletonLoader></p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./seller-details-skeleton.scss" scoped></style>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import ArrowIcon from '@/src/core/components/ui/ani-icons/arrow/arrow-icon.vue';
import SkeletonLoader from '@/src/core/components/ui/skeleton-loader/skeleton-loader.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';

@Component({
  components: {
    SkeletonLoader,
    ArrowIcon,
  },
})
export class SellerDetailsSkeleton extends Vue {
  public uiSize = UiSize;
}

export default toNative(SellerDetailsSkeleton);
</script>
