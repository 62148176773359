import { getEnv } from '@/src/core/services/environment';

export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const FIVE_MINUTES = 5 * ONE_MINUTE;
export const TEN_MINUTES = 10 * ONE_MINUTE;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const FOUR_HOURS = 4 * ONE_HOUR;

export const SESSION_TIME = FOUR_HOURS;
export const MAX_INACTIVE_MODAL_TIME = +getEnv('VUE_APP_INACTIVITY_LOGOUT_MODAL_TIMER');
export const MAX_INACTIVE_TIME = +getEnv('VUE_APP_INACTIVITY_TIMER') - MAX_INACTIVE_MODAL_TIME;
export const TOTAL_MAX_INACTIVE_TIME = MAX_INACTIVE_TIME + MAX_INACTIVE_MODAL_TIME;
