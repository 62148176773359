import { QuoteApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { SendQuoteEmailToAirbusRequest } from '@/src/core/types/api';
import { defineStore } from 'pinia';

interface FormData {
  purchaseOrderNumber: string;
  message?: string;
}

interface AirbusQuotesState {
  formData: SendQuoteEmailToAirbusRequest;
  isBusy: boolean;
  productId: string;
  serialNumber?: string;
}

export const useAirbusQuotesStore = defineStore('airbusQuotes', {
  state: (): AirbusQuotesState => ({
    formData: {
      purchaseOrderNumber: '',
    },
    productId: '',
    isBusy: false,
  }),
  actions: {
    setSerialNumber(payload: { serialNumber: string | undefined }) {
      this.serialNumber = payload.serialNumber;
    },
    setProductId(payload: { productId: string }) {
      this.productId = payload.productId;
    },
    setPriority(payload: { priority: string }) {
      this.formData.priority = payload.priority;
    },
    setShippingAddress(payload: { shippingAddressId: string }) {
      this.formData.shipToAddress = payload.shippingAddressId;
    },
    async sendEmail(payload: { globalId: string; requestObject: SendQuoteEmailToAirbusRequest }) {
      this.isBusy = true;

      const { IsSuccess } = await Req({
        url: QuoteApi.CreateAirbusQuote(payload.globalId),
        method: 'POST',
        data: payload.requestObject,
      });

      this.isBusy = false;

      return { IsSuccess };
    },
  },
});
