<template>
  <InViewWatcher>
    <div
      class="box-cta-module-wrapper"
      :class="{ hovered: hasLink && hover, 'has-link': hasLink }"
      @mouseover="mouseOver"
      @mouseout="mouseOut"
    >
      <slot :hovered="hover"></slot>
    </div>
  </InViewWatcher>
</template>

<style lang="scss" src="./box-cta-module.scss"></style>

<script lang="ts" setup>
import { ref } from 'vue';
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';

interface Props {
  hasLink?: boolean;
}

const emit = defineEmits(['hovered']);

withDefaults(defineProps<Props>(), {
  hasLink: false,
});
const hover = ref(false);

const mouseOver = () => {
  hover.value = true;
  emit('hovered', true);
};

const mouseOut = () => {
  hover.value = false;
  emit('hovered', false);
};
</script>
