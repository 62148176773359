<template>
  <span></span>
</template>

<script setup lang="ts">
import { useModalStore } from '@/src/core/stores/modal';
import { IAdobeTargetEvent } from '@/src/core/types/adobe';
import { computed, onBeforeUnmount, onMounted } from 'vue';

const modalStore = useModalStore();

const handleTargetAction = ($event: CustomEvent) => {
  const targetEvent: IAdobeTargetEvent = $event.detail;

  if (targetEvent.targetActionType === 'modal') {
    modalStore.showModal({
      modalComponent: 'ModalLoadContent',
      params: {
        url: targetEvent.umbracoUrl,
        label: targetEvent.label,
        targetOptions: targetEvent.options,
        track: true,
      },
    });
  }
};

const modalIsOpen = computed(() => modalStore.isVisible);

onMounted(() => {
  window.addEventListener('TargetAction', handleTargetAction as EventListener);
});

onBeforeUnmount(() => {
  window.removeEventListener('TargetAction', handleTargetAction as EventListener);
});
</script>
