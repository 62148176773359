/**
 * Convert decimal number to percentage
 * @param {number} num
 * @returns {number}
 */

export const asPercentage = (num: number) => num * 100;

/**
 * Extracts the numeric value from a string.
 *
 * @param {string} value - The string containing the numeric value to extract.
 * @return {number} The extracted number or 0 if no valid number is found.
 */
export const extractNumericValue = (value: string): number => {
  return parseInt(value, 10) || 0;
};

/**
 * Adds a comma separator to a price.
 *
 * @param {string} price - The string containing the price.
 * @return {string} The price formatted with comma separators
 */
export const formatPrice = (price: string): string => {
  const numericPrice = parseFloat(price);
  // Convert the price to a string and split it into parts
  const parts = numericPrice.toFixed(2).toString().split('.');

  // Add a comma separator for thousands in the integer part
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Join the parts back together and return the formatted price
  return parts.join('.');
};

/**
 * Rounds a decimal string to the nearest integer.
 *
 * @param {string} decimalString - The decimal string to round.
 * @returns {number} The rounded integer.
 */
export const roundDecimalString = (decimalString: string) => {
  const number = parseFloat(decimalString);
  return Math.round(number);
};
