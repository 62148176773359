<template>
  <div class="finance-solution-icon">
    <FinanceLogo ref="logo" />
  </div>
</template>

<script lang="ts" setup>
import FinanceLogo from '@/src/finance/components/ui/finance-logo/finance-logo.vue';
import { ref } from 'vue';

const logo = ref(FinanceLogo);
</script>
