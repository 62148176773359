<template>
  <div class="invoice-filter-modal">
    <StandardDialogModal :size="uiSize.Large" :has-cancel-button="false">
      <template #header>
        <h3>
          {{ textK('UI_DOCUMENTS_FILTERMODAL_HEADER') }}
        </h3>
      </template>
      <template #body>
        <DocumentsFilters :modal="true" />
      </template>
      <template #actions>
        <VButton :type="uiVariant.Primary" @click="close">
          {{ textK('UI_DOCUMENTS_FILTERMODAL_ACTION') }}
        </VButton>
      </template>
    </StandardDialogModal>
  </div>
</template>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import DocumentsFilters from '@/src/finance/components/documents/documents-filter/documents-filters.vue';

@Component({
  components: {
    DocumentsFilters,
    VButton,
    StandardDialogModal,
  },
})
export class ModalDocumentsFilter extends Vue {
  public textK = useText();
  public modalStore = useModalStore();
  public uiVariant = UiVariant;
  public uiSize = UiSize;

  public close() {
    this.modalStore.closeModal();
  }
}

export default toNative(ModalDocumentsFilter);
</script>
