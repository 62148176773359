import { Address, B2BUnit } from '@/src/core/apim';

/**
 * Maps B2B unit details to fit legacy umbraco structure..
 *
 * @param {B2BUnit} b2bUnit - a SAP CC B2B unit object.
 * @returns {object} - A mapped b2b unit.
 */
export const getB2bUnitMapping = (b2bUnit: B2BUnit) => {
  const billingAddress = getBillingAddress(b2bUnit.addresses || []);
  const mappedBillingAddress = billingAddress ? getAddressMapping(billingAddress) : null;
  const defaultBillingAddress = b2bUnit.defaultBillingAddress
    ? getAddressMapping(b2bUnit.defaultBillingAddress)
    : null;
  const defaultPayerAddress = b2bUnit.defaultPayerAddress
    ? getAddressMapping(b2bUnit.defaultPayerAddress)
    : null;

  return {
    ...b2bUnit,
    companies: [],
    addresses: getAddressesMapping(b2bUnit.addresses || []),
    billingAddress: mappedBillingAddress,
    blueAddresses: [],
    redAddresses: [],
    b2BUnitSalesOrganization: {
      code: b2bUnit.b2BUnitSalesOrganization?.code || '',
      name: b2bUnit.b2BUnitSalesOrganization?.name || '',
    },
    defaultBillingAddress,
    defaultPayerAddress,
  };
};

/**
 * Maps a single address object to the legacy format.
 *
 * @param {Address} address - The address object from SAP CC.
 * @returns {object} - A mapped address object with formatted country details and additional properties.
 */
const getAddressMapping = (address: Address) => ({
  ...address,
  country: { name: address.country?.name || '', isoCode: address.country?.isocode || '' },
  visibleInAddressBook: Boolean(address.visibleInAddressBook),
  vatId: '',
});

/**
 * Maps an array of address objects to the legacy format.
 *
 * @param {Address[]} addresses - An array of address objects from SAP CC.
 * @returns {object[]} - An array of mapped address objects.
 */
const getAddressesMapping = (addresses: Address[]) => addresses.map(getAddressMapping);

/**
 * Retrieves the billing address from an array of address objects.
 *
 * @param {Address[]} addresses - An array of address objects from SAP CC.
 * @returns {Address | null} - The first address marked as a billing address, or null if none is found.
 */
const getBillingAddress = (addresses: Address[]) =>
  addresses.find((address) => address.billingAddress) || null;
