<template>
  <form @submit.prevent="saveName">
    <StandardDialogModal
      :size="uiSize.Small"
      :has-cancel-button="true"
      :can-submit="cartName.length > 0"
      :on-submit="saveName"
      :ui-test-name-header="'modal-save-cart-header'"
    >
      <template #header>
        <h3>Save cart as list</h3>
      </template>
      <template #body>
        <div class="inner-body">
          <VTextField
            :type="uiVariant.Secondary"
            label="Cart name"
            name="Cart name"
            v-model="cartName"
            ref="textfield"
          ></VTextField>
        </div>
      </template>
      <template #actions>
        <VButton
          :type="uiVariant.Default"
          v-ui-test="'save-cart-button'"
          input-type="submit"
          :disabled="cartName.length <= 0"
          :loading="isBusy"
          @click.prevent="saveName"
          >Save</VButton
        >
      </template>
    </StandardDialogModal>
  </form>
</template>

<style lang="scss" src="./modal-save-cart.scss" scoped></style>

<script lang="ts">
import { Vue, Component, Ref, toNative } from 'vue-facing-decorator';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import router from '@/src/core/router';
import { useModalStore } from '@/src/core/stores/modal';
import { IList } from '@/src/core/types/interfaces';
import { useCartStore } from '@/src/market/stores/cart';
import { useListsStore } from '@/src/market/stores/list';

@Component({
  components: {
    VTextField,
    VButton,
    StandardDialogModal,
  },
})
export class ModalSaveAsList extends Vue {
  @Ref() textfield!: InstanceType<typeof VTextField>;

  public listsStore = useListsStore();
  public modalStore = useModalStore();
  public cartStore = useCartStore();
  public uiVariant = UiVariant;
  public uiSize = UiSize;
  public cartName: string = this.name ? this.name : '';

  public get name(): string {
    return this.modalParams.Name;
  }

  public get isBusy(): boolean {
    return this.listsStore.isBusy;
  }

  private get modalParams(): IList {
    return this.modalStore.params as IList;
  }

  public async saveName() {
    this.modalStore.lockCurrentModal(true);
    const cartCode = this.modalParams.Code;
    const result = await this.listsStore.saveAsList({ listName: this.cartName, cartCode });

    if (result.IsSuccess) {
      this.modalStore.lockCurrentModal(false);
      const myList = {} as IList;
      myList.Code = result.Data?.Code;
      this.cartStore.clearCart({ cartCode });
      router.push({ name: 'list', params: { listid: myList.Code } });
      this.modalStore.closeModal();
    } else {
      this.modalStore.lockCurrentModal(false);
    }
  }

  public mounted() {
    (this.$refs.textfield as any).setSelect();
  }
}

export default toNative(ModalSaveAsList);
</script>
