<template>
  <div class="help-menu">
    <NavigationDropdownMenu
      v-for="(linkGroup, linkGroupIndex) in links"
      :title="linkGroup.Header"
      :key="linkGroupIndex"
    >
      <NavigationDropdownMenuItem
        v-for="(link, linkIndex) in linkGroup.Items"
        :key="linkIndex"
        :route="{ path: link.Link }"
        :blank="link.LinkOpenNew || link.LinkExternal"
      >
        {{ link.Headline }}
      </NavigationDropdownMenuItem>
    </NavigationDropdownMenu>
  </div>
</template>

<style lang="scss" src="./help-menu.scss" scoped />

<script lang="ts" setup>
import NavigationDropdownMenuItem from '@/src/core/components/navigation/navigation-dropdown/navigation-dropdown-menu/navigation-dropdown-menu-item/navigation-dropdown-menu-item.vue';
import NavigationDropdownMenu from '@/src/core/components/navigation/navigation-dropdown/navigation-dropdown-menu/navigation-dropdown-menu.vue';
import { useHelpMenuStore } from '@/src/core/stores/help-menu';
import { computed, onMounted } from 'vue';

const helpMenuStore = useHelpMenuStore();

const links = computed(() => {
  return helpMenuStore.helpLinks;
});

const fetchLinks = async () => {
  if (!(links.value && links.value.length > 0)) {
    helpMenuStore.fetchHelpLinks();
  }
};

onMounted(async () => {
  await fetchLinks();
});
</script>
