/**
 * Retrieves the first string from a parameter that can be a string, an array of strings, or undefined.
 * This function is specifically designed for Vue Router 4, which can pass route parameters as either a string or an array of strings.
 *
 * @param param - The parameter to retrieve the string from. It can be a string, an array of strings, or undefined.
 * @returns The first string if the parameter is an array, the string itself if it's a string, or an empty string if it's undefined.
 */
export const getRouteParamAsString = (param?: string | string[] | Symbol): string => {
  if (Array.isArray(param)) {
    return param[0] ?? '';
  }

  if (typeof param === 'string') {
    return param;
  }

  return '';
};
