import { CancelReqCategory, Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { InvoiceItem } from '@/src/core/types/api';
import { DisplayDateFormat } from '@/src/core/utils/dates';
import { useUserStore } from '@/src/profile/stores/user';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { FinanceApi } from '../api/financeApi';
import { AirbusAccountStatements, AirbusAccountStatementsLineItems } from '@/src/core/apim';

export interface IAccountStatementQuery {
  fromDate?: string;
  toDate?: string;
  keyDate?: string;
  openItems?: boolean;
  overdueItems?: boolean;
}

const initialFilter: IAccountStatementQuery = {
  fromDate: dayjs().startOf('month').format(DisplayDateFormat),
  toDate: dayjs().format(DisplayDateFormat),
  keyDate: dayjs().format(DisplayDateFormat),
};

export const useAccountStatementStore = defineStore('account-statement', () => {
  const userStore = useUserStore();
  const statements = ref<InvoiceItem[]>([]);
  const isBusy = ref<boolean>(true);
  const filters = ref<IAccountStatementQuery>(Object.assign({}, initialFilter));
  const airbusStatements = ref<AirbusAccountStatementsLineItems[]>([]);
  const totalAirbusStatements = ref<number>(0);
  const requestId = ref<string>();

  const statementsUpdated = (payload: { statements: InvoiceItem[] }) => {
    statements.value = [];
    statements.value.push(...payload.statements);
  };

  const airbusStatementsUpdated = (payload: { statements: AirbusAccountStatements }) => {
    const lineItems = payload.statements.lineItems || [];
    const totalSize = payload.statements.totalResultSize;
    airbusStatements.value = [...airbusStatements.value, ...lineItems];
    totalAirbusStatements.value = totalSize ? parseInt(totalSize) : 0;
    requestId.value = payload.statements.requestId;
  };

  const isBusyUpdated = (payload: { IsBusy: boolean }) => {
    isBusy.value = payload.IsBusy;
  };

  const resetAirbusStatements = () => {
    airbusStatements.value = [];
    requestId.value = undefined;
    totalAirbusStatements.value = 0;
  };

  const reset = () => {
    filters.value = Object.assign({}, initialFilter);
    statements.value = [];
    isBusy.value = false;
  };

  const setFilters = (payload: IAccountStatementQuery) => {
    filters.value = Object.assign({}, filters.value, payload);
  };

  const resetFilters = () => {
    CancelReqCategory('accountStatement');
    CancelReqCategory('accountStatementAirbus');
    filters.value = Object.assign({}, initialFilter);
  };

  const fetchStatements = async () => {
    const unitId = userStore.currentUser?.DefaultUnitUid;

    if (!unitId) {
      return;
    }
    const postData = Object.assign({}, { unitId });

    if (filters.value?.openItems) {
      Object.assign(postData, { openItems: true });
    } else if (filters.value?.overdueItems) {
      Object.assign(postData, { overdueItems: true });
    } else {
      Object.assign(postData, { fromDate: filters.value?.fromDate, toDate: filters.value?.toDate });
    }

    isBusyUpdated({ IsBusy: true });

    const { IsSuccess, Data } = await Req(
      {
        url: FinanceApi.ShowAccountStatement,
        data: postData,
        method: 'POST',
      },
      new ReqQueue(ReqQueueTypes.Default, 'accountStatement'),
      FinanceApi.handleGetAccountStatement,
    );

    if (IsSuccess && Data?.InvoiceItems) {
      statementsUpdated({ statements: Data.InvoiceItems });
    }

    isBusyUpdated({ IsBusy: false });

    return { IsSuccess };
  };

  const fetchStatementsAirbus = async (requestId?: string) => {
    const params = {
      pageSize: 20,
      paginationDirection: requestId ? 'N' : 'F',
      keyDate: filters.value.keyDate,
      ...(requestId && { requestId }),
    };

    isBusyUpdated({ IsBusy: true });

    const { IsSuccess, Data } = await Req(
      {
        url: FinanceApi.GetAirbusAccountStatement,
        data: params,
        method: 'POST',
      },
      new ReqQueue(ReqQueueTypes.Default, 'accountStatementAirbus'),
      FinanceApi.handleGetAirbusAccountStatement.bind(FinanceApi, params),
    );

    if (IsSuccess && Data) {
      airbusStatementsUpdated({ statements: Data });
    }

    isBusyUpdated({ IsBusy: false });
  };

  return {
    statements,
    airbusStatements,
    totalAirbusStatements,
    requestId,
    isBusy,
    filters,
    statementsUpdated,
    isBusyUpdated,
    reset,
    resetAirbusStatements,
    setFilters,
    resetFilters,
    fetchStatements,
    fetchStatementsAirbus,
  };
});
