import mimeType from 'mime-types';

export const getMimeTypeExtension = (mime?: string | null) => {
  const defaultExtension = 'bin';

  if (!mime) {
    return defaultExtension;
  }

  const indexOfCharset = mime.indexOf(';charset=');

  if (indexOfCharset > -1) {
    mime = mime.substring(0, indexOfCharset);
  }

  return mimeType.extension(mime) || defaultExtension;
};
