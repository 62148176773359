<template>
  <form @submit.prevent="changeEmail">
    <StandardDialogModal :has-cancel-button="true" :size="uiSize.Small">
      <template #header>
        <div class="typeset">
          <h3>Change email</h3>
        </div>
      </template>
      <template #body>
        <div class="inner-body">
          <div class="input-container">
            <VTextField
              v-ui-test="'changeemail-email'"
              :type="uiVariant.Secondary"
              label="Email"
              name="Email"
              v-model="email"
              input-type="email"
              :required="true"
              @valid="emailIsValid = $event"
            ></VTextField>
          </div>
          <div class="input-container">
            <VTextField
              v-ui-test="'changeemail-password'"
              :type="uiVariant.Secondary"
              label="Password"
              name="Password"
              input-type="password"
              v-model="password"
              disable-type-validation
            ></VTextField>
          </div>
        </div>
      </template>
      <template #actions>
        <VButton
          :type="uiVariant.Default"
          v-ui-test="'changeemail-confirm-button'"
          :loading="isBusy"
          :disabled="!emailIsValid || password.length <= 0"
          >Save</VButton
        >
      </template>
    </StandardDialogModal>
  </form>
</template>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import { useModalStore } from '@/src/core/stores/modal';
import { useUserStore } from '@/src/profile/stores/user';
import { computed, ref, watch } from 'vue';

interface IChangeEmailModalParams {
  Email: string;
}

const modalStore = useModalStore();
const userStore = useUserStore();

const uiVariant = UiVariant;
const uiSize = UiSize;

const modalParams = computed(() => modalStore.params as IChangeEmailModalParams);

const email = ref(modalParams.value.Email ? modalParams.value.Email : '');
const password = ref('');
const emailIsValid = ref(true);

const isBusy = computed(() => userStore.isBusy);
const currentUserData = computed(() => userStore.currentUser);

const changeEmail = () => {
  if (userStore.currentUser.GlobalId) {
    modalStore.lockCurrentModal(true);
    userStore.updateUserEmail({
      userId: currentUserData.value.GlobalId,
      password: password.value,
      email: email.value,
    });
  }
};

watch(currentUserData, () => {
  modalStore.closeModal();
});

watch(isBusy, (newIsBusy) => {
  if (!newIsBusy) {
    modalStore.lockCurrentModal(false);
  }
});
</script>
