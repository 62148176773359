const htmlUnescapes = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&#39;': "'",
};

const unescapeHtmlChar = basePropertyOf(htmlUnescapes);
const reEscapedHtml = /&(?:amp|lt|gt|#39);/g;
const reHasEscapedHtml = RegExp(reEscapedHtml.source);

function basePropertyOf(object: object) {
  return (key: string) => {
    return object == null ? undefined : object[key];
  };
}

export function srUnescape(input: string): string {
  const inputString: string = input;
  return inputString && reHasEscapedHtml.test(inputString)
    ? inputString.replace(reEscapedHtml, unescapeHtmlChar)
    : inputString;
}
