<template>
  <div
    class="product-banner__wrapper"
    :class="{
      default: type === uiVariant.Default,
      secondary: type === uiVariant.Secondary,
      primary: type === uiVariant.Primary,
      warning: type === uiVariant.Warning,
    }"
  >
    <div class="product-banner__text">
      <slot name="banner-text"></slot>
    </div>
    <Tooltip class="tooltip" v-if="showTooltip && !hasDropdown">
      <span class="tooltip__icon">
        <svgicon name="ui-info" />
      </span>
      <template #tooltip-content>
        <slot name="tooltip-text"></slot>
      </template>
    </Tooltip>
    <div v-else-if="hasDropdown">
      <slot name="dropdown-icon"></slot>
      <slot name="dropdown"></slot>
    </div>
  </div>
</template>
<style src="./product-info-banner.scss" lang="scss" scoped />

<script setup lang="ts">
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';

interface Props {
  showTooltip?: boolean;
  hasDropdown?: boolean;
  type?: UiVariant;
}

withDefaults(defineProps<Props>(), {
  showTooltip: false,
  hasDropdown: false,
  type: UiVariant.Default,
});

const uiVariant = UiVariant;
</script>
