<template>
  <div class="l_standard-app-wrapper">
    <div class="l_standard-app-wrapper error-page">
      <div class="error-page-icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114 115">
          <path
            fill="#fff"
            fill-rule="evenodd"
            d="M89.9 26.9L45.6 1.2 1.4 27.9 1 79.3l26.4 15.1v17.7l18.3-7.6 17.5 8.5L107 87.4l-17.3-9.3 23.2-6.4V20.9z"
            clip-rule="evenodd"
          ></path>
          <path
            d="M113.9 19.6l-23.8 6.3L45.5 0 .4 27.3 0 79.8 26.4 95v18.5l19.4-8 17.5 8.6L109 87.4l-16.6-9 21.5-6V19.6zM99.1 44l-42 24.1L47 52.9l42.5-24.6L99.1 44zm-11 32.1L66.9 64.8l21-12 .2 23.3zM26.4 92.7L2 78.7l.4-49.1 41.2 23-17 7.6-.2 32.5zm2 17.9V94.4l.2-32.8 15.8-7.1.4 49.4-16.4 6.7zm17.1-59.1L3.4 27.9 45.6 2.3 88 26.9 45.5 51.5zm59.4 36l-41.8 24.4-16.4-8-.4-48.3 10 15.2 8.4-4.8 24 12.9 16.2 8.6zm7-16.6L90.1 77l-.2-25.4 12-6.9-10.4-17.1 20.4-5.4v48.7z"
          ></path>
        </svg>
      </div>
      <h1 class="error-page-headline">{{ textK('UI_ERRORPAGES_404_HEADER') }}</h1>
      <p class="error-page-paragraph">{{ textK('UI_ERRORPAGES_404_BODY') }}</p>

      <div class="not-found-module-linkcollection">
        <p class="error-page-paragraph" v-for="(urlItem, i) in urlList" :key="i">
          <a :href="urlItem.url">{{ urlItem.name }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./../error-pages.scss" scoped></style>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import { NotFoundApi } from '@/src/core/api';
import useText from '@/src/core/hooks/useText';
import { Req } from '@/src/core/services/requester';
import { useUIStore } from '@/src/core/stores/ui';

@Component({})
export class NotFoundLayout extends Vue {
  public textK = useText();
  public uiStore = useUIStore();

  public urlList = [];

  private async mounted() {
    this.uiStore.setSplashScreen({ splashScreen: true });
    const { Data, IsSuccess } = await Req({
      url: NotFoundApi.UrlList,
    });

    if (IsSuccess) {
      this.urlList = Data;
    }
  }
}

export default toNative(NotFoundLayout);
</script>
