import { getUserSearchPageWithRolesMapping } from '@/src/account/services/legacy-mapping-admin';
import { HttpResponse, UserSignUp, apim } from '@/src/core/apim';
import { getEnv } from '@/src/core/services/environment';
import { toLegacyResponse } from '@/src/core/services/legacy-response-utils';
import { getNotificationMapping } from '@/src/core/services/notification-mapping';
import { NotificationTypes } from '@/src/core/types/api';
import { TempUser } from '@/src/core/types/interfaces';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import { legacyErrorHandler } from '@/src/market/services/error-notification-handling';
import { SATAIR_BASESITE_ID } from '@/src/market/settings/baesiteId';
import { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class AdminApi {
  public static GetUsers = `${baseUrl}/api/admin/users`;
  public static async handleGetUsers(settings: AxiosRequestConfig) {
    try {
      const params = settings.params;
      const q = params.q ? encodeURI(params.q) : '';
      const size = params?.size ?? 20;
      const page = params?.p ?? 0;
      const enableFinanceSection = params.enableFinanceSection ?? false;
      const isFinanceBetaPage = params.isFinanceBetaPage ?? false;
      const globalId = Cookies.get('globalId')!;
      const betaCustomer = Cookies.get('betaCustomer') === 'true';
      const response = await apim.searchUsers(SATAIR_BASESITE_ID, globalId, {
        q,
        currentPage: page,
        pageSize: size,
        sort: 'name-asc',
        fields: 'FULL',
      });

      const data = getUserSearchPageWithRolesMapping(
        response.data,
        enableFinanceSection,
        isFinanceBetaPage,
        betaCustomer,
      );

      return toLegacyResponse(response, {
        Data: objectKeysToPascalCaseRecursive(data),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }
  public static AddUser = `${baseUrl}/api/admin/adduser`;
  public static async handleAddUser(user: TempUser) {
    try {
      const data: UserSignUp = {
        uid: user.Email,
        firstName: user.FirstName || '-',
        lastName: user.LastName,
        password: user.Password,
      };
      const response = await apim.registerUser(SATAIR_BASESITE_ID, data);

      const responseUser = response.data;

      if (responseUser.globalId) {
        if (user.IsAdmin) {
          await apim.enableAdmin(SATAIR_BASESITE_ID, responseUser.globalId, {
            enableAdmin: 'true',
          });
        }

        if (user.IsReadOnly) {
          await apim.setReadOnly(SATAIR_BASESITE_ID, responseUser.globalId, {
            readOnly: 'true',
          });
        }

        if (user.IsFinance) {
          await apim.setFinance(SATAIR_BASESITE_ID, responseUser.globalId, {
            financeCustomer: 'true',
          });
        }
      }
      const notification = getNotificationMapping({
        title: 'Success',
        description: `User created for ${user.Email}`,
        type: NotificationTypes.Success,
      });

      const result = {
        data: { globalId: responseUser.globalId },
        notification: [notification],
      };

      return toLegacyResponse(response, objectKeysToPascalCaseRecursive(result));
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static AddUsers = `${baseUrl}/api/admin/addusers`;
  public static async handleAddUsers(settings: AxiosRequestConfig) {
    try {
      const users = settings.data as TempUser[];
      const notCreatedUsers: string[] = [];
      let tempUsers: TempUser[] = [];

      for (const user of users) {
        const data: UserSignUp = {
          uid: user.Email,
          firstName: user.FirstName || '-',
          lastName: user.LastName,
          password: user.Password,
        };
        try {
          const response = await apim.registerUser(SATAIR_BASESITE_ID, data);
          const responseUser = response.data;

          if (responseUser.globalId) {
            if (user.IsAdmin) {
              await apim.enableAdmin(SATAIR_BASESITE_ID, responseUser.globalId, {
                enableAdmin: 'true',
              });
            }

            if (user.IsReadOnly) {
              await apim.setReadOnly(SATAIR_BASESITE_ID, responseUser.globalId, {
                readOnly: 'true',
              });
            }

            if (user.IsFinance) {
              await apim.setFinance(SATAIR_BASESITE_ID, responseUser.globalId, {
                financeCustomer: 'true',
              });
            }
          }
          user.Created = true;
        } catch (error) {
          notCreatedUsers.push(user.Email);
          user.Created = false;
        }
        tempUsers.push(user);
      }

      const createdUsersCount = users.length - notCreatedUsers.length;
      const partialSuccess = notCreatedUsers.length > 0;
      const notification = getNotificationMapping({
        title: partialSuccess ? `${createdUsersCount} of ${users.length} users created` : 'Success',
        description: partialSuccess
          ? `The following users already exists: ${notCreatedUsers.join(', ')}`
          : `All users successfully created`,
        type: NotificationTypes.Success,
      });

      const httpResponse = {
        data: null,
        status: 200,
      } as HttpResponse<unknown>;

      const result = {
        notification: [notification],
        data: tempUsers,
      };

      return toLegacyResponse(httpResponse, objectKeysToPascalCaseRecursive(result));
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }
}
