<template>
  <VSelect
    :type="uiVariant.Secondary"
    :disabled="disabled"
    :required="required"
    :options-skeleton="timeZones.length === 0"
    :label="textK('UI_COMMON_TIMEZONE')"
    :placeholder="modelValue"
    :modelValue="timeZones.indexOf(modelValue)"
    @update:modelValue="!!timeZones[$event] ? $emit('update:modelValue', timeZones[$event]) : null"
    :is-input-field="false"
    :options="timeZones"
    :ui-test-name-dropdown="'primary-workarea-timezone-option'"
    @valid="$emit('valid', $event)"
    @open="fetch()"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useTextsStore } from '@/src/core/stores/texts';

interface Props {
  modelValue?: string;
  disabled?: boolean;
  required: boolean;
}

withDefaults(defineProps<Props>(), {
  modelValue: '',
  disabled: false,
  required: false,
});

const textK = useText();
const textsStore = useTextsStore();
const uiVariant = UiVariant;

const timeZones = computed(() => {
  return textsStore.timeZones;
});

const fetch = () => {
  if (timeZones.value.length <= 0) {
    textsStore.fetchTimeZones();
  }
};
</script>
