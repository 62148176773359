<template>
  <transitionExpand>
    <section class="product-details-section" v-if="showPlantAvailabilityTable">
      <div>
        <VSimpleTable
          class="product-details-table"
          :headers="[
            textK('UI_PRODUCT_LOCATION'),
            textK('UI_PRODUCT_STOCK_STATUS'),
            textK('UI_PRODUCT_AVAILABLE_QUANTITY'),
          ]"
        >
          <tbody :key="1" v-if="(hasPlantAvailability || hasLocationInfos) && !hasPlants && !isFhs">
            <template v-if="isSatair">
              <VSimpleTableRow v-ui-test="'plantStockLocationRow'">
                <VSimpleTableCell :style="{ width: '50%' }" :label="textK('UI_PRODUCT_LOCATION')">
                  <strong v-if="productAdditionalData && warehouse">{{ warehouse.Name }}</strong>
                  <strong v-else>{{ textK('UI_NODATA_HASNODATA') }}</strong>
                </VSimpleTableCell>
                <VSimpleTableCell
                  :style="{ width: '25%' }"
                  :label="textK('UI_PRODUCT_STOCK_STATUS')"
                >
                  <ProductAvailibiltyIndication
                    class="instock-indicator"
                    :loading="!plantAvailability || plantAvailability.IsBusy"
                    :in-stock="product?.ShowStock"
                    :failed="!product?.ShowStock"
                  >
                  </ProductAvailibiltyIndication>
                  <span
                    v-if="hasUnknownDeliveryDate && !product?.ShowStock"
                    class="item-quantity-info"
                  >
                    {{ textK('UI_UNKNOWN_DELIVERY_DATE_AVAILABILITY') }}
                    <Tooltip>
                      <span>
                        <svgicon name="ui-info" />
                      </span>
                      <template #tooltip-content>
                        {{ textK('UI_UNKNOWN_DELIVERY_DATE_TOOL_TIP') }}
                      </template>
                    </Tooltip>
                  </span>
                  <span
                    class="item-quantity-info"
                    v-else-if="!product?.ShowStock || quantity === 0"
                  >
                    {{ textK('UI_PRODUCT_STOCK_LABEL_UNKNOWN') }}
                  </span>
                  <span class="item-quantity-info" v-else>
                    {{ textK('UI_CART_INSTOCK') }}
                  </span>
                </VSimpleTableCell>
                <VSimpleTableCell :style="{ width: '25%' }" :label="textK('UI_PRODUCT_UNIT')">
                  {{ unit }}
                </VSimpleTableCell>
              </VSimpleTableRow>
            </template>
            <template v-else-if="isAirbus">
              <!-- This is possibly not needed -->
              <VSimpleTableRow v-ui-test="'plantStockLocationRow'">
                <VSimpleTableCell :style="{ width: '50%' }" :label="textK('UI_PRODUCT_LOCATION')">
                  <strong v-if="procurementData?.warehouseLocationDescription">{{
                    procurementData.warehouseLocationDescription
                  }}</strong>
                  <strong v-else>{{ textK('UI_PRODUCT_STOCK_LABEL_UNKNOWN') }}</strong>
                </VSimpleTableCell>
                <VSimpleTableCell
                  :style="{ width: '25%' }"
                  :label="textK('UI_PRODUCT_STOCK_STATUS')"
                >
                  <ProductAvailibiltyIndication
                    class="instock-indicator"
                    :loading="!procurementData"
                    :in-stock="procurementData?.availableStock"
                    :with-lead-time="hasAvailabilityDate || procurementData?.unknownDeliveryDate"
                  >
                  </ProductAvailibiltyIndication>
                  <span
                    class="item-quantity-info"
                    v-if="!procurementData?.availableStock || procurementData?.availableStock === 0"
                  >
                    {{ textK('UI_PRODUCT_STOCK_LABEL_UNKNOWN') }}
                  </span>
                  <span class="item-quantity-info" v-else>
                    {{ textK('UI_CART_INSTOCK') }}
                  </span>
                </VSimpleTableCell>
                <VSimpleTableCell :style="{ width: '25%' }" :label="textK('UI_PRODUCT_UNIT')">
                  {{
                    procurementData && procurementData?.availableStock > 0
                      ? `${procurementData.availableStock} ${unit}`
                      : '-'
                  }}
                </VSimpleTableCell>
              </VSimpleTableRow>
            </template>
          </tbody>

          <tbody :key="2" v-else-if="hasPlantAvailability || (hasPlants && !isFhs)">
            <template v-if="isSatair">
              <VSimpleTableRow
                v-ui-test="'plantStockLocationRow'"
                v-for="(plant, index) in plantAvailability?.Plants"
                :key="index"
              >
                <VSimpleTableCell :style="{ width: '50%' }" :label="textK('UI_PRODUCT_LOCATION')"
                  >{{ plant.Warehouse }}
                </VSimpleTableCell>
                <VSimpleTableCell
                  :style="{ width: '25%' }"
                  :label="textK('UI_PRODUCT_STOCK_STATUS')"
                >
                  <ProductAvailibiltyIndication
                    class="instock-indicator"
                    :loading="!plantAvailability || plantAvailability.IsBusy"
                    :in-stock="plant.InStock"
                    :with-lead-time="hasUnknownDeliveryDate && !plant.InStock"
                  >
                  </ProductAvailibiltyIndication>
                  <span v-if="hasUnknownDeliveryDate && !plant.InStock" class="item-quantity-info">
                    {{ textK('UI_UNKNOWN_DELIVERY_DATE_AVAILABILITY') }}
                    <Tooltip>
                      <span>
                        <svgicon name="ui-info" />
                      </span>
                      <template #tooltip-content>
                        {{ textK('UI_UNKNOWN_DELIVERY_DATE_TOOL_TIP') }}
                      </template>
                    </Tooltip>
                  </span>
                  <span
                    class="item-quantity-info"
                    v-else-if="!plant.InStock || plant.Quantity === 0"
                  >
                    {{ textK('UI_PRODUCT_STOCK_LABEL_UNKNOWN') }}
                  </span>
                  <span class="item-quantity-info" v-else>
                    {{ textK('UI_CART_INSTOCK') }}
                  </span>
                </VSimpleTableCell>
                <VSimpleTableCell :style="{ width: '25%' }" :label="textK('UI_PRODUCT_UNIT')">{{
                  plant.Quantity !== undefined && plant.Quantity > 0
                    ? `${plant.Quantity} ${plant.Unit && plant.Unit}`
                    : '-'
                }}</VSimpleTableCell>
              </VSimpleTableRow>
            </template>
            <template v-else-if="isAirbus">
              <VSimpleTableRow
                v-ui-test="'plantStockLocationRow'"
                v-for="(plant, index) in locationInfos"
                :key="index"
              >
                <VSimpleTableCell :style="{ width: '50%' }" :label="textK('UI_PRODUCT_LOCATION')">
                  {{ plant.warehouseLocationDescription }}</VSimpleTableCell
                >
                <VSimpleTableCell
                  :style="{ width: '25%' }"
                  :label="textK('UI_PRODUCT_STOCK_STATUS')"
                >
                  <ProductAvailibiltyIndication
                    class="instock-indicator"
                    :loading="!hasLocationInfos"
                    :in-stock="plant.availableStock && plant.availableStock > 0"
                    :with-lead-time="hasAvailabilityDate || procurementData?.unknownDeliveryDate"
                  >
                  </ProductAvailibiltyIndication>
                  <span
                    v-if="
                      hasAvailabilityDate && (!plant.availableStock || plant.availableStock === 0)
                    "
                  >
                    {{ availabilityDate }}
                  </span>
                  <span
                    v-else-if="
                      hasUnknownDeliveryDate &&
                      (!plant.availableStock || plant.availableStock === 0)
                    "
                    class="item-quantity-info"
                  >
                    {{ textK('UI_UNKNOWN_DELIVERY_DATE_AVAILABILITY') }}
                    <Tooltip>
                      <span>
                        <svgicon name="ui-info" />
                      </span>
                      <template #tooltip-content>
                        {{ textK('UI_UNKNOWN_DELIVERY_DATE_TOOL_TIP') }}
                      </template>
                    </Tooltip>
                  </span>
                  <span
                    class="item-quantity-info"
                    v-else-if="!plant.availableStock || plant.availableStock === 0"
                  >
                    {{ textK('UI_PRODUCT_STOCK_LABEL_UNKNOWN') }}
                  </span>
                  <span class="item-quantity-info" v-else>
                    {{ textK('UI_CART_INSTOCK') }}
                  </span>
                </VSimpleTableCell>
                <VSimpleTableCell :style="{ width: '25%' }" :label="textK('UI_PRODUCT_UNIT')">{{
                  plant.availableStock !== undefined && plant.availableStock > 0
                    ? `${plant.availableStock} ${unit}`
                    : '-'
                }}</VSimpleTableCell>
              </VSimpleTableRow>
            </template>
            <ProductDetailsWarehouseButton
              class="item-warehouse-button"
              :id="id"
              :owning-system="owningSystem"
            ></ProductDetailsWarehouseButton>
          </tbody>

          <tbody :key="3" v-else-if="hasFhsLocations && isFhs">
            <VSimpleTableRow
              v-ui-test="'fhsStockLocationRow'"
              v-for="(location, index) in fhsProcurementLocations"
              :key="index"
            >
              <VSimpleTableCell :style="{ width: '50%' }" :label="textK('UI_PRODUCT_LOCATION')"
                >{{ location }}
              </VSimpleTableCell>
              <VSimpleTableCell :style="{ width: '25%' }" :label="textK('UI_PRODUCT_STOCK_STATUS')">
                <ProductAvailibiltyIndication
                  class="instock-indicator"
                  :loading="false"
                  :in-stock="true"
                  :with-lead-time="false"
                >
                </ProductAvailibiltyIndication>
                <span class="item-quantity-info">
                  {{ textK('UI_CART_INSTOCK') }}
                </span>
              </VSimpleTableCell>
              <VSimpleTableCell :style="{ width: '25%' }" :label="textK('UI_PRODUCT_UNIT')">
                <!-- FHS Product always have an available quantity of 1 and with In Stock stock status -->
                1
              </VSimpleTableCell>
            </VSimpleTableRow>
          </tbody>

          <tbody :key="4" v-else>
            <VSimpleTableRow
              v-ui-test="'plantStockLocationRow'"
              v-for="(plant, index) in 3"
              :key="index"
            >
              <VSimpleTableCell :style="{ width: '50%' }" :label="textK('UI_PRODUCT_LOCATION')">
                <SkeletonLoader></SkeletonLoader>
              </VSimpleTableCell>
              <VSimpleTableCell :style="{ width: '25%' }" :label="textK('UI_PRODUCT_STOCK_STATUS')">
                <ProductAvailibiltyIndication
                  class="instock-indicator"
                  :loading="true"
                  :in-stock="false"
                >
                </ProductAvailibiltyIndication>
                <span class="item-quantity-info"></span>
              </VSimpleTableCell>
              <VSimpleTableCell :style="{ width: '25%' }" :label="textK('UI_PRODUCT_UNIT')">
                <SkeletonLoader></SkeletonLoader>
              </VSimpleTableCell>
            </VSimpleTableRow>
          </tbody>
        </VSimpleTable>
      </div>
    </section>
  </transitionExpand>
</template>

<style scoped src="./product-details-plant-availability.scss" lang="scss"></style>

<script lang="ts" setup>
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import VSimpleTable from '@/src/core/components/ui/simple-table/simple-table.vue';
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import SkeletonLoader from '@/src/core/components/ui/skeleton-loader/skeleton-loader.vue';
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import useText from '@/src/core/hooks/useText';
import { OwningSystem } from '@/src/core/types/api';
import { DateFormat } from '@/src/core/utils/dates';
import ProductAvailibiltyIndication from '@/src/market/components/product/product-availibility-indication/product-availibility-indication.vue';
import ProductDetailsWarehouseButton from '@/src/market/components/product/product-details/product-details-warehouse-button/product-details-warehouse-button.vue';
import { formatPriceUnit } from '@/src/market/services/airbus-parts';
import { getProcurementByRouteParams, isLeaseTool } from '@/src/market/services/procurements';
import {
  isAirbus as checkIfIsAirbus,
  isSatair as checkIfIsSatair,
  productAdditionalData as getProductAdditionalData,
} from '@/src/market/services/product-parts';
import {
  hasNoPrice as checkIfHasNoPrice,
  isBlueOwningSystem as checkIfIsBlueOwningSystem,
  isSellable as checkIfIsSellable,
} from '@/src/market/services/product-rules';
import { unknownDeliveryDate } from '@/src/market/services/product-stock-availability';
import { useProductStore } from '@/src/market/stores/product';
import { useProductFHSDetailsStore } from '@/src/market/stores/product-fhs-details';
import { useProductPlantAvailabilityStore } from '@/src/market/stores/product-plant-availability';
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';

interface Props {
  id: string;
  owningSystem: string;
  isFhs?: boolean;
}

const props = defineProps<Props>();

const textK = useText();
const route = useRoute();
const productStore = useProductStore();
const productPlantAvailabilityStore = useProductPlantAvailabilityStore();
const productfhsDetailsStore = useProductFHSDetailsStore();

const product = computed(() => {
  return productStore.productById(props.id);
});

const productAdditionalData = computed(() => {
  return getProductAdditionalData(props.id, props.owningSystem);
});

const isSatair = computed(() => {
  return checkIfIsSatair(productAdditionalData.value);
});

const isAirbus = computed(() => {
  return checkIfIsAirbus(productAdditionalData.value);
});

const procurementData = computed(() => {
  if (checkIfIsAirbus(productAdditionalData.value)) {
    const { params } = route;
    return getProcurementByRouteParams(params, productAdditionalData.value.procurements);
  }

  return null;
});

const unit = computed(() => {
  if (isSatair.value && plantAvailability.value && !plantAvailability.value.HasPlants) {
    return '-';
  }

  if (isAirbus.value) {
    const priceUnit = formatPriceUnit(procurementData.value?.priceUnit);
    return priceUnit.SapCode;
  }

  return '';
});

const warehouse = computed(() => {
  if (checkIfIsSatair(productAdditionalData.value)) {
    return productAdditionalData.value.Warehouse;
  }

  return null;
});

const quantity = computed(() => {
  if (checkIfIsSatair(productAdditionalData.value)) {
    return productAdditionalData.value.Quantity;
  }

  return null;
});

const hasPlants = computed(() => {
  if (isSatair.value && plantAvailability.value) {
    return plantAvailability.value.HasPlants;
  }

  if (isAirbus.value && locationInfos.value) {
    const firLocationInfo = locationInfos.value[0];

    return Boolean(firLocationInfo) && 'warehouseLocationDescription' in firLocationInfo;
  }

  return false;
});

const plantAvailability = computed(() => {
  if (isSatair.value && props.id) {
    return productPlantAvailabilityStore.plantAvailabilityByProductOfferId(props.id);
  }
  return null;
});

const locationInfos = computed(() => {
  if (checkIfIsAirbus(productAdditionalData.value)) {
    return procurementData.value?.locationInfos ?? [];
  }

  return [];
});

const availabilityDate = computed(() => {
  if (checkIfIsAirbus(productAdditionalData.value) && procurementData.value?.availabilityDate) {
    return DateFormat(procurementData.value?.availabilityDate);
  }

  return undefined;
});

const productRuleStatus = computed(() => {
  if (checkIfIsSatair(productAdditionalData.value)) {
    return productAdditionalData.value?.RuleStatus || '';
  }

  return '';
});

const isSellable = computed(() => {
  return checkIfIsSellable(productAdditionalData.value, procurementData.value);
});

const hasNoPrice = computed(() => {
  return checkIfHasNoPrice(productAdditionalData.value, procurementData.value);
});

const isBlueOwningSystem = computed(() => {
  return checkIfIsBlueOwningSystem(props.owningSystem as OwningSystem);
});

const hasUnknownDeliveryDate = computed(() => {
  return unknownDeliveryDate(productAdditionalData.value, procurementData.value);
});

const hasPlantAvailability = computed(() => {
  return Boolean(plantAvailability.value);
});

const hasLocationInfos = computed(() => {
  return locationInfos.value.length > 0;
});

const hasAvailabilityDate = computed(() => {
  if (checkIfIsAirbus(productAdditionalData.value)) {
    return Boolean(procurementData.value?.availabilityDate);
  }

  return false;
});

const isToolLease = computed(() => {
  return isLeaseTool(procurementData.value?.procurementType, product.value);
});

const fhsProcurementLocations = computed(() => {
  const { params } = route;
  return (
    productfhsDetailsStore.getFhsDetailsByProductIdAndProcurement(
      props.id,
      params.procurementType?.toUpperCase(),
    )?.locations ?? []
  );
});

const hasFhsLocations = computed(() => fhsProcurementLocations.value.length > 0);

const showPlantAvailabilityTable = computed(() => {
  if (props.isFhs && hasFhsLocations.value) {
    return true;
  }

  if (isToolLease.value) {
    return hasLocationInfos.value && hasPlants.value;
  }

  if (!isSellable.value || hasNoPrice.value) {
    return false;
  }

  if (checkIfIsSatair(productAdditionalData.value)) {
    return Boolean(plantAvailability.value);
  }

  if (checkIfIsAirbus(productAdditionalData.value)) {
    return hasLocationInfos.value && hasPlants;
  }

  return false;
});

watch(
  () => productRuleStatus.value,
  (productStatus: string) => {
    // This is only for RED & MIRAKL products that
    // need to fetch availability from /plants
    const statusIsOk = productStatus === 'OK' || productStatus === 'NOPRICE';

    if (statusIsOk && props.id && !isBlueOwningSystem.value) {
      productPlantAvailabilityStore.fetchPlantAvailability({
        OfferId: props.id,
      });
    }
  },
  { immediate: true },
);
</script>
