import { defineStore } from 'pinia';
import { ref } from 'vue';

export enum LOGIN_STEPS {
  USERNAME = 'USERNAME',
  PASSWORD = 'PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  SELECT_ACCOUNT = 'SELECT_ACCOUNT',
}

export const useLoginStore = defineStore('login-store', () => {
  const currentStep = ref<keyof typeof LOGIN_STEPS>(LOGIN_STEPS.USERNAME);
  const state = ref<{ username?: string; password?: string } | null>();
  const isDualUser = ref(false);

  const setCurrentStep = (step: keyof typeof LOGIN_STEPS) => {
    currentStep.value = step;
  };

  const resetCurrentStep = () => {
    currentStep.value = LOGIN_STEPS.USERNAME;
  };

  const setState = (payload: { username?: string; password?: string }) => {
    state.value = payload;
  };

  const resetState = () => {
    state.value = null;
  };

  const setDualUser = (state: boolean) => {
    isDualUser.value = state;
  };

  return {
    currentStep,
    state,
    isDualUser,
    setDualUser,
    setCurrentStep,
    resetCurrentStep,
    setState,
    resetState,
  };
});
