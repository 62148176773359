<template>
  <form @submit.prevent="changePassword">
    <StandardDialogModal :has-cancel-button="true" :size="uiSize.Medium">
      <template #header>
        <div class="typeset">
          <h3>Change password</h3>
        </div>
      </template>
      <template #body>
        <div class="typeset">
          <div class="input-container">
            <VTextField
              autocomplete="current-password"
              v-ui-test="'changepassword-oldpassword'"
              :type="uiVariant.Secondary"
              label="Password"
              input-type="password"
              name="oldPassword"
              v-model="oldPassword"
              disable-type-validation
            ></VTextField>
          </div>
          <div class="input-container">
            <VTextField
              autocomplete="new-password"
              v-ui-test="'changepassword-newpassword'"
              :type="uiVariant.Secondary"
              label="New password"
              input-type="password"
              name="newPassword"
              v-model="newPassword"
              disable-type-validation
            >
            </VTextField>
          </div>
          <div class="input-container">
            <VTextField
              autocomplete="new-password"
              v-ui-test="'changepassword-newpassword-retyped'"
              :type="uiVariant.Secondary"
              label="Repeat new password"
              input-type="password"
              name="reapeatNewPassword"
              v-model="repeatNewPassword"
              disable-type-validation
            ></VTextField>
          </div>

          <PasswordValidationBox
            :value="newPassword"
            :repeated-value="repeatNewPassword"
          ></PasswordValidationBox>
        </div>
      </template>
      <template #actions>
        <VButton
          :disabled="disableSubmit"
          :type="uiVariant.Default"
          v-ui-test="'changepassword-confirm-button'"
          >Save
        </VButton>
      </template>
    </StandardDialogModal>
  </form>
</template>

<script lang="ts" setup>
import { UserApi } from '@/src/core/api';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import PasswordValidationBox from '@/src/core/components/ui/form/password-validation-box/password-validation-box.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import { Req } from '@/src/core/services/requester';
import { useModalStore } from '@/src/core/stores/modal';
import { IUser } from '@/src/core/types/interfaces';
import { passwordRegEx } from '@/src/core/utils/regex-library';
import { useUserStore } from '@/src/profile/stores/user';
import { computed, onMounted, ref, watch } from 'vue';

const modalStore = useModalStore();
const userStore = useUserStore();

const uiVariant = UiVariant;
const uiSize = UiSize;

const oldPassword = ref('');
const newPassword = ref('');
const repeatNewPassword = ref('');
const userData = ref<IUser>({} as IUser);

const currentUserData = computed(() => userStore.currentUser);

const modalParams = computed(() => modalStore.params as IUser);

const disableSubmit = computed(() => {
  return (
    !passwordRegEx.test(newPassword.value) ||
    oldPassword.value.length === 0 ||
    newPassword.value.length === 0 ||
    newPassword.value !== repeatNewPassword.value
  );
});

const changePassword = async () => {
  if (userData.value.GlobalId) {
    const { IsSuccess } = await Req({
      url: UserApi.UpdateUserPassword(userData.value.GlobalId),
      method: 'POST',
      data: { password: oldPassword.value, newpassword: newPassword.value },
    });

    if (IsSuccess) {
      modalStore.closeModal();
    }
  }
};

watch(
  () => currentUserData,
  () => {
    modalStore.closeModal();
  },
);

onMounted(() => {
  userData.value = modalParams.value || ({} as IUser);
});
</script>
