<template>
  <li class="list-navigation-item-container">
    <div class="list-navigation-item">
      <div class="list-navigation-item__media" v-if="$slots.icon">
        <slot name="icon" />
      </div>
      <div class="list-navigation-item__content">
        <span class="list-navigation-item__title" v-if="$slots.title">
          <slot name="title"></slot>
        </span>
        <span class="list-navigation-item__description" v-if="$slots.description">
          <slot name="description"></slot>
        </span>
      </div>
      <div class="list-navigation-item__arrow">
        <svgicon name="ui-concept-arrow"></svgicon>
      </div>
    </div>
  </li>
</template>

<style lang="scss" src="./list-navigation-item.scss" scoped></style>

<script lang="ts" setup></script>
