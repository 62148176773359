<template>
  <form @submit.prevent="deleteList" :disabled="isBusy">
    <StandardDialogModal :has-cancel-button="true">
      <template #header>
        <div class="typeset">
          <h3>Delete list</h3>
          <p>
            Are you sure you want to delete the list "<i>{{ list.Name }}</i
            >"
          </p>
        </div>
      </template>
      <template #actions>
        <VButton
          :type="uiVariant.Default"
          v-ui-test="'delete-list-button'"
          :loading="isBusy"
          @click.prevent="deleteList"
          >Delete</VButton
        >
      </template>
    </StandardDialogModal>
  </form>
</template>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import { useModalStore } from '@/src/core/stores/modal';
import { IList } from '@/src/core/types/interfaces';
import { useListsStore } from '@/src/market/stores/list';
import { useQuoteDraftsStore } from '@/src/market/stores/quote-drafts';
import { computed, watch } from 'vue';

export interface IModalDeleteListParams {
  IsQuoteList: boolean;
}

const listsStore = useListsStore();
const quoteDraftsStore = useQuoteDraftsStore();
const modalStore = useModalStore();
const uiVariant = UiVariant;

const list = computed(() => modalStore.params as IList);

const params = computed(() => modalStore.params as IModalDeleteListParams);

const isBusy = computed(() => {
  return params.value.IsQuoteList ? quoteDraftsStore.isBusy : listsStore.isBusy;
});

const deleteList = () => {
  if (!isBusy.value) {
    modalStore.lockCurrentModal(true);
    if (params.value.IsQuoteList) {
      quoteDraftsStore.deleteList({ listCode: list.value.Code });
    } else {
      listsStore.deleteList({ listCode: list.value.Code });
    }
  }
};

watch(isBusy, (busy) => {
  if (!busy) {
    modalStore.closeModal();
  }
});
</script>
