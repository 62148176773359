<template>
  <div ref="wrapperRef" class="twoway-icon__wrapper">
    <svg
      ref="iconRef"
      class="twoway-icon"
      width="81"
      height="32"
      viewBox="0 0 81 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="81" height="32" fill="#2E2E2E" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.9992 13.66L29.9892 13.67L23.3192 20.33H18.9592L24.0592 15.22H24.0392L24.0592 15.21H13.1992L16.3092 12.11H24.0592L18.9592 7H23.3292L29.9992 13.66Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 18.7804L2.01 18.7704L8.68 12.1104H13.04L7.94 17.2204H7.96L7.94 17.2304H18.8L15.69 20.3304H7.94L13.04 25.4404H8.67L2 18.7804Z"
        fill="white"
      />
      <path
        d="M53 7.50366V6.17773H58.8909V7.50366H56.7343V13.3612H55.1566V7.50366H53Z"
        fill="white"
      />
      <path
        d="M65.7972 13.3589L64.5804 8.46638H64.5636L63.3636 13.3589H61.7609L59.856 6.16699H61.4336L62.5748 11.0595H62.5916L63.842 6.16699H65.3189L66.5441 11.1182H66.5609L67.7357 6.16699H69.2881L67.3581 13.3589H65.7888H65.7972Z"
        fill="white"
      />
      <path
        d="M70.1608 8.29939C70.3203 7.83783 70.5552 7.43502 70.8573 7.09934C71.1594 6.75527 71.5287 6.48673 71.9734 6.29372C72.4182 6.1007 72.9049 6 73.4587 6C74.0126 6 74.5161 6.1007 74.9524 6.29372C75.3888 6.48673 75.758 6.75527 76.0601 7.09934C76.3622 7.44341 76.5972 7.84622 76.7566 8.29939C76.9161 8.76094 77 9.25606 77 9.79315C77 10.3302 76.9161 10.8086 76.7566 11.2617C76.5972 11.7149 76.3622 12.1093 76.0601 12.445C75.758 12.7807 75.3888 13.0408 74.9524 13.2338C74.5161 13.4268 74.021 13.5192 73.4587 13.5192C72.8965 13.5192 72.4098 13.4268 71.9734 13.2338C71.5371 13.0408 71.1594 12.7807 70.8573 12.445C70.5552 12.1093 70.3203 11.7149 70.1608 11.2617C70.0014 10.8086 69.9175 10.3218 69.9175 9.79315C69.9175 9.26446 70.0014 8.76094 70.1608 8.29939ZM71.6042 10.6743C71.6713 10.9596 71.7888 11.2198 71.9399 11.4464C72.0909 11.6729 72.2923 11.8576 72.5441 11.9918C72.7958 12.1261 73.0979 12.2016 73.4503 12.2016C73.8028 12.2016 74.1049 12.1345 74.3566 11.9918C74.6084 11.8492 74.8098 11.6729 74.9608 11.4464C75.1119 11.2198 75.2294 10.9596 75.2965 10.6743C75.3636 10.389 75.4056 10.0953 75.4056 9.79315C75.4056 9.49104 75.372 9.17215 75.2965 8.87843C75.221 8.58471 75.1119 8.31617 74.9608 8.08959C74.8098 7.85461 74.6084 7.66999 74.3566 7.53572C74.1049 7.40145 73.8028 7.32592 73.4503 7.32592C73.0979 7.32592 72.7958 7.39306 72.5441 7.53572C72.2923 7.67838 72.0909 7.85461 71.9399 8.08959C71.7888 8.32456 71.6713 8.58471 71.6042 8.87843C71.5371 9.17215 71.4951 9.48265 71.4951 9.79315C71.4951 10.1036 71.5287 10.389 71.6042 10.6743Z"
        fill="white"
      />
      <path
        d="M59.8231 25.1059L58.6063 20.2134H58.5895L57.3895 25.1059H55.7867L53.8818 17.9141H55.4595L56.6007 22.8066H56.6175L57.8678 17.9141H59.3448L60.5699 22.8653H60.5867L61.7616 17.9141H63.314L61.3839 25.1059H59.8147H59.8231Z"
        fill="white"
      />
      <path
        d="M67.6022 17.9248L70.2875 25.1167H68.6428L68.0973 23.5138H65.412L64.8498 25.1167H63.2554L65.9743 17.9248H67.5938H67.6022ZM67.6945 22.339L66.7882 19.7039H66.7715L65.8316 22.339H67.6945Z"
        fill="white"
      />
      <path
        d="M69.5898 17.9248H71.3605L73.0388 20.7613L74.7087 17.9248H76.471L73.8024 22.3557V25.1167H72.2248V22.3138L69.5898 17.9248Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script lang="ts" setup>
import useIntersectionObserver from '@/src/core/hooks/useIntersectionObserver';
import { ref } from 'vue';

const wrapperRef = ref(null);
const iconRef = ref<SVGElement | null>(null);

useIntersectionObserver(
  wrapperRef,
  () => {
    iconRef.value?.classList.add('animate');
  },
  1000,
);
</script>

<style lang="scss" src="./twoway-interchangeable.scss"></style>
