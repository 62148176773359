<template>
  <StandardDialogModal class="list-modal" :size="uiSize.Medium" :has-close-button="false">
    <template #header>
      <h3>{{ heading }}</h3>
    </template>
    <template #body>
      <ModalColumnContainer>
        <ModalColumn>
          <div class="content-modal-wrapper">
            <ContentBlock :modal="true" :link-key="params.key" :url="params.url"></ContentBlock>
          </div>
        </ModalColumn>
      </ModalColumnContainer>
    </template>
  </StandardDialogModal>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import ContentBlock from '@/src/core/components/ui/content-block/content-block.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import { useLinksStore } from '@/src/core/stores/links';
import { useModalStore } from '@/src/core/stores/modal';
import { IModalLoadContent } from '@/src/core/types/interfaces';

const uiSize = UiSize;
const linksStore = useLinksStore();
const modalStore = useModalStore();

const params = computed(() => {
  return modalStore.params as IModalLoadContent;
});

const heading = computed(() => {
  return (params.value.title && linksStore.getKey(params.value.title as never)) || '';
});
</script>
