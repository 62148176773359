<template>
  <div class="contextual-actions-container" :class="{ open: open, 'is-mouse-input': isMouseInput }">
    <slot :set-is-open="setIsOpen" />
  </div>
</template>

<style scoped src="./contextual-actions-container.scss" lang="scss"></style>

<script lang="ts" setup>
import { WhatInputBus } from '@/src/core/utils/what-input-event-bus';
import { WhatInputHandler } from '@/src/core/utils/what-input-handler';
import { computed, onUnmounted, ref } from 'vue';

interface Props {
  isHovered?: boolean;
  isOpen?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isHovered: false,
  isOpen: false,
});

const openLock = ref(false);
const isMouseInput = ref(false);

const open = computed(() => {
  return props.isOpen || props.isHovered || openLock.value || !isMouseInput.value;
});

const setIsOpen = (value: boolean) => {
  openLock.value = value;
};

const inputTypeChanged = (type: string) => {
  isMouseInput.value = type === 'mouse';
};

isMouseInput.value = WhatInputHandler.hasMouse();
WhatInputBus.$on('inputTypeChanged', inputTypeChanged);

onUnmounted(() => {
  WhatInputBus.$off('inputTypeChanged', inputTypeChanged);
});
</script>
