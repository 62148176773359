<template>
  <StandardDialogModal class="modal-request-tool-lease" :size="uiSize.Large" :loading="isBusy">
    <template #header>
      <ModalRequestQuoteHeader
        :offer-id="id"
        :header-title="textK('UI_TOOL_QUOTATION_REQUEST_LEASE_HEADER')"
        :is-tool="product?.IsTool"
        :procurement="procurement"
      />
    </template>
    <template #body>
      <p class="pb-xl">
        {{ textK('UI_TOOL_QUOTATION_FORM_DESCRIPTION') }}
      </p>
      <div class="flex gap-md pb-md">
        <DatePickerWrapper
          class="flex-grow"
          :from-date="formData.fromDate"
          :to-date="formData.toDate"
          :min-date="dayjs().format(DisplayDateFormat)"
          :range="true"
          :multi-calendars="true"
          :auto-position="true"
          @apply="applyDate"
        >
          <template #label>
            <VTextField
              class="flex-grow"
              label="Date from - to"
              :model-value="leasePeriodDates"
              :type="uiVariant.Secondary"
              :silent-disabled="true"
              :as-span="true"
            />
          </template>
        </DatePickerWrapper>
        <QuantitySelector
          @focus="isInputFocused = true"
          @blur="isInputFocused = false"
          class="flex-grow"
          :avoid-zero-value="true"
          :min-value="1"
          :step="stepQty"
          :as-input="true"
          :unit="textK('UI_QUOTES_REQUEST_QTY_LABEL')"
          :with-delay="false"
          v-model="formData.quantity"
        />
      </div>
      <p class="pb-xl">{{ textK('UI_TOOL_QUOTATION_MINIMUM_LEASE_PERIOD_INFO') }}</p>
      <VTextField
        class="pb-xl"
        v-model="formData.purchaseOrderNumber"
        input-type="text"
        :type="uiVariant.Secondary"
        :label="textK('UI_TOOL_QUOTATION_REFERENCE_TEXT')"
        name="referenceNumber"
        :required="true"
        :vmax-length="MAX_REFERENCE_LENGTH"
        regex="^[A-Za-z0-9\s\-]+$"
      />
      <VTextarea
        class="pb-l"
        v-model="formData.message"
        :vmax-length="MAX_MESSAGE_LENGTH"
        :type="uiVariant.Secondary"
        :label="textK('UI_TOOL_QUOTATION_REQUEST_MESSAGE')"
      />
    </template>
    <template #actions>
      <VButton
        @click.prevent="onSubmit"
        :disabled="!isValid"
        :loading="isBusy"
        :type="uiVariant.Default"
        v-ui-test="'submit-lease-tool-quote'"
        >{{ textK('UI_TOOL_QUOTATION_REQUEST_LEASE_HEADER') }}</VButton
      >
    </template>
  </StandardDialogModal>
</template>

<style src="./modal-request-tool-lease.scss" lang="scss" scoped></style>
<script setup lang="ts">
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import DatePickerWrapper from '@/src/core/components/ui/form/datepicker/datepicker.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { Req } from '@/src/core/services/requester';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { useModalStore } from '@/src/core/stores/modal';
import { SendQuoteEmailToAirbusRequest } from '@/src/core/types/api';
import { AirbusPart } from '@/src/core/types/interfaces';
import { DateFormat, DisplayDateFormat } from '@/src/core/utils/dates';
import { QuoteApi } from '@/src/market/api/quoteApi';
import ModalRequestQuoteHeader from '@/src/market/components/modals/modal-request-quote/modal-request-quote-header/modal-request-quote-header.vue';
import QuantitySelector from '@/src/market/components/ui/quantity-selector/quantity-selector.vue';
import { productAdditionalData } from '@/src/market/services/product-parts';
import { useProductStore } from '@/src/market/stores/product';
import { useUserStore } from '@/src/profile/stores/user';
import dayjs from 'dayjs';
import { computed, reactive, ref } from 'vue';

interface Params {
  id: string;
}

interface Form {
  fromDate?: string;
  purchaseOrderNumber: string;
  quantity: string;
  message?: string;
  toDate?: string;
}

const MAX_MESSAGE_LENGTH = 1000;
const MAX_REFERENCE_LENGTH = 11;

const textK = useText();
const modalStore = useModalStore();
const uiSize = UiSize;
const uiVariant = UiVariant;
const userStore = useUserStore();
const productStore = useProductStore();

const formData = reactive<Form>({
  purchaseOrderNumber: '',
  quantity: '1',
});
const isBusy = ref(false);
const isInputFocused = ref(false);
const isValid = computed(
  () =>
    formData.purchaseOrderNumber.length > 0 &&
    parseFloat(formData.quantity) > 0 &&
    !isInputFocused.value,
);
const leasePeriodDates = ref('');
const id = computed(() => (modalStore.params as Params)?.id ?? '');
const product = computed(() => productStore.productById(id.value));
const stepQty = computed(() => product.value?.CartStep ?? 1);

const additionalData = computed(() =>
  productAdditionalData(id.value, product.value?.OwningSystem ?? ''),
);

const procurement = computed(() =>
  (additionalData.value as AirbusPart)?.procurements.find(
    ({ procurementType }) => procurementType === ProcurementType.Lease,
  ),
);

const closeModal = () => {
  modalStore.closeModal();
};

const applyDate = (datepickerValue: string[]) => {
  if (datepickerValue.length >= 2) {
    leasePeriodDates.value =
      DateFormat(datepickerValue[0]) + ' - ' + DateFormat(datepickerValue[1]);
    formData.fromDate = dayjs(datepickerValue[0]).format(DisplayDateFormat);
    formData.toDate = dayjs(datepickerValue[1]).format(DisplayDateFormat);
  }
};

const onSubmit = async () => {
  isBusy.value = true;

  const { Name: companyData } = userStore.company;
  const { GlobalId, Unit, Name: customerName, Email: customerEmail } = userStore.currentUser;
  const address = Unit?.BlueB2BUnit?.Addresses.find(
    ({ DefaultAddress, ShippingAddress }) => DefaultAddress && ShippingAddress,
  );

  const requestObejct: SendQuoteEmailToAirbusRequest = {
    ...formData,
    customerName,
    customerEmail,
    companyData,
    productId: id.value,
    procurementType: ProcurementType.Lease,
    shipToAddress: address?.SapCustomerId,
  };

  const { IsSuccess } = await Req({
    url: QuoteApi.CreateAirbusQuote(GlobalId),
    method: 'POST',
    data: requestObejct,
  });

  if (IsSuccess) {
    modalStore.showModal({
      modalComponent: 'ModalQuoteEmailNotification',
      params: {
        key: 'AirbusRFQConfirmationToolLease_ModalUrl',
        title: 'AirbusRFQConfirmationToolLease_Name',
        size: uiSize.Medium,
        isConfirmation: true,
        withCloseButton: true,
      },
    });

    closeModal();
  }

  isBusy.value = false;
};
</script>
