import { HttpResponse } from '@/src/core/apim';

/**
 * Process response data of File Downloads
 * And convert the file contents to bytes and pass it as json
 *
 * @param response HttpResponse
 * @param fileName string
 * @returns
 */
export const toLegacyDownloadResponse = async (
  response: HttpResponse<unknown>,
  fileName: string = 'file',
) => {
  const blob = await response.blob();
  if (blob) {
    const toBytes = await convertBlobToBytes(blob);
    const contentType = response.headers.get('content-type');
    const data = { FileContents: toBytes, ContentType: contentType, FileDownloadName: fileName };
    return data;
  }

  return null;
};

/**
 *
 * Convert blob type to bytes
 *
 * @param blob
 * @returns Promise<Uint8Array>
 */
export const convertBlobToBytes = async (blob: Blob): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve(new Uint8Array(reader.result));
      } else {
        reject(new Error('Failed to read Blob as ArrayBuffer'));
      }
    };

    reader.onerror = () => {
      reject(new Error('Failed to read Blob'));
    };

    reader.readAsArrayBuffer(blob);
  });
};
