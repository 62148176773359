import ContentLoader from '@/src/content/components/content-loader/content-loader.vue';
import ContentLayout from '@/src/content/layouts/content/content-layout.vue';
import FaqLayout from '@/src/content/layouts/faq/faq-layout.vue';
import { Site, useUIStore } from '@/src/core/stores/ui';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

const contentRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'main',
    component: ContentLayout,
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: () => void) => {
      const uiStore = useUIStore();
      uiStore.setCurrentSite({ site: Site.Content });
      next();
    },
    children: [
      {
        path: '/pages/faq*',
        name: 'faq-test',
        components: {
          contentRoute: FaqLayout,
        },
      },
      {
        path: '/help-and-support',
        name: 'faq',
        components: {
          contentRoute: FaqLayout,
        },
      },
      {
        //path: routeConfigStore.getMenuConfigPath('brandguide', '/brandguide*'),
        path: '/brandguide',
        name: 'brandguide',
        components: {
          contentRoute: FaqLayout,
        },
        props: {
          disableSearch: true,
        },
      },
      {
        path: '/',
        name: 'brandsite-home',
        components: {
          contentRoute: ContentLoader,
        },
      },
      {
        path: '/:catchAll(.*)*',
        name: 'mainUmbracoContent',
        components: {
          contentRoute: ContentLoader,
        },
      },
    ],
  },
];

export default contentRoutes;
