<template>
  <div class="modal-request-quote-header">
    <div class="modal-request-quote-header__title-wrapper" v-ui-test="'request-quote-header'">
      <h3 class="modal-request-quote-header__title">
        {{ headerTitle }}
      </h3>
    </div>
    <div v-if="offer" v-ui-test="'request-quote-offer'" class="modal-request-quote-header__product">
      <div
        class="modal-request-quote-header__product__image"
        v-ui-test="'request-quote-offer-image'"
      >
        <img
          v-if="offer.HasImage && offer.Images.length > 0"
          alt="offer-image"
          :src="offer.Images[0].CdnUrl + '&width=100&height=100&mode=crop'"
        />
        <img v-else-if="offer.MainPicture" alt="offer-image" :src="offer.MainPicture" />
        <svg
          v-else
          class="placeholder-image"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 110 110"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M75.4 12.4v47.4l-59-59L.8 16.4l59 59H12.4L.8 97.4h96.6V.8z"
          />
        </svg>
      </div>
      <div
        class="modal-request-quote-header__product__details"
        :class="{
          'modal-request-quote-header__product__details--hasAdditionalDetails':
            hasAdditionalDetails,
        }"
      >
        <div
          class="modal-request-quote-header__product__number"
          :class="{
            'modal-request-quote-header__product__number--hasAdditionalDetails':
              hasAdditionalDetails,
          }"
          v-ui-test="'request-quote-offer-sku'"
        >
          {{ offer.ManufacturerAid
          }}<span
            v-if="offer.ProductManufacturer && offer.ProductManufacturer.CageCode.length > 0"
            class="modal-request-quote-header__product__cagecode"
            >:{{ offer.ProductManufacturer.CageCode }}</span
          >
        </div>
        <div
          class="modal-request-quote-header__product__title"
          v-ui-test="'request-quote-offer-name'"
        >
          {{ offer.Name && offer.Name.length > 0 ? offer.Name : '-' }}
        </div>
        <div v-if="hasAdditionalDetails">
          <slot name="additionalDetails" />
        </div>
      </div>
      <div class="price-type" v-if="showPrice">
        <div class="price-type__currency" v-ui-test="'request-quote-offer-currency'">
          <span v-if="showLeasePrice">From</span>
          {{ priceUnitLabel }}
          <Tooltip
            v-if="showLeasePrice"
            :position="TooltipDefaultPosition.Top"
            class="product-price__lease-tooltip"
          >
            <span class="banner">
              <svgicon name="ui-info" />
            </span>
            <template #tooltip-content>
              {{ minimumLeasePeriodText }}
            </template>
          </Tooltip>
        </div>
        <div
          class="price-type__value"
          v-ui-test="'request-quote-offer-original-price'"
          v-html="setPrice(formattedPriceValue)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./modal-request-quote-header.scss" scoped />

<script lang="ts" setup>
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import usePrice from '@/src/core/hooks/usePrice';
import useText from '@/src/core/hooks/useText';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { Procurement, TooltipDefaultPosition } from '@/src/core/types/interfaces';
import { productAdditionalData } from '@/src/market/services/product-parts';
import { basePriceHandler } from '@/src/market/services/product-parts-prices';
import { isPriceVisible } from '@/src/market/services/product-rules';
import { useProductStore } from '@/src/market/stores/product';
import { useProductPriceInfoStore } from '@/src/market/stores/product-price-info';
import { computed } from 'vue';

interface Props {
  offerId: string;
  headerTitle: string;
  procurement?: Procurement;
  hasAdditionalDetails?: boolean;
  isTool?: boolean;
  showPrice?: boolean;
}

const setPrice = usePrice;
const productStore = useProductStore();
const productPriceInfoStore = useProductPriceInfoStore();
const textK = useText();
const props = withDefaults(defineProps<Props>(), {
  isTool: false,
  hasAdditionalDetails: false,
  showPrice: true,
  procurement: undefined,
});

const offer = computed(() => {
  return productStore.productById(props.offerId);
});

const priceData = computed(() => {
  return productPriceInfoStore.priceDataByOfferId(props.offerId);
});

const additionalData = computed(() => {
  return productAdditionalData(props.offerId, offer.value?.OwningSystem ?? '');
});

const price = computed(() => {
  return basePriceHandler(additionalData.value, props.procurement, priceData.value);
});

const formattedPriceValue = computed(() => {
  if (showLeasePrice.value) {
    return props.procurement?.leaseInfo?.minimumLease.subtotal;
  }
  return price.value?.FormattedValue ?? '';
});

const priceVisible = computed(() => {
  return isPriceVisible(additionalData.value, props.procurement);
});

const showPrice = computed(() => {
  if (props.showPrice === false) {
    return false;
  }

  if (showLeasePrice.value) {
    return Boolean(
      props.procurement?.leaseInfo?.minimumLease.subtotal &&
        +props.procurement?.leaseInfo?.minimumLease.subtotal > 0,
    );
  }

  return priceVisible.value && price.value?.Value && +price.value?.Value > 0;
});

const currency = computed(() => {
  return price.value?.CurrencyIso;
});

const unit = computed(() => {
  return priceData.value?.ProductPriceInfo.PriceUnit.Code || props.procurement?.priceUnit.sapCode;
});

const showLeasePrice = computed(() => {
  return isLeaseProcurement.value && props.isTool;
});

const isLeaseProcurement = computed(() => {
  return props.procurement?.procurementType === ProcurementType.Lease;
});

const priceUnitLabel = computed(() => {
  if (isLeaseProcurement.value && props.procurement?.leaseInfo?.currency) {
    return `${props.procurement.leaseInfo?.currency}/${unit.value}`;
  }

  return `${currency.value}/${unit.value}`;
});

const minimumLeasePeriodText = computed(() => {
  return textK('UI_PRODUCT_MINIMUM_LEASE_CHARGE_TEXT_TOOL').replace(
    /\[\[minimum-lease-charge-percent\]\]/gi,
    props.procurement?.leaseInfo?.minimumLease.percentagePerDay as string,
  );
});
</script>
