<template>
  <VueDatePicker
    ref="dp"
    v-model="date"
    class="custom-datepicker"
    :teleport="'#custom-datepicker-teleport'"
    :range="range"
    :multi-calendars="multiCalendars"
    :week-start="weekStart"
    :action-row="{ showNow: false, showPreview: false }"
    :enable-time-picker="false"
    :month-name-format="monthNameFormat"
    :day-names="dayNames"
    :disable-month-year-select="disableMonthYearSelect"
    :hide-navigation="['month', 'year']"
    :uid="uid"
    :required="required"
    :name="name"
    :readonly="readonly"
    :disabled="disabled"
    :clearable="clearable"
    :loading="loading"
    :format="format"
    :min-date="minDate"
    :max-date="maxDate"
    :prevent-min-max-navigation="preventMinMaxNavigation"
    :auto-position="autoPosition"
    :alt-position="altPosition"
    :auto-apply="autoApply"
    @update:modelValue="onDateUpdate"
    @cleared="onCleared"
  >
    <template #arrow-left>
      <svg viewBox="0 0 1000 1000">
        <path
          d="M336.2 274.5l-210.1 210h805.4c13 0 23 10 23 23s-10 23-23 23H126.1l210.1 210.1c11 11 11 21 0 32-5 5-10 7-16 7s-11-2-16-7l-249.1-249c-11-11-11-21 0-32l249.1-249.1c21-21.1 53 10.9 32 32z"
        ></path>
      </svg>
    </template>
    <template #arrow-right>
      <svg class="arrow-right" viewBox="0 0 1000 1000">
        <path
          d="M336.2 274.5l-210.1 210h805.4c13 0 23 10 23 23s-10 23-23 23H126.1l210.1 210.1c11 11 11 21 0 32-5 5-10 7-16 7s-11-2-16-7l-249.1-249c-11-11-11-21 0-32l249.1-249.1c21-21.1 53 10.9 32 32z"
        ></path>
      </svg>
    </template>

    <template v-if="datepickerType === 'text'" #trigger>
      <span v-if="value || !placeHolder" :disabled="disabled" :label="'123'">
        <slot name="label" />
      </span>
    </template>

    <template v-else #trigger>
      <slot name="label"></slot>
    </template>

    <template #action-buttons>
      <div class="custom-datepicker__actions">
        <button class="action-btn custom-datepicker__actions--cancel" @click="onCancel">
          Cancel
        </button>
        <button class="action-btn custom-datepicker__actions--apply" @click="onApply">Apply</button>
      </div>
    </template>
  </VueDatePicker>
</template>

<style lang="scss" src="./datepicker.scss"></style>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import type { DatePickerInstance } from '@vuepic/vue-datepicker';

type WeekStart = '0' | '1' | '2' | '3' | '4' | '5' | '6' | 0 | 1 | 2 | 3 | 4 | 5 | 6;

interface Props {
  autoPosition?: boolean | 'top' | 'bottom';
  range?: boolean;
  multiCalendars?: boolean;
  weekStart?: WeekStart;
  monthNameFormat?: 'long' | 'short';
  dayNames?: string[];
  disableMonthYearSelect?: boolean;
  uid?: string;
  required?: boolean;
  name?: string;
  readonly?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  loading?: boolean;
  format?: string;
  from?: string | Date;
  to?: string | Date;
  value?: string | Date;
  minDate?: Date | string;
  maxDate?: Date | string;
  preventMinMaxNavigation?: boolean;
  altPosition?: (el: HTMLElement | undefined) => {
    top: number | string;
    left: number | string;
    transform?: string;
  };
  modelValue?: Date | Date[] | null;
  placeHolder?: string;
  label?: string;
  datepickerType?: 'date' | 'input' | 'button' | 'text';
  autoApply?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  autoPosition: true,
  range: false,
  multiCalendars: false,
  weekStart: 0,
  monthNameFormat: 'long',
  dayNames: () => ['SUN', 'MON', 'TUE', 'WED', 'THUR', 'FRI', 'SAT'],
  disableMonthYearSelect: false,
  uid: 'v3datepicker',
  required: false,
  readonly: false,
  disabled: false,
  clearable: false,
  loading: false,
  format: 'yyyy-MM-dd',
  minDate: undefined,
  maxDate: undefined,
  modelValue: null,
  datepickerType: 'date',
  autoApply: false,
});

const emit = defineEmits<{
  (e: 'selectedDate', value: Date | Date[] | null): void;
  (e: 'apply', value: Date | Date[] | null): void;
  (e: 'update:modelValue', value: Date | Date[] | null): void;
}>();

const dp = ref<DatePickerInstance>(null);
const date = ref<Date | Date[] | null>(null);

const dateValue = computed(() => props.value);
const from = computed(() => props.from);
const to = computed(() => props.to);

const onDateUpdate = (newDate: Date | Date[] | null) => {
  emit('update:modelValue', newDate);
};

const onApply = () => {
  if (dp.value) {
    dp.value.selectDate();
    emit('selectedDate', date.value);
    emit('apply', date.value);
  }
};

const onCleared = () => {
  if (dp.value) {
    emit('selectedDate', null);
    emit('apply', null);
  }
};

const onCancel = () => {
  if (dp.value) {
    dp.value.closeMenu();
  }
};

const openMenu = () => {
  if (dp.value) {
    dp.value.openMenu();
  }
};

watch(
  () => dateValue.value,
  (newValue) => {
    if (newValue && !props.range) {
      date.value = new Date(newValue);
    }
  },
  { immediate: true },
);

watch(
  [from, to],
  () => {
    if (from.value && to.value && props.range) {
      date.value = [new Date(from.value), new Date(to.value)];
    }
  },
  { immediate: true },
);

defineExpose({
  onCancel,
  openMenu,
});
</script>
