import { getEnv } from '@/src/core/services/environment';
import { AllInnerKeys, CompositeKey } from '@/src/core/types/link';
import Links from '@/src/core/types/link-types.generated';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { UmbracoApi } from '../api';
import { Req } from '../services/requester';
import { ILinkItem, ILinkObject } from '../types/interfaces';

export const useLinksStore = defineStore('links', () => {
  const status = ref<string>('');
  const links = ref<ILinkItem[]>([]);
  const isBusy = ref<boolean>(false);

  const getKey = <T extends keyof Links, K extends AllInnerKeys>(
    key: CompositeKey<T, K>,
  ): string => {
    const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';
    const acceptedUrls = new Set([key, baseUrl + key]);
    const value = links.value.find((x) => acceptedUrls.has(x.Key));

    if (!value) {
      return '';
    }

    if (value.Value.startsWith('/')) {
      return baseUrl + value.Value;
    } else {
      return value.Value;
    }
  };

  const isBusyUpdated = (payload: { IsBusy: boolean }) => {
    isBusy.value = payload.IsBusy;
  };

  const changeStatus = (payload: { status: string }) => {
    status.value = payload.status;
  };

  const updateLinks = (payload: { links: ILinkObject[] }) => {
    payload.links.map((items) => {
      Object.keys(items.Reference).map((key) => {
        links.value.push({
          Key: items.Key + '_' + key,
          Value: items.Reference[key],
        });
      });
    });
  };

  const fetchLinks = async () => {
    isBusyUpdated({ IsBusy: true });

    const { IsSuccess, Data } = await Req({
      url: UmbracoApi.getBlocks,
    });

    if (IsSuccess && Data) {
      updateLinks({ links: Data });
      changeStatus({ status: 'COMPLETE' });
    }
    isBusyUpdated({ IsBusy: false });
  };

  return {
    links,
    isBusy,
    fetchLinks,
    getKey,
  };
});
