// tslint:disable
import { apim } from '@/src/core/apim';
import { getEnv } from '@/src/core/services/environment';
import { toLegacyDownloadResponse } from '@/src/core/services/legacy-download-response';
import { toLegacyResponse } from '@/src/core/services/legacy-response-utils';
import { getNotificationMapping } from '@/src/core/services/notification-mapping';
import { TextKey } from '@/src/core/services/text-key';
import { ListType, NotificationTypes } from '@/src/core/types/api';
import {
  objectKeysToCamelCaseRecursive,
  objectKeysToPascalCaseRecursive,
} from '@/src/core/utils/object';
import { legacyErrorHandler } from '@/src/market/services/error-notification-handling';
import {
  FailIndicators,
  mapAddedCartEntries,
} from '@/src/market/services/legacy-cart-modification-mapping';
import { getFileNameFromHeaders } from '@/src/market/services/legacy-mapping-filename-headers';
import { getProductSearchItemWithMapping } from '@/src/market/services/legacy-product-search-item-mapping';
import {
  isListNameValid,
  listNameInvalidResponse,
  mapList,
  mapListImport,
  mapLists,
  mapListsWithPagination,
  removeUtilityLists,
  writeToCsv,
} from '@/src/market/services/lists-service';
import { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class ListApi {
  public static PreviewImportList = `${baseUrl}/api/previewimportlist`;
  public static ImportList = `${baseUrl}/api/importlist`;
  public static AllLists = `${baseUrl}/api/lists`;
  public static RecentLists = `${baseUrl}/api/lists/recent`;
  public static CreateList = `${baseUrl}/api/lists/createlist`;
  public static AllListPaging = (page: number, size: number = 20) =>
    `${baseUrl}/api/lists?size=${size}&page=${page ? page : '0'}`;
  public static GetList = (listCode: string) => `${baseUrl}/api/lists/${listCode}`;
  public static UpdateListEntry = (listCode: string, productId: string) =>
    `${baseUrl}/api/lists/${listCode}/entry/${productId}`;
  public static UpdateListEntries = (listCode: string, productId: string) =>
    `${baseUrl}/api/lists/${listCode}/entries/${productId}`;
  public static AddListEntries = (listCode: string, productId: string) =>
    `${baseUrl}/api/lists/${listCode}/add/${productId}`;
  public static DeleteEntry = (listCode: string, productId: string) =>
    `${baseUrl}/api/lists/${listCode}/delete/${productId}`;
  public static RenameList = (listCode: string, listName: string, type: ListType) =>
    `${baseUrl}/api/lists/rename/${listCode}/${listName}/${type}`;
  public static DeleteList = (listCode: string) => `${baseUrl}/api/lists/delete/${listCode}`;
  public static ClearUnresolvedEntries = (listCode: string) =>
    `${baseUrl}/api/lists/${listCode}/clearunresolved/`;
  public static ClearUnresolvedEntry = (listCode: string, pno: string, manu?: string) =>
    `${baseUrl}/api/lists/${listCode}/clearunresolvedentry/?pno=${pno}&manu=${manu}`;
  public static ProductSuggestions = (pno: string) =>
    `${baseUrl}/api/lists/productsuggestions/?pno=${pno}`;
  public static SelectProductSuggestion = (
    listCode: string,
    productId: string,
    quantity: number,
    pno: string,
    manu?: string,
  ) =>
    `${baseUrl}/api/lists/${listCode}/selectunresolved/?productId=${productId}&quantity=${quantity}&pno=${pno}&manu=${manu}`;
  public static DownloadProductListDocument = (listCode: string) =>
    `${baseUrl}/api/lists/${listCode}/download`;

  public static SaveAsList = `${baseUrl}/api/lists/saveaslist`;
  public static AddListToCart = (listCode: string, cartCode: string) =>
    `${baseUrl}/api/lists/addtocart/${listCode}/${cartCode}`;

  public static MultiLineOrderList = (listName: string) =>
    `${baseUrl}/api/lists/multiline/${listName}`;
  public static MultiLineOrderPasteRaw = (listCode: string) =>
    `${baseUrl}/api/lists/${listCode}/processtext`;

  //QuoteDraft
  public static AllQuoteLists = `${baseUrl}/api/lists`;
  //QuoteList
  public static GetQuoteList = `${baseUrl}/api/quotelist`;
  public static CreateQuoteFromList = `${baseUrl}/api/quotelist/create`;

  public static async handleAllLists(settings: AxiosRequestConfig) {
    try {
      const globalId = Cookies.get('globalId') || '';
      const params = settings.params;

      const resp = await apim.searchProductLists1('satairb2b', globalId, {
        currentPage: params.page,
        pageSize: params.size || 0,
        sort: params.sort,
        q: `:relevance:${params.facets || 'productListType:USER_CREATED'}`,
      });
      const lists = removeUtilityLists(resp.data.productLists || []);
      const mappedLists = mapLists(lists);
      const mappedData = mapListsWithPagination(resp.data, mappedLists);

      return toLegacyResponse(resp, {
        Data: objectKeysToPascalCaseRecursive(mappedData, ['airbusMSSOUsername', 'vatID']),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleCreateList(settings: AxiosRequestConfig, validateName = true) {
    try {
      const params = settings.params;
      const globalId = Cookies.get('globalId') || '';

      if (validateName && !isListNameValid(params.listName)) {
        listNameInvalidResponse(params.listName);
      }

      const resp = await apim.createProductList(
        'satairb2b',
        globalId,
        { name: params.listName },
        {
          fields: 'FULL',
          productListType: params.productListType,
        },
      );

      const mappedList = mapList(resp.data);

      return toLegacyResponse(resp, {
        Data: objectKeysToPascalCaseRecursive(mappedList, ['airbusMSSOUsername', 'vatID']),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleSaveAsList(settings: AxiosRequestConfig) {
    try {
      const params = settings.params;
      const globalId = Cookies.get('globalId') || '';
      const listName = params.listName || TextKey('UI_LOGIN_SESSION_LOGOUT_MODAL_DESCRIPTION');
      const cartCode = params.cartCode || 'current';

      if (!isListNameValid(listName)) {
        listNameInvalidResponse(listName);
      }

      const cartEntries = await apim.getCartEntries('satairb2b', cartCode, globalId);
      const orderEntries = cartEntries.data.orderEntries || [];

      const listEntries = orderEntries.map((entry) => ({
        id: entry.offerInfo?.id ? entry.offerInfo.id : entry.product?.id,
        quantity: entry.quantity,
      }));

      const resp = await apim.createProductList(
        'satairb2b',
        globalId,
        { name: listName, entries: listEntries },
        {
          fields: 'FULL',
        },
      );

      const mappedList = mapList(resp.data);

      const notification = getNotificationMapping({
        title: 'Cart saved as list',
        description: 'Now viewing your saved list.',
        type: NotificationTypes.Success,
      });

      return toLegacyResponse(resp, {
        Data: objectKeysToPascalCaseRecursive(mappedList, ['airbusMSSOUsername', 'vatID']),
        Notification: [objectKeysToPascalCaseRecursive(notification)],
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleGetList(listCode: string) {
    try {
      const globalId = Cookies.get('globalId') || '';

      const resp = await apim.getProductList('satairb2b', listCode, globalId, {
        fields: 'FULL',
      });

      const mappedList = mapList(resp.data);

      return toLegacyResponse(resp, {
        Data: objectKeysToPascalCaseRecursive(mappedList, ['airbusMSSOUsername', 'vatID']),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleDeleteList(listCode: string, settings?: AxiosRequestConfig) {
    try {
      const showNotification = settings?.params?.showNotification || false;
      const globalId = Cookies.get('globalId') || '';

      const list = await apim.getProductList('satairb2b', listCode, globalId, {
        fields: 'FULL',
      });
      const listName = list.data.name;

      const resp = await apim.deleteProductList('satairb2b', listCode, globalId);

      const notification = getNotificationMapping({
        title: 'Lists',
        description: `List ${listName} with ID ${listCode} has been deleted`,
        type: NotificationTypes.Success,
      });

      return toLegacyResponse(resp, {
        Data: resp.data,
        ...(showNotification && { Notification: [objectKeysToPascalCaseRecursive(notification)] }),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleUpdateListEntry({
    listCode,
    productCode,
    quantity,
  }: {
    listCode: string;
    productCode: string;
    quantity: number;
  }) {
    try {
      const globalId = Cookies.get('globalId') || '';
      let resp;

      if (quantity === 0) {
        await apim.deleteProductListEntry('satairb2b', listCode, globalId, {
          id: productCode,
        });

        resp = await apim.getProductList('satairb2b', listCode, globalId, {
          fields: 'FULL',
        });
      } else {
        resp = await apim.addOrUpdateProductListEntryOverridingQuantity(
          'satairb2b',
          listCode,
          globalId,
          {
            fields: 'FULL',
            id: productCode,
            quantity: quantity.toString(),
          },
        );
      }

      const mappedList = mapList(resp.data);
      const updatedEntry = mappedList.entries.find((entry) => entry.id === productCode);

      return toLegacyResponse(resp, {
        Data: objectKeysToPascalCaseRecursive(updatedEntry, ['airbusMSSOUsername', 'vatID']),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleAddListEntries({
    listCode,
    productCode,
    quantity,
    isQuoteList,
    enableNotification,
  }: {
    listCode: string;
    productCode: string;
    quantity: number;
    isQuoteList: boolean;
    enableNotification: boolean;
  }) {
    try {
      const globalId = Cookies.get('globalId') || '';

      if (quantity === 0) {
        return this.handleDeleteEntry({ listCode, productCode, enableNotification: false });
      }

      const resp = await apim.addOrUpdateProductListEntry('satairb2b', listCode, globalId, {
        fields: 'FULL',
        id: productCode,
        quantity: quantity.toString(),
      });

      const mappedList = mapList(resp.data);
      const updatedEntry = mappedList.entries.find((entry) => entry.id === productCode);

      const notification = getNotificationMapping({
        title: `${updatedEntry?.product.manufacturerAid}:${updatedEntry?.product?.productManufacturer?.cageCode} added to ${mappedList.name}`,
        type: NotificationTypes.Information,
        notificationAction: isQuoteList
          ? { Name: 'Go to quote draft', Url: `/market/quotes/drafts/${mappedList.code}` }
          : {
              Name: 'Go to list',
              Url: `/market/lists/${mappedList.code}`,
            },
      });

      return toLegacyResponse(resp, {
        Data: objectKeysToPascalCaseRecursive(mappedList, ['airbusMSSOUsername', 'vatID']),
        ...(enableNotification && {
          Notification: [objectKeysToPascalCaseRecursive(notification)],
        }),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleDeleteEntry({
    listCode,
    productCode,
    enableNotification,
  }: {
    listCode: string;
    productCode: string;
    enableNotification: boolean;
  }) {
    try {
      const globalId = Cookies.get('globalId') || '';

      const resp = await apim.deleteProductListEntry('satairb2b', listCode, globalId, {
        id: productCode,
      });

      const listResp = await apim.getProductList('satairb2b', listCode, globalId, {
        fields: 'FULL',
      });

      const mappedList = mapList(listResp.data);

      let notification;

      if (enableNotification) {
        const productResp = await apim.getProductById('satairb2b', productCode, {
          fields: 'FULL',
        });

        notification = getNotificationMapping({
          title: `${productResp.data?.manufacturerAid}:${productResp.data?.manufacturerData?.cageCode} removed from ${mappedList.name}`,
          type: NotificationTypes.Information,
        });
      }

      return toLegacyResponse(resp, {
        Data: objectKeysToPascalCaseRecursive(mappedList, ['airbusMSSOUsername', 'vatID']),
        ...(enableNotification && {
          Notification: [objectKeysToPascalCaseRecursive(notification)],
        }),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleClearUnresolvedEntries({ listCode }: { listCode: string }) {
    try {
      const globalId = Cookies.get('globalId') || '';

      const listResp = await apim.getProductList('satairb2b', listCode, globalId, {
        fields: 'FULL',
      });

      const list = mapList(listResp.data);

      list.unresolvedEntries?.forEach(async (entry) => {
        this.handleClearUnresolvedEntry({
          listCode,
          partNumber: entry.partNumber || '',
          cageCode: entry.cageCode,
        });
      });

      return toLegacyResponse(listResp, {});
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleClearUnresolvedEntry({
    listCode,
    partNumber,
    cageCode,
  }: {
    listCode: string;
    partNumber: string;
    cageCode?: string;
  }) {
    try {
      const globalId = Cookies.get('globalId') || '';

      const resp = await apim.deleteProductListUnresolvedEntry('satairb2b', listCode, globalId, {
        cageCode,
        partNumber,
      });

      return toLegacyResponse(resp, {});
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleSelectProductSuggestion({
    listCode,
    partNumber,
    cageCode,
    productCode,
    quantity,
  }: {
    listCode: string;
    partNumber: string;
    cageCode?: string;
    productCode: string;
    quantity: number;
  }) {
    await this.handleAddListEntries({
      listCode,
      productCode,
      quantity,
      isQuoteList: false,
      enableNotification: false,
    });

    await this.handleClearUnresolvedEntry({
      listCode,
      partNumber,
      cageCode,
    });

    return this.handleGetList(listCode);
  }

  public static async handleProductSuggestions({ partNumber }: { partNumber: string }) {
    try {
      const products = await apim.searchOffers1('satairb2b', {
        pageSize: '10',
        currentPage: '0',
        fields: 'FULL',
        q: partNumber,
      });

      if (partNumber.includes('Unknown Line')) {
        return toLegacyResponse(products, { Data: { SuggestionType: 'Not found' } });
      }

      const sameProductNumber = products.data?.offers?.filter(
        (product) => product.manufacturerAid === partNumber,
      );
      const hasMultipleManufacturers = sameProductNumber && sameProductNumber.length > 1;

      const mappedProducts = products.data?.offers?.map((product) =>
        getProductSearchItemWithMapping(product),
      );
      const mappedSameProductNumber = sameProductNumber?.map((product) =>
        getProductSearchItemWithMapping(product),
      );

      return toLegacyResponse(products, {
        Data: {
          SuggestionType: hasMultipleManufacturers ? 'Multiple manufacturers found' : 'Not found',
          Products: objectKeysToPascalCaseRecursive(
            hasMultipleManufacturers ? mappedSameProductNumber : mappedProducts,
            ['hasFHS'],
          ),
        },
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleRenameList({
    listCode,
    listName,
  }: {
    listCode: string;
    listName: string;
  }) {
    const globalId = Cookies.get('globalId') || '';

    if (!isListNameValid(listName)) {
      listNameInvalidResponse(listName);
    }
    try {
      const resp = await apim.changeProductListName('satairb2b', listCode, globalId, {
        newName: listName,
      });

      return toLegacyResponse(resp, {});
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleAddListToCart({
    listCode,
    cartCode,
  }: {
    listCode: string;
    cartCode: string;
  }) {
    try {
      const globalId = Cookies.get('globalId') || '';

      const listEntries = await apim.getProductList('satairb2b', listCode, globalId, {
        fields: 'FULL',
      });

      const list = listEntries.data;

      const orderEntries = list.entries?.map((entry) => ({
        id: entry.product?.id || entry.offer?.id,
        quantity: entry.quantity,
      }));

      const resp = await apim.addCartEntries(
        'satairb2b',
        cartCode,
        globalId,
        { orderEntries },
        {
          fields: 'FULL',
        },
      );

      const addedCartEntries = mapAddedCartEntries(resp.data);

      let notification;
      let customAction;

      switch (addedCartEntries.failIndicator) {
        case FailIndicators.Partial:
          notification = getNotificationMapping({
            title: `${list.name} added to cart`,
            notificationAction: {
              Url: `/market/cart`,
              Name: 'Go to cart',
            },
            description: `Please note that ${addedCartEntries.failCount} of your products could not be added.`,
            type: NotificationTypes.Warning,
          });
          break;
        case FailIndicators.Full:
          if (
            addedCartEntries?.cartModifications?.[0].statusCode == 'notSellable' ||
            addedCartEntries?.cartModifications?.[0].statusCode == 'NO_PRICE_AVAILABLE_BUT_QUOTABLE'
          ) {
            notification = getNotificationMapping({
              title:
                'This list could not be added to cart, as it consists of products that are unsellable.',
              type: NotificationTypes.Error,
            });
          } else {
            notification = getNotificationMapping({
              title:
                'This list could not be added to cart, as it consists of products that is not available in your region.',
              type: NotificationTypes.Error,
            });
          }
          break;
        case FailIndicators.MaxAllowedQuantityReached:
          notification = getNotificationMapping({
            title: 'Product was not added to cart. Max allowed quantity reached.',
            type: NotificationTypes.Error,
          });
          break;
        case FailIndicators.IncorrectCurrency:
          customAction = {
            actionType: 'TriggerModal',
            modalData: { name: 'ModalCartCurrency', data: 'list' },
          };
          break;
        default:
          notification = getNotificationMapping({
            title: `${list.name} added to cart`,
            notificationAction: {
              Url: `/market/cart`,
              Name: 'Go to cart',
            },
            type: NotificationTypes.Success,
          });
          break;
      }

      return toLegacyResponse(resp, {
        Data: objectKeysToPascalCaseRecursive({ ...addedCartEntries, modificationName: list.name }),
        ...(notification && { Notification: [objectKeysToPascalCaseRecursive(notification)] }),
        ...(customAction && { CustomActions: [objectKeysToPascalCaseRecursive(customAction)] }),
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleMultiLineOrderPasteRaw({
    listCode,
    rawText,
  }: {
    listCode: string;
    rawText: string;
  }) {
    try {
      const globalId = Cookies.get('globalId') || '';
      const maxCount = 20;
      const separators = [' ', '\n\r', '\r', '\n'];
      let list = [rawText];

      // Split the text by each separator and flatten the result
      separators.forEach((separator) => {
        list = list.flatMap((text) => text.split(separator).filter((entry) => entry)); // Removing empty entries
      });

      // Map each entry to the desired format
      const data = list.map((entry) => {
        const [pnr, cage = ''] = entry.includes(':') ? entry.split(':') : [entry, ''];
        return {
          partNumber: pnr,
          cageNumber: cage,
          quantity: '1',
        };
      });

      if (data.length > maxCount) {
        throw {
          error: {
            errors: [
              {
                reason: 'Paste text',
                message: `Text must contain less than ${maxCount} entries. Current number of entries: ${data.length}`,
              },
            ],
          },
        };
      }

      data.unshift({
        partNumber: 'Part Number',
        cageNumber: 'Cage Number',
        quantity: 'Quantity',
      });

      const bytes = writeToCsv(data);
      const model = {
        content: btoa(String.fromCharCode(...bytes)),
        contentType: 'application/vnd.ms-excel',
        name: 'Multiline search',
      };

      const importResp = await apim.importProductList('satairb2b', globalId, model);

      const correlationId = importResp.data.correlationId;

      if (!correlationId || correlationId == null) {
        throw {
          error: {
            errors: [
              {
                reason: 'Paste text',
                message: 'File upload failed',
              },
            ],
          },
        };
      }

      let importedCart;
      const start = Date.now();

      do {
        importedCart = await apim.getImportedProductList('satairb2b', correlationId, globalId, {
          fields: 'FULL',
        });
      } while (
        importedCart.data.importStatus === 'PROCESSING' &&
        (Date.now() - start) / 1000 / 60 < 1
      );

      const importList = mapListImport(importedCart.data, correlationId);

      this.handleDeleteList(listCode);

      return this.handleGetList(importList.listCode || '');
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleMultiLineOrderList({
    listName,
    productListType,
  }: {
    listName: string;
    productListType?: string;
  }) {
    try {
      const globalId = Cookies.get('globalId') || '';

      const searchListResp = await apim.searchProductLists1('satairb2b', globalId, {
        pageSize: 1,
        q: listName,
      });

      const list = searchListResp.data.productLists?.[0];

      if (list) {
        return this.handleGetList(list.code || '');
      }

      return this.handleCreateList(
        {
          params: { listName, productListType: productListType || 'MULTILINE_SEARCH' },
        } as AxiosRequestConfig,
        false,
      );
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleDownloadProductListDocument({ listCode }: { listCode: string }) {
    try {
      const globalId = Cookies.get('globalId') || '';
      const resp = await apim.exportProductListAsExcel('satairb2b', listCode, globalId);

      const fileName = getFileNameFromHeaders(resp.headers) || undefined;

      const processResponse = await toLegacyDownloadResponse(resp, fileName);

      return toLegacyResponse(resp, {
        Data: processResponse,
      });
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }

  public static async handleCreateQuoteFromList(settings: AxiosRequestConfig) {
    try {
      const userId = Cookies.get('globalId') || '';
      const createQuote = objectKeysToCamelCaseRecursive(settings.data.createQuote);

      if (createQuote.requestedDate === '') {
        delete createQuote['requestedDate'];
      }

      const resp = await apim.validate('satairb2b', userId, createQuote.quoteItems);

      if (resp.data.listValid) {
        const quote = await apim.createSatairQuote('satairb2b', userId, createQuote);

        const notification = getNotificationMapping({
          title: 'Your quote request was submitted.',
          notificationAction: {
            Name: 'Follow your request',
            Url: `/market/quotes/${quote.data.code}`,
          },
          type: NotificationTypes.Success,
        });

        const result = {
          data: 'Ok',
          notification: [notification],
        };

        return toLegacyResponse(quote, objectKeysToPascalCaseRecursive(result));
      }

      const notification = getNotificationMapping({
        title: 'Your quote request failed.',
        type: NotificationTypes.Error,
      });

      const result = {
        data: 'Failed',
        notification: [notification],
      };

      return toLegacyResponse(resp, objectKeysToPascalCaseRecursive(result));
    } catch (error) {
      return legacyErrorHandler(error);
    }
  }
}
