import { Offer } from '@/src/core/apim';
import useText from '@/src/core/hooks/useText';
import { DateFormat } from '@/src/core/utils/dates';
import { ConvertToPascalCase } from '@/src/core/utils/object';
import { calculateMaxInt } from '@/src/market/services/legacy-mapping';
import { getProductWithMapping } from '@/src/market/services/legacy-mapping-product';

/**
 * Retrieves offer data details of the offer.
 *
 * @param {Offer} offer - A SAP CC offer.
 * @returns {Array<object>} - An array of offer data attributes.
 */
export const getOfferData = (offer: Offer) => {
  const textK = useText();
  const noDataText = textK('UI_PRODUCTPAGE_OFFERDETAILS_NODATA') || 'No information available';
  const offerData = [];

  if (offer.state) {
    offerData.push({
      Name: 'Part condition:',
      Value: offer.state ?? noDataText,
      TestTag: 'condition',
    });
  }

  if (typeof offer.dateOfManufacture !== 'undefined') {
    offerData.push({
      Name: 'Manufacture date:',
      Value: offer.dateOfManufacture ? DateFormat(offer.dateOfManufacture) : noDataText,
      TestTag: 'manuDate',
    });
  }

  if (typeof offer.visibleWear !== 'undefined') {
    offerData.push({
      Name: 'Visible wear and tear:',
      Value: offer.visibleWear ? 'Yes' : 'No',
      TestTag: 'wear',
    });
  }

  if (offer.lastMaintenanceOrganisation) {
    offerData.push({
      Name: 'Last maintenance organisation:',
      Value: offer.lastMaintenanceOrganisation,
      TestTag: 'mainOrg',
    });
  }

  if (Number(offer.timeRemaining) > 0) {
    offerData.push({
      Name: 'Flying hours remaining:',
      Value: String(offer.timeRemaining),
      TestTag: 'timeRemaining',
    });
  }

  if (Number(offer.cyclesRemaining) > 0) {
    offerData.push({
      Name: 'Flying cycles remaining:',
      Value: String(offer.cyclesRemaining),
      TestTag: 'cyclesRemaining',
    });
  }

  if (Number(offer.timeSinceNew) > 0) {
    offerData.push({
      Nam: 'Flying hours since new:',
      Value: String(offer.timeSinceNew),
      TestTag: 'timeSinceNew',
    });
  }

  if (Number(offer.cyclesSinceNew) > 0) {
    offerData.push({
      Name: 'Flying cycles since new:',
      Value: String(offer.cyclesSinceNew),
      TestTag: 'cyclesSinceNew',
    });
  }

  if (typeof offer.lastArcIssueDate !== 'undefined') {
    offerData.push({
      Name: 'Last ARC issue date:',
      Value: offer.lastArcIssueDate ? DateFormat(offer.lastArcIssueDate) : noDataText,
      TestTag: 'arc',
    });
  }

  if (offer.warrantyType) {
    offerData.push({
      Name: 'Warranty:',
      Value: offer.warrantyType,
      TestTag: 'warrantyType',
    });
  }

  if (typeof offer.warrantyExpirationDate !== 'undefined') {
    offerData.push({
      Name: 'Warranty expiration date:',
      Value: offer.warrantyExpirationDate ? DateFormat(offer.warrantyExpirationDate) : noDataText,
      TestTag: 'warrantyExp',
    });
  }

  if (typeof offer.expirationDate !== 'undefined') {
    offerData.push({
      Name: 'Expiry date:',
      Value: offer.expirationDate ? DateFormat(offer.expirationDate) : noDataText,
      TestTag: 'ExpirationExp',
    });
  }

  if (offer.traceType) {
    offerData.push({
      Name: 'Trace type:',
      Value: offer.traceType,
      TestTag: 'traceType',
    });
  }

  if (offer.serialNumber) {
    offerData.push({
      Name: 'Serial number:',
      Value: offer.serialNumber,
      TestTag: 'serialnumber',
    });
  }

  return offerData;
};

/**
 * Maps a SAP CC offer to an expanded version of the offer, including the PDP details
 *
 * @param {Offer} offer - A SAP CC offer.
 * @returns {OfferWithMapping} - An expanded version of the offer.
 */
export const getOfferWithMapping = (offer: Offer) => {
  if (!offer.product) {
    return;
  }

  const maxInt = Math.max(offer.minOrderQuantity ?? 0, offer.packageQuantity ?? 0);

  const packageQuantity = offer.packageQuantity ?? 1;

  const finalMaxInt =
    maxInt % packageQuantity === 0 ? maxInt : calculateMaxInt(maxInt, packageQuantity);

  const addToBasketQty = packageQuantity < finalMaxInt ? finalMaxInt : packageQuantity;
  const cartStep = packageQuantity;

  const images = [
    offer.mainPicture,
    offer.additionalPicture1,
    offer.additionalPicture2,
    offer.additionalPicture3,
  ]
    .filter(Boolean)
    .map((imageUrl) => ({ url: imageUrl!.replace('http://', '//') }));

  const hasImage = images.length > 0;

  const documents = [
    { link: offer.document1Link, description: offer.document1Description },
    { link: offer.document2Link, description: offer.document2Description },
    { link: offer.document3Link, description: offer.document3Description },
    { link: offer.document4Link, description: offer.document4Description },
  ].filter((doc) => doc.link && doc.description);

  const documentLinks = documents.map((doc) => doc.link!.replace('http://', 'https://'));
  const documentNames = documents.map((doc) => doc.description);

  const productWithMapping = getProductWithMapping({
    ...offer.product,
    id: offer.id,
  });

  const mappedProduct = {
    ...productWithMapping,

    owningSystem: productWithMapping?.owningSystem, // this is only needed for the types

    offerData: getOfferData(offer),

    packageQuantity,
    addToBasketQty,
    cartStep,
    supplierDisplay: offer.shop?.name ?? 'Satair',
    allowQuoteRequests: offer.allowQuoteRequests,
    priceHidden: offer.priceHidden,
    images,
    hasImage,
  };

  const mappedShop = offer.shop
    ? {
        ...offer.shop,
        masterShop: {
          ...offer.shop.masterShop,
          id: offer.shop.masterShop?.id,
          name: offer.shop.masterShop?.name,
          paymentTerms: offer.shop.masterShop?.paymentTerms,
        },
      }
    : null;

  return {
    ...offer,
    masterShopName: offer.shop?.masterShop?.name ?? 'Satair',
    shopId: offer.shop?.masterShop?.id,
    supplierLogo: offer.newSearchResultLogo ?? '', // ! TODO: ADD MISSING CDN
    isAsmHidden: offer.infoStatus === 'ASM_HIDDEN',
    inactive: offer?.product?.productRule?.sellable !== 'YES',
    product: mappedProduct,
    shop: {
      ...mappedShop,
    },
    documents,
    documentsNames: documentNames,
    documentsLinks: documentLinks,
    cyclesRemaining: offer.cyclesRemaining || 0,
    cyclesSinceNew: offer.cyclesSinceNew || 0,
    timeRemaining: offer.timeRemaining || 0,
    timeSinceNew: offer.timeSinceNew || 0,

    // properties below are static and should be removed
    flammable: false,
    infoStatus: '',
  };
};

export const legacyEmptyOffer = {
  allowQuoteRequests: false,
  priceHidden: false,
  infoStatus: '',
  isAsmHidden: false,
  documents: [],
  documentsLinks: [],
  documentsNames: [],
  cyclesRemaining: 0,
  cyclesSinceNew: 0,
  timeRemaining: 0,
  timeSinceNew: 0,
  inactive: false,
  quantity: 0,
  flammable: false,
  supplierLogo: '',
};

export type OfferWithMapping = Exclude<ReturnType<typeof getOfferWithMapping>, undefined>;
export type LegacyOffer = ConvertToPascalCase<OfferWithMapping>;
