<template>
  <StandardDialogModal :size="uiSize.Medium">
    <template #header>
      <h3>{{ textK('UI_CART_MOV_LABEL') }}</h3>
    </template>
    <template #body>
      <div class="minimum-order-line minimum-order-line__line-one">
        <span class="minimum-order-line-text">{{ textK('UI_CART_MOV_TEXT_LINE_ONE') }}</span
        ><br />
      </div>
      <div
        v-for="(seller, i) in sellersGroupMOValues"
        :key="i"
        class="minimum-order-line"
        v-ui-test="'mov-seller'"
      >
        <span class="minimum-order-line-text">{{ seller }}</span>
      </div>
      <div class="minimum-order-line">
        <br />
        <span class="minimum-order-line-text">
          {{
            textK('UI_CART_MOV_TEXT_LINE_TWO').replace(
              /\[\[seller\]\]/gi,
              sellersGroupMOVNames || '',
            )
          }}
        </span>
      </div>
    </template>
    <template #actions>
      <VButton
        :type="uiVariant.Secondary"
        v-ui-test="'cartchanged-tocart-button'"
        @click="continueToCheckout"
        >{{ textK('UI_CART_MOV_SECONDARY_BUTTON_PROCEED') }}</VButton
      >
      <VButton
        :type="uiVariant.Default"
        v-ui-test="'cartchanged-continue-button'"
        @click="closeModal"
        >{{ textK('UI_CART_MOV_PRIMARY_BUTTON_EDIT_CART') }}</VButton
      >
    </template>
  </StandardDialogModal>
</template>

<style lang="scss" src="./modal-minim-order-value.scss" scoped></style>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { SellerGroup } from '@/src/core/types/api';
import { useCheckoutStore } from '@/src/market/stores/checkout';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

export interface IModalMinimOrderValueParam {
  sellersGroupMOV: SellerGroup[];
}

const router = useRouter();
const textK = useText();
const modalStore = useModalStore();
const checkoutStore = useCheckoutStore();
const uiSize = UiSize;
const uiVariant = UiVariant;

const params = computed(() => modalStore.params as IModalMinimOrderValueParam);

const sellersGroupMOVNotValid = computed(() => params.value?.sellersGroupMOV);

const sellersGroupMOVNames = computed(() => {
  const length = sellersGroupMOVNotValid.value.length;

  if (length === 0) {
    return null;
  }

  if (length < 3) {
    return sellersGroupMOVNotValid.value.map(({ Name }) => `${Name}'s`).join(' and ');
  }

  const lastIndex = length - 1;

  return sellersGroupMOVNotValid.value
    .map(({ Name }, index) =>
      index === lastIndex ? ` and ${Name}'s` : index === lastIndex - 1 ? `${Name}'s` : `${Name}'s,`,
    )
    .join(' ');
});

const sellersGroupMOValues = computed(() => {
  const sellersGroupMOValues: string[] = [];
  sellersGroupMOVNotValid.value.forEach((element) => {
    sellersGroupMOValues.push(
      element.Name + ' (' + element.MinOrderValue + ' ' + element.Price.CurrencyIso + ')',
    );
  });
  return sellersGroupMOValues;
});

const closeModal = (e: Event) => {
  e.preventDefault();
  modalStore.closeModal();
};

const continueToCheckout = (e: Event) => {
  e.preventDefault();
  modalStore.closeModal();
  router.replace({ name: 'checkout' });
  checkoutStore.setIsUrl({ isUrl: false });
};
</script>
