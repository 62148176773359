import { ProcurementType } from '@/src/core/settings/procurement-types';

export const serviceReferenceCheck = (
  procurementType?: string,
  isTool = false,
  airbusProprietary = false,
) => {
  if (!procurementType) {
    return false;
  }

  const isProcurementWithServiceDescriptions = [
    ProcurementType.Lease,
    ProcurementType.Repair,
    ProcurementType.Exchange,
    ProcurementType.ExchangeNew,
    ProcurementType.ExchangeUsed,
  ].includes(procurementType.toUpperCase() as ProcurementType);

  return isProcurementWithServiceDescriptions && (isTool || airbusProprietary);
};
