import { ISCrollLock } from '@/src/core/types/ui';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useScrollLockStore = defineStore('scroll-lock', () => {
  const lockers = ref<ISCrollLock[]>([]);

  const isLocked = computed(() => {
    return lockers.value.length > 0;
  });

  const scrollLockUpdated = (payload: { id: string }) => {
    if (lockers.value.find((item) => item.id === payload.id)) {
      return;
    }

    lockers.value.push({
      id: payload.id,
    });
  };

  const scrollLockRemove = (payload?: { id?: string }) => {
    if (payload?.id) {
      const foundIndex = lockers.value.findIndex((item) => item.id === payload.id);
      // Only remove, if the ID matches something in the array
      if (foundIndex > -1) {
        lockers.value.splice(
          lockers.value.findIndex((item) => item.id === payload.id),
          1,
        );
      }
    } else {
      lockers.value.splice(0, 1);
    }
  };

  const reset = () => {
    lockers.value = [];
  };

  const scrollLock = async (payload: { id: string }) => {
    scrollLockUpdated(payload);
  };

  const removeScrollLock = async (payload?: { id?: string }) => {
    scrollLockRemove(payload);
  };

  const isLocking = (payload: { id: string }) => {
    return !!lockers.value.findIndex((item) => item.id === payload.id);
  };

  return {
    removeScrollLock,
    scrollLock,
    isLocking,
    reset,
    isLocked,
  };
});
