<template>
  <div class="invoice-filter-modal">
    <form>
      <StandardDialogModal :size="uiSize.Medium" :has-cancel-button="false">
        <template #header>
          <h3>
            {{ textK('UI_DOCUMENTS_MULTILINE_QUERY_TITLE') }}
          </h3>
        </template>
        <template #body>
          <VTextarea
            ref="textarea"
            :type="uiVariant.Secondary"
            :label="params.title"
            :model-value="value"
            @update:modelValue="localValue = $event"
          />
        </template>
        <template #actions>
          <VButton :type="uiVariant.Default" @click.prevent="setValue">{{
            textK('UI_DOCUMENTS_MULTILINE_QUERY_ACTION')
          }}</VButton>
        </template>
      </StandardDialogModal>
    </form>
  </div>
</template>

<script lang="ts">
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { FilterStringArrayUtils } from '@/src/core/utils/string-array';
import { useDocumentsStore } from '@/src/finance/stores/documents';
import { Component, Ref, Vue, toNative } from 'vue-facing-decorator';

@Component({
  components: {
    VButton,
    VTextarea,
    StandardDialogModal,
  },
})
export class ModalMultilineQuery extends Vue {
  public textK = useText();
  public modalStore = useModalStore();
  public documentsStore = useDocumentsStore();
  public uiVariant = UiVariant;
  public uiSize = UiSize;
  public localValue: string = '';

  @Ref() readonly textarea!: InstanceType<typeof VTextarea>;

  public get params(): IMultilineQuery {
    return this.modalStore.params as IMultilineQuery;
  }

  public get value() {
    return FilterStringArrayUtils.filterArrayToString(
      this.documentsStore.filters[this.params.value],
      '\n',
    );
  }

  public setValue() {
    this.documentsStore.setFilters({
      [this.params.value]: FilterStringArrayUtils.stringToFilterArray(
        this.localValue.replace(/([ ,.])/g, '\n'),
        '\n',
      ),
    });

    if (this.params.input) {
      this.params.input(this.localValue);
    }

    this.modalStore.closeModal();
  }
}

interface IMultilineQuery {
  value: string;
  input: (value: string) => void;
  title: string;
}

export default toNative(ModalMultilineQuery);
</script>
