// tslint:disable
import { getEnv } from '@/src/core/services/environment';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class MessagingApi {
  public static GetMessages = (offerId: string) => `${baseUrl}/api/messages/offers/${offerId}`;
  public static GetOrderMessages = (orderCode: string, consignmentCode: string) =>
    `${baseUrl}/api/messages/orders/${orderCode}/${consignmentCode}`;
  public static GetQuoteMessages = (quoteCode: string) =>
    `${baseUrl}/api/messages/quotes/${quoteCode}`;
  public static PostMessage = (offerId: string) => `${baseUrl}/api/messages/offers/${offerId}/post`;
  public static PostOrderMessage = (orderCode: string, consignmentCode: string) =>
    `${baseUrl}/api/messages/orders/${orderCode}/${consignmentCode}/post`;
  public static PostQuoteMessage = (quoteCode: string, miraklQuoteRequestId: string) =>
    `${baseUrl}/api/messages/quotes/${quoteCode}/${miraklQuoteRequestId}/post`;

  //Download
  //Api.ts interface: MarketplaceMessageAttachmentInfo attachmentInfo {Id: string, Name: string, Size: string}
  public static DownloadOfferMessageDocument = (offerId: string) =>
    `${baseUrl}/api/messages/offers/${offerId}/download`;
  public static DownloadOrderMessageDocument = (
    orderCode: string,
    threadId: string,
    consignmentCode: string,
  ) => `${baseUrl}/api/messages/orders/${orderCode}/${consignmentCode}/${threadId}/download`;
  public static DownloadQuoteMessageDocument = (
    quoteCode: string,
    miraklQuoteRequestId: string,
    messageId: string,
  ) => `${baseUrl}/api/messages/quotes/${quoteCode}/${miraklQuoteRequestId}/${messageId}/download`;
}
