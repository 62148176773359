<template>
  <VSimpleTableRow v-if="isAirbus && approvedSuppliers">
    <VSimpleTableCell
      class="product-details-cell--index"
      :label="'cell-label'"
      :style="{ width: '50%' }"
    >
      <span> Approved suppliers: </span>
    </VSimpleTableCell>
    <VSimpleTableCell class="align-right" :label="'cell-value'" :style="{ width: '50%' }">
      {{ approvedSuppliers }}
    </VSimpleTableCell>
  </VSimpleTableRow>
</template>

<script lang="ts" setup>
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import { isAirbusProduct } from '@/src/market/services/product-parts';
import { useAirbusPartStore } from '@/src/market/stores/airbus-part';
import { computed } from 'vue';

interface Props {
  productId: string;
  owningSystem: string;
}

const props = defineProps<Props>();

const airbusPartStore = useAirbusPartStore();

const isAirbus = computed(() => isAirbusProduct(props.owningSystem));

const approvedSuppliers = computed(() => {
  if (isAirbus.value && props.productId) {
    const airbusPartRequest = airbusPartStore.airbusPartByOfferId(props.productId);

    if (airbusPartRequest) {
      return airbusPartRequest?.approvedSuppliers?.length > 0
        ? airbusPartRequest.approvedSuppliers.map((values) => values.cageCode).join(', ')
        : '';
    }
  }
  return '';
});
</script>
