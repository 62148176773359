import { IAdobe, IAdobeTargetOffer, IWindowAdobe } from '@/src/core/types/adobe';

const timeout: number = 3000;
let timer: number;

function GetAdobe() {
  return new Promise<{ Adobe: IAdobe }>((resolve, reject) => {
    if (
      (window as unknown as IWindowAdobe).adobe &&
      (window as unknown as IWindowAdobe).adobe.target
    ) {
      resolve({ Adobe: (window as unknown as IWindowAdobe).adobe });
    } else {
      document.addEventListener('at-library-loaded', () => {
        resolve({ Adobe: (window as unknown as IWindowAdobe).adobe });
        clearTimeout(timer);
      });

      timer = window.setTimeout(() => {
        reject();
      }, timeout);
    }
  });
}

function ApplyOffer(id: string) {
  return new Promise<{ IsSuccess?: boolean; Offers?: IAdobeTargetOffer[] }>((resolve) => {
    GetAdobe()
      .then((response) => {
        response.Adobe.target.getOffer({
          mbox: id,
          success: (offers: IAdobeTargetOffer[]) => {
            resolve({ IsSuccess: true, Offers: offers });
          },
          error: () => {
            resolve({ IsSuccess: false });
          },
          timeout,
        });
      })
      .catch(() => {
        resolve({ IsSuccess: false });
      });
  });
}

export { ApplyOffer, GetAdobe };
