<template>
  <div class="facet-filter-group">
    <div class="facet-filter-group__wrapper">
      <transition-group name="move-transition" class="facet-filter__items" tag="div">
        <template v-for="facetItem in facetValues" :key="facetItem.Name">
          <SearchResultFacetItem :facet-item="facetItem" :display-name="facet.DisplayName" />
        </template>
      </transition-group>
    </div>
    <a
      v-if="facet.Values.length > 8 && !isExpanded"
      class="facet-filter__show-all"
      v-ui-test="'facet-filter-show-all'"
      @click="expand()"
      >{{ textK('UI_COMMON_SHOWMORE') }} ({{ facet.Values.length - 8 }})</a
    >
    <a
      v-if="isExpanded"
      class="facet-filter__show-all"
      v-ui-test="'facet-filter-show-all'"
      @click="collapse"
      >{{ textK('UI_COMMON_SHOWLESS') }}</a
    >
  </div>
</template>

<style scoped lang="scss" src="./search-result-facet-group.scss"></style>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import useText from '@/src/core/hooks/useText';
import { Facets } from '@/src/core/types/api';
import SearchResultFacetItem from '@/src/market/components/search/search-results-facets/search-result-facet-item/search-result-facet-item.vue';

interface Props {
  facet: Facets;
}

const props = defineProps<Props>();
const facet = computed(() => props.facet);
const textK = useText();
const isExpanded = ref<boolean>(false);

const facetValues = computed(() => {
  if (!isExpanded.value) {
    return facet.value.Values.slice(0, 8);
  } else {
    return facet.value.Values;
  }
});

const expand = () => {
  isExpanded.value = true;
};

const collapse = () => {
  isExpanded.value = false;
};
</script>
