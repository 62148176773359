import { ProductApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { Product } from '@/src/core/types/api';
import { IDahSuggestionItem, IProductRelationEntry } from '@/src/core/types/interfaces';
import { getProductOfferAsync, getProductSkuAsync } from '@/src/market/services/productservice';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useProductStore } from '../stores/product';

const expireTime: number = 300000;

export const useProductRelationsStore = defineStore('product-relations', () => {
  const relations = ref<IProductRelationEntry[]>([]);

  const relationsUpdate = (payload: { relation: IProductRelationEntry }) => {
    const relationIndex = relations.value.findIndex((x) => x.Sku === payload.relation.Sku);

    if (relationIndex >= 0) {
      relations.value.splice(
        relationIndex,
        1,
        Object.assign({}, relations.value[relationIndex], payload.relation),
      );
    } else {
      relations.value.push(payload.relation);
    }
  };

  const recommendationProductUpdate = (payload: { sku: string; product?: Product }) => {
    const relation = relations.value.find((x) => x.Sku === payload.sku);
    const productSku = payload.product && payload.product.Sku;
    let index: number | undefined;

    if (payload.product && relation && relation.Recommendations) {
      index = relation.Recommendations.findIndex((x) => x.Sku === productSku);
    }

    if (index !== undefined && relation && relation.Recommendations) {
      const recommendation = relation.Recommendations[index];
      relation.Recommendations.splice(
        index,
        1,
        Object.assign({}, recommendation, { Product: payload.product }),
      );
      relation.Timestamp = Date.now();
    }
  };

  const interchangeableProductUpdate = (payload: { sku: string; product?: Product }) => {
    const relation = relations.value.find((x) => x.Sku === payload.sku);
    const productSku = payload.product && payload.product.Sku;
    let index: number | undefined;

    if (payload.product && relation && relation.Interchangeables) {
      index = relation.Interchangeables.findIndex((x) => x.Sku === productSku);
    }

    if (index !== undefined && relation && relation.Interchangeables) {
      const interchangeable = relation.Interchangeables[index];
      relation.Interchangeables.splice(
        index,
        1,
        Object.assign({}, interchangeable, { Product: payload.product }),
      );
      relation.Timestamp = Date.now();
    }
  };

  const interchangeableProductRemoved = (payload: { sku: string; relationSku: string }) => {
    const relation = relations.value.find((x) => x.Sku === payload.sku);
    const interchangeables = (relation && relation.Interchangeables) || [];
    const relationIndex = interchangeables.findIndex((x) => x.Sku === payload.relationSku);

    if (relation && relation.Interchangeables) {
      relation.Interchangeables.splice(relationIndex, 1);
    }
  };

  const recommendationProductRemoved = (payload: { sku: string; relationSku: string }) => {
    const relation = relations.value.find((x) => x.Sku === payload.sku);
    const recommendations = (relation && relation.Recommendations) || [];
    const relationIndex = recommendations.findIndex((x) => x.Sku === payload.relationSku);

    if (relation && relation.Recommendations) {
      relation.Recommendations.splice(relationIndex, 1);
    }
  };

  const reset = () => {
    relations.value = [];
  };

  const prefetchRecommendations = async (payload: { sku: string }) => {
    const { Data, IsSuccess } = await Req({
      url: ProductApi.ProductRecommendations(),
      params: { sku: encodeURIComponent(payload.sku) },
    });

    if (IsSuccess && Data) {
      relationsUpdate({
        relation: {
          Timestamp: Date.now(),
          Sku: payload.sku,
          IsRecommendationsChecked: true,
          Recommendations: Data.Items as IDahSuggestionItem[],
        },
      });
    } else {
      relationsUpdate({
        relation: {
          Timestamp: Date.now(),
          Sku: payload.sku,
          IsRecommendationsChecked: true,
        },
      });
    }
    return { IsSuccess };
  };

  const fetchInterchangeables = async (payload: {
    sku: string;
    relationSku?: string;
    relationId?: string;
  }) => {
    const productStore = useProductStore();
    let product;

    if (payload.relationId) {
      const offer = await getProductOfferAsync(payload.relationId);
      product = offer.Product;
    } else if (payload.relationSku) {
      product = await getProductSkuAsync(payload.relationSku);
    }

    if (product) {
      interchangeableProductUpdate({
        sku: payload.sku,
      });
      productStore.addProduct({ product });
    } else {
      if (payload.relationSku) {
        interchangeableProductRemoved({ sku: payload.sku, relationSku: payload.relationSku });
      }
    }
  };

  const fetchRecommendations = async (payload: { sku: string; relationSku: string }) => {
    const productStore = useProductStore();
    const product = await getProductSkuAsync(payload.relationSku);

    if (product) {
      recommendationProductUpdate({
        sku: payload.sku,
      });
      productStore.addProduct({ product });
    } else {
      recommendationProductRemoved({ sku: payload.sku, relationSku: payload.relationSku });
    }
  };

  const relationBySku = (sku: string) => {
    return relations.value.find((x) => x.Sku === sku);
  };

  const interchangeablesBySku = (sku: string) => {
    return relationBySku(sku)?.Interchangeables || [];
  };

  const recommendationsBySku = (sku: string) => {
    return relationBySku(sku)?.Recommendations || [];
  };

  const isRelationValid = (sku: string) => {
    const item = relationBySku(sku);
    return (item && Date.now() - item.Timestamp < expireTime) || false;
  };

  const isRecommendationsChecked = (sku: string) => {
    return relationBySku(sku)?.IsRecommendationsChecked || false;
  };

  return {
    relations,
    reset,
    prefetchRecommendations,
    fetchInterchangeables,
    fetchRecommendations,
    relationBySku,
    interchangeablesBySku,
    recommendationsBySku,
    isRelationValid,
    isRecommendationsChecked,
  };
});
