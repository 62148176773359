<template>
  <form @submit.prevent="closeModal" class="cartchanged-modal">
    <StandardDialogModal
      :locked-modal="true"
      :has-cancel-button="false"
      :has-close-button="false"
      :size="uiSize.Medium"
    >
      <template #header>
        <div class="typeset">
          <h3>
            {{
              isSearch
                ? textK('UI_CART_CARTCURRENCY_SEARCH_TITLE')
                : isList
                ? textK('UI_CART_CARTCURRENCY_LIST_TITLE')
                : textK('UI_CART_CARTCURRENCY_ORDER_TITLE')
            }}
          </h3>
        </div>
      </template>
      <template #body>
        <div class="inner-body">
          {{
            isSearch
              ? textK('UI_CART_CARTCURRENCY_SEARCH_BODY')
              : isList
              ? textK('UI_CART_CARTCURRENCY_LIST_BODY')
              : textK('UI_CART_CARTCURRENCY_ORDER_BODY')
          }}
        </div>
        <br />
        <div class="inner-body">
          {{
            isSearch
              ? textK('UI_CART_CARTCURRENCY_SEARCH_BODY_EXTENDED')
              : isList
              ? textK('UI_CART_CARTCURRENCY_LIST_BODY_EXTENDED')
              : textK('UI_CART_CARTCURRENCY_ORDER_BODY_EXTENDED')
          }}
        </div>
      </template>
      <template #actions>
        <VButton :type="uiVariant.Default" v-ui-test="'cartcurrency-ok-button'">{{
          textK('UI_COMMON_OK')
        }}</VButton>
      </template>
    </StandardDialogModal>
  </form>
</template>

<style lang="scss" src="./ModalCartCurrency.scss" scoped></style>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { onMounted, ref } from 'vue';

const textK = useText();
const modalStore = useModalStore();

const uiSize = UiSize;
const uiVariant = UiVariant;
const isSearch = ref(false);
const isOrder = ref(false);
const isList = ref(false);

const closeModal = async () => {
  modalStore.closeModal();
};

onMounted(() => {
  if (modalStore.params === 'search') {
    isSearch.value = true;
  }
  if (modalStore.params === 'list') {
    isList.value = true;
  }
  if (modalStore.params === 'order') {
    isOrder.value = true;
  }
});
</script>
