<template>
  <div class="modal-seller-conversation typeset" v-ui-test="'seller-conversation-modal'">
    <StandardDialogContainer :size="uiSize.Large" :loading="isBusy">
      <div class="modal-seller-conversation__inner" ref="scrollcontainer">
        <SellerOrderConversationHeader
          v-if="messages"
          :quote-id="quoteCode"
          :quote-ref="quoteRef"
          :is-quote="true"
          :master-shop-name="masterShopName"
          :location-display-name="locationDisplayName"
          :is-busy="isBusy"
        />
        <div class="advanced-modal__body">
          <SellerConversation
            class="modal-seller-conversation__thread"
            :mirakl-quote-request-id="miraklQuoteRequestId"
            :quote-code="quoteCode"
            @beforeLoad="beforeProductUpdate"
            @afterLoad="onProductUpdate"
          />
          <SellerConversationMessaging
            v-if="!isMessagingDisabled"
            ref="sellerConversationMessaging"
            class="modal-seller-conversation__messaging"
            :class="{ 'no-messages': messagesCount <= 0 }"
            :mirakl-quote-request-id="miraklQuoteRequestId"
            :thread="messages"
          >
          </SellerConversationMessaging>
          <SellerConversationMessagingDisabled v-else />
        </div>
      </div>
    </StandardDialogContainer>
  </div>
</template>

<style lang="scss" src="./modal-seller-quote-conversation.scss" scoped />

<script lang="ts" setup>
import StandardDialogContainer from '@/src/core/components/modals/ui/standard-dialog-layout/standard-dialog-container.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import { useModalStore } from '@/src/core/stores/modal';
import { QuoteDetail } from '@/src/core/types/api';
import SellerConversationMessagingDisabled from '@/src/market/components/seller/seller-conversation/seller-conversation-messaging-disabled/seller-conversation-messaging-disabled.vue';
import SellerConversationMessaging from '@/src/market/components/seller/seller-conversation/seller-conversation-messaging/seller-conversation-messaging.vue';
import SellerConversation from '@/src/market/components/seller/seller-conversation/seller-conversation.vue';
import SellerOrderConversationHeader from '@/src/market/components/seller/seller-conversation/seller-order-conversation-header/seller-order-conversation-header.vue';
import { useQuotesStore } from '@/src/market/stores/quotes';
import { useSellerQuoteConversationStore } from '@/src/market/stores/seller-quote-conversation';
import { computed, nextTick, onMounted, ref } from 'vue';

interface IModalSellerQuoteConversationParams {
  id?: string;
  quote?: QuoteDetail;
  State: string;
}

const modalStore = useModalStore();
const quotesStore = useQuotesStore();
const sellerQuoteConversationStore = useSellerQuoteConversationStore();
const uiSize = UiSize;
const oldContainerHeight = ref<number | undefined>();
const oldScrollTop = ref<number | undefined>();
const scrollcontainer = ref<HTMLElement | undefined>();
const sellerConversationMessaging = ref<
  InstanceType<typeof SellerConversationMessaging> | undefined
>();

const params = computed(() => {
  return modalStore.params as IModalSellerQuoteConversationParams;
});

const messagesCount = computed(() => {
  return sellerQuoteConversationStore.messages.length;
});

const messages = computed(() => {
  return sellerQuoteConversationStore.messages;
});

const seller = computed(() => {
  return quotesStore.currentQuoteView.SellerGroup;
});

const masterShopName = computed(() => {
  return quotesStore.currentQuoteView.MasterShopName;
});

const locationDisplayName = computed(() => {
  return quotesStore.currentQuoteView.LocationDisplayName;
});

const miraklQuoteRequestId = computed(() => {
  return quotesStore.currentQuoteView.MiraklQuoteRequestId;
});

const quoteCode = computed(() => {
  return quotesStore.currentQuoteView.Code;
});

const quoteRef = computed(() => {
  return (
    quotesStore.currentQuoteView.PurchaseOrderNumber || quotesStore.currentQuoteView.ReferenceNumber
  );
});

const quoteState = computed(() => {
  return params.value.State;
});

const isMessagingDisabled = computed(() => {
  const isQuoteAccepted = quoteState.value === 'ACCEPTED';
  const isQuoteExpired = quoteState.value === 'EXPIRED';
  const isQuoteCancelled = quoteState.value === 'CANCELLED';
  return isQuoteAccepted || isQuoteExpired || isQuoteCancelled;
});

const isBusy = computed(() => {
  return sellerQuoteConversationStore.isBusy || quotesStore.isBusyDetails;
});

const beforeProductUpdate = () => {
  if (scrollcontainer.value) {
    oldContainerHeight.value = (scrollcontainer.value as any)?.scrollHeight;
    oldScrollTop.value = (scrollcontainer.value as any)?.scrollTop;
  }
};

const onProductUpdate = () => {
  if (scrollcontainer.value) {
    if (
      oldContainerHeight.value &&
      oldContainerHeight.value >= 0 &&
      oldScrollTop.value &&
      oldScrollTop.value >= 0
    ) {
      (scrollcontainer.value as any).scrollTop =
        oldScrollTop.value +
        ((scrollcontainer.value as any)?.scrollHeight - oldContainerHeight.value);
    } else {
      (scrollcontainer.value as any).scrollTop = (scrollcontainer.value as any)?.scrollHeight;
    }
  }
  if (messagesCount.value <= 0 && !isBusy.value && sellerConversationMessaging.value) {
    nextTick(() => {
      (sellerConversationMessaging.value as any).setFocus();
    });
  }
};

onMounted(async () => {
  if (params.value.id) {
    quotesStore.fetchQuoteDetails({ quoteCode: params.value.id });
    await sellerQuoteConversationStore.getMessages({ quoteCode: params.value.id });
    onProductUpdate();
  } else {
    await sellerQuoteConversationStore.getMessages({
      quoteCode: quotesStore.currentQuoteView.Code,
    });
    onProductUpdate();
  }
});
</script>
