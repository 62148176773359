<template>
  <div class="seller-conversation-wrapper">
    <div class="seller-conversation-indication">
      <UserAccountBadge
        class="seller-conversation-indication__dot"
        :user-name="author"
        :type="authorType"
      />
    </div>
    <div class="seller-conversation-content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" src="./seller-conversation-indication.scss" scoped />

<script lang="ts">
import { Vue, Component, Prop, toNative } from 'vue-facing-decorator';
import UserAccountBadge from '@/src/core/components/ui/user-account-badge/user-account-badge.vue';
import { UserIndicationTypes } from '@/src/core/types/ui';

@Component({
  components: {
    UserAccountBadge,
  },
})
export class SellerConversationIndication extends Vue {
  @Prop() public author: string;
  @Prop() public authorType: UserIndicationTypes;
}

export default toNative(SellerConversationIndication);
</script>
