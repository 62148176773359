<template>
  <div
    class="image-box-module-wrapper"
    :class="{ hovered: hasLink && hover, 'has-link': hasLink }"
    @mouseover="mouseOver"
    @mouseout="mouseOut"
  >
    <slot :hovered="hover" />
  </div>
</template>

<style lang="scss" src="./image-box-module.scss"></style>

<script lang="ts">
import { Vue, Component, Prop, toNative } from 'vue-facing-decorator';
import ArrowIcon from '@/src/core/components/ui/ani-icons/arrow/arrow-icon.vue';

@Component({
  components: {
    ArrowIcon,
  },
})
export class ContentImageBoxModule extends Vue {
  @Prop({ default: false }) public hasLink: boolean;
  public hover: boolean = false;

  public mouseOver() {
    this.hover = true;
    this.$emit('hovered', true);
  }
  public mouseOut() {
    this.hover = false;
    this.$emit('hovered', false);
  }
}

export default toNative(ContentImageBoxModule);
</script>
