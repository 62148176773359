<template>
  <StandardDialogModal class="list-modal" :size="uiSize.Medium" :loading="isBusy || isSubmitting">
    <template #header>
      <div>
        <h3>{{ textK('UI_TOOL_QUOTATION_AIRBUS_RFQ_PART_LEASE_ADDRESS_HEADER') }}</h3>
      </div>
    </template>
    <template #body>
      <ModalColumnContainer>
        <ModalColumn>
          <SearchList
            v-if="shippingAddresses"
            :data="shippingAddresses"
            :search-keys="searcheableKeys"
            :label="'Search address'"
            @selected="selected"
          >
            <template #default="{ item }">
              <SimpleListItem
                :selected="item.Id === selectedAddressId"
                :class="item.RestrictedAddress ? 'simple-list-item--disabled' : ''"
              >
                <AddressCard :data="item"></AddressCard>
              </SimpleListItem>
            </template>
          </SearchList>
        </ModalColumn>
      </ModalColumnContainer>
    </template>
    <template #actions>
      <VButton :type="uiVariant.Secondary" @click="back">{{
        textK('UI_COMMON_BACK_BUTTON')
      }}</VButton>
      <VButton :type="uiVariant.Default" :disabled="isBusy || isSubmitting" @click="onSubmit">
        {{ textK('UI_TOOL_QUOTATION_AIRBUS_RFQ_PART_LEASE_REQUEST_BUTTON') }}
      </VButton>
    </template>
  </StandardDialogModal>
</template>

<script setup lang="ts">
import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import AddressCard from '@/src/core/components/ui/addressCard/address-card.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import SearchList from '@/src/core/components/ui/search-list/search-list.vue';
import SimpleListItem from '@/src/core/components/ui/simple-list-item/simple-list-item.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import {
  AIRBUS_ONLY_SEARCHABLE_KEYS,
  DEFAULT_ADDRESS_SEARCHABLE_KEYS,
} from '@/src/core/settings/address';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { useModalStore } from '@/src/core/stores/modal';
import { Address, SendQuoteEmailToAirbusRequest } from '@/src/core/types/api';
import { useAirbusQuotesStore } from '@/src/market/stores/airbus-quotes';
import { useUserStore } from '@/src/profile/stores/user';
import { computed, onMounted, ref } from 'vue';

const textK = useText();
const uiVariant = UiVariant;
const uiSize = UiSize;

const selectedAddressId = ref('');
const isSubmitting = ref(false);

const userStore = useUserStore();
const airbusQuotesStore = useAirbusQuotesStore();
const modalStore = useModalStore();

const searcheableKeys = computed(() => {
  return [...DEFAULT_ADDRESS_SEARCHABLE_KEYS, ...AIRBUS_ONLY_SEARCHABLE_KEYS];
});

const isBusy = computed(() => {
  return userStore.isBusy;
});

const fetchDeliveryAddress = async () => {
  userStore.fetchCompany({ userId: userStore.currentUser.GlobalId });
};

const deliveryAddresses = computed(() => {
  return userStore.company.BlueB2BUnit?.Addresses ?? [];
});

const shippingAddresses = computed(() => {
  return deliveryAddresses.value?.filter(
    (address) => address.ShippingAddress === true && address.FormattedAddress !== '',
  );
});

const back = () => {
  modalStore.closeModal({ modalComponent: 'ModalAirbusRequestQuoteAddress' });
  modalStore.showModal({
    modalComponent: 'ModalRequestPartLease',
    params: {
      id: airbusQuotesStore.productId,
    },
  });
};

const selected = (item: Address) => {
  selectedAddressId.value = item.Id;
  airbusQuotesStore.setShippingAddress({ shippingAddressId: item.Id });
};

const onSubmit = async () => {
  isSubmitting.value = true;

  const { GlobalId: globalId } = userStore.currentUser;
  const formData = airbusQuotesStore.formData;

  const requestObject: SendQuoteEmailToAirbusRequest = {
    ...formData,
    productId: airbusQuotesStore.productId,
    procurementType: ProcurementType.Lease,
  };

  const { IsSuccess } = await airbusQuotesStore.sendEmail({ globalId, requestObject });

  if (IsSuccess) {
    modalStore.closeModal();
    airbusQuotesStore.$reset();
    modalStore.showModal({
      modalComponent: 'ModalQuoteEmailNotification',
      params: {
        key: 'AirbusRFQConfirmationPartLease_ModalUrl',
        title: 'AirbusRFQConfirmationPartLease_Name',
        size: uiSize.Medium,
      },
    });
  }

  isSubmitting.value = false;
};

onMounted(async () => {
  if (deliveryAddresses.value.length === 0) {
    await fetchDeliveryAddress();
  }

  const shipToAddressSelected = airbusQuotesStore.formData?.shipToAddress;
  if (shipToAddressSelected) {
    selectedAddressId.value = shipToAddressSelected;
  }
});
</script>
