import { SellerApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { MarketplaceMasterShop } from '@/src/core/types/api';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useMasterShopStore = defineStore('masterShops', () => {
  const mastershops = ref<MarketplaceMasterShop[]>([]);

  const masterShopById = (id: string) => {
    return mastershops.value.find((mastershop) => mastershop?.Id === id);
  };

  function updateMasterShops(shop: MarketplaceMasterShop) {
    const { Id } = shop;
    if (!masterShopById(Id)) {
      mastershops.value.push(shop);
    }
  }

  function $reset() {
    mastershops.value = [];
  }

  async function fetchMasterShopById(id: string) {
    if (masterShopById(id)) {
      return { IsSuccess: true };
    }

    const { IsSuccess, Data } = await Req(
      {
        url: SellerApi.FetchMastershopById(id),
      },
      undefined,
      SellerApi.handleMastershopById.bind(SellerApi, {
        url: SellerApi.FetchMastershopById(id),
        id,
      }),
    );

    if (IsSuccess && Data) {
      updateMasterShops(Data);
    }

    return { IsSuccess };
  }

  return { mastershops, masterShopById, updateMasterShops, $reset, fetchMasterShopById };
});
