<template>
  <div>
    <StandardDialogModal class="list-modal" :size="UiSize.Large" :loading="isBusy">
      <template #header>
        <h3>{{ textK('UI_HEAVY_MAINTENANCE_MODAL_TITLE') }}</h3>
        <p class="pt-lg">{{ textK('UI_HEAVY_MAINTENANCE_MODAL_DESCRIPTION') }}</p>
      </template>
      <template #body>
        <form>
          <section class="pt-sm">
            <h4 class="pb-12">Your details</h4>
            <div class="flex gap-lg">
              <VTextField
                class="flex-grow"
                input-type="text"
                :type="UiVariant.Secondary"
                label="First name"
                :required="true"
                v-model="formDetails.firstName"
              />
              <VTextField
                class="flex-grow"
                input-type="text"
                :type="UiVariant.Secondary"
                label="Last name"
                :required="true"
                v-model="formDetails.lastName"
              />
            </div>
            <div class="py-lg">
              <VTextField
                input-type="text"
                :type="UiVariant.Secondary"
                label="Email"
                :required="true"
                v-model="formDetails.email"
                :error-message="errors?.email && errors.email[0]"
              />
            </div>
            <div class="flex pb-lg gap-lg phone-wrapper">
              <VTextField
                v-ui-test="'profile-details-country-code'"
                input-type="tel"
                :vmax-length="3"
                :type="UiVariant.Secondary"
                :label="textK('UI_COMMON_COUNTRYCODE')"
                :prefix="'+'"
                :required="true"
                name="country-code"
                v-model="formDetails.countryCode"
              />
              <VTextField
                input-type="tel"
                :type="UiVariant.Secondary"
                label="Phone number"
                :required="true"
                v-model="formDetails.phoneNumber"
              />
            </div>
            <div class="flex gap-lg phone-wrapper">
              <VTextField
                v-ui-test="'profile-details-fax-country-code'"
                :required="formDetails.faxNumber.length > 0"
                input-type="tel"
                :vmax-length="3"
                :type="UiVariant.Secondary"
                :label="textK('UI_COMMON_COUNTRYCODE')"
                :prefix="'+'"
                name="country-code"
                v-model="formDetails.faxCountryCode"
              />
              <VTextField
                v-ui-test="'profile-details-fax-number'"
                input-type="tel"
                :type="UiVariant.Secondary"
                label="Fax number"
                v-model="formDetails.faxNumber"
              />
            </div>
          </section>

          <section class="pt-32">
            <h4 class="pb-12">Event details</h4>
            <div class="flex gap-lg">
              <VTextField
                class="flex-grow"
                input-type="text"
                :type="UiVariant.Secondary"
                label="Operator name (max 5 characters)"
                :vmax-length="5"
                :vmin-length="3"
                :required="true"
                v-model="formDetails.operator"
              />
              <VTextField
                class="flex-grow"
                input-type="text"
                :type="UiVariant.Secondary"
                label="Maintenance station"
                :required="true"
                :readonly="true"
                :disabled="true"
                v-model="formDetails.maintenanceStation"
              />
            </div>
            <div class="flex gap-lg pt-lg">
              <VSelect
                class="flex-grow dropdown"
                :required="true"
                :options-skeleton="msnPrefixOptions.length === 0"
                label="Aircraft family"
                :modelValue="formDetails.msnPrefixCode"
                @update:modelValue="(value: number) => handleDropdownInput(value, 'msnPrefixCode')"
                :is-input-field="false"
                :options="msnPrefixOptions"
                :type="UiVariant.Secondary"
                ui-test-name-dropdown="aircraft-family-dropdown-option"
              />
              <VTextField
                class="flex-grow"
                input-type="text"
                :type="UiVariant.Secondary"
                :vmax-length="5"
                :vmin-length="5"
                label="MSN number (5 characters)"
                :required="true"
                :error-message="errors?.msn && errors.msn[0]"
                v-model="formDetails.msn"
              />
            </div>
            <div class="flex gap-lg pt-lg">
              <VTextField
                class="flex-grow"
                input-type="text"
                :type="UiVariant.Secondary"
                :vmax-length="5"
                :vmin-length="5"
                label="AC Reg. / Tail"
                :required="true"
                v-model="formDetails.aircraftTail"
              />
              <VSelect
                class="flex-grow dropdown"
                :required="true"
                :options-skeleton="aircraftTypeOptions.length === 0"
                label="Aircraft type"
                :modelValue="formDetails.aircraftTypeCode"
                @update:modelValue="
                  (value: number) => handleDropdownInput(value, 'aircraftTypeCode')
                "
                :is-input-field="false"
                :options="aircraftTypeOptions"
                :type="UiVariant.Secondary"
                ui-test-name-dropdown="aircraft-type-dropdown-option"
              />
            </div>
            <div class="flex gap-lg pt-lg">
              <VSelect
                class="flex-grow dropdown"
                :required="true"
                :options-skeleton="checkTypeOptions.length === 0"
                label="Check type"
                :modelValue="formDetails.checkTypeCode"
                @update:modelValue="(value: number) => handleDropdownInput(value, 'checkTypeCode')"
                :is-input-field="false"
                :options="checkTypeOptions"
                :type="UiVariant.Secondary"
                ui-test-name-dropdown="aircraft-family-dropdown-option"
              />
              <DatePickerWrapper
                class="flex-grow"
                label="Maintenance period"
                datepicker-type="text"
                :from-date="formDetails.fromDate"
                :to-date="formDetails.toDate"
                :range="true"
                :multi-calendars="true"
                auto-position="top"
                :min-date="dayjs().format(DisplayDateFormat)"
                @apply="applyMaintenancePeriodDate"
                @closed="togglePicker"
              >
                <template #label>
                  <VTextField
                    :required="true"
                    class="flex-grow maintenance-period"
                    :type="UiVariant.Secondary"
                    :label="'Maintenance period'"
                    :model-value="maintenancePeriodDates"
                    @click="togglePicker"
                    :readonly="true"
                  >
                    <template #append>
                      <div
                        class="select-arrow"
                        :class="{ 'select-arrow--active': isDatePickerOpen }"
                      >
                        <svgicon name="ui-arrow" @click="togglePicker"></svgicon>
                      </div>
                    </template>
                  </VTextField>
                </template>
              </DatePickerWrapper>
            </div>
            <div class="pt-lg">
              <VTextarea
                v-ui-test="'heavy-maintenance-additionalal-info'"
                :type="UiVariant.Secondary"
                label="Additional information (max 300 characters)"
                v-model="formDetails.additionalInformation"
                :vmax-length="300"
                name="additional-info"
              />
            </div>
          </section>

          <section class="pt-32 flex justify-between">
            <VButton input-type="button" :type="UiVariant.Secondary" @click.prevent="resetForm"
              >reset form
            </VButton>
            <VButton
              v-ui-test="'button-save-user'"
              :disabled="!isValid"
              @click.prevent="onSubmit"
              :context="UiContext.ShopSite"
              :loading="isBusy"
            >
              send
            </VButton>
          </section>
        </form>
      </template>
    </StandardDialogModal>
  </div>
</template>

<style src="./modal-heavy-maintenance-form.scss" lang="scss" scoped></style>
<script setup lang="ts">
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import DatePickerWrapper from '@/src/core/components/ui/form/datepicker/datepicker.vue';
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import { UiContext, UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { Req } from '@/src/core/services/requester';
import { useModalStore } from '@/src/core/stores/modal';
import { useNotificationsStore } from '@/src/core/stores/notifications';
import { AirbusHeavyMaintenanceFormRequest, NotificationTypes } from '@/src/core/types/api';
import { HeavyMaintenanceDropdownField, HeavyMaintenanceForm } from '@/src/core/types/interfaces';
import { IVSelect } from '@/src/core/types/ui';
import { DateFormat, DisplayDateFormat } from '@/src/core/utils/dates';
import { getSelectedValue, mapToSelectOption } from '@/src/core/utils/select';
import { HeavyMaintenanceApi } from '@/src/market/api/heavymaintenanceApi';
import { useFetchHeavyMaintenanceFormInfo } from '@/src/market/hooks/useFetchHeavyMaintenanceFormInfo';
import { useUserStore } from '@/src/profile/stores/user';
import dayjs from 'dayjs';
import {
  custom,
  email,
  flatten,
  length,
  maxLength,
  minLength,
  number,
  object,
  parse,
  regex,
  string,
} from 'valibot';
import type { Ref } from 'vue';
import { computed, onMounted, ref, watch } from 'vue';

const initialFormState = {
  aircraftTail: '',
  additionalInformation: '',
  aircraftTypeCode: null,
  checkTypeCode: null,
  countryCode: '',
  phoneNumber: '',
  faxCountryCode: '',
  faxNumber: '',
  email: '',
  firstName: '',
  lastName: '',
  maintenanceStation: '',
  operator: '',
  fromDate: '',
  toDate: '',
  msnPrefixCode: null,
  msn: '',
};

const NUMERIC_VALUE_REGEX = /^\d+$/;
const textK = useText();
const notificationsStore = useNotificationsStore();
const modalStore = useModalStore();
const { isBusy, fetchFormInfo, heavyMaintenanceFormInfo } = useFetchHeavyMaintenanceFormInfo();

const formDetails = ref<HeavyMaintenanceForm>({ ...initialFormState });
const msnPrefixOptions: Ref<IVSelect[]> = ref([]);
const aircraftTypeOptions: Ref<IVSelect[]> = ref([]);
const checkTypeOptions: Ref<IVSelect[]> = ref([]);
const maintenancePeriodDates = ref('');
const isDatePickerOpen = ref(false);
const isValid = ref(false);
const errors = ref<Partial<Record<any, [string, ...string[]]>> | null>(null);
const userStore = useUserStore();

const HeavyMaintenanceSchema = object({
  additionalInformation: string([maxLength(300)]),
  aircraftTail: string([length(5)]),
  checkTypeCode: number([
    custom((input: number) => {
      const value = getSelectedValue(input, checkTypeOptions.value);
      if (value.length >= 1) {
        return true;
      }
      return false;
    }),
  ]),
  msnPrefixCode: number([
    custom((input: number) => {
      const value = getSelectedValue(input, msnPrefixOptions.value);
      if (value.length >= 1) {
        return true;
      }
      return false;
    }),
  ]),
  aircraftTypeCode: number([
    custom((input: number) => {
      const value = getSelectedValue(input, aircraftTypeOptions.value);
      if (value.length >= 1) {
        return true;
      }
      return false;
    }),
  ]),
  email: string([email('Invalid email format')]),
  fromDate: string([minLength(1)]),
  maintenanceStation: string(),
  msn: string([
    length(5, 'MSN must have at least 5 characters'),
    regex(NUMERIC_VALUE_REGEX, 'Only numbers are allowed.'),
  ]),
  firstName: string([minLength(1)]),
  operator: string([minLength(3), maxLength(5)]),
  phoneNumber: string([minLength(1), maxLength(20)]),
  countryCode: string([minLength(1), maxLength(3)]),
  faxNumber: string([maxLength(20)]),
  faxCountryCode: string([
    custom((input: string) => {
      if (formDetails.value.faxNumber.length === 0) {
        return true;
      } else if (input.length > 0) {
        return true;
      }
      return false;
    }),
  ]),
  toDate: string([minLength(1)]),
});

const handleDropdownInput = (value: number | null, field: HeavyMaintenanceDropdownField) => {
  formDetails.value[field] = value;
};

const applyMaintenancePeriodDate = (datepickerValue: string[]) => {
  if (datepickerValue.length >= 2) {
    maintenancePeriodDates.value =
      DateFormat(datepickerValue[0]) + ' - ' + DateFormat(datepickerValue[1]);

    formDetails.value.fromDate = dayjs(datepickerValue[0]).format(DisplayDateFormat);
    formDetails.value.toDate = dayjs(datepickerValue[1]).format(DisplayDateFormat);
  }
};

const togglePicker = () => {
  isDatePickerOpen.value = !isDatePickerOpen.value;
};

const resetForm = () => {
  const { CountryCode, ContactNumber, Email, FirstName, LastName } = userStore.currentUser;

  Object.assign(formDetails.value, {
    ...initialFormState,
    countryCode: CountryCode,
    phoneNumber: ContactNumber,
    email: Email,
    firstName: FirstName,
    lastName: LastName,
    maintenanceStation: heavyMaintenanceFormInfo.value?.maintenanceStation ?? '',
  });

  maintenancePeriodDates.value = '';
};

const onSubmit = async () => {
  const { GlobalId } = userStore.currentUser;
  isBusy.value = true;

  const { IsSuccess } = await Req(
    {
      url: HeavyMaintenanceApi.HeavyMaintenanceFormSubmit(GlobalId),
      method: 'POST',
      data: formRequestObj.value,
    },
    undefined,
    HeavyMaintenanceApi.handleHeavyMaintenanceFormSubmit,
  );

  if (IsSuccess) {
    modalStore.closeModal();
    notificationsStore.addNotification({
      notificationItem: {
        Description: 'Successfully submitted heavy maintenance event details',
        Type: NotificationTypes.Success,
        Timing: 5000,
      },
    });
  }

  isBusy.value = false;
};

const formRequestObj = computed((): AirbusHeavyMaintenanceFormRequest => {
  const {
    countryCode,
    phoneNumber,
    faxCountryCode,
    faxNumber,
    firstName,
    lastName,
    msnPrefixCode,
    aircraftTypeCode,
    checkTypeCode,
    ...rest
  } = formDetails.value;

  return {
    ...rest,
    phoneNumber: countryCode + phoneNumber,
    fax: faxCountryCode + faxNumber,
    name: `${firstName} ${lastName}`,
    msnPrefixCode: getSelectedValue(msnPrefixCode, msnPrefixOptions.value),
    aircraftTypeCode: getSelectedValue(aircraftTypeCode, aircraftTypeOptions.value),
    checkTypeCode: getSelectedValue(checkTypeCode, checkTypeOptions.value),
  };
});

watch(
  () => formDetails.value,
  (newValue) => {
    try {
      parse(HeavyMaintenanceSchema, newValue);
      isValid.value = true;
      errors.value = null;
    } catch (error) {
      isValid.value = false;
      errors.value = flatten<typeof HeavyMaintenanceSchema>(error).nested;
    }
  },
  { immediate: true, deep: true },
);

onMounted(async () => {
  const user = userStore.currentUser;

  if (!user) {
    await userStore.fetchUserProfile();
  }

  const { CountryCode, ContactNumber, Email, FirstName, LastName } = user;

  formDetails.value.countryCode = CountryCode;
  formDetails.value.phoneNumber = ContactNumber;
  formDetails.value.email = Email;
  formDetails.value.firstName = FirstName;
  formDetails.value.lastName = LastName;

  const userId = userStore.currentUser.GlobalId;

  await fetchFormInfo(userId);

  formDetails.value.maintenanceStation = heavyMaintenanceFormInfo.value?.maintenanceStation ?? '';

  msnPrefixOptions.value =
    heavyMaintenanceFormInfo.value?.msnPrefixes?.map(mapToSelectOption) ?? [];
  aircraftTypeOptions.value =
    heavyMaintenanceFormInfo.value?.aircraftTypes?.map(mapToSelectOption) ?? [];
  checkTypeOptions.value = heavyMaintenanceFormInfo.value?.checkTypes?.map(mapToSelectOption) ?? [];
});
</script>
