<template>
  <div class="modal-seller-conversation typeset" v-ui-test="'seller-conversation-modal'">
    <StandardDialogContainer :size="uiSize.Large" :loading="isBusy">
      <div class="modal-seller-conversation__inner" ref="scrollcontainer">
        <SellerOrderConversationHeader
          v-if="thread"
          :order-id="orderCode"
          :order-ref="poNo"
          :thread="thread"
        />
        <SellerOfferConversationHeader v-else :offer-id="id" />
        <div class="advanced-modal__body">
          <SellerConversation
            class="modal-seller-conversation__thread"
            :id="id"
            :thread="thread"
            @beforeLoad="beforeProductUpdate"
            @afterLoad="onProductUpdate"
          />
          <SellerConversationMessaging
            ref="sellerConversationMessaging"
            class="modal-seller-conversation__messaging"
            :class="{ 'no-messages': messageCount <= 0 }"
            :id="id"
            :thread="thread"
          ></SellerConversationMessaging>
        </div>
      </div>
    </StandardDialogContainer>
  </div>
</template>

<style lang="scss" src="./modal-seller-conversation.scss" scoped />

<script lang="ts">
import { Vue, Component, Ref, toNative } from 'vue-facing-decorator';
import StandardDialogContainer from '@/src/core/components/modals/ui/standard-dialog-layout/standard-dialog-container.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import { useModalStore } from '@/src/core/stores/modal';
import { MarketplaceMessagingConsignemtThreads } from '@/src/core/types/api';
import SellerConversationMessaging from '@/src/market/components/seller/seller-conversation/seller-conversation-messaging/seller-conversation-messaging.vue';
import SellerConversation from '@/src/market/components/seller/seller-conversation/seller-conversation.vue';
import SellerOfferConversationHeader from '@/src/market/components/seller/seller-conversation/seller-offer-conversation-header/seller-offer-conversation-header.vue';
import SellerOrderConversationHeader from '@/src/market/components/seller/seller-conversation/seller-order-conversation-header/seller-order-conversation-header.vue';
import { useOrderDetailsStore } from '@/src/market/stores/order-details';
import { useSellerConversationStore } from '@/src/market/stores/seller-conversation';

@Component({
  components: {
    SellerConversation,
    StandardDialogContainer,
    SellerConversationMessaging,
    SellerOfferConversationHeader,
    SellerOrderConversationHeader,
  },
})
export class ModalSellerConversation extends Vue {
  public modalStore = useModalStore();
  public orderDetailsStore = useOrderDetailsStore();

  public uiSize = UiSize;
  private oldContainerHeight: number;
  private oldScrollTop: number;
  public sellerConversationStore = useSellerConversationStore();

  @Ref() readonly scrollcontainer!: HTMLElement;
  @Ref() readonly sellerConversationMessaging!: InstanceType<typeof SellerConversationMessaging>;

  private get params() {
    return this.modalStore.params as IModalSellerConversationParams;
  }

  public get thread() {
    return this.params.consignmentThread;
  }

  public get id() {
    if (this.thread) {
      return this.thread.ConsignmentId;
    } else {
      return this.params.id;
    }
  }

  public get messageCount() {
    return this.sellerConversationStore.messages.length;
  }

  public get isBusy() {
    return this.sellerConversationStore.isBusy;
  }

  public get poNo() {
    return this.orderDetailsStore.order.PurchaseOrderNumber;
  }
  public get orderCode() {
    return this.orderDetailsStore.order.FormattedCode;
  }

  public beforeProductUpdate() {
    if (this.$refs.scrollcontainer) {
      this.oldContainerHeight = (this.$refs.scrollcontainer as any)?.scrollHeight;
      this.oldScrollTop = (this.$refs.scrollcontainer as any)?.scrollTop;
    }
  }

  public onProductUpdate() {
    if (this.$refs.scrollcontainer) {
      if (this.oldContainerHeight >= 0 && this.oldScrollTop >= 0) {
        (this.$refs.scrollcontainer as any).scrollTop =
          this.oldScrollTop +
          ((this.$refs.scrollcontainer as any)?.scrollHeight - this.oldContainerHeight);
      } else {
        (this.$refs.scrollcontainer as any).scrollTop = (
          this.$refs.scrollcontainer as any
        )?.scrollHeight;
      }
    }

    if (this.messageCount <= 0 && !this.isBusy && this.$refs.sellerConversationMessaging) {
      this.$nextTick(() => {
        (this.$refs.sellerConversationMessaging as any).setFocus();
      });
    }
  }
}

export interface IModalSellerConversationParams {
  id: string;
  consignmentThread?: MarketplaceMessagingConsignemtThreads;
}

export default toNative(ModalSellerConversation);
</script>
