<template>
  <div class="modal-request-quote-header">
    <div
      class="modal-request-quote-header__title-wrapper"
      v-ui-test="'request-repair-quote-header'"
    >
      <h3 class="modal-request-quote-header__title">
        {{ heading }}
      </h3>
    </div>
    <div
      class="modal-request-quote-header__product"
      v-if="product"
      v-ui-test="'request-quote-offer'"
    >
      <div
        class="modal-request-quote-header__product__image"
        v-ui-test="'request-quote-offer-image'"
      >
        <img
          v-if="product.HasImage && product.Images.length > 0"
          :src="product.Images[0].CdnUrl + '&width=100&height=100&mode=crop'"
        />
        <svg
          v-else
          class="placeholder-image"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 110 110"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M75.4 12.4v47.4l-59-59L.8 16.4l59 59H12.4L.8 97.4h96.6V.8z"
          />
        </svg>
      </div>
      <div class="modal-request-quote-header__product__details">
        <div
          class="modal-request-quote-header__product__number"
          v-ui-test="'request-quote-offer-sku'"
        >
          {{ product.ManufacturerAid
          }}<span
            v-if="product.ProductManufacturer && product.ProductManufacturer.CageCode.length > 0"
            class="modal-request-quote-header__product__cagecode"
            >:{{ product.ProductManufacturer.CageCode }}</span
          >
        </div>
        <div
          class="modal-request-quote-header__product__title"
          v-ui-test="'request-quote-offer-name'"
        >
          {{ product.Name && product.Name.length > 0 ? product.Name : '-' }}
        </div>
      </div>
    </div>
  </div>
</template>

<style
  lang="scss"
  src="../../modal-request-quote/modal-request-quote-header/modal-request-quote-header.scss"
  scoped
></style>

<script setup lang="ts">
import useText from '@/src/core/hooks/useText';
import { useProductStore } from '@/src/market/stores/product';
import { computed } from 'vue';

const textK = useText();
const productStore = useProductStore();

const props = defineProps<{ id: string }>();
const heading = computed(() => textK('UI_REPAIR_QUOTATION_REQUEST_HEADER'));
const product = computed(() => productStore.productById(props.id));
</script>
