<template>
  <div
    :class="{ 'link-portfolio': true, 'white-theme': theme === 'ui-grey-ligh' }"
    ref="linkPortfolioRef"
  >
    <header v-if="heading" class="content-module-section__header link-portfolio__header">
      <h2>{{ heading }}</h2>
    </header>

    <swiper
      v-if="showSlider"
      :slides-per-view="1"
      :space-between="0"
      :modules="swiperModules"
      :autoplay="{
        delay: 5000,
      }"
      :pagination="{
        dynamicBullets: true,
      }"
      @swiper="onSwiper"
    >
      <template #wrapper-start>
        <swiper-slide>
          <div class="link-portfolio__grid">
            <a
              :href="logo.urlLink"
              v-for="logo in first12Logos"
              :key="logo.imageUrl"
              class="link-portfolio__grid--logo"
            >
              <img
                @mouseover="pauseSwiper"
                @mouseleave="resumeSwiper"
                :src="imageSrc(logo)"
                alt="logo"
              />
            </a>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div
            :class="{
              'link-portfolio__grid': true,
              'link-portfolio__grid--centered': remainingLogos.length < 6,
            }"
          >
            <a
              :href="logo.urlLink"
              v-for="logo in remainingLogos"
              :key="logo.imageUrl"
              class="link-portfolio__grid--logo"
            >
              <img
                @mouseover="pauseSwiper"
                @mouseleave="resumeSwiper"
                :src="imageSrc(logo)"
                alt="logo"
              />
            </a>
          </div>
        </swiper-slide>
      </template>
    </swiper>

    <div v-else>
      <div
        :class="{
          'link-portfolio__grid': true,
          'link-portfolio__grid--centered': logos.length < 6,
        }"
      >
        <a
          :href="logo.urlLink"
          v-for="logo in logos"
          :key="logo.imageUrl"
          class="link-portfolio__grid--logo"
        >
          <img :src="imageSrc(logo)" alt="logo" />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import isMobile from '@/src/core/utils/mobile-detection';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper as SwiperType } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';

interface Props {
  headerText?: string;
  elements?: string;
  theme?: 'primary-dark' | 'ui-grey-ligh';
}

interface Logo {
  imageUrl: string | null;
  darkImageUrl: string | null;
  urlLink?: string;
  text?: string;
}

const props = defineProps<Props>();

const logosPerPage = 12;
const slider = ref<null | SwiperType>(null);
const linkPortfolioRef = ref();
const swiperModules = [Autoplay, Pagination];
const isMobileScreen = ref(false);

const logos = computed(() => {
  if (!props.elements) {
    return [];
  }
  return JSON.parse(props.elements);
});

const theme = computed(() => {
  return props.theme;
});

const heading = computed(() => {
  return props.headerText;
});

const first12Logos = computed(() => {
  return logos.value.slice(0, 12);
});

const remainingLogos = computed(() => {
  const startIdx = logosPerPage;
  const endIdx = Math.min(logos.value.length, startIdx + logosPerPage);
  return logos.value.slice(startIdx, endIdx);
});

const showSlider = computed(() => {
  return logos.value.length > logosPerPage;
});

const onSwiper = (swiper: SwiperType) => {
  slider.value = swiper;
};

const pauseSwiper = () => {
  slider.value?.autoplay?.pause();
  linkPortfolioRef.value.classList.add('link-portfolio__paused');
};

const resumeSwiper = () => {
  slider.value?.autoplay.resume();
  linkPortfolioRef.value.classList.remove('link-portfolio__paused');
};

const detectMobileBreakpoint = () => {
  isMobileScreen.value = isMobile.isMobileAndTablet();
};

const imageSrc = (logo: Logo) => {
  const logoSrc = theme.value === 'primary-dark' ? logo.imageUrl : logo.darkImageUrl;
  return logoSrc ? logoSrc : '';
};

onMounted(() => {
  window.addEventListener('resize', detectMobileBreakpoint);
  detectMobileBreakpoint();
});

onUnmounted(() => {
  window.removeEventListener('resize', detectMobileBreakpoint);
});
</script>

<style lang="scss" src="./link-portfolio.scss"></style>
