import { ProcurementType } from '@/src/core/settings/procurement-types';
import { PriceBreakInfo, ProductPrice } from '@/src/core/types/api';
import {
  PriceBreakInfo as AirbusPriceInfo,
  BasePrice,
  LocationInfo,
  PriceUnit,
  Procurement,
  ProductAirbusPart,
} from '@/src/core/types/interfaces';

const sortLocation = (locationInfo: LocationInfo[]) => {
  const locationInfoCopy = [...locationInfo];

  return locationInfoCopy.sort((a, b) => {
    if (a.sortIndex < b.sortIndex) {
      return -1;
    }

    if (a.sortIndex > b.sortIndex) {
      return 1;
    }

    return 0;
  });
};

export const getAirbusPartLocation = (procurement?: Procurement) => {
  const wareHouseLocation = procurement?.warehouseLocationDescription ?? '-';

  if (procurement?.locationInfos && procurement?.locationInfos.length > 0) {
    return (
      sortLocation(procurement.locationInfos)[0].warehouseLocationDescription ?? wareHouseLocation
    );
  }

  return wareHouseLocation;
};

export const getAirbusPartAvailableStock = (procurement?: Procurement) => {
  if (procurement?.locationInfos && procurement?.locationInfos.length > 0) {
    return sortLocation(procurement.locationInfos)[0].availableStock;
  }

  return procurement?.availableStock ?? 0;
};

export const formatBasePrice = (basePrice?: BasePrice) => ({
  CurrencyIso: basePrice?.currencyIso,
  FormattedVolumeDiscountPercentage: basePrice?.formattedVolumeDiscountPercentage ?? '',
  InfoStatus: '',
  IsAsmHidden: false,
  FormattedValue: basePrice?.formattedValue,
  MaxQuantity: basePrice?.maxQuantity ?? 0,
  MinQuantity: basePrice?.minQuantity ?? 0,
  PriceType: basePrice?.priceType,
  Value: basePrice?.value?.toString(),
  VolumeDiscountPercentage: basePrice?.volumeDiscountPercentage ?? 0,
});

export const formatProductPrice = (basePrice?: BasePrice): ProductPrice => ({
  CurrencyIso: basePrice?.currencyIso || '',
  InfoStatus: '',
  IsAsmHidden: false,
  FormattedValue: basePrice?.formattedValue || '-',
  PriceType: basePrice?.priceType || '',
  Value: basePrice?.value?.toString() || '',
});

export const formatBreakInfoPrice = (price: AirbusPriceInfo) => ({
  CurrencyIso: price?.basePrice?.currencyIso,
  FormattedVolumeDiscountPercentage: price?.formattedVolumeDiscountPercentage,
  FormattedValue: price?.basePrice?.formattedValue,
  InfoStatus: '',
  IsAsmHidden: false,
  MaxQuantity: price?.maxQuantity,
  MinQuantity: price?.minQuantity,
  PriceType: '',
  Value: price?.basePrice?.value?.toString(),
  VolumeDiscountPercentage: price?.volumeDiscountPercentage,
});

export const formatPriceUnit = (priceUnit?: PriceUnit) => ({
  Code: priceUnit?.code ?? '',
  Name: priceUnit?.name ?? '',
  SapCode: priceUnit?.sapCode ?? '',
});

export const formatPriceBreakInfo = (procurement?: Procurement | null): PriceBreakInfo[] => {
  return procurement?.priceBreakInfos
    ? procurement.priceBreakInfos.map((price) => ({
        BasePrice: formatBreakInfoPrice(price),
        PriceUnit: formatPriceUnit(price.priceUnit),
        PriceUnitFactor: price.priceUnitFactor,
        MaxQuantity: price.maxQuantity,
        MinQuantity: price.minQuantity,
        FormattedVolumeDiscountPercentage: price.formattedVolumeDiscountPercentage,
        VolumeDiscountPercentage: price.volumeDiscountPercentage,
      }))
    : [];
};

export const hasBasePrice = (procurement?: Procurement) => {
  if (procurement?.procurementType === ProcurementType.Lease) {
    return Boolean(procurement?.leaseInfo?.minimumLease?.subtotal);
  }

  return Boolean(procurement?.basePrice?.value);
};

export const hasAirbusCrossPlantMaterialStatus = (
  productAdditionalData: ProductAirbusPart | null,
) => {
  return Boolean(productAdditionalData?.productData?.airbusCrossPlantMaterialStatus?.code);
};

export const airbusCertificateType = (procurement?: Procurement) => {
  return procurement?.certificateType;
};
