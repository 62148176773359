import router from '@/src/core/router/index';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useModalStore } from '@/src/core/stores/modal';
import { useRouteConfigStore } from '@/src/core/stores/route-config';

const AuthOrLogin = async (payload: {
  cb?: () => void;
  betaRestricted?: string;
  allowAccess?: () => boolean;
}) => {
  const authenticationStore = useAuthenticationStore();
  const routeConfigStore = useRouteConfigStore();
  const modalStore = useModalStore();

  if (authenticationStore.isAuthenticated) {
    const routerConfigBeta = routeConfigStore.getMenuConfigBeta(payload.betaRestricted);

    const betaRestricted = payload.betaRestricted && routerConfigBeta;
    const betaUserAccess =
      betaRestricted !== undefined ? betaRestricted && authenticationStore.betaCustomer : true;
    const canAccess = payload.allowAccess ? payload.allowAccess() : true;

    if (betaUserAccess && canAccess) {
      if (payload.cb) {
        // ONLY RUN PAYLOAD.CB (often contains router next()) if it EXISTS else STAY ON PAGE..
        payload.cb();
      }
    } else {
      await router.push({ name: 'restricted' });
    }
  } else {
    setTimeout(() => {
      modalStore.showModal({
        modalComponent: 'ModalLogin',
        params: { next: () => AuthOrLogin(payload) },
        single: true,
      });
    }, 200);
  }
};
export default AuthOrLogin;
