<template>
  <div class="account-statement-filters" :class="{ 'account-statement-filters--modal': isModal }">
    <div class="account-statement-filters__group">
      <div class="account-statement-filters__group-name">
        {{ textK('UI_ACCOUNT_STATEMENT_FILTER_TITLE') }}
      </div>
      <VSelect
        class="account-statement-filters__group-input"
        :label="textK('UI_ACCOUNT_STATEMENT_FILTER_TYPE_LABEL')"
        :type="isModal ? uiVariant.Secondary : uiVariant.Primary"
        :options="typeOptions"
        v-model="selectedType"
      />
    </div>
    <div class="account-statement-filters__group">
      <div class="account-statement-filters__group-name">
        {{ textK('UI_ACCOUNT_STATEMENT_PERIOD_TITLE') }}
      </div>
      <VSelect
        v-if="!disabledDateSelect"
        class="account-statement-filters__group-input"
        :label="textK('UI_ACCOUNT_STATEMENT_PERIOD_SELECT_LABEL')"
        :type="isModal ? uiVariant.Secondary : uiVariant.Primary"
        :options="periodOptions"
        :modelValue="selectedPeriod"
        v-model="selectedPeriod"
      ></VSelect>
      <VSelect
        v-else
        class="account-statement-filters__group-input"
        :label="textK('UI_ACCOUNT_STATEMENT_PERIOD_SELECT_LABEL')"
        :type="isModal ? uiVariant.Secondary : uiVariant.Primary"
        :options="periodOptions"
        :disabled="true"
      ></VSelect>
      <DatePickerWrapper
        v-if="!disabledDateSelect"
        ref="datepicker"
        class="account-statement-filters__group-input"
        :label="textK('UI_ACCOUNT_STATEMENT_PERIOD_FILTER_TITLE')"
        :fromDate="fromDate"
        :toDate="toDate"
        :minDate="dayjs().subtract(2, 'year').format(DisplayDateFormat)"
        :maxDate="dayjs().format(DisplayDateFormat)"
        :range="true"
        :multi-calendars="true"
        :auto-position="true"
        @change="pickerFromDate = $event"
        @change2="pickerToDate = $event"
        @apply="applyDates($event)"
      >
        <template #label>
          <VTextField
            class="account-statement-filters__group-input"
            :label="textK('UI_ACCOUNT_STATEMENT_PERIOD_DATEPICKER_LABEL')"
            :model-value="dates"
            :type="isModal ? uiVariant.Secondary : uiVariant.Primary"
            :silent-disabled="true"
            :as-span="true"
          />
        </template>
      </DatePickerWrapper>
      <VTextField
        v-else
        class="account-statement-filters__group-input"
        :label="textK('UI_ACCOUNT_STATEMENT_PERIOD_DATEPICKER_LABEL')"
        :type="isModal ? uiVariant.Secondary : uiVariant.Primary"
        :disabled="true"
      />
    </div>
  </div>
</template>

<style src="./account-statement-filters.scss" lang="scss" scoped />

<script lang="ts" setup>
import DatePickerWrapper from '@/src/core/components/ui/form/datepicker/datepicker.vue';
import VSelect from '@/src/core/components/ui/form/select/select.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { IVSelect } from '@/src/core/types/ui';
import { createDateRange } from '@/src/core/utils/datepicker';
import { DisplayDateFormat, InputDateFormat } from '@/src/core/utils/dates';
import { PeriodOptions } from '@/src/finance/components/utils/period-options';
import { useAccountStatementStore } from '@/src/finance/stores/account-statement';
import dayjs from 'dayjs';
import { computed, ref } from 'vue';

enum AccountStatementTypes {
  All,
  Overdue,
  Open,
}

interface Props {
  isModal?: boolean;
}

withDefaults(defineProps<Props>(), {
  isModal: false,
});

const textK = useText();
const accountStatementStore = useAccountStatementStore();
const datePicker = ref<typeof DatePickerWrapper | null>(null);
const uiVariant = UiVariant;
const pickerFromDate = ref('');
const pickerToDate = ref('');

const periodOptions = PeriodOptions;

const typeOptions: IVSelect[] = [
  { label: 'All items', value: 'all' },
  { label: 'Overdue items', value: 'overdue' },
  { label: 'Open items', value: 'open' },
];

const selectedType = computed({
  get: () => {
    if (accountStatementStore.filters.openItems) {
      return AccountStatementTypes.Open;
    } else if (accountStatementStore.filters.overdueItems) {
      return AccountStatementTypes.Overdue;
    } else {
      return AccountStatementTypes.All;
    }
  },
  set: (value) => {
    switch (value) {
      case AccountStatementTypes.All:
        accountStatementStore.setFilters({
          openItems: false,
          overdueItems: false,
        });
        break;

      case AccountStatementTypes.Open:
        accountStatementStore.setFilters({
          openItems: true,
          overdueItems: false,
        });
        break;

      case AccountStatementTypes.Overdue:
        accountStatementStore.setFilters({
          openItems: false,
          overdueItems: true,
        });
        break;
    }
  },
});

const selectedPeriod = computed({
  get: () => {
    const index: number = periodOptions.findIndex((period) => period.value === dates.value);
    return index > -1 ? index : periodOptions.length - 1;
  },
  set: (index: number) => {
    if (index >= 0) {
      if (periodOptions[index].value !== '') {
        dates.value = periodOptions[index].value;
      } else {
        openDatepicker();
      }
    }
  },
});

const fromDate = computed({
  get: () => {
    return accountStatementStore.filters.fromDate;
  },
  set: (value) => {
    accountStatementStore.setFilters({
      fromDate: dayjs(value, InputDateFormat).format(DisplayDateFormat),
    });
  },
});

const toDate = computed({
  get: () => {
    return accountStatementStore.filters.toDate;
  },
  set: (value) => {
    accountStatementStore.setFilters({
      toDate: dayjs(value, InputDateFormat).format(DisplayDateFormat),
    });
  },
});

const dates = createDateRange(fromDate, toDate);

const disabledDateSelect = computed(() => {
  return accountStatementStore.filters.openItems || accountStatementStore.filters.overdueItems;
});

const applyDates = (newDates: string) => {
  dates.value = newDates;
};

const openDatepicker = () => {
  datePicker.value?.openMenu();
};
</script>
