<template>
  <div class="user-account-badge" :class="type.toLowerCase()">
    <div class="user-account-badge__inner">
      {{ initials }}
    </div>
  </div>
</template>

<style lang="scss" src="./user-account-badge.scss" scoped />

<script lang="ts" setup>
import { UserIndicationTypes } from '@/src/core/types/ui';
import { computed } from 'vue';

interface Props {
  userName: string;
  predefinedInitials?: string;
  type: UserIndicationTypes;
}

const props = defineProps<Props>();

const findInitials = computed(() => {
  const userNameToArray = props.userName.split(' ');
  if (userNameToArray.length > 0) {
    return (
      userNameToArray[0].split('')[0] + userNameToArray[userNameToArray.length - 1].split('')[0]
    );
  } else {
    return userNameToArray[0].split('').slice(0, 2).join('');
  }
});

const initials = computed(() => {
  if (props.predefinedInitials) {
    return props.predefinedInitials;
  } else {
    return findInitials.value;
  }
});
</script>
