<template>
  <StandardDialogModal
    :locked-modal="true"
    :loading="isBusy"
    class="modal-content"
    :has-close-button="false"
    :has-cancel-button="false"
    :size="uiSize.Medium"
  >
    <template #header>
      <h3>{{ textK('UI_USER_NEED_YOUR_PERMISSION') }}</h3>
    </template>
    <template #body>
      <div class="inner-body">
        <div class="input-container">
          <p>{{ textK('UI_USER_REGISTERED_EMAIL') }}</p>
          <VTextField
            :disabled="currentUser.Email && currentUser.Email.length > 0"
            :type="uiVariant.Secondary"
            :required="true"
            v-model="currentUser.Email"
            name="email"
            label="Email"
            input-type="email"
            v-ui-test="'first-time-user-email'"
          ></VTextField>
        </div>
        <ContentBlock :modal="true" :link-key="'PermissionBlock_ModalUrl'"></ContentBlock>
        <a :href="linK('PrivacyPolicy_PageUrl')" target="_blank">{{
          textK('UI_USER_READ_PRIVACY_POLICY')
        }}</a>
      </div>
    </template>

    <template #actions>
      <VButton @click="closeModal" input-type="button" :type="uiVariant.Secondary">{{
        textK('UI_USER_NOT_NOW')
      }}</VButton>
      <VButton @click="signUp" input-type="button" :type="uiVariant.Default">{{
        textK('UI_USER_YES_PLEASE')
      }}</VButton>
    </template>
  </StandardDialogModal>
</template>

<style lang="scss" scoped src="./modal-permission.scss"></style>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import ContentBlock from '@/src/core/components/ui/content-block/content-block.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useLink from '@/src/core/hooks/useLink';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { IPermissions } from '@/src/core/types/interfaces';
import { useUserStore } from '@/src/profile/stores/user';
import { computed } from 'vue';

const linK = useLink();
const textK = useText();
const modalStore = useModalStore();
const userStore = useUserStore();

const uiSize = UiSize;
const uiVariant = UiVariant;

const currentUser = computed(() => userStore.currentUser);
const isBusy = computed(() => userStore.isBusy);

const closeModal = () => {
  modalStore.closeModal();
};

const signUp = () => {
  const permissionObj: IPermissions = {
    Newsletter: true,
  };
  userStore.updatePermissions({ permissions: permissionObj }).then(() => {
    modalStore.closeModal({ modalComponent: 'ModalPermission' });
  });
};
</script>
