<template>
  <InViewWatcher>
    <div class="hero-module-wrapper">
      <slot></slot>
    </div>
  </InViewWatcher>
</template>

<style lang="scss" src="./hero-module.scss"></style>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import InViewWatcher from '@/src/core/components/util/in-view/in-view-watcher.vue';

@Component({
  components: {
    InViewWatcher,
  },
})
export class ContentHeroModule extends Vue {}

export default toNative(ContentHeroModule);
</script>
