<template>
  <div class="panel typeset">
    <div class="panel-header" v-if="$slots.header">
      <slot name="header" />
    </div>
    <div class="profile-header-details" v-if="$slots.headerDetails">
      <slot name="headerDetails" />
    </div>
    <slot />
  </div>
</template>

<style lang="scss" src="./panel-layout.scss" scoped></style>

<script lang="ts" setup></script>
