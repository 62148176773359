<template>
  <StandardDialogModal class="modal-request-tool-used" :size="uiSize.Large" :loading="isBusy">
    <template #header>
      <ModalRequestQuoteHeader
        :offer-id="productId"
        :header-title="textK('UI_TOOL_QUOTATION_REQUEST_USED_TOOL_HEADER')"
        :procurement="procurement"
        :has-additional-details="hasSerialNumber"
      >
        <template v-if="hasSerialNumber" #additionalDetails>
          <RequestToolSerialNumber :serial-number="serialNumber" />
        </template>
      </ModalRequestQuoteHeader>
    </template>
    <template #body>
      <p class="pb-xl">
        {{ textK('UI_TOOL_QUOTATION_USED_FORM_DESCRIPTION') }}
      </p>
      <VTextField
        class="pb-xl"
        v-model="formData.purchaseOrderNumber"
        input-type="text"
        :type="uiVariant.Secondary"
        :label="textK('UI_TOOL_QUOTATION_PO_NUMBER_TEXT')"
        name="referenceNumber"
        :required="true"
        :vmax-length="MAX_REFERENCE_LENGTH"
      />
      <VTextarea
        class="pb-l"
        v-model="formData.message"
        :vmax-length="MAX_MESSAGE_LENGTH"
        :type="uiVariant.Secondary"
        :label="textK('UI_TOOL_QUOTATION_REQUEST_MESSAGE')"
      />
    </template>
    <template #actions>
      <VButton
        @click.prevent="onSubmit"
        :disabled="!isValid"
        :loading="isBusy"
        :type="uiVariant.Default"
        v-ui-test="'submit-used-tool-quote'"
        >{{ textK('UI_TOOL_QUOTATION_SUBMIT_FORM_BUTTON_TEXT') }}</VButton
      >
    </template>
  </StandardDialogModal>
</template>

<style src="./modal-request-tool-used.scss" lang="scss" scoped></style>
<script setup lang="ts">
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { useModalStore } from '@/src/core/stores/modal';
import { SendQuoteEmailToAirbusRequest } from '@/src/core/types/api';
import { AirbusPart } from '@/src/core/types/interfaces';
import ModalRequestQuoteHeader from '@/src/market/components/modals/modal-request-quote/modal-request-quote-header/modal-request-quote-header.vue';
import RequestToolSerialNumber from '@/src/market/components/request-tool-used-serial-number/request-tool-used-serial-number.vue';
import { productAdditionalData } from '@/src/market/services/product-parts';
import { useAirbusQuotesStore } from '@/src/market/stores/airbus-quotes';
import { useProductStore } from '@/src/market/stores/product';
import { useUserStore } from '@/src/profile/stores/user';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';

interface Params {
  id: string;
  serialNumber?: string;
}

const MAX_MESSAGE_LENGTH = 1000;
const MAX_REFERENCE_LENGTH = 11;

const userStore = useUserStore();
const modalStore = useModalStore();
const airbusQuotesStore = useAirbusQuotesStore();
const productStore = useProductStore();
const { formData } = storeToRefs(airbusQuotesStore);

const textK = useText();
const uiSize = UiSize;
const uiVariant = UiVariant;

const isBusy = ref(false);
const isInputFocused = ref(false);

const isValid = computed(() => {
  return formData.value.purchaseOrderNumber.length > 0 && !isInputFocused.value;
});

const productId = computed(() => {
  return (modalStore.params as Params)?.id ?? '';
});

const product = computed(() => {
  return productStore.productById(productId.value);
});

const serialNumber = computed(() => {
  return (modalStore.params as Params)?.serialNumber;
});

const additionalData = computed(() => {
  return productAdditionalData(productId.value, product.value?.OwningSystem ?? '');
});

const procurement = computed(() => {
  return (additionalData.value as AirbusPart)?.procurements.find((p) =>
    p.serialNumber
      ? p.serialNumber === serialNumber.value && p.procurementType === ProcurementType.Used
      : p.procurementType === ProcurementType.Used,
  );
});

const locationInfo = computed(() => {
  return procurement.value?.warehouseLocationDescription;
});

const hasSerialNumber = computed(() => {
  return Boolean(serialNumber.value);
});

const closeModal = () => {
  modalStore.closeModal();
};

const onSubmit = async () => {
  isBusy.value = true;

  const { GlobalId: globalId } = userStore.currentUser;

  const requestObject: SendQuoteEmailToAirbusRequest = {
    ...formData.value,
    productId: productId.value,
    procurementType: ProcurementType.Used,
  };

  if (serialNumber.value) {
    requestObject.serialNumber = serialNumber.value;
  }

  if (serialNumber.value && locationInfo.value) {
    requestObject.locationInfo = locationInfo.value;
  }

  const { IsSuccess } = await airbusQuotesStore.sendEmail({ globalId, requestObject });

  if (IsSuccess) {
    modalStore.showModal({
      modalComponent: 'ModalQuoteEmailNotification',
      params: {
        key: 'AirbusRFQConfirmationSaleUsed_ModalUrl',
        title: 'AirbusRFQConfirmationSaleUsed_Name',
        size: uiSize.Medium,
        isConfirmation: true,
        withCloseButton: true,
      },
    });

    closeModal();
  }

  isBusy.value = false;
};

onMounted(() => {
  // Persist form values, except when the procurement selected in the modal has changed.
  if (serialNumber.value !== airbusQuotesStore.serialNumber) {
    airbusQuotesStore.$reset();
    airbusQuotesStore.setSerialNumber({ serialNumber: serialNumber.value });
  }
});
</script>
