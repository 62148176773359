<template>
  <div class="release-notes">
    <StandardDialogModal
      :class="{ 'is-lilly': isLilly }"
      :size="isLilly ? uiSize.Lilly : uiSize.Large"
      :border-line="isLilly ? uiVariant.Secondary : undefined"
      :use-padding="false"
      :has-cancel-button="true"
    >
      <template #body>
        <ModalColumnContainer>
          <ModalColumn
            v-if="isLilly && releaseNotes"
            :size="uiColumnSize.OneThird"
            class="lilly-banner"
          >
            <div class="lilly-banner__container">
              <LottieAnimation
                class="lilly-banner__media"
                :animation-data="lillyAnimationData"
                :loop="2"
                autoplay
                stop-at-first-frame-after-complete
              />
              <h1 class="lilly-banner__text">{{ textK('UI_FORMS_LILLY_RELEASE_NOTIFICATION') }}</h1>
            </div>
          </ModalColumn>
          <ModalColumn
            :class="{ 'modal-column__is-lilly-release': isLilly }"
            :size="isLilly ? uiColumnSize.TwoThirds : undefined"
          >
            <template #header>
              <h2 class="modal-column__header" v-if="releaseNotes">
                {{ releaseNotes.BlockTitle }}
              </h2>
            </template>
            <div class="content-modal-wrapper">
              <ContentBlock
                :url="releaseNotes.Url"
                :modal="true"
                :force="true"
                :animation="false"
              ></ContentBlock>
            </div>
          </ModalColumn>
        </ModalColumnContainer>
      </template>
    </StandardDialogModal>
  </div>
</template>
<style lang="scss" src="./modal-release-notes.scss" scoped></style>

<script lang="ts" setup>
import lillyAnimationData from '@/src/core/assets/lottie/lilly-release.json';
import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import ContentBlock from '@/src/core/components/ui/content-block/content-block.vue';
import LottieAnimation from '@/src/core/components/ui/lottie-animation/lottie-animation.vue';
import { UiColumnSize, UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useReleaseNotesStore } from '@/src/core/stores/release-notes';
import { useUserStore } from '@/src/profile/stores/user';
import { computed, onMounted, onUnmounted } from 'vue';

const textK = useText();
const releaseNotesStore = useReleaseNotesStore();
const userStore = useUserStore();

const uiSize = UiSize;
const uiVariant = UiVariant;
const uiColumnSize = UiColumnSize;

const releaseNotes = computed(() => releaseNotesStore.releaseNotes);

const isLilly = computed(() => releaseNotesStore.releaseNotes.IsLillyStyling);

onMounted(() => {
  releaseNotesStore.fetchReleaseNotes();
});

onUnmounted(() => {
  userStore.releaseNotesSeen();
});
</script>
