import { SellerApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { Address, B2BUnit } from '@/src/core/types/api';
import { defineStore } from 'pinia';

interface DeliveryAddressesQuotesState {
  allDeliveryAddresses: Address[];
  isBusy: boolean;
}

export const useDeliveryAddressesQuotesStore = defineStore('deliveryAddressesQuotes', {
  state: (): DeliveryAddressesQuotesState => ({
    allDeliveryAddresses: [],
    isBusy: false,
  }),
  getters: {
    defaultDeliveryAddressId(state) {
      return state.allDeliveryAddresses.find((addr) => addr.DefaultAddress)?.Id;
    },
    isShippingAddressRestricted(state) {
      return state.allDeliveryAddresses.find((addr) => addr.ShippingAddress)?.RestrictedAddress;
    },
  },
  actions: {
    currentCostCenterUpdated(payload: { AllCostCenters: B2BUnit }) {
      this.allDeliveryAddresses = payload.AllCostCenters?.Addresses;
    },
    async fetchDeliveryAddresses(payload: { sellerId: string }) {
      this.isBusy = true;
      const { IsSuccess, Data } = await Req(
        {
          url: SellerApi.FetchDeliveryAddresses(payload.sellerId),
        },
        undefined,
        SellerApi.handleDeliveryAddresses.bind(SellerApi, {
          url: SellerApi.FetchDeliveryAddresses(payload.sellerId),
          id: payload.sellerId,
        }),
      );

      if (IsSuccess) {
        this.currentCostCenterUpdated({ AllCostCenters: Data });
      }

      this.isBusy = false;
    },
  },
});
