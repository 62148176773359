<template>
  <div
    class="cta-box-module-wrapper"
    :class="{ hovered: hasLink && hover, 'has-link': hasLink }"
    @mouseover="mouseOver"
    @mouseout="mouseOut"
  >
    <slot :hovered="hover" />
  </div>
</template>

<style lang="scss" src="./cta-box-module.scss"></style>

<script lang="ts" setup>
import { ref } from 'vue';

interface Props {
  hasLink?: boolean;
}

withDefaults(defineProps<Props>(), {
  hasLink: false,
});

const emit = defineEmits(['hovered']);
const hover = ref(false);

const mouseOver = () => {
  hover.value = true;
  emit('hovered', true);
};

const mouseOut = () => {
  hover.value = false;
  emit('hovered', false);
};
</script>
