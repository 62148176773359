import { defineStore } from 'pinia';
import { ref } from 'vue';
import { UmbracoApi } from '../api';
import { getEnv } from '../services/environment';
import { Req } from '../services/requester';
import { MenuQuicklinkGroup } from '../types/api';

export const useSolutionMenuStore = defineStore('solution-menu', () => {
  const quickLinks = ref<MenuQuicklinkGroup[]>([] as MenuQuicklinkGroup[]);
  const isBusy = ref<boolean>(false);

  const isBusyUpdated = (payload: { IsBusy: boolean }) => {
    isBusy.value = payload.IsBusy;
  };

  const setQuickLinks = (payload: { quickLinks: MenuQuicklinkGroup[] }) => {
    quickLinks.value = [];
    quickLinks.value.push(...payload.quickLinks);
  };

  const fetchQuickLinks = async () => {
    isBusyUpdated({ IsBusy: true });
    const { IsSuccess, Data } = await Req({
      url: UmbracoApi.getMenuQuickLinks,
      method: 'POST',
    });

    if (IsSuccess) {
      const quickLinks = Data as MenuQuicklinkGroup[];
      const withEnvironmentIconUrls = quickLinks.map((menu) => ({
        ...menu,
        Items: menu.Items.map((item) => ({
          ...item,
          Icon: `${getEnv('VUE_APP_HOST_UMBRACO') ?? ''}${item.Icon}`,
        })),
      }));

      setQuickLinks({ quickLinks: withEnvironmentIconUrls });
    }
    isBusyUpdated({ IsBusy: false });
  };

  return {
    fetchQuickLinks,
    isBusy,
    quickLinks,
  };
});
