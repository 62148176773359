import { ProductRule } from '@/src/core/apim';

/**
 * Maps product rules to stock, price visibility, order button visibility, and rule status.
 *
 * @param {ProductRule} productRule - The SAP CC product rule object.
 * @returns {object} - An object containing showStock, showPrice, showOrderButton, and ruleStatus.
 */
export const getProductRuleMapping = (productRule: ProductRule) => {
  const showStock = productRule?.stockVisible === 'YES';
  const showPrice = productRule?.priceVisible === 'YES';
  const showOrderButton = productRule?.sellable === 'YES';
  let ruleStatus: string = 'Restricted';

  if (showOrderButton) {
    ruleStatus = 'OK';
  } else if (productRule?.sellable === 'LIMITED_BY_CROSS_PLANT_MATERIAL_STATUS' && showPrice) {
    ruleStatus = 'Out of stock';
  } else if (productRule?.sellable === 'LIMITED_BY_CROSS_PLANT_MATERIAL_STATUS') {
    ruleStatus = 'CPMS';
  } else if (
    productRule?.sellable === 'NO_PRICE_AVAILABLE' ||
    productRule?.sellable === 'NO_PRICE_AVAILABLE_BUT_QUOTABLE'
  ) {
    ruleStatus = 'NOPRICE';
  } else if (productRule?.sellable === 'CROSS_PLANT_MATERIAL_STATUS_OUT_OF_STOCK') {
    ruleStatus = 'CPMS30';
  }

  return {
    showStock,
    showPrice,
    showOrderButton,
    ruleStatus,
  };
};
