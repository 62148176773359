<template>
  <NavigationDropdownMenu
    :title="!asm.Active ? user.Name : textK('UI_COMMON_IMPERSONATING')"
    v-ui-test="'header-user-name'"
  >
    <NavigationDropdownMenuItem
      icon="ui-profile"
      :data-suppress="true"
      v-if="user || asm.Active"
      :route="{ name: 'profile' }"
      v-ui-test="'profile-menu-profile-link'"
    >
      {{ textK('UI_PROFILE_PROFILEDETAILS') }}
    </NavigationDropdownMenuItem>

    <NavigationDropdownMenuItem :raw="true">
      <NavigationDropdownMenu :title="'Current account'" v-if="linkedAccounts.length > 1">
        <template v-for="(account, i) in linkedAccounts">
          <NavigationDropdownMenuItem
            class="profile-menu__companies__item"
            v-if="i < maxCountShown || showAllAccounts"
            :class="{ selected: account.CurrentAccount }"
            :icon="account.CurrentAccount || hovered === i ? 'ui-airplane' : 'ui-circle'"
            :key="account.CompanyUid"
            @mouseover="hovered = i"
            @mouseleave="hovered = -1"
            @click.prevent="
              switchAccount(
                account.CompanyUid,
                account.CompanyName + ' (' + getFormattedChild(account) + ')',
              )
            "
            v-ui-test="'account:' + account.CompanyUid"
          >
            <span class="profile-menu__company__title" v-ui-test="'profile-menu-company-name'">{{
              account.CompanyName
            }}</span>
            <span class="profile-menu__company__number" v-ui-test="'profile-menu-company-id'">{{
              getFormattedChild(account)
            }}</span>
          </NavigationDropdownMenuItem>
        </template>
        <NavigationDropdownMenuItem
          class="toggle-accounts"
          icon="ui-circle"
          key="toggleAccounts"
          v-if="maxCountShown < linkedAccounts.length"
        >
          <a
            class="toggle-account-switch"
            @click="showAllAccounts = !showAllAccounts"
            v-ui-test="'toggle-accounts'"
          >
            <template v-if="!showAllAccounts">Show all ({{ linkedAccounts.length }})</template>
            <template v-else>Show less</template>
          </a>
        </NavigationDropdownMenuItem>
      </NavigationDropdownMenu>
    </NavigationDropdownMenuItem>

    <NavigationDropdownMenuItem :raw="true">
      <NavigationDropdownMenu
        :title="currentAccount.CompanyName"
        :subtitle="getFormattedChild(currentAccount)"
        v-ui-test="'header-company'"
      >
        <NavigationDropdownMenuItem
          icon="ui-settings"
          :route="{ name: 'companydetails' }"
          v-ui-test="'profile-menu-account-link'"
        >
          {{ textK('UI_PROFILE_MENU_ACCOUNT_SETTINGS') }}
        </NavigationDropdownMenuItem>
        <NavigationDropdownMenuItem
          v-if="isAdmin"
          icon="ui-user-administration"
          :route="{ name: 'admin' }"
          v-ui-test="'profile-menu-admin-link'"
        >
          {{ textK('UI_ADMINPAGE_TITLE') }}
        </NavigationDropdownMenuItem>
      </NavigationDropdownMenu>
    </NavigationDropdownMenuItem>

    <NavigationDropdownMenuItem :raw="true">
      <NavigationDropdownMenu>
        <NavigationDropdownMenuItem
          :button="true"
          @click="openModal({ modalComponent: 'ModalLogout' })"
          icon="ui-logout"
          v-ui-test="'profile-menu-logout'"
        >
          {{ textK('UI_PROFILE_MENU_LOG_OUT') }}
        </NavigationDropdownMenuItem>
      </NavigationDropdownMenu>
    </NavigationDropdownMenuItem>
  </NavigationDropdownMenu>
</template>

<style lang="scss" src="./profile-menu.scss" scoped />

<script lang="ts" setup>
import NavigationDropdownMenuItem from '@/src/core/components/navigation/navigation-dropdown/navigation-dropdown-menu/navigation-dropdown-menu-item/navigation-dropdown-menu-item.vue';
import NavigationDropdownMenu from '@/src/core/components/navigation/navigation-dropdown/navigation-dropdown-menu/navigation-dropdown-menu.vue';
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useUIStore } from '@/src/core/stores/ui';
import { UserLinkedAccount } from '@/src/core/types/api';
import { useUserStore } from '@/src/profile/stores/user';
import { computed, onMounted, ref } from 'vue';

interface Props {
  isAsm: boolean;
}

withDefaults(defineProps<Props>(), {
  isAsm: true,
});

const hovered = ref(-1);
const showAllAccounts = ref(false);
const maxCountShown = ref(3);

const textK = useText();

const authenticationStore = useAuthenticationStore();
const openModal = useModal();
const uiStore = useUIStore();
const userStore = useUserStore();

const user = computed(() => {
  return userStore.currentUser;
});

const asm = computed(() => {
  return authenticationStore.asm;
});

const linkedAccounts = computed(() => {
  return userStore.linkedAccounts;
});

const currentAccount = computed(() => {
  return linkedAccounts.value.filter((account) => account.CurrentAccount)[0];
});

const isAdmin = computed(() => {
  return authenticationStore.isAuthenticated && authenticationStore.isAdmin;
});

const switchAccount = async (unitUid: string, unitName: string) => {
  if (user.value.ParentUnitUid !== unitUid) {
    await userStore.switchAccountDetect({
      user: user.value,
      unitUid,
      unitName,
    });
    uiStore.setNavigationDropdownId({ id: null });
    await userStore.fetchUserProfile();
  }
};

const getFormattedChild = (account: UserLinkedAccount) => {
  const redChildUnitUid = account?.RedChildUnit?.formattedUID ?? '';
  const blueChildUnitCic = account?.BlueChildUnit?.Cic ?? '';
  return redChildUnitUid && blueChildUnitCic
    ? `${redChildUnitUid}, ${blueChildUnitCic}`
    : redChildUnitUid || blueChildUnitCic;
};

onMounted(() => {
  if (!user.value?.Uid) {
    userStore.fetchUserProfile();
  }
});
</script>
