<template>
  <div class="solution-item">
    <div class="solution-item__label" v-if="$slots.label">
      <slot name="label" />
    </div>
    <div class="solution-item__ticker" v-if="$slots.ticker">
      <slot name="ticker" />
    </div>
    <div class="solution-item__image">
      <slot name="image" />
    </div>
    <span class="solution-item__title">
      <slot name="title" />
    </span>
    <span class="solution-item__text">
      <slot name="text" />
    </span>
  </div>
</template>

<style lang="scss" src="./solution-item.scss" scoped />
