<template>
  <div class="list-details" v-if="userLoaded">
    <AppDefaultLayout>
      <template #header>
        <header class="list-header">
          <h2>{{ textK('UI_ADMINPAGE_EDIT') }}</h2>
        </header>
      </template>

      <template #default>
        <ColumnLayout>
          <Column>
            <PanelLayout>
              <template #header>
                <h4>{{ textK('UI_PROFILE_DETAILS') }}</h4>
              </template>
              <UserProfile
                @userInput="handleInputChange"
                @valid="formIsValid = $event"
                :require-phone="false"
                :user="user"
                :is-busy="!user && userStore.isBusy"
                :disabled="!!user && userStore.isBusy"
                :show-email="isProvisionedByAirbusWorld"
              />
            </PanelLayout>
            <PanelLayout>
              <template #header>
                <h4>{{ textK('UI_ADMINPAGE_ROLES') }}</h4>
              </template>
              <UserRoles :user="user" :is-busy="isBusy"></UserRoles>
            </PanelLayout>
          </Column>
          <Column>
            <PanelLayout>
              <template #header>
                <h4>{{ textK('UI_PROFILE_DETAILS_LOGIN_DETAILS') }}</h4>
              </template>
              <template #headerDetails>
                <p>{{ textK('UI_PROFILE_DETAILS_LOGIN_DETAILS_TEXT') }}</p>
              </template>
              <UserLoginDetails :user="user" :is-busy="isBusy" />
            </PanelLayout>
          </Column>
        </ColumnLayout>
      </template>
    </AppDefaultLayout>

    <transition name="fade">
      <StickyFooter v-if="userLoaded">
        <template #right>
          <VButton
            v-ui-test="'button-save-user'"
            @click="saveChanges"
            :context="uiContext.ShopSite"
            :size="uiSize.Large"
            :disabled="!formIsValid"
            :loading="isBusy"
          >
            {{ textK('UI_COMMON_EDIT_SAVE') }}
          </VButton>
        </template>
      </StickyFooter>
    </transition>
  </div>
</template>

<style lang="scss" src="./user-details.scss"></style>

<script setup lang="ts">
import { useAdminStore } from '@/src/account/stores/admin';
import { UiContext, UiSize } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';

import { useUserStore } from '@/src/profile/stores/user';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';

import VButton from '@/src/core/components/ui/button/button.vue';
import ColumnLayout from '@/src/core/components/ui/column-layout/column-layout.vue';
import Column from '@/src/core/components/ui/column-layout/column/column.vue';
import PanelLayout from '@/src/core/components/ui/panel-layout/panel-layout.vue';
import StickyFooter from '@/src/core/components/ui/sticky-footer/sticky-footer.vue';
import AppDefaultLayout from '@/src/core/layouts/app-default-layout/app-default-layout.vue';
import { useUIStore } from '@/src/core/stores/ui';
import UserLoginDetails from '@/src/profile/components/user/user-login-details/user-login-details.vue';
import UserProfile from '@/src/profile/components/user/user-profile/user-profile.vue';
import UserRoles from '@/src/profile/components/user/user-roles/user-roles.vue';
import { useRoute, useRouter } from 'vue-router';

const adminStore = useAdminStore();
const uiStore = useUIStore();
const userStore = useUserStore();

const textK = useText();
const uiSize = UiSize;
const uiContext = UiContext;

const userLoaded = ref(false);
const formIsValid = ref(false);

const router = useRouter();
const route = useRoute();

const userId = ref(route.params && route.params.userid);

const user = computed(() => {
  return adminStore.currentUser;
});

const isBusy = computed(() => adminStore.isBusy);
const accountId = computed(() => userStore.accountId);

const isProvisionedByAirbusWorld = computed(() => {
  return userStore.provisionedByAirbusWorld;
});

const handleInputChange = ({ fieldName, value }: { fieldName: string; value: string }) => {
  user.value[fieldName] = value;
};

const saveChanges = async (ev: Event) => {
  ev.preventDefault();
  if (user.value.GlobalId) {
    await adminStore.updateUser({ user: user.value });
    await router.push({ name: 'admin' });
  }
};

const accountChange = (newId: string, oldId: string) => {
  if (!!oldId && newId !== oldId) {
    const key: string = 'parent';
    if (route.matched[1].props[key]) {
      router.push({ name: route.matched[1].props[key] });
    } else if (uiStore.lastMarketRoute) {
      router.push({ path: uiStore.lastMarketRoute });
    }
  }
};

watch(accountId, accountChange);

onMounted(() => {
  adminStore.fetchCurrentUserDetails({ userId: userId.value }).then(() => {
    userLoaded.value = true;
  });
});

onBeforeUnmount(() => {
  adminStore.resetCurrentUserDetails();
});
</script>
