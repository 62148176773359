<template>
  <div>
    <div class="input-container">
      <VTextField
        @valid="setValid('FirstName', $event)"
        @update:modelValue="handleInput('FirstName', $event)"
        v-ui-test="'profile-details-firstname'"
        :disabled="disabled || userData.ProvisionedByAirbusWorld"
        :skeleton="isBusy"
        :type="uiVariant.Secondary"
        :label="textK('UI_COMMON_FIRSTNAME')"
        v-model="userData.FirstName"
        :required="true"
        name="fname"
        autocomplete="given-name"
      />
    </div>
    <div class="input-container">
      <VTextField
        @valid="setValid('LastName', $event)"
        @update:modelValue="handleInput('LastName', $event)"
        v-ui-test="'profile-details-lastname'"
        :disabled="disabled || userData.ProvisionedByAirbusWorld"
        :skeleton="isBusy"
        :type="uiVariant.Secondary"
        :label="textK('UI_COMMON_LASTNAME')"
        v-model="userData.LastName"
        :required="true"
        name="lname"
        autocomplete="family-name"
      />
    </div>

    <div class="input-container" v-if="showEmail">
      <VTextField
        @valid="setValid('Email', $event)"
        @update:modelValue="handleInput('Email', $event)"
        v-ui-test="'profile-details-email'"
        :disabled="disabled || userData.ProvisionedByAirbusWorld"
        :skeleton="isBusy"
        :type="uiVariant.Secondary"
        :label="textK('UI_COMMON_EMAIL')"
        v-model="userData.Email"
        :required="true"
        input-type="email"
        name="email"
        autocomplete="email"
      />
    </div>

    <div v-if="!isBusy">
      <div class="input-container">
        <WorkAreaSelect
          @update:modelValue="handleInput('PrimaryWorkArea', $event)"
          v-ui-test="'profile-details-primary-work-area'"
          :disabled="disabled"
          v-model="userData.PrimaryWorkArea"
        />
      </div>
      <div class="input-container">
        <TimeZoneSelect
          @update:modelValue="handleInput('TimeZone', $event)"
          v-ui-test="'profile-details-timezone'"
          :disabled="disabled"
          v-model="userData.TimeZone"
        ></TimeZoneSelect>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./user-profile.scss" scoped></style>

<script lang="ts" setup>
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import TimeZoneSelect from '@/src/core/components/ui/timezone-select/timezone-select.vue';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import WorkAreaSelect from '@/src/core/components/ui/workarea-select/workarea-select.vue';
import useText from '@/src/core/hooks/useText';
import { User } from '@/src/core/types/api';
import { computed, ref, watch } from 'vue';

interface Props {
  user: User;
  isBusy: boolean;
  disabled?: boolean;
  showEmail?: boolean;
  requirePhone?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  requirePhone: true,
  showEmail: false,
  disabled: false,
});

const textK = useText();

const emit = defineEmits(['valid', 'userInput']);
const userData = computed(() => props.user);

const validatationCollection = ref({});
const allValid = ref(false);
const uiVariant = UiVariant;

const setValid = (key: string, valid: boolean) => {
  validatationCollection.value = Object.assign(
    {},
    validatationCollection.value,
    (validatationCollection.value[key] = valid),
  );
};

const profileValid = () => {
  if (!props.user) {
    return false;
  }

  if (!props.user.LastName?.trim()) {
    return false;
  }

  if (!props.user.FirstName?.trim()) {
    return false;
  }

  return !Object.keys(validatationCollection.value).find(
    (key) => validatationCollection.value[key] === false,
  );
};

const handleInput = (fieldName: string, value: string) => {
  emit('userInput', { fieldName, value });
};

watch(
  validatationCollection,
  () => {
    if (allValid.value !== profileValid()) {
      allValid.value = profileValid();
      emit('valid', allValid.value);
    }
  },
  { deep: true },
);
</script>
