<template>
  <div class="seller-conversation">
    <VButton
      v-if="hasMoreMessages"
      @click="loadMore()"
      class="seller-conversation__load-more"
      :size="uiSize.Small"
      :type="uiVariant.Secondary"
      >Load older messages</VButton
    >
    <div class="seller-conversation__list">
      <SellerConversationItem
        class="list-animation-item"
        v-for="(message, i) in messages"
        :key="i"
        :message="message"
        :offer-id="id"
        :thread-id="threadId"
        :is-quote="!!quoteCode"
        :order-code="orderCode"
        :consignment-code="consignmentCode"
      />
    </div>
  </div>
</template>

<style lang="scss" src="./seller-conversation.scss" scoped />

<script lang="ts">
import { Vue, Component, Prop, toNative } from 'vue-facing-decorator';
import ListAnimation from '@/src/core/components/ui/animations/list-animation/list-animation.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import { MarketplaceMessagingConsignemtThreads } from '@/src/core/types/api';
import SellerConversationIndication from '@/src/market/components/seller/seller-conversation/seller-conversation-indication/seller-conversation-indication.vue';
import SellerConversationItem from '@/src/market/components/seller/seller-conversation/seller-conversation-item/seller-conversation-item.vue';
import { useOrderDetailsStore } from '@/src/market/stores/order-details';
import { useSellerConversationStore } from '@/src/market/stores/seller-conversation';
import { useSellerQuoteConversationStore } from '@/src/market/stores/seller-quote-conversation';

@Component({
  components: {
    ListAnimation,
    SellerConversationItem,
    SellerConversationIndication,
    VButton,
  },
})
export class SellerConversation extends Vue {
  @Prop() public id: string;
  @Prop() public thread: MarketplaceMessagingConsignemtThreads;
  @Prop() public quoteCode: string;

  public sellerConversationStore = useSellerConversationStore();
  public sellerQuoteConversationStore = useSellerQuoteConversationStore();
  public uiVariant = UiVariant;
  public uiSize = UiSize;
  public orderDetailsStore = useOrderDetailsStore();

  public get orderCode() {
    return this.thread && this.orderDetailsStore.order.Code;
  }

  public get threadId() {
    return this.thread?.ThreadId;
  }

  public get consignmentCode() {
    return this.thread?.ConsignmentId;
  }

  public get messages() {
    return !this.quoteCode
      ? this.sellerConversationStore.messages
      : this.sellerQuoteConversationStore.messages;
  }

  public get hasMoreMessages() {
    return (
      this.sellerConversationStore.messages?.length > 0 &&
      this.sellerConversationStore.messages?.length <
        parseInt(this.sellerConversationStore.pagination.TotalResults, 10)
    );
  }

  public loadMore() {
    this.$emit('beforeLoad');
    this.sellerConversationStore
      .getMoreMessages({ id: this.id, orderCode: this.orderCode })
      .then(() => {
        this.$nextTick(() => {
          this.$emit('afterLoad');
        });
      });
  }

  public mounted() {
    if (!this.quoteCode) {
      this.sellerConversationStore
        .getMessages({ id: this.id, orderCode: this.orderCode })
        .then(() => {
          this.$nextTick(() => {
            this.$emit('afterLoad');
          });
        });
    } else {
      this.sellerQuoteConversationStore.getMessages({ quoteCode: this.quoteCode }).then(() => {
        this.$nextTick(() => {
          this.$emit('afterLoad');
        });
      });
    }
  }

  public beforeDestroy() {
    this.sellerConversationStore.resetMessages();
  }
}

export default toNative(SellerConversation);
</script>
