<template>
  <StandardDialogModal
    class="list-modal"
    :size="allLists.length > 5 ? uiSize.Large : uiSize.Medium"
  >
    <template #header>
      <h3>
        {{
          currentParams.Quote
            ? textK('UI_QUOTES_ADD_PRODUCT_TO_QUOTES_DRAFT_MODAL')
            : textK('UI_LIST_ADD_PRODUCT_TO_LIST_MODAL')
        }}
      </h3>
    </template>
    <template #body>
      <ModalColumnContainer>
        <ModalColumn>
          <template #header>
            <h6>
              {{
                currentParams.Quote
                  ? textK('UI_QUOTES_RECENTLY_USED_QUOTES_DRAFTS_HEADER')
                  : textK('UI_LIST_RECENTLY_USED_LIST_HEADER')
              }}
            </h6>
          </template>
          <ul class="add-to-list__list">
            <template v-if="recentlyUsedLists">
              <li
                v-for="recentlyUsed in recentlyUsedLists.slice(0, 5)"
                :key="recentlyUsed.Code"
                class="add-to-list__list__item"
              >
                <SimpleListItem @click="selected(recentlyUsed)"
                  >{{ recentlyUsed.Name }} ({{ recentlyUsed.NumberOfEntries }})</SimpleListItem
                >
              </li>
            </template>
            <li v-if="recentlyUsedLists.length <= 0" class="add-to-list__list__item">
              <small
                ><i>{{
                  currentParams.Quote
                    ? textK('UI_QUOTES_NO_QUOTES_DRAFTS_CREATE_NEW')
                    : textK('UI_LIST_NO_LIST_CREATE_NEW')
                }}</i></small
              >
            </li>
          </ul>
          <div class="add-new-list-option">
            <TransitionExpand mode="out-in" @after-enter="$refs.listName.setFocus()">
              <VButton
                :type="uiVariant.Secondary"
                v-ui-test="'addtolist-add-new'"
                v-if="!createNewList"
                @click="createNewList = true"
                class="add-new-list-option__button"
                >{{
                  currentParams.Quote
                    ? textK('UI_QUOTES_ADD_TO_NEW_QUOTES_DRAFT')
                    : textK('UI_LIST_ADD_TO_NEW_LIST')
                }}</VButton
              >

              <div class="" v-else-if="createNewList">
                <form @submit.prevent="createList">
                  <InputGroup class="create-new-container">
                    <VTextField
                      v-ui-test="'addtolist-new-list-name'"
                      :required="true"
                      ref="listName"
                      :type="uiVariant.Secondary"
                      name="List name"
                      :label="textK('UI_COMMON_NAME')"
                      v-model="listName"
                    >
                    </VTextField>
                    <VButton
                      v-ui-test="'addtolist-new-list-save'"
                      @click="createList"
                      :type="uiVariant.Default"
                      :loading="isBusy"
                      :disabled="listName.length <= 0"
                      class="add-new-list-option__save-button"
                      >{{ textK('UI_COMMON_CREATE_AND_ADD') }}</VButton
                    >
                  </InputGroup>
                </form>
              </div>
            </TransitionExpand>
          </div>
        </ModalColumn>

        <ModalColumn v-if="allLists.length > 5">
          <template #header>
            <h6>{{ allListLabel }}</h6>
          </template>
          <SearchList
            :ui-test-name="'addtolist-search-lists'"
            :data="allLists"
            :search-keys="['Name']"
            :bind-key="'Code'"
            :label="searchListLabel"
            @selected="selected"
          >
            <template #default="{ item }">
              <SimpleListItem :key="item.Code"
                >{{ item.Name }} ({{ item.NumberOfEntries }})
              </SimpleListItem>
            </template>
          </SearchList>
        </ModalColumn>
      </ModalColumnContainer>
    </template>
  </StandardDialogModal>
</template>

<style lang="scss" src="./modal-add-to-list.scss" scoped></style>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import InputGroup from '@/src/core/components/ui/form/input-group/input-group.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import SearchList from '@/src/core/components/ui/search-list/search-list.vue';
import SimpleListItem from '@/src/core/components/ui/simple-list-item/simple-list-item.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { TextKey } from '@/src/core/services/text-key';
import { useModalStore } from '@/src/core/stores/modal';
import { IList, IQuoteDrafts } from '@/src/core/types/interfaces';
import { useListsStore } from '@/src/market/stores/list';
import { useQuoteDraftsStore } from '@/src/market/stores/quote-drafts';

@Component({
  components: {
    TransitionExpand,
    StandardDialogModal,
    ModalColumnContainer,
    ModalColumn,
    SimpleListItem,
    SearchList,
    VButton,
    VTextField,
    InputGroup,
  },
})
export class ModalAddToList extends Vue {
  public textK = useText();
  public listsStore = useListsStore();
  public quoteDraftsStore = useQuoteDraftsStore();
  public modalStore = useModalStore();

  public uiSize = UiSize;
  public uiVariant = UiVariant;
  public listName: string = '';
  public createNewList: boolean = false;

  public $refs: {
    listName: any;
  };

  public selected(list: IList) {
    const code = this.currentParams.Code;

    if (code && this.currentParams.Quote) {
      this.quoteDraftsStore.addProductToList({
        listCode: list.Code,
        productId: code,
        quantity: this.currentParams.Quantity,
        notify: true,
        isQuoteList: true,
      });
      this.modalStore.closeModal();
    } else if (code) {
      this.listsStore.addProductToList({
        listCode: list.Code,
        productId: code,
        notify: true,
        quantity: this.currentParams.Quantity,
      });
      this.modalStore.closeModal();
    }
  }

  public get currentParams(): IModalTracking {
    return this.modalStore.params as IModalTracking;
  }

  public get isBusy(): boolean {
    return this.listsStore.isBusy || this.quoteDraftsStore.isBusy;
  }

  public get recentlyUsedLists(): IList[] | IQuoteDrafts[] {
    if (this.currentParams.Quote) {
      return this.quoteDraftsStore.recentlyUsedLists;
    } else {
      return this.listsStore.recentlyUsedLists;
    }
  }

  public get allLists(): IList[] | IQuoteDrafts[] {
    if (this.currentParams.Quote) {
      return this.quoteDraftsStore.allQuoteLists;
    } else {
      return this.listsStore.allLists;
    }
  }

  public get allListLabel(): string {
    const isQuote = this.currentParams.Quote;
    if (isQuote) {
      return TextKey('UI_QUOTES_ALL_QUOTE_DRAFTS');
    } else {
      return TextKey('UI_LIST_ALL_LISTS');
    }
  }

  public get searchListLabel(): string {
    const isQuote = this.currentParams.Quote;
    if (isQuote) {
      return TextKey('UI_QUOTES_SEARCH_IN_DRAFTS');
    } else {
      return TextKey('UI_LIST_SEARCH_IN_LISTS');
    }
  }

  private async fetchAllLists() {
    if (this.currentParams.Quote) {
      this.quoteDraftsStore.fetchAllQuoteLists({ updateCurrent: true });
      this.quoteDraftsStore.fetchRecentLists();
    } else {
      this.listsStore.fetchAllLists({ updateCurrent: true });
      this.listsStore.fetchRecentLists();
    }
  }

  public async createList(e: Event) {
    e.preventDefault();

    if (this.isBusy) {
      return;
    }

    if (this.currentParams.Quote) {
      const qResult = await this.quoteDraftsStore.createList({ listName: this.listName });
      if (qResult.IsSuccess) {
        this.selected(qResult.Data);
      }
    } else {
      const result = await this.listsStore.createList({ listName: this.listName });
      if (result.IsSuccess && !result.Data?.HasError) {
        this.selected(result.Data);
      }
    }
  }

  public mounted() {
    this.fetchAllLists();
  }
}

interface IModalTracking {
  Code: string;
  SellerName: string;
  ActionView: string;
  Quote: boolean;
  Quantity?: number;
}

export default toNative(ModalAddToList);
</script>
