<template>
  <StandardDialogModal :size="uiSize.Small">
    <template #header>
      <div class="typeset">
        <h3 class="status-info">
          {{ textK('UI_PRODUCT_MATERIAL_STATUS_INFO') }}
        </h3>
        <p>{{ materialStatusTextComputed }}</p>
      </div>
    </template>
  </StandardDialogModal>
</template>

<style lang="scss" src="./modal-material-status.scss" scoped></style>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { materialStatusText } from '@/src/market/services/material-status';
import { computed } from 'vue';

export interface MaterialStatusModalParams {
  airbusCrossPlantMaterialStatus: string;
}

const textK = useText();
const modalStore = useModalStore();
const uiSize = UiSize;

const params = computed(() => modalStore.params as MaterialStatusModalParams);

const materialStatus = computed(() => params.value?.airbusCrossPlantMaterialStatus);

const materialStatusTextComputed = computed(() => materialStatusText(materialStatus.value));
</script>
