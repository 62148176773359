<template>
  <VButton v-ui-test="uiTestTag" :type="type" :size="size" @click.stop="openModal(modalParams)">
    {{ text }}
  </VButton>
</template>

<script setup lang="ts">
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useModal from '@/src/core/hooks/useModal';
import { IModal } from '@/src/core/types/ui';

export interface Props {
  type?: UiVariant;
  size?: UiSize;
  text: string;
  modalParams: IModal;
  uiTestTag?: string;
}

withDefaults(defineProps<Props>(), {
  type: UiVariant.Secondary,
  size: UiSize.Auto,
  text: '',
  uiTestTag: 'request-quote-button',
});

const openModal = useModal();
</script>
