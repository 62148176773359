<template>
  <div
    class="content-navigation-list-item"
    :class="{ 'content-navigation-list-item--large': size === uiSize.Large }"
    v-if="navigationItem"
  >
    <div class="content-navigation-list-item__name">
      <a
        v-if="!noLink"
        class="content-navigation-list-item__name__link"
        :class="{ active: active, 'exact-active': exactActive }"
        :href="routeHref"
        @click="
          () => {
            routeNavigate();
            closeDropdown();
          }
        "
        >{{ navigationItem.Name }}</a
      >
      <span
        v-else
        class="content-navigation-list-item__name__link"
        :class="{ active: active, 'exact-active': exactActive }"
        @click="isExpanded = !isExpanded"
        >{{ navigationItem.Name }}</span
      >
      <span
        v-if="navigationItem.SubItems.length > 0"
        class="content-navigation-list-item__expand-toggle"
      >
        <PlusMinusAnimation
          class="content-navigation-list-item__expand-toggle__icon"
          @click.stop.prevent="isExpanded = !isExpanded"
          :open="isExpanded"
        />
      </span>
    </div>
    <TransitionExpand>
      <ContentNavigationList
        v-if="navigationItem.SubItems.length > 0 && isExpanded"
        :navigation="navigationItem.SubItems"
      />
    </TransitionExpand>
  </div>
</template>

<style lang="scss" src="./content-navigation-list-item.scss" scoped />

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import PlusMinusAnimation from '@/src/core/components/ui/ani-icons/plus-minus/plus-minus.vue';
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import { useUIStore } from '@/src/core/stores/ui';
import { NavigationItem } from '@/src/core/types/interfaces';
import ContentNavigationList from '@/src/content/components/content-navigation/content-navigation-list/content-navigation-list.vue';

interface Props {
  size?: UiSize;
  navigationItem?: NavigationItem;
  routeHref: string;
  routeNavigate: () => void;
  active?: boolean;
  exactActive?: boolean;
  noLink?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  size: UiSize.Small,
  routeHref: '',
  routeNavigate: () => {},
  active: false,
  exactActive: false,
  noLink: false,
});

const uiStore = useUIStore();
const uiSize = UiSize;
const isExpanded = ref(false);

const closeDropdown = () => {
  uiStore.setNavigationDropdownId({ id: null });
};

onMounted(() => {
  isExpanded.value = props.active;
});
</script>
