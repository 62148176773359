<template>
  <div class="blog-filters">
    <div class="blog-filters__title-bar">
      <h4>{{ textK('UI_BLOG_FILTERS_TITLE') }}</h4>

      <transition name="fade" mode="out-in">
        <a @click="toggleExpanded()" v-if="filtersCanExpand">
          <template v-if="expanded">
            {{ textK('UI_BLOG_FILTERS_CLOSE_ALL') }}
          </template>
          <template v-else>
            {{ textK('UI_BLOG_FILTERS_SHOW_ALL') }}
          </template>
        </a>
      </transition>
    </div>
    <div class="blog-filters__container">
      <button
        class="blog-filters__filter"
        :class="{
          'blog-filters__filter--selected': filterIsSelected(filter),
        }"
        v-for="(filter, i) in filters"
        :key="i"
        @click="toggleFilter(filter)"
        v-html="filter"
      />
    </div>
  </div>
</template>

<style lang="scss" src="./blog-filters.scss" scoped></style>

<script lang="ts">
import { Vue, Component, Prop, toNative } from 'vue-facing-decorator';
import useText from '@/src/core/hooks/useText';
import { gsap } from 'gsap';
import debounce from 'lodash/debounce';

const TITLE_BAR_HEIGHT = 30;
const BUTTON_HEIGHT = 34;
const GAP = 16;

@Component({
  components: {},
})
export class BlogFilters extends Vue {
  public textK = useText();
  @Prop({ default: [] }) public filters: string[];
  @Prop({ type: Function }) public toggleFilter: (filter: string) => void;
  @Prop({ type: Function }) public filterIsSelected: (filter: string) => boolean;

  public filtersCanExpand = false;

  public resizeChecker() {
    const filterContainer = this.$el.querySelector('.blog-filters__container');
    const filterContainerHeight = filterContainer?.clientHeight || 0;

    this.filtersCanExpand = filterContainerHeight > BUTTON_HEIGHT;
  }

  public debouncedResizeChecker = debounce(this.resizeChecker, 300);

  public mounted() {
    this.resizeChecker();
    window?.addEventListener('resize', this.debouncedResizeChecker);
  }

  public beforeDestroy() {
    window?.removeEventListener('resize', this.debouncedResizeChecker);
  }

  public expanded = true;
  public playing = false;

  public toggleExpanded() {
    if (this.playing) {
      return;
    }

    this.playing = true;

    const container = this.$el;
    const filterContainer = this.$el.querySelector('.blog-filters__container');
    const filterContainerHeight = filterContainer?.clientHeight || 0;
    const shrinkSize = TITLE_BAR_HEIGHT + BUTTON_HEIGHT + GAP;
    const fullSize = TITLE_BAR_HEIGHT + GAP + filterContainerHeight;

    gsap.to(container, {
      height: this.expanded ? shrinkSize : fullSize,
      duration: '.3',
      onComplete: () => {
        this.expanded = !this.expanded;
        this.playing = false;

        if (this.expanded) {
          gsap.set(container, {
            height: 'auto',
          });
        }
      },
    });
  }
}

export default toNative(BlogFilters);
</script>
