<template>
  <span></span>
</template>

<script setup lang="ts">
import { BreakpointWidth } from '@/src/core/settings/media-queries.model';
import { useScrollLockStore } from '@/src/core/stores/scroll-lock';
import { useUIStore } from '@/src/core/stores/ui';
import { computed, onMounted, ref, watch } from 'vue';

const scrollLockStore = useScrollLockStore();
const uiStore = useUIStore();
const HTMLElement = ref<HTMLElement | null>(null);
const noScrollClass = 'no-scroll';
const noScrollSelectClass = 'no-scroll--select';
const breakpointWidth = BreakpointWidth;

const lastScrolledPosition = computed({
  get: () => uiStore.lastScrollPos,
  set: (value) => uiStore.setLastScrollPosition({ scrollPos: value }),
});

const scrollLocked = computed(() => scrollLockStore.isLocked);
const selectIsOpen = computed(() => !!uiStore.dropdownIsOpen);

const isCat = () => breakpointWidth.Cat < window.innerWidth;

const lockScrollPositionHandling = (scrollLocked: boolean, toggleClass: string) => {
  if (HTMLElement.value) {
    if (scrollLocked) {
      mobileScrollLockPositionHandling(scrollLocked);
      HTMLElement.value.classList.add(toggleClass);
    } else {
      HTMLElement.value.classList.remove(toggleClass);
      mobileScrollLockPositionHandling(scrollLocked);
    }
  }
};

const mobileScrollLockPositionHandling = (locked: boolean) => {
  const elements = document.querySelectorAll('.l-app-container');

  if (!elements) {
    return;
  }

  if (locked) {
    lastScrolledPosition.value = window.pageYOffset;
    HTMLElement.value!.style.top = -lastScrolledPosition.value + 'px';
  } else {
    window.scrollTo(0, lastScrolledPosition.value);
    lastScrolledPosition.value = 0;
    HTMLElement.value!.style.top = '';
  }
};

watch(selectIsOpen, (selectOpen) => {
  if (!isCat()) {
    lockScrollPositionHandling(selectOpen, noScrollSelectClass);
  }
});

watch(scrollLocked, (scrollLocked) => {
  lockScrollPositionHandling(scrollLocked, noScrollClass);
});

onMounted(() => {
  HTMLElement.value = document.documentElement;
});
</script>
