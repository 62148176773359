<template>
  <a
    :is="!!routeName ? 'a' : 'div'"
    class="header-logo"
    v-ui-test="'logo-link'"
    :href="routeName || null"
    @click.prevent="closeNavigationDropdown()"
  >
    <SatairLogo class="satair-logo" />
  </a>
</template>

<style lang="scss" src="./header-logo.scss" />

<script lang="ts">
import { Vue, Component, Prop, toNative } from 'vue-facing-decorator';
import SatairLogo from '@/src/core/components/ui/satair-logo/satair-logo.vue';
import { useUIStore } from '@/src/core/stores/ui';

@Component({
  components: {
    SatairLogo,
  },
})
export class SatairHeaderLogo extends Vue {
  @Prop() public routeName: string;
  public uiStore = useUIStore();

  public closeNavigationDropdown() {
    this.uiStore.setNavigationDropdownId({ id: null });
    if (this.routeName) {
      this.goto();
    }
  }

  private goto() {
    if (this.routeName === 'main') {
      if (this.$route.path !== '/') {
        this.$router.push({ path: '/' });
      }
    } else if (this.routeName !== this.$route.name) {
      this.$router.push({ name: this.routeName });
    }
  }
}

export default toNative(SatairHeaderLogo);
</script>
