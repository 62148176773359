<template>
  <div class="quantity-controller">
    <QuantitySelector
      v-if="entry"
      ref="quantitySelector"
      v-model="qty"
      :subtile="subtile"
      :entry="entry"
      :unit="unit"
      :list-id="listId"
      :seller-id="warehouseCode"
      :product-id="productId"
      :bid-or-quote="bidOrQuote"
      :is-marketplace="isMarketplace"
      :is-airbus="isAirbus"
      :procurement-type="procurementType"
      :has-add-to-cart="hasAddToCart"
      :min-value="minValue"
      :step="step"
    />
    <div
      v-if="hasMultipleWarehouse"
      class="quantity-controller__customize"
      v-ui-test="'quantity-controller-customize'"
      @click.stop="
        openModal({
          modalComponent: 'ModalSelectWarehouse',
          params: { offerId: entry?.Product.Id, calculated },
          first: true,
        })
      "
    ></div>
  </div>
</template>

<style lang="scss" src="./quantity-controller.scss" scoped></style>

<script setup lang="ts">
import useModal from '@/src/core/hooks/useModal';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { CartEntry } from '@/src/core/types/api';
import { IListEntry } from '@/src/core/types/interfaces';
import QuantitySelector from '@/src/market/components/ui/quantity-selector/quantity-selector.vue';
import { useCartStore } from '@/src/market/stores/cart';
import { useListsStore } from '@/src/market/stores/list';
import { useProductAdditionalInfoStore } from '@/src/market/stores/product-additional-info';
import debounce from 'lodash/debounce';
import { computed, onMounted, ref, watch } from 'vue';

interface Props {
  productId: string;
  listId?: string;
  subtile?: boolean;
  hasAddToCart?: boolean;
  minValue?: number;
  step?: number;
  unit?: string;
  calculated?: boolean;
  bidOrQuote?: boolean;
  isMarketplace?: boolean;
  actionview?: string;
  fetchAvailibilityList?: boolean;
  isAirbus?: boolean;
  procurementType?: ProcurementType;
}

const props = withDefaults(defineProps<Props>(), {
  productId: undefined,
  listId: undefined,
  subtile: false,
  hasAddToCart: false,
  minValue: 0,
  step: 0,
  unit: undefined,
  calculated: false,
  bidOrQuote: false,
  isMarketplace: false,
  actionview: 'unknown',
  fetchAvailibilityList: false,
  isAirbus: false,
  procurementType: undefined,
});

const openModal = useModal();
const listStore = useListsStore();
const productAdditionalInfoStore = useProductAdditionalInfoStore();
const cartStore = useCartStore();
const qty = ref(0);
const isBusy = ref(false);
const seller = ref('');

const quantitySelector = ref<InstanceType<typeof QuantitySelector>>();

const entry = computed<CartEntry | IListEntry | undefined>(() => {
  if (props.listId) {
    return listStore.getListEntry(props.productId);
  } else {
    return cartStore.getCartEntry(props.productId);
  }
});

const isCartBusy = computed(() => {
  return cartStore.isBusy;
});

const cartQty = computed(() => {
  return entry.value?.Quantity || 0;
});

const hasMultipleWarehouse = computed(() => {
  if (entry?.value && 'HasMultipleWarehouse' in entry.value) {
    return entry.value.HasMultipleWarehouse;
  }

  return false;
});

const cartIndex = computed(() => {
  if (props.listId) {
    return entry.value && entry.value[1];
  } else {
    return cartStore.getCartEntry(props.productId).EntryNumber;
  }
});

const productData = computed(() =>
  productAdditionalInfoStore.additionalDataByOfferId(props.productId),
);

const warehouseCode = computed(() => {
  if (productData.value?.Warehouse?.Code) {
    return productData.value.Warehouse.Code;
  }

  return null;
});

const entryExistsInQueue = computed(() => {
  return (
    cartStore.addToCartQueue.findIndex((queuedItem) => queuedItem.productId === props.productId) >
    -1
  );
});

const quantityHandler = debounce((productId: string, quantity: number, listId?: string) => {
  isBusy.value = false;
  if (quantity > 0) {
    if (listId) {
      listStore.updateProductQuantity({
        listCode: listId,
        productId,
        quantity,
      });
    } else {
      cartStore.addProductToCurrentCart({
        productId,
        quantity,
        entryId: cartIndex.value,
        calculated: props.fetchAvailibilityList,
        warehouse: warehouseCode.value,
      });
    }
  }
}, 400);

watch(qty, (newValue) => {
  if (qty.value === cartQty.value) {
    return;
  }

  isBusy.value = true;

  if (qty.value !== 0) {
    quantityHandler(props.productId, newValue, props.listId);
  }
});

watch(
  entry,
  (newEntry) => {
    seller.value =
      (newEntry as CartEntry)?.OfferInfo?.ShopName ||
      (newEntry as any)?.Product?.SupplierDisplay ||
      '';
  },
  { immediate: true, deep: true },
);

watch(cartQty, (newValue, oldValue) => {
  if (
    (newValue === oldValue && oldValue === qty.value) ||
    qty.value === cartQty.value ||
    entryExistsInQueue.value
  ) {
    return;
  }

  qty.value = cartQty.value;

  if (quantitySelector.value?.activeChange) {
    quantitySelector.value.activeChange = false;
  }
});

onMounted(() => {
  qty.value = cartQty.value;
});

watch(
  () => isCartBusy.value,
  (newValue, oldValue) => {
    // cart has been updated but we don't have an entry quantity
    // or this cart entry does not exist, set quantity to 0
    if (
      oldValue === true &&
      newValue === false &&
      quantitySelector.value?.activeChange &&
      !entry.value?.Quantity
    ) {
      qty.value = 0;
    }
  },
);
</script>
