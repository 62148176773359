<template>
  <div class="product-tabs">
    <Tab v-if="showTabs">
      <TabButton
        v-for="tab in filteredTabs"
        :key="tab.value"
        :active="isActiveTab(tab.value)"
        @click.stop="onTabChanged(tab.value)"
      >
        {{ tab.label }}
      </TabButton>
    </Tab>
    <div class="tab-body">
      <template v-if="isRedOwningSystem">
        <div
          class="product__procurement product__procurement--grid"
          key="red-owning-system-products"
          @click="$emit('itemSelect')"
        >
          <ProductCondition class="product__procurement__item" :product="product" />
          <ProductCertificate class="product__procurement__item" :product="product" />
          <ProductStock class="product__procurement__item" :product="product" />
          <ProductLocation class="product__procurement__item" :product="product" />
          <ProductPrice class="product__procurement__item" :product="product" />
          <ProductCTA class="product__procurement__item" :product="product" :is-active="isActive" />
        </div>
      </template>
      <template v-else-if="hasProcurements">
        <div
          v-for="(procurement, index) in activeProcurements"
          :key="procurement.procurementType + index"
          class="product__procurement"
          :class="{
            'product__procurement--multiple-procurements': hasMultipleProcurements,
            'product__procurement--grid': !hasOverSixColumns,
          }"
          @click="
            productAdditionalData?.IsBusy
              ? null
              : $emit('itemSelect', {
                  procurementType: procurement.procurementType,
                  serialNumber: procurement.serialNumber,
                  index: index,
                })
          "
        >
          <ProductCondition
            class="product__procurement__item"
            :product="product"
            :procurement="procurement"
            :procurement-type="activeProcurementLabel"
          />
          <ProductSerialNumber
            v-if="isBlueOwningSystem && hasSerialNumber"
            class="product__procurement__item"
            :product="product"
            :procurement="procurement"
          />
          <ProductCertificate
            class="product__procurement__item"
            :product="product"
            :procurement="procurement"
          />
          <ProductStock
            class="product__procurement__item"
            :product="product"
            :procurement="procurement"
            :is-fhs="isFhs"
          />
          <ProductLocation
            class="product__procurement__item"
            :product="product"
            :procurement="procurement"
            :is-fhs="isFhs"
          />
          <ProductPrice
            class="product__procurement__item"
            :class="{
              'product__procurement__item--price': hasSerialNumber,
            }"
            :product="product"
            :procurement="procurement"
            :procurement-type="procurement.procurementType"
            :active-tab-label="activeTab"
            :is-fhs="isFhs"
          />
          <ProductCTA
            class="product__procurement__item"
            :product="product"
            :is-active="isActive"
            :procurement="procurement"
            :is-fhs="isFhs"
          />
        </div>
      </template>
      <template v-else>
        <div
          class="product__procurement product__procurement--grid"
          key="red-owning-system-products"
          @click="$emit('itemSelect')"
        >
          <ProductCondition class="product__procurement__item" :product="product" />
          <ProductCertificate class="product__procurement__item" :product="product" />
          <ProductStock class="product__procurement__item" :product="product" />
          <ProductLocation class="product__procurement__item" :product="product" />
          <ProductPrice class="product__procurement__item" :product="product" />
          <ProductCTA class="product__procurement__item" :product="product" />
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" src="./product-tabs.scss" scoped></style>

<script lang="ts" setup>
import TabButton from '@/src/core/components/ui/tab/tab-button/tab-button.vue';
import Tab from '@/src/core/components/ui/tab/tab.vue';
import { TextKey } from '@/src/core/services/text-key';
import { TrackingCache } from '@/src/core/services/tracking-utils-service';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { useTextsStore } from '@/src/core/stores/texts';
import { OwningSystem, Product } from '@/src/core/types/api';
import ProductCertificate from '@/src/market/components/search/search-item/components/product-certificate/product-certificate.vue';
import ProductCondition from '@/src/market/components/search/search-item/components/product-condition/product-condition.vue';
import ProductCTA from '@/src/market/components/search/search-item/components/product-cta/product-cta.vue';
import ProductLocation from '@/src/market/components/search/search-item/components/product-location/product-location.vue';
import ProductPrice from '@/src/market/components/search/search-item/components/product-price/product-price.vue';
import ProductSerialNumber from '@/src/market/components/search/search-item/components/product-serial-number/product-serial-number.vue';
import ProductStock from '@/src/market/components/search/search-item/components/product-stock/product-stock.vue';
import { findProcurementTypes, mapFhsToProcurement } from '@/src/market/services/procurements';
import {
  productAdditionalData as getAdditionalData,
  isAirbus,
} from '@/src/market/services/product-parts';
import { useProductFHSDetailsStore } from '@/src/market/stores/product-fhs-details';
import { computed, onMounted, ref, watch } from 'vue';

interface Props {
  product: Product;
  isActive: boolean;
  isInterchangeableItem: boolean;
  isFhs: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isActive: false,
  isInterchangeableItem: false,
  isFhs: false,
});

const textsStore = useTextsStore();
const fhsDetails = useProductFHSDetailsStore();
const activeTab = ref(ProcurementType.New as string);
const emit = defineEmits(['activeTab', 'update:activeTab', 'itemSelect']);

const tabs = computed(() => {
  const { isBusy } = textsStore;

  if (isBusy) {
    return [];
  }

  if (props.isInterchangeableItem) {
    return [{ label: TextKey('UI_PRODUCT_NEW'), value: ProcurementType.New }];
  }

  return [
    { label: TextKey('UI_PRODUCT_NEW'), value: ProcurementType.New },
    { label: TextKey('UI_PRODUCT_USED'), value: ProcurementType.Used },
    { label: TextKey('UI_PRODUCT_LEASE'), value: ProcurementType.Lease },
    { label: TextKey('UI_PRODUCT_REPAIR'), value: ProcurementType.Repair },
    { label: TextKey('UI_PRODUCT_EXCHANGE'), value: ProcurementType.Exchange },
  ];
});

const onTabChanged = (selectedTab: string) => {
  activeTab.value = selectedTab;
  emit('activeTab', { isDisabled: notSellableProcurements.value });
  emit('update:activeTab', activeTab.value);
  TrackingCache.setProcurementType(activeTab.value);
};

const notSellableProcurements = computed(() => {
  const procurements = [
    ProcurementType.Used,
    ProcurementType.Repair,
    ProcurementType.Lease,
    ProcurementType.Exchange,
    ProcurementType.ExchangeNew,
    ProcurementType.ExchangeUsed,
  ] as string[];

  return procurements.includes(activeTab.value);
});

const isActiveTab = (tabName: string) => {
  return activeTab.value === tabName;
};

const showTabs = computed(() => {
  return (
    isAirbus(productAdditionalData.value) &&
    procurementTypes.value.size > 1 &&
    !props.isInterchangeableItem
  );
});

const productAdditionalData = computed(() => {
  return getAdditionalData(props.product.Id, props.product.OwningSystem);
});

const fhsDetailsProcurements = computed(() => {
  return fhsDetails.getFhsDetailsByProductId(props.product.Id);
});

const procurements = computed(() => {
  if (isAirbus(productAdditionalData.value)) {
    if (props.isFhs && fhsDetailsProcurements.value && fhsDetailsProcurements.value?.length > 0) {
      return mapFhsToProcurement(fhsDetailsProcurements.value);
    }
    return productAdditionalData.value.procurements;
  }

  return [];
});

const procurementTypes = computed(() => {
  const types = findProcurementTypes(procurements.value);
  const uniqueProcurements = new Set(types);

  return uniqueProcurements;
});

const filteredTabs = computed(() => {
  return tabs.value.filter((tab) =>
    tab.value === ProcurementType.Exchange
      ? procurementTypes.value.has(ProcurementType.ExchangeUsed) ||
        procurementTypes.value.has(ProcurementType.ExchangeNew)
      : procurementTypes.value.has(tab.value),
  );
});

const activeProcurements = computed(() => {
  return procurements.value?.filter(({ procurementType }) =>
    activeTab.value === ProcurementType.Exchange
      ? procurementType === ProcurementType.ExchangeUsed ||
        procurementType === ProcurementType.ExchangeNew
      : procurementType === activeTab.value,
  );
});

const activeProcurementLabel = computed(() => {
  return tabs.value.find(({ value }) => {
    const activeTabValue =
      activeTab.value === ProcurementType.ExchangeNew ||
      activeTab.value === ProcurementType.ExchangeUsed
        ? ProcurementType.Exchange
        : activeTab.value;
    return value === activeTabValue;
  })?.label;
});

const isRedOwningSystem = computed(() => {
  return props.product.OwningSystem === OwningSystem.RED;
});

const isBlueOwningSystem = computed(() => {
  return props.product.OwningSystem === OwningSystem.BLUE;
});

const hasProcurements = computed(() => {
  return activeProcurements.value?.length > 0;
});

const hasMultipleProcurements = computed(() => {
  return activeProcurements.value?.length > 1;
});

const hasSerialNumber = computed(() => {
  const procurement = procurements.value.find(
    (procurement) => procurement.procurementType === ProcurementType.Used,
  );

  return activeProcurementLabel.value === 'Used' && procurement?.serialNumber !== undefined;
});

const hasOverSixColumns = computed(() => {
  return isBlueOwningSystem.value && hasSerialNumber.value;
});

watch(procurementTypes, () => {
  if (isAirbus(productAdditionalData.value) && procurementTypes.value.size === 1) {
    const singleProcurement = Array.from(new Set(procurementTypes.value));
    onTabChanged(singleProcurement[0]);
  } else {
    const procurements = Array.from(new Set(procurementTypes.value));
    setDefaultActiveTab(procurements);
  }
});

const setDefaultActiveTab = (procurements: string[]) => {
  const procurementTypes = [
    ProcurementType.New,
    ProcurementType.Used,
    ProcurementType.Lease,
    ProcurementType.Repair,
    ProcurementType.ExchangeNew,
    ProcurementType.ExchangeUsed,
  ] as const;

  activeTab.value = procurementTypes.find((type) => procurements.includes(type)) ?? '';
};

onMounted(() => {
  TrackingCache.setProcurementType(activeTab.value);
  emit('update:activeTab', activeTab.value);
  TrackingCache.setOwningSystem(props.product.OwningSystem);
  TrackingCache.setProductId(props.product.Id);
});
</script>
