<template>
  <div class="modal-request-quote typeset" v-ui-test="'request-quote-modal'">
    <StandardDialogModal :size="uiSize.Large">
      <template #header>
        <ModalRequestQuoteHeader :offer-id="id" :header-title="headerTitle" />
      </template>

      <template #body>
        <div class="modal-request-quote__inner" ref="scrollcontainer">
          <div class="advanced-modal__body">
            <div class="modal-request-quote__body-wrapper">
              <div class="modal-request-quote__body__description">
                <p v-if="hasPrice">
                  {{ sellerName }} {{ textK('UI_QUOTES_REQUEST_BODY_PRICE') }} {{ currency }}/{{
                    unit
                  }}:
                </p>
                <p v-else>{{ sellerName }} {{ textK('UI_QUOTES_REQUEST_BODY_NOPRICE') }}:</p>
              </div>
              <div class="modal-request-quote__input-wrapper">
                <div class="input-container modal-request-quote__input" v-if="hasPrice">
                  <VTextField
                    :required="true"
                    input-type="number"
                    :vmax-length="9"
                    :type="uiVariant.Secondary"
                    :label="textK('UI_QUOTES_REQUEST_PRICE_LABEL')"
                    name="price"
                    :placeholder="price"
                    v-model="quoteRequest.OfferedBasePrice"
                  />
                </div>
                <div class="input-container modal-request-quote__input">
                  <QuantitySelector
                    :avoid-zero-value="true"
                    :min-value="minQty"
                    :max-value="maxQty"
                    :step="stepQty"
                    :as-input="true"
                    v-model="quoteRequest.RequestedQuantity"
                    :unit="textK('UI_QUOTES_REQUEST_QTY_LABEL')"
                    :is-quote-modal="true"
                  ></QuantitySelector>
                </div>
                <div class="input-container modal-request-quote__input price-type">
                  <template v-if="+total > 0">
                    <div class="price-type__currency">
                      {{ textK('UI_QUOTES_REQUEST_TOTAL') }} {{ currency }}
                    </div>
                    <div
                      class="price-type__value"
                      v-ui-test="'request-quote-offer-total-price'"
                      v-html="usePrice(total)"
                    />
                  </template>
                </div>
              </div>
              <div class="modal-request-quote__input-wrapper">
                <div class="input-container modal-request-quote__textarea">
                  <VTextField
                    input-type="text"
                    :type="uiVariant.Secondary"
                    :label="textK('UI_QUOTES_REQUEST_REFERENCE')"
                    name="referenceNumber"
                    v-model="quoteRequest.ReferenceNumber"
                  /><br />
                  <VTextarea
                    ref="textarea"
                    :type="uiVariant.Secondary"
                    :label="textK('UI_QUOTES_REQUEST_MESSAGE')"
                    v-model="quoteRequest.Message"
                  />
                </div>
              </div>
            </div>
            <div class="modal-request-quote__actions-wrapper">
              <VButton
                class="modal-request-quote__submit"
                :type="uiVariant.Default"
                :disabled="!isValid"
                :is-marketplace="isMarketplace"
                @click="next"
                >{{ textK('UI_COMMON_NEXT_BUTTON') }}</VButton
              >
            </div>
          </div>
        </div>
      </template>
    </StandardDialogModal>
  </div>
</template>

<style lang="scss" src="./modal-request-quote.scss" scoped />

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import usePrice from '@/src/core/hooks/usePrice';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { OfferQuoteRequest } from '@/src/core/types/api';
import { RuleStatus } from '@/src/core/types/rule-status';
import QuantitySelector from '@/src/market/components/ui/quantity-selector/quantity-selector.vue';
import { useProductStore } from '@/src/market/stores/product';
import { useProductAdditionalInfoStore } from '@/src/market/stores/product-additional-info';
import { useProductPriceInfoStore } from '@/src/market/stores/product-price-info';
import { useQuotesRequestStore } from '@/src/market/stores/quotes-request';
import { computed, onMounted } from 'vue';
import ModalRequestQuoteHeader from './modal-request-quote-header/modal-request-quote-header.vue';

interface IModalRequestQuoteParams {
  isMarketplace: boolean;
  sellerId: string;
  id: string;
}

const textK = useText();
const productStore = useProductStore();
const productAdditionalInfoStore = useProductAdditionalInfoStore();
const productPriceInfo = useProductPriceInfoStore();
const usePriceFn = usePrice;
const createQuoteStore = useQuotesRequestStore();
const modalStore = useModalStore();

const uiVariant = UiVariant;
const uiSize = UiSize;

const params = computed<IModalRequestQuoteParams>(
  () => modalStore.params as IModalRequestQuoteParams,
);

const id = computed(() => params.value.id);
const sellerId = computed(() => params.value.sellerId);
const isMarketplace = computed(() => params.value.isMarketplace);

const quoteRequest = computed({
  get: () => createQuoteStore.quoteRequest,
  set: (value) => createQuoteStore.updateQuoteRequest(value),
});

const quoteReference = computed(() => {
  if (quoteRequest.value.ReferenceNumber) {
    return quoteRequest.value.ReferenceNumber;
  } else {
    quoteRequest.value.ReferenceNumber = offer.value?.ManufacturerAid || '';
    return quoteRequest.value.ReferenceNumber;
  }
});

const isSatair = computed(() => offer.value?.SupplierDisplay.toLocaleLowerCase() === 'satair');

const headerTitle = computed(() =>
  isSatair.value
    ? textK('UI_QUOTES_QUOTE_SINGLE_TITLE')
    : hasPrice.value
      ? textK('UI_QUOTES_REQUEST_EXISTING_PRICE_TITLE')
      : textK('UI_QUOTES_REQUEST_TITLE'),
);

const offer = computed(() => productStore.productById(id.value));

const offerAdditionalInfo = computed(() =>
  productAdditionalInfoStore.additionalDataByOfferId(id.value),
);

const priceData = computed(() => productPriceInfo.priceDataByOfferId(id.value));

const sellerName = computed(() => offer.value?.SupplierDisplay);

const minQty = computed(() => (offer.value?.AddToBasketQty ? offer.value.AddToBasketQty : 1));

const maxQty = computed(() =>
  offerAdditionalInfo.value?.RemainingOfferQuantity
    ? offerAdditionalInfo.value.RemainingOfferQuantity
    : 999999,
);

const stepQty = computed(() => (offer.value?.CartStep ? offer.value.CartStep : 1));

const hasPrice = computed(() => priceData.value?.RuleStatus === RuleStatus.OK);

const unit = computed(() => priceData.value?.ProductPriceInfo.PriceUnit.Code);

const currency = computed(() => priceData.value?.ProductPriceInfo.Price.CurrencyIso);

const price = computed(() => priceData.value?.ProductPriceInfo.BasePrice.FormattedValue);

const total = computed(() => {
  const price =
    (quoteRequest.value?.OfferedBasePrice && parseFloat(quoteRequest.value?.OfferedBasePrice)) || 0;
  const quantity =
    (quoteRequest.value?.RequestedQuantity &&
      parseInt(quoteRequest.value?.RequestedQuantity, 10)) ||
    0;
  return (price * quantity).toFixed(2).toString();
});

const isValid = computed(() => {
  let offeredBasePrice = quoteRequest.value?.OfferedBasePrice;

  if (offeredBasePrice) {
    offeredBasePrice = offeredBasePrice.toString();
    if (offeredBasePrice.indexOf('.') >= 0) {
      offeredBasePrice =
        offeredBasePrice.substr(0, offeredBasePrice.indexOf('.')) +
        offeredBasePrice.substr(offeredBasePrice.indexOf('.'), 3);
    }
  }

  return (
    quoteRequest.value?.RequestedQuantity &&
    parseFloat(quoteRequest.value?.RequestedQuantity) > 0 &&
    !(
      hasPrice.value &&
      (!offeredBasePrice || parseFloat(offeredBasePrice) === 0 || offeredBasePrice.length > 9)
    )
  );
});

const next = () => {
  modalStore.showModal({
    modalComponent: 'ModalRequestQuoteSelectAddress',
    params: { id: id.value, sellerId: sellerId.value, isMarketplace: isMarketplace.value },
    single: true,
  });
  modalStore.closeModal({ modalComponent: 'ModalRequestQuote' });
  return quoteReference.value;
};

onMounted(() => {
  if (!priceData.value) {
    productStore.fetchAndUpdateProductByOfferId({ id: id.value });
    productPriceInfo.fetchProduct({ OfferId: id.value, Quantity: minQty.value });
  }
  if (createQuoteStore.quoteRequest.OfferId !== id.value) {
    createQuoteStore.$reset();
    createQuoteStore.updateQuoteRequest({
      OfferId: id.value,
      Subject: offer.value?.MaterialNumberExternal,
      RequestedQuantity: minQty.value.toString(),
    } as OfferQuoteRequest);
  }
});
</script>
