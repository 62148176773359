<template>
  <div class="planning-splash">
    <PlanningLogo ref="logo" class="planning-splash__logo" />
    <div ref="cover" class="planning-splash__cover"></div>
    <h4 class="planning-splash__text" ref="text">Welcome to Planning</h4>
  </div>
</template>

<script setup lang="ts">
import { useUIStore } from '@/src/core/stores/ui';
import PlanningLogo from '@/src/planning/components/ui/planning-logo/planning-logo.vue';
import { gsap } from 'gsap';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';

const uiStore = useUIStore();
const emit = defineEmits(['isFinished']);
const logo = ref<InstanceType<typeof PlanningLogo> | null>(null);
const cover = ref<HTMLDivElement | null>(null);
const text = ref<HTMLElement | null>(null);
const timeline = gsap.timeline();

const createTimeline = () => {
  const firstRect = logo.value?.$refs.firstRect;
  const secondRect = logo.value?.$refs.secondRect;
  const thirdRect = logo.value?.$refs.thirdRect;
  const duration = 0.3;

  gsap.defaults({
    ease: 'linear',
    transformOrigin: 'center',
  });

  gsap.set([text.value], { opacity: 0, y: 15 });
  gsap.set([secondRect], { x: -68, y: 68 });
  gsap.set([thirdRect], { x: -136, y: 136 });
  gsap.set([firstRect, secondRect, thirdRect], { scale: 0 });

  timeline
    // move up
    .to(cover.value, { scaleX: 0, duration, delay: 1 })
    .to([thirdRect, secondRect, firstRect], {
      scale: 1,
      duration: 0.16,
    })
    .to([thirdRect], { x: 0, y: 0, duration })
    .to([text.value], { y: 0, opacity: 1, duration }, '<')
    .to(
      [secondRect],
      {
        x: 0,
        y: 0,
        duration,

        onComplete: () => {
          if (!show.value) {
            timeline.pause();
            setTimeout(() => {
              emit('isFinished', true);
            }, 1000);
          }
        },
      },
      '<',
    );
};

const show = computed(() => {
  return uiStore.showSplashScreen;
});

watch(
  () => show.value,
  () => {
    emit('isFinished', false);
  },
  { immediate: true },
);

onMounted(() => {
  createTimeline();
});

onBeforeUnmount(() => {
  timeline.kill();
});
</script>

<style src="./planning-splash.scss" lang="scss" scoped />
