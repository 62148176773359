import ModalAccountStatementFilter from '@/src/finance/components/modals/modal-account-statement-filter/modal-account-statement-filter.vue';
import ModalAccountStatementDownload from '@/src/finance/components/modals/modal-account-statement-download/modal-account-statement-download.vue';
import ModalDocumentsFilter from '@/src/finance/components/modals/modal-documents-filter/modal-documents-filter.vue';
import ModalMultilineQuery from '@/src/finance/components/modals/modal-multiline-query/modal-multiline-query.vue';

const FinanceModals = {
  ModalAccountStatementFilter,
  ModalAccountStatementDownload,
  ModalDocumentsFilter,
  ModalMultilineQuery,
};

export default FinanceModals;
