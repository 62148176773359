import ModalReleaseNotes from '@/src/core/components/modals/ModalReleaseNotes/modal-release-notes.vue';
import ModalAcceptTermsOfUse from '@/src/core/components/modals/modal-accept-terms-of-use/modal-accept-terms-of-use.vue';
import ModalChangeEmail from '@/src/core/components/modals/modal-change-email/modal-change-email.vue';
import ModalChangePassword from '@/src/core/components/modals/modal-change-password/modal-change-password.vue';
import ModalConfirmResetPassword from '@/src/core/components/modals/modal-confirm-reset-password/modal-reset-password.vue';
import ModalCreateNewUser from '@/src/core/components/modals/modal-create-new-user/modal-create-new-user.vue';
import ModalDeleteUserProfile from '@/src/core/components/modals/modal-delete-user-profile/modal-delete-user-profile.vue';
import ModalDeleteUser from '@/src/core/components/modals/modal-delete-user/modal-delete-user.vue';
import ModalDisableAccountSwitch from '@/src/core/components/modals/modal-disable-account-switch/modal-disable-account-switch.vue';
import ModalFirstTimeUser from '@/src/core/components/modals/modal-first-time-user/modal-first-time-user.vue';
import ModalForcedLogout from '@/src/core/components/modals/modal-forced-logout/modal-forced-logout.vue';
import ModalGetAccess from '@/src/core/components/modals/modal-get-access/modal-get-access.vue';
import ModalInactivityWarning from '@/src/core/components/modals/modal-inactivity-warning/modal-inactivity-warning.vue';
import ModalInvalidToken from '@/src/core/components/modals/modal-invalid-token/modal-invalid-token.vue';
import ModalLoadContent from '@/src/core/components/modals/modal-load-content/modal-load-content.vue';
import ModalLogin from '@/src/core/components/modals/modal-login/ModalLogin.vue';
import ModalLogout from '@/src/core/components/modals/modal-logout/modal-logout.vue';
import ModalPermission from '@/src/core/components/modals/modal-permission/modal-permission.vue';
import ModalProfileSelectAddress from '@/src/core/components/modals/modal-profile-select-address/modal-profile-select-address.vue';
import ModalQuoteTermsAndConditions from '@/src/core/components/modals/modal-quote-terms-and-conditions/modal-quote-terms-and-conditions.vue';
import ModalRequestAccess from '@/src/core/components/modals/modal-request-access/modal-request-access.vue';
import ModalResetPassword from '@/src/core/components/modals/modal-reset-password/modal-reset-password.vue';
import ModalSessionLogout from '@/src/core/components/modals/modal-session-logout/modal-session-logout.vue';
import ModalSwitchAccount from '@/src/core/components/modals/modal-switch-account/modal-switch-account.vue';
import FinanceModals from '@/src/finance/components/modals';
import MarketModals from '@/src/market/components/modals';
import PlanningModals from '@/src/planning/components/modals';
import { default as ProfileModals } from '@/src/profile/components/modals';

const Modals = {
  ModalInactivityWarning,
  ModalSessionLogout,
  ModalForcedLogout,
  ModalAcceptTermsOfUse,
  ModalReleaseNotes,
  ModalLogin,
  ModalLogout,
  ModalResetPassword,
  ModalGetAccess,
  ModalRequestAccess,
  ModalSwitchAccount,
  ModalChangeEmail,
  ModalChangePassword,
  ModalCreateNewUser,
  ModalDeleteUser,
  ModalDisableAccountSwitch,
  ModalDeleteUserProfile,
  ModalConfirmResetPassword,
  ModalInvalidToken,
  ModalLoadContent,
  ModalFirstTimeUser,
  ModalPermission,
  ModalProfileSelectAddress,
  ModalQuoteTermsAndConditions,
  ...MarketModals,
  ...FinanceModals,
  ...ProfileModals,
  ...PlanningModals,
};

export type ModalType = keyof typeof Modals;

export default Modals;
