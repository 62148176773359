import { oKeys } from '@/src/core/utils/object';

export class HybrisService {
  /**
   * Builds a query string to filter a SAP database by providing a map of facets
   * @param  {string} search
   * @param  {string} sort
   * @param  {{[keyinT]?:string}} facetMap
   */
  public static buildFacetQuery<T extends string>(
    search: string = '',
    sort: string = '',
    facetMap: { [key in T]?: string } = {},
  ) {
    return oKeys(facetMap).reduce((queryStr, facetKey) => {
      const facetValue = facetMap[facetKey];

      if (!facetValue) {
        return queryStr;
      }

      return (queryStr += `:${facetKey}:${facetValue}`);
    }, `${search}:${sort}`);
  }
}
