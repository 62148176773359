<template>
  <StandardDialogModal
    class="modal-initial-provisioning-filters"
    :size="uiSize.Medium"
    :has-close-button="false"
  >
    <template #header>
      <h3>
        {{ textK('UI_INITIAL_PROVISIONING_FILTERMODAL_HEADER') }}
      </h3>
    </template>
    <template #body>
      <InitialProvisioningFilters :is-modal="true" />
    </template>
    <template #actions>
      <VButton :type="uiVariant.Primary" @click="closeModal">
        {{ textK('UI_INITIAL_PROVISIONING_FILTERMODAL_ACTION') }}
      </VButton>
    </template>
  </StandardDialogModal>
</template>

<script setup lang="ts">
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import InitialProvisioningFilters from '@/src/planning/components/initial-provisioning/initial-provisioning-filters/initial-provisioning-filters.vue';

const textK = useText();
const modalStore = useModalStore();
const uiSize = UiSize;
const uiVariant = UiVariant;

const closeModal = () => {
  modalStore.closeModal();
};
</script>
