<template>
  <form @submit.prevent="logout">
    <StandardDialogModal :has-cancel-button="true" :size="uiSize.Small">
      <template #header>
        <div class="typeset">
          <h3>Log out</h3>
          <p>Are you sure you want to log out?</p>
        </div>
      </template>
      <template #actions>
        <VButton :type="uiVariant.Default" v-ui-test="'logout-confirm-button'">Log out</VButton>
      </template>
    </StandardDialogModal>
  </form>
</template>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import { useAuthenticationStore } from '@/src/core/stores/authentication';

const authenticationStore = useAuthenticationStore();
const uiVariant = UiVariant;
const uiSize = UiSize;

const logout = () => {
  authenticationStore.doLogout();
};
</script>
