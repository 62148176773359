<template>
  <div class="tab">
    <div class="tab__inner">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" src="./tab.scss" scoped></style>

<script lang="ts" setup></script>
