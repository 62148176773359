<template>
  <div class="login" :class="{ 'login--new': showNewLogin }" ref="loginElement">
    <div v-if="showNewLogin" class="login-wrapper login-wrapper--new">
      <div class="login-steps">
        <transition-group
          tag="div"
          class="login-steps__inner"
          :class="{
            'login-steps__password--active': passwordStepsActive,
            'login-steps__select-account--active': isSelectAccountStepActive,
          }"
          :name="transitionEffect"
        >
          <div v-if="activeStep === LOGIN_STEPS.USERNAME" key="username-step">
            <UsernameStep
              class="login-steps__item"
              :username="username"
              @update-username="updateUsername"
              @next-step="nextStep"
              :username-has-error="usernameHasError"
            />
          </div>
          <div v-if="activeStep === LOGIN_STEPS.SELECT_ACCOUNT" key="select-account-step">
            <SelectAccountStep
              class="login-steps__item"
              @next-step="handleSatairSelected"
              @go-back="goBack"
            />
          </div>
          <div v-else-if="activeStep === LOGIN_STEPS.PASSWORD" key="password-step">
            <PasswordStep
              class="login-steps__item"
              :username="username"
              :password="password"
              :site-in-maintenance-mode="siteInMaintenanceMode"
              :password-has-error="passwordHasError"
              @go-to-reset-password="goToResetPassword"
              @go-back="goBack"
              @update-password="updatePassword"
              @login="login"
            />
          </div>
          <div v-else-if="activeStep === LOGIN_STEPS.RESET_PASSWORD" key="reset-password-step">
            <ResetPasswordStep
              class="login-steps__item"
              :username="username"
              @reset-password="resetPassword"
              @update-username="updateUsername"
              @go-back="goBack"
            />
          </div>
        </transition-group>
      </div>

      <div class="login__footer">
        <a class="login__footer__need-help" @click="openNeedHelpModal">{{
          textK('UI_LOGIN_NEED_HELP')
        }}</a>
        <VButton
          v-if="activeStep === LOGIN_STEPS.USERNAME"
          v-ui-test="'continue-button'"
          class="login__cta"
          :type="uiVariant.Default"
          :disabled="username.length <= 0"
          :loading="isRequestingAuthTargets"
          @click="nextStep"
          ref="nextStepButton"
        >
          <transition :name="buttonEffect" appear mode="out-in">
            <span>{{ textK('UI_LOGIN_CONTINUE_BUTTON') }}</span>
          </transition>
        </VButton>
        <VButton
          v-else-if="activeStep === LOGIN_STEPS.PASSWORD"
          v-ui-test="'login-button'"
          class="login__cta"
          :type="uiVariant.Default"
          :disabled="password.length <= 0"
          :loading="isBusy"
          @click.prevent="login"
          ref="loginButton"
        >
          <transition :name="buttonEffect" appear mode="out-in">
            <span key="login">{{ textK('UI_LOGIN_LOG_IN_BUTTON') }}</span>
          </transition>
        </VButton>
        <VButton
          class="login__cta"
          :class="{ 'login__cta--expanded': activeStep === LOGIN_STEPS.RESET_PASSWORD }"
          v-else-if="activeStep === LOGIN_STEPS.RESET_PASSWORD"
          v-ui-test="'reset-password-button'"
          :type="uiVariant.Default"
          @click="resetPassword"
          :disabled="username.length <= 0"
          :loading="isBusy"
        >
          <transition :name="buttonEffect" appear mode="out-in">
            <span key="reset-password">{{ textK('UI_LOGIN_RESET_BUTTON') }}</span>
          </transition>
        </VButton>
      </div>
    </div>
    <div v-else class="login-wrapper">
      <TransitionExpand>
        <div v-if="loginVisible">
          <div class="login-header">
            <h3>
              <template v-if="isOnePortal">
                {{ textK('UI_LOGIN_HEADER_ONE_PORTAL') }}
              </template>
              <template v-else>
                {{ textK('UI_LOGIN_HEADER') }}
              </template>
            </h3>
            <InlineNotification v-if="siteInMaintenanceMode" :type="notificationTypes.Warning">
              {{ textK('UI_LOGIN_MAINTENANCE') }}
            </InlineNotification>
          </div>
          <form class="l_form" @submit.prevent="login" v-ui-test="'login-modal'">
            <div class="input-group">
              <div class="input-container">
                <VTextField
                  v-ui-test="'login-username'"
                  :disabled="siteInMaintenanceMode"
                  :type="uiVariant.Secondary"
                  name="email"
                  input-type="email"
                  :label="textK('UI_LOGIN_USERNAME')"
                  v-model="username"
                  autocomplete="email"
                  ref="emailInput"
                ></VTextField>
              </div>
              <div class="input-container">
                <VTextField
                  v-ui-test="'login-password'"
                  :disabled="siteInMaintenanceMode"
                  :type="uiVariant.Secondary"
                  name="password"
                  input-type="password"
                  :label="textK('UI_COMMON_PASSWORD')"
                  v-model="password"
                  disable-type-validation
                ></VTextField>
              </div>
              <div class="input-container">
                <Checkbox
                  v-ui-test="'remember-password'"
                  :disabled="siteInMaintenanceMode"
                  :id="'remember'"
                  v-model="rememberMe"
                >
                  <p class="remember-label">{{ textK('UI_LOGIN_REMEMBER') }}</p>
                </Checkbox>
              </div>
            </div>
            <div class="input-container login-group">
              <div class="login-group__col">
                <a
                  class="forgot-password-link"
                  v-if="!siteInMaintenanceMode"
                  v-ui-test="'forgot-password'"
                  @click="!isBusy ? (loginVisible = !loginVisible) : null"
                  >{{ textK('UI_LOGIN_FORGOT_PASSWORD') }}</a
                >
              </div>
              <div class="login-group__col">
                <VButton
                  v-ui-test="'login-button'"
                  :type="uiVariant.Default"
                  :disabled="username.length <= 0 || password.length <= 0"
                  :loading="isBusy"
                  @click.prevent="login"
                >
                  <svgicon v-if="isOnePortal" name="ui-login" />
                  {{ textK('UI_LOGIN_LOG_IN_BUTTON') }}
                </VButton>
              </div>
            </div>
          </form>
        </div>
      </TransitionExpand>

      <TransitionExpand>
        <div v-if="!loginVisible">
          <div class="login-header">
            <h3>{{ textK('UI_LOGIN_FORGOT_PASSWORD') }}</h3>
          </div>
          <form class="l_form" @submit.prevent="resetPassword" v-ui-test="'reset-password-modal'">
            <div class="input-group">
              <div class="input-container">
                <VTextField
                  v-ui-test="'reset-password-email'"
                  :required="true"
                  :type="uiVariant.Secondary"
                  name="email"
                  input-type="email"
                  :label="textK('UI_LOGIN_USERNAME')"
                  v-model="username"
                  autocomplete="email"
                  ref="emailInput"
                  disable-type-validation
                ></VTextField>
              </div>
            </div>
            <div class="input-container login-group">
              <div class="login-group__col">
                <a class="forgot-password-link" @click="loginVisible = !loginVisible">{{
                  textK('UI_LOGIN_BACK_BUTTON')
                }}</a>
              </div>
              <div class="login-group__col">
                <VButton
                  v-ui-test="'reset-password-button'"
                  :type="uiVariant.Default"
                  @click="resetPassword"
                  :loading="isBusy"
                  :disabled="username.length <= 0"
                  >{{ textK('UI_LOGIN_RESET_BUTTON') }}</VButton
                >
              </div>
            </div>
          </form>
        </div>
      </TransitionExpand>
    </div>
  </div>
</template>

<style lang="scss" src="./login.scss"></style>

<script setup lang="ts">
import { UserApi } from '@/src/core/api';
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import Checkbox from '@/src/core/components/ui/form/checkbox/checkbox.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import InlineNotification from '@/src/core/components/ui/inline-notification/inline-notification.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { Req } from '@/src/core/services/requester';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { LOGIN_STEPS, useLoginStore } from '@/src/core/stores/login';
import { useMaintenanceStore } from '@/src/core/stores/maintenance';
import { useModalStore } from '@/src/core/stores/modal';
import { NotificationTypes } from '@/src/core/types/api';
import { redirectToSSO } from '@/src/oidc/api/oidcApi';
import { computed, onMounted, ref, watch } from 'vue';
import PasswordStep from './password-step/password-step.vue';
import ResetPasswordStep from './reset-password-step/reset-password-step.vue';
import SelectAccountStep from './select-account-step/select-account-step.vue';
import UsernameStep from './username-step/username-step.vue';

export interface Props {
  focusOnInit?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  focusOnInit: false,
});

type AUTH_TARGET =
  | 'LOCAL_USER'
  | 'MULTIPLE_USERS'
  | 'UNKNOWN_USER'
  | 'AIRBUS_WORLD_SINGLE_SIGN_ON_USER';

const textK = useText();
const maintenanceStore = useMaintenanceStore();
const authenticationStore = useAuthenticationStore();
const modalStore = useModalStore();
const loginStore = useLoginStore();
const openModal = useModal();
const uiSize = UiSize;

const username = ref('');
const password = ref('');
const rememberMe = ref(false);
const loginVisible = ref(true);
const usernameHasError = ref(false);
const nextStepButton = ref<typeof VButton | null>(null);
const emailInput = ref<typeof VTextField>();
const transitionEffect = ref('slide');
const buttonEffect = ref('disabled');
const loginModalId = ref('ModalLogin');
const emit = defineEmits(['openNeedHelp']);

const uiVariant = UiVariant;
const notificationTypes = NotificationTypes;

const focusOnInit = computed(() => props.focusOnInit);
const isBusy = computed(() => authenticationStore.isBusy);
const isAuthenticated = computed(() => authenticationStore.isAuthenticated);
const siteInMaintenanceMode = computed(() => maintenanceStore.maintenanceMode);
const isOnePortal = computed(() => hasFeature(FEATURES.ONE_PORTAL_WAVE_0));
const showNewLogin = computed(() => hasFeature(FEATURES.NEW_LOGIN_DESIGN));

const activeStep = ref<keyof typeof LOGIN_STEPS>(LOGIN_STEPS.USERNAME);
const isRequestingAuthTargets = ref(false);
const passwordHasError = ref(false);
const loginButton = ref<typeof VButton | null>(null);

const isDualUser = computed(() => {
  return loginStore.isDualUser;
});

const login = async () => {
  loginButton.value?.focus(); // set focus away for input to show error message
  loginStore.resetState();
  loginStore.resetCurrentStep();

  lockModal(true);
  const isLoggedIn = await authenticationStore.doLogin({
    userId: username.value,
    password: password.value,
    rememberMe: rememberMe.value,
  });
  passwordHasError.value = !isLoggedIn;
};

const updateUsername = (value: string) => {
  username.value = value;
};

const updatePassword = (value: string) => {
  password.value = value;
};

const resetPassword = async ($event: SubmitEvent) => {
  $event.preventDefault();
  lockModal(true);
  transitionEffect.value = 'slideback';

  const { IsSuccess } = await Req({
    url: UserApi.ResetUserPassword(encodeURIComponent(username.value)),
    method: 'POST',
  });

  if (IsSuccess) {
    loginVisible.value = true;
    loginStore.setCurrentStep(LOGIN_STEPS.USERNAME);
    closeModal();
  } else {
    lockModal(false);
  }
};

const closeModal = () => {
  if (modalStore.activeModal?.modalComponent === loginModalId.value) {
    modalStore.closeModal({ modalComponent: loginModalId.value });
  }
};

const lockModal = (shouldLock: boolean) => {
  if (modalStore.activeModal?.modalComponent === loginModalId.value) {
    modalStore.lockCurrentModal(shouldLock);
  }
};

const handleSatairSelected = () => {
  transitionEffect.value = 'slide';
  activeStep.value = LOGIN_STEPS.PASSWORD;
};

const nextStep = async () => {
  nextStepButton.value?.focus();
  isRequestingAuthTargets.value = true;
  buttonEffect.value = 'btn-text-fade';

  const { IsSuccess, Data } = await Req({
    url: UserApi.CheckLoginType(username.value),
  });

  if (IsSuccess) {
    const target: AUTH_TARGET = Data.type;
    transitionEffect.value = 'slide';
    if (target === 'LOCAL_USER') {
      loginStore.setDualUser(false);
      activeStep.value = LOGIN_STEPS.PASSWORD;
    } else if (target === 'MULTIPLE_USERS') {
      loginStore.setDualUser(true);
      activeStep.value = LOGIN_STEPS.SELECT_ACCOUNT;
    } else if (target === 'AIRBUS_WORLD_SINGLE_SIGN_ON_USER') {
      loginStore.setDualUser(false);
      await redirectToSSO();
    } else if (target === 'UNKNOWN_USER') {
      loginStore.setDualUser(false);
      usernameHasError.value = true;
    } else {
      loginStore.setDualUser(false);
      console.error('Unhandled user login-type');
    }
  } else {
    console.error('User login-type error');
  }

  isRequestingAuthTargets.value = false;
};

const goBack = (from: string) => {
  transitionEffect.value = 'slideback';
  buttonEffect.value = 'btn-text-fade';
  if (from === LOGIN_STEPS.PASSWORD) {
    if (isDualUser.value) {
      activeStep.value = LOGIN_STEPS.SELECT_ACCOUNT;
    } else {
      activeStep.value = LOGIN_STEPS.USERNAME;
    }
  } else if (from === LOGIN_STEPS.RESET_PASSWORD) {
    activeStep.value = LOGIN_STEPS.PASSWORD;
  } else if (from === LOGIN_STEPS.SELECT_ACCOUNT) {
    activeStep.value = LOGIN_STEPS.USERNAME;
  }
};

const goToResetPassword = () => {
  transitionEffect.value = 'slide';
  buttonEffect.value = 'btn-text-fade';
  activeStep.value = LOGIN_STEPS.RESET_PASSWORD;
};

const openNeedHelpModal = () => {
  emit('openNeedHelp', true);
  openModal({
    modalComponent: 'ModalLoadContent',
    params: {
      key: 'NeedHelpLoggingIn_ModalUrl',
      title: 'NeedHelpLoggingIn_Name',
      size: uiSize.Medium,
    },
    first: true,
  });
};

const passwordStepsActive = computed(() => {
  return (
    activeStep.value === LOGIN_STEPS.PASSWORD || activeStep.value === LOGIN_STEPS.RESET_PASSWORD
  );
});

const isSelectAccountStepActive = computed(() => {
  return activeStep.value === LOGIN_STEPS.SELECT_ACCOUNT;
});

watch(isBusy, (isBusy) => {
  if (!isBusy && !isAuthenticated.value) {
    lockModal(false);
  }
});

watch(username, () => {
  loginStore.setState({ username: username.value, password: password.value });
  if (usernameHasError.value) {
    usernameHasError.value = false;
  }
});

watch(password, () => {
  loginStore.setState({ username: username.value, password: password.value });

  if (passwordHasError.value) {
    passwordHasError.value = false;
  }
});

watch(activeStep, (newValue: keyof typeof LOGIN_STEPS) => {
  loginStore.setCurrentStep(newValue);
});

onMounted(async () => {
  transitionEffect.value = 'disabled'; // Disabled transition effect
  username.value = loginStore.state?.username || '';
  password.value = loginStore.state?.password || '';
  activeStep.value = loginStore.currentStep;

  await maintenanceStore.fetchMaintenanceModeStatus();

  if (!focusOnInit.value || !emailInput.value) {
    return;
  }

  emailInput.value.setFocus();
});
</script>
