<template>
  <div
    class="text-field-container"
    :class="{
      'shop-context': context === uiContext.ShopSite,
      'content-context': context === uiContext.ContentSite,
      isFocused: isFocused || (currentValue && currentValue.length > 0),
      loading: loading,
      'has-icon-after': $slots.append,
      'has-icon-before': $slots.prepend,
    }"
  >
    <!-- icon -->
    <div class="text-field-icon-before" v-if="$slots.prepend">
      <slot name="prepend"></slot>
    </div>
    <!-- icon -->

    <input
      ref="inputfield"
      @click="click()"
      @focus="focus()"
      @blur="blur()"
      @keyup="keyup($event)"
      @input="input()"
      @keydown:left.stop
      @keydown:right.stop
      @keypress:left.stop
      @keypress:right.stop
      :type="type"
      :name="name"
      :id="id"
      :disabled="!!disabled"
      :placeholder="placeholder"
      v-ui-test="'search-text-field'"
      v-model="currentValue"
      class="text-field"
      :class="{ 'data-hj-whitelist': dataWhitelist, 'data-hj-suppress': dataSuppress }"
      :maxlength="vmaxLength"
      autocomplete="off"
    />

    <!-- icon -->
    <div class="text-field-icon-after" v-if="$slots.append">
      <slot name="append"></slot>
    </div>
    <!-- icon -->
  </div>
</template>

<style lang="scss" src="./search-text-field.scss" scoped></style>

<script lang="ts" setup>
import { UiContext } from '@/src/core/components/ui/ui.types';
import { useModalStore } from '@/src/core/stores/modal';
import { useUIStore } from '@/src/core/stores/ui';
import { PageTransitionEventBus } from '@/src/core/utils/page-transition-event-bus';
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

interface Props {
  modelValue?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  id?: string;
  loading?: boolean;
  vmaxLength?: number;
  disabled?: boolean;
  context?: UiContext;
  dataWhitelist?: boolean;
  dataSuppress?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  type: 'Text',
  loading: false,
  vmaxLength: 255,
  disabled: false,
  context: UiContext.ShopSite,
  dataWhitelist: false,
  dataSuppress: false,
  name: '',
  placeholder: '',
  id: '',
});

const uiStore = useUIStore();
const modalStore = useModalStore();
const route = useRoute();
const uiContext = UiContext;
const isFocused = ref(false);
const currentValue = ref<string>();
const inputfield = ref<HTMLInputElement | null>(null);
const emit = defineEmits(['focus', 'click', 'blur', 'keyup', 'update:modelValue', 'change']);

const focus = () => {
  isFocused.value = true;
  emit('focus', currentValue.value);
};

const click = () => {
  emit('click', currentValue.value);
};

const blur = () => {
  isFocused.value = false;
  emit('blur', currentValue.value);
};

const keyup = ($event: KeyboardEvent) => {
  emit('keyup', $event);
};

const input = () => {
  emit('update:modelValue', currentValue.value);
  emit('change', currentValue.value);
};

const setFocus = () => {
  if (
    inputfield.value &&
    !modalStore.isVisible &&
    route.name === 'search' &&
    !uiStore.showSplashScreen
  ) {
    setTimeout(() => {
      inputfield.value?.focus();
    }, 500);
  }
};

watch(
  () => props.modelValue,
  (val: string | number, oldVal: string | number) => {
    if (val === oldVal) {
      return;
    }
    currentValue.value = '' + val;
  },
);

onMounted(() => {
  currentValue.value = props.modelValue || '';
  setFocus();
  PageTransitionEventBus.$on('ended', () => setFocus());
});
</script>
