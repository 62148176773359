<template>
  <div
    class="classification-icons"
    v-if="hasHazmat || isFlammable || isAirbusProductWithCSPM || materialStatusE || isAirbusEroPart"
  >
    <!-- Hazmat Icon-->
    <Tooltip v-if="hasHazmat">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.02591 18.4L12 4.22384L19.9741 18.4H4.02591Z"
          fill="#FFA800"
          stroke="black"
          stroke-width="1.2"
        />
        <path d="M12 9V13.5M12 16.5V15" stroke="black" stroke-width="1.2" />
      </svg>
      <template #tooltip-content>
        <span v-ui-test="'hazmat'">
          <div class="tooltip-notice" v-if="!isAirbus">
            <template v-if="isNotAirbusAndhasHazmatUNCode">
              {{ product.HazmatCode }}<br />{{ product.HazmatDescription }}<br />
              <span>{{ textK('UI_PRODUCT_HAZMATNOTICE') }}</span>
            </template>
            <template v-else>
              <span>{{ textK('UI_PRODUCT_HAZMATNOTICE') }}</span>
            </template>
          </div>

          <div class="tooltip-notice" v-if="isAirbus">
            <template v-if="!isLoggedIn">
              {{ textK('UI_PRODUCT_HAZMAT_ANONYMOUS_DESCRIPTION') }}
            </template>
            <template v-if="!useAdditionalProductData">
              {{ textK('UI_PRODUCT_HAZMAT_NOT_FETCHED') }}
            </template>
            <template v-else-if="isLoggedIn && !productAdditionalData">
              {{ textK('UI_PRODUCT_HAZMAT_LOADING_INFO') }}
            </template>
            <template v-else-if="isLoggedIn && airbusHazmatUNCode">
              {{ airbusHazmatUNCode }}
            </template>
            <template v-else>
              {{ textK('UI_PRODUCT_HAZMAT_DESCRIPTION_NO_INFO') }}
            </template>
          </div>
        </span>
      </template>
    </Tooltip>
    <!--/ Hazmat Icon-->

    <!-- Flammable Icon-->
    <Tooltip v-if="isFlammable">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.9205 11.9999L12.5999 2.6792L3.27919 11.9999L12.5999 21.3205L21.9205 11.9999Z"
          fill="#FFA800"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.5999 2L22.5999 12L12.5999 22L2.59985 12L12.5999 2ZM12.5999 3.35867L3.95852 12L12.5999 20.6413L21.2412 12L12.5999 3.35867Z"
          fill="black"
        />
        <path
          d="M14.2016 8.84559C13.4935 8.29001 12.4477 7.89784 11.5326 7.71265C12.012 9.55369 10.9553 10.4143 10.3888 11.0788C9.76785 11.8087 9.35389 12.6366 9.38657 13.6388C9.40836 14.4123 9.82232 15.1857 10.4324 15.676C10.4651 15.6978 10.4977 15.7304 10.5304 15.7522C10.8899 15.0441 11.4673 14.1508 12.3715 13.4754C13.243 14.3469 13.755 15.2511 13.9946 15.8285C14.6918 15.3709 15.5415 15.0441 16.0971 13.6062C16.8052 11.776 15.5524 9.94586 14.1907 8.86738L14.2016 8.84559Z"
          fill="black"
        />
      </svg>
      <template #tooltip-content>
        <span v-ui-test="'flammable'">
          <div class="tooltip-notice">
            {{ textK('UI_PRODUCT_FLAMMABLENOTICE') }}
          </div>
        </span>
      </template>
    </Tooltip>
    <!--/ Flammable Icon-->

    <!-- Engine Part Icon-->
    <Tooltip v-if="materialStatusE">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.08026 13.6225V9.111L7.38571 8.12891L15.2744 8.88082L20.3715 10.8297L22.3319 13.6225L20.3715 16.4L15.2744 18.3489L9.73821 18.8859L5.08026 18.134V13.6225Z"
          stroke="black"
          stroke-width="1.2"
          stroke-miterlimit="10"
        />
        <path
          d="M14.4899 18.9937V14.3901H8.21655V18.9937"
          stroke="black"
          stroke-width="1.2"
          stroke-miterlimit="10"
        />
        <path
          d="M5.10004 10.5H3.60004V16.5H5.10004"
          stroke="black"
          stroke-width="1.2"
          stroke-miterlimit="10"
        />
        <path d="M8.10004 8.25V5.25" stroke="black" stroke-width="1.5" />
        <path d="M14.1 8.25V5.25" stroke="black" stroke-width="1.5" />
      </svg>
      <template #tooltip-content>
        <span v-ui-test="'enginepart'">
          <div class="tooltip-notice">
            {{ textK('UI_PRODUCT_AIRBUS_MATERIAL_ICONSTATUS_E') }}
          </div>
        </span>
      </template>
    </Tooltip>
    <!--/ Engine Part Icon-->

    <!-- Material Status Icon-->
    <Tooltip v-if="isAirbusProductWithCSPM" :disabled="isMobile">
      <span
        class="material-status-icon"
        @click="
          openModal({
            modalComponent: 'ModalMaterialStatus',
            params: { airbusCrossPlantMaterialStatus },
          })
        "
      >
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.80005 20H20.8V4H4.80005V20ZM3.80005 3V21H21.8V3H3.80005Z"
            fill="black"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.55 11.0001V16.5H12.05V11.0001H13.55ZM12.05 9.50009L12.05 8.00009H13.55L13.55 9.50009H12.05Z"
            fill="black"
          />
        </svg>
      </span>
      <template #tooltip-content>
        <span v-ui-test="'material-s1'">
          <div class="tooltip-notice">
            {{ textK('UI_PRODUCT_MATERIAL_STATUS_ICON_TOOLTIP_DESC') }}
          </div>
        </span>
      </template>
    </Tooltip>
    <!--/ Material Status Icon-->

    <!-- ERO Icon-->
    <Tooltip v-if="isAirbusEroPart">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_719_547)">
          <path
            d="M16.5519 9.05547L12.0212 12.4485L12.6999 13.3546L17.2305 9.96159L16.5519 9.05547Z"
            fill="black"
          />
          <path
            d="M18.0604 21.7353H7.78113L4.83774 16.6637L5.81132 16.0977L8.43774 20.6033H17.4038L21.8868 12.9053L17.4038 5.19602H8.43774L5.8 9.71292L4.82642 9.14689L7.78113 4.06396H18.0604L23.2 12.9053L18.0604 21.7353Z"
            fill="black"
          />
          <path d="M7.26072 11.9885H1.60034V13.1206H7.26072V11.9885Z" fill="black" />
          <path d="M9.52463 15.3845H2.73218V16.5166H9.52463V15.3845Z" fill="black" />
          <path d="M9.52463 8.59229H2.73218V9.72434H9.52463V8.59229Z" fill="black" />
          <path d="M15.1853 1.80005H10.657V2.9321H15.1853V1.80005Z" fill="black" />
          <path d="M13.8606 11.6262H11.5964V13.8903H13.8606V11.6262Z" fill="black" />
        </g>
        <defs>
          <clipPath id="clip0_719_547">
            <rect
              width="21.6"
              height="19.9355"
              fill="white"
              transform="translate(1.59985 1.80005)"
            />
          </clipPath>
        </defs>
      </svg>
      <template #tooltip-content>
        <span v-ui-test="'enginepart'">
          <div class="tooltip-notice">
            {{ textK('UI_PRODUCT_ERO_SHORT_DESCRIPTION') }}
          </div>
        </span>
      </template>
    </Tooltip>
    <!--/ ERO Icon-->
  </div>
</template>

<style lang="scss" src="./product-classification-icons.scss" scoped></style>

<script lang="ts" setup>
import useText from '@/src/core/hooks/useText';
import { computed, onBeforeMount, onMounted, ref } from 'vue';

import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import useModal from '@/src/core/hooks/useModal';
import { UN_PREFIX } from '@/src/core/settings/materials';
import { Product } from '@/src/core/types/api';
import { Procurement } from '@/src/core/types/interfaces';
import { MaterialStatus } from '@/src/core/types/material-status';
import mobile from '@/src/core/utils/mobile-detection';
import { hasNonSellableMaterialStatus } from '@/src/market/services/material-status';
import {
  productAdditionalData as getAdditionalData,
  isAirbusProduct,
} from '@/src/market/services/product-parts';
import {
  getAirbusHazmatUNCode,
  isAuthenticated,
  materialStatus,
} from '@/src/market/services/product-rules';

interface Props {
  product: Product;
  procurement?: Procurement | null;
  useAdditionalProductData?: boolean;
  isAirbusQuote?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  procurement: null,
  useAdditionalProductData: true,
  isAirbusQuote: false,
});

const textK = useText();
const openModal = useModal();
const isMobile = ref(false);

const productAdditionalData = computed(() => {
  return getAdditionalData(props.product.Id, props.product.OwningSystem);
});

const hasHazmat = computed(() => {
  if (
    (props.useAdditionalProductData && isAirbusProduct(props.product.OwningSystem)) ||
    props.isAirbusQuote
  ) {
    return props.product.IsAirbusHazmat;
  }

  return props.product.HasHazmat;
});

const isFlammable = computed(() => {
  return props.product.Flammable;
});

const isAirbusProductWithCSPM = computed(() => {
  // CSPM: Cross plant material status
  if (props.useAdditionalProductData && isAirbus.value) {
    return hasNonSellableMaterialStatus(
      productAdditionalData.value?.productData?.airbusCrossPlantMaterialStatus?.code,
    );
  }

  if (props.isAirbusQuote) {
    return hasNonSellableMaterialStatus(props.product.AirbusCrossPlantMaterialStatus?.code);
  }

  return false;
});

const airbusCrossPlantMaterialStatus = computed(() => {
  if (props.useAdditionalProductData && isAirbus.value) {
    return productAdditionalData.value?.productData?.airbusCrossPlantMaterialStatus?.code;
  }

  if (props.isAirbusQuote) {
    return props.product.AirbusCrossPlantMaterialStatus?.code;
  }

  return '';
});

const airbusHazmatUNCode = computed(() => {
  const hazmatUNCode = getAirbusHazmatUNCode(productAdditionalData.value);
  return hazmatUNCode ? `${UN_PREFIX}${hazmatUNCode}` : '';
});

const isNotAirbusAndhasHazmatUNCode = computed(() => {
  return (
    props.product.HazmatCode &&
    props.product.HazmatDescription &&
    props.product.IsAirbusHazmat === false
  );
});

const detectMobileBreakpoint = () => {
  isMobile.value = mobile.isMobileAndTablet();
};

const isLoggedIn = computed(() => {
  return isAuthenticated();
});

const isAirbus = computed(() => {
  return isAirbusProduct(props.product.OwningSystem);
});

const materialStatusE = computed(() => {
  if (props.useAdditionalProductData && isAirbus.value) {
    return materialStatus(MaterialStatus.STATUS_E, productAdditionalData.value);
  }

  if (props.isAirbusQuote) {
    return props.product.AirbusCrossPlantMaterialStatus?.code === MaterialStatus.STATUS_E;
  }

  return false;
});

const isAirbusEroPart = computed(() => {
  if (props.useAdditionalProductData && isAirbus.value) {
    return props.procurement?.eroPart;
  }

  return false;
});

onMounted(() => {
  window.addEventListener('resize', detectMobileBreakpoint);
  detectMobileBreakpoint();
});

onBeforeMount(() => {
  window.removeEventListener('resize', detectMobileBreakpoint);
});
</script>
