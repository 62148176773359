<template>
  <span
    class="facet-filter__item"
    :class="{ 'facet-filter__item--single-line-label': !facetItem.SubLabel }"
    v-ui-test="'search-result-facet-item'"
  >
    <Checkbox :id="facetItem.Name" :name="facetItem.Name" v-model="facet">
      <span class="facet-filter-checkbox">
        <span
          class="facet-filter-checkbox__label"
          v-ui-test="'search-result-facet-checkbox-label'"
          :title="facetTitle(facetItem)"
        >
          <span class="facet-filter-checkbox__label__name">{{
            changeAirbusLegalName(facetItem.Name) || '-'
          }}</span>
          <span class="facet-filter-checkbox__label__count"> ({{ facetItem.Count }})</span>
        </span>
        <span
          v-if="facetItem.SubLabel"
          class="facet-filter-checkbox__label__sub-label"
          :title="titleTag(facetItem.SubLabel)"
          >{{ facetItem.SubLabel }}</span
        >
      </span>
    </Checkbox>
  </span>
</template>

<style scoped lang="scss" src="./search-result-facet-item.scss"></style>

<script setup lang="ts">
import Checkbox from '@/src/core/components/ui/form/checkbox/checkbox.vue';
import useTitleTag from '@/src/core/hooks/useTitleTag';
import { useAdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { defaultValueSetter } from '@/src/core/services/tracking-utils-service';
import { SearchFilterData } from '@/src/core/types/adobe';
import { FacetValues } from '@/src/core/types/api';
import { useProductStore } from '@/src/market/stores/product';
import { useSearchStore } from '@/src/market/stores/search';
import { useUserStore } from '@/src/profile/stores/user';
import { ref, toRefs, watch } from 'vue';

interface SearchResultFaceItemProps {
  facetItem: FacetValues;
  displayName: string;
}

const props = defineProps<SearchResultFaceItemProps>();
const titleTag = useTitleTag;
const searchStore = useSearchStore();
const productStore = useProductStore();
const userStore = useUserStore();
const { facetItem, displayName } = toRefs(props);
const facet = ref(facetItem.value.Selected);

const changeAirbusLegalName = (name: string) => {
  if (name === 'Airbus') {
    return userStore.airbusLegalName;
  }
  return name;
};

const facetTitle = (item: FacetValues) => {
  return changeAirbusLegalName(item.Name) + ' (' + item.Count + ')';
};

watch(facet, async (value) => {
  facetItem.value.Selected = value;
  const data = await searchStore.searchProducts({
    searchQuery: searchStore.query,
    facetQuery: facetItem.value.Query.Query.Value,
  });
  if (data.IsSuccess && value) {
    const products = productStore.products.filter((product) => {
      return searchStore.search.some(({ ProductId }) => product.Id === ProductId);
    });
    const searchFilter = {
      searchInfo: {
        term: searchStore.query,
        results: products.length,
      },
      filter: {
        label: facetItem.value.Name,
        category: displayName.value,
      },
    };
    const withDefaultValues = defaultValueSetter(searchFilter);
    useAdobeLaunchTracking().searchFilterTracking(products, withDefaultValues as SearchFilterData);
  }
});
</script>
