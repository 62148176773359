import { Carrier } from '@/src/core/types/api';

export const CUSTOM_FORWARDER_CODE = 'CUSTOMCARRIER';
export const DEFAULT_FORWARDER_CODE = 'RESETCARRIER';

/**
 * Receives a Carrier and returns if the forwarder is custom
 * @param  {Carrier} carrier
 * @returns boolean - true if forwarder is custom
 */
export function isCustomForwarder(carrier: Carrier) {
  return carrier.Code === CUSTOM_FORWARDER_CODE;
}

/**
 * Receives a Carrier and returns if the forwarder is default
 * @param  {Carrier} carrier
 * @returns boolean - true if forwarder is default
 */
export function isDefaultForwarder(carrier: Carrier) {
  return carrier.Code === DEFAULT_FORWARDER_CODE;
}
