<template>
  <StandardDialogModal :size="uiSize.Large" :loading="!allCarriers.PreferedCarriers">
    <template #header>
      <h3>{{ textK('UI_MODAL_FORWARDER_HEADER') }}</h3>
    </template>
    <template #body>
      <ModalColumnContainer>
        <ModalColumn class="select-forwarder-column">
          <template #header>
            <h6>{{ textK('UI_MODAL_FORWARDER_ALL') }}</h6></template
          >
          <SearchList
            :data="allCarriers.Carriers"
            :label="'Search forwarder'"
            :search-keys="searcheableKeys"
            bind-key="Code"
            :filter="true"
          >
            <template #default="{ item }">
              <SimpleListItem
                :class="{ 'simple-list-item--selected': item.Code === newSelectedCarrier.Code }"
                :index="item.Code"
                @click="selected(item)"
              >
                {{ item.Code === 'CUSTOMCARRIER' ? textK('UI_MODAL_FORWARDER_CUSTOM') : item.Name }}
              </SimpleListItem>
            </template>
          </SearchList>
        </ModalColumn>
        <ModalColumn>
          <div v-if="sellerId === 'satair'">
            <h6 class="select-forwarder-column__header">
              {{ textK('UI_MODAL_FORWARDER_PREFERRED') }}
            </h6>
            <ul class="simple-list" v-ui-test="'prefered-forwarders'">
              <template
                v-if="allCarriers.PreferedCarriers && allCarriers.PreferedCarriers.length > 0"
              >
                <li
                  v-for="preferred in allCarriers.PreferedCarriers"
                  :key="preferred.Name"
                  @click="selected(preferred)"
                  v-ui-test="'prefered-forwarder'"
                >
                  <SimpleListItem
                    :class="{
                      'simple-list-item--selected': preferred.Code === newSelectedCarrier.Code,
                    }"
                  >
                    {{ preferred.Name }}
                  </SimpleListItem>
                </li>
              </template>
              <li v-if="allCarriers.PreferedCarriers && allCarriers.PreferedCarriers.length <= 0">
                <small
                  ><i>{{ textK('UI_MODAL_FORWARDER_NO_PREFERRED') }}</i></small
                >
              </li>
            </ul>
          </div>

          <TransitionExpand>
            <div class="typeset" v-if="!isDefaultInstructions">
              <form @submit.prevent="save()">
                <h6>{{ textK('UI_MODAL_FORWARDER_DETAILS') }}</h6>
                <TransitionExpand>
                  <div v-if="isCustomForwarder">
                    <InputGroup class="select-forwarder-input-container">
                      <VTextField
                        ref="name"
                        v-model="newSelectedCarrier.Name"
                        :vmax-length="40"
                        :type="uiVariant.Secondary"
                        :label="textK('UI_MODAL_FORWARDER_NAME')"
                        name="forwarder-name"
                        v-ui-test="'forwarder-name'"
                        :required="isCustomForwarder"
                      ></VTextField>
                    </InputGroup>
                  </div>
                </TransitionExpand>
                <InputGroup class="select-forwarder-input-container">
                  <VTextField
                    v-model="newAccountNumber"
                    :vmax-length="40"
                    :type="uiVariant.Secondary"
                    :label="textK('UI_MODAL_FORWARDER_ACCOUNT_NO')"
                    name="account-no"
                    v-ui-test="'forwarder-account-no'"
                    :required="true"
                  ></VTextField>
                </InputGroup>
                <TransitionExpand>
                  <div v-if="serviceLevelRequired">
                    <InputGroup class="select-forwarder-input-container">
                      <VTextField
                        v-model="newServiceLevel"
                        :vmax-length="40"
                        :type="uiVariant.Secondary"
                        :label="textK('UI_MODAL_FORWARDER_SERVICE_LEVEL_LABEL')"
                        name="service-level"
                        v-ui-test="'forwarder-service-level'"
                        :required="true"
                      ></VTextField>
                    </InputGroup>
                  </div>
                </TransitionExpand>
                <InputGroup>
                  <VTextarea
                    :resize="false"
                    ref="additional"
                    v-model="newAdditionalInstructions"
                    :placeholder="textK('UI_MODAL_FORWARDER_ADDITIONAL_INSTRUCTION_DESCRIPTION')"
                    :vmax-length="255"
                    :type="uiVariant.Secondary"
                    :label="textK('UI_MODAL_FORWARDER_ADDITIONAL_INSTRUCTION')"
                    name="additional-instructions"
                    v-ui-test="'forwarder-additional-instructions'"
                  ></VTextarea>
                </InputGroup>
                <div class="button-container">
                  <VButton :disabled="!isValid" :size="uiSize.Medium">{{
                    textK('UI_MODAL_FORWARDER_SET_BTN')
                  }}</VButton>
                </div>
              </form>
            </div>
          </TransitionExpand>
        </ModalColumn>
      </ModalColumnContainer>
    </template>
  </StandardDialogModal>
</template>

<style lang="scss" src="./modal-checkout-select-forwarder.scss" scoped></style>

<script lang="ts">
import { Vue, Component, Ref, Watch, toNative } from 'vue-facing-decorator';
import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import TransitionExpand from '@/src/core/components/ui/animations/transition-expand/transition-expand.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import InputGroup from '@/src/core/components/ui/form/input-group/input-group.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import SearchList from '@/src/core/components/ui/search-list/search-list.vue';
import SimpleListItem from '@/src/core/components/ui/simple-list-item/simple-list-item.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { Carrier } from '@/src/core/types/api';
import { ICarriers } from '@/src/core/types/interfaces';
import { isCustomForwarder, isDefaultForwarder } from '@/src/market/services/carrier-service';
import { useCarriersStore } from '@/src/market/stores/carriers';
import { useCheckoutStore } from '@/src/market/stores/checkout';

@Component({
  components: {
    VButton,
    StandardDialogModal,
    SearchList,
    SimpleListItem,
    ModalColumnContainer,
    ModalColumn,
    DotLoader,
    TransitionExpand,
    VTextField,
    VTextarea,
    InputGroup,
  },
})
export class ModalCheckoutSelectForwarder extends Vue {
  public textK = useText();
  public modalStore = useModalStore();
  public carriersStore = useCarriersStore();
  public checkoutStore = useCheckoutStore();
  public uiVariant = UiVariant;
  public uiSize = UiSize;
  public searcheableKeys: string[] = ['Name', 'Code'];
  private isFetched: boolean = false;
  public newSelectedCarrier: Carrier = {
    Name: '',
    Code: 'RESETCARRIER',
    Preferred: false,
    ServiceLevelRequired: false,
  };
  public newAccountNumber: string = '';
  public newServiceLevel: string = '';
  public newAdditionalInstructions: string = '';
  private isServiceLevelRequired: boolean = false;
  private isBusy: boolean = false;
  public sellerId: string = '';

  @Ref() readonly name!: InstanceType<typeof VTextField>;

  private get modalParams() {
    return this.modalStore.params as { sellerId: string };
  }

  public get allCarriers(): ICarriers {
    return this.carriersStore.allCarriers;
  }

  public get seller() {
    return this.checkoutStore.seller(this.modalParams.sellerId);
  }

  private get selectedCarrier() {
    return this.seller?.Carrier;
  }

  private get selectedAccountNumber() {
    return this.seller?.CarrierInfo;
  }

  private get selectedServiceLevel() {
    return this.seller?.ServiceLevelAgreements;
  }

  private get carrierInstructions() {
    return this.seller?.CarrierInstructions;
  }

  public get isDefaultInstructions() {
    return isDefaultForwarder(this.newSelectedCarrier);
  }

  public get isCustomForwarder() {
    return isCustomForwarder(this.newSelectedCarrier);
  }

  public get serviceLevelRequired() {
    return this.isServiceLevelRequired;
  }

  public get isValid() {
    return !(
      this.newSelectedCarrier.Name.length === 0 ||
      this.newAccountNumber.length === 0 ||
      (this.isServiceLevelRequired && this.newServiceLevel.length === 0)
    );
  }

  private fetchForwarders() {
    this.carriersStore.fetchCarriers({ sellerId: this.sellerId });
  }

  public selected(item: Carrier) {
    this.newSelectedCarrier = item;

    this.newAccountNumber = '';
    this.newServiceLevel = '';
    this.newAdditionalInstructions = '';

    if (!this.isDefaultInstructions) {
      this.$nextTick(() => {
        if (this.$refs.name) {
          (this.$refs.name as any).setFocus();
        }
      });
    } else {
      this.save();
    }
    this.isServiceLevelRequired = this.newSelectedCarrier.ServiceLevelRequired;
  }

  public async save() {
    this.isBusy = true;

    await this.checkoutStore.updateCarrier({
      sellerId: this.sellerId,
      carrierCode: this.newSelectedCarrier.Code,
      accountNo: this.newAccountNumber,
      serviceLevelAgreements: this.newServiceLevel,
      carrierName: this.newSelectedCarrier.Name,
      carrierInstructions: this.newAdditionalInstructions,
      serviceLevelRequired: this.newSelectedCarrier.ServiceLevelRequired,
    });

    this.modalStore.closeModal();

    this.isBusy = false;
  }

  public mounted() {
    this.sellerId = this.modalParams.sellerId;
    this.fetchForwarders();
    if (
      this.sellerId !== 'satair' &&
      !(this.selectedCarrier?.Code && this.selectedCarrier.Code.length > 0)
    ) {
      this.newSelectedCarrier = {
        Name: '',
        Code: 'CUSTOMCARRIER',
        Preferred: false,
        ServiceLevelRequired: false,
      };
    }
  }

  public destroyed() {
    this.carriersStore.reset();
  }

  @Watch('carriers') private carriersUpdated() {
    if (!this.isFetched) {
      this.isFetched = true;
    }
  }

  @Watch('selectedAccountNumber', { immediate: true }) private selectedAccountNumberUpdated() {
    if (this.selectedAccountNumber) {
      this.newAccountNumber = this.selectedAccountNumber;
    }
  }

  @Watch('selectedServiceLevel', { immediate: true }) private selectedServiceLevelUpdated() {
    if (this.selectedServiceLevel) {
      this.newServiceLevel = this.selectedServiceLevel;
    }
  }

  @Watch('carrierInstructions', { immediate: true }) private carrierInstructionsUpdated() {
    if (this.carrierInstructions) {
      this.newAdditionalInstructions = this.carrierInstructions;
    }
  }

  @Watch('selectedCarrier', { immediate: true }) private selectedCarrierUpdated() {
    if (this.selectedCarrier && this.selectedCarrier.Code) {
      this.newSelectedCarrier = this.selectedCarrier;
      this.isServiceLevelRequired = this.newSelectedCarrier.ServiceLevelRequired;
    }
  }
}

export default toNative(ModalCheckoutSelectForwarder);
</script>
