<template>
  <form class="content-form-handler" ref="form" @submit.prevent="submit">
    <slot :data="data" :valid="isFormValid" :success="isSuccess" />
  </form>
</template>

<script lang="ts" setup>
import { Req } from '@/src/core/services/requester';
import { IFormData } from '@/src/core/types/interfaces';
import { ref, watch } from 'vue';

interface Props {
  dataModel: IFormData;
  url: string;
  id?: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: '',
});

const data = ref<IFormData>({});
const isSuccess = ref(false);
const isDirty = ref(false);

const isFormValid = () => {
  const keys = Object.keys(data.value);

  for (const key of keys) {
    const value = data.value ? data.value[key] : { valid: false };
    if (!value.valid) {
      return false;
    }
  }
  return true;
};

const submit = async () => {
  const keys = Object.keys(data.value);
  const formData = { formData: {} };

  keys.forEach((key) => {
    formData.formData[key] = data.value[key].value;
  });

  const { IsSuccess } = await Req({
    method: 'POST',
    url: props.url,
    data: formData,
  });

  if (IsSuccess) {
    isSuccess.value = true;
  }
};

data.value = Object.assign({}, props.dataModel);

watch(
  () => data.value,
  (newData: IFormData, oldData: IFormData) => {
    if (!isDirty.value && Object.keys(oldData).length > 0 && Object.keys(newData).length > 0) {
      isDirty.value = true;
    }
  },
  { deep: true },
);
</script>
