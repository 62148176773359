<template>
  <div class="upload-handler">
    <AssistantIcon
      :mode="assistantParams.loading ? assistantMode.loading : assistantMode.default"
      class="assistant-icon"
    ></AssistantIcon>
    <transition name="lineheightToZero" tag="div">
      <h2 class="headline" v-if="!assistantParams.loading">
        {{ textK('UI_UPLOAD_DRAGDROPHOVER') }}
      </h2>
    </transition>
  </div>
</template>

<style lang="scss" src="./upload-handler.scss" scoped></style>

<script lang="ts" setup>
import AssistantIcon from '@/src/core/components/assistant/assistant-icon/assistant-icon.vue';
import { AssistantMode } from '@/src/core/components/assistant/assistant-icon/assistant-mode';
import useText from '@/src/core/hooks/useText';
import { useAssistantStore } from '@/src/core/stores/assistant';
import { computed } from 'vue';

const textK = useText();
const assistantStore = useAssistantStore();
const assistantMode = AssistantMode;

const assistantParams = computed(() => {
  return assistantStore.params;
});
</script>
