<template>
  <StandardDialogModal :has-cancel-button="false" :size="uiSize.Small">
    <template #header>
      <h3>{{ textK('UI_QUOTES_CREATE_REFERENCE_HEADER') }}</h3>
    </template>
    <template #body>
      <div class="inner-body">
        <p>
          {{ textK('UI_QUOTES_CREATE_REFERENCE_TEXT') }}
        </p>
        <VTextField
          input-type="text"
          :type="uiVariant.Secondary"
          :label="textK('UI_QUOTES_REQUEST_REFERENCE')"
          v-model="quoteReference"
          name="referenceNumber"
        />
      </div>
    </template>
    <template #actions>
      <VButton class="modal-request-quote__submit" :type="uiVariant.Default" @click="next">{{
        textK('UI_COMMON_CONTINUE')
      }}</VButton>
    </template>
  </StandardDialogModal>
</template>
<style lang="scss" src="./modal-create-quote.scss" scoped></style>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { IModalCreateQuoteParams } from '@/src/core/types/interfaces';
import { useListsStore } from '@/src/market/stores/list';
import { useQuoteListStore } from '@/src/market/stores/quote-list';
import { computed, onMounted, ref } from 'vue';

const textK = useText();
const listsStore = useListsStore();
const quoteListStore = useQuoteListStore();
const modalStore = useModalStore();

const uiSize = UiSize;
const uiVariant = UiVariant;
const quoteRef = ref(listsStore.currentList.Name || '');

const params = computed(() => modalStore.params as IModalCreateQuoteParams);
const listCode = computed(() => params.value.listCode || '');

const next = async () => {
  modalStore.showModal({
    modalComponent: 'ModalCreateQuoteSelectAddress',
    params: { single: false, listCode: listCode.value },
  });
  modalStore.closeModal({ modalComponent: 'ModalCreateQuote' });
};

const quoteList = () => {
  return quoteListStore.getQuoteList(listCode.value);
};

const quoteReference = computed({
  get: () => {
    quoteListStore.quoteList.Reference = quoteRef.value;
    return quoteRef.value;
  },
  set: (value: string) => {
    quoteListStore.quoteList.Reference = value;
  },
});

onMounted(() => {
  return quoteList;
});
</script>
