<template>
  <div
    class="accordion-container"
    :class="{
      'accordion-shop-site': context === uiContext.ShopSite,
      'accordion-content-site': context === uiContext.ContentSite,
      'accordion-secondary': variant === uiVariant.Secondary,
    }"
  >
    <slot></slot>
  </div>
</template>

<style lang="scss" src="./accordion.scss" scoped></style>

<script lang="ts" setup>
import { UiContext, UiVariant } from '@/src/core/components/ui/ui.types';

interface Props {
  context?: UiContext;
  variant: UiVariant;
}

withDefaults(defineProps<Props>(), {
  context: UiContext.ShopSite,
});

const uiContext = UiContext;
const uiVariant = UiVariant;
</script>
