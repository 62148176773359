/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  AirbusOrderDetails,
  Consignment,
  Container,
  MasterShop,
  Order,
  OrderDocumentInfo,
  OrderEntry,
  OrderPriority,
  OrderThreads,
  Warehouse,
} from '@/src/core/apim';
import { DateFormat } from '@/src/core/utils/dates';
import { isDefined, objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import {
  DocumentMapped,
  getDocumentsGrouped,
} from '@/src/market/services/legacy-mapping-documents';
import {
  checkIfEntryHasMinOrderLineValue,
  getUnknownDeliveryDate,
  mapOrderEntryToCartEntry,
  MappedOrderEntry,
} from '@/src/market/services/legacy-mapping-entry';
import { MASTERSHOP_ID } from '@/src/market/settings/mastershop';

const AOG = 'aog';
const TERMS_OF_PAYMENT = 'termsOfPayment';
const TERMS_OF_DELIVERY = 'termsOfDelivery';
const INTERNALBOX = 'internalbox';
const PROCESSING = 'processing';
const CREATED = 'created';

interface OrderData {
  forwarder: string;
  priority?: OrderPriority;
  EstimatedDate?: boolean;
  deliveryDate?: string | null;
  location?: Warehouse;
  statusDisplay?: string;
  container?: Container[] | null;
  unknownDeliveryDate?: boolean;
  batchNumber?: string | null;
  quantity?: number;
}

interface CodeName {
  code: string;
  name: string;
}

export const getOrderDetailsMapping = (order: Order) => {
  const formattedCode = removePreZero(order.code);
  const deliveryAddress = getDeliveryAddress(order);
  const hasMinOrderLineValue = order.entries?.some(checkIfEntryHasMinOrderLineValue);
  const unknownDeliveryDate = order.entries?.some(getUnknownDeliveryDate);
  const orderToReview = getOrderToReview(order);
  const termsOfPayment = getTerms(order, TERMS_OF_PAYMENT);
  const termsOfDelivery = getTerms(order, TERMS_OF_DELIVERY);
  const mappedEntries = order.entries?.map(mapOrderEntryToCartEntry) ?? [];
  const entries = addOrderDataToEntries(order, mappedEntries);
  const consignments = handleConsignmentMapping(order.consignments);
  const supplierDisplay = getSupplierName(order);
  const isQuoteOrder = checkIfIsQuoteOrder(order);
  const reference = getReference(order);
  const orderDetailsDisplayList = handleDisplayList(order);
  const orderShippingDisplayList = handleShippingList(order);
  const masterShopId = order.masterShop?.id ?? '';
  const showTotal = order.totalPrice?.value !== 0;
  const isAogOrder = order.priorityInfo?.priority?.code?.toLowerCase() === AOG;
  const isSatairOrder = order.masterShop?.id?.toLowerCase() === MASTERSHOP_ID.SATAIR;
  const showSubTotal = false;

  return {
    ...order,
    formattedCode,
    deliveryAddress,
    entries,
    hasMinOrderLineValue,
    unknownDeliveryDate,
    orderToReview,
    termsOfPayment,
    termsOfDelivery,
    reference,
    consignments,
    supplierDisplay,
    isQuoteOrder,
    orderDetailsDisplayList,
    orderShippingDisplayList,
    masterShopId,
    showTotal,
    showSubTotal,
    isAogOrder,
    isSatairOrder,
  };
};

export type OrderDetailsMapping = ReturnType<typeof getOrderDetailsMapping>;

export const getConsignmentThreadList = (
  orderThreads: OrderThreads[],
  order: OrderDetailsMapping,
) => {
  const { consignments, entries } = order ?? {};
  return (
    consignments?.map((cons) => {
      const threads = orderThreads.find(({ orderCode }) => orderCode === cons.code)?.threads || [];

      return {
        threadId: threads?.find(Boolean)?.threadId ?? '',
        consignmentId: cons.code,
        wareHouseName: cons.entries?.find(isDefined)?.orderEntry?.warehouse?.name ?? '',
        locationDisplayName:
          threads?.find(Boolean)?.locationDisplayName ??
          entries?.find(Boolean)?.offerInfo?.locationDisplayName,
        masterShopName:
          threads?.find(Boolean)?.masterShopName ?? entries?.find(Boolean)?.offerInfo?.shopName,
        totalMessageCount: threads?.find(Boolean)?.totalMessageCount ?? 0,
      };
    }) ?? []
  );
};

const getDeliveryAddress = (order: Order) => {
  const { country, ...restAddress } = order.deliveryAddress ?? {};
  const { isocode, ...restCountry } = country ?? {};

  return {
    country: {
      isoCode: country?.isocode,
      ...restCountry,
    },
    ...restAddress,
  };
};

const getCarrier = (order: Order) => {
  if (order.forwarderInfo) {
    return order.forwarderInfo.carrier?.name
      ? order.forwarderInfo.carrier?.name
      : order.forwarderInfo.forwarderName;
  }

  return 'Default Shipping Instructions';
};

const getOrderToReview = (order: OrderEntry) => {
  const terms = ['exw', 'fca', 'ex works', 'free carrier'];
  return terms.some(
    (term) =>
      order.termsOfDelivery?.code?.toLowerCase().includes(term.toLowerCase()) ||
      order.termsOfDelivery?.name?.toLowerCase().includes(term.toLowerCase()),
  );
};

const getTerms = <T extends { code?: string }>(
  order: Order,
  key: 'termsOfPayment' | 'termsOfDelivery',
): T | undefined => {
  const terms = order[key];
  return terms?.code
    ? ({
        ...terms,
        code: `(${terms.code})`,
      } as T)
    : (terms as T | undefined);
};

export type ConsignmentThreadMapping = ReturnType<typeof getConsignmentThreadList>;

const addOrderDataToEntries = (order: Order, entries: MappedOrderEntry[]) => {
  if (!entries.length) {
    return [];
  }

  const updatedEntries = entries.map((entry) => {
    const orderData: OrderData[] = [];

    if (!entry.cancelled) {
      let consignmentSum = 0;

      order.consignments
        ?.filter((consignment) =>
          consignment.entries?.some(
            (consignmentEntry) => consignmentEntry.orderEntry?.entryNumber === entry.entryNumber,
          ),
        )
        .forEach((consignment) => {
          consignment.entries
            ?.filter(
              (consignmentEntry) => consignmentEntry.orderEntry?.entryNumber === entry.entryNumber,
            )
            .forEach((consignmentEntry) => {
              consignmentEntry.containersWithThisEntry =
                consignmentEntry.containersWithThisEntry?.filter(
                  (container) => container.packageType?.toLowerCase() !== INTERNALBOX,
                );

              consignmentSum += consignmentEntry?.quantity || 0;

              orderData.push({
                forwarder:
                  consignment.carrierDetails?.name || consignment.blueForwarderString || '',
                priority: consignmentEntry.orderEntry?.priorityInfo?.priority,
                deliveryDate: consignment.shippingDate ?? null,
                quantity: consignmentEntry.quantity,
                statusDisplay: consignment.statusDisplay?.toLowerCase(),
                location: getLocationCodeName(entry),
                container: consignmentEntry.containersWithThisEntry || [],
                unknownDeliveryDate: entry.unknownDeliveryDate,
                batchNumber: consignmentEntry.batchNumber,
              });
            });
        });

      if (entry.quantity && entry.quantity > consignmentSum) {
        let remaining = entry.quantity - consignmentSum;

        entry.availabilityInfos
          ?.sort((a, b) => {
            const dateA = parseDateToObject(a.availabilityDate);
            const dateB = parseDateToObject(b.availabilityDate);

            if (!dateA) return 1;
            if (!dateB) return -1;

            return dateB.getTime() - dateA.getTime();
          })
          .map((x) => {
            if (remaining > 0 && x.quantity) {
              remaining -= x.quantity;
              return x;
            }
            return null;
          })
          .filter(isDefined)
          .sort((a, b) => {
            const dateA = parseDateToObject(a.availabilityDate);
            const dateB = parseDateToObject(b.availabilityDate);

            if (!dateA) return 1;
            if (!dateB) return -1;

            return dateA.getTime() - dateB.getTime();
          })
          .forEach((availDate) => {
            const dateTime = parseDate(availDate.availabilityDate)?.toString();

            orderData.push({
              forwarder: '-',
              priority: entry.priorityInfo?.priority,
              deliveryDate: order.confirmedDeliveryDate ? dateTime : null,
              quantity: availDate.quantity,
              statusDisplay:
                order.statusDisplay?.toLowerCase() === PROCESSING ? PROCESSING : CREATED,
              location: getLocationCodeName(entry),
              EstimatedDate: true,
              container: [],
            });
          });
      }

      return { ...entry, orderData };
    } else {
      orderData.push({
        container: [],
        forwarder: '-',
        priority: { code: '', name: '' },
        deliveryDate: null,
        quantity: entry.quantity,
        statusDisplay: entry.statusDisplay,
        location: { code: '', name: '' },
      });

      return {
        ...entry,
        totalPrice: {
          ...entry.totalPrice,
          value: '0',
        },
        orderData,
      };
    }
  });

  return updatedEntries;
};

const getLocationCodeName = (entry: MappedOrderEntry) => ({
  code: '',
  name: entry.warehouse?.name || '',
});

const handleConsignmentMapping = (consignments?: Consignment[]) => {
  return consignments?.map((consignment) => ({
    ...consignment,
    entries: consignment.entries?.map((entry) => ({
      ...entry,
      orderEntry: entry.orderEntry ? mapOrderEntryToCartEntry(entry.orderEntry) : entry.orderEntry,
    })),
  }));
};

const parseDate = (dateStr?: string): string | null => {
  if (!dateStr) {
    return null; // or return a default formatted date string if needed
  }

  const [year, month, day] = dateStr.split('-').map(Number);

  // Manually construct the date string in the desired format
  const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(
    2,
    '0',
  )}T00:00:00`;

  return formattedDate;
};

const parseDateToObject = (dateStr?: string): Date | null => {
  if (!dateStr) {
    return null;
  }

  const [year, month, day] = dateStr.split('-').map(Number);
  return new Date(year, month - 1, day); // Returning a Date object
};

const getSupplierName = (order: Order) => order.masterShop?.name || '';

const checkIfIsQuoteOrder = (order: Order) => Boolean(order.referencedQuotes);

const getReference = (order: Order) => (order.user?.name ? order.user.name : null);

const handleDisplayList = (order: Order) => {
  const orderDetailsDisplayList = [];
  const supplierName = getSupplierName(order);
  const isQuoteOrder = checkIfIsQuoteOrder(order);
  const reference = getReference(order);

  orderDetailsDisplayList.push(
    {
      name: 'Order date',
      value: [order.created?.toString() || ''],
      displayType: 'datetime',
    },
    {
      name: 'Order origin',
      value: [order.originDisplay || ''],
      displayType: 'string',
    },
    {
      name: 'Sold by',
      value: [supplierName],
      customValue: {
        name: supplierName,
        url: `/market/orders/seller/${order.masterShop?.id}`,
      },
      displayType: 'string',
    },
    {
      name: 'Order placed by',
      value: [reference],
      displayType: 'string',
    },
    {
      name: 'Priority',
      value: [
        order.priorityInfo?.priority.code === 'AOG'
          ? order.priorityInfo.priority.code
          : order.priorityInfo?.priority.name,
      ],
      displayType: 'string',
    },
    {
      name: 'AC Reg. or MSN',
      value: [order.priorityInfo?.acReg || null],
      displayType: 'string',
    },
    {
      name: 'Payment terms',
      value: [order.termsOfPayment?.description ? order.termsOfPayment.description : 'N/A'],
      displayType: 'string',
    },
  );

  if (isQuoteOrder) {
    const refQuotes = order.referencedQuotes?.map((refQuote) => ({
      name: refQuote.formattedSapQuoteId || refQuote.formattedCode,
      url: `/market/quotes/${refQuote.code}`,
    }));

    orderDetailsDisplayList.push({
      name: 'Quote reference',
      customValue: refQuotes,
      displayType: 'linklist',
    });
  }

  return orderDetailsDisplayList;
};

const handleShippingList = (order: Order) => {
  const shippingList = [
    {
      name: 'Terms of delivery',
      value: [
        order.termsOfDelivery?.name
          ? `${order.termsOfDelivery.name} ${order.termsOfDelivery.code}`
          : 'N/A',
      ],
    },
    {
      name: 'Selected forwarder',
      value: [getCarrier(order)],
    },
    {
      name: 'Requested ship-date',
      value: [order.namedDeliveryDate ? DateFormat(order.namedDeliveryDate) : 'When ready'],
      displayType: order.namedDeliveryDate ? 'datetime' : 'string',
    },
  ];

  return shippingList;
};

const removePreZero = (inputText?: string) => {
  if (inputText && inputText.length > 0) {
    let idx = 0;

    while (inputText.length > 0 && inputText[0] === '0') {
      inputText = inputText.slice(1);
      idx++;
    }

    return inputText;
  }

  return inputText;
};

interface OrderDetails {
  mappedOrder: OrderDetailsMapping;
  consignmentThreadsList: ConsignmentThreadMapping;
  documents: OrderDocumentInfo[];
  masterShop?: MasterShop;
  termsAndPolicies: any;
  airbusExtendedOrderDetails: AirbusOrderDetails;
}

export const getOrderDetailsData = ({
  mappedOrder,
  consignmentThreadsList,
  documents,
  masterShop,
  termsAndPolicies,
  airbusExtendedOrderDetails,
}: OrderDetails) => {
  const documentsGrouped = getDocumentsGrouped(documents, mappedOrder);

  const order = {
    ...mappedOrder,
    ...(consignmentThreadsList.length > 0 && { consignmentThreadsList }),
    ...(documents.length > 0 && { documentsGrouped }),
    documents,
    masterShop: {
      ...masterShop,
      ...(termsAndPolicies && { termsAndPolicies }),
    },
  };

  const entries = order.entries.map(({ airbusInterchangeabilityInfo, ...rest }) => ({
    ...objectKeysToPascalCaseRecursive(rest),
    AirbusInterchangeabilityInfo: airbusInterchangeabilityInfo,
  }));

  return {
    ...objectKeysToPascalCaseRecursive(order),
    Entries: entries,
    ...(Object.keys(airbusExtendedOrderDetails).length > 0 && {
      AirbusExtendedOrderDetails: airbusExtendedOrderDetails,
    }),
  };
};
