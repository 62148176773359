<template>
  <div class="blog-post-module">
    {{ blogPost }}
    <slot></slot>
  </div>
</template>

<style lang="scss" src="./blog-post-module.scss"></style>

<script lang="ts">
import { Vue, Component, toNative } from 'vue-facing-decorator';
import { IUmbracoContent } from '@/src/core/types/interfaces';

@Component({
  components: {},
})
export class BlogPostModule extends Vue {
  private blogPostContent: IUmbracoContent;

  public get blogPost() {
    return this.blogPostContent;
  }
}

export default toNative(BlogPostModule);
</script>
