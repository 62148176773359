import EditUserLayout from '@/src/account/layouts/admin/edit-user/edit-user-layout.vue';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { Site, useUIStore } from '@/src/core/stores/ui';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

const AccountLayout = () => import('@/src/account/layouts/account-layout.vue');
const AccountDetailsLayout = () =>
  import('@/src/account/layouts/account-details/account-details.vue');
const AdminLayout = () => import('@/src/account/layouts/admin/admin-layout.vue');

const accountRoutes: RouteRecordRaw[] = [
  {
    path: '/account',
    component: AccountLayout,
    redirect: { name: 'companydetails' },
    meta: {
      loginRestricted: true,
    },
    beforeEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: () => void) {
      const uiStore = useUIStore();
      uiStore.setCurrentSite({ site: Site.Account });
      next();
    },
    children: [
      {
        path: '/account/company-details',
        name: 'companydetails',
        meta: {
          title: 'Company details',
          type: 'Company Details page',
          loginRestricted: true,
        },
        components: {
          accountRoute: AccountDetailsLayout,
        },
        children: [
          {
            path: '/account/company-details/satair',
            name: 'companyDetailsSatair',
            meta: {
              title: 'Company details Satair',
              type: 'Company Details Satair page',
              loginRestricted: true,
            },
            components: {
              accountRoute: AccountDetailsLayout,
            },
          },
          {
            path: '/account/company-details/airbus',
            name: 'companyDetailsAirbus',
            meta: {
              title: 'Company details Airbus',
              type: 'Company Details Airbus page',
              loginRestricted: true,
            },
            components: {
              accountRoute: AccountDetailsLayout,
            },
          },
        ],
      },
      {
        path: '/account/admin',
        name: 'admin',
        meta: {
          title: 'User Administration',
          type: 'User Administration Page',
          loginRestricted: true,
          accessRestricted: () => {
            const authenticationStore = useAuthenticationStore();
            return authenticationStore.isAdmin;
          },
        },
        components: {
          accountRoute: AdminLayout,
        },
      },
      {
        path: '/account/admin/edit/:userid',
        name: 'edituser',
        components: {
          accountRoute: AdminLayout,
          subContentRoute: EditUserLayout,
        },
        meta: {
          title: 'Edit user',
          type: 'Edit user page',
          loginRestricted: true,
          accessRestricted: () => {
            const authenticationStore = useAuthenticationStore();
            return authenticationStore.isAdmin;
          },
        },
      },
    ],
  },
];

export default accountRoutes;
