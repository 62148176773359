import { RequestExchangeForm } from '@/src/core/types/interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';

interface RequestExchangeForms {
  [productId: string]: RequestExchangeForm | undefined;
}

export const useRequestExchangeFormStore = defineStore('requestExchangeForm', () => {
  const forms = ref<RequestExchangeForms>({});

  const getForm = (productId: string): RequestExchangeForm | undefined => {
    return forms.value[productId];
  };

  const saveForm = (productId: string, formDetails: RequestExchangeForm): void => {
    forms.value[productId] = formDetails;
  };

  const clearForm = (productId: string): void => {
    delete forms.value[productId];
  };

  return {
    forms,
    getForm,
    saveForm,
    clearForm,
  };
});
