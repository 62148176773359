import { useQuotesStore } from '@/src/market/stores/quotes';
export const isQuoteReady = (state: string) => {
  const quotesStore = useQuotesStore();

  return (
    state === 'COMPLETED' ||
    (quotesStore.satairQuotesEnabled &&
      (state === 'PARTIALLY_READY' || state === 'PARTIALLY_ACCEPTED'))
  );
};
