<template>
  <SearchList
    v-if="shippingAddresses"
    :data="shippingAddresses"
    :search-keys="searcheableKeys"
    :label="'Search address'"
    @selected="selected"
  >
    <template #default="{ item }">
      <SimpleListItem
        :selected="item.Id === modelValue?.Id"
        :class="item.RestrictedAddress ? 'simple-list-item--disabled' : ''"
      >
        <AddressCard :data="item"></AddressCard>
      </SimpleListItem>
    </template>
  </SearchList>
</template>

<script lang="ts" setup>
import AddressCard from '@/src/core/components/ui/addressCard/address-card.vue';
import SearchList from '@/src/core/components/ui/search-list/search-list.vue';
import SimpleListItem from '@/src/core/components/ui/simple-list-item/simple-list-item.vue';
import {
  AIRBUS_ONLY_SEARCHABLE_KEYS,
  DEFAULT_ADDRESS_SEARCHABLE_KEYS,
} from '@/src/core/settings/address';
import { Address } from '@/src/core/types/api';
import { useDeliveryAddressStore } from '@/src/market/stores/delivery-addresses';
import { useDeliveryAddressesQuotesStore } from '@/src/market/stores/delivery-addresses-quotes';
import { computed, watch } from 'vue';

interface Props {
  modelValue: Address | null;
  isAirbus?: boolean;
  isMarketplace?: boolean;
}

const emit = defineEmits(['update:modelValue', 'isFetched']);

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  isAirbus: false,
  isMarketplace: false,
});

const deliveryAddressesQuoteStore = useDeliveryAddressesQuotesStore();
const deliverAddressesStore = useDeliveryAddressStore();

const searcheableKeys = computed(() => {
  return [
    ...DEFAULT_ADDRESS_SEARCHABLE_KEYS,
    ...(props.isAirbus ? AIRBUS_ONLY_SEARCHABLE_KEYS : []),
  ];
});

const allDeliveryAddresses = computed(() => {
  return props.isMarketplace
    ? deliveryAddressesQuoteStore.allDeliveryAddresses
    : deliverAddressesStore.allDeliveryAddresses;
});

const shippingAddresses = computed(() => {
  return allDeliveryAddresses.value?.filter(
    ({ ShippingAddress, FormattedAddress }) => ShippingAddress === true && FormattedAddress !== '',
  );
});

const selected = (item: Address) => {
  emit('update:modelValue', item);
};

watch(allDeliveryAddresses, () => {
  emit('isFetched');
});
</script>
