import { UmbracoApi } from '@/src/core/api';

const client = new XMLHttpRequest();
client.open('GET', UmbracoApi.getFeatureFlags, false); // third parameter indicates sync xhr.
client.send();

type SAP_CC_FEATURE_FLAG = {
  name: string;
  active: boolean;
};

type SAP_CC_FEATURE_FLAG_RESPONSE = {
  Data: SAP_CC_FEATURE_FLAG[];
};

try {
  const resp = JSON.parse(client.response) as SAP_CC_FEATURE_FLAG_RESPONSE;

  const toMap = (acc: any, curr: SAP_CC_FEATURE_FLAG) => {
    acc[curr.name] = curr.active;
    return acc;
  };

  (window as unknown as any).features = resp.Data.reduce(toMap, {});
} catch (e) {
  console.error('Failed to load feature flags!');
}
