<template>
  <li class="product-details-list-item">
    <div class="product-details-list-item-content">
      <p
        v-if="airbusHazmatUNCode"
        class="product-details-list-item-title product-details-title-label"
      >
        {{ textK('UI_PRODUCTPAGE_HAZMAT_UN_CODE') }}
      </p>
      <p v-else class="product-details-list-item-title product-details-title-label">
        {{ textK('UI_PRODUCTPAGE_HAZMAT_LABEL') }}
      </p>
      <p class="product-details-list-item-value" v-ui-test="'productDetailsHazmat'">
        <svgicon class="hazmat-icon" name="ui-hazmat"></svgicon>
        <span v-if="airbusHazmatUNCode">{{ airbusHazmatUNCode }}</span>
        <span v-else>Yes</span>
      </p>
    </div>
  </li>
</template>

<script lang="ts" setup>
import useText from '@/src/core/hooks/useText';
import { UN_PREFIX } from '@/src/core/settings/materials';
import { productAdditionalData as productAdditionalDataService } from '@/src/market/services/product-parts';
import { getAirbusHazmatUNCode } from '@/src/market/services/product-rules';
import { computed } from 'vue';

interface Props {
  productId: string;
  owningSystem: string;
}

const props = defineProps<Props>();

const textK = useText();

const productAdditionalData = computed(() =>
  productAdditionalDataService(props.productId, props.owningSystem),
);

const airbusHazmatUNCode = computed(() => {
  const hazmatUNCode = getAirbusHazmatUNCode(productAdditionalData.value);
  return hazmatUNCode ? `${UN_PREFIX}${hazmatUNCode}` : '';
});
</script>
