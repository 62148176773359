<template>
  <div class="switch-account-modal">
    <StandardDialogModal :size="uiSize.Medium" :has-close-button="true" :has-cancel-button="true">
      <template #header>
        <h3>{{ textK('UI_CART_SWITCH_ACCOUNT_CHANGE_HEADLINE') }}</h3>
      </template>
      <template #body>
        <div>
          {{ textK('UI_CART_SWITCH_ACCOUNT_CHANGE_BODY') }}
          <div class="switch-account-modal__dont-show">
            <Checkbox
              v-model="dontshowagain"
              :id="'dontshowagain'"
              v-ui-test="'switch-account-dont-show-again'"
            >
              {{ textK('UI_COMMON_DONTSHOWAGAIN') }}
            </Checkbox>
          </div>
        </div>
      </template>
      <template #actions>
        <VButton
          :type="uiVariant.Default"
          v-ui-test="'cart-switch-account-change-modal'"
          input-type="submit"
          @click.prevent="switchaccount"
          >{{ textK('UI_COMMON_CONTINUE') }}</VButton
        >
      </template>
    </StandardDialogModal>
  </div>
</template>
<style lang="scss" src="./modal-switch-account.scss" scoped></style>

<script lang="ts" setup>
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import Checkbox from '@/src/core/components/ui/form/checkbox/checkbox.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { useUIStore } from '@/src/core/stores/ui';
import { getDomainByHostname } from '@/src/core/utils/urls';
import { useUserStore } from '@/src/profile/stores/user';
import Cookies from 'js-cookie';
import { computed, ref } from 'vue';

interface IModalSwitchAccountParams {
  unitUid: string;
  unitName: string;
}

const textK = useText();
const uiStore = useUIStore();
const modalStore = useModalStore();
const userStore = useUserStore();

const uiVariant = UiVariant;
const uiSize = UiSize;
const dontshowagain = ref(false);

const params = computed(() => modalStore.params as IModalSwitchAccountParams);

const switchaccount = async () => {
  if (dontshowagain.value) {
    Cookies.set('disable-switch-account-confirmation', 'true', {
      expires: 365 * 10,
      domain: getDomainByHostname(location.hostname),
    });
  }

  await userStore.switchAccount({
    user: userStore.currentUser,
    unitUid: params.value.unitUid,
    unitName: params.value.unitName,
  });

  uiStore.setNavigationDropdownId({ id: null });
  await userStore.fetchUserProfile();
  modalStore.closeModal();
};
</script>
