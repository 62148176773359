<template>
  <div class="product-unit-price">
    <template v-if="basePrice && productBasePriceAvailable && !isAsmHidden">
      <!-- RESTRICTED PRODUCT -->
      <template v-if="additionalProductInfo?.RuleStatus === 'Restricted'">
        <div class="product-unit-price__specification" v-ui-test="'price-specification'">
          {{ textK('UI_PRODUCT_RESTRICTED_SHORT_TEXT') }}
        </div>
      </template>

      <!-- PRODUCT OUT OF PRODUCTION -->
      <template v-else-if="additionalProductInfo?.RuleStatus === 'CPMS'">
        <div class="product-unit-price__specification" v-ui-test="'price-specification'">
          {{ textK('UI_PRODUCT_OUTOFPRODUCTION') }}
        </div>
      </template>

      <!-- PRICE IS ABOVE 0, SHOW PRICE -->
      <template v-else-if="offeredBasePrice && parseFloat(offeredBasePrice.Value) > 0">
        <div
          class="product-unit-price__specification"
          :class="{ 'product-unit-price--loading': cartIsBusy || additionalDataBusy }"
          v-ui-test="'price-specification'"
        >
          {{ offeredBasePrice.CurrencyIso }} / {{ PriceUnit }}
          <ProductUnitConversion :product="entry?.Product"></ProductUnitConversion>
        </div>
        <div
          class="product-unit-price__value"
          :class="{
            'product-unit-price--loading': cartIsBusy || additionalDataBusy,
            'product-unit-price--discount': hasDiscount && !hideDiscount,
          }"
          v-if="parseFloat(offeredBasePrice.Value) > 0"
          v-ui-test="'unit-price-label'"
          v-html="price(offeredBasePrice.FormattedValue)"
        ></div>
      </template>

      <template v-else-if="basePrice.Value && parseFloat(basePrice.Value) > 0">
        <div
          class="product-unit-price__specification"
          :class="{ 'product-unit-price--loading': cartIsBusy || additionalDataBusy }"
          v-ui-test="'price-specification'"
        >
          {{ basePrice.CurrencyIso }} / {{ PriceUnit }}
          <ProductUnitConversion :product="entry?.Product"></ProductUnitConversion>
        </div>
        <div
          class="product-unit-price__value"
          :class="{
            'product-unit-price--loading': cartIsBusy || additionalDataBusy,
            'product-unit-price--discount': hasDiscount && !hideDiscount,
          }"
          v-if="parseFloat(basePrice.Value) > 0"
          v-ui-test="'unit-price-label'"
          v-html="price(basePrice.FormattedValue || '-')"
        ></div>
      </template>

      <!-- PRICE IS 0 — NO PRICE -->
      <template v-else-if="basePrice">
        <div class="product-unit-price__specification" v-ui-test="'price-specification'">
          <span v-if="quoteItem || isMarketplace" class="price">{{
            textK('UI_PRODUCT_NOPRICE_MARKETPLACE')
          }}</span>
          <span v-else class="product-unit-price__no-price-text">{{
            isAsmHidden
              ? textK('UI_PRODUCT_HIDDEN_PRICE')
              : isBlueOwningSystemValue
                ? textK('UI_COMMON_AIRBUS_NO_PRICE')
                : textK('UI_PRODUCT_NOPRICE')
          }}</span>
          <ProductUnitConversion :product="entry?.Product"></ProductUnitConversion>
        </div>
      </template>

      <template v-else>
        <div class="product-unit-price__specification" v-ui-test="'price-specification'">
          <span v-if="quoteItem" class="price">{{ textK('UI_PRODUCT_NOPRICE_MARKETPLACE') }}</span>
          <span v-else class="price">{{
            isAsmHidden ? textK('UI_PRODUCT_HIDDEN_PRICE') : textK('UI_PRODUCT_NOPRICE')
          }}</span>
          <ProductUnitConversion :product="entry?.Product"></ProductUnitConversion>
        </div>
      </template>
    </template>

    <template v-else>
      <DotLoader
        v-if="
          additionalDataBusy || cartIsBusy || (useAdditionalProductInfo && !additionalProductInfo)
        "
        :loading="true"
      ></DotLoader>
      <div v-else class="product-unit-price__specification">
        {{
          isAsmHidden ? textK('UI_PRODUCT_HIDDEN_PRICE') : textK('UI_PRODUCT_NOPRICE_MARKETPLACE')
        }}
      </div>
    </template>
  </div>
</template>

<style lang="scss" src="./product-unit-price.scss" scoped></style>

<script setup lang="ts">
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import usePrice from '@/src/core/hooks/usePrice';
import useText from '@/src/core/hooks/useText';
import { CartEntry, OrderLine, OwningSystem } from '@/src/core/types/api';
import { IListEntry, IProductAdditionalData, ProductAirbusPart } from '@/src/core/types/interfaces';
import ProductUnitConversion from '@/src/market/components/product/product-unit-conversion/product-unit-conversion.vue';
import { formatPriceBreakInfo } from '@/src/market/services/airbus-parts';
import { getCurrentPriceBreakIndex } from '@/src/market/services/price-service';
import { getNewProcurementType } from '@/src/market/services/procurements';
import { isAirbus, isSatair } from '@/src/market/services/product-parts';
import { basePriceHandler, priceUnitHandler } from '@/src/market/services/product-parts-prices';
import { isBlueOwningSystem } from '@/src/market/services/product-rules';
import { useCartStore } from '@/src/market/stores/cart';
import { useProductPriceInfoStore } from '@/src/market/stores/product-price-info';
import { computed, onMounted, ref } from 'vue';

interface Props {
  entry?: CartEntry | IListEntry | OrderLine | null;
  additionalProductInfo: IProductAdditionalData | ProductAirbusPart | null;
  useAdditionalProductInfo?: boolean;
  quoteItem?: boolean;
  hideDiscount?: boolean;
  quantity?: number;
}

const props = withDefaults(defineProps<Props>(), {
  entry: null,
  useAdditionalProductInfo: false,
  quoteItem: false,
  hideDiscount: false,
  quantity: 0,
});

const textK = useText();
const productPriceInfoStore = useProductPriceInfoStore();
const cartStore = useCartStore();
const price = usePrice;
const cartEntry = ref<CartEntry | undefined>(undefined);

const cartIsBusy = computed(() => cartStore.isBusy);

const additionalDataBusy = computed(() => {
  return props.additionalProductInfo?.IsBusy;
});

const priceData = computed(() => {
  if (!props.entry) return undefined;

  if (isSatair(props.additionalProductInfo)) {
    return productPriceInfoStore.priceDataByOfferId(props.entry?.Id);
  }

  return undefined;
});

const procurement = computed(() => {
  if (isAirbus(props.additionalProductInfo)) {
    return getNewProcurementType(props.additionalProductInfo.procurements);
  }

  return undefined;
});

const PriceUnit = computed(() =>
  priceUnitHandler(props.additionalProductInfo, procurement.value, priceData.value),
);

const additionalInfoOrEntryItem = computed(() => {
  return props.additionalProductInfo || cartEntry.value?.OfferInfo;
});

const isBlueOwningSystemValue = computed(() =>
  isBlueOwningSystem(props.entry?.Product.OwningSystem as OwningSystem),
);

const hasDiscount = computed(() => additionalInfoOrEntryItem.value?.DiscountPriceAvailable);

const volumePrices = computed(() => {
  if (isSatair(props.additionalProductInfo)) {
    return priceData.value?.ProductPriceInfo?.PriceBreakInfos || [];
  }
  if (isAirbus(props.additionalProductInfo)) {
    return formatPriceBreakInfo(procurement.value);
  }
  return [];
});

const currentVolumePrice = computed(() => {
  const index = getCurrentPriceBreakIndex(volumePrices.value, props.quantity);
  return volumePrices.value[index];
});

const basePrice = computed(() => {
  if (props.useAdditionalProductInfo && !props.quoteItem) {
    if (volumePrices.value.length > 1) {
      return currentVolumePrice.value.BasePrice;
    }
    return basePriceHandler(props.additionalProductInfo, procurement.value, priceData.value);
  } else {
    return props.entry?.BasePrice;
  }
});

const isAsmHidden = computed(() => props.entry?.BasePrice?.IsAsmHidden);

const offeredBasePrice = computed(() => props.entry?.OfferedBasePrice);

const productBasePriceAvailable = computed(() => {
  if (props.useAdditionalProductInfo && !props.quoteItem) {
    return (
      (!props.additionalProductInfo?.HasFailed && Boolean(basePrice.value?.Value)) ||
      Boolean(priceData.value?.ProductPriceInfo?.BasePrice?.Value)
    );
  } else {
    return (
      (Boolean(basePrice.value?.Value) && Boolean(basePrice.value?.Value !== '0')) ||
      Boolean(priceData.value?.ProductPriceInfo?.BasePrice?.Value)
    );
  }
});

const isMarketplace = computed(() => {
  return props.entry?.Product?.IsMarketplace;
});

onMounted(() => {
  cartEntry.value = props.entry as CartEntry;
});
</script>
