import { Address, MasterShop } from '@/src/core/apim';
import { addCommerceCDNtoUrl } from '@/src/market/services/legacy-mapping';

export const handleMasterShopMapping = (mastershop?: MasterShop) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { blackListedProductsRecipientEmail, ...rest } = mastershop ?? {};
  const priorityFee = mastershop?.priorityFees?.find(Boolean);
  const companyOfficeDays = handleCompanyOfficeDays(mastershop?.companyOfficeDays);
  const shortDescription = getShortDescription(mastershop?.description);
  const address = getAddressWithFormattedIsoCode(mastershop?.address);
  const priorities = mastershop?.priorityHandling?.map((priority) => priority.code) ?? [];
  const displayAddress = getDisplayAddress(mastershop?.address);
  const showCompanyDetailsSection = checkIfCompanySectionIsVisible(
    mastershop,
    Boolean(displayAddress),
  );
  const leadShopId = mastershop?.shops?.find((s) => s.leadShop)?.id ?? '';
  const bannerCdnUrl = addCommerceCDNtoUrl(mastershop?.banner);

  return {
    ...rest,
    address,
    priorityFee,
    companyOfficeDays,
    shortDescription,
    priorities,
    displayAddress,
    showCompanyDetailsSection,
    leadShopId,
    bannerCdnUrl,
  };
};

const handleCompanyOfficeDays = (officeDays?: string[]) => {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  return officeDays
    ?.map((day) => daysOfWeek.indexOf(day)) // Convert day names to their numeric indices
    .sort((a, b) => ((a + 6) % 7) - ((b + 6) % 7)) // Sort days of the week
    .map((index) => daysOfWeek[index]); //
};

const getShortDescription = (description = '') => {
  return description.length <= 255 ? description.substring(0, 255) + '...' : description;
};

const getAddressWithFormattedIsoCode = (address?: Address) => {
  const { country: originalCountry } = address ?? {};
  const { isocode: isoCode, ...rest } = originalCountry ?? {};
  const country = { isoCode, ...rest };
  return { ...address, country };
};

const getDisplayAddress = (address?: Address): string => {
  if (!address) {
    return '';
  }

  let formattedAddress = address.line1 ?? '';
  formattedAddress += address.line2 ? `<br />${address.line2}` : '';
  formattedAddress += address.town ? `<br />${address.town}` : '';
  formattedAddress += address.postalCode ? `, ${address.postalCode}` : '';
  formattedAddress += address.district ? `<br />${address.district}` : '';
  formattedAddress += address.country?.name ? `<br />${address.country.name}` : '';

  return formattedAddress;
};

const checkIfCompanySectionIsVisible = (mastershop?: MasterShop, hasDisplayAddress = false) => {
  return (
    hasDisplayAddress ||
    mastershop?.companyOfficeDays?.length ||
    mastershop?.companyPublicHolidays?.length ||
    mastershop?.companyDunsNumber ||
    mastershop?.taxIdNumber
  );
};
