import { AirbusPart, IProductAdditionalData, Procurement } from '@/src/core/types/interfaces';
import { getAirbusPartAvailableStock } from '@/src/market/services/airbus-parts';
import { isAirbus, isSatair } from '@/src/market/services/product-parts';

export const unknownDeliveryDate = (
  productData: IProductAdditionalData | AirbusPart | null | undefined,
  procurement?: Procurement | null,
) => {
  if (isSatair(productData)) {
    return productData.UnknownDeliveryDate;
  }

  if (isAirbus(productData)) {
    return Boolean(procurement?.unknownDeliveryDate);
  }

  return false;
};

export const isInStock = (
  productData: IProductAdditionalData | AirbusPart | null | undefined,
  procurement?: Procurement | null,
) => {
  if (isSatair(productData)) {
    return productData.IsInStock;
  }

  if (isAirbus(productData) && procurement) {
    const availableStock = getAirbusPartAvailableStock(procurement);

    if (procurement.unknownDeliveryDate) {
      return false;
    }

    if (availableStock === 0 && procurement.availabilityDate) {
      return false;
    }

    return availableStock > 0;
  }

  return false;
};
