import { CartModification, CartModificationList, OrderEntry } from '@/src/core/apim';
import { getProductWithBaseMapping } from '@/src/market/services/legacy-mapping-product';

export enum FailIndicators {
  Full,
  Partial,
  None,
  IncorrectCurrency,
  MultipleOffers,
  MaxAllowedQuantityReached,
  InActiveOfferRemoved,
}

export const mapAddedCartEntries = (list: CartModificationList) => {
  return {
    ...list,
    cartModifications: list.cartModifications && mapCartModifications(list.cartModifications),
    failCount: list.cartModifications ? checkFailCount(list.cartModifications) : 0,
    failIndicator: list.cartModifications && getFailIndicatior(list.cartModifications),
    inActiveOfferRemoved: false,
  };
};

export const mapCartModifications = (cartModifications: CartModification[]) => {
  return cartModifications.map((item) => mapCartModificationValue(item));
};

export const mapCartModificationValue = (cartModification: CartModification) => {
  return {
    ...cartModification,
    successAdded: cartModification.quantityAdded && cartModification.quantityAdded > 0,
    entry: cartModification.entry ? [mapCartModificationEntry(cartModification.entry)] : [],
  };
};

export const mapCartModificationEntry = (entry: OrderEntry) => {
  return {
    ...entry,
    product: entry.product && getProductWithBaseMapping(entry.product),
    cartEntryRoundedFromSap: entry.changeReasons?.some(
      (reason) => reason.reason === 'quantityRoundedBySap',
    ),
  };
};

const checkFailCount = (cartModifications: CartModification[]) => {
  return cartModifications.filter((item) => item.quantityAdded === 0).length;
};

const getFailIndicatior = (cartModifications: CartModification[]): FailIndicators => {
  // Check if all modifications have a positive quantity added
  const allPositiveQuantity = cartModifications.every(
    (mod) => mod.quantityAdded && mod.quantityAdded > 0,
  );
  // Check the status codes when no positive quantity is found
  const allZeroQuantity = cartModifications.every(
    (mod) => !(mod.quantityAdded && mod.quantityAdded > 0),
  );
  const statusCode = cartModifications.map((mod) => mod.statusCode);
  if (allPositiveQuantity) {
    return FailIndicators.None;
  }
  if (allZeroQuantity) {
    if (statusCode.every((code) => code === 'incorrectCartCurrency')) {
      return FailIndicators.IncorrectCurrency;
    }
    if (statusCode.every((code) => code === 'multipleOffersFound')) {
      return FailIndicators.MultipleOffers;
    }
    if (statusCode.every((code) => code === 'maxAllowedQuantityReached')) {
      return FailIndicators.MaxAllowedQuantityReached;
    }
    return FailIndicators.Full;
  }
  return FailIndicators.Partial;
};
