import { useUIStore } from '@/src/core/stores/ui';
import { RouteLocationNormalized } from 'vue-router';

export default (to: RouteLocationNormalized, from: RouteLocationNormalized, next: () => void) => {
  const uiStore = useUIStore();

  if (from.name) {
    if (from.fullPath.includes('market')) {
      uiStore.setlastMarketRoute({ lastRoute: from.path });
    } else {
      uiStore.setlastMarketRoute({ lastRoute: '' });
    }
  }
  // TODO: Fix the following error
  //  The "next" callback was called more than once in one navigation guard when going from...
  // It should be called exactly one time in each navigation guard. This will fail in production.

  next();
};
