import { User } from '@/src/core/types/api';

export const sanitizeUser = ({
  CountryCode,
  ContactNumber,
  PrimaryWorkArea,
  TimeZone,
  ...rest
}: User): User => ({
  CountryCode: CountryCode !== null ? CountryCode : '',
  ContactNumber: ContactNumber !== null ? ContactNumber : '',
  PrimaryWorkArea: PrimaryWorkArea !== null ? PrimaryWorkArea : '',
  TimeZone: TimeZone !== null ? TimeZone : '',
  ...rest,
});
