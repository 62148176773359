import { UmbracoApi, UserApi } from '@/src/core/api';
import { UiVariant } from '@/src/core/components/ui/ui.types';
import useModal from '@/src/core/hooks/useModal';
import router from '@/src/core/router';
import RestrictedRouteChecker from '@/src/core/router/restricted-route-checker/restricted-route-checker';
import RouteAuthenticationGate from '@/src/core/router/route-authentication-gate/route-authentication-gate';
import { getEnv, isDevelopment } from '@/src/core/services/environment';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { Req } from '@/src/core/services/requester';
import { TextKey } from '@/src/core/services/text-key';
import { IASM } from '@/src/core/types/interfaces';
import { LoginCredentials } from '@/src/core/types/ui';
import { useLocalStorageRemoveItem } from '@/src/core/utils/local-storage';
import { upperFirst } from '@/src/core/utils/string';
import { FIVE_MINUTES, FOUR_HOURS } from '@/src/core/utils/time';
import { getDomainByHostname } from '@/src/core/utils/urls';
import { useUserStore } from '@/src/profile/stores/user';
import Cookies from 'js-cookie';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { clearAllStores } from '.';

type AuthenticationState = Record<Capitalize<MARKET_COOKIE>, string | boolean>;

interface MarketCookies {
  globalId?: string;
  isAdmin?: boolean;
  isReadOnly?: boolean;
  isFinance?: boolean;
  betaCustomer?: boolean;
  readOnlyAccount?: boolean;
  isFirstTime?: boolean;
  rememberMe?: boolean;
  hasAcceptedTou?: boolean;
  hasAcceptedReleaseNotes?: boolean;
  userUnitType?: string;
}

const removeCookies = (cookies: Array<string>) => {
  for (const name of cookies) {
    Cookies.remove(name);
    Cookies.remove(name, { domain: getDomainByHostname(location.hostname) });
  }
};

const InitialState = {
  IsAdmin: Cookies.get('isAdmin') === 'true',
  IsReadOnly: Cookies.get('isReadOnly') === 'true',
  IsFinance: Cookies.get('isFinance') === 'true',
  ReadOnlyAccount: Cookies.get('readOnlyAccount') === 'true',
  BetaCustomer: Cookies.get('betaCustomer') === 'true',
  HasAcceptedTou: Cookies.get('hasAcceptedTou') === 'true',
  HasAcceptedReleaseNotes: Cookies.get('hasAcceptedReleaseNotes') === 'true',
  IsFirstTime: Cookies.get('isFirstTime') === 'true',
  GlobalId: Cookies.get('globalId') || '',
  RememberMe: false,
  UserUnitType: Cookies.get('userUnitType') || '',
  SapccAccessTokenExpirationTimestamp: Cookies.get('sapccAccessTokenExpirationTimestamp') || '',
  SapccRefreshTokenExpirationTimestamp: Cookies.get('sapccRefreshTokenExpirationTimestamp') || '',
  LastActivityTimestamp: Cookies.get('lastActivityTimestamp') || '',
} satisfies Partial<AuthenticationState>;

const utils = {
  get getStorageToken() {
    return {
      GlobalId: Cookies.get('globalId'),
      IsAdmin: Cookies.get('isAdmin') === 'true' || false,
      IsReadOnly: Cookies.get('isReadOnly') === 'true' || false,
      IsFinance: Cookies.get('isFinance') === 'true' || false,
      BetaCustomer: Cookies.get('betaCustomer') === 'true' || false,
      ReadOnlyAccount: Cookies.get('readOnlyAccount') === 'true' || false,
      IsFirstTime: Cookies.get('isFirstTime') === 'true' || false,
      HasAcceptedTou: Cookies.get('hasAcceptedTou') === 'true' || false,
      HasAcceptedReleaseNotes: Cookies.get('hasAcceptedReleaseNotes') === 'true' || false,
      UserUnitType: Cookies.get('userUnitType') || '',
      SapccAccessTokenExpirationTimestamp: Cookies.get('sapccAccessTokenExpirationTimestamp') || '',
      SapccRefreshTokenExpirationTimestamp:
        Cookies.get('sapccRefreshTokenExpirationTimestamp') || '',
      LastActivityTimestamp: Cookies.get('lastActivityTimestamp') || '',
      AccessTokenTimestamp: Cookies.get('accessTokenTimestamp') || '',
    };
  },
  removeUserCookies() {
    const userCookies: MARKET_COOKIE[] = [
      'CommerceSID',
      'globalId',
      'isAdmin',
      'isReadOnly',
      'isFinance',
      'betaCustomer',
      'readOnlyAccount',
      'isFirstTime',
      'hasAcceptedTou',
      'hasAcceptedReleaseNotes',
      'userUnitType',
    ];

    removeCookies(userCookies);
  },
  removeAsmCookies() {
    const asmCookies: MARKET_COOKIE[] = ['asm', 'asmInfo'];

    removeCookies(asmCookies);
  },
  addUserCookies({
    globalId,
    isAdmin,
    isReadOnly,
    isFinance,
    betaCustomer,
    readOnlyAccount,
    isFirstTime,
    rememberMe,
    hasAcceptedTou,
    hasAcceptedReleaseNotes,
    userUnitType,
  }: MarketCookies) {
    const defaultCookieOptions = {
      expires: 365,
      domain: getDomainByHostname(location.hostname),
    };

    if (globalId) {
      Cookies.set(
        'globalId',
        globalId,
        rememberMe
          ? { expires: 14, domain: getDomainByHostname(location.hostname) }
          : {
              domain: getDomainByHostname(location.hostname),
            },
      );
    }
    if (isAdmin !== undefined) {
      Cookies.set('isAdmin', isAdmin.toString(), defaultCookieOptions);
    }
    if (isReadOnly !== undefined) {
      Cookies.set('isReadOnly', isReadOnly.toString(), defaultCookieOptions);
    }
    if (isFinance !== undefined) {
      Cookies.set('isFinance', isFinance.toString(), defaultCookieOptions);
    }
    if (readOnlyAccount !== undefined) {
      Cookies.set('readOnlyAccount', readOnlyAccount.toString(), defaultCookieOptions);
    }
    if (betaCustomer !== undefined) {
      Cookies.set('betaCustomer', betaCustomer.toString(), defaultCookieOptions);
    }
    if (isFirstTime) {
      Cookies.set('isFirstTime', isFirstTime.toString(), defaultCookieOptions);
    }
    if (hasAcceptedTou !== undefined) {
      Cookies.set('hasAcceptedTou', hasAcceptedTou.toString(), defaultCookieOptions);
    }
    if (hasAcceptedReleaseNotes !== undefined) {
      Cookies.set(
        'hasAcceptedReleaseNotes',
        hasAcceptedReleaseNotes.toString(),
        defaultCookieOptions,
      );
    }
    if (userUnitType !== undefined) {
      Cookies.set('userUnitType', userUnitType.toString(), defaultCookieOptions);
    }
  },
};

export const useAuthenticationStore = defineStore('authentincation', () => {
  const authentication = ref<Partial<AuthenticationState>>(InitialState);
  const asm = ref<IASM>({} as IASM);
  const isBusy = ref<boolean>(false);
  const isLogoutInProgress = ref<boolean>(false);

  const isAuthenticated = computed(
    () =>
      !!authentication.value.GlobalId && !!authentication.value.SapccAccessTokenExpirationTimestamp,
  );
  const isAdmin = computed(() => authentication.value.IsAdmin);
  const isReadOnly = computed(() => authentication.value.IsReadOnly);
  const isFinance = computed(() => authentication.value.IsFinance);
  const betaCustomer = computed(() => authentication.value.BetaCustomer);
  const readOnlyAccount = computed(() => authentication.value.ReadOnlyAccount);
  const isFirstTime = computed(() => authentication.value.IsFirstTime);
  const getGlobalId = computed(() => authentication.value.GlobalId);
  const hasAcceptedTou = computed(() => authentication.value.HasAcceptedTou);
  const hasAcceptedReleaseNotes = computed(() => authentication.value.HasAcceptedReleaseNotes);
  const userUnitType = computed(() => authentication.value.UserUnitType);
  const hasStorageToken = computed<boolean>(() => !!getGlobalId.value);
  const getAuthenticationTokenHeaders = computed<IAuthenticationHeaders>(() => ({
    Authorized: true,
  }));
  const getUnauthorizedHeader = computed<IAuthenticationHeaders>(() => ({
    Authorized: false,
  }));
  const getSapccAccessTokenExpirationTimestamp = () =>
    Cookies.get('sapccAccessTokenExpirationTimestamp') as string;
  const getSsapccRefreshTokenExpirationTimestamp = () =>
    Cookies.get('sapccRefreshTokenExpirationTimestamp') as string;

  const authenticationLogout = () => {
    Object.assign(authentication.value, InitialState, {
      GlobalId: '',
      SapccAccessTokenExpirationTimestamp: '',
      SapccRefreshTokenExpirationTimestamp: '',
      LastActivityTimestamp: '',
    });

    const cookiesRequiredForLogin: MARKET_COOKIE[] = [
      'globalId',
      'sapccAccessTokenExpirationTimestamp',
      'sapccRefreshTokenExpirationTimestamp',
      'lastActivityTimestamp',
    ];

    removeCookies(cookiesRequiredForLogin);
  };

  const isFirstTimeUpdated = (payload: { isFirstTime: boolean }) => {
    authentication.value.IsFirstTime = payload.isFirstTime;
  };

  const acceptedTouUpdated = () => {
    authentication.value.HasAcceptedTou = true;
  };

  const asmModeUpdated = (payload: { asm: IASM }) => {
    asm.value = payload.asm;
  };

  const authenticationUpdated = () => {
    authentication.value.GlobalId = utils.getStorageToken?.GlobalId || '';
    authentication.value.IsAdmin = utils.getStorageToken?.IsAdmin;
    authentication.value.IsReadOnly = utils.getStorageToken?.IsReadOnly;
    authentication.value.IsFinance = utils.getStorageToken?.IsFinance;
    authentication.value.BetaCustomer = utils.getStorageToken?.BetaCustomer;
    authentication.value.ReadOnlyAccount = utils.getStorageToken?.ReadOnlyAccount;
    authentication.value.IsFirstTime = utils.getStorageToken?.IsFirstTime;
    authentication.value.HasAcceptedTou = utils.getStorageToken?.HasAcceptedTou;
    authentication.value.HasAcceptedReleaseNotes = utils.getStorageToken?.HasAcceptedReleaseNotes;
    authentication.value.UserUnitType = utils.getStorageToken.UserUnitType;
    authentication.value.SapccAccessTokenExpirationTimestamp =
      utils.getStorageToken.SapccAccessTokenExpirationTimestamp;
    authentication.value.SapccRefreshTokenExpirationTimestamp =
      utils.getStorageToken.SapccRefreshTokenExpirationTimestamp;
  };

  const isBusyUpdated = (payload: { IsBusy: boolean }) => {
    isBusy.value = payload.IsBusy;
  };

  const setUserCookies = async (payload: {
    globalId: string;
    isAdmin: boolean;
    isReadOnly: boolean;
    isFinance: boolean;
    betaCustomer: boolean;
    readOnlyAccount: boolean;
    isFirstTime: boolean;
    rememberMe: boolean;
    hasAcceptedTou: boolean;
    hasAcceptedReleaseNotes: boolean;
    userUnitType: string;
  }) => {
    // Clear previous user cookies (Tokens + Permissions)
    utils.removeUserCookies();

    // Set new cookies (Tokens + Permissions)
    utils.addUserCookies({
      globalId: payload.globalId,
      isAdmin: payload.isAdmin,
      isReadOnly: payload.isReadOnly,
      isFinance: payload.isFinance,
      betaCustomer: payload.betaCustomer,
      readOnlyAccount: payload.readOnlyAccount,
      isFirstTime: payload.isFirstTime,
      rememberMe: payload.rememberMe,
      hasAcceptedTou: payload.hasAcceptedTou,
      hasAcceptedReleaseNotes: payload.hasAcceptedReleaseNotes,
      userUnitType: payload.userUnitType,
    });
    refreshLogin();
  };

  const updateCompanyCookies = (payload: {
    isAdmin: boolean;
    isReadOnly: boolean;
    isFinance: boolean;
    betaCustomer: boolean;
    readOnlyAccount: boolean;
  }) => {
    utils.addUserCookies({
      isAdmin: payload.isAdmin,
      isReadOnly: payload.isReadOnly,
      isFinance: payload.isFinance,
      betaCustomer: payload.betaCustomer,
    });

    authentication.value.IsAdmin = utils.getStorageToken?.IsAdmin;
    authentication.value.IsReadOnly = utils.getStorageToken?.IsReadOnly;
    authentication.value.IsFinance = utils.getStorageToken?.IsFinance;
    authentication.value.BetaCustomer = utils.getStorageToken?.BetaCustomer;
    authentication.value.ReadOnlyAccount = utils.getStorageToken?.ReadOnlyAccount;
  };

  const isFirstTimeLogin = async (payload: { isFirstTime: boolean }) => {
    isFirstTimeUpdated(payload);

    if (!payload.isFirstTime) {
      Cookies.set('isFirstTime', 'false', {
        domain: getDomainByHostname(location.hostname),
      });
    }
  };

  const hasAcceptedTermsOfUse = async () => {
    acceptedTouUpdated();
    Cookies.set('hasAcceptedTou', 'true', {
      domain: getDomainByHostname(location.hostname),
    });
  };

  const hasAcceptedReleaseNotesAction = async () => {
    authentication.value.HasAcceptedReleaseNotes = true;
    Cookies.set('hasAcceptedReleaseNotes', 'true', {
      domain: getDomainByHostname(location.hostname),
    });
  };

  const generateTimestamp = (fromNow = 0) => {
    const now = new Date();
    const timestampFromNow = now.getTime() + fromNow;

    return timestampFromNow;
  };

  const timestampToEpoch = (timestamp: number) => ~~(timestamp / 1000);

  const saveTimestampToCookie = (cookieName: string, fromNom: number = 0) => {
    const timestamp = generateTimestamp(fromNom);
    const timestampEpoch = timestampToEpoch(timestamp);
    const cookieNameInAuthentication = upperFirst(cookieName);

    authentication.value[cookieNameInAuthentication] = timestampEpoch;
    Cookies.set(cookieName, String(timestampEpoch), {
      domain: getDomainByHostname(location.hostname),
    });
  };

  const saveLastActivityTimestamp = () => {
    saveTimestampToCookie('lastActivityTimestamp');
  };

  const saveAccessTokenTimestamp = () =>
    saveTimestampToCookie('sapccAccessTokenExpirationTimestamp', FIVE_MINUTES);

  const saveRefreshTokenTimestamp = () =>
    saveTimestampToCookie('sapccRefreshTokenExpirationTimestamp', FOUR_HOURS);

  const doLogin = async (data: LoginCredentials) => {
    isBusyUpdated({ IsBusy: true });

    const loginParameters = {
      url: UmbracoApi.login,
      method: 'post',
      data,
    };

    const { IsSuccess, Error } = await Req(loginParameters);

    if (IsSuccess) {
      if (isDevelopment()) {
        saveAccessTokenTimestamp();
        saveRefreshTokenTimestamp();
      }
    } else if (Error && Error.ErrorDescription === 'password_reset_required') {
      // password is comporomised
      // show modal and start reset password flow
      handlePasswordResetRequired(data.userId);
    }

    isBusyUpdated({ IsBusy: false });
    RouteAuthenticationGate();

    if (!isAuthenticated.value) {
      return false;
    }

    if (router.currentRoute.value.name === 'serviceunavailable') {
      await router.push('search');
    }

    return true;
  };

  const handlePasswordResetRequired = (email: string) => {
    // reset password
    resetPassword(email);

    // show modal
    const openModal = useModal();
    openModal({
      modalComponent: 'ModalLoadContent',
      params: {
        key: 'PasswordResetRequired_ModalUrl',
        title: 'PasswordResetRequired_Name',
        buttonText: TextKey('UI_COMMON_OK'),
        buttonType: UiVariant.Default,
        targetOptions: { clickOutside: false },
      },
      first: true,
      single: true,
    });
  };

  const resetPassword = async (username: string) => {
    const { IsSuccess } = await Req({
      url: UserApi.ResetUserPassword(encodeURIComponent(username)),
      method: 'POST',
    });

    return IsSuccess;
  };

  const doLogout = async (disableRouteChange?: boolean) => {
    const userStore = useUserStore();
    const isBlueOrMixed = userStore.blueCustomer || userStore.mixedCustomer;
    authenticationLogout();
    utils.removeUserCookies();
    utils.removeAsmCookies();
    useLocalStorageRemoveItem('search-query');
    clearAllStores();
    if (!isLogoutInProgress.value) {
      try {
        isLogoutInProgress.value = true;
        await Req({
          url: UmbracoApi.logout,
          method: 'post',
        });
      } catch (e) {
        console.error('Failed to commit logout');
      } finally {
        isLogoutInProgress.value = false;
      }
    }

    if (hasFeature(FEATURES.NEW_LOGIN_DESIGN)) {
      if (isBlueOrMixed) {
        const SSO_AUTH_URL = getEnv('VUE_APP_SSO_AUTH_URI') ?? '';
        const SSO_AUTH_LOGOUT_PATH = getEnv('VUE_APP_SSO_AUTH_LOGOUT_PATH');
        const logoutURL = new URL(`${SSO_AUTH_URL}${SSO_AUTH_LOGOUT_PATH}`);
        window.location.href = logoutURL.href;
      }
    }

    if (!disableRouteChange) {
      await router
        .push(await RestrictedRouteChecker(router.currentRoute.value.matched))
        .catch(() => {
          /**/
        });
    }
  };

  const refreshLogin = async () => {
    authenticationUpdated();
  };

  const updateAsm = async (payload: { asm: IASM }) => {
    asmModeUpdated(payload);
  };

  const doLoginSAML = async (payload: { relayState: string }) => {
    const { IsSuccess, Data } = await Req({
      url: UmbracoApi.SAML(payload.relayState),
      method: 'get',
    });

    if (IsSuccess && Data) {
      window.location.href = Data;
    }
  };

  const refreshAccessToken = async (): Promise<boolean> => {
    const { IsSuccess } = await Req({
      url: UmbracoApi.getRefreshToken,
      method: 'get',
    });
    if (IsSuccess) {
      if (isDevelopment()) {
        saveAccessTokenTimestamp();
      }
      authenticationUpdated();
      return IsSuccess;
    } else {
      throw new Error('Failed to refresh access token');
    }
  };

  const timeRemaining = (timestampInFuture: number) => {
    const currentTimestamp = new Date().getTime();
    const remainingTime = timestampInFuture - currentTimestamp;

    return remainingTime;
  };

  const isAccessTokenExpired = () => {
    return timeRemaining(parseInt(getSapccAccessTokenExpirationTimestamp()) * 1000) <= 0;
  };
  const getRemainingSessionTime = () =>
    timeRemaining(parseInt(getSsapccRefreshTokenExpirationTimestamp()) * 1000);

  const getRemainingActivityTime = (sessionDuration: number) => {
    const timestampCookie = Cookies.get('lastActivityTimestamp');

    if (!timestampCookie) {
      return 0;
    }

    const timestamp = +timestampCookie * 1000;
    const currentTimestamp = new Date().getTime();
    const timeDifference = currentTimestamp - timestamp;
    const remainingTime = sessionDuration - timeDifference;

    return remainingTime;
  };

  return {
    setUserCookies,
    isFirstTimeLogin,
    hasAcceptedTermsOfUse,
    doLogin,
    doLogout,
    refreshLogin,
    updateAsm,
    doLoginSAML,
    hasAcceptedReleaseNotesAction,
    getRemainingActivityTime,
    saveLastActivityTimestamp,
    isAccessTokenExpired,
    refreshAccessToken,
    getRemainingSessionTime,
    updateCompanyCookies,
    authentication,
    asm,
    isAuthenticated,
    isAdmin,
    isReadOnly,
    isFinance,
    isBusy,
    betaCustomer,
    readOnlyAccount,
    isFirstTime,
    getGlobalId,
    hasAcceptedTou,
    hasAcceptedReleaseNotes,
    userUnitType,
    hasStorageToken,
    getAuthenticationTokenHeaders,
    getUnauthorizedHeader,
  };
});

export interface IAuthenticationHeaders {
  Authorized: boolean;
}
