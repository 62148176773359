<template>
  <span
    class="skeleton-loader"
    :class="{
      'skeleton-loader--small': uiSize.Small === size,
      'skeleton-loader--large': uiSize.Large === size,
      'skeleton-loader--auto': uiSize.Auto === size,
    }"
    ><br
  /></span>
</template>

<style src="./skeleton-loader.scss" lang="scss" scoped></style>

<script lang="ts" setup>
import { UiSize } from '@/src/core/components/ui/ui.types';

interface Props {
  size?: UiSize;
}

withDefaults(defineProps<Props>(), {
  size: UiSize.Medium,
});

const uiSize = UiSize;
</script>
