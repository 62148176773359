import { defineStore } from 'pinia';
import { ref } from 'vue';
import { UmbracoApi } from '../api';
import { Req } from '../services/requester';
import { INotificationGlobal } from '../types/ui';

export const useGlobalNotificationStore = defineStore('global-notification', () => {
  const notification = ref<INotificationGlobal>({});

  const notificationUpdated = (payload: INotificationGlobal) => {
    notification.value = Object.assign({}, payload);
  };

  const reset = () => {
    notification.value = {};
  };

  const fetchNotification = async () => {
    const { IsSuccess, Data } = await Req({ url: UmbracoApi.getGlobalNotification });

    if (IsSuccess) {
      notificationUpdated(Data);
    }
  };

  return {
    fetchNotification,
    reset,
    notificationUpdated,
    notification,
  };
});
